import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import validator from "validator";

import { ACCEPT_UPLOAD_EXTENSIONS, TOAST_CONTENT_TYPES } from "../../constants";
import { isValidRole } from "../../lib/roleChecker";
import FileItem from "../FileItem";
import Modal from "../Modal";
import ChargeSelect from "../common/ChargeSelect";
import Select from "../common/Select";
import { SubmitButton } from "@/components/common/SubmitButton";
import { isPositiveFiniteNumber, isToSecondDecimalPlace, isValidNumberRange } from "@/lib/validate";

class ItemEditorChiba extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      title: "",
      work_start_hour: "",
      work_end_hour: "",
      weight: "",
      item_name: "",
      regulation: "",
      system_ids: [0, 0, 0],
      company_id: 0,
      user_id: 0,
      group_id: 0,
      document_no: "",
      note: "",
      files: [],
      area_id: 0,
      device_id: 0,
      facility_management_id: 0,
      construction_management_id: 0,
      other_id: 0,
      showLightbox: false,
      lightboxSrc: "",
      error: {},
      roles: [],
      special_processes: [],
      protective_equipments: [],
      otherName: "",
      updating: false,
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleChangeWorkStart = this.handleChangeWorkStart.bind(this);
    this.handleChangeWorkEnd = this.handleChangeWorkEnd.bind(this);
    this.handleChangeWeight = this.handleChangeWeight.bind(this);
    this.handleChangeRegulation = this.handleChangeRegulation.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeSystem = this.handleChangeSystem.bind(this);
    this.handleChangeCompany = this.handleChangeCompany.bind(this);
    this.handleChangeCharge = this.handleChangeCharge.bind(this);
    this.handleChangeDocumentNo = this.handleChangeDocumentNo.bind(this);
    this.handleChangeNote = this.handleChangeNote.bind(this);
    this.handleChangeProcess = this.handleChangeProcess.bind(this);
    this.handleChangeEquipment = this.handleChangeEquipment.bind(this);
    this.handleChangeOtherName = this.handleChangeOtherName.bind(this);
    this.handleOpenLightbox = this.handleOpenLightbox.bind(this);
    this.handleCloseLightbox = this.handleCloseLightbox.bind(this);
    this.handleRemoveFile = this.handleRemoveFile.bind(this);
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.handleUploadProgress = this.handleUploadProgress.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
    this.handleUploadError = this.handleUploadError.bind(this);
  }

  componentDidMount() {
    this.setState({ fetching: true });
    this.props.fetchSubjectChiba(this.props.itemId, (data) => {
      const {
        work_start_hour,
        work_end_hour,
        weight = 0,
        item_name,
        regulation,
        document_no,
        area_id,
        device_id,
        facility_management_id,
        construction_management_id,
        other_id,
        roles,
        special_processes,
        protective_equipments,
        timestamp,
      } = data;

      const special = special_processes.map((item) => {
        return item.special_process_id;
      });
      const protective = protective_equipments.map((item) => {
        return item.protective_equipment_id;
      });
      const obj = protective_equipments.filter((item) => item.protective_equipment_id == 9).shift();
      let otherName = "";
      if (obj) {
        otherName = obj.protective_equipment_other_name;
      }
      const user_id = data.user_id || 0;
      const group_id = data.group_id || 0;
      const note = data.note || "";
      const files = data.files.map((f) => {
        f.mode = 0;

        return f;
      });

      const system_ids = [0, 0, 0];

      data.system_ids.forEach((systemId, index) => {
        system_ids[index] = systemId;
      });

      let company_id = 0;

      if (user_id > 0) {
        this.props.masters.users.forEach((user) => {
          if (user.user_id === user_id) {
            company_id = user.company_id;
          }
        });
      }

      if (group_id > 0) {
        this.props.masters.groups.forEach((group) => {
          if (group.group_id === group_id) {
            company_id = group.company_id;
          }
        });
      }

      this.setState({
        title: item_name,
        work_start_hour,
        work_end_hour,
        weight,
        item_name,
        regulation,
        system_ids,
        user_id,
        group_id,
        document_no,
        note,
        files,
        fetching: false,
        area_id,
        device_id,
        facility_management_id,
        construction_management_id,
        other_id,
        company_id,
        roles,
        special_processes: special,
        protective_equipments: protective,
        otherName,
        timestamp,
      });
    });

    this.props.setUnloadAlert();
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();
  }

  handleChangeWorkStart(e) {
    this.setState({ work_start_hour: e.target.value });
  }

  handleChangeWorkEnd(e) {
    this.setState({ work_end_hour: e.target.value });
  }

  handleChangeWeight(e) {
    this.setState({ weight: e.target.value });
  }

  handleChangeName(e) {
    this.setState({ item_name: e.target.value });
  }

  handleChangeRegulation(e) {
    this.setState({ regulation: e.target.value });
  }

  handleChangeSystem(index, e) {
    const system_ids = [...this.state.system_ids];
    system_ids[index] = e;
    this.setState({ system_ids });
  }

  handleChangeCompany(e) {
    this.setState({
      company_id: Number(e.target.value),
      user_id: 0,
      group_id: 0,
    });
  }

  handleChangeCharge(e) {
    this.setState({
      company_id: e.company_id,
      user_id: e.user_id,
      group_id: e.group_id,
    });
  }

  handleChangeDocumentNo(e) {
    this.setState({ document_no: e.target.value });
  }

  handleChangeNote(e) {
    this.setState({ note: e.target.value });
  }

  handleOpenLightbox(lightboxSrc) {
    this.setState({
      showLightbox: true,
      lightboxSrc,
    });
  }

  handleCloseLightbox() {
    this.setState({ showLightbox: false });
  }

  handleRemoveFile(fileId, isTemp) {
    const { t } = this.props;
    this.props.removeFile(
      t("delete_file"),
      [t("delete_file_alert")],
      () => {
        this.setState({
          files: this.state.files
            .map((f) => {
              if (isTemp && f.temp_file_id === fileId) {
                f.mode = -1;
              } else if (!isTemp && f.file_id === fileId) {
                f.mode = 2;
              }

              return f;
            })
            .filter((f) => f.mode !== -1),
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function -- 暫定処置でdisableしている
      () => {}
    );
  }

  handleFileSelect() {
    this.file.click();
  }

  handleUploadProgress(key, e) {
    this.setState({
      files: this.state.files.map((file) => {
        if (file.temp && file.key === key) {
          file.progress = (e.loaded / e.total) * 100;
        }

        return file;
      }),
    });
  }

  handleUploadComplete(key, e) {
    this.setState({
      files: this.state.files.map((file) => {
        if (file.temp && file.key === key) {
          file.progress = 100;
          file.temp_file_id = e.temp_file_id;
        }

        return file;
      }),
    });
  }

  handleUploadError(key, e) {
    const { t } = this.props;

    const msgs = e.response.errors?.map((m) => m.err_message).filter((m) => m);
    if (msgs) {
      this.props.showAlert(t("error"), msgs);
    }

    const file = this.state.files.find((f) => f.key === key);

    if (file) {
      this.setState({ files: this.state.files.filter((f) => f.key !== key) });
    }
  }

  handleChangeFile(e) {
    let files = [];

    Array.prototype.forEach.call(e.target.files, (file) => {
      files = [
        ...files,
        {
          key: Symbol(),
          file_name: file.name,
          file_format: file.type,
          mode: 1,
          temp: true,
          progress: 0,
          done: false,
          data: file,
        },
      ];
    });

    if (files.some((v) => 100 < v.file_name.length)) {
      const { t } = this.props;
      this.setState({ error: { ...this.state.error, file: t("file_name_is_too_long") } });
      return;
    } else {
      this.setState({ error: { ...this.state.error, file: null } });
    }

    files.forEach((file) => {
      const formData = new FormData();

      formData.append("target", 2);
      formData.append("uploadfile", file.data);
      this.props.upload(
        formData,
        (e) => this.handleUploadProgress(file.key, e),
        (e) => this.handleUploadComplete(file.key, e),
        (e) => this.handleUploadError(file.key, e)
      );
    });

    this.setState({
      files: [...files, ...this.state.files],
    });
  }

  handleSave() {
    if (this.state.updating) return;
    const error = {};
    const {
      area_id,
      device_id,
      facility_management_id,
      construction_management_id,
      other_id,
      work_start_hour,
      work_end_hour,
      weight,
      regulation,
      user_id,
      group_id,
      document_no,
      note,
      system_ids,
      files,
      special_processes,
      protective_equipments,
      otherName,
      timestamp,
    } = this.state;

    const item_name = this.state.item_name ? this.state.item_name.trim() : "";

    const { t } = this.props;

    if (!weight && weight !== 0) {
      error.weight = `${t("weight")}${t("input_required_field")}`;
    } else if (!isPositiveFiniteNumber(weight)) {
      error.weight = t("invalid_number_format", { field: t("weight") });
    } else if (!isToSecondDecimalPlace(weight)) {
      error.weight = t("weight_invalid_format");
    } else if (!isValidNumberRange(weight)) {
      error.weight = t("maximum_validation");
    }

    if (validator.isEmpty(item_name)) {
      error.item_name = `${t("title")}${t("is_required")}`;
    } else if (item_name.length > 100) {
      error.item_name = `${t("title")}${t("is_too_long")}`;
    }

    if (regulation && regulation.length > 100) {
      error.regulation = `${t("law")}${t("is_too_long")}`;
    }

    if (!user_id && !group_id) {
      error.user_id = `${t("assignee")}${t("is_not_selected")}`;
    }

    if (document_no && document_no.length > 60) {
      error.document_no = `${t("construction_specification")}No.${t("is_too_long_60")}`;
    }

    if (note && note.length > 100) {
      error.note = `${t("note")}${t("is_too_long")}`;
    }

    if (protective_equipments.some((id) => id === 9) && otherName.length < 1) {
      error.othername = `${t("enter_other_name")}`;
    }

    if (protective_equipments.some((id) => id === 9) && otherName.length > 100) {
      error.othername = `${t("other_name_too_long")}`;
    }

    if (protective_equipments.some((id) => id === 9) && otherName.length > 100) {
      error.othername = `${t("other_name_too_long")}`;
    }

    if (
      (work_start_hour !== null &&
        work_start_hour.length > 0 &&
        work_start_hour.match(/^([0-9]|[01][0-9]|2[0-3]):[0-5][0-9]$/u) == null) ||
      (work_end_hour !== null &&
        work_end_hour.length > 0 &&
        work_end_hour.match(/^([0-9]|[01][0-9]|2[0-3]):[0-5][0-9]$/u) == null)
    ) {
      error.work_hour = `${t("oirec_chiba_work_time_alert")}`;
    }

    if (Object.keys(error).length > 0) {
      this.setState({ error });
    } else {
      const data = {
        area_id: area_id || null,
        device_id: device_id || null,
        facility_management_id: facility_management_id || null,
        construction_management_id: construction_management_id || null,
        other_id: other_id || null,
        work_start_hour,
        work_end_hour,
        weight,
        item_name,
        regulation,
        user_id,
        group_id,
        document_no,
        note,
        special_processes,
        protective_equipments,
        protective_equipment_name: otherName,
        timestamp,
      };

      if (weight !== null && !validator.isEmpty(weight.toString())) {
        data.weight = Number(weight);
      }

      data.system_ids = system_ids.filter(Boolean).filter((id) => id !== 0);
      data.files = this.state.files
        // アップロード未完了ファイルはPOSTしない
        .filter((f) => {
          if (f.mode === 1 && f.progress && f.progress < 100) {
            return false;
          }

          return true;
        })
        .map((f) => {
          const file = {
            file_name: f.file_name,
            mode: f.mode,
          };

          if (f.temp) {
            file.temp_file_id = f.temp_file_id;
          } else {
            file.file_id = f.file_id;
            file.timestamp = f.timestamp;
          }

          return file;
        });

      this.setState({ updating: true });
      this.props.updateItemChiba(
        this.props.itemId,
        data,
        () => {
          this.setState({ updating: false });
          this.props.closeHandler();
        },
        () => {
          this.setState({ updating: false });
        }
      );
    }
  }

  handleChangeProcess(id, checked) {
    if (checked) {
      this.setState({ special_processes: [...this.state.special_processes, id] });
    } else {
      this.setState({ special_processes: this.state.special_processes.filter((item) => item != id) });
    }
  }

  handleChangeEquipment(id, checked) {
    if (checked) {
      this.setState({ protective_equipments: [...this.state.protective_equipments, id] });
    } else {
      this.setState({ protective_equipments: this.state.protective_equipments.filter((item) => item != id) });
    }
  }

  handleChangeOtherName(e) {
    this.setState({ otherName: e.target.value });
  }

  render() {
    const { download, fileUpload, t } = this.props;
    const systems = this.props.masters.systems.filter(
      (system) => system.area_id === this.state.area_id && system.device_id === this.state.device_id
    );
    const { roles, error } = this.state;
    const canEdit = isValidRole(this.state.roles, 1);
    const uploading = this.state.files.some((f) => f.mode === 1 && !f.temp_file_id);

    const specialProcesses = [
      { id: 1, name: "high_place_work" },
      { id: 2, name: "heavy_machinery_work" },
      { id: 3, name: "dust_work" },
      { id: 4, name: "radiation_work" },
      { id: 5, name: "inert_work" },
      { id: 6, name: "noisy_work" },
      { id: 7, name: "asbestos_handling_work" },
    ];
    const protectiveEquipments = [
      { id: 1, name: "rubber_glove" },
      { id: 2, name: "resistant_cloth" },
      { id: 3, name: "resistant_boots" },
      { id: 4, name: "face_mask" },
      { id: 5, name: "airline_mask" },
      { id: 6, name: "dust_mask" },
      { id: 7, name: "safety_harness" },
      { id: 8, name: "ear_protectors" },
      { id: 9, name: "others" },
    ];

    return (
      <Modal
        title={`${t("edit_title")}：[${this.state.title}]`}
        closeHandler={this.props.closeHandler}
        loading={this.state.fetching}
      >
        <div className="modal-body w-1060 clearfix">
          <div className="modal-body-top">
            <div className="mb-15 ml-[64px]">
              {/* エリア */}
              <div className="form-group w-290">
                <span className="form-label txt-bold">{t("area")}</span>
                <div className="d-ib ta-l w-220">
                  <Select
                    prefix="area"
                    disabled={!canEdit}
                    options={this.props.masters.areas}
                    value={this.state.area_id}
                    onChange={(v) => this.setState({ area_id: v })}
                    isClearable={true}
                  />
                </div>
              </div>
              {/* 装置 */}
              <div className="form-group w-280">
                <span className="form-label txt-bold">{t("device")}</span>
                <div className="d-ib ta-l w-220">
                  <Select
                    prefix="device"
                    disabled={!canEdit}
                    options={this.props.masters.devices}
                    value={this.state.device_id}
                    onChange={(v) => this.setState({ device_id: v })}
                    isClearable={true}
                  />
                </div>
              </div>
              {/* 設備管理 */}
              <div className="form-group w-290">
                <span className="form-label txt-bold">{t("facility_management")}</span>
                <div className="d-ib ta-l w-220">
                  <Select
                    prefix="facility_management"
                    disabled={!canEdit}
                    options={this.props.masters.facility_managements}
                    value={this.state.facility_management_id}
                    onChange={(v) => this.setState({ facility_management_id: v })}
                    isClearable={true}
                  />
                </div>
              </div>
            </div>
            <div className="mb-10 ml-50">
              {/* 工事管理 */}
              <div className="form-group w-290">
                <span className="form-label txt-bold">{t("construction_management")}</span>
                <div className="d-ib ta-l w-220">
                  <Select
                    prefix="construction_management"
                    disabled={!canEdit}
                    options={this.props.masters.construction_managements}
                    value={this.state.construction_management_id}
                    onChange={(v) => this.setState({ construction_management_id: v })}
                    isClearable={true}
                  />
                </div>
              </div>
              {/* その他 */}
              <div className="form-group w-290">
                <span className="form-label txt-bold">{t("other")}</span>
                <div className="d-ib ta-l w-220">
                  <Select
                    prefix="other"
                    disabled={!canEdit}
                    options={this.props.masters.others}
                    value={this.state.other_id}
                    onChange={(v) => this.setState({ other_id: v })}
                    isClearable={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-body-left w-350">
            <div className="form-row">
              <div className="form-group w-240">
                <span className="form-label txt-bold">{t("weight")}</span>
                <input
                  data-test-id="text-item-editor-weight"
                  type="text"
                  className="form-control w-110"
                  value={this.state.weight || ""}
                  disabled={!canEdit}
                  onChange={this.handleChangeWeight}
                />
              </div>
              {error.weight && (
                <div className="form-error w-300">
                  <p className="form-error-message w-220">{error.weight}</p>
                </div>
              )}
            </div>
            <div className="form-row">
              <div className="form-group w-350">
                <span className="form-label txt-bold">{t("title")}</span>
                <input
                  data-test-id="text-item-editor-item-name"
                  type="text"
                  className="form-control w-220"
                  value={this.state.item_name || ""}
                  disabled={!canEdit}
                  onChange={this.handleChangeName}
                />
              </div>
              {error.item_name && (
                <div className="form-error w-350">
                  <p className="form-error-message w-220">{error.item_name}</p>
                </div>
              )}
            </div>
            <div className="form-row">
              <div className="form-group w-350">
                <span className="form-label txt-bold">{t("law")}</span>
                <input
                  data-test-id="text-item-editor-regulation"
                  type="text"
                  className="form-control w-220"
                  value={this.state.regulation || ""}
                  disabled={!canEdit}
                  onChange={this.handleChangeRegulation}
                />
              </div>
              {error.regulation && (
                <div className="form-error w-350">
                  <p className="form-error-message w-220">{error.regulation}</p>
                </div>
              )}
            </div>
            <div className="form-row">
              <div className="form-group w-350">
                <span className="form-label txt-bold">{t("system")}</span>
                <div className="d-ib ta-l w-220">
                  <Select
                    prefix="system"
                    disabled={!canEdit}
                    options={systems}
                    value={this.state.system_ids[0]}
                    isClearable={true}
                    onChange={(e) => this.handleChangeSystem(0, e)}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group w-350">
                <div className="d-ib ta-l w-220">
                  <Select
                    prefix="system"
                    disabled={!canEdit}
                    options={systems}
                    value={this.state.system_ids[1]}
                    isClearable={true}
                    onChange={(e) => this.handleChangeSystem(1, e)}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group w-350">
                <div className="d-ib ta-l w-220">
                  <Select
                    prefix="system"
                    disabled={!canEdit}
                    options={systems}
                    value={this.state.system_ids[2]}
                    isClearable={true}
                    onChange={(e) => this.handleChangeSystem(2, e)}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group w-350">
                <span className="form-label txt-bold">{t("assignee")}</span>
                <div className="d-ib ta-l w-220">
                  <ChargeSelect
                    disabled={!canEdit}
                    masters={this.props.masters}
                    userId={this.state.user_id}
                    groupId={this.state.group_id}
                    isClearable={true}
                    onChange={this.handleChangeCharge}
                  />
                </div>
              </div>
              {error.user_id && (
                <div className="form-error w-350">
                  <p className="form-error-message w-220">{error.user_id}</p>
                </div>
              )}
            </div>
            <div className="form-row">
              <div className="form-group w-350">
                <span className="form-label txt-bold">{t("construction_specification")}No.</span>
                <input
                  data-test-id="text-item-editor-document-no"
                  type="text"
                  disabled={!canEdit}
                  className="form-control w-220"
                  value={this.state.document_no || ""}
                  onChange={this.handleChangeDocumentNo}
                />
              </div>
              {error.document_no && (
                <div className="form-error w-350">
                  <p className="form-error-message w-220">{error.document_no}</p>
                </div>
              )}
            </div>
            <div className="form-row">
              <div className="form-group w-350">
                <span className="form-label txt-bold">{t("note")}</span>
                <textarea
                  data-test-id="text-item-editor-note"
                  rows="4"
                  disabled={!canEdit}
                  className="form-control w-220"
                  value={this.state.note || ""}
                  onChange={this.handleChangeNote}
                ></textarea>
              </div>
              {error.note && (
                <div className="form-error w-350">
                  <p className="form-error-message w-220">{error.note}</p>
                </div>
              )}
            </div>
          </div>
          <div className="modal-body-center w-320">
            <div className="form-row">
              <div className="form-group w-320">
                <span className="form-label-left txt-bold">{t("worktime")}</span>
                <div className="worktime w-320 chiba">
                  <div className="w-60 worktime-start">
                    <input
                      data-test-id="text-item-editor-worktime-start"
                      type="text"
                      className="form-control w-60"
                      value={this.state.work_start_hour || ""}
                      disabled={!canEdit}
                      onChange={this.handleChangeWorkStart}
                    />
                  </div>
                  &nbsp;〜&nbsp;
                  <div className="w-60 worktime-end">
                    <input
                      data-test-id="text-item-editor-worktime-end"
                      type="text"
                      className="form-control w-60"
                      value={this.state.work_end_hour || ""}
                      disabled={!canEdit}
                      onChange={this.handleChangeWorkEnd}
                    />
                  </div>
                </div>
                {error.work_hour && (
                  <div className="form-error w-250">
                    <p className="form-error-message w-220">{error.work_hour}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group w-320">
                <span className="form-label-left txt-bold">{t("special_work")}</span>
                <div className="ckbox-vertically w-320 chiba">
                  {specialProcesses.map((process, index) => (
                    <label key={index} className="ckbox">
                      <input
                        type="checkbox"
                        disabled={!canEdit}
                        value={process.id || undefined}
                        checked={this.state.special_processes.some((value) => value === process.id)}
                        onChange={(e) => this.handleChangeProcess(process.id, e.target.checked)}
                      />
                      <span>{t(process.name)}</span>
                    </label>
                  ))}
                </div>
              </div>
              <div className="form-group w-230">
                <span className="form-label-left txt-bold">{t("protection")}</span>
                <div className="ckbox-vertically w-300 chiba">
                  {protectiveEquipments.map((equip, index) => (
                    <label key={index} className="ckbox">
                      <input
                        type="checkbox"
                        disabled={!canEdit}
                        value={equip.id || undefined}
                        checked={this.state.protective_equipments.some((value) => value === equip.id)}
                        onChange={(e) => this.handleChangeEquipment(equip.id, e.target.checked)}
                      />
                      <span>{t(equip.name)}</span>
                      {equip.id == 9 && (
                        <input
                          data-test-id="text-item-editor-other_name"
                          type="text"
                          className="form-control w-130 othe-name"
                          value={this.state.otherName || ""}
                          disabled={!canEdit}
                          onChange={this.handleChangeOtherName}
                        />
                      )}
                    </label>
                  ))}
                </div>
              </div>
              {error.othername && (
                <div className="form-error w-300">
                  <p className="form-error-message w-190 othername">{error.othername}</p>
                </div>
              )}
            </div>
          </div>
          <div className="modal-body-right w-290">
            <div className="form-row" style={{ marginBottom: "7px" }}>
              <div className="form-group">
                <span className="form-label txt-bold">{t("upload_file")}</span>
                <button
                  data-test-id="button-item-editor-select-file"
                  disabled={!isValidRole(roles, 2) || !fileUpload}
                  className="btn btn-light-blue w-150"
                  onClick={this.handleFileSelect}
                >
                  {t("browze_file")}
                </button>
                <form ref={(node) => (this.form = node)} style={{ display: "none" }} encType={"multipart/form-data"}>
                  <input
                    type="file"
                    disabled={!canEdit}
                    name="uploadFiles"
                    accept={ACCEPT_UPLOAD_EXTENSIONS.join(",")}
                    multiple={true}
                    onChange={this.handleChangeFile}
                    onClick={(e) => (e.target.value = null)}
                    ref={(node) => (this.file = node)}
                  />
                </form>
              </div>
            </div>
            {error.file && (
              <div className="form-error" style={{ textAlign: "left" }}>
                <p className="form-error-message">{error.file}</p>
              </div>
            )}
            {isValidRole(roles, 3) && (
              <div className="file-upload-box">
                <ul className="form-file-list">
                  {this.state.files
                    .filter((f) => f.mode !== 2)
                    .map((file, index) => (
                      <FileItem
                        key={index}
                        disabled={!isValidRole(roles, 4)}
                        file={file}
                        kind="item"
                        readOnly={false}
                        qrFlag={true}
                        onDelete={this.handleRemoveFile}
                        onDownload={download}
                        additionalFilenameClass="mw-90"
                      />
                    ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className={"modal-footer"}>
          <button
            data-test-id="button-item-editor-cancel"
            type="button"
            className="btn btn-gray"
            onClick={this.props.closeHandler}
          >
            {t("cancel")}
          </button>
          <SubmitButton
            data-test-id="button-item-editor-save"
            onClick={this.handleSave}
            loading={this.state.updating}
            disabled={!isValidRole(roles, 0) || uploading}
          />
        </div>
      </Modal>
    );
  }
}

ItemEditorChiba.propTypes = {
  itemId: PropTypes.number.isRequired,
  masters: PropTypes.object.isRequired,
  fetchSubjectChiba: PropTypes.func.isRequired,
  updateItemChiba: PropTypes.func.isRequired,
  download: PropTypes.func.isRequired,
  closeHandler: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
};

export default withTranslation()(ItemEditorChiba);
