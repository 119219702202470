import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class LeftGridHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps) {
    return false;
  }

  render() {
    const { t } = this.props;

    return (
      <table className="grid-table grid-table-header grid-table-left grid-table-left-header">
        <thead>
          <tr id="left-header">
            <th>{t("area")}</th>
            <th>{t("device_name")}</th>
            <th>{t("device_code")}</th>
            <th>{t("facility_management")}</th>
            <th>{t("construction_management")}</th>
            <th>{t("primary_charge")}</th>
          </tr>
        </thead>
      </table>
    );
  }
}

LeftGridHeader.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(LeftGridHeader);
