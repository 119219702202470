import moment from "moment";

export const formatUseHour = (useHour: string) => {
  if (!useHour) {
    return "";
  }

  // NOTE: H:MM と HH:MM の両形式に対応するためreplaceする
  // 例: 9:00 と 09:00 を 両方とも対応する
  const hour = Number(useHour.slice(0, 2).replace(/[^0-9]/g, ""));
  const minute = Number(useHour.slice(-2));
  const date = moment().hour(hour).minute(minute);

  return date;
};

export const formatUseHourString = (value: string): string => {
  const trimmed = value.trim();
  const v = trimmed.replace(/[：；]/, ":").replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 65248));
  if (!v.match(/^[0-9]?[0-9]:?[0-9]{2}$/)) return trimmed;

  return v.replace(":", "").replace(/([0-9]{1,2})([0-9]{2})/g, "$1:$2");
};
