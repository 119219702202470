import { zodResolver } from "@hookform/resolvers/zod";
import moment from "moment";
import { ComponentProps, FC, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as z from "zod";

import { Button } from "@/sx-layout/common/Button";
import { FormError, FormLabel, FormRow, InputText } from "@/sx-layout/common/Form";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "@/sx-layout/common/Modal";
import { ChangeMachineUseEndHourInput } from "@/sx-layout/components/plotmap/actions/types";
import { formatUseHour, formatUseHourString } from "@/sx-layout/components/plotmap/util/formatUseHour";
import { HHMMRegex } from "@/sx-layout/utils";

export type ChangeMachineUseEndHourModal = ComponentProps<typeof Modal> & {
  useEndHourProp: string;
  useStartHourProp: string;
  onSubmit: ({ useEndHour }: { useEndHour: string }) => void;
};

export const ChangeMachineUseEndHourModal: FC<ChangeMachineUseEndHourModal> = ({
  useEndHourProp,
  useStartHourProp,
  onClose,
  onSubmit,
}) => {
  // プロットマップの拡大/縮小に伴ってPropsがundefinedになり得るので、本コンポーネントで初期値を保持する
  const [useStartHour] = useState(useStartHourProp);
  const [useEndHour] = useState(useEndHourProp);

  const { t } = useTranslation();

  const formSchema = z.object({
    changedUseEndHour: z
      .string()
      .refine((hour) => hour !== "", { message: t("end_hour_is_required") })
      .refine((hour) => HHMMRegex.test(hour) && hour !== "24:00", {
        message: t("end_hour_has_wrong_format"),
      })
      .refine(
        (hour) => {
          const now = moment();

          return moment(formatUseHour(hour)).isAfter(now);
        },
        {
          message: t("end_hour_is_after_now"),
        }
      ),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(formSchema),
    reValidateMode: "onSubmit",
    defaultValues: {
      changedUseEndHour: useEndHour,
    },
  });

  // 時間文字列のフォーマット
  const changedUseEndHour = watch("changedUseEndHour");
  const onBlurUseStartHour = useCallback(() => {
    setValue("changedUseEndHour", formatUseHourString(changedUseEndHour));
  }, [changedUseEndHour]);

  const submit = (values: ChangeMachineUseEndHourInput) => {
    onSubmit({
      useEndHour: values.changedUseEndHour,
    });
    onClose();
  };

  const renderErrorComponent = () => {
    if (errors.changedUseEndHour) {
      return <FormError className="ml-[100px]">{errors.changedUseEndHour.message}</FormError>;
    }

    return null;
  };

  return (
    <Modal
      onClose={onClose}
      id="change-machine-use-end-hour-editor-modal"
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      <ModalHeader>{t("adjust_end_hour")}</ModalHeader>

      <ModalBody className="flex-col min-h-[150px] h-full items-start p-0 w-[450px]" size="small">
        <div className="flex justify-center w-[90%]">
          <FormRow className="mt-0" errorComponent={renderErrorComponent()}>
            <FormLabel className="w-[100px] mr-0">{t("operation_time")}</FormLabel>
            <span className="w-[70px] px-[8px]">{useStartHour}</span>
            &nbsp;〜&nbsp;
            <InputText
              className="ml-[10px] w-[90px] px-[8px] py-[4px] border border-gray-300 rounded"
              {...register("changedUseEndHour")}
              onBlur={onBlurUseStartHour}
            />
          </FormRow>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button buttonType="cancel" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button onClick={handleSubmit(submit)}>{t("save")}</Button>
      </ModalFooter>
    </Modal>
  );
};
