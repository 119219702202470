import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Modal from "../Modal";

class ScheduleUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryId: -1,
      scheduleDate: "",
      loading: false,
      loadingAll: false,
      categoryName: "",
      categoryNum: this.props.categories.length,
      count: 1,
      isAllCategory: false,
      isDone: false,
      isSuspend: false,
      isUpdating: false,
      isSkipped: false,
      skippedRecords: "",
      updatedCount: 0,
      scheduleDateList: [],
    };

    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.handleChangeScheduleDate = this.handleChangeScheduleDate.bind(this);
    this.handleSuspend = this.handleSuspend.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleAllCreate = this.handleAllCreate.bind(this);
  }

  componentDidMount() {
    this.props.setUnloadAlert();

    // 予定日の選択肢
    const now = moment();
    // @ts-ignore: TODO 型定義
    const edit_end_time = this.props.config.schedule.edit_end_time;
    const editEndTime = moment(now.format("YYYY-MM-DD") + " " + ("0" + edit_end_time).slice(-5) + ":00");
    const scheduleDateList: { id: number; value: string }[] = [];
    const date = moment(now);
    for (let i = 0; i < 7; i++) {
      if (i === 0 && now.isAfter(editEndTime)) {
        date.add(1, "days");
        continue;
      }
      scheduleDateList.push({ id: i, value: date.add(1, "days").format("YYYY-MM-DD") });
    }
    this.setState({ scheduleDate: scheduleDateList[0].value, scheduleDateList });
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();
  }

  handleChangeCategory(e) {
    this.setState({
      categoryId: e.target.value,
      isAllCategory: e.target.value == -1 ? true : false,
    });
  }

  handleChangeScheduleDate(e) {
    this.setState({
      scheduleDate: e.target.value,
    });
  }

  handleSuspend() {
    this.setState({ isSuspend: true, loading: false, loadingAll: false });
  }

  handleCancel() {
    this.props.closeHandler();
  }

  handleSave() {
    const data = { ...this.state };
    const { createSchedule } = this.props;

    if (data.categoryId == -1) {
      this.handleAllCreate();
    } else {
      data.loading = true;
      this.setState(data);

      createSchedule(
        data,
        (res) => {
          this.setState({
            loading: false,
            isDone: true,
            isSkipped: res.skipped_records.length > 0 ? true : false,
            skippedRecords: res.skipped_records,
            updatedCount: res.count,
          });
        },
        () => {
          this.setState({ loading: false });
        }
      );
    }
  }

  handleAllCreate() {
    const { categories, createSchedule } = this.props;
    const getSuspend = () => this.state.isSuspend;
    const skippedData = [];
    const execCreateSchedule = (data) => {
      return new Promise((resolve) => {
        createSchedule(
          data,
          (res) => {
            resolve(true);
            if (res.skipped_records.length > 0) {
              skippedData.push(res.skipped_records);
            }
          },
          () => {
            reject(false);
          }
        );
      });
    };

    const run = async () => {
      let suspend = false;
      this.setState({ isSuspend: false });
      for (let i = 0; i < categories.length; i += 1) {
        const data = { ...this.state };
        data.loadingAll = true;
        data.isAllCategory = true;
        data.categoryId = categories[i].category_id;
        data.categoryName = categories[i].category_name;
        data.isUpdating = true;
        data.count = i + 1;

        if (getSuspend()) {
          suspend = true;
          data.isSuspend = false;
          this.setState(data);

          break;
        }
        this.setState(data);

        const result = await execCreateSchedule(data);
        if (!result) break;
      }

      return suspend;
    };
    run().then((suspend) => {
      this.setState({
        loading: false,
        loadingAll: false,
        categoryId: -1,
        isDone: suspend ? false : true,
        isUpdating: false,
        skippedRecords: skippedData.join("\n"),
        isSkipped: skippedData.length > 0,
      });
    });
  }

  render() {
    const { categories, closeHandler, t } = this.props;
    const {
      loading,
      loadingAll,
      scheduleDate,
      categoryName,
      categoryNum,
      count,
      isAllCategory,
      isDone,
      isUpdating,
      isSkipped,
      skippedRecords,
      updatedCount,
      scheduleDateList,
    } = this.state;
    const skippedTextarea = () => {
      return (
        <React.Fragment>
          <br />
          <p>{t("skipped_for_requested")}</p>
          <textarea
            data-test-id="text-note-editor"
            rows="6"
            className="form-control"
            value={skippedRecords}
            // eslint-disable-next-line @typescript-eslint/no-empty-function -- 暫定処置でdisableしている
            onChange={() => {}}
          />
        </React.Fragment>
      );
    };

    return (
      <Modal className="" title={t("schedule_update")} closeHandler={() => closeHandler()}>
        <div className={`modal-body schedule-update-chiba ${isSkipped ? "isSkipped" : ""} `}>
          {!isUpdating && !isDone ? (
            <React.Fragment>
              <div className="form-row">
                <div className="form-group w-280">
                  <div>
                    <span className="form-label txt-bold">{t("scheduled_date_shorten")}</span>
                    <select
                      data-test-id="select-schedule-date"
                      className="form-control w-220"
                      onChange={this.handleChangeScheduleDate}
                    >
                      {scheduleDateList.map((item) => (
                        <option key={item.id} value={item.value}>
                          {moment(item.value).format("YYYY/MM/DD")}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-row mb-5">
                <div className="form-group w-280">
                  <div>
                    <span className="form-label txt-bold">{t("machines_category")}</span>
                    <select
                      data-test-id="text-import-file-type"
                      className="form-control w-220"
                      onChange={this.handleChangeCategory}
                    >
                      <option key={-1} value={-1}>
                        {t("all_categories")}
                      </option>
                      {categories.map((category) => (
                        <option key={category.category_id} value={category.category_id}>
                          {category.category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : isAllCategory ? (
            <React.Fragment>
              <div className="updating-msg">
                <p>{`${t("scheduled_date")}：${scheduleDate}`}</p>
                {isDone && (
                  <React.Fragment>
                    <p>
                      {t("all_categories_no_braket")} {t("update_done")}
                    </p>
                    {isSkipped && skippedTextarea()}
                  </React.Fragment>
                )}
                {isUpdating && (
                  <React.Fragment>
                    <p>{`${categoryName} ${t("updating")}`}</p>
                    <p>{`${count}/${categoryNum}`}</p>
                  </React.Fragment>
                )}
              </div>
              {loadingAll && <div className="loading-area loading loading--dialog"></div>}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="updating-msg">
                <p>{t("updated_schedule") + "（" + updatedCount + t("unit") + "）"}</p>
                {isSkipped && skippedTextarea()}
              </div>
            </React.Fragment>
          )}
        </div>
        <div className={`modal-footer ${loading ? "loading loading--dialog" : ""}`}>
          {loadingAll ? (
            <button
              data-test-id="button-update-cancel"
              type="button"
              className={`btn btn-gray ${isDone ? "disp-non" : ""}`}
              onClick={this.handleSuspend}
            >
              {t("cancel")}
            </button>
          ) : (
            <button
              data-test-id="button-update-cancel"
              type="button"
              className={`btn btn-gray ${isDone ? "disp-non" : ""}`}
              onClick={this.handleCancel}
            >
              {t("cancel")}
            </button>
          )}
          <button
            disabled={loading || loadingAll ? "disabled" : ""}
            data-test-id="button-update-save"
            type="button"
            className={`btn btn-blue ${isDone ? "disp-non" : ""}`}
            onClick={this.handleSave}
          >
            {t("update")}
          </button>
          <button
            data-test-id="button-update-ok"
            type="button"
            className={`btn btn-blue ${isDone ? "" : "disp-non"}`}
            onClick={this.handleCancel}
          >
            {t("alert_ok")}
          </button>
        </div>
      </Modal>
    );
  }
}

ScheduleUpdate.propTypes = {
  categories: PropTypes.array.isRequired,
  closeHandler: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ScheduleUpdate);
