import { ClassValue } from "clsx";
import { ComponentProps, FC } from "react";

import { cn } from "@/lib/classNames";

export type TableProps = Omit<ComponentProps<"table">, "className"> & {
  className?: ClassValue;
};

export const Table: FC<TableProps> = ({ children, className, ...restProps }) => {
  return (
    <table className={cn("text-[13px] w-full", className)} {...restProps}>
      {children}
    </table>
  );
};
