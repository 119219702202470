import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { isValidRole } from "../../lib/roleChecker";

class LeftGridHeaderChiba extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.isWide === nextProps.isWide) {
      return false;
    }

    return true;
  }

  render() {
    const { taskRoles, showSpecialProcessBundle, showProtectionBundle, t } = this.props;
    const canBulkUpdate = isValidRole(taskRoles, 0);

    return (
      <table className="grid-table grid-table-header grid-table-left-chiba grid-table-left-header chiba">
        <thead>
          <tr>
            <th>
              <span className="grid-cell-narrow">No</span>
              <button
                data-test-id="button-matrix-collapse"
                className="grid-btn-collapse"
                onClick={this.props.toggleWide}
              >
                {this.props.isWide ? "▶︎" : "◀︎"}
              </button>
            </th>
            <th className="rotate">
              <span className="grid-cell-narrow">{t("delivery")}</span>
            </th>
            <th className="rotate">
              <span className="grid-cell-narrow">{t("complete_process")}</span>
            </th>
            <th className="rotate">
              <span className="grid-cell-narrow">{t("airtight_preparation")}</span>
            </th>
            <th>{t("area")}</th>
            <th>{t("device")}</th>
            <th>{t("primary_charge")}</th>
            <th>{t("construction_specification")}No</th>
            <th>{t("title")}</th>
            <th>{t("special_work")}</th>
            <th>{t("protection")}</th>
            <th>{t("law")}</th>
            <th>{t("system")}</th>
            <th>{t("note")}</th>
            <th>{t("assignee")}</th>
          </tr>
          <tr className="grid-separator-bg">
            <th className="grid-separator"></th>
            <th className="grid-separator"></th>
            <th className="grid-separator"></th>
            <th className="grid-separator"></th>
            <th className="grid-separator"></th>
            <th className="grid-separator"></th>
            <th className="grid-separator"></th>
            <th className="grid-separator"></th>
            <th className="grid-separator"></th>
            <th
              className={`grid-separator ${canBulkUpdate ? "grid-cell-clickable" : ""}`}
              onClick={() => {
                if (!canBulkUpdate) {
                  return false;
                }
                showSpecialProcessBundle();
              }}
            >
              <React.Fragment>
                {canBulkUpdate && <button className="grid-icon grid-icon-edit icon-mode_edit"></button>}
              </React.Fragment>
            </th>
            <th
              className={`grid-separator ${canBulkUpdate ? "grid-cell-clickable" : ""}`}
              onClick={() => {
                if (!canBulkUpdate) {
                  return false;
                }
                showProtectionBundle();
              }}
            >
              <React.Fragment>
                {canBulkUpdate && <button className="grid-icon grid-icon-edit icon-mode_edit"></button>}
              </React.Fragment>
            </th>
            <th className="grid-separator"></th>
            <th className="grid-separator"></th>
            <th className="grid-separator"></th>
            <th className="grid-separator"></th>
          </tr>
        </thead>
      </table>
    );
  }
}

LeftGridHeaderChiba.propTypes = {
  isWide: PropTypes.bool.isRequired,
  toggleWide: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(LeftGridHeaderChiba);
