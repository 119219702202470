import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import * as util from "../lib/common";

import Modal from "./Modal";

class PasswordEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordOld: "",
      passwordNew: "",
      passwordConfirm: "",
      updating: false,
      error: {},
    };

    this.handleChangePasswordOld = this.handleChangePasswordOld.bind(this);
    this.handleChangePasswordNew = this.handleChangePasswordNew.bind(this);
    this.handleChangePasswordConfirm = this.handleChangePasswordConfirm.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.props.setUnloadAlert();
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();
  }

  handleChangePasswordOld(e) {
    this.setState({ passwordOld: e.target.value });
  }

  handleChangePasswordNew(e) {
    this.setState({ passwordNew: e.target.value });
  }

  handleChangePasswordConfirm(e) {
    this.setState({ passwordConfirm: e.target.value });
  }

  handleSave() {
    const error = {};
    const { passwordOld, passwordNew, passwordConfirm } = this.state;
    const { save, close, t } = this.props;

    if (passwordOld === "") {
      error.passwordOld = t("unentered");
    }

    if (passwordNew === "") {
      error.passwordNew = t("unentered");
    }

    if (passwordConfirm === "") {
      error.passwordConfirm = t("unentered");
    } else if (passwordNew !== passwordConfirm) {
      error.passwordConfirm = t("not_match_password");
    }

    if (Object.keys(error).length > 0) {
      this.setState({ error });
    } else {
      this.setState({ updating: true });
      save(passwordOld, passwordNew, close, () => {
        this.setState({ updating: false });
      });
    }
  }

  render() {
    const { close, t } = this.props;
    const { passwordOld, passwordNew, passwordConfirm, error, updating } = this.state;

    return (
      <Modal title={t("change_password")} closeHandler={close}>
        <div className="modal-body">
          <div className="form-row">
            <div className="form-group w-360">
              <span className="form-label txt-bold">{t("current_password")}</span>
              <input
                data-test-id="text-password-current_password"
                type="password"
                className="form-control w-200"
                value={passwordOld}
                onChange={this.handleChangePasswordOld}
              />
            </div>
            {error.passwordOld && (
              <div className="form-error w-360">
                <p className="form-error-message w-200">{error.passwordOld}</p>
              </div>
            )}
          </div>
          <div className="form-row">
            <div className="form-group w-360">
              <span className="form-label txt-bold">{t("new_password")}</span>
              <input
                data-test-id="text-password-new_password1"
                type="password"
                className="form-control w-200"
                value={passwordNew}
                onChange={this.handleChangePasswordNew}
              />
            </div>
            {error.passwordNew && (
              <div className="form-error w-360">
                <p className="form-error-message w-200">{error.passwordNew}</p>
              </div>
            )}
          </div>
          <div className="form-row">
            <div className="form-group w-360">
              <span className="form-label txt-bold">{t("new_password_check")}</span>
              <input
                data-test-id="text-password-new_password2"
                type="password"
                className="form-control w-200"
                value={passwordConfirm}
                onChange={this.handleChangePasswordConfirm}
              />
            </div>
            {error.passwordConfirm && (
              <div className="form-error w-360">
                <p className="form-error-message w-200">{error.passwordConfirm}</p>
              </div>
            )}
          </div>
        </div>
        <div className={`modal-footer ${updating ? "loading loading--dialog" : ""}`}>
          <button data-test-id="button-password-cancel" type="button" className="btn btn-gray" onClick={close}>
            {t("cancel")}
          </button>
          <button data-test-id="button-password-save" type="button" className="btn btn-blue" onClick={this.handleSave}>
            {t("decision")}
          </button>
        </div>
      </Modal>
    );
  }
}

PasswordEditor.propTypes = {
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
};

export default withTranslation()(PasswordEditor);
