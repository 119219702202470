import { connect } from "react-redux";

import actions from "../../actions/index";
import PulldownExport from "../../components/matrix/PulldownExport";

const mapStateToProps = (_) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    download: (params, callback, fallback) => dispatch(actions.matrix.download(params, callback, fallback)),
    toggleDownloading: (downloading) => dispatch(actions.matrix.toggleDownloading(downloading)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PulldownExport);
