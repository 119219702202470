import { ScheduleScheduleItem } from "@/components/list/Schedule/types";
import * as constants from "@/constants";
import environments from "@/environments";
import { UnworkDayGroup, UnworkDaySearchParams, UnworkDayUpdateParams } from "@/models/holiday";
import format from "date-fns/format";

// 全休日_一覧取得
export const fetchUnworkDays = (params: UnworkDaySearchParams, callback?: (_) => void, fallback?: () => void) => {
  const endpoint = `${environments.API_BASE_URI}/unwork_days`;
  const query = new URLSearchParams();
  (params.construction_id || []).forEach((id) => query.append("construction_id", id.toString()));
  (params.category_id || []).forEach((id) => query.append("category_id", id.toString()));
  (params.primary_charge_id || []).forEach((id) => query.append("primary_charge_id", id.toString()));
  (params.area_id || []).forEach((id) => query.append("area_id", id.toString()));
  (params.device_id || []).forEach((id) => query.append("device_id", id.toString()));
  (params.facility_management_id || []).forEach((id) => query.append("facility_management_id", id.toString()));
  (params.construction_management_id || []).forEach((id) => query.append("construction_management_id", id.toString()));

  return {
    type: constants.APP_CALL_API,
    endpoint: `${endpoint}?${query.toString()}`,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.UNWORK_DAYS_BEGIN_FETCH,
        };
      },
      success: (response) => {
        callback?.(response);

        return {
          type: constants.UNWORK_DAYS_END_FETCH,
          payload: response,
        };
      },
      error: (response) => {
        fallback?.();

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

// 全休日更新
export const updateUnworkDays = (unworkDayGroups: UnworkDayGroup, dates: Date[], callback?) => {
  const params: UnworkDayUpdateParams = {
    category_id: unworkDayGroups.category_id,
    primary_charge_id: unworkDayGroups.primary_charge_id,
    area_id: unworkDayGroups.area_id,
    device_id: unworkDayGroups.device_id,
    facility_management_id: unworkDayGroups.facility_management_id,
    construction_management_id: unworkDayGroups.construction_management_id,
    other_id: unworkDayGroups.other_id,
    unwork_days: dates.map((v) => format(v, "yyyy-MM-dd")),
  };
  const endpoint = `${environments.API_BASE_URI}/unwork_days/update`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(params),
    callbacks: {
      begin: () => {
        return {
          type: constants.UNWORK_DAYS_BEGIN_UPDATE,
        };
      },
      success: (response: ScheduleScheduleItem) => {
        callback?.(response);

        return {
          type: constants.UNWORK_DAYS_END_UPDATE,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const startLoading = () => {
  return { type: constants.UNWORK_DAYS_START_LOADING };
};

export const endLoading = () => {
  return { type: constants.UNWORK_DAYS_END_LOADING };
};

export const expandRow = (index: number) => {
  return {
    type: constants.HOLIDAY_EXPAND_ROW,
    payload: index,
  };
};
