import { connect } from "react-redux";

import PulldownStatus from "../../components/matrix/PulldownStatus";

const mapStateToProps = (_) => {
  return {};
};

const mapDispatchToProps = (_) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PulldownStatus);
