import { ClassValue } from "clsx";
import { ComponentProps, FC } from "react";

import { cn } from "@/lib/classNames";

export type TbodyProps = Omit<ComponentProps<"tbody">, "className"> & {
  className?: ClassValue;
};

export const Tbody: FC<TbodyProps> = ({ children, className, ...restProps }) => {
  return (
    <tbody className={cn("", className)} {...restProps}>
      {children}
    </tbody>
  );
};
