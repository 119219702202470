import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Modal from "../Modal";
import { SubmitButton } from "@/components/common/SubmitButton";

class ProtectionBundleEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      protective_equipments: [],
      otherName: "",

      error: {},
    };

    this.handleChangeProtect = this.handleChangeProtect.bind(this);
    this.handleChangeOtherName = this.handleChangeOtherName.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.props.setUnloadAlert();
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();
  }

  handleChangeProtect(id, checked) {
    if (checked) {
      this.setState({ protective_equipments: [...this.state.protective_equipments, id] });
    } else {
      this.setState({ protective_equipments: this.state.protective_equipments.filter((item) => item != id) });
    }
  }

  handleChangeOtherName(e) {
    this.setState({ otherName: e.target.value });
  }

  handleSave() {
    if (this.state.updating) return;
    const error = {};
    const { params, itemId, t } = this.props;
    const { protective_equipments, otherName } = this.state;

    const data = {
      category_id: params.categoryId,
      user_id: params.userIds,
      group_id: params.groupIds,
      area_id: params.areaIds,
      device_id: params.deviceIds,
      facility_management_id: params.facilityManagementIds,
      construction_management_id: params.constructionManagementIds,
      primary_charge_id: params.primaryChargeIds,
      other_id: params.otherIds,
      system_id: params.systemIds,
      process_middle_class_id: params.processMiddleClassIds,
      company_id: params.companyIds,
      item_text: params.itemText,
      process_text: params.processText,
      regulation: params.regulation,
      filter: params.filters,
      protective_equipments,
      protective_equipment_name: otherName,
    };

    if (itemId > 0) {
      data.item_id = itemId;
    }

    if (protective_equipments.some((id) => id === 9) && otherName.length < 1) {
      error.othername = `${t("enter_other_name")}`;
    }

    if (protective_equipments.some((id) => id === 9) && otherName.length > 100) {
      error.othername = `${t("other_name_too_long")}`;
    }

    if (Object.keys(error).length > 0) {
      this.setState({ error });
    } else {
      this.setState({ updating: true });
      this.props.onSave(
        data,
        () => {
          this.setState({ updating: false });
          this.props.closeHandler();
          this.props.handleSearch();
        },
        () => this.setState({ updating: false }),
        () => this.setState({ updating: false })
      );
    }
  }

  render() {
    const { closeHandler, t } = this.props;
    const { error } = this.state;

    const protectiveEquipments = [
      { id: 1, name: "rubber_glove" },
      { id: 2, name: "resistant_cloth" },
      { id: 3, name: "resistant_boots" },
      { id: 4, name: "face_mask" },
      { id: 5, name: "airline_mask" },
      { id: 6, name: "dust_mask" },
      { id: 7, name: "safety_harness" },
      { id: 8, name: "ear_protectors" },
      { id: 9, name: "others" },
    ];

    return (
      <Modal title={`${t("protection")} ${t("update_bulk")}`} closeHandler={() => closeHandler()}>
        <div className="modal-body">
          <div className="form-row mb-0">
            <div className="form-group w-400 txt-center">
              <div className="ckbox-vertically w-220 ml-40">
                {protectiveEquipments.map((protect, index) => (
                  <label key={index} className="ckbox mr-0">
                    <input
                      type="checkbox"
                      value={protect.id || undefined}
                      checked={this.state.protective_equipments.some((value) => value === protect.id)}
                      onChange={(e) => this.handleChangeProtect(protect.id, e.target.checked)}
                    />
                    <span>{t(protect.name)}</span>
                    {protect.id == 9 && (
                      <input
                        data-test-id="text-item-editor-other_name"
                        type="text"
                        className="form-control w-130 othe-name"
                        value={this.state.otherName || ""}
                        onChange={this.handleChangeOtherName}
                      />
                    )}
                  </label>
                ))}
              </div>
            </div>
            {error.othername && (
              <div className="form-error w-390">
                <p className="form-error-message w-190 othername">{error.othername}</p>
              </div>
            )}
          </div>
        </div>
        <div className={"modal-footer"}>
          <button
            data-test-id="button-column-editor-cancel"
            type="button"
            className="btn btn-gray"
            onClick={() => closeHandler()}
          >
            {t("cancel")}
          </button>
          <SubmitButton
            data-test-id="button-column-editor-save"
            onClick={this.handleSave}
            loading={this.state.updating}
          />
        </div>
      </Modal>
    );
  }
}

ProtectionBundleEditor.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
};

export default withTranslation()(ProtectionBundleEditor);
