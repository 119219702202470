import { connect } from "react-redux";

import actions from "../../actions";
import ScheduleRightRowEneosKawasaki from "../../components/list/ScheduleRightRowEneosKawasaki";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateItem: (data, handleSuccess, handleCancel) =>
      dispatch(actions.schedule_eneos_kawasaki.updateItem(data, handleSuccess, handleCancel)),
    showAlert: (title, messages, okClickHandler) => dispatch(actions.app.showAlert(title, messages, okClickHandler)),
    hideAlert: () => dispatch(actions.app.hideAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleRightRowEneosKawasaki);
