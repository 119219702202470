import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { useTranslation, withTranslation } from "react-i18next";

import * as util from "../../lib/matrix";
import Modal from "../Modal";
import ChargeSelect from "../common/ChargeSelect";
import Select from "../common/Select";

import { ConstructionMasters } from "@/models/masters";
import { MatrixSearchState } from "@/reducers/matrixSearch";

type Props = {
  search: () => void;
  cancelHandler: (_) => void;
  closeHandler: () => void;
};

type DispatchProps = {
  masters: ConstructionMasters;
  searchParams: MatrixSearchState;
  changeArea: (_) => void;
  changeDevice: (_) => void;
  changeCategory: (_) => void;
  changeFacilityManagement: (_) => void;
  changeConstructionManagement: (_) => void;
  changePrimaryCharge: (_) => void;
  changeOther: (_) => void;
  changeSystem: (_) => void;
  changeCompany: (_) => void;
  changeUser: (_) => void;
  changeGroup: (_) => void;
  changeProcessMiddleClass: (_) => void;
  changeItemText: (_) => void;
  changeProcessText: (_) => void;
  changeRegulation: (_) => void;
  changeFilter: (_) => void;
  clearConditions: () => void;
  showAlert: (title, message) => void;
};

const SearchPanel: React.FC<Props> = (props: Props & DispatchProps) => {
  const { t } = useTranslation();

  const handleSearch = (e) => {
    e.preventDefault();
    if (!searchParams.primaryChargeId || searchParams.primaryChargeId === 0) {
      props.showAlert(t("error"), [t("select_primary_charge")]);
    } else {
      props.search();
      props.closeHandler();
    }
  };

  const {
    masters,
    searchParams,
    changeArea,
    changeDevice,
    changeCategory,
    changeFacilityManagement,
    changeConstructionManagement,
    changePrimaryCharge,
    changeOther,
    changeSystem,
    changeItemText,
    changeProcessText,
    changeRegulation,
    changeFilter,
    changeUser,
    changeGroup,
    changeCompany,
    changeProcessMiddleClass,
  } = props;

  const primaryChargesForCategory = useMemo(() => {
    const categoryPrimaryCharge = masters.categories_primary_charges.find(
      (category) => category.category_id === searchParams.categoryId
    );
    return categoryPrimaryCharge?.primary_charges ?? [];
  }, [masters.categories_primary_charges, searchParams.categoryId]);

  useEffect(() => {
    if (!primaryChargesForCategory.some((pc) => pc.primary_charge_id === searchParams.primaryChargeId)) {
      changePrimaryCharge(primaryChargesForCategory[0]?.primary_charge_id ?? 0);
    }
  }, [changePrimaryCharge, primaryChargesForCategory, searchParams.primaryChargeId]);

  return (
    <Modal title={t("matrix_search")} closeHandler={props.closeHandler}>
      <div className="modal-body w-880 clearfix">
        <div className="form-row">
          <div className="form-group w-360">
            <span className="form-label">{t("machines_category")}</span>
            <div className="d-ib ta-l w-250">
              <Select
                prefix="category"
                options={masters.categories}
                value={searchParams.categoryId}
                onChange={changeCategory}
              />
            </div>
          </div>
          <div className="form-group w-260">
            <span className="form-label">{t("primary_charge")}</span>
            <div className="d-ib ta-l w-170">
              <Select
                prefix="primary_charge"
                options={primaryChargesForCategory}
                value={searchParams.primaryChargeId}
                onChange={changePrimaryCharge}
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group w-280">
            <span className="form-label">{t("area")}</span>
            <div className="d-ib ta-l w-170">
              <Select
                prefix="area"
                options={masters.areas}
                value={searchParams.areaIds}
                onChange={changeArea}
                isMulti={true}
                withNone
              />
            </div>
          </div>
          <div className="form-group w-260">
            <span className="form-label">{t("device")}</span>
            <div className="d-ib ta-l w-170">
              <Select
                prefix="device"
                options={masters.devices}
                value={searchParams.deviceIds}
                onChange={changeDevice}
                isMulti={true}
                withNone
              />
            </div>
          </div>
          <div className="form-group w-280">
            <span className="form-label">{t("facility_management")}</span>
            <div className="d-ib ta-l w-170">
              <Select
                prefix="facility_management"
                options={masters.facility_managements}
                value={searchParams.facilityManagementIds}
                onChange={changeFacilityManagement}
                isMulti={true}
                withNone
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group w-280">
            <span className="form-label">{t("construction_management")}</span>
            <div className="d-ib ta-l w-170">
              <Select
                prefix="construction_management"
                options={masters.construction_managements}
                value={searchParams.constructionManagementIds}
                onChange={changeConstructionManagement}
                isMulti={true}
                withNone
              />
            </div>
          </div>
          <div className="form-group w-260">
            <span className="form-label">{t("other")}</span>
            <div className="d-ib ta-l w-170">
              <Select
                prefix="other"
                options={masters.others}
                value={searchParams.otherIds}
                onChange={changeOther}
                isMulti={true}
                withNone
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group w-280">
            <span className="form-label">{t("company")}</span>
            <div className="d-ib ta-l w-170">
              <Select
                prefix="company"
                options={masters.companies}
                value={searchParams.companyIds}
                onChange={changeCompany}
                isMulti={true}
              />
            </div>
          </div>
          <div className="form-group w-260">
            <span className="form-label">{t("matrix_search_middle")}</span>
            <div className="d-ib ta-l w-170">
              <Select
                prefix="process_middle_class"
                options={masters.process_middle_classes}
                value={searchParams.processMiddleClassIds}
                onChange={changeProcessMiddleClass}
                isMulti={true}
              />
            </div>
          </div>
          <div className="form-group w-280">
            <span className="form-label">{t("law")}</span>
            <input
              data-test-id="text-search-panel-regulation"
              type="text"
              className="form-control w-170"
              value={searchParams.regulation}
              onChange={(e) => changeRegulation(e.target.value)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group w-360">
            <span className="form-label">{t("system")}</span>
            <div className="d-ib ta-l w-250">
              <Select
                prefix="system"
                options={masters.systems}
                value={searchParams.systemIds}
                onChange={changeSystem}
                isMulti={true}
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group w-360">
            <span className="form-label">{t("assignee")}</span>
            <div className="d-ib ta-l w-250">
              <ChargeSelect
                masters={masters}
                userId={searchParams.userIds}
                groupId={searchParams.groupIds}
                isMulti={true}
                onChange={(e) => {
                  const users = e ? e.filter((d) => d.category === "user") : [];
                  const groups = e ? e.filter((d) => d.category === "group") : [];

                  changeUser(users.map((u) => u.value));
                  changeGroup(groups.map((g) => g.value));
                }}
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group w-540">
            <span className="form-label">{t("title")}</span>
            <input
              data-test-id="text-search-panel-item-name"
              type="text"
              className="form-control w-430"
              value={searchParams.itemText}
              onChange={(e) => changeItemText(e.target.value)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group w-540">
            <span className="form-label">{t("process_title")}</span>
            <input
              data-test-id="text-search-panel-process-name"
              type="text"
              className="form-control w-430"
              value={searchParams.processText}
              onChange={(e) => changeProcessText(e.target.value)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group ml-100">
            <label data-test-id="checkbox-search-panel-coming" className="ckbox">
              <input type="checkbox" checked={_.includes(searchParams.filters, 1)} onChange={() => changeFilter(1)} />
              <span>{t("coming")}</span>
            </label>
            <label data-test-id="checkbox-search-panel-expired" className="ckbox">
              <input type="checkbox" checked={_.includes(searchParams.filters, 2)} onChange={() => changeFilter(2)} />
              <span>{t("expired")}</span>
            </label>
            <label data-test-id="checkbox-search-panel-assigned" className="ckbox">
              <input type="checkbox" checked={_.includes(searchParams.filters, 3)} onChange={() => changeFilter(3)} />
              <span>{t("assigned")}</span>
            </label>
            <label data-test-id="checkbox-search-panel-not-completed" className="ckbox">
              <input type="checkbox" checked={_.includes(searchParams.filters, 4)} onChange={() => changeFilter(4)} />
              <span>{t("not_completed")}</span>
            </label>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          data-test-id="button-search-panel-cancel"
          type="button"
          className="btn btn-gray"
          onClick={props.cancelHandler}
        >
          {t("cancel")}
        </button>
        <button
          data-test-id="button-search-panel-clear"
          type="button"
          className="btn btn-gray"
          onClick={() => {
            util.removeItemIdParam();
            props.clearConditions();
          }}
        >
          {t("clear")}
        </button>
        <button
          data-test-id="button-search-panel-search"
          type="button"
          className="btn btn-blue"
          onClick={(e) => {
            util.removeItemIdParam();
            util.removeProcessIdParam();
            handleSearch(e);
          }}
        >
          {t("search")}
        </button>
      </div>
    </Modal>
  );
};

export default withTranslation()(SearchPanel);
