import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Modal from "../Modal";
import { SubmitButton } from "@/components/common/SubmitButton";

class SpecialProcessBundleEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      special_processes: [],

      error: {},
    };

    this.handleChangeProcess = this.handleChangeProcess.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.props.setUnloadAlert();
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();
  }

  handleChangeProcess(id, checked) {
    if (checked) {
      this.setState({ special_processes: [...this.state.special_processes, id] });
    } else {
      this.setState({ special_processes: this.state.special_processes.filter((item) => item != id) });
    }
  }

  handleSave() {
    if (this.state.updating) return;
    const { params, itemId, t } = this.props;
    const { special_processes } = this.state;

    const data = {
      category_id: params.categoryId,
      user_id: params.userIds,
      group_id: params.groupIds,
      area_id: params.areaIds,
      device_id: params.deviceIds,
      facility_management_id: params.facilityManagementIds,
      construction_management_id: params.constructionManagementIds,
      primary_charge_id: params.primaryChargeIds,
      other_id: params.otherIds,
      system_id: params.systemIds,
      process_middle_class_id: params.processMiddleClassIds,
      company_id: params.companyIds,
      item_text: params.itemText,
      process_text: params.processText,
      regulation: params.regulation,
      filter: params.filters,
      special_processes,
    };

    if (itemId > 0) {
      data.item_id = itemId;
    }

    this.setState({ updating: true });
    this.props.onSave(
      data,
      () => {
        this.setState({ updating: false });
        this.props.closeHandler();
        this.props.handleSearch();
      },
      () => this.setState({ updating: false }),
      () => this.setState({ updating: false })
    );
  }

  render() {
    const { closeHandler, t } = this.props;

    const specialProcesses = [
      { id: 1, name: "high_place_work" },
      { id: 2, name: "heavy_machinery_work" },
      { id: 3, name: "dust_work" },
      { id: 4, name: "radiation_work" },
      { id: 5, name: "inert_work" },
      { id: 6, name: "noisy_work" },
      { id: 7, name: "asbestos_handling_work" },
    ];

    return (
      <Modal title={`${t("special_work")} ${t("update_bulk")}`} closeHandler={() => closeHandler()}>
        <div className="modal-body">
          <div className="form-row mb-0">
            <div className="form-group w-400 txt-center">
              <div className="ckbox-vertically w-280">
                {specialProcesses.map((process, index) => (
                  <label key={index} className="ckbox mr-0">
                    <input
                      type="checkbox"
                      value={process.id || undefined}
                      checked={this.state.special_processes.some((value) => value === process.id)}
                      onChange={(e) => this.handleChangeProcess(process.id, e.target.checked)}
                    />
                    <span>{t(process.name)}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={"modal-footer"}>
          <button
            data-test-id="button-column-editor-cancel"
            type="button"
            className="btn btn-gray"
            onClick={() => closeHandler()}
          >
            {t("cancel")}
          </button>
          <SubmitButton
            data-test-id="button-column-editor-save"
            onClick={this.handleSave}
            loading={this.state.updating}
          />
        </div>
      </Modal>
    );
  }
}

SpecialProcessBundleEditor.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
};

export default withTranslation()(SpecialProcessBundleEditor);
