import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/sx-layout/common/Button";

type Props = {
  onCancel: () => void;
};

export const CustomKeepOutAreaTool: FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <div
      id="text-tool"
      className="flex items-center grow mx-2 h-[100%] border-solid border-gray-400 border-[1px] rounded bg-white"
    >
      <p className="mx-3 text-[#dea321] text-[14px] whitespace-nowrap">{t("drawing_custom_area")}</p>
      <Button className="btn-gray ml-[150px] mr-2 p-[4px] min-w-[80px]" onClick={props.onCancel}>
        {t("cancel")}
      </Button>
    </div>
  );
};
