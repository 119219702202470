import { connect } from "react-redux";

import actions from "../actions";
import Contents from "../components/Contents";
import storageManager from "../lib/storageManager";

const mapStateToProps = (state) => {
  return {
    menu: state.app.menu,
    activeMenu: state.app.activeMenu,
    visibleConstructionSelector: state.app.visibleConstructionSelector,
    visiblePasswordEditor: state.app.visiblePasswordEditor,
    visibleLangSelector: state.app.visibleLangSelector,
    toastContents: state.app.toastContents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    contentsReady: () => dispatch(actions.app.contentsReady()),
    showConstructionSelector: () => dispatch(actions.app.showConstructionSelector()),
    fetchConstructions: (callback) => dispatch(actions.construction.fetchItems(callback)),
    fetchGlobalData: () => {
      dispatch(actions.app.fetchMenus());
      dispatch(actions.construction.fetchMasters());
      dispatch(actions.construction.fetchProgressRate());
      dispatch(actions.construction.fetchItems());
      dispatch(actions.app.fetchRoles());
      dispatch(
        actions.construction.fetchExtensions((response) => {
          const extension = response.extensions.filter((item) => item.extension_type === 12).shift();
          if (extension) {
            dispatch(actions.construction.fetchLinkageTasks());
          }
        })
      );
    },
    fetchPollingGlobalData: () => {
      dispatch(actions.notifications.fetchNotificationsSummary());
    },
    logout: (cid) => {
      storageManager.removeConstruction(cid);
      dispatch(actions.login.logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contents);
