import { ClassValue } from "clsx";
import { ComponentProps, FC } from "react";

import { cn } from "@/lib/classNames";

export type ModalBodyProps = Omit<ComponentProps<"div">, "className"> & {
  size?: "small" | "normal";
  className?: ClassValue;
};

export const ModalBody: FC<ModalBodyProps> = ({ className, size = "normal", ...restProps }) => {
  return (
    <div
      className={cn(
        "flex justify-center items-center min-h-[100px] text-[13px]",
        size === "small" ? "min-w-[400px] p-[20px]" : "min-w-[500px] p-[40px]",
        className
      )}
      {...restProps}
    />
  );
};
