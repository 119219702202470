import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";

import styles from "../../constants/reactSelectStyle";

const SearchConditionItem = (props) => {
  const { labelName, className, selectClassName, options, onChange, value, isMulti, isClearable, hide } = props;

  if (hide) {
    return null;
  }

  const defaultClassName = "form-group";
  const defaultSelectClassName = "d-ib ta-l";
  const rootClassName = className ? `${defaultClassName} ${className}` : defaultClassName;
  const itemClassName = selectClassName ? `${selectClassName} ${defaultSelectClassName}` : defaultSelectClassName;

  const label = labelName ? <span className="form-label">{labelName}</span> : null;

  return (
    <div className={rootClassName}>
      {label}
      <div className={itemClassName}>
        <Select
          styles={styles}
          isMulti={isMulti}
          options={options}
          onChange={onChange}
          value={value}
          isClearable={isClearable}
        />
      </div>
    </div>
  );
};

SearchConditionItem.propTypes = {
  labelName: PropTypes.string,
  className: PropTypes.string,
  selectClassName: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  hide: PropTypes.bool,
};

export default SearchConditionItem;
