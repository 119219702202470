import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContinuousUpdateStatus } from "@/components/list/Schedule/constants";
import { ContinuousUpdateStatusType } from "@/components/list/Schedule/types";

type Props = {
  value: ContinuousUpdateStatusType;
  onChange: (_: ContinuousUpdateStatusType) => void;
};

export const ScheduleStatusSwitcher: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const switcherStatusList = [
    { value: ContinuousUpdateStatus.OFF, label: `${t("status_change")} OFF` },
    { value: ContinuousUpdateStatus.CANCEL, mark: "ー", label: `（${t("schedule_status_cancel")}）` },
    { value: ContinuousUpdateStatus.DO, mark: "●", label: `（${t("schedule_status_do")}）` },
    { value: ContinuousUpdateStatus.APPROVE, mark: "△", label: `（${t("schedule_status_approve")}）` },
    { value: ContinuousUpdateStatus.DENY, mark: "✕", label: `（${t("schedule_status_deny")}）` },
  ];

  return (
    <div className="btn-dropdown-area" onMouseLeave={() => setOpen(false)}>
      <button
        data-test-id="button-status-switcher"
        className={`btn btn-dropdown ${value === ContinuousUpdateStatus.OFF ? "btn-gray" : "btn-blue"}`}
        onClick={() => setOpen(true)}
      >
        {t("status_change")}：
        {value === ContinuousUpdateStatus.OFF ? (
          <span>OFF</span>
        ) : (
          <span className="status-mark">{switcherStatusList.find((v) => v.value === value)?.mark}</span>
        )}
      </button>
      {open && (
        <ul className="dropdown-menu" onClick={() => setOpen(false)}>
          {switcherStatusList.map((item, index) => {
            if (value === item.value) {
              return null;
            }

            return (
              <li key={index} onClick={() => onChange(item.value)}>
                <span style={{ display: "flex" }}>
                  <span className="status-mark">{item.mark}</span>
                  {item.label}
                </span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
