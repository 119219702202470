import CloseButton from "@/sx-layout/components/notification/components/DrawerHeader/CloseButton";
import Title from "@/sx-layout/components/notification/components/DrawerHeader/Title";

type Props = {
  isLoading?: boolean;
  onRefreshPress: () => void;
  onClosePress: () => void;
};

const DrawerHeader: React.FC<Props> = ({ isLoading, onRefreshPress, onClosePress }) => {
  return (
    <div id="drawer-header">
      <div className="flex-1">
        <Title isLoading={isLoading} onRefreshPress={onRefreshPress} />
      </div>
      <div className="flex-none">
        <CloseButton onClick={onClosePress} />
      </div>
    </div>
  );
};

export default DrawerHeader;
