import { ClassValue } from "clsx";
import { ComponentProps, forwardRef } from "react";

import { cn } from "@/lib/classNames";

export type InputTextProps = Omit<ComponentProps<"input">, "className"> & {
  size?: "normal";
  className?: ClassValue;
};

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  ({ size = "normal", className, disabled, ...restProps }, ref) => {
    return (
      <input
        ref={ref}
        type="text"
        className={cn(
          "px-[8px] py-[4px] border border-[#000] rounded",
          size === "normal" && "w-[300px]",
          disabled && "bg-[#e9ecef]",
          className
        )}
        disabled={disabled}
        {...restProps}
      />
    );
  }
);

InputText.displayName = "InputText";
