import { connect } from "react-redux";

import actions from "../actions";
import PasswordEditor from "../components/PasswordEditor";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => dispatch(actions.app.hidePasswordEditor()),
    save: (oldPassword, newPassword, okHandler, ngHandler) =>
      dispatch(actions.app.updatePassword(oldPassword, newPassword, okHandler, ngHandler)),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordEditor);
