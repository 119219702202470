import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";

import actions from "@/actions";
import storageManager from "@/lib/storageManager";
import { UnworkDaySearchParams } from "@/models/holiday";

export const useSearchInput = () => {
  const dispatch = useDispatch();

  const LOCAL_STORAGE_KEY = "unworkDaysSearchParams";

  const [categoryIds, setCategoryIds] = useState<number[]>([]);
  const [primaryChargeIds, setPrimaryChargeIds] = useState<number[]>([]);
  const [areaIds, setAreaIds] = useState<number[]>([]);
  const [deviceIds, setDeviceIds] = useState<number[]>([]);
  const [facilityManagementIds, setFacilityManagementIds] = useState<number[]>([]);
  const [constructionManagementIds, setConstructionManagementIds] = useState<number[]>([]);

  const endLoading = useMemo(() => _.debounce(() => dispatch(actions.holiday.endLoading()), 300), []);

  useEffect(() => {
    const stored = storageManager.getConstructionItem(LOCAL_STORAGE_KEY);
    if (stored) {
      const parsed = JSON.parse(stored);
      setCategoryIds(parsed.category_id ?? []);
      setPrimaryChargeIds(parsed.primary_charge_id ?? []);
      setAreaIds(parsed.area_id ?? []);
      setDeviceIds(parsed.device_id ?? []);
      setFacilityManagementIds(parsed.facility_management_id ?? []);
      setConstructionManagementIds(parsed.construction_management_id ?? []);
      dispatch(
        actions.holiday.fetchUnworkDays(
          parsed,
          () => endLoading(),
          () => endLoading()
        )
      );
    } else {
      endLoading();
    }

    return () => {
      // 画面を離れるときにfetchingフラグを初期化しておいて検索中に「該当するデータがありません」が出ないようにする
      dispatch(actions.holiday.startLoading());
    };
  }, []);

  const fetchHolidays = (params: UnworkDaySearchParams) => {
    dispatch(actions.holiday.startLoading());
    storageManager.setConstructionItem(LOCAL_STORAGE_KEY, JSON.stringify(params));
    dispatch(
      actions.holiday.fetchUnworkDays(
        params,
        () => endLoading(),
        () => endLoading()
      )
    );
  };

  const reset = () => {
    fetchHolidays({
      construction_id: [],
      category_id: [],
      primary_charge_id: [],
      area_id: [],
      device_id: [],
      facility_management_id: [],
      construction_management_id: [],
    });
    setCategoryIds([]);
    setPrimaryChargeIds([]);
    setAreaIds([]);
    setDeviceIds([]);
    setFacilityManagementIds([]);
    setConstructionManagementIds([]);
  };

  const search = () => {
    fetchHolidays({
      category_id: categoryIds,
      primary_charge_id: primaryChargeIds,
      area_id: areaIds,
      device_id: deviceIds,
      facility_management_id: facilityManagementIds,
      construction_management_id: constructionManagementIds,
    });
  };

  return {
    categoryIds,
    primaryChargeIds,
    areaIds,
    deviceIds,
    facilityManagementIds,
    constructionManagementIds,

    setCategoryIds,
    setPrimaryChargeIds,
    setAreaIds,
    setDeviceIds,
    setFacilityManagementIds,
    setConstructionManagementIds,

    reset,
    search,
  };
};
