const restoreState = (): { [key: string]: any } => {
  const hash = location.hash;
  const pattern = /#\/(\d+)\/(\w+)(\?(.+))?/;
  const matches = hash.match(pattern);
  const result = {};

  if (matches) {
    result.isMatch = true;
    result.constructionId = Number(matches[1]);
    result.menu = matches[2];
    result.hasQuery = false;

    if (matches[4] !== undefined) {
      const query = matches[4];
      const params = query.split("&");
      result.hasQuery = true;

      params.forEach((param) => {
        const keyValue = param.split("=");

        if (keyValue.length === 2) {
          result[keyValue[0]] = keyValue[1];
        }
      });
    }
  } else {
    return false;
  }

  return result;
};

export default restoreState;
