export type Notification = {
  layout_notification_id: number;
  notification_type_id: number;
  notification_type_name: string;
  notification_date: string;
  read_date: string;
  information: {
    No: number;
    重機名: string;
    作業完了日時: string;
  };
  machine_id: number;
  machine_icon_id: number;
};

export type NotificationList = {
  total_num: number;
  list: Notification[];
};

export const LAYOUT_NOTIFICATION_TYPE_COMPLETION_REPORT = 1 as const;

export type LayoutNotificationType = typeof LAYOUT_NOTIFICATION_TYPE_COMPLETION_REPORT;

export type LayoutNotificationFetchedNotificationLargestId = {
  [key in LayoutNotificationType]: number;
};
