import { connect } from "react-redux";

import actions from "../../actions";
import System from "../../components/system/SystemEditor";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateItem: (data, handleSuccess, handleCancel) =>
      dispatch(actions.system.updateItem(data, handleSuccess, handleCancel)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(System);
