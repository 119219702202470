import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class LeftGridHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.isWide === nextProps.isWide) {
      return false;
    }

    return true;
  }

  render() {
    const { t } = this.props;

    return (
      <table className="grid-table grid-table-header grid-table-left grid-table-left-header">
        <thead>
          <tr>
            <th>
              <span className="grid-cell-narrow">No</span>
              <button
                data-test-id="button-matrix-collapse"
                className="grid-btn-collapse"
                onClick={this.props.toggleWide}
              >
                {this.props.isWide ? "▶︎" : "◀︎"}
              </button>
            </th>
            <th className="rotate">
              <span className="grid-cell-narrow">{t("delivery")}</span>
            </th>
            <th className="rotate">
              <span className="grid-cell-narrow">{t("complete_process")}</span>
            </th>
            <th className="rotate">
              <span className="grid-cell-narrow">{t("airtight_preparation")}</span>
            </th>
            <th>{t("area")}</th>
            <th>{t("device")}</th>
            <th>{t("primary_charge")}</th>
            <th>{t("construction_specification")}No</th>
            <th>{t("title")}</th>
            <th>{t("law")}</th>
            <th>{t("system")}</th>
            <th>{t("note")}</th>
            {!this.props.isWide && this.props.displayRemarks && (
              <th style={{ maxWidth: 115, minWidth: 115 }}>{t("remarks")}</th>
            )}
            <th style={{ maxWidth: 60, minWidth: 60 }}>{t("assignee")}</th>
          </tr>
          <tr className="grid-separator-bg">
            <td className="grid-separator"></td>
            <td className="grid-separator"></td>
            <td className="grid-separator"></td>
            <td className="grid-separator"></td>
            <td className="grid-separator"></td>
            <td className="grid-separator"></td>
            <td className="grid-separator"></td>
            <td className="grid-separator"></td>
            <td className="grid-separator"></td>
            <td className="grid-separator"></td>
            <td className="grid-separator"></td>
            <td className="grid-separator"></td>
            {!this.props.isWide && this.props.displayRemarks && (
              <td style={{ minWidth: 115, maxWidth: 115 }} className="grid-separator"></td>
            )}
            <td style={{ minWidth: 60, maxWidth: 60 }} className="grid-separator"></td>
          </tr>
        </thead>
      </table>
    );
  }
}

LeftGridHeader.propTypes = {
  isWide: PropTypes.bool.isRequired,
  toggleWide: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(LeftGridHeader);
