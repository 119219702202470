import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePopper } from "react-popper";

import { KeepOutAreaPolygon, Rectangle } from "@/sx-layout/components/plotmap/models";

type Props = {
  area: KeepOutAreaPolygon;
  mapRef: HTMLDivElement;
  referenceElement: HTMLElement;
  rect: Rectangle;
  scale: number;
  control: {
    isPast: boolean;
    isAdmin: boolean;
    isUser: boolean;
    isAreaFromOtherCompany: boolean;
  };
  onEdit: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onDuplicate: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onDelete: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export const CustomKeepOutAreaSubMenu: FC<Props> = ({
  area,
  mapRef,
  referenceElement,
  rect,
  scale,
  control,
  onEdit,
  onDuplicate,
  onDelete,
}) => {
  const { t } = useTranslation();

  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "right",
  });

  const [translateX, translateY, transformOrigin] = useMemo(() => {
    // サブメニューの位置調整
    const margin = 20;
    const menuWidth = 140;
    const menuHeight = control.isPast ? 38 : 104;
    const menuRight = rect.x + rect.width + margin + menuWidth;

    return [
      menuRight < mapRef.clientWidth ? rect.x + rect.width + margin : rect.x - menuWidth - margin,
      rect.y + rect.height / 2 - menuHeight / 2,
      menuRight < mapRef.clientWidth ? "left" : "right",
    ];
  }, [area, rect, scale, control.isPast, mapRef]);

  const isUserAndOwnCompany = control.isUser && !control.isAreaFromOtherCompany;
  if (!(control.isAdmin || isUserAndOwnCompany)) {
    return <></>;
  }

  return (
    area && (
      <div
        id="layout-target-submenu"
        className="z-10"
        style={{
          ...styles.popper,
          transform: `translate(${translateX}px, ${translateY}px) scale(${scale})`,
          transformOrigin: transformOrigin,
        }}
        ref={setPopperElement}
        {...attributes.popper}
      >
        {/* Konvaのオブジェクトに対して制御が難しいので吹き出しはとりあえず表示しない */}
        {/*<div id="layout-target-submenu-arrow" data-popper-arrow />*/}
        <div className="bg-[#f3f2ef] p-[5px] w-[140px] font-bold rounded-[5px]">
          <ul id={`area-submenu-${area.keepout_area_id}`} className="bg-[#f3f2ef]">
            {!control.isPast && (
              <li
                onClick={onEdit}
                className="bg-[#fff] hover:bg-[#fff8ee] cursor-pointer rounded flex items-center justify-center p-[5px] text-[13px] mb-[5px]"
              >
                {t("edit")}
              </li>
            )}
            <li
              className={
                `bg-[#fff] hover:bg-[#fff8ee] cursor-pointer rounded flex items-center justify-center p-[5px] text-[13px]` +
                (control.isPast ? "" : " mb-[5px]")
              }
            >
              <button className="w-[100%]" onClick={onDuplicate}>
                {t("duplicate")}
              </button>
            </li>
            {!control.isPast && (
              <li
                onClick={onDelete}
                className="bg-[#ffdddd] hover:bg-[#f8d0d0] cursor-pointer rounded flex items-center justify-center p-[5px] text-[13px]"
              >
                {t("delete")}
              </li>
            )}
          </ul>
        </div>
      </div>
    )
  );
};
