import { ClassValue } from "clsx";
import { ComponentProps, FC } from "react";

import { cn } from "@/lib/classNames";

export type ModalHeaderProps = Omit<ComponentProps<"h3">, "className"> & {
  className?: ClassValue;
};

export const ModalHeader: FC<ModalHeaderProps> = ({ className, ...restProps }) => {
  return (
    <h3
      className={cn("flex items-center justify-center text-[15px] p-[15px] font-bold bg-white", className)}
      {...restProps}
    />
  );
};
