import anime from "animejs";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import ColumnEditorContainer from "../../containers/matrix/ColumnEditorContainer";
import ItemEditorChibaContainer from "../../containers/matrix/ItemEditorChibaContainer";
import ItemEditorContainer from "../../containers/matrix/ItemEditorContainer";
import MiddleClassEditorContainer from "../../containers/matrix/MiddleClassEditorContainer";
import ProtectionBundleEditorContainer from "../../containers/matrix/ProtectionBundleEditorContainer";
import PulldownExportContainer from "../../containers/matrix/PulldownExportContainer";
import PulldownStatusContainer from "../../containers/matrix/PulldownStatusContainer";
import RightGridHeaderContainer from "../../containers/matrix/RightGridHeaderContainer";
import SearchPanelContainer from "../../containers/matrix/SearchPanelContainer";
import SpecialProcessBundleEditorContainer from "../../containers/matrix/SpecialProcessBundleEditorContainer";
import TaskEditorContainer from "../../containers/matrix/TaskEditorContainer";
import TooltipContainer from "../../containers/matrix/TooltipContainer";
import WorkEditorContainer from "../../containers/matrix/WorkEditorContainer";
import * as commonUtil from "../../lib/common";
import restoreState from "../../lib/restoreState";
import { isValid, isValidRole } from "../../lib/roleChecker";
import storageManager from "../../lib/storageManager";
import Modal from "../Modal";
import StatusSwitcher from "../common/StatusSwitcher";

import Help from "./Help";
import LeftGridBody from "./LeftGridBody";
import LeftGridHeader from "./LeftGridHeader";
import LeftGridHeaderChiba from "./LeftGridHeaderChiba";
import RightGridBody from "./RightGridBody";

import ConfirmDialog from "@/components/ConfirmDialog";
import DownloadScheduleModal from "@/components/matrix/DownloadScheduleModal";
import SortEditor from "@/components/matrix/SortEditor";
import WorkCreateEditorContainer from "@/containers/matrix/WorkCreateEditorContainer";
import { TaskScheduleType, TaskSwitcherStatus } from "@/models/tasks";
import format from "date-fns/format";
import { SegmentSettingModal } from "@/components/matrix/SegmentSettingModal";

class Matrix extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSearchPanel: this.isInitialView(),
      activeButton: "",
      openDownloadScheduleModal: false,
      showMiddleClassEditor: false,
      showColumnEditor: false,
      showTaskEditor: false,
      showItemEditor: false,
      showCreateWorkEditor: false,
      showSortEditor: false,
      showWorkEditor: false,
      showColumnDeleteDialog: false,
      deleteProcess: null,
      showApprovalEditor: false,
      showSpecialBundleEditor: false,
      showProtectBundleEditor: false,
      showHelp: false,
      wide: false, // TODO 反対の意味で使われている
      limit: 20,
      start: 1,
      end: false,
      editingProcessMiddleClassId: 0,
      editingProcessId: 0,
      editingItemId: 0,
      editingTaskId: 0,
      sortItemId: 0,
      tooltip: null,
      tooltipChiba: null,
      downloadUrl: "",
      downloadName: "",
      validRequired: false,
      initialSearched: false,
      offset: 0,
      maxLength: 60,
      showStatusBulkUpdate: false,
      statusBulkUpdateValue: null,
      statusBulkUpdateNum: null,
      loadingTaskUpdate: false,
      scheduleType: null,
      bundleParams: [],
      currentPage: 1,
      totalNum: 0,
      maxPage: 0,
      backParams: null,
      tmpLimit: 20,
      startPos: 1,
      isStatus: false,
      headerMessage: "",
      processIdSplitScheduleModal: null,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.showWorkEditor = this.showWorkEditor.bind(this);
    this.showCreateWorkEditor = this.showCreateWorkEditor.bind(this);
    this.setTooltip = this.setTooltip.bind(this);
    this.setTooltipChiba = this.setTooltipChiba.bind(this);
    this.download = this.download.bind(this);
    this.showSearchPanel = this.showSearchPanel.bind(this);
    this.isInitialView = this.isInitialView.bind(this);
    this.clearSubmenu = this.clearSubmenu.bind(this);
    this.showStatusBulkUpdatePanel = this.showStatusBulkUpdatePanel.bind(this);
    this.fetchStatusUpdateNum = this.fetchStatusUpdateNum.bind(this);
    this.closeStatusBulkUpdatePanel = this.closeStatusBulkUpdatePanel.bind(this);
    this.handleBulkUpdate = this.handleBulkUpdate.bind(this);
    this.showSpecialProcessBundle = this.showSpecialProcessBundle.bind(this);
    this.hideSpecialProcessBundle = this.hideSpecialProcessBundle.bind(this);
    this.showProtectionBundle = this.showProtectionBundle.bind(this);
    this.hideProtectionBundle = this.hideProtectionBundle.bind(this);
    this.handleClickAddColumnContextMenu = this.handleClickAddColumnContextMenu.bind(this);
    this.handleClickDeleteColumnContextMenu = this.handleClickDeleteColumnContextMenu.bind(this);
    this.handleClickContextMenu = this.handleClickContextMenu.bind(this);
    this.handleClickReceiverContextMenu = this.handleClickReceiverContextMenu.bind(this);
    this.handleClickSplitScheduleContextMenu = this.handleClickSplitScheduleContextMenu.bind(this);
    this.showDownloadScheduleModal = this.showDownloadScheduleModal.bind(this);
    this.hideDownloadScheduleModal = this.hideDownloadScheduleModal.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.state.showTaskEditor && nextState.showTaskEditor);
  }

  componentDidMount() {
    if (this.isInitialView()) {
      return;
    }

    this.props.changeSwitcherStatus(-1);

    if (this.props.validRequired) {
      this.handleSearch();
    }
    if (localStorage.getItem("limit")) {
      this.setState({ limit: parseInt(localStorage.getItem("limit")) });
    }
    // AM1:00〜5:00に予定未確定のメッセージを表示する
    const now = new Date();
    if (1 <= now.getHours() && now.getHours() < 5) {
      const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      this.setState({ headerMessage: format(tomorrow, this.props.t("schedule_is_not_confirmed")) });
    }

    // 件名のコンテキストメニュー表示制御
    window.addEventListener("click", this.clearSubmenu);
  }

  componentWillUnmount() {
    this.props.revertLocalCondition();

    window.removeEventListener("click", this.clearSubmenu);
  }

  componentDidUpdate(prevProps) {
    const { validRequired } = this.props;
    if (typeof validRequired === "undefined") {
      return;
    }
    if (typeof prevProps.validRequired === "undefined" && validRequired) {
      this.handleSearch();
    } else if (typeof prevProps.validRequired === "undefined" && !validRequired) {
      this.showSearchPanel();
    }
    const isGridDisplayed = this.rightHeaderContainer && this.rightBodyContainer;
    if (this.props.dragScroll?.startsWith("left") && isGridDisplayed) {
      const toLeft = this.rightHeaderContainer.scrollLeft - 60;
      this.rightHeaderContainer.scrollLeft = toLeft;
      this.rightBodyContainer.scrollLeft = toLeft;
    }
    if (this.props.dragScroll?.startsWith("right") && isGridDisplayed) {
      const toRight = this.rightHeaderContainer.scrollLeft + 60;
      this.rightHeaderContainer.scrollLeft = toRight;
      this.rightBodyContainer.scrollLeft = toRight;
    }
  }

  isInitialView() {
    const urlState = restoreState();

    if (this.props.inheritingSearchConditions || urlState.item_id) {
      return false;
    }

    return storageManager.getConstructionItem("matrixSearchParams") === null;
  }

  clearSubmenu() {
    if (this.props.titleSubmenuItemId) {
      this.props.clearTitleSubmenu();
    }
    if (this.props.processSubmenu) {
      this.props.clearProcessSubmenu();
    }
  }

  showDropdown(name) {
    this.setState({ activeButton: name });
  }

  hideDropdown() {
    this.setState({ activeButton: "" });
  }

  showSearchPanel() {
    this.setState({ showSearchPanel: true });
  }

  hideSearchPanel() {
    this.setState({ showSearchPanel: false });
  }

  showDownloadScheduleModal() {
    this.setState({ openDownloadScheduleModal: true });
  }

  hideDownloadScheduleModal() {
    this.setState({ openDownloadScheduleModal: false });
  }

  showMiddleClassEditor(processMiddleClassId) {
    this.setState({
      showMiddleClassEditor: true,
      editingProcessMiddleClassId: processMiddleClassId,
    });
  }

  hideMiddleClassEditor() {
    this.setState({ showMiddleClassEditor: false });
  }

  showColumnEditor(processId) {
    this.setState({
      showColumnEditor: true,
      editingProcessId: processId,
    });
  }

  showDeleteColumnDialog(process) {
    this.setState({
      showColumnDeleteDialog: true,
      deleteProcess: process,
    });
  }

  hideColumnEditor() {
    this.setState({ showColumnEditor: false });
  }

  hideDeleteColumnDialog() {
    this.setState({
      showColumnDeleteDialog: false,
      deleteProcess: null,
    });
  }

  handleDeleteProcess() {
    this.props.deleteProcess(
      this.state.deleteProcess.process_id,
      { timestamp: this.state.deleteProcess.timestamp },
      () => {
        this.handleSearch({ keepPage: true });
        this.hideDeleteColumnDialog();
      },
      () => this.hideDeleteColumnDialog()
    );
  }

  showTaskEditor(taskId, isStatus, linkageInfo) {
    this.setState({
      showTaskEditor: true,
      editingTaskId: taskId,
      isStatus: isStatus,
      linkageInfo: linkageInfo,
    });
  }

  hideTaskEditor() {
    this.setState({ showTaskEditor: false });
  }

  showItemEditor(itemId) {
    this.setState({
      editingItemId: itemId,
      showItemEditor: true,
    });
  }

  showSortEditor(itemId) {
    this.setState({
      sortItemId: itemId,
      showSortEditor: true,
    });
  }
  closeSortEditor() {
    this.setState({
      sortItemId: null,
      showSortEditor: false,
    });
  }

  hideItemEditor() {
    this.setState({ showItemEditor: false });
    this.props.removeEmptyItem();
  }

  showCreateWorkEditor(processId) {
    this.setState({
      showCreateWorkEditor: true,
      editingProcessId: processId,
    });
  }

  hideCreateWorkEditor() {
    this.setState({
      showCreateWorkEditor: false,
      editingProcessId: null,
    });
  }

  showWorkEditor(processId) {
    this.setState({
      showWorkEditor: true,
      editingProcessId: processId,
    });
  }

  hideWorkEditor() {
    this.setState({ showWorkEditor: false });
  }

  showApprovalEditor() {
    this.setState({ showApprovalEditor: true });
  }

  hideApprovalEditor() {
    this.setState({ showApprovalEditor: false });
  }

  showHelp() {
    this.setState({ showHelp: true });
  }

  hideHelp() {
    this.setState({ showHelp: false });
  }

  handleSearch(option?: { keepPage?: boolean }) {
    this.setState({
      initialSearched: true,
      start: option?.keepPage ? this.state.start : 1,
      offset: 0,
      startPos: option?.keepPage ? this.state.startPos : 1,
    });
    const params = { ...this.props.searchParams };
    params.limit = localStorage.getItem("limit") ? parseInt(localStorage.getItem("limit")) : this.state.limit;
    params.start = option?.keepPage ? (this.state.currentPage - 1) * this.state.limit + 1 : 1;

    // リスト更新後にそのまま追加ロードが走ることがあるので、
    // スクロール位置をトップに戻す
    if (this.rightBodyContainer) {
      this.rightBodyContainer.scrollTop = 0;
    }

    this.props.search(this.props.isChiba, params, (data) => {
      this.setState(
        {
          start: option?.keepPage ? this.state.start : data.tasks.list.length + 1,
          end: data.tasks.list.length < this.state.limit,
          totalNum: data.tasks.total_num,
          maxPage: Math.ceil(data.tasks.total_num / this.state.limit),
          currentPage: Math.ceil(params.start / this.state.limit),
          backParams: params,
        },
        () => {
          const urlState = restoreState();
          if (urlState.process_id) {
            scrollToProcess(urlState.process_id);

            return;
          }

          const completeProcessId = data.tasks.complete_process_id;
          if (completeProcessId) {
            scrollToProcess(completeProcessId);
          }
        }
      );
    });
  }

  handleSearchPager(startPos = 1) {
    this.setState({ isEmpty: true, startPos: startPos });
    const params = { ...this.state.backParams };
    params.limit = this.state.limit;
    params.start = startPos;

    // リスト更新後にそのまま追加ロードが走ることがあるので、
    // スクロール位置をトップに戻す
    if (this.rightBodyContainer) {
      this.rightBodyContainer.scrollTop = 0;
    }
    this.props.search(this.props.isChiba, params, (data) => {
      this.setState(
        {
          start: data.tasks.list.length + 1,
          end: data.tasks.list.length < this.state.limit,
          maxPage: Math.ceil(data.tasks.total_num / this.state.limit),
          currentPage: Math.ceil(params.start / this.state.limit),
        },
        () => {
          const completeProcessId = data.tasks.complete_process_id;
          if (completeProcessId) {
            scrollToProcess(completeProcessId);
          }
        }
      );
    });
  }

  handleNextPage() {
    const _currentPage = this.state.currentPage;

    if (_currentPage < this.state.maxPage) {
      const startPos = 1 + _currentPage * this.state.limit;
      this.handleSearchPager(startPos);
    }
  }

  handlePrevPage() {
    const _currentPage = this.state.currentPage;

    if (_currentPage > 1) {
      const startPos = 1 + (_currentPage - 2) * this.state.limit;
      this.handleSearchPager(startPos);
    }
  }

  syncScroll(e) {
    this.rightHeaderContainer.scrollLeft = e.target.scrollLeft;
    this.leftBodyContainer.scrollTop = e.target.scrollTop;
  }

  syncWheel(e) {
    this.rightBodyContainer.scrollTop += e.deltaY;
    this.leftBodyContainer.scrollTop += e.deltaY;
  }

  toggleWide() {
    this.setState({ wide: !this.state.wide });
  }

  setTooltip(data) {
    this.setState({ tooltip: data });
  }

  setTooltipChiba(data) {
    this.setState({ tooltipChiba: data });
  }

  download(searchParams, filetype, format, filename) {
    const { download, toggleDownloading } = this.props;
    toggleDownloading(true);

    const params = {
      ...searchParams,
      filetype,
      format,
    };

    delete params.start;
    delete params.limit;

    const callback = (blob, filename) => {
      this.setState(
        {
          downloadUrl: URL.createObjectURL(blob),
          downloadName: filename,
        },
        () => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            const evt = commonUtil.createClickEvent();
            this.btnDownload.dispatchEvent(evt);
          }
          toggleDownloading(false);
        }
      );
    };

    const fallback = () => toggleDownloading(false);
    download(params, callback, fallback);
  }

  showStatusBulkUpdatePanel(type, value) {
    this.fetchStatusUpdateNum(type);

    this.setState({
      showStatusBulkUpdate: true,
      statusBulkUpdateValue: value,
      scheduleType: type,
    });
  }

  closeStatusBulkUpdatePanel() {
    this.setState({
      showStatusBulkUpdate: false,
      statusBulkUpdateValue: null,
      loadingTaskUpdate: false,
    });
  }

  fetchStatusUpdateNum(scheduleType) {
    const callback = (res) => {
      const { total_num } = res;
      this.setState({
        statusBulkUpdateNum: total_num,
      });
    };
    this.props.fetchTaskNum(scheduleType, 1, callback, this.closeStatusBulkUpdatePanel);
  }

  handleBulkUpdate() {
    this.setState({ loadingTaskUpdate: true });
    const { bulkUpdateTaskStatus } = this.props;

    const params = {
      from_valid_flg: true,
      from_schedule_type: this.state.scheduleType,
      from_status: 0,
      from_ahead_days: 1,
      to_valid_flg: true,
      to_schedule_type: this.state.scheduleType,
      to_status: 0,
      to_ahead_days: this.state.statusBulkUpdateValue,
      to_result_end_date: null,
    };

    const callback = () => {
      this.closeStatusBulkUpdatePanel();
      this.handleSearch();
    };
    const fallback = () => {
      this.closeStatusBulkUpdatePanel();
    };
    bulkUpdateTaskStatus(params, callback, fallback);
  }

  showSpecialProcessBundle() {
    this.setState({
      showSpecialBundleEditor: true,
      bundleParams: this.props.searchParams,
    });
  }

  hideSpecialProcessBundle() {
    this.setState({ showSpecialBundleEditor: false });
  }

  showProtectionBundle() {
    this.setState({
      showProtectBundleEditor: true,
      bundleParams: this.props.searchParams,
    });
  }

  hideProtectionBundle() {
    this.setState({ showProtectBundleEditor: false });
  }

  showLimitPanel() {
    this.setState({
      isLimitPanel: true,
      tmpLimit: parseInt(localStorage.getItem("limit")) || 20,
    });
  }

  hideLimitPanel() {
    this.setState({
      isLimitPanel: false,
      tmpLimit: this.state.limit,
    });
  }
  handleClickAddColumnContextMenu(e) {
    e.preventDefault();
    e.stopPropagation();
    this.showCreateWorkEditor(this.props.processSubmenu.process.process_id);
    this.props.clearProcessSubmenu();
  }
  handleClickDeleteColumnContextMenu(e) {
    e.preventDefault();
    e.stopPropagation();
    this.showDeleteColumnDialog(this.props.processSubmenu.process);
    this.props.clearProcessSubmenu();
  }
  handleClickContextMenu(e, on) {
    e.preventDefault();
    e.stopPropagation();

    const payload = {
      on,
      column: this.props.processSubmenu.process,
      searchParams: this.props.searchParams,
    };

    this.props.bulkUpdateProcess(payload, () => {
      this.handleSearch({ keepPage: true });
    });
    this.props.clearProcessSubmenu();
  }
  handleClickReceiverContextMenu(e) {
    e.preventDefault();
    e.stopPropagation();
    this.showColumnEditor(this.props.processSubmenu.process.process_id);
    this.props.clearProcessSubmenu();
  }

  handleClickSplitScheduleContextMenu(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ processIdSplitScheduleModal: this.props.processSubmenu.process.process_id });
    this.props.clearProcessSubmenu();
  }

  handleCloseSplitScheduleModal() {
    this.setState({ processIdSplitScheduleModal: null });
  }

  handleSave() {
    localStorage.setItem("limit", this.state.tmpLimit);
    this.setState(
      {
        isLimitPanel: false,
        limit: this.state.tmpLimit,
      },
      () => this.handleSearch()
    );
  }

  changeLimit(value) {
    this.setState({ tmpLimit: value });
  }

  render() {
    const {
      t,
      searchParams,
      rows,
      taskRoles,
      fetching,
      fetchingItemTasks,
      fetchingProcesses,
      fetchingProgressRate,
      isError,
      downloading,
      roles,
      isChiba,
    } = this.props;
    const { showStatusBulkUpdate, statusBulkUpdateValue, statusBulkUpdateNum, scheduleType } = this.state;
    const isInitialView = this.isInitialView();

    const resultProgressRate = fetchingProgressRate ? "  --  " : this.props.resultProgressRate;
    const scheduleProgressRate = fetchingProgressRate ? "  --  " : this.props.scheduleProgressRate;

    const tableTopRight = (
      <div className="tbl-top-right">
        {!fetching && rows.length === 0 ? (
          <span className="icon icon-help" onClick={() => this.showHelp()}></span>
        ) : (
          <React.Fragment>
            {!fetchingProgressRate && (
              <React.Fragment>
                {t("matrix_progress")}:{resultProgressRate}%　{t("matrix_progress_schedule")}:{scheduleProgressRate}%
                <span
                  className={`icon icon-cached ${fetchingProgressRate ? "animation-rotate-progress" : ""}`}
                  onClick={() => this.props.reloadProgressRate()}
                ></span>
              </React.Fragment>
            )}
            <span className="icon icon-help" onClick={() => this.showHelp()}></span>
          </React.Fragment>
        )}
      </div>
    );

    const showBulkUpdateButton = isValid(roles, "matrix", "update");

    return (
      <div>
        <div className="contents">
          <div className="inner chiba">
            <div className="tbl-top-area mt-15 clearfix">
              <div className="tbl-top-left">
                <h1 className="page-ttl">{t("progress_manage_matrix")}</h1>
                <button
                  data-test-id="button-matrix-search-filter"
                  className="btn btn-blue btn-search w-170 mr-10"
                  onClick={() => this.showSearchPanel()}
                >
                  <i className="icon icon-search"></i>
                  {t("filter_search")}
                </button>
                <span className="item-num mr-20">
                  {t("total")}
                  {this.props.itemNum}
                  {t("unit")}
                </span>
                {!isInitialView && Boolean(rows && rows.length) && (
                  <React.Fragment>
                    <StatusSwitcher
                      value={this.props.switcherStatus}
                      onChange={(value) => this.props.changeSwitcherStatus(value)}
                    />
                    {showBulkUpdateButton && (
                      <PulldownStatusContainer showStatusBulkUpdatePanel={this.showStatusBulkUpdatePanel} />
                    )}
                  </React.Fragment>
                )}
                <PulldownExportContainer
                  searchParams={searchParams}
                  downloading={downloading}
                  onClickDownloadSchedule={this.showDownloadScheduleModal}
                />
              </div>

              {!isInitialView && tableTopRight}
            </div>
            {this.state.headerMessage && <div className="text-[#cc0000] my-[5px]">{this.state.headerMessage}</div>}
            {!fetching && rows.length === 0 ? (
              <React.Fragment>
                {this.state.initialSearched ? <p className="empty-message">{t("no_data")}</p> : null}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {!isInitialView && (
                  <div className={`matrix tbl-area ${fetching ? "loading loading--list" : ""}`}>
                    <div
                      className={`grid-container grid-container-progress ${isChiba ? "chiba" : ""} ${
                        this.state.wide ? "is-wide" : ""
                      }`}
                      // FIXME スタイル指定改善
                      style={{ height: `calc(100vh - ${this.state.headerMessage ? 177 : 154}px)` }}
                    >
                      {!fetchingItemTasks && (
                        <div className="grid-left-header">
                          {isChiba ? (
                            <LeftGridHeaderChiba
                              isWide={this.state.wide}
                              toggleWide={() => this.toggleWide()}
                              taskRoles={taskRoles}
                              showSpecialProcessBundle={this.showSpecialProcessBundle}
                              showProtectionBundle={this.showProtectionBundle}
                            />
                          ) : (
                            <LeftGridHeader
                              isWide={this.state.wide}
                              displayRemarks={this.props.displayRemarks}
                              toggleWide={() => this.toggleWide()}
                            />
                          )}
                        </div>
                      )}
                      {!fetchingItemTasks && (
                        <div
                          className="grid-left-body"
                          ref={(el) => (this.leftBodyContainer = el)}
                          onWheel={(e) => this.syncWheel(e)}
                        >
                          <LeftGridBody
                            rows={this.props.rows}
                            showItemEditor={(itemId) => this.showItemEditor(itemId)}
                            showSortEditor={(itemId) => this.showSortEditor(itemId)}
                            offset={this.state.offset}
                            maxLength={this.state.maxLength}
                            isChiba={isChiba}
                            isWide={this.state.wide}
                            startPos={this.state.startPos}
                            totalNum={this.state.totalNum}
                            titleSubmenuItemId={this.props.titleSubmenuItemId}
                            handleSearch={this.handleSearch}
                          />
                        </div>
                      )}
                      {!fetchingProcesses && (
                        <div
                          className="grid-right-header"
                          ref={(el) => (this.rightHeaderContainer = el)}
                          style={{ position: "relative" }}
                        >
                          <RightGridHeaderContainer
                            columns={this.props.columns}
                            showWorkEditor={this.showWorkEditor}
                            showCreateWorkEditor={this.showCreateWorkEditor}
                            showColumnEditor={(processId) => this.showColumnEditor(processId)}
                            showDeleteDialog={(process) => this.showDeleteColumnDialog(process)}
                            showMiddleClassEditor={(processMiddleClassId) =>
                              this.showMiddleClassEditor(processMiddleClassId)
                            }
                            handleSearch={this.handleSearch}
                            rightHeaderElement={this.rightHeaderContainer}
                          />
                        </div>
                      )}
                      {!fetchingItemTasks && (
                        <div
                          className={`grid-right-body ${
                            this.props.switcherStatus !== TaskSwitcherStatus.OFF && !commonUtil.isIE()
                              ? `cursor-${this.props.switcherStatus}`
                              : ""
                          }`}
                          onScroll={(e) => this.syncScroll(e)}
                          ref={(node) => (this.rightBodyContainer = node)}
                          style={{ position: "relative" }}
                        >
                          <RightGridBody
                            rows={this.props.rows}
                            showTaskEditor={(taskId, isStatus, linkageInfo) =>
                              this.showTaskEditor(taskId, isStatus, linkageInfo)
                            }
                            setTooltip={this.setTooltip}
                            offset={this.state.offset}
                            maxLength={this.state.maxLength}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
          {this.state.maxPage > 0 && (
            <div className="pagination">
              <div className="null-box grid1">
                {this.state.currentPage > 1 && (
                  <div className="left-arrow-box" onClick={() => this.handlePrevPage()}>
                    <span>{t("prev")}</span>
                  </div>
                )}
              </div>
              <span className="page-status">
                {this.state.currentPage} / {this.state.maxPage}
              </span>
              <div className="null-box grid3">
                {this.state.currentPage < this.state.maxPage && (
                  <div className="right-arrow-box" onClick={() => this.handleNextPage()}>
                    <span>{t("next")}</span>
                  </div>
                )}
              </div>
              <div className="limit">
                <span>
                  {t("limit_per_page")}
                  <a className="underline" onClick={() => this.showLimitPanel()}>
                    {this.state.limit}件
                  </a>
                </span>
              </div>
            </div>
          )}
        </div>

        {this.props.processSubmenu && (
          <div
            id={`matrix-column-contextmenu-${this.props.processSubmenu.process?.process_id}`}
            className="context-menu"
            style={{ left: this.props.processSubmenu.x, top: this.props.processSubmenu.y }}
          >
            {isValidRole(this.props.processSubmenu.process.roles, 5) && (
              <div className="menu-item" onClick={(e) => this.handleClickAddColumnContextMenu(e)}>
                {t("add_column")}
              </div>
            )}
            {isValidRole(this.props.processSubmenu.process.roles, 6) && (
              <div className="menu-item" onClick={(e) => this.handleClickDeleteColumnContextMenu(e)}>
                {t("delete_column")}
              </div>
            )}
            {isValidRole(this.props.processSubmenu.process.roles, 3) && (
              <div className="menu-item" onClick={(e) => this.handleClickContextMenu(e, true)}>
                {t("on_all_task")}
              </div>
            )}
            {isValidRole(this.props.processSubmenu.process.roles, 4) && (
              <div className="menu-item" onClick={(e) => this.handleClickContextMenu(e, false)}>
                {t("off_all_task")}
              </div>
            )}
            {isValidRole(this.props.processSubmenu.process.roles, 2) && (
              <div className="menu-item" onClick={(e) => this.handleClickReceiverContextMenu(e)}>
                {t("receiver_all_task")}
              </div>
            )}
            {isChiba && isValidRole(this.props.processSubmenu.process.roles, 8) && (
              <div className="menu-item" onClick={(e) => this.handleClickSplitScheduleContextMenu(e)}>
                {t("split_schedule")}
              </div>
            )}
          </div>
        )}
        {this.state.showSearchPanel && (
          <SearchPanelContainer
            search={this.handleSearch}
            cancelHandler={() => this.hideSearchPanel()}
            closeHandler={() => this.hideSearchPanel()}
          />
        )}
        {this.state.openDownloadScheduleModal && <DownloadScheduleModal onClose={this.hideDownloadScheduleModal} />}
        {this.state.showMiddleClassEditor && (
          <MiddleClassEditorContainer
            closeHandler={() => this.hideMiddleClassEditor()}
            processMiddleClassId={this.state.editingProcessMiddleClassId}
          />
        )}
        {this.state.showColumnEditor && (
          <ColumnEditorContainer
            closeHandler={() => this.hideColumnEditor()}
            handleSearch={() => this.handleSearch({ keepPage: true })}
            processId={this.state.editingProcessId}
          />
        )}
        {this.state.showTaskEditor && (
          <TaskEditorContainer
            taskId={this.state.editingTaskId}
            isStatus={this.state.isStatus}
            linkageInfo={this.state.linkageInfo}
            closeHandler={() => this.hideTaskEditor()}
          />
        )}
        {this.state.showHelp && <Help closeHandler={() => this.hideHelp()} saveHandler={() => this.hideHelp()} />}
        {this.state.showItemEditor && !isChiba && (
          <ItemEditorContainer
            itemId={this.state.editingItemId}
            displayRemarks={this.props.displayRemarks}
            closeHandler={() => this.hideItemEditor()}
          />
        )}
        {this.state.showItemEditor && isChiba && (
          <ItemEditorChibaContainer itemId={this.state.editingItemId} closeHandler={() => this.hideItemEditor()} />
        )}
        {this.state.showWorkEditor && (
          <WorkEditorContainer processId={this.state.editingProcessId} closeHandler={() => this.hideWorkEditor()} />
        )}
        {this.state.showCreateWorkEditor && (
          <WorkCreateEditorContainer
            processId={this.state.editingProcessId}
            onClose={() => this.hideCreateWorkEditor()}
            onComplete={() => this.handleSearch({ keepPage: true })}
          />
        )}
        {this.state.showColumnDeleteDialog && (
          <ConfirmDialog
            title={t("confirmation")}
            messages={[(this.state.deleteProcess?.process_name ?? "") + t("delete_confirmation"), "", t("is_it_ok")]}
            okClickHandler={() => this.handleDeleteProcess()}
            cancelClickHandler={() => this.hideDeleteColumnDialog()}
          />
        )}
        {this.state.showSortEditor && (
          <SortEditor
            itemId={this.state.sortItemId}
            onClose={() => this.closeSortEditor()}
            onCompleted={() => {
              this.closeSortEditor();
              this.handleSearch({ keepPage: true });
            }}
          />
        )}
        {this.state.tooltip !== null && (
          <ReactTooltip
            id="matrix-task-detail"
            className="matrix-task-detail"
            delayShow={500}
            effect="solid"
            isCapture={true}
            scrollHide={true}
          >
            <TooltipContainer data={this.state.tooltip} />
          </ReactTooltip>
        )}
        {showStatusBulkUpdate && statusBulkUpdateNum !== 0 && (
          <Modal title={t("confirmation")} closeHandler={this.closeStatusBulkUpdatePanel}>
            <div className={`modal-body ${this.state.loadingTaskUpdate ? "loading" : ""}`}>
              <p>
                {statusBulkUpdateNum}
                {t("update_status_1")}
                {scheduleType === TaskScheduleType.SCHEDULED ? "◯" : "◎"}
                {t("update_status_2")}
                {scheduleType === TaskScheduleType.SCHEDULED && statusBulkUpdateValue === 2 ? "②" : ""}
                {scheduleType === TaskScheduleType.SCHEDULED && statusBulkUpdateValue === 3 ? "③" : ""}
                {scheduleType === TaskScheduleType.CONTINUE && statusBulkUpdateValue === 2 ? "⓶" : ""}
                {scheduleType === TaskScheduleType.CONTINUE && statusBulkUpdateValue === 3 ? "⓷" : ""}
                {t("update_status_3")}
              </p>
              <p>{t("is_it_ok")}</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-gray" onClick={this.closeStatusBulkUpdatePanel}>
                {t("confirm_dismiss")}
              </button>
              <button type="button" className="btn btn-blue" onClick={this.handleBulkUpdate}>
                {t("confirm_accept")}
              </button>
            </div>
          </Modal>
        )}
        {showStatusBulkUpdate && statusBulkUpdateNum === 0 && (
          <Modal title={t("confirmation")} closeHandler={this.closeStatusBulkUpdatePanel} className="w-350 txt-center">
            <div className="modal-body">
              <p>{t("no_data_found")}</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-blue" onClick={this.closeStatusBulkUpdatePanel}>
                {t("alert_ok")}
              </button>
            </div>
          </Modal>
        )}
        {this.state.showSpecialBundleEditor && (
          <SpecialProcessBundleEditorContainer
            params={this.state.bundleParams}
            itemId={this.props.paramItemId}
            handleSearch={this.handleSearch}
            closeHandler={this.hideSpecialProcessBundle}
          />
        )}
        {this.state.showProtectBundleEditor && (
          <ProtectionBundleEditorContainer
            params={this.state.bundleParams}
            itemId={this.props.paramItemId}
            handleSearch={this.handleSearch}
            closeHandler={this.hideProtectionBundle}
          />
        )}
        {this.state.isLimitPanel && (
          <Modal title={t("limit")} closeHandler={() => this.hideLimitPanel()} className="w-350 txt-center">
            <div className="modal-body">
              <div className="form-group w-200">
                <select
                  data-test-id="text-import-status"
                  className="form-control w-100"
                  value={this.state.tmpLimit}
                  onChange={(e) => this.changeLimit(Number(e.target.value))}
                >
                  <option key={1} value={10}>
                    10{t("unit")}
                  </option>
                  <option key={2} value={20}>
                    20{t("unit")}
                  </option>
                  <option key={3} value={30}>
                    30{t("unit")}
                  </option>
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-gray" onClick={() => this.hideLimitPanel()}>
                {t("cancel")}
              </button>
              <button type="button" className="btn btn-blue" onClick={() => this.handleSave()}>
                {t("decision")}
              </button>
            </div>
          </Modal>
        )}
        {this.state.processIdSplitScheduleModal && (
          <SegmentSettingModal
            processId={this.state.processIdSplitScheduleModal}
            categoryId={this.props.searchParams.categoryId}
            primaryChargeId={this.props.searchParams.primaryChargeId}
            onClose={() => this.handleCloseSplitScheduleModal()}
          />
        )}
      </div>
    );
  }
}

Matrix.propTypes = {
  searchParams: PropTypes.object.isRequired,
  search: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  fetchingProgressRate: PropTypes.bool.isRequired,
  fetchingItemTasks: PropTypes.bool.isRequired,
  fetchingProcesses: PropTypes.bool.isRequired,
  switcherStatus: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  resultProgressRate: PropTypes.number.isRequired,
  scheduleProgressRate: PropTypes.number.isRequired,
  itemNum: PropTypes.number.isRequired,
  changeSwitcherStatus: PropTypes.func.isRequired,
  removeEmptyItem: PropTypes.func.isRequired,
  reloadProgressRate: PropTypes.func.isRequired,
  download: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  validRequired: PropTypes.bool,
  isError: PropTypes.bool.isRequired,
  revertLocalCondition: PropTypes.func.isRequired,
  inheritingSearchConditions: PropTypes.bool.isRequired,
  toggleDownloading: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired,
  fetchTaskNum: PropTypes.func.isRequired,
  bulkUpdateTaskStatus: PropTypes.func.isRequired,
  roles: PropTypes.object.isRequired,
  displayRemarks: PropTypes.bool.isRequired,
  titleSubmenuItemId: PropTypes.number,
  clearTitleSubmenu: PropTypes.func.isRequired,
  processSubmenu: PropTypes.object,
  clearProcessSubmenu: PropTypes.func.isRequired,
  deleteProcess: PropTypes.func.isRequired,
  bulkUpdateProcess: PropTypes.func.isRequired,
};

export default withTranslation()(Matrix);

const scrollToProcess = (process_id: number) => {
  const processEl = document.querySelector(`[data-process-id="${process_id}"]`);
  const containerEl = document.querySelector(".grid-right-header");
  const bodyEl = document.querySelector(".grid-right-body");

  if (processEl && containerEl && bodyEl) {
    const processRect = processEl.getBoundingClientRect();
    const containerRect = containerEl.getBoundingClientRect();
    const diffX = processRect.x - containerRect.x;
    anime({
      targets: bodyEl,
      duration: 500,
      delay: 1500,
      easing: "cubicBezier(.09, .61, .47, 1)",
      scrollLeft: diffX - processRect.width / 2,
    });
  }
};
