import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Modal from "../Modal";
import { SubmitButton } from "@/components/common/SubmitButton";

class SpecialProcessBundleEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // target: this.props.target,
      note: "",
      loading: false,
      special_processes: [],
      processesData: [
        { id: 1, name: "high_place_work" },
        { id: 2, name: "heavy_machinery_work" },
        { id: 3, name: "dust_work" },
        { id: 4, name: "radiation_work" },
        { id: 5, name: "inert_work" },
        { id: 6, name: "noisy_work" },
        { id: 7, name: "asbestos_handling_work" },
      ],
      error: {},
    };

    this.handleChangeProcess = this.handleChangeProcess.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.props.setUnloadAlert();
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();
  }

  handleChangeProcess(id, checked) {
    if (checked) {
      this.setState({ special_processes: [...this.state.special_processes, id] });
    } else {
      this.setState({ special_processes: this.state.special_processes.filter((item) => item != id) });
    }
  }

  handleSave() {
    if (this.state.loading) return;
    const { scheduleIds, t } = this.props;
    const { processesData, special_processes } = this.state;

    const processStrings = [];

    processesData.map((process, index) => {
      if (special_processes.some((id) => id === process.id)) {
        processStrings.push(t(process.name));
      }
    });

    const data = {
      ...this.state,
      target: "special_process_text",
      note: processStrings.join("\n"),
      scheduleIds: scheduleIds,
    };

    data.loading = true;
    this.setState(data);
    this.props.updateItemBundle(
      data,
      () => {
        this.setState({ loading: false });
        this.props.searchHandler();
        this.props.closeHandler();
      },
      () => {
        this.setState({ loading: false });
      }
    );
  }

  render() {
    const { closeHandler, t } = this.props;
    const { processesData } = this.state;

    return (
      <Modal title={`${t("special_work")} ${t("update_bulk")}`} closeHandler={() => closeHandler()}>
        <div className="modal-body">
          <div className="form-row mb-0">
            <div className="form-group w-360 txt-center">
              <div className="ckbox-vertically w-280">
                {processesData.map((process, index) => (
                  <label key={index} className="ckbox mr-0">
                    <input
                      type="checkbox"
                      value={process.id || undefined}
                      checked={this.state.special_processes.some((value) => value === process.id)}
                      onChange={(e) => this.handleChangeProcess(process.id, e.target.checked)}
                    />
                    <span>{t(process.name)}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={"modal-footer"}>
          <button
            data-test-id="button-column-editor-cancel"
            type="button"
            className="btn btn-gray"
            onClick={() => closeHandler()}
          >
            {t("cancel")}
          </button>
          <SubmitButton
            data-test-id="button-column-editor-save"
            onClick={this.handleSave}
            loading={this.state.loading}
          />
        </div>
      </Modal>
    );
  }
}

SpecialProcessBundleEditor.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  updateItemBundle: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
};

export default withTranslation()(SpecialProcessBundleEditor);
