import { useCallback, useState } from "react";

export const useDrawingArrow = (props: { defaultColor: string }) => {
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [color, setColor] = useState<string>(props.defaultColor);
  const [thickness, setThickness] = useState<number>(5);
  const [coords, setCoords] = useState<[number, number][]>([]);

  const init = useCallback(() => {
    setIsDrawing(true);
    setColor(props.defaultColor);
    setThickness(5);
    setCoords([]);
  }, [props.defaultColor]);

  const end = useCallback(() => {
    setIsDrawing(false);
  }, []);

  const changeColorAndThickness = useCallback((color: string, thickness: number) => {
    setColor(color);
    setThickness(thickness);
  }, []);

  const addCoord = useCallback(
    (coord: [number, number]) => {
      setCoords([...coords, coord]);
    },
    [coords]
  );

  return { init, end, isDrawing, color, thickness, coords, changeColorAndThickness, addCoord };
};
