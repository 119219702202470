import { connect } from "react-redux";

import actions from "../actions";
import Login from "../components/Login";

const mapStateToProps = (state) => {
  const { lang } = state.app;

  return {
    ...state.login,
    lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeName: (name) => dispatch(actions.login.changeName(name)),
    changePassword: (password) => dispatch(actions.login.changePassword(password)),
    submit: (name, password) => dispatch(actions.login.submit(name, password)),
    showConfirm: (title, messages, okClickHandler, cancelClickHandler) =>
      dispatch(actions.app.showConfirm(title, messages, okClickHandler, cancelClickHandler)),
    hideConfirm: () => dispatch(actions.app.hideConfirm()),
    changeLang: (id) => dispatch(actions.app.changeLang(id)),
    saveLang: () => dispatch(actions.app.saveLang()),
    requestSecurityCode: (countryCode, phoneNumber) =>
      dispatch(actions.login.requestSecurityCode(countryCode, phoneNumber)),
    sendSecurityCode: (code) => dispatch(actions.login.sendSecurityCode(code)),
    hidePasswordWarning: () => dispatch(actions.login.hidePasswordWarning()),
    showPasswordEditor: () => dispatch(actions.login.showPasswordEditor()),
    hidePasswordEditor: () => dispatch(actions.login.hidePasswordEditor()),
    hideTwoFactorAuth: () => dispatch(actions.login.hideTwoFactorAuth()),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
    savePassword: (oldPassword, newPassword, okHandler, ngHandler) =>
      dispatch(actions.app.updatePassword(oldPassword, newPassword, okHandler, ngHandler, true)),
    verifyTwoFactorAuth: (security_code, ng_handler) =>
      dispatch(actions.login.verifyTwoFactorAuth(security_code, ng_handler)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
