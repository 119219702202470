import { connect } from "react-redux";

import actions from "../../actions";
import RightGridRow from "../../components/summary/RightGridRow";

const mapStateToProps = (state) => {
  return {
    isInazumaSelecting: state.summary.isInazumaSelecting,
    inazumaSelectedCells: state.summary.inazumaSelectedCells,
    isGraphSelecting: state.summary.isGraphSelecting,
    graphSelectedCells: state.summary.graphSelectedCells,
    isScheduleSelecting: state.summary.isScheduleSelecting,
    scheduleSelectedCells: state.summary.scheduleSelectedCells,
    isDelaySelecting: state.summary.isDelaySelecting,
    delaySelectedCells: state.summary.delaySelectedCells,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickCell: (row, data) => dispatch(actions.summary.selectCategorySummary(row, data)),
    onClickCellInazuma: (row, data) => dispatch(actions.summary.toggleInazumaCell(row, data)),
    onClickCellGraph: (row, data) => dispatch(actions.summary.toggleGraphCell(row, data)),
    onClickCellSchedule: (row, data) => dispatch(actions.summary.toggleScheduleCell(row, data)),
    onClickCellDelay: (row, data) => dispatch(actions.summary.toggleDelayCell(row, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RightGridRow);
