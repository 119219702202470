import * as constants from "../constants/index";
import environments from "../environments";
import storageManager from "../lib/storageManager";

export const setValidRequired = (valid) => {
  return {
    type: constants.SCHEDULE_SET_VALID_REQUIRED,
    payload: valid,
  };
};

export const changeArea = (items) => ({
  type: constants.SCHEDULE_CHANGE_AREA,
  payload: items,
});

export const changeDevice = (items) => ({
  type: constants.SCHEDULE_CHANGE_DEVICE,
  payload: items,
});

export const changeCategory = (item) => ({
  type: constants.SCHEDULE_CHANGE_CATEGORY,
  payload: item || 0,
});

export const changeFacilityManagement = (items) => ({
  type: constants.SCHEDULE_CHANGE_FACILITY_MANAGEMENT,
  payload: items,
});

export const changeConstructionManagement = (items) => ({
  type: constants.SCHEDULE_CHANGE_CONSTRUCTION_MANAGEMENT,
  payload: items,
});

export const changePrimaryCharge = (items) => ({
  type: constants.SCHEDULE_CHANGE_PRIMARY_CHARGE,
  payload: items,
});

export const changeField1 = (items) => ({
  type: constants.SCHEDULE_CHANGE_USER_FIELD1,
  payload: items ? items.map((item) => item.value) : [],
});

export const changeField7 = (items) => ({
  type: constants.SCHEDULE_CHANGE_USER_FIELD7,
  payload: items ? items.map((item) => item.value) : [],
});

export const changeField8 = (items) => ({
  type: constants.SCHEDULE_CHANGE_USER_FIELD8,
  payload: items ? items.map((item) => item.value) : [],
});

export const changeUser = (items) => ({
  type: constants.SCHEDULE_CHANGE_USER,
  payload: items,
});

export const changeGroup = (items) => ({
  type: constants.SCHEDULE_CHANGE_GROUP,
  payload: items,
});

export const changeScheduleDate = (scheduleDate) => ({
  type: constants.SCHEDULE_CHANGE_SCHEDULE_DATE,
  payload: scheduleDate,
});

export const changeItemText = (itemText) => ({
  type: constants.SCHEDULE_CHANGE_ITEM_TEXT,
  payload: itemText,
});

export const changeProcessText = (processText) => ({
  type: constants.SCHEDULE_CHANGE_PROCESS_TEXT,
  payload: processText,
});

export const clearSearch = (target) => ({
  type: constants.SCHEDULE_CLEAR_SEARCH,
  payload: target,
});

export const revertLocalCondition = () => ({
  type: constants.SCHEDULE_REVERT_CONDITION,
});

export const setValidated = (validated) => ({
  type: constants.SCHEDULE_SET_VALIDATED,
  payload: validated,
});

export const search = (params, callback) => {
  let endpoint = `${environments.API_BASE_URI}/list/schedules_eneos_kawasaki`;
  const query = new URLSearchParams();

  if (params && params.areaIds && params.areaIds.length > 0) {
    params.areaIds.forEach((param) => query.append("area_id", param));
  }

  if (params && params.deviceIds && params.deviceIds.length > 0) {
    params.deviceIds.forEach((param) => query.append("device_id", param));
  }

  if (params && params.categoryId && params.categoryId > 0) {
    query.set("category_id", params.categoryId);
  }

  if (params && params.facilityManagementIds && params.facilityManagementIds.length > 0) {
    params.facilityManagementIds.forEach((param) => query.append("facility_management_id", param));
  }

  if (params && params.constructionManagementIds && params.constructionManagementIds.length > 0) {
    params.constructionManagementIds.forEach((param) => query.append("construction_management_id", param));
  }

  if (params && params.primaryChargeIds && params.primaryChargeIds.length > 0) {
    params.primaryChargeIds.forEach((param) => query.append("primary_charge_id", param));
  }

  if (params && params.field1Ids && params.field1Ids.length > 0) {
    params.field1Ids.forEach((param) => query.append("field1", param));
  }

  if (params && params.field7Ids && params.field7Ids.length > 0) {
    params.field7Ids.forEach((param) => query.append("field7", param));
  }

  if (params && params.field8Ids && params.field8Ids.length > 0) {
    params.field8Ids.forEach((param) => query.append("field8", param));
  }

  if (params && params.userIds && params.userIds.length > 0) {
    params.userIds.forEach((param) => query.append("user_id", param));
  }

  if (params && params.groupIds && params.groupIds.length > 0) {
    params.groupIds.forEach((param) => query.append("group_id", param));
  }

  if (params && params.scheduleDate) {
    query.set("schedule_date", params.scheduleDate.replace(/\//g, "-"));
  }

  if (params && params.itemText) {
    query.set("item_text", params.itemText);
  }

  if (params && params.processText) {
    query.set("process_text", params.processText);
  }

  if (params && params.filter && params.filter.length > 0) {
    params.filter.forEach((param) => query.append("filter", param));
  }

  if (params && params.limit) {
    query.set("limit", params.limit);
  }

  if (params && params.start) {
    query.set("start", params.start);
  }

  storageManager.setConstructionItem("scheduleSearchParams", JSON.stringify(params));
  endpoint = `${endpoint}?${query.toString()}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.SCHEDULE_BEGIN_SEARCH,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.SCHEDULE_END_SEARCH,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const download = (params = null, callback, fallback) => {
  let endpoint = `${environments.API_BASE_URI}/list/schedule_eneos_kawasaki/files`;

  const query = new URLSearchParams();

  query.set("filetype", 1);
  query.set("format", "excel");

  if (params && params.areaIds && params.areaIds.length > 0) {
    params.areaIds.forEach((param) => query.append("area_id", param));
  }

  if (params && params.deviceIds && params.deviceIds.length > 0) {
    params.deviceIds.forEach((param) => query.append("device_id", param));
  }

  if (params && params.categoryId && params.categoryId > 0) {
    query.set("category_id", params.categoryId);
  }

  if (params && params.facilityManagementIds && params.facilityManagementIds.length > 0) {
    params.facilityManagementIds.forEach((param) => query.append("facility_management_id", param));
  }

  if (params && params.constructionManagementIds && params.constructionManagementIds.length > 0) {
    params.constructionManagementIds.forEach((param) => query.append("construction_management_id", param));
  }

  if (params && params.primaryChargeIds && params.primaryChargeIds.length > 0) {
    params.primaryChargeIds.forEach((param) => query.append("primary_charge_id", param));
  }

  if (params && params.field1Ids && params.field1Ids.length > 0) {
    params.field1Ids.forEach((param) => query.append("field1", param));
  }

  if (params && params.field7Ids && params.field7Ids.length > 0) {
    params.field7Ids.forEach((param) => query.append("field7", param));
  }

  if (params && params.field8Ids && params.field8Ids.length > 0) {
    params.field8Ids.forEach((param) => query.append("field8", param));
  }

  if (params && params.userIds && params.userIds.length > 0) {
    params.userIds.forEach((param) => query.append("user_id", param));
  }

  if (params && params.groupIds && params.groupIds.length > 0) {
    params.groupIds.forEach((param) => query.append("group_id", param));
  }

  if (params && params.scheduleDate) {
    query.set("schedule_date", params.scheduleDate.replace(/\//g, "-"));
  }

  if (params && params.itemText) {
    query.set("item_text", params.itemText);
  }

  if (params && params.processText) {
    query.set("process_text", params.processText);
  }

  if (params && params.filter && params.filter.length > 0) {
    params.filter.forEach((param) => query.append("filter", param));
  }

  if (params && params.limit) {
    query.set("limit", params.limit);
  }

  if (params && params.start) {
    query.set("start", params.start);
  }

  endpoint = `${endpoint}?${query.toString()}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      success: (response, fileName) => {
        if (callback !== undefined) {
          callback(response, fileName);
        }

        return {
          type: constants.SCHEDULE_END_DOWNLOAD,
        };
      },
      error: (response) => {
        fallback && fallback();

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateItem = (data, callback, failedCallback) => {
  return (dispatch, getState) => {
    const { schedule_id, target, value, update_date, timestamp } = data;
    const action = {
      type: constants.APP_CALL_API,
      endpoint: `${environments.API_BASE_URI}/list/schedules_eneos_kawasaki/update/${schedule_id}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        schedule_id,
        target,
        value,
        update_date,
        timestamp,
      }),
      callbacks: {
        success: (response) => {
          if (callback !== undefined) {
            callback(response);
          }

          return {
            type: constants.SCHEDULE_END_UPDATE_ITEM,
            payload: response,
          };
        },
        error: (response) => {
          if (failedCallback !== undefined) {
            failedCallback(response);
          }

          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    };

    dispatch(action);
  };
};

export const updateItemBundle = (data, callback, failedCallback) => {
  return (dispatch, getState) => {
    const { schedules_eneos_kawasaki_id, target, value } = data;
    const action = {
      type: constants.APP_CALL_API,
      endpoint: `${environments.API_BASE_URI}/list/schedules_eneos_kawasaki/update`,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        schedules_eneos_kawasaki_id,
        target,
        value,
      }),
      callbacks: {
        success: (response) => {
          if (callback !== undefined) {
            callback(response);
          }

          return {
            type: constants.SCHEDULE_END_UPDATE_ITEM_BUNDLE,
            payload: response,
          };
        },
        error: (response) => {
          if (failedCallback !== undefined) {
            failedCallback(response);
          }

          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    };

    dispatch(action);
  };
};

export const getOptions = (callback) => {
  const endpoint = `${environments.API_BASE_URI}/list/schedules_eneos_kawasaki/options`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.SCHEDULE_BEGIN_GET_OPTIONS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.SCHEDULE_END_GET_OPTIONS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const createSchedule = (data, callback, failedCallback) => {
  return (dispatch, getState) => {
    const { categoryId, scheduleDate } = data;
    const action = {
      type: constants.APP_CALL_API,
      endpoint: `${environments.API_BASE_URI}/list/schedules_eneos_kawasaki/create`,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        category_id: categoryId,
        schedule_date: scheduleDate,
      }),
      callbacks: {
        success: (response) => {
          if (callback !== undefined) {
            callback(response);
          }

          return {
            type: constants.SCHEDULE_END_CREATE_SCHEDULE,
            payload: response,
          };
        },
        error: (response) => {
          if (failedCallback !== undefined) {
            failedCallback(response);
          }

          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    };

    dispatch(action);
  };
};
