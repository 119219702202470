import * as constants from "../constants";
import * as util from "../lib/common";

const api = (store) => (next) => (action) => {
  next(action);

  if (action.type === constants.APP_FILE_UPLOAD) {
    const method = action.method || "POST";
    const headers = action.headers || {};
    const sessionToken = util.getSessionToken();
    const userId = util.getUserId();
    const state = store.getState();
    const constructionId = state.construction.constructionId;
    const { lang } = state.app;

    if (sessionToken !== null) {
      headers.Authorization = `Bearer ${sessionToken}`;
    }

    if (userId !== null) {
      headers["X-SXP-User-Id"] = userId;
    }

    if (constructionId !== 0) {
      headers["X-SXP-Construction-Id"] = constructionId;
    }

    headers["X-SXP-Language"] = lang;
    headers["X-Requested-With"] = "XMLHttpRequest";

    const params = {
      method,
      headers,
    };

    if (action.body) {
      params.body = action.body;
    }

    const callbacks = action.callbacks || {};

    if (callbacks.begin) {
      next(callbacks.begin());
    }

    const futch = (url, params, onProgress) => {
      return new Promise((res, rej) => {
        const xhr = new XMLHttpRequest();
        let key;

        xhr.open(params.method, url);

        for (key in params.headers) {
          xhr.setRequestHeader(key, params.headers[key]);
        }

        xhr.onload = (e) => {
          if (xhr.status === 200) {
            res(e.target.responseText);
          } else {
            rej({ text: e.target.responseText, status: xhr.status });
          }
        };

        xhr.onerror = rej;

        if (xhr.upload && onProgress) {
          xhr.upload.onprogress = onProgress;
        }

        xhr.send(params.body);
      });
    };

    futch(action.endpoint, params, callbacks.progress)
      .then((res) => {
        if (callbacks.success) {
          callbacks.success(JSON.parse(res));
        }
      })
      .catch((err) => {
        if (callbacks.error) {
          let msg;

          try {
            msg = JSON.parse(err.text);
          } catch (e) {
            msg = err.text;
          }

          return next(callbacks.error(msg, err.status));
        }

        console.error(res);
      });
  }
};

export default api;
