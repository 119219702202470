import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";

class LeftGridRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(this.props.row, nextProps.row)) {
      return true;
    }

    return false;
  }

  render() {
    const row = this.props.row;
    const index = this.props.index;

    return (
      <tr>
        <td>
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">{row.area_name}</div>
            </div>
          </div>
        </td>
        <td>
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">{row.device_name}</div>
            </div>
          </div>
        </td>
        <td>
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">{row.device_code}</div>
            </div>
          </div>
        </td>
        <td>
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">{row.facility_management_name}</div>
            </div>
          </div>
        </td>
        <td>
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">{row.construction_management_name}</div>
            </div>
          </div>
        </td>
        <td>
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">{row.primary_charge_name}</div>
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

LeftGridRow.propTypes = {
  index: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
};

export default LeftGridRow;
