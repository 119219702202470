import format from "date-fns/format";
import React, { useCallback, useMemo, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../Modal";

import actions from "@/actions";
import { SubmitButton } from "@/components/common/SubmitButton";
import { ToggleButtonGroup } from "@/components/common/ToggleButtonGroup";
import * as commonUtil from "@/lib/common";
import { RootState } from "@/reducers/types";

type Props = {
  onClose: () => void;
};

const DownloadScheduleModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const searchParams = useSelector((state: RootState) => state.matrixSearch);
  const [tomorrow, threeDaysLater, oneWeekLater] = useMemo(() => {
    const today = new Date();

    return [
      new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1), // 翌日
      new Date(today.getFullYear(), today.getMonth(), today.getDate() + 3), // 3日後
      new Date(today.getFullYear(), today.getMonth(), today.getDate() + 8), // 翌日から7日後
    ];
  }, []);

  // 作業が選択されているかどうか（作業基準と件名基準のラジオ）
  const [isWork, setIsWork] = useState(false);
  // 予定日
  const [fromDate, setFromDate] = useState<Date>(tomorrow);
  const [toDate, setToDate] = useState<Date>(threeDaysLater);

  const [error, setError] = useState<boolean>(false);

  // ダウンロード関連State
  const btnDownload = useRef<HTMLAnchorElement>();
  const [loading, setLoading] = useState(false);

  const isWithinOneWeek = useCallback(
    (date: Date) => {
      return tomorrow.getTime() <= date.getTime() && date.getTime() < oneWeekLater.getTime();
    },
    [tomorrow, oneWeekLater]
  );

  const handleChangeStartDate = useCallback(
    (date: Date) => {
      setFromDate(date);
      if (toDate.getTime() < date.getTime()) {
        setToDate(date);
      }
    },
    [fromDate, toDate]
  );

  const handleChangeEndDate = useCallback(
    (date: Date) => {
      setToDate(date);
      if (date.getTime() < fromDate.getTime()) {
        setFromDate(date);
      }
    },
    [fromDate, toDate]
  );

  const toggleDownloading = (downloading: boolean) => {
    dispatch(actions.matrix.toggleDownloading(downloading));
  };

  const handleSubmit = () => {
    if (toDate.getTime() < fromDate.getTime()) {
      setError(true);
      return;
    }
    setError(false);

    setLoading(true);
    toggleDownloading(true);

    dispatch(
      actions.matrix.download(
        {
          ...searchParams,
          filetype: isWork ? 4 : 13,
          format: "excel",
          fromScheduleDate: format(fromDate, "yyyy-MM-dd"),
          toScheduleDate: format(toDate, "yyyy-MM-dd"),
        },
        (blob, filename) => {
          // @ts-ignore: MS用
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // @ts-ignore: MS用
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else if (btnDownload.current) {
            btnDownload.current.href = URL.createObjectURL(blob);
            btnDownload.current.download = filename;
            const evt = commonUtil.createClickEvent();
            btnDownload.current.dispatchEvent(evt);
          }
          toggleDownloading(false);
          setLoading(false);
          props.onClose();
        },
        () => {
          toggleDownloading(false);
          setLoading(false);
          props.onClose();
        }
      )
    );
  };

  return (
    <Modal title={t("putting_schedule_list")} closeHandler={() => props.onClose()}>
      <div className="modal-body" style={{ padding: "20px 60px", position: "static" }}>
        <div className="form-row flex items-center justify-center">
          <ToggleButtonGroup
            value={isWork ? "work" : "subject"}
            options={[
              { label: t("working_standard"), value: "work" },
              { label: t("title_standard"), value: "subject" },
            ]}
            onChange={(value) => setIsWork(value === "work")}
          />
        </div>
        <div className="form-row">
          <div className="form-group  flex items-center justify-center">
            <span className="form-label txt-bold">{t("scheduled_date")}</span>
            <div className="w-[120px]">
              <DatePicker
                selected={fromDate}
                dateFormat="yyyy/MM/dd"
                onChange={handleChangeStartDate}
                locale={t("calender_locale")}
                filterDate={isWithinOneWeek}
              />
            </div>
            &nbsp;〜&nbsp;
            <div className="w-[120px]">
              <DatePicker
                selected={toDate}
                dateFormat="yyyy/MM/dd"
                onChange={handleChangeEndDate}
                locale={t("calender_locale")}
                filterDate={isWithinOneWeek}
              />
            </div>
          </div>
          {error && (
            <div className="form-error">
              <p className="form-error-message w-260">{t("error_scheduled_date")}</p>
            </div>
          )}
        </div>
      </div>
      <div className="modal-footer">
        <button
          data-test-id="button-column-editor-cancel"
          type="button"
          className="btn btn-gray"
          onClick={props.onClose}
        >
          {t("cancel")}
        </button>
        <SubmitButton data-test-id="button-column-editor-save" onClick={handleSubmit} loading={loading}>
          {t("decision")}
        </SubmitButton>
      </div>
      <a className="d-n" ref={btnDownload}>
        download
      </a>
    </Modal>
  );
};

export default DownloadScheduleModal;
