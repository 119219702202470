import * as inspection from "./Inspection";
import * as app from "./app";
import * as approval from "./approval";
import * as area from "./area";
import * as asset from "./asset";
import * as category from "./category";
import * as checkpoint from "./checkpoint";
import * as common from "./common";
import * as construction from "./construction";
import * as construction_management from "./construction_management";
import * as dataExport from "./dataExport";
import * as device from "./device";
import * as facility_management from "./facility_management";
import * as dataimport from "./import";
import * as information from "./information";
import * as login from "./login";
import * as matrix from "./matrix";
import * as matrixSearch from "./matrixSearch";
import * as notifications from "./notifications";
import * as other from "./other";
import * as primary_charge from "./primary_charge";
import * as process_major_class from "./process_major_class";
import * as process_middle_class from "./process_middle_class";
import * as progressNotification from "./progressNotification";
import * as qrcode from "./qrcode";
import * as schedule from "../components/list/Schedule/actions/schedule";
import * as schedule_chiba from "./schedule_chiba";
import * as schedule_chita from "./schedule_chita";
import * as schedule_eneos_kawasaki from "./schedule_eneos_kawasaki";
import * as summary from "./summary";
import * as system from "./system";
import * as holiday from "../components/list/Holiday/actions/holiday";

import * as layoutApp from "@/sx-layout/actions/layoutApp";
import * as layoutMasters from "@/sx-layout/actions/layoutMasters";
import * as layoutNotifications from "@/sx-layout/components/notification/actions/notification";
import * as keepOutAreas from "@/sx-layout/components/plotmap/actions/keepOutAreas";
import * as machineEdit from "@/sx-layout/components/plotmap/actions/machine";
import * as plotmap from "@/sx-layout/components/plotmap/actions/plotmap";
import * as machine from "@/sx-layout/components/settings/machineSetting/actions/machine";
import * as plotPlan from "@/sx-layout/components/settings/plotPlanSetting/actions/plotPlan";
import * as arrows from "@/sx-layout/components/plotmap/actions/arrows";
import * as texts from "@/sx-layout/components/plotmap/actions/texts";

export default {
  common,
  login,
  app,
  construction,
  matrix,
  summary,
  category,
  checkpoint,
  device,
  area,
  facility_management,
  construction_management,
  primary_charge,
  progressNotification,
  other,
  process_major_class,
  process_middle_class,
  information,
  dataimport,
  matrixSearch,
  approval,
  inspection,
  schedule,
  schedule_eneos_kawasaki,
  schedule_chita,
  schedule_chiba,
  system,
  qrcode,
  asset,
  dataExport,
  notifications,
  layoutApp,
  layoutMasters,
  plotmap,
  machine,
  machineEdit,
  plotPlan,
  layoutNotifications,
  keepOutAreas,
  arrows,
  texts,
  holiday,
};
