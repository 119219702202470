import { useCallback, useState } from "react";

import { FreeDrawText } from "@/sx-layout/components/plotmap/components/freeDraw/models";

export const useSelectedText = () => {
  const [freeDrawText, setFreeDrawText] = useState<FreeDrawText>();

  const start = useCallback((text: FreeDrawText) => {
    setFreeDrawText(text);
  }, []);

  const end = useCallback(() => {
    setFreeDrawText(null);
  }, []);

  const change = useCallback(
    (text: FreeDrawText) => {
      setFreeDrawText({
        ...freeDrawText,
        content: text.content,
        color: text.color,
        font_size: text.font_size,
        timestamp: { ...freeDrawText.timestamp, update_date: text.timestamp.update_date },
      });
    },
    [freeDrawText]
  );

  const changeCoord = useCallback(
    (text: FreeDrawText) => {
      setFreeDrawText({
        ...freeDrawText,
        x: text.x,
        y: text.y,
        timestamp: { ...freeDrawText.timestamp, update_date: text.timestamp.update_date },
      });
    },
    [freeDrawText]
  );

  return { freeDrawText, start, end, change, changeCoord };
};
