import moment from "moment/moment";

import { ScheduleExportFile } from "@/components/list/Schedule/constants";
import {
  ScheduleOption,
  ScheduleResult,
  ScheduleSchedule,
  ScheduleScheduleItem,
} from "@/components/list/Schedule/types";
import {
  BulkUpdateScheduleRequest,
  ScheduleFilesRequest,
  ScheduleFilterParams,
  ScheduleResultsRequest,
  ScheduleSchedulesRequest,
  UpdateScheduleRequest,
} from "@/components/list/Schedule/types/api";
import * as constants from "@/constants";
import environments from "@/environments";

export const getScheduleOptions = (callback?) => {
  const endpoint = `${environments.API_BASE_URI}/schedules/options`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.SCHEDULES_BEGIN_GET_OPTIONS,
        };
      },
      success: (response: ScheduleOption) => {
        callback?.(response);

        return {
          type: constants.SCHEDULES_END_GET_OPTIONS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const getScheduleResults = (request: ScheduleResultsRequest, page: number) => {
  const endpoint = `${environments.API_BASE_URI}/results`;

  const query = new URLSearchParams();
  query.set("result_date", request.result_date);
  if (request.category_id) {
    request.category_id.forEach((v) => query.append("category_id", v));
  }
  if (request.area_id) {
    request.area_id.forEach((v) => query.append("area_id", v));
  }
  if (request.device_id) {
    request.device_id.forEach((v) => query.append("device_id", v));
  }
  if (request.manager_user_id) {
    request.manager_user_id.forEach((v) => query.append("manager_user_id", v));
  }
  query.set("start", request.start.toString());
  query.set("limit", request.limit.toString());

  return {
    type: constants.APP_CALL_API,
    endpoint: `${endpoint}?${query.toString()}`,
    method: "GET",
    request,
    callbacks: {
      begin: () => {
        return {
          type: constants.SCHEDULES_BEGIN_GET_RESULTS,
          payload: { request, page },
        };
      },
      success: (response: ScheduleResult) => {
        return {
          type: constants.SCHEDULES_END_GET_RESULTS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

const setFilterQueryParams = (query: URLSearchParams, request: ScheduleFilterParams) => {
  query.set("schedule_date", request.schedule_date);
  if (request.category_id) {
    request.category_id.forEach((v) => query.append("category_id", v));
  }
  if (request.area_id) {
    request.area_id.forEach((v) => query.append("area_id", v));
  }
  if (request.device_id) {
    request.device_id.forEach((v) => query.append("device_id", v));
  }
  if (request.manager_user_id) {
    request.manager_user_id.forEach((v) => query.append("manager_user_id", v));
  }
  request.field_p1 && query.set("field_p1", request.field_p1);
  if (request.field1) {
    request.field1.forEach((v) => query.append("field1", v));
  }
  request.field2 && query.set("field2", request.field2);
  if (request.approve1_user_id) {
    request.approve1_user_id.forEach((v) => query.append("approve1_user_id", v));
  }
  if (request.approve4_user_id) {
    request.approve4_user_id.forEach((v) => query.append("approve4_user_id", v));
  }
  request.field_t9 && query.set("field_t9", "true");
  (request.filter || []).forEach((v) => {
    query.append("filter", v.toString());
  });
};

export const getScheduleSchedules = (request: ScheduleSchedulesRequest, page: number, preventRefresh?: boolean) => {
  const endpoint = `${environments.API_BASE_URI}/schedules`;

  const query = new URLSearchParams();
  setFilterQueryParams(query, request);
  query.set("start", request.start.toString());
  query.set("limit", request.limit.toString());

  return {
    type: constants.APP_CALL_API,
    endpoint: `${endpoint}?${query.toString()}`,
    method: "GET",
    request,
    callbacks: {
      begin: () => {
        return {
          type: constants.SCHEDULES_BEGIN_GET_SCHEDULES,
          payload: { request, page, preventRefresh },
        };
      },
      success: (response: ScheduleSchedule) => {
        return {
          type: constants.SCHEDULES_END_GET_SCHEDULES,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

// 1件更新
export const updateSchedule = ({ input, onSuccess }: UpdateScheduleRequest) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/schedules/update/${input.schedule_task_id}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(input),
    callbacks: {
      begin: () => {
        return {
          type: constants.SCHEDULES_BEGIN_UPDATE_SCHEDULE,
          payload: input,
        };
      },
      success: (response: ScheduleScheduleItem) => {
        onSuccess?.(response);

        return {
          type: constants.SCHEDULES_END_UPDATE_SCHEDULE,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateScheduleWithTask = ({ input, diff_number_of_workers, onSuccess }: UpdateScheduleRequest) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/schedules/update_with_tasks/${input.schedule_task_id}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(input),
    callbacks: {
      begin: () => {
        return {
          type: constants.SCHEDULES_BEGIN_UPDATE_SCHEDULE_WITH_TASK,
          payload: input,
        };
      },
      success: (response: ScheduleScheduleItem) => {
        onSuccess?.(response);

        return {
          type: constants.SCHEDULES_END_UPDATE_SCHEDULE_WITH_TASK,
          payload: { ...response, diff_number_of_workers },
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const getAndUpdateScheduleOvertime = (request: ScheduleSchedulesRequest) => {
  const endpoint = `${environments.API_BASE_URI}/schedules`;

  const query = new URLSearchParams();
  setFilterQueryParams(query, request);
  query.set("start", request.start.toString());
  query.set("limit", request.limit.toString());

  return {
    type: constants.APP_CALL_API,
    endpoint: `${endpoint}?${query.toString()}`,
    method: "GET",
    request,
    callbacks: {
      success: (response: ScheduleSchedule) => {
        return {
          type: constants.SCHEDULES_END_UPDATE_OVERTIME,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateScheduleWithProcess = ({ input, onSuccess }: UpdateScheduleRequest) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/schedules/update_with_processes/${input.schedule_task_id}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(input),
    callbacks: {
      begin: () => {
        return {
          type: constants.SCHEDULES_BEGIN_UPDATE_SCHEDULE_WITH_PROCESS,
          payload: input,
        };
      },
      success: (response: ScheduleSchedule) => {
        onSuccess?.(response);

        return {
          type: constants.SCHEDULES_END_UPDATE_SCHEDULE_WITH_PROCESS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const approveSchedule = ({ input, onSuccess }: UpdateScheduleRequest) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/schedules/approve/${input.schedule_task_id}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(input),
    callbacks: {
      begin: () => {
        return {
          type: constants.SCHEDULES_BEGIN_APPROVE_SCHEDULE,
          payload: input,
        };
      },
      success: (response: ScheduleScheduleItem) => {
        onSuccess?.(response);

        return {
          type: constants.SCHEDULES_END_APPROVE_SCHEDULE,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

// 一括更新
export const bulkUpdateSchedule = ({ input, onSuccess }: BulkUpdateScheduleRequest) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/schedules/update`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(input),
    callbacks: {
      begin: () => {
        return {
          type: constants.SCHEDULES_BEGIN_BULK_UPDATE_SCHEDULE,
        };
      },
      success: (response: ScheduleScheduleItem) => {
        onSuccess?.(response);

        return {
          type: constants.SCHEDULES_END_BULK_UPDATE_SCHEDULE,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const bulkUpdateScheduleWithTask = ({ input, onSuccess }: BulkUpdateScheduleRequest) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/schedules/update_with_tasks`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(input),
    callbacks: {
      begin: () => {
        return {
          type: constants.SCHEDULES_BEGIN_BULK_UPDATE_SCHEDULE_WITH_TASK,
        };
      },
      success: (response: ScheduleScheduleItem) => {
        onSuccess?.(response);

        return {
          type: constants.SCHEDULES_END_BULK_UPDATE_SCHEDULE_WITH_TASK,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const bulkUpdateScheduleWithProcess = ({ input, onSuccess }: BulkUpdateScheduleRequest) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/schedules/update_with_processes`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(input),
    callbacks: {
      begin: () => {
        return {
          type: constants.SCHEDULES_BEGIN_BULK_UPDATE_SCHEDULE_WITH_PROCESS,
        };
      },
      success: (response: ScheduleScheduleItem) => {
        onSuccess?.(response);

        return {
          type: constants.SCHEDULES_END_BULK_UPDATE_SCHEDULE_WITH_PROCESS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const bulkApproveSchedule = ({ input, onSuccess }: BulkUpdateScheduleRequest) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/schedules/approve`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(input),
    callbacks: {
      begin: () => {
        return {
          type: constants.SCHEDULES_BEGIN_BULK_APPROVE_SCHEDULE,
        };
      },
      success: (response: ScheduleScheduleItem) => {
        onSuccess?.(response);

        return {
          type: constants.SCHEDULES_END_BULK_APPROVE_SCHEDULE,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const downloadSchedules = (request: ScheduleFilesRequest, callback) => {
  const endpoint = `${environments.API_BASE_URI}/schedules/files`;

  const query = new URLSearchParams();
  query.set("filetype", request.filetype.toString());
  query.set("format", request.format);
  setFilterQueryParams(query, request);
  if (request.filetype === ScheduleExportFile.SCHEDULE) {
    query.set("result_date", moment(request.schedule_date).subtract(1, "day").format("YYYY-MM-DD"));
    request.unskilled_worker_num && query.set("unskilled_worker_num", request.unskilled_worker_num);
    request.older_worker_num && query.set("older_worker_num", request.older_worker_num);
    request.younger_worker_num && query.set("younger_worker_num", request.younger_worker_num);
  }

  return {
    type: constants.APP_CALL_API,
    endpoint: `${endpoint}?${query.toString()}`,
    method: "GET",
    request,
    callbacks: {
      begin: () => {
        return {
          type: constants.SCHEDULES_BEGIN_GET_FILES,
        };
      },
      success: (response, filename) => {
        callback(response, filename);

        return { type: constants.SCHEDULES_END_GET_FILES };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};
