import { ClassValue } from "clsx";
import { ComponentProps } from "react";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import ReactSelect from "react-select";

import styles from "@/constants/reactSelectStyle";
import { cn } from "@/lib/classNames";

// MEMO: サイズのパターンを増やすときに"normal" | "large" のように増やしていく
type SelectSize = "normal";

type SelectProps<T extends FieldValues> = UseControllerProps<T> &
  ComponentProps<typeof ReactSelect> & {
    size?: SelectSize;
    className?: ClassValue;
    handleChange?: (value: number) => void;
    disabled?: boolean;
  };
type OptionType = {
  label: string;
  value: number;
};

export const Select = <T extends FieldValues>({
  className,
  name,
  control,
  rules,
  handleChange,
  disabled = false,
  ...restProps
}: SelectProps<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <ReactSelect
          className={cn("w-[100%]", className)}
          styles={styles}
          value={
            restProps.options.find(
              // @ts-ignore 暫定処置でts-ignoreしている
              (x) => String(x.value) === String(value)
            ) ?? null
          }
          onChange={(e: OptionType) => {
            if (handleChange) {
              handleChange(e?.value);
            }

            onChange(e ? e.value : null);
          }}
          isDisabled={disabled}
          menuPortalTarget={document.querySelector("body")}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#dea321",
              primary25: "#ffda8b",
            },
          })}
          {...restProps}
        />
      )}
    />
  );
};
