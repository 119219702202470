import { KonvaEventObject } from "konva/lib/Node";
import React, { useCallback, useEffect, useState } from "react";
import { Circle, Layer, Line, Stage } from "react-konva";
import { useKeyPress, useKeyPressEvent } from "react-use";

import { Size } from "../models";

type Props = {
  isDrawing: boolean;
  mainScreenElem: HTMLDivElement;
  mapRatio: number;
  drawingCoords: [number, number][];
  onAddFreeDrawingCoord: (coord: [number, number]) => void;
  onAddKeepOutArea: () => void;
  onFreeDrawClear: () => void;
  color: string;
};

export const KeepOutAreaPolygonDrawStage: React.FC<Props> = (props) => {
  const [isVerticalAndHorizontalMode] = useKeyPress("Shift");
  const [mousePoint, setMousePoint] = useState<[number, number]>();
  const [drawTargetPoint, setDrawTargetPoint] = useState<[number, number]>();
  const [stageSize, setStageSize] = useState<Size>({ width: 0, height: 0 });

  const createKeepOutAreaPolygon = useCallback(() => {
    props.onAddKeepOutArea();
  }, [props]);

  const getPointVerticalAndHorizontal = useCallback(
    (drawingPoints: [number, number][], mousePoint: [number, number] | undefined): [number, number] | undefined => {
      // Shiftを押したときは水平もしくは垂直の位置にスナップする
      const lastDrawPoint = drawingPoints.at(-1);
      if (lastDrawPoint === undefined || mousePoint == undefined) {
        return undefined;
      }
      const lastDrawPointX = lastDrawPoint[0] * props.mapRatio;
      const lastDrawPointY = lastDrawPoint[1] * props.mapRatio;
      if (Math.abs(mousePoint[0] - lastDrawPointX) < Math.abs(mousePoint[1] - lastDrawPointY)) {
        return [lastDrawPointX, mousePoint[1]];
      }

      return [mousePoint[0], lastDrawPointY];
    },
    [props.mapRatio]
  );

  const entryVerticalAndHorizontalMode = useCallback(() => {
    setDrawTargetPoint(getPointVerticalAndHorizontal(props.drawingCoords, mousePoint));
  }, [props.drawingCoords, mousePoint]);

  const exitVerticalAndHorizontalMode = useCallback(() => {
    setDrawTargetPoint(mousePoint);
  }, [mousePoint]);

  useKeyPressEvent("Shift", entryVerticalAndHorizontalMode, exitVerticalAndHorizontalMode);
  useKeyPressEvent("Escape", () => props.onFreeDrawClear());

  const handleClick = useCallback(
    (event) => {
      // 描画範囲外をクリックしたときにキャンセルする
      const paths = event.composedPath();
      if (
        props.isDrawing &&
        !paths.some((path: HTMLElement) => ["add-custom-keepout-area-button", "polygon-drawer"].includes(path.id))
      ) {
        props.onFreeDrawClear();
      }
    },
    [props.isDrawing]
  );
  useEffect(() => {
    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  const handleMouseDown = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      if (e.evt.button === 2) {
        // 右クリックを押したとき、3点以上あれば完了、そうでなければキャンセルとする
        if (props.drawingCoords.length < 3) {
          props.onFreeDrawClear();
        } else {
          createKeepOutAreaPolygon();
        }

        return;
      }
      props.onAddFreeDrawingCoord([drawTargetPoint[0] / props.mapRatio, drawTargetPoint[1] / props.mapRatio]);
    },
    [
      props.onAddFreeDrawingCoord,
      isVerticalAndHorizontalMode,
      drawTargetPoint,
      props.drawingCoords,
      createKeepOutAreaPolygon,
      props.mapRatio,
    ]
  );

  const handleMouseMove = useCallback(
    (event: KonvaEventObject<MouseEvent>) => {
      const pos = event.target.getStage().getPointerPosition();
      setMousePoint([pos.x, pos.y]);

      if (isVerticalAndHorizontalMode) {
        setDrawTargetPoint(getPointVerticalAndHorizontal(props.drawingCoords, [pos.x, pos.y]));
      } else {
        setDrawTargetPoint([pos.x, pos.y]);
      }
    },
    [isVerticalAndHorizontalMode, props.drawingCoords]
  );

  const linePoints = props.drawingCoords.map((p) => [p[0] * props.mapRatio, p[1] * props.mapRatio]);

  useEffect(() => {
    if (!props.mainScreenElem) return;
    setStageSize({ width: props.mainScreenElem.clientWidth, height: props.mainScreenElem.clientHeight });
  }, [props.mainScreenElem?.clientWidth, props.mainScreenElem?.clientHeight]);

  if (!props.isDrawing) return <></>;

  return (
    <Stage
      id="polygon-drawer"
      {...stageSize}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      className="cursor-crosshair"
      onContextMenu={(e) => e.evt.preventDefault()}
    >
      <Layer>
        {/* 描画中の線 */}
        <Line points={linePoints.flat()} stroke={props.color} strokeWidth={3} />
        {/* 描画中の線(破線) */}
        {linePoints.length > 0 && (
          <Line
            stroke="black"
            points={[linePoints.at(-1)[0], linePoints.at(-1)[1], drawTargetPoint[0], drawTargetPoint[1]]}
            dash={[10, 10]}
            strokeWidth={3}
          />
        )}
        {/* 描画中の線のつなぎ目 */}
        {linePoints.map((point, index) => (
          <Circle
            key={`free-drawing-point-${index}`}
            onMouseDown={(event) => {
              if (linePoints.length < 3) {
                props.onFreeDrawClear();
              } else if (index === 0) {
                createKeepOutAreaPolygon();
                event.cancelBubble = true;
              }
            }}
            x={point[0]}
            y={point[1]}
            stroke="black"
            fill="white"
            radius={8}
          />
        ))}
      </Layer>
    </Stage>
  );
};
