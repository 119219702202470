import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as z from "zod";

import actions from "@/actions";
import { Button } from "@/sx-layout/common/Button";
import { FormLabel, FormRow, Select } from "@/sx-layout/common/Form";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "@/sx-layout/common/Modal";
import { ColorPickerFormController } from "@/sx-layout/components/plotmap/components/freeDraw/components/ColorPickerFormController";
import { FreeDrawArrow } from "@/sx-layout/components/plotmap/components/freeDraw/models";

type FreeDrawArrowForm = { color: string; thickness: number };

type Props = {
  companyId: number;
  freeDrawArrow?: FreeDrawArrow;
  color: string;
  thickness: number;
  onSaveDrawingArrow: (color: string, thickness: number) => void;
  onSave: (arrow: FreeDrawArrow) => void;
  onClose: () => void;
};

const ARROW_THICKNESS_OPTIONS = [
  { label: "10pt", value: 10 },
  { label: "7pt", value: 7 },
  { label: "5pt", value: 5 },
  { label: "3pt", value: 3 },
  { label: "1pt", value: 1 },
];

export const EditFreeDrawArrowModal: FC<Props> = ({
  companyId,
  freeDrawArrow,
  color,
  thickness,
  onSaveDrawingArrow,
  onSave,
  onClose,
}) => {
  const { t } = useTranslation();

  const formSchema = z.object({
    color: z.string(),
    thickness: z.number(),
  });
  const { handleSubmit, control } = useForm<FreeDrawArrowForm>({
    resolver: zodResolver(formSchema),
    reValidateMode: "onSubmit",
    defaultValues: {
      color: color,
      thickness: thickness,
    },
  });

  const dispatch = useDispatch();
  const handleSave = useCallback(
    (values: FreeDrawArrowForm) => {
      if (freeDrawArrow) {
        dispatch(
          actions.arrows.updateArrow({
            input: {
              company_id: companyId,
              arrow_id: freeDrawArrow.arrow_id,
              color: values.color,
              thickness: values.thickness,
              coords: freeDrawArrow.coords,
              timestamp: {
                update_date: freeDrawArrow.timestamp?.update_date,
              },
            },
            onSuccess: (arrow: FreeDrawArrow) => onSave(arrow),
          })
        );
      } else {
        onSaveDrawingArrow(values.color, values.thickness);
      }
    },
    [dispatch, freeDrawArrow, onSave]
  );

  return (
    <Modal
      id="arrow-edit-modal"
      onClose={onClose}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      style={{ content: { overflow: "visible" } }}
    >
      <ModalHeader>{t("arrow_edit")}</ModalHeader>
      <ModalBody size="small" className="modal-body items-start">
        <form>
          <FormRow className="items-start">
            <FormLabel className="mt-2">{t("arrow_color")}</FormLabel>
            <ColorPickerFormController name="color" control={control} defaultColor={color} />
          </FormRow>
          <FormRow>
            <FormLabel>{t("thickness")}</FormLabel>
            <Select name="thickness" control={control} options={ARROW_THICKNESS_OPTIONS} className="w-100" />
          </FormRow>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button buttonType="cancel" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button onClick={handleSubmit(handleSave)}>{t("save")}</Button>
      </ModalFooter>
    </Modal>
  );
};
