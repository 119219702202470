import clsx from "clsx";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { getConstructionId } from "../lib/common";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverMenu: "",
    };
  }

  mouseEnterHandler(menu) {
    this.setState({ ...this.state, hoverMenu: menu });
  }

  mouseLeaveHandler() {
    this.setState({ ...this.state, hoverMenu: "" });
  }

  hideSubmenu() {
    this.setState({ ...this.state, hoverMenu: "" });
  }

  clickHandler(menu) {
    this.props.changeMenu(menu);
    this.props.closeHandler();
  }

  render() {
    const { menus, t } = this.props;

    const isList =
      this.props.menu === "list" ||
      this.props.menu === "approval" ||
      this.props.menu === "inspection" ||
      this.props.menu === "schedule_list" ||
      this.props.menu === "schedule_eneos_kawasaki" ||
      this.props.menu === "schedule_chita" ||
      this.props.menu === "schedule_chiba";

    const isTool = this.props.menu === "qrcode" || this.props.menu === "asset" || this.props.menu === "unworkdays";

    const isMaster =
      this.props.menu === "area" ||
      this.props.menu === "category" ||
      this.props.menu === "checkpoint" ||
      this.props.menu === "facility_management" ||
      this.props.menu === "construction_management" ||
      this.props.menu === "primary_charge" ||
      this.props.menu === "other" ||
      this.props.menu === "device" ||
      this.props.menu === "process_major_class" ||
      this.props.menu === "process_middle_class";

    const isSwitchable = this.props.session.funcLayout === 1 && this.props.session.funcProgress === 1;

    const isActive = (id) => {
      if (!getConstructionId()) {
        return false;
      }

      return menus.some((menu) => menu.menu_id === id && menu.active);
    };

    const isActiveSome = (ids) => {
      if (!getConstructionId()) {
        return false;
      }

      return ids.some((id) => menus.some((menu) => menu.menu_id === id && menu.active));
    };

    return (
      <nav className="global-nav">
        <ul>
          <li
            data-test-id="button-nav-list"
            className={`nav00 dropdown ${isSwitchable ? "" : "disabled"}`}
            onMouseEnter={() => this.mouseEnterHandler("switching")}
            onMouseLeave={() => this.mouseLeaveHandler()}
          >
            <a>
              <img src="./img/sx-progress.svg" alt="progress" />
            </a>
            {this.state.hoverMenu === "switching" && isSwitchable && (
              <ul className="dropdown-menu" onClick={() => this.hideSubmenu()}>
                <li data-test-id="button-nav-approval" className="">
                  <a onClick={() => this.props.switchSystem("progress")}>
                    <img src="./img/sx-progress.svg" alt="progress" />
                    {t("sx_progress")}
                  </a>
                </li>
                <li data-test-id="button-nav-approval" className="active">
                  <a onClick={() => this.props.switchSystem("layout")}>
                    <img src="./img/sx-layout.svg" alt="layout" />
                    {t("sx_layout")}
                  </a>
                </li>
              </ul>
            )}
          </li>
          <li
            data-test-id="button-nav-home"
            className={`nav01 ${this.props.menu === "home" ? "on" : ""}`}
            onMouseEnter={() => this.mouseEnterHandler("home")}
            onMouseLeave={() => this.mouseLeaveHandler()}
          >
            <a onClick={() => this.clickHandler("home")}>{t("home")}</a>
          </li>
          {isActive(1001) && (
            <li
              data-test-id="button-nav-import"
              className={`nav02 ${this.props.menu === "dataimport" ? "on" : ""}`}
              onMouseEnter={() => this.mouseEnterHandler("import")}
              onMouseLeave={() => this.mouseLeaveHandler()}
            >
              <a onClick={() => this.clickHandler("dataimport")}>{t("data_import")}</a>
            </li>
          )}
          {isActive(1002) && (
            <li
              data-test-id="button-nav-summary"
              className={`nav03 ${this.props.menu === "summary" ? "on" : ""}`}
              onMouseEnter={() => this.mouseEnterHandler("summary")}
              onMouseLeave={() => this.mouseLeaveHandler()}
            >
              <a onClick={() => this.clickHandler("summary")}>{t("construction_summary")}</a>
            </li>
          )}
          {isActive(1003) && (
            <li
              data-test-id="button-nav-matrix"
              className={`nav04 ${this.props.menu === "matrix" ? "on" : ""}`}
              onMouseEnter={() => this.mouseEnterHandler("matrix")}
              onMouseLeave={() => this.mouseLeaveHandler()}
            >
              <a onClick={() => this.clickHandler("matrix")}>{t("progress_manage")}</a>
            </li>
          )}
          {isActive(1009) && (
            <li data-test-id="button-nav-shedule" className={clsx("nav09", this.props.menu === "schedule" ? "on" : "")}>
              <a onClick={() => this.clickHandler("schedule")}>{t("schedule_list")}</a>
            </li>
          )}
          {isActive(1004) && (
            <li
              data-test-id="button-nav-system"
              className={`nav05 ${this.props.menu === "system" ? "on" : ""}`}
              onMouseEnter={() => this.mouseEnterHandler("system")}
              onMouseLeave={() => this.mouseLeaveHandler()}
            >
              <a onClick={() => this.clickHandler("system")}>{t("system_list")}</a>
            </li>
          )}
          {(isActive(1005) || isActive(1006) || isActive(1101) || isActive(1102)) && (
            <li
              data-test-id="button-nav-list"
              className={`nav05 dropdown ${isList ? "on" : ""}`}
              onMouseEnter={() => this.mouseEnterHandler("list")}
              onMouseLeave={() => this.mouseLeaveHandler()}
            >
              <a>{t("list")}</a>
              {this.state.hoverMenu === "list" && (
                <ul className="dropdown-menu" onClick={() => this.hideSubmenu()}>
                  {isActive(1005) && (
                    <li
                      data-test-id="button-nav-approval"
                      className={`plan ${this.props.menu === "approval" ? "on" : ""}`}
                    >
                      <a onClick={() => this.clickHandler("approval")}>{t("plan_approval")}</a>
                    </li>
                  )}
                  {/* isActive(1006) && (
                    <li className={`inspection ${this.props.menu === 'inspection' ? 'on' : ''}`}>
                      <a onClick={() => this.props.changeMenu('inspection')}>{t('inspection')}</a>
                    </li>
                  ) */}
                  {isActive(1101) && (
                    <li
                      data-test-id="button-nav-shedule"
                      className={`schedule ${this.props.menu === "schedule_eneos_kawasaki" ? "on" : ""}`}
                    >
                      <a onClick={() => this.clickHandler("schedule_eneos_kawasaki")}>{t("schedule_list")}</a>
                    </li>
                  )}
                  {isActive(1102) && (
                    <li
                      data-test-id="button-nav-shedule"
                      className={`schedule ${this.props.menu === "schedule_chita" ? "on" : ""}`}
                    >
                      <a onClick={() => this.clickHandler("schedule_chita")}>{t("schedule_list")}</a>
                    </li>
                  )}
                  {isActive(1103) && (
                    <li
                      data-test-id="button-nav-shedule"
                      className={`schedule ${this.props.menu === "schedule_chiba" ? "on" : ""}`}
                    >
                      <a onClick={() => this.clickHandler("schedule_chiba")}>{t("schedule_list")}</a>
                    </li>
                  )}
                </ul>
              )}
            </li>
          )}
          {(isActive(1007) || isActive(1008)) && (
            <li
              data-test-id="button-nav-tool"
              className={`nav05 dropdown ${isTool ? "on" : ""}`}
              onMouseEnter={() => this.mouseEnterHandler("tool")}
              onMouseLeave={() => this.mouseLeaveHandler()}
            >
              <a>{t("tool")}</a>
              {this.state.hoverMenu === "tool" && (
                <ul className="dropdown-menu" onClick={() => this.hideSubmenu()}>
                  {isActive(1007) && (
                    <li
                      data-test-id="button-nav-qrcode"
                      className={`nav07 ${this.props.menu === "qrcode" ? "on" : ""}`}
                    >
                      <a onClick={() => this.clickHandler("qrcode")}>{t("qr_export")}</a>
                    </li>
                  )}
                  {isActive(1008) && (
                    <li data-test-id="button-nav-asset" className={`nav07 ${this.props.menu === "asset" ? "on" : ""}`}>
                      <a onClick={() => this.clickHandler("asset")}>{t("asset_list")}</a>
                    </li>
                  )}
                  {isActive(1010) && (
                    <li
                      data-test-id="button-nav-holiday"
                      className={`nav07 ${this.props.menu === "unworkdays" ? "on" : ""}`}
                    >
                      <a onClick={() => this.clickHandler("unworkdays")}>{t("unwork_days") + t("settings")}</a>
                    </li>
                  )}
                </ul>
              )}
            </li>
          )}
          {isActiveSome([1011, 1012, 1021, 1022, 1023, 1024, 1025, 1026, 1031, 1032]) && (
            <li
              data-test-id="button-nav-master"
              className={`nav08 dropdown ${isMaster ? "on" : ""}`}
              onMouseEnter={() => this.mouseEnterHandler("master")}
              onMouseLeave={() => this.mouseLeaveHandler()}
            >
              <a>{t("master_manage")}</a>
              {this.state.hoverMenu === "master" && (
                <ul className="dropdown-menu" onClick={() => this.hideSubmenu()}>
                  {isActive(1011) && (
                    <li data-test-id="button-nav-category" className={this.props.menu === "category" ? "on" : ""}>
                      <a onClick={() => this.clickHandler("category")}>{t("machines_category")}</a>
                    </li>
                  )}
                  {isActive(1025) && (
                    <li
                      data-test-id="button-nav-primary_charge"
                      className={this.props.menu === "primary_charge" ? "on" : ""}
                    >
                      <a onClick={() => this.clickHandler("primary_charge")}>{t("primary_charge")}</a>
                    </li>
                  )}
                  {isActive(1012) && (
                    <li data-test-id="button-nav-checkpoint" className={this.props.menu === "checkpoint" ? "on" : ""}>
                      <a onClick={() => this.clickHandler("checkpoint")}>{t("checkpoint")}</a>
                    </li>
                  )}
                  {isActiveSome([1021, 1022, 1023, 1024, 1026]) && (
                    <li>
                      <span className="category-ttl">＜{t("category")}＞</span>
                      <ul>
                        {isActive(1021) && (
                          <li data-test-id="button-nav-area" className={this.props.menu === "area" ? "on" : ""}>
                            <a onClick={() => this.clickHandler("area")}>{t("area")}</a>
                          </li>
                        )}
                        {isActive(1022) && (
                          <li data-test-id="button-nav-device" className={this.props.menu === "device" ? "on" : ""}>
                            <a onClick={() => this.clickHandler("device")}>{t("device")}</a>
                          </li>
                        )}
                        {isActive(1023) && (
                          <li
                            data-test-id="button-nav-facility_management"
                            className={this.props.menu === "facility_management" ? "on" : ""}
                          >
                            <a onClick={() => this.clickHandler("facility_management")}>{t("facility_management")}</a>
                          </li>
                        )}
                        {isActive(1024) && (
                          <li
                            data-test-id="button-nav-construction_management"
                            className={this.props.menu === "construction_management" ? "on" : ""}
                          >
                            <a onClick={() => this.clickHandler("construction_management")}>
                              {t("construction_management")}
                            </a>
                          </li>
                        )}
                        {isActive(1026) && (
                          <li data-test-id="button-nav-other" className={this.props.menu === "other" ? "on" : ""}>
                            <a onClick={() => this.clickHandler("other")}>{t("other")}</a>
                          </li>
                        )}
                      </ul>
                    </li>
                  )}
                  {isActiveSome([1031, 1032]) && (
                    <li>
                      <span className="category-ttl">＜{t("job_category")}＞</span>
                      <ul>
                        {isActive(1031) && (
                          <li
                            data-test-id="button-nav-process_major_class"
                            className={this.props.menu === "process_major_class" ? "on" : ""}
                          >
                            <a onClick={() => this.clickHandler("process_major_class")}>{t("process_major_class")}</a>
                          </li>
                        )}
                        {isActive(1032) && (
                          <li
                            data-test-id="button-nav-process_middle_class"
                            className={this.props.menu === "process_middle_class" ? "on" : ""}
                          >
                            <a onClick={() => this.clickHandler("process_middle_class")}>{t("process_middle_class")}</a>
                          </li>
                        )}
                      </ul>
                    </li>
                  )}
                </ul>
              )}
            </li>
          )}
        </ul>
      </nav>
    );
  }
}

Navigation.propTypes = {
  menu: PropTypes.string.isRequired,
  menus: PropTypes.array.isRequired,
  changeMenu: PropTypes.func.isRequired,
  switchSystem: PropTypes.func.isRequired,
};

export default withTranslation()(Navigation);
