import * as constants from "@/constants";
import environments from "@/environments";
import {
  CopyArrowProps,
  CreateArrowProps,
  DeleteArrowProps,
  UpdateArrowCoordinatesProps,
  UpdateArrowProps,
} from "@/sx-layout/components/plotmap/actions/types";

export const createArrow = (props: CreateArrowProps) => {
  const endpoint = `${environments.API_BASE_URI}/arrows/create`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(toRequest(props.input)),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_CREATE_ARROWS,
        };
      },
      success: (response) => {
        const arrow = fromResponse(response, props.input.coords);
        props.onSuccess?.(arrow);

        return {
          type: constants.PLOT_MAP_END_CREATE_ARROWS,
          payload: arrow,
        };
      },
      error: (response) => {
        props.onError?.();

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const deleteArrow = (props: DeleteArrowProps) => {
  const endpoint = `${environments.API_BASE_URI}/arrows/delete/${props.input.arrow_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    body: JSON.stringify({ timestamp: props.input.timestamp }),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_DELETE_ARROWS,
        };
      },
      success: (response) => {
        props.onSuccess?.(response);

        return {
          type: constants.PLOT_MAP_END_DELETE_ARROWS,
          payload: {
            ...response,
            arrow_id: props.input.arrow_id,
          },
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateArrow = (props: UpdateArrowProps) => {
  const endpoint = `${environments.API_BASE_URI}/arrows/update/${props.input.arrow_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(toRequest(props.input)),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_UPDATE_ARROWS,
        };
      },
      success: (response) => {
        const arrow = fromResponse(response, props.input.coords);
        props.onSuccess?.(arrow);

        return {
          type: constants.PLOT_MAP_END_UPDATE_ARROWS,
          payload: arrow,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateArrowCoordinates = (props: UpdateArrowCoordinatesProps) => {
  const endpoint = `${environments.API_BASE_URI}/arrows/coord/update/${props.input.arrow_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(toRequest(props.input)),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_UPDATE_ARROW_COORDINATES,
        };
      },
      success: (response) => {
        const arrow = fromResponse(response, props.input.coords);
        props.onSuccess?.(arrow);

        return {
          type: constants.PLOT_MAP_END_UPDATE_ARROW_COORDINATES,
          payload: arrow,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const copyArrow = (props: CopyArrowProps) => {
  const endpoint = `${environments.API_BASE_URI}/arrows/copy`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(props.input),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_COPY_ARROWS,
        };
      },
      success: (response) => {
        props.onSuccess?.(response);

        return {
          type: constants.PLOT_MAP_END_COPY_ARROWS,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

const toRequest = (input) => {
  return {
    ...input,
    color: input.color?.replace("#", ""),
    coords: JSON.stringify(input.coords.map((coord) => [Math.round(coord[0]), Math.round(coord[1])])),
  };
};

const fromResponse = (response, coords) => {
  return {
    ...response,
    color: `#${response.color}`,
    // NOTE: リフレッシュするまでは座標を丸めない
    coords,
  };
};
