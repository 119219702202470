import * as constants from "../constants/index";
import restoreState from "../lib/restoreState";
import storageManager from "../lib/storageManager";

const initialState = {
  areaIds: [],
  deviceIds: [],
  categoryId: 0,
  facilityManagementIds: [],
  constructionManagementIds: [],
  primaryChargeIds: [],
  otherIds: [],
  companyId: 0,
  userId: 0,
  groupId: 0,
  items: [],
  fetching: false,
  isError: false,
  validRequiredParameter: undefined,
};

const getInitialSearchConditions = (onlyLocal = false) => {
  const urlState = restoreState();
  let areaIds = [];
  let deviceIds = [];
  let categoryId = 0;
  let facilityManagementIds = [];
  let constructionManagementIds = [];
  let primaryChargeIds = [];
  let otherIds = [];
  let companyId = 0;
  let groupId = 0;
  let userId = 0;

  if (storageManager.getConstructionItem("qrSearchParams") !== null) {
    const params = JSON.parse(storageManager.getConstructionItem("qrSearchParams"));

    if (typeof params.areaIds !== "undefined") {
      areaIds = params.areaIds;
    }
    if (typeof params.deviceIds !== "undefined") {
      deviceIds = params.deviceIds;
    }
    if (typeof params.categoryId !== "undefined") {
      categoryId = params.categoryId;
    }
    if (typeof params.facilityManagementIds !== "undefined") {
      facilityManagementIds = params.facilityManagementIds;
    }
    if (typeof params.constructionManagementIds !== "undefined") {
      constructionManagementIds = params.constructionManagementIds;
    }
    if (typeof params.primaryChargeIds !== "undefined") {
      primaryChargeIds = params.primaryChargeIds;
    }
    if (typeof params.otherIds !== "undefined") {
      otherIds = params.otherIds;
    }
    if (typeof params.groupId !== "undefined") {
      groupId = params.groupId;
    }
    if (typeof params.companyId !== "undefined") {
      companyId = params.companyId;
    }
    if (typeof params.userId !== "undefined") {
      userId = params.userId;
    }
    if (typeof params.groupId !== "undefined") {
      groupId = params.groupId;
    }
    if (typeof params.companyId !== "undefined") {
      companyId = params.companyId;
    }
  }

  if (!onlyLocal && urlState && urlState.menu === "qrcode") {
    areaIds = urlState.area_ids
      ? decodeURIComponent(urlState.area_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    deviceIds = urlState.device_ids
      ? decodeURIComponent(urlState.device_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    categoryId = urlState.category_id ? Number(urlState.category_id) : 0;
    facilityManagementIds = urlState.facility_management_ids
      ? decodeURIComponent(urlState.facility_management_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    constructionManagementIds = urlState.construction_management_ids
      ? decodeURIComponent(urlState.construction_management_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    primaryChargeIds = urlState.primary_charge_ids
      ? decodeURIComponent(urlState.primary_charge_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    otherIds = urlState.other_ids
      ? decodeURIComponent(urlState.other_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    // companyId = urlState.company_id ? Number(urlState.company_id) : 0;
    userId = urlState.user_id ? Number(urlState.user_id) : 0;
    groupId = urlState.group_id ? Number(urlState.group_id) : 0;
  }

  return {
    areaIds,
    deviceIds,
    categoryId,
    facilityManagementIds,
    constructionManagementIds,
    primaryChargeIds,
    otherIds,
    companyId,
    userId,
    groupId,
  };
};

const qrcode = (state = initialState, action) => {
  switch (action.type) {
    case constants.APP_CONTENTS_READY: {
      return {
        ...state,
        ...getInitialSearchConditions(),
      };
    }
    case constants.QRCODE_SET_VALID_REQUIRED: {
      return {
        ...state,
        validRequiredParameter: Boolean(action.payload),
      };
    }
    case constants.CONSTRUCTION_END_FETCH_MASTERS: {
      const { categories } = action.payload;

      if (state.categoryId === 0 && categories.length > 0) {
        return {
          ...state,
          categoryId: categories[0].category_id,
        };
      }

      return state;
    }
    case constants.QRCODE_CHANGE_AREA: {
      return {
        ...state,
        areaIds: action.payload,
      };
    }
    case constants.QRCODE_CHANGE_DEVICE: {
      return {
        ...state,
        deviceIds: action.payload,
      };
    }
    case constants.QRCODE_CHANGE_FACILITY_MANAGEMENT: {
      return {
        ...state,
        facilityManagementIds: action.payload,
      };
    }
    case constants.QRCODE_CHANGE_CATEGORY: {
      return {
        ...state,
        categoryId: action.payload,
      };
    }
    case constants.QRCODE_CHANGE_CONSTRUCTION_MANAGEMENT: {
      return {
        ...state,
        constructionManagementIds: action.payload,
      };
    }
    case constants.QRCODE_CHANGE_PRIMARY_CHARGE: {
      return {
        ...state,
        primaryChargeIds: action.payload,
      };
    }
    case constants.QRCODE_CHANGE_OTHER: {
      return {
        ...state,
        otherIds: action.payload,
      };
    }
    case constants.QRCODE_CHANGE_COMPANY: {
      return {
        ...state,
        companyId: action.payload,
      };
    }
    case constants.QRCODE_CHANGE_USER: {
      return {
        ...state,
        userId: action.payload,
      };
    }
    case constants.QRCODE_CHANGE_GROUP: {
      return {
        ...state,
        groupId: action.payload,
      };
    }
    case constants.QRCODE_BEGIN_SEARCH: {
      return {
        ...state,
        fetching: true,
        isError: false,
      };
    }
    case constants.QRCODE_END_SEARCH: {
      return {
        ...state,
        items: action.payload,
        fetching: false,
      };
    }
    case constants.QRCODE_CLEAR_SEARCH: {
      return {
        ...initialState,
        items: state.items,
      };
    }
    case constants.COMMON_BEGIN_CHANGE_TASK_STATUS: {
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.task_id === action.payload.task_id) {
            return {
              ...item,
              processing: true,
              error: false,
            };
          }

          return item;
        }),
      };
    }
    case constants.COMMON_END_CHANGE_TASK_STATUS: {
      const { task_id, status, display_status } = action.payload.task;

      return {
        ...state,
        items: state.items.map((item) => {
          if (item.task_id === task_id) {
            return {
              ...item,
              status: status,
              display_status: display_status,
              processing: false,
              error: false,
            };
          }

          return item;
        }),
      };
    }
    case constants.COMMON_ERROR_CHANGE_TASK_STATUS: {
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.task_id === task_id) {
            return {
              ...item,
              processing: false,
              error: true,
            };
          }

          return item;
        }),
      };
    }
    case constants.QRCODE_BEGIN_SEARCH_INFINITE: {
      return {
        ...state,
        fetching: true,
        isError: false,
      };
    }
    case constants.QRCODE_END_SEARCH_INFINITE: {
      return {
        ...state,
        items: [...state.items, ...action.payload],
        fetching: false,
      };
    }
    case constants.DOWNLOAD_ZIP: {
      return state;
    }
    case constants.QRCODE_REVERT_CONDITION: {
      return {
        ...state,
        ...getInitialSearchConditions(true),
      };
    }
    case constants.APP_SHOW_ERROR: {
      if (action.errorFrom === "qrcode") {
        return {
          ...state,
          fetching: false,
          isError: true,
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export default qrcode;
