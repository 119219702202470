import _ from "lodash";

import * as constants from "../constants/index";
import restoreState from "../lib/restoreState";
import storageManager from "../lib/storageManager";
import { MatrixSearchParams } from "@/models/matrix";

export type MatrixSearchState = MatrixSearchParams & {
  validRequired?: boolean | undefined;
};

const initialConditions: MatrixSearchState = {
  categoryId: 0,
  primaryChargeId: 0,
  areaIds: [],
  deviceIds: [],
  facilityManagementIds: [],
  constructionManagementIds: [],
  otherIds: [],
  systemIds: [],
  companyIds: [],
  userIds: [],
  groupIds: [],
  processMiddleClassIds: [],
  itemText: "",
  processText: "",
  regulation: "",
  filters: [],
};

const getInitialSearchConditions = (onlyLocal = false) => {
  const urlState = restoreState();

  let {
    categoryId,
    primaryChargeId,
    areaIds,
    deviceIds,
    facilityManagementIds,
    constructionManagementIds,
    otherIds,
    systemIds,
    companyIds,
    userIds,
    groupIds,
    processMiddleClassIds,
    itemText,
    processText,
    regulation,
    filters,
  } = initialConditions;

  if (storageManager.getConstructionItem("matrixSearchParams") !== null) {
    const params = JSON.parse(storageManager.getConstructionItem("matrixSearchParams"));

    categoryId = params.categoryId;
    primaryChargeId = params.primaryChargeId;
    areaIds = params.areaIds;
    deviceIds = params.deviceIds;
    facilityManagementIds = params.facilityManagementIds;
    constructionManagementIds = params.constructionManagementIds;
    otherIds = params.otherIds;
    systemIds = params.systemIds;
    companyIds = params.companyIds;
    userIds = params.userIds;
    groupIds = params.groupIds;
    processMiddleClassIds = params.processMiddleClassIds;
    itemText = params.itemText;
    processText = params.processText;
    regulation = params.regulation;
    filters = params.filters;
  }

  if (!onlyLocal && urlState && urlState.menu === "matrix" && Object.keys(urlState).length > 2) {
    categoryId = urlState.category_id ? Number(urlState.category_id) : categoryId;
    primaryChargeId = urlState.primary_charge_id ? Number(urlState.primary_charge_id) : primaryChargeId;
    areaIds = urlState.area_ids
      ? decodeURIComponent(urlState.area_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    deviceIds = urlState.device_ids
      ? decodeURIComponent(urlState.device_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    facilityManagementIds = urlState.facility_management_ids
      ? decodeURIComponent(urlState.facility_management_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    constructionManagementIds = urlState.construction_management_ids
      ? decodeURIComponent(urlState.construction_management_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    otherIds = urlState.other_ids
      ? decodeURIComponent(urlState.other_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    systemIds = urlState.system_ids
      ? decodeURIComponent(urlState.system_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    companyIds = urlState.company_ids
      ? decodeURIComponent(urlState.company_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    userIds = urlState.user_ids
      ? decodeURIComponent(urlState.user_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    groupIds = urlState.group_ids
      ? decodeURIComponent(urlState.group_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    processMiddleClassIds = urlState.process_middle_class_ids
      ? decodeURIComponent(urlState.process_middle_class_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    itemText = urlState.item_text ? decodeURIComponent(urlState.item_text) : "";
    processText = urlState.process_text ? decodeURIComponent(urlState.process_text) : "";
    regulation = urlState.regulation ? decodeURIComponent(urlState.regulation) : "";
    filters = urlState.filter
      ? decodeURIComponent(urlState.filter)
          .split(",")
          .map((f) => Number(f))
      : [];
  }

  const filterMaster = [1, 2, 3, 4];
  filters = filters.filter((f) => filterMaster.indexOf(f) >= 0);

  return {
    categoryId,
    areaIds,
    deviceIds,
    facilityManagementIds,
    constructionManagementIds,
    primaryChargeId,
    otherIds,
    systemIds,
    companyIds,
    userIds,
    groupIds,
    processMiddleClassIds,
    itemText,
    processText,
    regulation,
    filters,
  };
};

const initialState = { ...initialConditions, validRequired: undefined };
const { NONE_VALUE } = constants;

const matrixSearch = (
  state = {
    ...initialState,
  },
  action
) => {
  switch (action.type) {
    case constants.APP_CONTENTS_READY: {
      return {
        ...state,
        ...getInitialSearchConditions(),
      };
    }
    case constants.MATRIXSEARCH_REVERT_CONDITION: {
      return {
        ...state,
        ...getInitialSearchConditions(true),
        validRequired: undefined,
      };
    }
    case constants.MATRIXSEARCH_SET_VALID_REQUIRED: {
      return {
        ...state,
        validRequired: Boolean(action.payload),
      };
    }
    case constants.MATRIXSEARCH_CHANGE_CATEGORY: {
      const categoryId = action.payload;

      return {
        ...state,
        categoryId,
      };
    }
    case constants.MATRIXSEARCH_CHANGE_PRIMARY_CHARGE: {
      const primaryChargeId = action.payload;

      return {
        ...state,
        primaryChargeId,
      };
    }
    case constants.MATRIXSEARCH_CHANGE_AREA: {
      const { payload } = action;
      const areaIds =
        !state.areaIds.includes(NONE_VALUE) && payload.includes(NONE_VALUE)
          ? [NONE_VALUE]
          : payload.filter((id) => id !== NONE_VALUE);

      return {
        ...state,
        areaIds,
      };
    }
    case constants.MATRIXSEARCH_CHANGE_DEVICE: {
      const { payload } = action;
      const deviceIds =
        !state.deviceIds.includes(NONE_VALUE) && payload.includes(NONE_VALUE)
          ? [NONE_VALUE]
          : payload.filter((id) => id !== NONE_VALUE);

      return {
        ...state,
        deviceIds,
      };
    }
    case constants.MATRIXSEARCH_CHANGE_FACILITY_MANAGEMENT: {
      const { payload } = action;
      const facilityManagementIds =
        !state.facilityManagementIds.includes(NONE_VALUE) && payload.includes(NONE_VALUE)
          ? [NONE_VALUE]
          : payload.filter((id) => id !== NONE_VALUE);

      return {
        ...state,
        facilityManagementIds,
      };
    }
    case constants.MATRIXSEARCH_CHANGE_CONSTRUCTION_MANAGEMENT: {
      const { payload } = action;
      const constructionManagementIds =
        !state.constructionManagementIds.includes(NONE_VALUE) && payload.includes(NONE_VALUE)
          ? [NONE_VALUE]
          : payload.filter((id) => id !== NONE_VALUE);

      return {
        ...state,
        constructionManagementIds,
      };
    }
    case constants.MATRIXSEARCH_CHANGE_OTHER: {
      const { payload } = action;
      const otherIds =
        !state.otherIds.includes(NONE_VALUE) && payload.includes(NONE_VALUE)
          ? [NONE_VALUE]
          : payload.filter((id) => id !== NONE_VALUE);

      return {
        ...state,
        otherIds,
      };
    }
    case constants.MATRIXSEARCH_CHANGE_SYSTEM: {
      return {
        ...state,
        systemIds: action.payload,
      };
    }
    case constants.MATRIXSEARCH_CHANGE_COMPANY: {
      return {
        ...state,
        companyIds: action.payload,
      };
    }
    case constants.MATRIXSEARCH_CHANGE_USER: {
      return {
        ...state,
        userIds: action.payload,
      };
    }
    case constants.MATRIXSEARCH_CHANGE_GROUP: {
      return {
        ...state,
        groupIds: action.payload,
      };
    }
    case constants.MATRIXSEARCH_CHANGE_MIDDLE: {
      return {
        ...state,
        processMiddleClassIds: action.payload,
      };
    }
    case constants.MATRIXSEARCH_CHANGE_ITEM_TEXT: {
      return {
        ...state,
        itemText: action.payload,
      };
    }
    case constants.MATRIXSEARCH_CHANGE_PROCESS_TEXT: {
      return {
        ...state,
        processText: action.payload,
      };
    }
    case constants.MATRIXSEARCH_CHANGE_REGULATION: {
      return {
        ...state,
        regulation: action.payload,
      };
    }
    case constants.MATRIXSEARCH_CHANGE_FILTER: {
      let filters = [...state.filters];

      if (_.includes(filters, action.payload)) {
        filters = filters.filter((f) => f !== action.payload);
      } else {
        filters = [...filters, action.payload];
      }

      return {
        ...state,
        filters,
      };
    }
    case constants.MATRIXSEARCH_CLEAR_CONDITIONS: {
      return {
        ...state,
        ...initialState,
      };
    }
    case constants.SUMMARY_SELECT_CATEGORY_SUMMARY: {
      const { row, data } = action.payload;

      return {
        categoryId: data.category_id > 0 ? data.category_id : 0,
        primaryChargeId: row.primary_charge_id > 0 ? row.primary_charge_id : 0,
        areaIds: row.area_id > 0 ? [row.area_id] : [NONE_VALUE],
        deviceIds: row.device_id > 0 ? [row.device_id] : [NONE_VALUE],
        facilityManagementIds: row.facility_management_id > 0 ? [row.facility_management_id] : [NONE_VALUE],
        constructionManagementIds: row.construction_management_id > 0 ? [row.construction_management_id] : [NONE_VALUE],
        otherIds: [],
        systemIds: [],
        companyIds: [],
        userIds: [],
        groupIds: [],
        processMiddleClassIds: [],
        itemText: "",
        processText: "",
        regulation: "",
        filters: [],
      };
    }
    default:
      return state;
  }
};

export default matrixSearch;
