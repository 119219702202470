import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import actions from "@/actions";
import { RootState } from "@/reducers/types";
import { KeepOutAreaPolygon } from "@/sx-layout/components/plotmap/models";

// カスタム立入禁止エリア描画完了後ダイアログで編集確定する前に表示しておくダミーのオブジェクト
// 色と座標以外はダミーの値を設定していい
export const useWipAreaPolygon = () => {
  const dispatch = useDispatch();

  const value = useSelector<RootState, KeepOutAreaPolygon>((state) => state.plotmap.wipKeepOutAreaPolygon);

  const add = useCallback(
    (
      area: Partial<KeepOutAreaPolygon> & {
        icon_color: string;
        coords: [number, number][];
      }
    ) => {
      dispatch(actions.keepOutAreas.addWipKeepOutAreaPolygon(area));
    },
    [dispatch]
  );

  const changeCompany = useCallback(
    (area: Pick<KeepOutAreaPolygon, "company_id" | "icon_color">) => {
      dispatch(actions.keepOutAreas.changeWipKeepOutAreaPolygonCompany(area));
    },
    [dispatch]
  );

  const remove = useCallback(() => {
    dispatch(actions.keepOutAreas.removeWipKeepOutAreaPolygon());
  }, [dispatch]);

  return { value, add, changeCompany, remove };
};
