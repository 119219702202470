import React, { FC } from "react";

import { KeepOutArea } from "@/sx-layout/components/plotmap/models";

type Props = {
  area: KeepOutArea;
};

export const KeepOutAreaObject: FC<Props> = ({ area }) => {
  return (
    <div className="relative w-full h-full">
      <div id={`area-moveable-${area.keepout_area_id}`} className="relative h-full" />
    </div>
  );
};
