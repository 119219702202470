import * as constants from "../constants/index";
import environments from "../environments";

import { ProgressNotificationSearchParam } from "@/components/ProgressNotificationDrawer/models";

export const fetchProgressNotifications = ({
  searchParam,
  start = 1,
  onSuccess,
}: {
  searchParam: ProgressNotificationSearchParam;
  start?: number;
  onSuccess: (response?, searchParam?) => void;
}) => {
  let endpoint = `${environments.API_BASE_URI}/progress_notifications?start=${start}&limit=20`;

  Object.entries(searchParam).forEach(([key, value]) => {
    if (value !== undefined) endpoint += `&${key}=${value}`;
  });

  const init = start === 1;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.PROGRESS_NOTIFICATIONS_BEGIN_FETCH_NOTIFICATIONS,
        };
      },
      success: (response) => {
        onSuccess(response, searchParam);

        return {
          type: constants.PROGRESS_NOTIFICATIONS_END_FETCH_NOTIFICATIONS,
          payload: {
            response,
            init,
            searchParam,
          },
        };
      },
      error: (response) => {
        onSuccess();

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const readProgressNotification = (progress_notification_id: number, onSuccess: () => void) => {
  const endpoint = `${environments.API_BASE_URI}/progress_notifications/read/${progress_notification_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({}),
    callbacks: {
      begin: () => {
        return {
          type: constants.PROGRESS_NOTIFICATIONS_BEGIN_READ_NOTIFICATION,
        };
      },
      success: (response) => {
        onSuccess();

        return {
          type: constants.PROGRESS_NOTIFICATIONS_END_READ_NOTIFICATION,
          payload: {
            response,
          },
        };
      },
      error: (response) => {
        onSuccess();

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const readAllProgressNotification = ({
  fetchProgressNotifications,
  fetchNotificationsSummary,
}: {
  fetchProgressNotifications: () => void;
  fetchNotificationsSummary: () => void;
}) => {
  const endpoint = `${environments.API_BASE_URI}/progress_notifications/read`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({}),
    callbacks: {
      begin: () => {
        return {
          type: constants.PROGRESS_NOTIFICATIONS_BEGIN_READ_ALL_NOTIFICATION,
        };
      },
      success: () => {
        fetchProgressNotifications();
        fetchNotificationsSummary();

        return {
          type: constants.PROGRESS_NOTIFICATIONS_END_READ_ALL_NOTIFICATION,
        };
      },
      error: (response) => {
        fetchNotificationsSummary();

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};
