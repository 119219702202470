import { connect } from "react-redux";

import actions from "../../actions";
import PermitTableDownloadChiba from "../../components/list/PermitTableDownloadChiba";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    permitTableDownload: (scheduleDate, handleSuccess, handleFailure) =>
      dispatch(actions.schedule_chiba.permitTableDownload(scheduleDate, handleSuccess, handleFailure)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PermitTableDownloadChiba);
