import React, { FC, useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import actions from "@/actions";
import { AddClipboardDataDialog } from "@/components/list/Schedule/components/ScheduleUrl/AddClipboardDataDialog";
import { EditScheduleUrlDialog } from "@/components/list/Schedule/components/ScheduleUrl/EditScheduleUrlDialog";
import { useUrlList } from "@/components/list/Schedule/hooks/useUrlList";
import { ScheduleItemChiba, ScheduleUrl } from "@/models/scheduleChiba";

type Props = {
  schedule: ScheduleItemChiba;
  onClose: () => void;
};

export const ClipboardDataDialog: FC<Props> = ({ schedule, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const urlList = useUrlList();
  const [init, setInit] = useState(false);
  const [hasEditRole, setHasEditRole] = useState(false);
  const [openAddLinkModal, setOpenAddLinkModal] = useState(false);
  const [itemUpdateModal, setItemUpdateModal] = useState<ScheduleUrl>(null);

  // URL一覧データはモーダル内のStateで完結させる
  useEffect(() => {
    // URL一覧取得
    dispatch(
      actions.schedule_chiba.getScheduleUrls(schedule.item_id, (res) => {
        urlList.setData(res.list);
        setHasEditRole(!!res.roles[0]);
        setInit(true);
      })
    );
  }, [schedule]);

  const handleCreateUrl = (url: ScheduleUrl) => {
    urlList.add(url);
  };

  const handleChangeUrl = (url: ScheduleUrl) => {
    urlList.update(url);
  };

  const handleDeleteUrl = (url: ScheduleUrl) => {
    dispatch(
      actions.schedule_chiba.deleteScheduleUrl(url.item_url_id, { timestamp: url.timestamp }, () =>
        urlList.remove(url.item_url_id)
      )
    );
  };

  const handleClose = () => {
    dispatch(actions.schedule_chiba.updateScheduleUrlNum(schedule.item_id, urlList.data.length));
    onClose();
  };

  // レイアウトシフト対策
  if (!init) return <></>;

  return (
    <div className="modal">
      <Draggable handle=".modal-header">
        <div className="modal-drag-container">
          <div className="modal-dialog">
            <div className={"modal-content"}>
              <div className="modal-header">
                <h3 className="modal-ttl">{t("link_list")}</h3>
                <div className="btn-close" onClick={handleClose}>
                  <span className="icon icon-close"></span>
                </div>
              </div>
              <div className="modal-body clipboard-dialog">
                <p className="form-text">
                  {schedule.item_name}
                  {hasEditRole && (
                    <button type="button" onClick={() => setOpenAddLinkModal(true)} className="mx-2 cursor-pointer">
                      <img className="w-[18px] h-[18px]" src="./img/icon-add-black.svg" alt="plus" />
                    </button>
                  )}
                </p>
                {urlList.data.map((url, index) => (
                  <div key={index} className="clipboard-item">
                    <div className="clipboard-link mr-2">
                      <a href={url.url} target="_blank" rel="noreferrer" className="w-[100%] break-all">
                        {url.label}
                      </a>
                    </div>
                    {hasEditRole && (
                      <>
                        <button
                          type="button"
                          onClick={() => setItemUpdateModal(url)}
                          className="min-w-[18px] mx-2 cursor-pointer opacity-10 hover:opacity-100"
                        >
                          <img className="w-[18px] h-[18px]" src="./img/icon-edit-black.svg" />
                        </button>
                        <button
                          type="button"
                          onClick={() => handleDeleteUrl(url)}
                          className="min-w-[18px] ml-2 cursor-pointer opacity-10 hover:opacity-100"
                        >
                          <img className="w-[18px] h-[18px]" src="./img/icon-delete-black.svg" />
                        </button>
                      </>
                    )}
                  </div>
                ))}
                {urlList.data.length === 0 && <div className="h-4" />}
              </div>
              <div className={"modal-footer"}>
                <button
                  data-test-id="button-column-editor-cancel"
                  type="button"
                  className="btn btn-gray"
                  onClick={handleClose}
                >
                  {t("close")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
      {openAddLinkModal && (
        <AddClipboardDataDialog
          itemId={schedule.item_id}
          onCreateUrl={handleCreateUrl}
          onClose={() => setOpenAddLinkModal(false)}
        />
      )}
      {itemUpdateModal && (
        <EditScheduleUrlDialog
          scheduleUrl={itemUpdateModal}
          onUpdateUrl={handleChangeUrl}
          onClose={() => setItemUpdateModal(null)}
        />
      )}
    </div>
  );
};
