import _ from "lodash";
import moment from "moment";

import * as constants from "../constants/index";
import restoreState from "../lib/restoreState";
import storageManager from "../lib/storageManager";

const getInitialSearchConditions = (onlyLocal = false) => {
  const urlState = restoreState();
  let areaIds = [];
  let deviceIds = [];
  let categoryId = 0;
  let facilityManagementIds = [];
  let constructionManagementIds = [];
  let primaryChargeIds = [];
  let field1Ids = [];
  let field7Ids = [];
  let field8Ids = [];
  let userIds = [];
  let groupIds = [];
  let scheduleDate = moment().format("YYYY/MM/DD");
  let itemText = "";
  let processText = "";

  if (storageManager.getConstructionItem("scheduleSearchParams") !== null) {
    const params = JSON.parse(storageManager.getConstructionItem("scheduleSearchParams"));

    areaIds = params.areaIds;
    deviceIds = params.deviceIds;
    categoryId = params.categoryId;
    facilityManagementIds = params.facilityManagementIds;
    constructionManagementIds = params.constructionManagementIds;
    primaryChargeIds = params.primaryChargeIds;
    field1Ids = params.field1Ids;
    field7Ids = params.field7Ids;
    field8Ids = params.field8Ids;
    userIds = params.userIds;
    groupIds = params.groupIds;
    scheduleDate = params.scheduleDate;
    itemText = params.itemText;
    processText = params.processText;
  }

  if (!onlyLocal && urlState && urlState.menu === "schedule_eneos_kawasaki") {
    areaIds = urlState.area_ids
      ? decodeURIComponent(urlState.area_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    deviceIds = urlState.device_ids
      ? decodeURIComponent(urlState.device_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    categoryId = urlState.category_id ? Number(urlState.category_id) : 0;
    facilityManagementIds = urlState.facility_management_ids
      ? decodeURIComponent(urlState.facility_management_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    constructionManagementIds = urlState.construction_management_ids
      ? decodeURIComponent(urlState.construction_management_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    primaryChargeIds = urlState.primary_charge_ids
      ? decodeURIComponent(urlState.primary_charge_ids)
          .split(",")
          .map((f) => Number(f))
      : [];
    field1Ids = urlState.field1
      ? decodeURIComponent(urlState.field1)
          .split(",")
          .map((f) => Number(f))
      : [];
    field7Ids = urlState.field7
      ? decodeURIComponent(urlState.field7)
          .split(",")
          .map((f) => Number(f))
      : [];
    field8Ids = urlState.field8
      ? decodeURIComponent(urlState.field8)
          .split(",")
          .map((f) => Number(f))
      : [];
    userIds = urlState.user_id
      ? decodeURIComponent(urlState.user_id)
          .split(",")
          .map((f) => Number(f))
      : [];
    groupIds = urlState.group_id
      ? decodeURIComponent(urlState.group_id)
          .split(",")
          .map((f) => Number(f))
      : [];
    scheduleDate = urlState.schedule_date ? decodeURIComponent(urlState.schedule_date) : "";
    itemText = urlState.item_text ? decodeURIComponent(urlState.item_text) : "";
    processText = urlState.process_text ? decodeURIComponent(urlState.process_text) : "";
  }

  if (scheduleDate) {
    const date = moment(scheduleDate, "YYYY/MM/DD").format("YYYY-MM-DD").toString();
    if (date === "Invalid date") {
      scheduleDate = "";
    } else if (scheduleDate !== date) {
      scheduleDate = date;
    }
  }

  return {
    areaIds,
    deviceIds,
    categoryId,
    facilityManagementIds,
    constructionManagementIds,
    primaryChargeIds,
    field1Ids,
    field7Ids,
    field8Ids,
    userIds,
    groupIds,
    scheduleDate,
    itemText,
    processText,
  };
};

const schedule_eneos_kawasaki = (
  state = {
    areaIds: [],
    deviceIds: [],
    categoryId: 0,
    facilityManagementIds: [],
    constructionManagementIds: [],
    primaryChargeIds: [],
    field1Ids: [],
    field7Ids: [],
    field8Ids: [],
    userIds: [],
    groupIds: [],
    scheduleDate: "",
    itemText: "",
    processText: "",
    items: [],
    bulkRoles: [],
    scheduleUpdateDate: "",
    fetching: false,
    isError: false,
    validated: false,
    validRequiredParameter: undefined,
  },
  action
) => {
  switch (action.type) {
    case constants.APP_CONTENTS_READY: {
      return {
        ...state,
        ...getInitialSearchConditions(),
      };
    }
    case constants.SCHEDULE_SET_VALID_REQUIRED: {
      return {
        ...state,
        validRequiredParameter: Boolean(action.payload),
      };
    }
    case constants.CONSTRUCTION_END_FETCH_MASTERS: {
      const { categories } = action.payload;

      if (state.categoryId === 0 && categories.length > 0) {
        return {
          ...state,
          categoryId: categories[0].category_id,
        };
      }

      return state;
    }
    case constants.SCHEDULE_CHANGE_AREA: {
      return {
        ...state,
        areaIds: action.payload,
      };
    }
    case constants.SCHEDULE_CHANGE_DEVICE: {
      return {
        ...state,
        deviceIds: action.payload,
      };
    }
    case constants.SCHEDULE_CHANGE_FACILITY_MANAGEMENT: {
      return {
        ...state,
        facilityManagementIds: action.payload,
      };
    }
    case constants.SCHEDULE_CHANGE_CATEGORY: {
      return {
        ...state,
        categoryId: action.payload,
      };
    }
    case constants.SCHEDULE_CHANGE_CONSTRUCTION_MANAGEMENT: {
      return {
        ...state,
        constructionManagementIds: action.payload,
      };
    }
    case constants.SCHEDULE_CHANGE_PRIMARY_CHARGE: {
      return {
        ...state,
        primaryChargeIds: action.payload,
      };
    }
    case constants.SCHEDULE_CHANGE_OTHER: {
      return {
        ...state,
        otherIds: action.payload,
      };
    }
    case constants.SCHEDULE_CHANGE_USER: {
      return {
        ...state,
        userIds: action.payload,
      };
    }
    case constants.SCHEDULE_CHANGE_GROUP: {
      return {
        ...state,
        groupIds: action.payload,
      };
    }
    case constants.SCHEDULE_CHANGE_USER_FIELD1: {
      return {
        ...state,
        field1Ids: action.payload,
      };
    }
    case constants.SCHEDULE_CHANGE_USER_FIELD7: {
      return {
        ...state,
        field7Ids: action.payload,
      };
    }
    case constants.SCHEDULE_CHANGE_USER_FIELD8: {
      return {
        ...state,
        field8Ids: action.payload,
      };
    }
    case constants.SCHEDULE_CHANGE_SCHEDULE_DATE: {
      return {
        ...state,
        scheduleDate: action.payload,
      };
    }
    case constants.SCHEDULE_CHANGE_ITEM_TEXT: {
      return {
        ...state,
        itemText: action.payload,
      };
    }
    case constants.SCHEDULE_CHANGE_PROCESS_TEXT: {
      return {
        ...state,
        processText: action.payload,
      };
    }
    case constants.SCHEDULE_BEGIN_SEARCH: {
      return {
        ...state,
        fetching: true,
        isError: false,
      };
    }
    case constants.SCHEDULE_END_SEARCH: {
      return {
        ...state,
        fetching: false,
        items: action.payload.list,
        bulkRoles: action.payload.roles,
        scheduleUpdateDate: action.payload.schedule_update_date,
        firstScheduleDate: action.payload.list.length > 0 ? action.payload.list[0].schedule_date : "",
      };
    }
    case constants.SCHEDULE_CLEAR_SEARCH: {
      return {
        ...state,
        areaIds: action.payload.areaIds,
        deviceIds: action.payload.deviceIds,
        categoryId: action.payload.categoryId,
        facilityManagementIds: action.payload.facilityManagementIds,
        constructionManagementIds: action.payload.constructionManagementIds,
        primaryChargeIds: action.payload.primaryChargeIds,
        field1Ids: action.payload.field1Ids,
        field7Ids: action.payload.field7Ids,
        field8Ids: action.payload.field8Ids,
        userIds: action.payload.userIds,
        groupIds: action.payload.groupIds,
        itemText: action.payload.itemText,
        processText: action.payload.processText,
        scheduleDate: "",
      };
    }
    case constants.COMMON_BEGIN_CHANGE_TASK_STATUS: {
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.task_id === action.payload.task_id) {
            return {
              ...item,
              processing: true,
              error: false,
            };
          }

          return item;
        }),
      };
    }
    case constants.COMMON_END_CHANGE_TASK_STATUS: {
      const { task_id, status, display_status, timestamp } = action.payload.task;

      return {
        ...state,
        items: state.items.map((item) => {
          if (item.task_id === task_id) {
            return {
              ...item,
              status,
              display_status,
              timestamp,
              processing: false,
              error: false,
            };
          }

          return item;
        }),
      };
    }
    case constants.COMMON_ERROR_CHANGE_TASK_STATUS: {
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.task_id === action.payload.task_id) {
            return {
              ...item,
              processing: false,
              error: true,
            };
          }

          return item;
        }),
      };
    }
    case constants.MATRIX_END_UPDATE_TASK_DETAIL: {
      const { itemId, taskId } = action.payload;
      const { schedule_end_date, display_status, schedule_type, status, timestamp } = action.payload.data;

      return {
        ...state,
        items: state.items.map((item) => {
          if (item.task_id === taskId && item.item_id === itemId) {
            return {
              ...item,
              schedule_date: schedule_end_date,
              display_status,
              schedule_type,
              status,
              timestamp,
            };
          }

          return item;
        }),
      };
    }
    case constants.SCHEDULE_END_UPDATE_ITEM: {
      const items = state.items.map((item) => {
        if (item.schedule_id === action.payload.schedule_id) {
          return action.payload;
        }

        return item;
      });

      return {
        ...state,
        items,
      };
    }
    case constants.SCHEDULE_END_UPDATE_ITEM_BUNDLE: {
      const items = state.items.map((item) => {
        if (item.schedule_id === action.payload.schedule_id) {
          return action.payload;
        }

        return item;
      });

      return {
        ...state,
        items,
      };
    }
    case constants.SCHEDULE_BEGIN_SEARCH_INFINITE: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.SCHEDULE_END_SEARCH_INFINITE: {
      return {
        ...state,
        items: [...state.items, ...action.payload],
        fetching: false,
      };
    }
    case constants.SCHEDULE_SET_VALIDATED: {
      return {
        ...state,
        validated: action.payload,
      };
    }
    case constants.SCHEDULE_REVERT_CONDITION: {
      return {
        ...state,
        ...getInitialSearchConditions(true),
      };
    }
    case constants.SCHEDULE_BEGIN_GET_OPTIONS: {
      return {
        ...state,
      };
    }
    case constants.SCHEDULE_END_GET_OPTIONS: {
      return {
        ...state,
        options: action.payload,
      };
    }
    case constants.SCHEDULE_END_CREATE_SCHEDULE: {
      return {
        ...state,
        updates: action.payload,
      };
    }

    case constants.APP_SHOW_ERROR: {
      if (action.errorFrom === "schedule") {
        return {
          ...state,
          fetching: false,
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export default schedule_eneos_kawasaki;
