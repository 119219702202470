import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";

import { isValidRole } from "../../lib/roleChecker";
import clsx from "clsx";
import { omitString } from "@/lib/common";

type Props = {
  index: number;
  isLastIndex: boolean;
  row: any;
  startPos: number;
  totalNum: number;
  isWide: boolean;
  displayRemarks: boolean;
  showItemEditor: (itemId?: string) => void;
  showSortEditor: (itemId?: string) => void;
  updateNote: (itemId, data, callback?) => void;
  updateRemark: (itemId, data, callback?) => void;
  updateDelivery: (itemId, data, callback?) => void;
  updateAttention: (itemId, data, callback?) => void;
  addItemPrev: (index: number) => void;
  addItemNext: (index: number) => void;
  copyItem: (index, data, callback?) => void;
  deleteItem: (itemId, data, callback?) => void;
  bulkUpdateItemStatus: (itemId, data, callback?) => void;
  showAlert: (title, messages) => void;
  showTitleSubmenu: boolean;
  setTitleSubmenu: (columnId: number) => void;
  clearTitleSubmenu: () => void;
  clearProcessSubmenu: () => void;
  handleSearch: (option?: { keepPage?: boolean }) => void;
};

const LeftGridRow: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const [editCommentId, setEditCommentId] = useState(null);
  const [editRemarkId, setEditRemarkId] = useState(null);

  const commentTextArea = useRef<HTMLTextAreaElement>();
  const remarkTextArea = useRef<HTMLTextAreaElement>();

  const focusNote = useMemo(
    () =>
      _.debounce(() => {
        commentTextArea.current?.focus();
      }, 100),
    []
  );

  const focusRemark = useMemo(
    () =>
      _.debounce(() => {
        remarkTextArea.current?.focus();
      }, 100),
    []
  );

  useEffect(() => {
    props.row.item_id === editCommentId && focusNote();
    props.displayRemarks && focusRemark();
  }, [props.displayRemarks, props.row, editCommentId]);

  const beginEditComment = (id) => {
    setEditCommentId(id);
  };

  const endEditComment = (comment, row) => {
    if (100 < (comment ?? "").length) {
      const { showAlert } = props;
      showAlert(t("error"), [t("note") + t("is_too_long")]);
      commentTextArea.current?.focus();
      return;
    }

    const data = {
      note: comment,
      timestamp: row.timestamp,
    };

    props.updateNote(editCommentId, data, () => setEditCommentId(null));
  };

  const beginEditRemark = (id) => {
    setEditRemarkId(id);
  };

  const endEditRemark = (remark, row) => {
    if (100 < (remark ?? "").length) {
      const { showAlert } = props;
      showAlert(t("error"), [t("remarks") + t("is_too_long")]);
      remarkTextArea.current?.focus();
      return;
    }

    const data = {
      remark,
      timestamp: row.timestamp,
    };

    props.updateRemark(editRemarkId, data, () => setEditRemarkId(null));
  };

  const omitText = (text) => {
    if (text && text.length > 40) {
      return `${text.substr(0, 40)}...`;
    }

    return text;
  };

  const handleClickDelivery = (itemId, row) => {
    const data = {
      delivery_flg: !row.delivery_flg,
      timestamp: row.timestamp,
    };

    props.updateDelivery(itemId, data);
  };

  const handleClickAttention = (itemId, row) => {
    const data = {
      attention_flg: !row.attention_flg,
      timestamp: row.timestamp,
    };

    props.updateAttention(itemId, data);
  };

  const handleClickSubmenu = (e) => {
    props.clearProcessSubmenu();
    props.setTitleSubmenu(row.item_id);
    e.stopPropagation();
  };

  const handleClickContextMenu = (e, row, action) => {
    e.preventDefault();
    e.stopPropagation();

    if (action === "insert-prev") {
      props.addItemPrev(props.index);
      props.showItemEditor();
    } else if (action === "insert-next") {
      props.addItemNext(props.index);
      props.showItemEditor();
    } else if (action === "copy") {
      props.copyItem(props.index, row);
    } else if (action === "delete") {
      props.deleteItem(row.item_id, row);
    } else if (action === "bulk-off") {
      props.bulkUpdateItemStatus(row.item_id, row, () => props.handleSearch({ keepPage: true }));
    } else if (action === "sort") {
      props.showSortEditor(row.item_id);
    }
    props.clearTitleSubmenu();
  };

  const { row, index, startPos } = props;
  const canCopy = isValidRole(row.roles, 2);
  const canEdit = isValidRole(row.roles, 0);
  const canDelete = isValidRole(row.roles, 3);
  const canBulkOff = isValidRole(row.roles, 4);
  const canAttentionUpdate = isValidRole(row.roles, 1);

  return (
    <tr>
      <td className={`${row.attention_flg ? "cell-yellow" : ""} ${canAttentionUpdate ? "grid-cell-clickable" : ""}`}>
        <div
          className="grid-cell-wrap"
          onClick={() => {
            if (canAttentionUpdate) {
              handleClickAttention(row.item_id, row);
            }
          }}
        >
          <div className="grid-cell-con">
            <div className="grid-cell-inner">{startPos + index}</div>
          </div>
        </div>
      </td>
      <td
        className={`grid-cell-delivery ${row.delivery_flg ? "is-on" : "is-off"} ${
          canAttentionUpdate ? "grid-cell-clickable" : ""
        }`}
        onClick={() => {
          if (canAttentionUpdate) {
            handleClickDelivery(row.item_id, row);
          }
        }}
      >
        <div className="grid-cell-wrap">
          <div className="grid-cell-con">
            <div className="grid-cell-inner">
              <i className="grid-btn-delivery" />
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className="grid-cell-wrap">
          <div className="grid-cell-con">
            <div className="grid-cell-inner">{row.complete ? "●" : ""}</div>
          </div>
        </div>
      </td>
      <td>
        <div className="grid-cell-wrap">
          <div className="grid-cell-con">
            <div className="grid-cell-inner">{row.totaltest_process ? "●" : ""}</div>
          </div>
        </div>
      </td>
      <td>
        <div className="grid-cell-wrap">
          <div className="grid-cell-con">
            <div className="grid-cell-inner txt-left p-5">{row.area_name}</div>
          </div>
        </div>
      </td>
      <td>
        <div className="grid-cell-wrap">
          <div className="grid-cell-con">
            <div className="grid-cell-inner txt-left p-5">{row.device_name}</div>
          </div>
        </div>
      </td>
      <td>
        <div className="grid-cell-wrap">
          <div className="grid-cell-con">
            <div className="grid-cell-inner txt-left p-5">{row.primary_charge_name}</div>
          </div>
        </div>
      </td>
      <td>
        <div className="grid-cell-wrap">
          <div className="grid-cell-con">
            <div className="grid-cell-inner txt-left p-5">{row.document_no}</div>
          </div>
        </div>
      </td>
      <td
        className={`grid-item_name ${canEdit ? "grid-cell-clickable" : ""}`}
        onClick={() => canEdit && props.showItemEditor(row.item_id)}
        onContextMenu={(e) => {
          if (!(canCopy || canDelete || canBulkOff)) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        <div className="grid-cell-wrap" style={{ overflow: "visible" }}>
          {canCopy || canDelete || canBulkOff ? (
            <React.Fragment>
              {props.showTitleSubmenu && (
                <div
                  id={`matrix-subject-contextmenu-${row.item_id}`}
                  className="grid-context-menu context-menu"
                  style={{
                    left: props.isWide ? "70px" : "110px",
                    ...(props.index !== 0 && props.isLastIndex ? { bottom: 0 } : { top: "10px" }),
                  }}
                >
                  {canCopy && (
                    <div className="menu-item" onClick={(e) => handleClickContextMenu(e, row, "copy")}>
                      {t("copy_row")}
                    </div>
                  )}
                  {canDelete && (
                    <div className="menu-item" onClick={(e) => handleClickContextMenu(e, row, "delete")}>
                      {t("delete_row")}
                    </div>
                  )}
                  {canBulkOff && (
                    <div className="menu-item" onClick={(e) => handleClickContextMenu(e, row, "bulk-off")}>
                      {t("off_all_task")}
                    </div>
                  )}
                  <div
                    className={clsx("menu-item", 1 < props.totalNum ? "" : "disabled")}
                    onClick={(e) => {
                      1 < props.totalNum && handleClickContextMenu(e, row, "sort");
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    {t("sort_row")}
                  </div>
                </div>
              )}
              <div className="grid-cell-con">
                <div className="grid-cell-inner txt-left p-5">
                  {omitString(row.item_name, 35)}
                  <div className="grid-icon-submenu-wrapper" onClick={handleClickSubmenu}>
                    <button className="grid-icon grid-icon-submenu icon-submenu" />
                  </div>
                  {row.file_status && <span className="grid-icon grid-icon-information icon-attach_file"></span>}
                  {canEdit && <button className="grid-icon grid-icon-edit icon-mode_edit"></button>}
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">
                {omitString(row.item_name, 35)}
                {row.file_status && <span className="grid-icon grid-icon-information icon-attach_file"></span>}
                {canEdit && <button className="grid-icon grid-icon-edit icon-mode_edit"></button>}
              </div>
            </div>
          )}
        </div>
      </td>
      <td>
        <div className="grid-cell-wrap">
          <div className="grid-cell-con">
            <div className="grid-cell-inner txt-left p-5">{row.regulation}</div>
          </div>
        </div>
      </td>
      <td>
        <div className="grid-cell-wrap">
          <div className="grid-cell-con">
            <div className="grid-cell-inner txt-left p-5">
              <ul>
                {row &&
                  row.systems &&
                  row.systems.map((system, index) => {
                    return <li key={index}>{system.system_name}</li>;
                  })}
              </ul>
            </div>
          </div>
        </div>
      </td>
      {row.item_id === editCommentId ? (
        <td className="no-hover">
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner">
                <textarea
                  className="grid-textarea txt-left p-5"
                  ref={commentTextArea}
                  onBlur={(e) => endEditComment(e.target.value, row)}
                  defaultValue={row.note}
                ></textarea>
              </div>
            </div>
          </div>
        </td>
      ) : (
        <td
          className={canAttentionUpdate ? "grid-cell-clickable" : ""}
          onClick={() => {
            if (canAttentionUpdate) {
              beginEditComment(row.item_id);
            }
          }}
        >
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">
                {omitText(row.note)}
                {canAttentionUpdate && <button className="grid-icon grid-icon-edit icon-mode_edit"></button>}
              </div>
            </div>
          </div>
        </td>
      )}
      {!props.isWide &&
        props.displayRemarks &&
        (row.item_id === editRemarkId ? (
          <td style={{ minWidth: 115, maxWidth: 115 }} className="no-hover">
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner">
                  <textarea
                    className="grid-textarea txt-left p-5"
                    ref={remarkTextArea}
                    onBlur={(e) => endEditRemark(e.target.value, row)}
                    defaultValue={row.remark}
                  ></textarea>
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            style={{ minWidth: 115, maxWidth: 115 }}
            className={canAttentionUpdate ? "grid-cell-clickable" : ""}
            onClick={() => {
              if (canAttentionUpdate) {
                beginEditRemark(row.item_id);
              }
            }}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner txt-left p-5">
                  {omitText(row.remark)}
                  {canAttentionUpdate && <button className="grid-icon grid-icon-edit icon-mode_edit"></button>}
                </div>
              </div>
            </div>
          </td>
        ))}
      <td style={{ minWidth: 60, maxWidth: 60 }}>
        <div className="grid-cell-wrap">
          <div className="grid-cell-con">
            <div className="grid-cell-inner txt-left p-5">{row.user_name}</div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default withTranslation()(LeftGridRow);
