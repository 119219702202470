import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import PermitTableDownloadChibaContainer from "../../containers/list/PermitTableDownloadChibaContainer";
import ProtectionBundleEditorContainer from "../../containers/list/ProtectionBundleEditorContainer";
import ProtectionEditorContainer from "../../containers/list/ProtectionEditorContainer";
import ScheduleEditorBundleChibaContainer from "../../containers/list/ScheduleEditorBundleChibaContainer";
import ScheduleEditorChibaContainer from "../../containers/list/ScheduleEditorChibaContainer";
import ScheduleRightRowChibaContainer from "../../containers/list/ScheduleRightRowChibaContainer";
import ScheduleUpdateChibaContainer from "../../containers/list/ScheduleUpdateChibaContainer";
import SpecialProcessBundleEditorContainer from "../../containers/list/SpecialProcessBundleEditorContainer";
import SpecialProcessEditorContainer from "../../containers/list/SpecialProcessEditorContainer";
import TooltipContainer from "../../containers/list/TooltipContainer";
import * as util from "../../lib/common";
import { addHeightResizeListener, getTableBodyHeight, removeHeightResizeListener } from "../../lib/common";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import storageManager from "../../lib/storageManager";
import restoreState from "../../lib/restoreState";
import { isValid, isValidRole } from "../../lib/roleChecker";

import RevisionHistoryModal from "./RevisionHistoryModal";
import {
  HistoryColumn,
  HistoryColumnType,
  ScheduleHistory,
  ScheduleItemChiba,
  ScheduleOptionGroup,
} from "@/models/scheduleChiba";
import { ConstructionMasters } from "@/models/masters";
import { ClipboardDataDialog } from "@/components/list/Schedule/components/ScheduleUrl/ClipboardDataDialog";
import clsx from "clsx";
import { ScheduleChibaSearchBox } from "@/components/list/ScheduleChiba/components/ScheduleChibaSearchBox";
import { HistoryButton } from "@/components/list/ScheduleChiba/components/HistoryButton";
import { ConstructionExtensionConfig } from "@/models/constructionExtensions";

// @ts-ignore: IEの二重リクエスト対策フラグ
const autoload = false;

// テーブル折り畳み状態の型
type SheduleExpandedFieds = "specialProcess" | "worktime" | "constructionAttend" | "rightTable";
type ScheduleExpandedState = Record<SheduleExpandedFieds, boolean>;

type Props = {
  areaIds: any[];
  deviceIds: any[];
  categoryIds: any[];
  otherIds: any[];
  field1Params: any;
  companyParams: any;
  userParams: any;
  groupParams: any;
  approveParams: any;
  checkpoint1?: number;
  checkpoint2?: number;
  checkpoint3?: number;
  checkpoint4?: number;
  checkpoint5?: number;
  checkpoint6?: number;
  filter?: number;
  requestUser?: number;
  field5?: number;
  scheduleIds: any;
  facilityManagementIds: number[];
  constructionManagementIds: number[];
  primaryChargeIds: number[];
  scheduleDate: string;
  masters: ConstructionMasters;
  items: ScheduleItemChiba[];
  fetching: boolean;
  isError: boolean;
  search: (p, c) => void;
  clearSearch: (_) => void;
  t: (_) => string;
  revertLocalCondition: () => void;
  validated: boolean;
  config: ConstructionExtensionConfig;
  getOptions: (callback) => void;
  download: (a, b, c, d) => void;
  showAlert: (title, messages, okClickHandler) => void;
  hideAlert: () => void;
  updateItem: (data, handleSuccess, handleCancel) => void;
  getHistories: (
    scheduleId: number,
    targetColumn: HistoryColumnType,
    callback: (res: { total_num: number; list: ScheduleHistory[] }) => void
  ) => void;
  changeArea: (items) => void;
  changeDevice: (items) => void;
  changeCategory: (items) => void;
  changeFacilityManagement: (items) => void;
  changeConstructionManagement: (items) => void;
  changePrimaryCharge: (items) => void;
  changeOther: (items) => void;
  changeConstructionCompany: (items) => void;
  changeField1: (items) => void;
  changeUser: (items) => void;
  changeGroup: (items) => void;
  changeApprove: (items) => void;
  changeCheckpoint1: (items) => void;
  changeCheckpoint2: (items) => void;
  changeCheckpoint3: (items) => void;
  changeCheckpoint4: (items) => void;
  changeCheckpoint5: (items) => void;
  changeCheckpoint6: (items) => void;
  changeFilter: (items) => void;
  changeRequestUser: (items) => void;
  changeField5: (items) => void;
  changeScheduleDate: (items) => void;
  bulkRoles: any[];
  roles: any[];
};

type State = {
  showSearch: boolean;
  showTable: boolean;
  currentRowIndex: number;
  start: number;
  end: boolean;
  isEmpty: boolean;
  tableBodyMaxHeight: number | string;
  offset: number;
  maxLength: number;
  scheduleId: number;
  downloadUrl: string;
  downloadName: string;
  downloading: boolean;
  panelText: string;
  requestParam: any;
  unitParam: any[];
  showUpdateBundle: boolean;
  showEditor: boolean;
  showUpdatePanel: boolean;
  showPermitTableDownloadPanel: boolean;
  timeStamp: string;
  target: string;
  processText: string;
  scheduleUpdateDate: string;
  switchTime: string;
  selectValues: any[];
  tooltip: null;
  unit: [];
  isUnit: boolean;
  bulk_update_options: ScheduleOptionGroup[];
  search_condition_options: ScheduleOptionGroup[];
  selectVals: any[];
  editNote3?: any;
  editSpecial: any;
  editProtect: any;
  editWorkStart: any;
  editWorkEnd: any;
  isExcluse: boolean;
  row?: ScheduleItemChiba;
  limit: number;
  currentPage: number;
  maxPage: number;
  totalNum: number;
  rightBodyScroll: number;
  // 折りたたみ状態の初期化
  isExpandedRightTable: boolean;
  isExpandedSpecialProcess: boolean;
  isExpandedWorktime: boolean;
  isExpandedConstructionAttend: boolean;
  // 変更履歴
  activeButton?: string;
  optionId?: number;
  showSpecialBundleEditor?: boolean;
  showProtectBundleEditor?: boolean;
  showSpecialEditor?: boolean;
  showProtectionEditor?: boolean;
  historyData?: {
    itemName: string;
    columnName: string;
    data: ScheduleHistory[];
  };
  selectedScheduleForClipboardDialog?: ScheduleItemChiba | null;
  dataInitSearch: boolean;
};

class ScheduleChiba extends Component<Props, State> {
  resizeTimer: number;
  searchBox: HTMLDivElement;
  note3Textarea: HTMLTextAreaElement;
  specialTextarea: HTMLDivElement; // TODO 使われていない
  protectTextarea: HTMLDivElement; // TODO 使われていない
  workStartTextarea: HTMLTextAreaElement;
  workEndTextarea: HTMLTextAreaElement;
  theader: HTMLDivElement;
  rightBodyContainer: HTMLDivElement;
  rightHeaderContainer: HTMLDivElement;
  leftBodyContainer: HTMLDivElement;
  btnDownload: HTMLAnchorElement;

  constructor(props) {
    super(props);

    // 工事、ユーザ単位のテーブル折り畳み状態を取得
    const constId = util.getConstructionId();
    const userId = util.getUserId();
    const scheduleExpandedState: ScheduleExpandedState = util.getScheduleTableExpantionState()[`${constId}_${userId}`];

    this.state = {
      dataInitSearch: false,
      showSearch: true,
      showTable: false,
      currentRowIndex: -1,
      start: 1,
      end: false,
      isEmpty: false,
      tableBodyMaxHeight: window.innerHeight - 350,
      offset: 0,
      maxLength: 60,
      scheduleId: 0,
      downloadUrl: "",
      downloadName: "",
      downloading: false,

      panelText: "",
      requestParam: [],
      unitParam: [],
      showUpdateBundle: false,
      showEditor: false,
      showUpdatePanel: false,
      showPermitTableDownloadPanel: false,

      timeStamp: "",
      target: "",
      processText: "",
      scheduleUpdateDate: "",
      switchTime: "",
      selectValues: [],

      tooltip: null,
      unit: [],
      isUnit: false,

      bulk_update_options: [],
      search_condition_options: [],
      selectVals: [],

      editNote3: null,
      editSpecial: null,
      editProtect: null,
      editWorkStart: null,
      editWorkEnd: null,

      isExcluse: false,

      row: undefined,

      limit: 20,
      currentPage: 1,
      maxPage: 0,
      totalNum: 0,

      rightBodyScroll: 0,

      // 折りたたみ状態の初期化
      isExpandedRightTable: scheduleExpandedState?.rightTable ?? false,
      isExpandedSpecialProcess: scheduleExpandedState?.specialProcess ?? true,
      isExpandedWorktime: scheduleExpandedState?.worktime ?? true,
      isExpandedConstructionAttend: scheduleExpandedState?.constructionAttend ?? true,

      // 変更履歴
      historyData: undefined,
    };

    this.judgeScheduleDate = this.judgeScheduleDate.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleBundleSearch = this.handleBundleSearch.bind(this);
    this.handleSearchBoxHeightChange = this.handleSearchBoxHeightChange.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.fillList = this.fillList.bind(this);
    this.download = this.download.bind(this);
    this.checkScheduleDate = this.checkScheduleDate.bind(this);
    this.showUpdatePanel = this.showUpdatePanel.bind(this);
    this.showPermitTableDownloadPanel = this.showPermitTableDownloadPanel.bind(this);
    this.setTooltip = this.setTooltip.bind(this);
    this.handleOnMouse = this.handleOnMouse.bind(this);
    this.showSpecialProcessBundle = this.showSpecialProcessBundle.bind(this);
    this.hideSpecialProcessBundle = this.hideSpecialProcessBundle.bind(this);
    this.showProtectionBundle = this.showProtectionBundle.bind(this);
    this.hideProtectionBundle = this.hideProtectionBundle.bind(this);
    this.showSpecialProcess = this.showSpecialProcess.bind(this);
    this.hideSpecialProcess = this.hideSpecialProcess.bind(this);
    this.showProtection = this.showProtection.bind(this);
    this.hideProtection = this.hideProtection.bind(this);
    // 変更履歴
    this.handleShowRevisionHistory = this.handleShowRevisionHistory.bind(this);
    this.handleHideRevisionHistory = this.handleHideRevisionHistory.bind(this);
    this.showClipboardDialog = this.showClipboardDialog.bind(this);
    this.closeClipboardDialog = this.closeClipboardDialog.bind(this);
  }

  componentDidMount() {
    this.resizeTimer = 0;
    window.addEventListener("resize", this.handleResize);

    const urlState = restoreState();
    const { getOptions, validated } = this.props;
    let isSearch = false;
    if (
      validated &&
      (storageManager.getConstructionItem("scheduleChibaSearchParams") !== null || (urlState && urlState.hasQuery))
    ) {
      isSearch = true;
    }

    this.handleSearchBoxHeightChange();
    addHeightResizeListener(this.searchBox, this.handleSearchBoxHeightChange);
    getOptions(
      (data: { bulk_update_options: ScheduleOptionGroup[]; search_condition_options: ScheduleOptionGroup[] }) => {
        this.setState(
          {
            bulk_update_options: data.bulk_update_options,
            search_condition_options: data.search_condition_options,
          },
          () => {
            this.props.changeScheduleDate(this.judgeScheduleDate());
            if (isSearch) {
              this.handleSearch(this.judgeScheduleDate());
            }
          }
        );
      }
    );
  }

  componentDidUpdate() {
    const { validated } = this.props;
    if (!validated) {
      return;
    }
    this.note3Textarea?.focus();
    this.specialTextarea?.focus();
    this.protectTextarea?.focus();
    this.workStartTextarea?.focus();
    this.workEndTextarea?.focus();

    const notFirst = storageManager.getConstructionItem("scheduleChibaSearchParams") !== null;
    if (!this.state.dataInitSearch && notFirst && !!this.props.config?.schedule && !!this.props.masters) {
      // マスタデータがあってlocalStorageに過去の検索条件が保存されていれば画面表示したときに検索を行う
      this.setState({ dataInitSearch: true });
      this.handleSearch(this.judgeScheduleDate());
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    removeHeightResizeListener(this.searchBox);

    this.props.revertLocalCondition();
  }

  //予定日の補正
  judgeScheduleDate(): string {
    const switchingTime = this.props.config.schedule.schedule_date_switch_time;
    if (switchingTime.trim().length < 1) {
      return moment().format("YYYY/MM/DD");
    }

    const switchedTime = moment(moment().format("YYYY-MM-DD") + " " + ("0" + switchingTime).slice(-5) + ":00");
    return moment() > switchedTime ? moment().add(1, "days").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
  }

  getPermitTableDownloadDateList() {
    return [
      { id: 0, value: moment().add(0, "days").format("YYYY-MM-DD") },
      { id: 1, value: moment().add(1, "days").format("YYYY-MM-DD") },
      { id: 2, value: moment().add(2, "days").format("YYYY-MM-DD") },
      { id: 3, value: moment().add(3, "days").format("YYYY-MM-DD") },
    ];
  }

  handleSearchBoxHeightChange() {
    const maxHeight = getTableBodyHeight("schedule_chiba", this.searchBox, this.theader);
    this.setState({ tableBodyMaxHeight: "calc(100vh - " + maxHeight + "px)" });
  }

  handleResize() {
    if (this.resizeTimer > 0) {
      clearTimeout(this.resizeTimer);
    }

    const callback = () => {
      this.handleSearchBoxHeightChange();
      this.fillList();
    };

    // @ts-ignore
    this.resizeTimer = setTimeout(callback, 200);
  }

  toggleSearch() {
    const callback = () => {
      this.handleSearchBoxHeightChange();
      addHeightResizeListener(this.searchBox, this.handleSearchBoxHeightChange);
      this.fillList();
    };
    this.setState({ showSearch: !this.state.showSearch }, () => setTimeout(callback, 100));
  }

  showTable(callback) {
    this.setState({ showTable: true }, () => {
      if (callback !== undefined) {
        // コールバックにしてもsetState直後はDOMに反映していない
        // ことがあるため、時間差で実行する
        setTimeout(callback, 100);
      }
    });
  }

  fillList() {
    const { showTable, end, rightBodyScroll } = this.state;

    if (!showTable || end) {
      return;
    }
    if (this.rightBodyContainer) {
      this.rightBodyContainer.scrollLeft = rightBodyScroll;
    }
    if (this.rightHeaderContainer) {
      this.rightHeaderContainer.scrollLeft = rightBodyScroll;
    }
  }

  handleSearch(sche_date, startPos = 1) {
    this.setState({ isEmpty: true });

    const {
      areaIds,
      deviceIds,
      categoryIds,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      field1Params,
      companyParams,
      userParams,
      groupParams,
      approveParams,
      checkpoint1,
      checkpoint2,
      checkpoint3,
      checkpoint4,
      checkpoint5,
      checkpoint6,
      filter,
      requestUser,
      field5,
      scheduleDate,
      search,
      scheduleIds,
    } = this.props;

    const params = {
      areaIds,
      deviceIds,
      categoryIds,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      field1Params,
      companyParams,
      userParams,
      groupParams,
      approveParams,
      checkpoint1,
      checkpoint2,
      checkpoint3,
      checkpoint4,
      checkpoint5,
      checkpoint6,
      filter,
      requestUser,
      field5,
      scheduleDate: sche_date || scheduleDate,
      start: startPos,
      limit: this.state.limit,
      scheduleIds,
    };

    search(params, (data) => {
      this.setState(
        {
          dataInitSearch: true,
          isEmpty: data.list.length === 0,
          maxPage: Math.ceil(data.total_num / this.state.limit),
          currentPage: Math.ceil(params.start / this.state.limit),
          totalNum: data.list.total_num,
          requestParam: {
            area_id: areaIds,
            device_id: deviceIds,
            category_id: categoryIds,
            facility_management_id: facilityManagementIds,
            construction_management_id: constructionManagementIds,
            primary_charge_id: primaryChargeIds,
            other_id: otherIds,
            field1: field1Params,
            company_id: companyParams,
            user_id: userParams,
            group_id: groupParams,
            approve_user_id: approveParams,
            checkpoint1,
            checkpoint2,
            checkpoint3,
            checkpoint4,
            checkpoint5,
            checkpoint6,
            filter,
            requestUser,
            field5,
            schedule_date: params.scheduleDate,
            start: 1 + (Math.ceil(params.start / this.state.limit) - 1) * this.state.limit,
            limit: this.state.limit,
          },
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  handleSearchPager(startPos = 1) {
    this.setState({ isEmpty: true });
    const { clearSearch, changeScheduleDate, search } = this.props;

    const { requestParam } = this.state;
    const params = {
      areaIds: requestParam.area_id,
      deviceIds: requestParam.device_id,
      categoryIds: requestParam.category_id,
      facilityManagementIds: requestParam.facility_management_id,
      constructionManagementIds: requestParam.construction_management_id,
      primaryChargeIds: requestParam.primary_charge_id,
      otherIds: requestParam.other_id,
      field1Params: requestParam.field1,
      companyParams: requestParam.company_id,
      userParams: requestParam.user_id,
      groupParams: requestParam.group_id,
      approveParams: requestParam.approve_user_id,
      checkpoint1: requestParam.checkpoint1,
      checkpoint2: requestParam.checkpoint2,
      checkpoint3: requestParam.checkpoint3,
      checkpoint4: requestParam.checkpoint4,
      checkpoint5: requestParam.checkpoint5,
      checkpoint6: requestParam.checkpoint6,
      filter: requestParam.filter,
      requestUser: requestParam.requestUser,
      field5: requestParam.field5,
      scheduleDate: requestParam.schedule_date,
      start: startPos,
      limit: this.state.limit,
    };

    clearSearch(params);
    changeScheduleDate(this.state.requestParam.schedule_date);

    search(params, (data) => {
      this.setState(
        {
          isEmpty: data.list.length === 0,
          maxPage: Math.ceil(data.total_num / this.state.limit),
          currentPage: Math.ceil(params.start / this.state.limit),
          totalNum: data.total_num,
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  handleClear() {
    const target = {
      areaIds: [],
      deviceIds: [],
      categoryIds: [],
      facilityManagementIds: [],
      constructionManagementIds: [],
      primaryChargeIds: [],
      otherIds: [],
      field1Params: [],
      companyParams: [],
      userParams: [],
      groupParams: [],
      approveParams: [],
      checkpoint1: 0,
      checkpoint2: 0,
      checkpoint3: 0,
      checkpoint4: 0,
      checkpoint5: 0,
      checkpoint6: 0,
      filter: 0,
      requestUser: 0,
      field5: 0,
    };
    const scheduleDate = this.judgeScheduleDate();
    this.props.clearSearch(target);
    this.setState({ start: 1, showTable: false });

    const { changeScheduleDate, search } = this.props;

    const params = {
      areaIds: [],
      deviceIds: [],
      categoryIds: [],
      facilityManagementIds: [],
      constructionManagementIds: [],
      primaryChargeIds: [],
      otherIds: [],
      field1Params: [],
      companyParams: [],
      userParams: [],
      groupParams: [],
      approveParams: [],
      checkpoint1: 0,
      checkpoint2: 0,
      checkpoint3: 0,
      checkpoint4: 0,
      checkpoint5: 0,
      checkpoint6: 0,
      filter: 0,
      requestUser: 0,
      field5: 0,
      scheduleDate: scheduleDate,
      start: 1,
      limit: this.state.limit,
    };

    changeScheduleDate(this.judgeScheduleDate());

    search(params, (data) => {
      this.setState(
        {
          start: data.list.length + 1,
          end: data.list.length < this.state.limit,
          isEmpty: data.list.length === 0,
          maxPage: Math.ceil(data.total_num / this.state.limit),
          currentPage: 1,
          requestParam: {
            category_id: [],
            area_id: [],
            device_id: [],
            facility_management_id: [],
            construction_management_id: [],
            primary_charge_id: [],
            other_id: [],
            company_id: [],
            field1: [],
            user_id: [],
            group_id: [],
            approve_user_id: [],
            checkpoint1: 0,
            checkpoint2: 0,
            checkpoint3: 0,
            checkpoint4: 0,
            checkpoint5: 0,
            checkpoint6: 0,
            filter: 0,
            requestUser: 0,
            field5: 0,
            schedule_date: scheduleDate,
          },
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  handleBundleSearch() {
    const { requestParam, currentPage, limit, totalNum } = this.state;
    const { clearSearch, changeScheduleDate, search, showAlert, hideAlert, t } = this.props;
    const params = {
      areaIds: requestParam.area_id,
      deviceIds: requestParam.device_id,
      categoryIds: requestParam.category_id,
      facilityManagementIds: requestParam.facility_management_id,
      constructionManagementIds: requestParam.construction_management_id,
      primaryChargeIds: requestParam.primary_charge_id,
      otherIds: requestParam.other_id,
      field1Params: requestParam.field1,
      companyParams: requestParam.company_id,
      userParams: requestParam.user_id,
      groupParams: requestParam.group_id,
      approveParams: requestParam.approve_user_id,
      checkpoint1: requestParam.checkpoint1,
      checkpoint2: requestParam.checkpoint2,
      checkpoint3: requestParam.checkpoint3,
      checkpoint4: requestParam.checkpoint4,
      checkpoint5: requestParam.checkpoint5,
      checkpoint6: requestParam.checkpoint6,
      filter: requestParam.filter,
      requestUser: requestParam.requestUser,
      field5: requestParam.field5,
      scheduleDate: requestParam.schedule_date,
      start: 1 + (currentPage - 1) * limit,
      limit: limit,
    };

    this.setState({ isEmpty: true });

    clearSearch(params);
    changeScheduleDate(requestParam.schedule_date);

    search(params, (data) => {
      if (totalNum !== data.total_num) {
        if (currentPage > 1) {
          showAlert(t("confirmation"), [t("return_first_page")], () => {
            hideAlert();
            search({ ...params, start: 1 }, (data) => {
              this.setState(
                {
                  isEmpty: data.list.length === 0,
                  maxPage: Math.ceil(data.total_num / limit),
                  currentPage: 1,
                },
                () => {
                  this.showTable(this.fillList);
                }
              );
            });
          });
        } else {
          this.setState(
            {
              isEmpty: data.list.length === 0,
              maxPage: Math.ceil(data.total_num / limit),
            },
            () => {
              this.showTable(this.fillList);
            }
          );
        }
      } else {
        this.setState(
          {
            isEmpty: data.list.length === 0,
            totalNum: data.total_num,
            maxPage: Math.ceil(data.total_num / limit),
          },
          () => {
            this.showTable(this.fillList);
          }
        );
      }
    });
  }

  syncScroll(e) {
    this.rightHeaderContainer.scrollLeft = e.target.scrollLeft;
    this.leftBodyContainer.scrollTop = e.target.scrollTop;
  }

  syncWheel(e) {
    this.rightBodyContainer.scrollTop += e.deltaY;
    this.leftBodyContainer.scrollTop += e.deltaY;
  }

  toggleWide(e: React.MouseEvent<HTMLButtonElement>, field: SheduleExpandedFieds) {
    e.stopPropagation();
    const scheduleExpandedStateAll = util.getScheduleTableExpantionState();
    const constId = util.getConstructionId();
    const userId = util.getUserId();
    const updateState: ScheduleExpandedState = scheduleExpandedStateAll[`${constId}_${userId}`] ?? {};

    switch (field) {
      case "specialProcess":
        updateState.specialProcess = !this.state.isExpandedSpecialProcess;
        this.setState({ isExpandedSpecialProcess: !this.state.isExpandedSpecialProcess });

        break;
      case "worktime":
        updateState.worktime = !this.state.isExpandedWorktime;
        this.setState({ isExpandedWorktime: !this.state.isExpandedWorktime });

        break;
      case "constructionAttend":
        updateState.constructionAttend = !this.state.isExpandedConstructionAttend;
        this.setState({ isExpandedConstructionAttend: !this.state.isExpandedConstructionAttend });

        break;
      case "rightTable":
        updateState.rightTable = !this.state.isExpandedRightTable;
        this.setState({ isExpandedRightTable: !this.state.isExpandedRightTable });

        break;
      default:
        break;
    }

    // 折り畳み状態をlocalStorageに保存（工事、ユーザ単位）
    localStorage.setItem(
      "scheduleExpandedState",
      JSON.stringify({ ...scheduleExpandedStateAll, [`${constId}_${userId}`]: updateState })
    );
  }

  showDropdown(name) {
    this.setState({ activeButton: name });
  }

  hideDropdown() {
    this.setState({ activeButton: "" });
  }

  showScheduleEditor(target, column, processText, scheduleId, optionId, timestamp) {
    this.setState({
      showEditor: true,
      target: target,
      processText: processText || "",
      scheduleId: scheduleId,
      optionId: optionId || 0,
      panelText: column,
      timeStamp: timestamp,
      selectValues: this.state.bulk_update_options
        .filter((option) => {
          return option.target == target;
        })
        .shift().options,
    });
  }

  showScheduleEditorBundle(bulkTarget, column) {
    this.setState({
      showUpdateBundle: true,
      target: bulkTarget,
      panelText: column,
      selectValues: this.state.bulk_update_options
        .filter((option) => {
          return option.target == bulkTarget;
        })
        .shift().options,
      rightBodyScroll: document.getElementById("right-body").scrollLeft,
    });
  }

  showScheduleEditorNoteBundle(bulkTarget, column) {
    this.setState({
      showUpdateBundle: true,
      target: bulkTarget,
      panelText: column,
      rightBodyScroll: document.getElementById("right-body").scrollLeft,
    });
  }

  showUpdatePanel() {
    this.setState({
      showUpdatePanel: true,
    });
  }

  showPermitTableDownloadPanel() {
    this.setState({
      showPermitTableDownloadPanel: true,
    });
  }

  hideScheduleEditor() {
    this.setState({
      showUpdateBundle: false,
      showEditor: false,
      showUpdatePanel: false,
    });
  }

  hidePermitTableDownloadPanel() {
    this.setState({
      showPermitTableDownloadPanel: false,
    });
  }

  download(filetype) {
    this.setState({ downloading: true });

    const { requestParam } = this.state;
    const params = {
      areaIds: requestParam.area_id,
      deviceIds: requestParam.device_id,
      categoryIds: requestParam.category_id,
      facilityManagementIds: requestParam.facility_management_id,
      constructionManagementIds: requestParam.construction_management_id,
      primaryChargeIds: requestParam.primary_charge_id,
      otherIds: requestParam.other_id,
      field1Params: requestParam.field1,
      companyParams: requestParam.company_id,
      userParams: requestParam.user_id,
      groupParams: requestParam.group_id,
      approveParams: requestParam.approve_user_id,
      checkpoint1: requestParam.checkpoint1,
      checkpoint2: requestParam.checkpoint2,
      checkpoint3: requestParam.checkpoint3,
      checkpoint4: requestParam.checkpoint4,
      checkpoint5: requestParam.checkpoint5,
      checkpoint6: requestParam.checkpoint6,
      filter: requestParam.filter,
      requestUser: requestParam.requestUser,
      field5: requestParam.field5,
      scheduleDate: requestParam.schedule_date,
    };

    const fallback = () => this.setState({ downloading: false });

    const constId = util.getConstructionId();
    const userId = util.getUserId();

    this.props.download(
      params,
      filetype,
      (blob, fileName) => {
        const fname = !fileName
          ? "schedule_" + userId + "_" + constId + "_" + moment().format("YYYY-MM-DD_HHmmssSSS") + ".xlsx"
          : fileName;

        this.setState({ downloadUrl: URL.createObjectURL(blob), downloadName: fname }, () => {
          // @ts-ignore: MS用
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // @ts-ignore: MS用
            window.navigator.msSaveOrOpenBlob(blob, fname);
          } else {
            const evt = util.createClickEvent();
            // @ts-ignore: MS用
            this.btnDownload.dispatchEvent(evt);
          }
          this.setState({ downloading: false });
        });
      },
      fallback
    );
  }

  checkScheduleDate(config, scheduleDate) {
    const { edit_end_days, edit_end_time } = config;

    if (edit_end_days == "") {
      return true;
    }

    const schedule =
      moment(scheduleDate, "YYYY-MM-DD").add(parseInt(edit_end_days), "days").format("YYYY-MM-DD") +
      " " +
      ("0" + edit_end_time).slice(-5) +
      ":00";

    return moment().isBefore(schedule, "minute");
  }

  omitText(text, validLength) {
    if (text && text.length > validLength) {
      return `${text.substr(0, validLength)}...`;
    }

    return text;
  }

  omitProtectiveText(text) {
    const protect = text == null ? "" : text.split("\n");
    let output = "";

    for (let i = 0; i < protect.length; i++) {
      if (i == 3 || i == protect.length - 1) {
        if (protect[i].length > 9) {
          output = output + protect[i].slice(0, 9) + "...";
        } else {
          if (protect.length > 4) {
            output = output + protect[i] + "...";
          } else {
            output = output + protect[i];
          }
        }

        break;
      } else {
        output = output + protect[i] + "\n";
      }
    }

    return output;
  }

  isOmit(text, validLength) {
    return text && text.length > validLength ? true : false;
  }

  setTooltip(data) {
    this.setState({ tooltip: data });
  }

  handleOnMouse(columnValue, validLength) {
    if (this.isOmit(columnValue, validLength)) {
      this.setTooltip({
        value: columnValue,
      });
      ReactTooltip.rebuild();
    } else {
      this.setTooltip(null);
    }
  }

  beginEditNote3(id) {
    if (!this.state.isExcluse) {
      this.setState({
        ...this.state,
        editNote3: id,
        isExcluse: true,
      });
    }
  }

  endEditNote3(comment, row: ScheduleItemChiba) {
    if ((!comment && !row.note3) || comment === row.note3) {
      this.setState({ editNote3: null, isExcluse: false });
      return;
    }

    const { showAlert, hideAlert, t } = this.props;

    const data = {
      schedule_id: row.schedule_id,
      target: "note3",
      value: comment,
      timestamp: row.timestamp,
    };

    if (comment.length > 500) {
      showAlert(t("confirmation"), [t("builder_is_too_long_500")], hideAlert);

      return;
    }

    this.props.updateItem(
      data,
      () => {
        this.setState({ editNote3: null, isExcluse: false });
      },
      () => {
        this.setState({ editNote3: null });
      }
    );
  }

  beginEditSpecial(id) {
    if (!this.state.isExcluse) {
      this.setState({
        ...this.state,
        editSpecial: id,
        isExcluse: true,
      });
    }
  }

  endEditSpecial(comment, row) {
    const { showAlert, hideAlert, t } = this.props;

    const data = {
      schedule_id: row.schedule_id,
      target: "special_process_text",
      value: comment,
      timestamp: row.timestamp,
    };

    if (comment.length > 200) {
      showAlert(t("confirmation"), [t("special_is_too_long_200")], hideAlert);

      return;
    }

    this.props.updateItem(
      data,
      () => {
        this.setState({ editSpecial: null, isExcluse: false });
      },
      () => {
        this.setState({ editSpecial: null });
      }
    );
  }

  beginEditProtect(id) {
    if (!this.state.isExcluse) {
      this.setState({
        ...this.state,
        editProtect: id,
        isExcluse: true,
      });
    }
  }

  endEditProtect(comment, row) {
    const { showAlert, hideAlert, t } = this.props;

    const data = {
      schedule_id: row.schedule_id,
      target: "protective_equipment_text",
      value: comment,
      timestamp: row.timestamp,
    };

    if (comment.length > 200) {
      showAlert(t("confirmation"), [t("protection_is_too_long_200")], hideAlert);

      return;
    }

    this.props.updateItem(
      data,
      () => {
        this.setState({ editProtect: null, isExcluse: false });
      },
      () => {
        this.setState({ editProtect: null });
      }
    );
  }

  beginEditWorkStart(id) {
    if (!this.state.isExcluse) {
      this.setState({
        ...this.state,
        editWorkStart: id,
        isExcluse: true,
      });
    }
  }

  endEditWorkStart(comment, row: ScheduleItemChiba) {
    if ((!comment && !row.work_start_hour) || comment === row.work_start_hour) {
      this.setState({ editWorkStart: null, isExcluse: false });
      return;
    }

    const { showAlert, hideAlert, t } = this.props;

    const data = {
      schedule_id: row.schedule_id,
      target: "work_start_hour",
      value: comment,
      timestamp: row.timestamp,
    };

    if (comment.length > 0 && comment.match(/^([0-9]|[01][0-9]|2[0-3]):[0-5][0-9]$/u) == null) {
      showAlert(t("confirmation"), [t("oirec_chiba_work_time_alert")], hideAlert);

      return;
    }

    this.props.updateItem(
      data,
      () => {
        this.setState({ editWorkStart: null, isExcluse: false });
      },
      () => {
        this.setState({ editWorkStart: null });
      }
    );
  }

  beginEditWorkEnd(id) {
    if (!this.state.isExcluse) {
      this.setState({
        ...this.state,
        editWorkEnd: id,
        isExcluse: true,
      });
    }
  }

  endEditWorkEnd(comment, row: ScheduleItemChiba) {
    if ((!comment && !row.work_end_hour) || comment === row.work_end_hour) {
      this.setState({ editWorkEnd: null, isExcluse: false });
      return;
    }

    const { showAlert, hideAlert, t } = this.props;

    const data = {
      schedule_id: row.schedule_id,
      target: "work_end_hour",
      value: comment,
      timestamp: row.timestamp,
    };

    if (comment !== null && comment.length > 0 && comment.match(/^([0-9]|[01][0-9]|2[0-3]):[0-5][0-9]$/u) == null) {
      showAlert(t("confirmation"), [t("oirec_chiba_work_time_alert")], hideAlert);

      return;
    }

    this.props.updateItem(
      data,
      () => {
        this.setState({ editWorkEnd: null, isExcluse: false });
      },
      () => {
        this.setState({ editWorkEnd: null });
      }
    );
  }

  showSpecialProcessBundle() {
    this.setState({
      showSpecialBundleEditor: true,
    });
  }

  hideSpecialProcessBundle() {
    this.setState({ showSpecialBundleEditor: false });
  }

  showProtectionBundle() {
    this.setState({
      showProtectBundleEditor: true,
    });
  }

  hideProtectionBundle() {
    this.setState({ showProtectBundleEditor: false });
  }

  showSpecialProcess(row) {
    this.setState({ showSpecialEditor: true, row: row });
  }

  hideSpecialProcess() {
    this.setState({ showSpecialEditor: false });
  }

  showProtection(row) {
    this.setState({ showProtectionEditor: true, row: row });
  }

  hideProtection() {
    this.setState({ showProtectionEditor: false });
  }

  historyColumnNameMap = {
    [HistoryColumn.RECORD]: this.props.t("project_name"),
    [HistoryColumn.SPECIAL_PROCESS_TEXT]: this.props.t("special_work"),
    [HistoryColumn.PROCESS_TEXT]: this.props.t("content"),
    [HistoryColumn.CHECKPOINT1]: this.props.t("presence_request"),
    [HistoryColumn.CHECKPOINT2]: this.props.t("new_keep"),
    [HistoryColumn.CHECKPOINT3]: this.props.t("hot_work_direct"),
    [HistoryColumn.CHECKPOINT4]: this.props.t("hot_work_indirect"),
    [HistoryColumn.CHECKPOINT5]: this.props.t("container_work"),
    [HistoryColumn.CHECKPOINT6]: this.props.t("abnormal_work"),
    [HistoryColumn.WORK_START_HOUR]: this.props.t("worktime") + "：" + this.props.t("start_hour"),
    [HistoryColumn.WORK_END_HOUR]: this.props.t("worktime") + "：" + this.props.t("end_hour"),
    [HistoryColumn.NOTE3]: this.props.t("remarks_constructor"),
    [HistoryColumn.PROTECTIVE_EQUIPMENT_TEXT]: this.props.t("protection"),
    [HistoryColumn.FIELD1]: this.props.t("application") + "：" + this.props.t("pic_maintenance"),
    [HistoryColumn.NOTE1]: this.props.t("application") + "：" + this.props.t("remarks_maintenance"),
    [HistoryColumn.REQUEST_USER_ID]: this.props.t("application") + "：" + this.props.t("application"),
  };

  // 変更履歴の表示
  handleShowRevisionHistory(itemName: string, scheduleId: number, targetColumn: HistoryColumnType) {
    this.props.getHistories(scheduleId, targetColumn, (res) => {
      this.setState({
        historyData: {
          itemName,
          columnName: this.historyColumnNameMap[targetColumn],
          data:
            targetColumn === HistoryColumn.PROTECTIVE_EQUIPMENT_TEXT
              ? res.list.map((v) => ({
                  ...v,
                  content: (v.content ? `${v.content}\n` : "") + this.props.t("all_time_protective_gear"),
                }))
              : res.list,
        },
      });
    });
  }

  // 変更履歴の非表示
  handleHideRevisionHistory() {
    this.setState({ historyData: undefined });
  }

  handleNextPage() {
    const _currentPage = this.state.currentPage;

    if (_currentPage < this.state.maxPage) {
      const startPos = 1 + _currentPage * this.state.limit;
      this.handleSearchPager(startPos);
    }
  }

  handlePrevPage() {
    const _currentPage = this.state.currentPage;

    if (_currentPage > 1) {
      const startPos = 1 + (_currentPage - 2) * this.state.limit;
      this.handleSearchPager(startPos);
    }
  }

  showClipboardDialog(schedule: ScheduleItemChiba) {
    this.setState({ selectedScheduleForClipboardDialog: schedule });
  }
  closeClipboardDialog() {
    this.setState({ selectedScheduleForClipboardDialog: null });
  }

  hasHistory(schedule: ScheduleItemChiba, target: string): "cell-red" | "" {
    return (schedule?.history_alert ?? []).includes(target) ? "cell-red" : "";
  }

  render() {
    const titleClassName = `toggle icon-keyboard_arrow_up ${!this.state.showSearch ? "closed" : ""}`;
    const {
      masters,
      areaIds,
      deviceIds,
      categoryIds,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      field1Params,
      companyParams,
      userParams,
      groupParams,
      approveParams,
      checkpoint1,
      checkpoint2,
      checkpoint3,
      checkpoint4,
      checkpoint5,
      checkpoint6,
      filter,
      requestUser,
      field5,
      scheduleDate,
      changeArea,
      changeDevice,
      changeFacilityManagement,
      changeCategory,
      changeConstructionManagement,
      changePrimaryCharge,
      changeOther,
      changeConstructionCompany,
      changeField1,
      changeUser,
      changeGroup,
      changeApprove,
      changeCheckpoint1,
      changeCheckpoint2,
      changeCheckpoint3,
      changeCheckpoint4,
      changeCheckpoint5,
      changeCheckpoint6,
      changeFilter,
      changeRequestUser,
      changeField5,
      changeScheduleDate,
      t,
      items,
      bulkRoles,
      fetching,
      isError,
      roles,
    } = this.props;

    const constructionId = util.getConstructionId();

    return (
      <div id="schedule-chiba">
        <div className="contents">
          <div id="hiddenProcess" style={{ display: "none" }}></div>

          <div className="inner schedule">
            <div className="tbl-top-area mt-15 clearfix">
              <div className="tbl-top">
                <h1 className="page-ttl">
                  {t("schedule_list")}
                  <span
                    data-test-id="button-schedule-toggle-search"
                    className={titleClassName}
                    onClick={() => this.toggleSearch()}
                  ></span>
                </h1>
                {isValid(roles, "schedule_chiba", "create") && (
                  <button
                    data-test-id="button-schedule-search"
                    className="btn btn-blue w-170 schedule-reload"
                    onClick={this.showUpdatePanel}
                  >
                    <i className="icon icon-reload"></i>
                    {t("update_schedule")}
                  </button>
                )}
                <button
                  data-test-id="button-permit-table-download"
                  className="btn btn-blue w-210 permit-table-download-button"
                  onClick={this.showPermitTableDownloadPanel}
                >
                  {t("permit_table_download")}
                </button>
              </div>
            </div>
            {this.state.showSearch && (
              <ScheduleChibaSearchBox
                ref={(node) => (this.searchBox = node)}
                masters={masters}
                scheduleDate={scheduleDate === "" ? null : moment(scheduleDate, "YYYY/MM/DD").toDate()}
                areaIds={areaIds}
                deviceIds={deviceIds}
                categoryIds={categoryIds}
                facilityManagementIds={facilityManagementIds}
                constructionManagementIds={constructionManagementIds}
                primaryChargeIds={primaryChargeIds}
                otherIds={otherIds}
                companyIds={companyParams}
                userIds={userParams}
                groupIds={groupParams}
                field1Ids={field1Params}
                approveIds={approveParams}
                checkpoint1Id={checkpoint1}
                checkpoint2Id={checkpoint2}
                checkpoint3Id={checkpoint3}
                checkpoint4Id={checkpoint4}
                checkpoint5Id={checkpoint5}
                checkpoint6Id={checkpoint6}
                filter={filter}
                requestUserId={requestUser}
                field5Ids={field5}
                onChangeScheduleDate={(date) => {
                  const val = moment(date);
                  changeScheduleDate(
                    !date || !val.isValid() ? this.judgeScheduleDate() : val.format("YYYY-MM-DD").toString()
                  );
                }}
                onChangeArea={changeArea}
                onChangeDevice={changeDevice}
                onChangeCategory={changeCategory}
                onChangeFacilityManagement={changeFacilityManagement}
                onChangeConstructionManagement={changeConstructionManagement}
                onChangePrimaryCharge={changePrimaryCharge}
                onChangeOther={changeOther}
                onChangeCompany={changeConstructionCompany}
                onChangeUser={changeUser}
                onChangeGroup={changeGroup}
                onChangeField1={changeField1}
                onChangeApprove={changeApprove}
                onChangeCheckpoint1={changeCheckpoint1}
                onChangeCheckpoint2={changeCheckpoint2}
                onChangeCheckpoint3={changeCheckpoint3}
                onChangeCheckpoint4={changeCheckpoint4}
                onChangeCheckpoint5={changeCheckpoint5}
                onChangeCheckpoint6={changeCheckpoint6}
                onChangeFilter={changeFilter}
                onChangeRequestUser={changeRequestUser}
                onChangeField5={changeField5}
                onClear={() => this.handleClear()}
                onSearch={() => this.handleSearch(false)}
                searchConditionOptions={this.state.search_condition_options}
              />
            )}
            <div className={`tbl-schedules chiba ${fetching ? "loading loading--list" : ""}`}>
              {items.length === 0
                ? this.state.showTable && !isError && !fetching && <p className="empty-message">{t("no_data")}</p>
                : this.state.showTable &&
                  !isError && (
                    <React.Fragment>
                      <div className="tbl-top-area chiba clearfix relative">
                        <div className="tbl-top-left">
                          <div className="btn-dropdown-area" onMouseLeave={() => this.hideDropdown()}>
                            <button
                              data-test-id="button-summary-file-export"
                              className="btn btn-light-blue btn-dropdown"
                              onClick={() => this.showDropdown("list")}
                            >
                              <span className="icon icon-get_app" />
                              {t("file_export")}
                            </button>
                            {this.state.activeButton === "list" && (
                              <ul className="dropdown-menu" onClick={() => this.hideDropdown()}>
                                <li data-test-id="button-summary-list" onClick={() => this.download(1)}>
                                  <img src="./img/icon_xls.svg" alt="XLS" className="icon-file" />
                                  {t("table")}
                                </li>
                                <li data-test-id="button-summary-list" onClick={() => this.download(2)}>
                                  <img src="./img/icon_xls.svg" alt="XLS" className="icon-file" />
                                  {t("daily_active_separately")}
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                        <div className={`${this.state.downloading ? "loading-small loading-small-download" : ""}`} />
                      </div>
                      <div
                        className="tbl-area tbl-schedules-header tbl-head-adjusted"
                        ref={(node) => (this.theader = node)}
                      >
                        <div
                          className={`grid-container grid-container-schedule chiba grid-container-progress ${
                            this.state.isExpandedRightTable ? "" : "is-wide-table-chiba"
                          }`}
                          style={{ height: this.state.tableBodyMaxHeight }}
                        >
                          <div className="grid-left-header-chiba">
                            <table className="tbl-basic tbl-data list-table-row">
                              <thead className="schedule">
                                <tr className="schedule-chiba">
                                  <th rowSpan={2} className="col_index">
                                    No
                                    <button
                                      data-test-id="button-header-open"
                                      className={`grid-btn-collapse-chiba open ${
                                        this.state.isExpandedSpecialProcess ? "disp-non" : ""
                                      }`}
                                      onClick={(e) => this.toggleWide(e, "specialProcess")}
                                    >
                                      {"▶︎"}
                                    </button>
                                  </th>
                                  {
                                    /* 特殊作業 */
                                    // 折りたたみ機能
                                    !this.state.isExpandedSpecialProcess ? (
                                      <th rowSpan={2} className="folded folded--top">
                                        &nbsp;
                                      </th>
                                    ) : isValidRole(bulkRoles, 0) ? (
                                      <th
                                        rowSpan={2}
                                        className={`header-2row col10`}
                                        onClick={() => this.showSpecialProcessBundle()}
                                      >
                                        <GridCellWrap>
                                          <span className="title">{t("special_work")}</span>
                                          <EditIcon />
                                        </GridCellWrap>
                                        <button
                                          data-test-id="button-header-close"
                                          className="grid-btn-collapse-chiba close single"
                                          onClick={(e) => {
                                            this.toggleWide(e, "specialProcess");
                                          }}
                                        >
                                          {"◀︎"}
                                        </button>
                                      </th>
                                    ) : (
                                      <th rowSpan={2} className="col10">
                                        {t("special_work")}
                                        <button
                                          data-test-id="button-header-close"
                                          className="grid-btn-collapse-chiba close single"
                                          onClick={(e) => this.toggleWide(e, "specialProcess")}
                                        >
                                          {"◀︎"}
                                        </button>
                                      </th>
                                    )
                                  }

                                  <th rowSpan={2} className="col9">
                                    {t("plant")}
                                  </th>
                                  <th rowSpan={2} className="col9">
                                    {t("project_name")}
                                  </th>
                                  <th rowSpan={2} className="col10">
                                    {t("content")}
                                  </th>

                                  {
                                    /* 製油立会依頼 */
                                    isValidRole(bulkRoles, 1) ? (
                                      <th
                                        rowSpan={2}
                                        className="header-2row col17"
                                        onClick={() =>
                                          this.showScheduleEditorBundle("checkpoint1", t("presence_request"))
                                        }
                                      >
                                        <GridCellWrap className="vertical">
                                          <span className="title">{t("presence_request")}</span>
                                          <EditIcon />
                                        </GridCellWrap>
                                      </th>
                                    ) : (
                                      <th rowSpan={2} className="header-2row col17">
                                        <GridCellWrap className="vertical">
                                          <span>{t("presence_request")}</span>
                                        </GridCellWrap>
                                      </th>
                                    )
                                  }
                                  {
                                    /* 新規継続 */
                                    isValidRole(bulkRoles, 2) ? (
                                      <th
                                        rowSpan={2}
                                        className="header-2row col17"
                                        onClick={() => this.showScheduleEditorBundle("checkpoint2", t("new_keep"))}
                                      >
                                        <GridCellWrap className="vertical">
                                          <span className="title">{t("new_keep")}</span>
                                          <EditIcon />
                                        </GridCellWrap>
                                      </th>
                                    ) : (
                                      <th rowSpan={2} className="header-2row col17">
                                        <GridCellWrap className="vertical">
                                          <span>{t("new_keep")}</span>
                                        </GridCellWrap>
                                      </th>
                                    )
                                  }
                                  {
                                    /* 直接火気 */
                                    isValidRole(bulkRoles, 3) ? (
                                      <th
                                        rowSpan={2}
                                        className="header-2row col17"
                                        onClick={() =>
                                          this.showScheduleEditorBundle("checkpoint3", t("hot_work_direct"))
                                        }
                                      >
                                        <GridCellWrap className="vertical">
                                          <span className="title">{t("hot_work_direct")}</span>
                                          <EditIcon />
                                        </GridCellWrap>
                                      </th>
                                    ) : (
                                      <th rowSpan={2} className="header-2row col17">
                                        <GridCellWrap className="vertical">
                                          <span>{t("hot_work_direct")}</span>
                                        </GridCellWrap>
                                      </th>
                                    )
                                  }
                                  {
                                    /* 間接火気 */
                                    isValidRole(bulkRoles, 4) ? (
                                      <th
                                        rowSpan={2}
                                        className="header-2row col17"
                                        onClick={() =>
                                          this.showScheduleEditorBundle("checkpoint4", t("hot_work_indirect"))
                                        }
                                      >
                                        <GridCellWrap className="vertical">
                                          <span className="title">{t("hot_work_indirect")}</span>
                                          <EditIcon />
                                        </GridCellWrap>
                                      </th>
                                    ) : (
                                      <th rowSpan={2} className="header-2row col17">
                                        <GridCellWrap className="vertical">
                                          <span>{t("hot_work_indirect")}</span>
                                        </GridCellWrap>
                                      </th>
                                    )
                                  }
                                  {
                                    /* 容器内作業 */
                                    isValidRole(bulkRoles, 5) ? (
                                      <th
                                        rowSpan={2}
                                        className="header-2row col17"
                                        onClick={() =>
                                          this.showScheduleEditorBundle("checkpoint5", t("container_work"))
                                        }
                                      >
                                        <GridCellWrap className="vertical">
                                          <span className="title">{t("container_work")}</span>
                                          <EditIcon />
                                        </GridCellWrap>
                                      </th>
                                    ) : (
                                      <th rowSpan={2} className="header-2row col17">
                                        <GridCellWrap className="vertical">
                                          <span>{t("container_work")}</span>
                                        </GridCellWrap>
                                      </th>
                                    )
                                  }
                                  {
                                    /* 非定常作業 */
                                    isValidRole(bulkRoles, 6) ? (
                                      <th
                                        rowSpan={2}
                                        className="header-2row col17"
                                        onClick={() => this.showScheduleEditorBundle("checkpoint6", t("abnormal_work"))}
                                      >
                                        <GridCellWrap className="vertical">
                                          <span className="title">{t("abnormal_work")}</span>
                                          <EditIcon />
                                        </GridCellWrap>
                                        <button
                                          data-test-id="button-header-open"
                                          className={`grid-btn-collapse-chiba open ${
                                            this.state.isExpandedWorktime ? "disp-non" : ""
                                          }`}
                                          onClick={(e) => this.toggleWide(e, "worktime")}
                                        >
                                          {"▶︎"}
                                        </button>
                                      </th>
                                    ) : (
                                      <th rowSpan={2} className="header-2row col17">
                                        <GridCellWrap className="vertical">
                                          <span>{t("abnormal_work")}</span>
                                        </GridCellWrap>
                                        <button
                                          data-test-id="button-header-open"
                                          className={`grid-btn-collapse-chiba open ${
                                            this.state.isExpandedWorktime ? "disp-non" : ""
                                          }`}
                                          onClick={(e) => this.toggleWide(e, "worktime")}
                                        >
                                          {"▶︎"}
                                        </button>
                                      </th>
                                    )
                                  }
                                  {/* 折りたたみ機能（作業時間） */}
                                  {!this.state.isExpandedWorktime ? (
                                    <th className="folded folded--top">&nbsp;</th>
                                  ) : (
                                    <th colSpan={2} className="col-worktime-top col-top">
                                      {t("worktime")}
                                      <button
                                        data-test-id="button-header-close"
                                        className="grid-btn-collapse-chiba close single"
                                        onClick={(e) => this.toggleWide(e, "worktime")}
                                      >
                                        {"◀︎"}
                                      </button>
                                    </th>
                                  )}
                                  {/* 特記事項(施工者) */}
                                  {isValidRole(bulkRoles, 9) ? (
                                    <th
                                      rowSpan={2}
                                      className={`header-2row col19`}
                                      onClick={() =>
                                        this.showScheduleEditorNoteBundle("note3", t("remarks_constructor"))
                                      }
                                    >
                                      <GridCellWrap>
                                        <span className="title">{t("remarks_constructor")}</span>
                                        <EditIcon />
                                      </GridCellWrap>
                                    </th>
                                  ) : (
                                    <th rowSpan={2} className="col19">
                                      {t("remarks_constructor")}
                                    </th>
                                  )}
                                  {
                                    /* 必要保護具 */
                                    isValidRole(bulkRoles, 10) ? (
                                      <th
                                        rowSpan={2}
                                        className={`header-2row col19`}
                                        onClick={() => this.showProtectionBundle()}
                                      >
                                        <GridCellWrap>
                                          <span className="title">{t("protection")}</span>
                                          <EditIcon />
                                        </GridCellWrap>
                                        <button
                                          data-test-id="button-header-open"
                                          className={`grid-btn-collapse-chiba open ${
                                            this.state.isExpandedConstructionAttend ? "disp-non" : ""
                                          }`}
                                          onClick={(e) => this.toggleWide(e, "constructionAttend")}
                                        >
                                          {"▶︎"}
                                        </button>
                                      </th>
                                    ) : (
                                      <th rowSpan={2} className="col19">
                                        {t("protection")}
                                        <button
                                          data-test-id="button-header-open"
                                          className={`grid-btn-collapse-chiba open ${
                                            this.state.isExpandedConstructionAttend ? "disp-non" : ""
                                          }`}
                                          onClick={(e) => this.toggleWide(e, "constructionAttend")}
                                        >
                                          {"▶︎"}
                                        </button>
                                      </th>
                                    )
                                  }
                                  {/* 折りたたみ機能（施工） */}
                                  {!this.state.isExpandedConstructionAttend ? (
                                    <th className="folded folded--top">&nbsp;</th>
                                  ) : (
                                    <th colSpan={2} className="col4 col-top">
                                      {t("construction_attend")}
                                      <button
                                        data-test-id="button-header-close"
                                        className="grid-btn-collapse-chiba close single"
                                        onClick={(e) => this.toggleWide(e, "constructionAttend")}
                                      >
                                        {"◀︎"}
                                      </button>
                                    </th>
                                  )}
                                </tr>
                                <tr className="schedule-chiba">
                                  {
                                    /* 作業開始時間　 */
                                    // 折りたたみ機能（開始時間）
                                    !this.state.isExpandedWorktime ? (
                                      <th className="col-bottom folded"></th>
                                    ) : isValidRole(bulkRoles, 7) ? (
                                      <th
                                        rowSpan={2}
                                        className={`header-2row col-worktime`}
                                        onClick={() =>
                                          this.showScheduleEditorNoteBundle(
                                            "work_start_hour",
                                            t("worktime") + "：" + t("start_hour")
                                          )
                                        }
                                      >
                                        <GridCellWrap>
                                          <span className="title">{t("start_hour")}</span>
                                          <EditIcon />
                                        </GridCellWrap>
                                      </th>
                                    ) : (
                                      <th rowSpan={2} className="col-worktime">
                                        {t("start_hour")}
                                      </th>
                                    )
                                  }
                                  {
                                    /* 作業終了時間　 */
                                    // 折りたたみ機能
                                    !this.state.isExpandedWorktime ? null : isValidRole(bulkRoles, 8) ? (
                                      <th
                                        rowSpan={2}
                                        className={`header-2row col-worktime`}
                                        onClick={() =>
                                          this.showScheduleEditorNoteBundle(
                                            "work_end_hour",
                                            t("worktime") + "：" + t("end_hour")
                                          )
                                        }
                                      >
                                        <GridCellWrap>
                                          <span className="title">{t("end_hour")}</span>
                                          <EditIcon />
                                        </GridCellWrap>
                                      </th>
                                    ) : (
                                      <th rowSpan={2} className="col-worktime">
                                        {t("end_hour")}
                                      </th>
                                    )
                                  }

                                  {!this.state.isExpandedConstructionAttend ? (
                                    <>
                                      <th className="col-bottom folded"></th>
                                    </>
                                  ) : (
                                    <>
                                      <th className="col18 col-bottom">{t("company_name")}</th>
                                      <th className="col4">{t("manager")}</th>
                                    </>
                                  )}
                                </tr>
                              </thead>
                            </table>
                          </div>
                          {!this.state.isEmpty && (
                            <div
                              className="grid-left-body"
                              ref={(el) => (this.leftBodyContainer = el)}
                              onWheel={(e) => this.syncWheel(e)}
                            >
                              <table className="tbl-basic tbl-data list-table-row data-schedule grid-table-left-body-schedule">
                                <tbody className="schedule">
                                  {items.map((schedule: ScheduleItemChiba, index) => (
                                    <tr
                                      key={index}
                                      className={`schedule-chiba ${
                                        schedule.field6 == 2 ||
                                        schedule.field1_name == null ||
                                        schedule.field1_name == ""
                                          ? "color-gray"
                                          : ""
                                      }`}
                                    >
                                      <td className="col_index txt-center">{schedule.schedule_no}</td>
                                      {
                                        /* 特殊作業 */
                                        // 折りたたみ機能
                                        !this.state.isExpandedSpecialProcess ? (
                                          <td className="folded">&nbsp;</td>
                                        ) : isValidRole(schedule.roles, 0) ? (
                                          // TODO: issue No.887 17時以降に変更されたセルを着色する
                                          <td
                                            className={clsx(
                                              "no-hover col10 grid-cell-clickable txt-center schedule-right-body",
                                              this.hasHistory(schedule, HistoryColumn.SPECIAL_PROCESS_TEXT)
                                            )}
                                            data-tip
                                            data-for="schedule-full-text"
                                            onMouseEnter={() => this.handleOnMouse(schedule.special_process_text, 34)}
                                            onClick={() => this.showSpecialProcess(schedule)}
                                          >
                                            <GridCellWrap className="txt-left p-5">
                                              {this.omitText(schedule.special_process_text, 34)}
                                              <EditIcon />
                                            </GridCellWrap>
                                            <HistoryButton
                                              schedule={schedule}
                                              targetColumn={HistoryColumn.SPECIAL_PROCESS_TEXT}
                                              onOpenHistory={(id, col) =>
                                                this.handleShowRevisionHistory(schedule.item_name, id, col)
                                              }
                                            />
                                          </td>
                                        ) : (
                                          <td
                                            className={clsx(
                                              "col10",
                                              this.hasHistory(schedule, HistoryColumn.SPECIAL_PROCESS_TEXT)
                                            )}
                                            data-tip
                                            data-for="schedule-full-text"
                                            onMouseEnter={() => this.handleOnMouse(schedule.special_process_text, 34)}
                                          >
                                            <GridCellWrap>
                                              {this.omitText(schedule.special_process_text, 34)}
                                            </GridCellWrap>
                                            <HistoryButton
                                              schedule={schedule}
                                              targetColumn={HistoryColumn.SPECIAL_PROCESS_TEXT}
                                              onOpenHistory={(id, col) =>
                                                this.handleShowRevisionHistory(schedule.item_name, id, col)
                                              }
                                            />
                                          </td>
                                        )
                                      }
                                      {/* プラント */}
                                      <td
                                        className="col9"
                                        data-tip
                                        data-for="schedule-full-text"
                                        onMouseEnter={() => this.handleOnMouse(schedule.device_name, 15)}
                                      >
                                        <GridCellWrap className="txt-left">
                                          {this.omitText(schedule.device_name, 15)}
                                        </GridCellWrap>
                                      </td>
                                      {/* 機器No. */}
                                      <td
                                        className={clsx("col9", this.hasHistory(schedule, HistoryColumn.RECORD))}
                                        data-tip
                                        data-for="schedule-full-text"
                                        onMouseEnter={() => this.handleOnMouse(schedule.item_name, 26)}
                                      >
                                        <div className="grid-cell-wrap">
                                          <div className="grid-cell-con">
                                            <div className="grid-cell-inner txt-left">
                                              <a
                                                href={
                                                  `/#/${constructionId}/matrix` +
                                                  `?category_id=${schedule.category_id}&primary_charge_id=${schedule.primary_charge_id}&item_id=${schedule.item_id}`
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {this.omitText(schedule.item_name, 26)}
                                              </a>
                                            </div>
                                            <span
                                              className={clsx(
                                                "icon icon-clipboard cursor-pointer",
                                                0 < (schedule.item_url_num ?? 0) ? "" : "opacity-10",
                                                "hover:opacity-50"
                                              )}
                                              onClick={() => this.showClipboardDialog(schedule)}
                                            >
                                              <img className="attach_file" src="./img/icon-url-link.svg" />
                                            </span>
                                          </div>
                                        </div>
                                        <HistoryButton
                                          schedule={schedule}
                                          targetColumn={HistoryColumn.RECORD}
                                          onOpenHistory={(id, col) =>
                                            this.handleShowRevisionHistory(schedule.item_name, id, col)
                                          }
                                        />
                                      </td>
                                      {/* 作業内容 */}
                                      <td
                                        className={clsx("col10", this.hasHistory(schedule, HistoryColumn.PROCESS_TEXT))}
                                        data-tip
                                        data-for="schedule-full-text"
                                        onMouseEnter={() => this.handleOnMouse(schedule.process_text, 46)}
                                      >
                                        <GridCellWrap className="txt-left wrap">
                                          {this.omitText(schedule.process_text, 46)}
                                        </GridCellWrap>
                                        <HistoryButton
                                          schedule={schedule}
                                          targetColumn={HistoryColumn.PROCESS_TEXT}
                                          onOpenHistory={(id, col) =>
                                            this.handleShowRevisionHistory(schedule.item_name, id, col)
                                          }
                                        />
                                      </td>
                                      {
                                        /* 精油立会依頼 */
                                        isValidRole(schedule.roles, 1) ? (
                                          <td
                                            className={clsx(
                                              "col17 grid-cell-clickable txt-center",
                                              this.hasHistory(schedule, HistoryColumn.CHECKPOINT1)
                                            )}
                                            onClick={() =>
                                              this.showScheduleEditor(
                                                "checkpoint1",
                                                t("presence_request"),
                                                schedule.process_text,
                                                schedule.schedule_id,
                                                schedule.checkpoint1,
                                                schedule.timestamp
                                              )
                                            }
                                          >
                                            <GridCellWrap className="p-5">
                                              {this.omitText(schedule.checkpoint1_name, 2)}
                                              <EditIcon />
                                            </GridCellWrap>
                                            <HistoryButton
                                              schedule={schedule}
                                              targetColumn={HistoryColumn.CHECKPOINT1}
                                              onOpenHistory={(id, col) =>
                                                this.handleShowRevisionHistory(schedule.item_name, id, col)
                                              }
                                            />
                                          </td>
                                        ) : (
                                          <td
                                            className={clsx(
                                              "col17 txt-center",
                                              this.hasHistory(schedule, HistoryColumn.CHECKPOINT1)
                                            )}
                                          >
                                            <GridCellWrap className="p-5">
                                              {this.omitText(schedule.checkpoint1_name, 2)}
                                            </GridCellWrap>
                                            <HistoryButton
                                              schedule={schedule}
                                              targetColumn={HistoryColumn.CHECKPOINT1}
                                              onOpenHistory={(id, col) =>
                                                this.handleShowRevisionHistory(schedule.item_name, id, col)
                                              }
                                            />
                                          </td>
                                        )
                                      }
                                      {
                                        /* 新規継続 */
                                        isValidRole(schedule.roles, 2) ? (
                                          <td
                                            className={clsx(
                                              "col17 grid-cell-clickable txt-center",
                                              this.hasHistory(schedule, HistoryColumn.CHECKPOINT2)
                                            )}
                                            onClick={() =>
                                              this.showScheduleEditor(
                                                "checkpoint2",
                                                t("new_keep"),
                                                schedule.process_text,
                                                schedule.schedule_id,
                                                schedule.checkpoint2,
                                                schedule.timestamp
                                              )
                                            }
                                          >
                                            <GridCellWrap className="p-5">
                                              {this.omitText(schedule.checkpoint2_name, 2)}
                                              <EditIcon />
                                            </GridCellWrap>
                                            <HistoryButton
                                              schedule={schedule}
                                              targetColumn={HistoryColumn.CHECKPOINT2}
                                              onOpenHistory={(id, col) =>
                                                this.handleShowRevisionHistory(schedule.item_name, id, col)
                                              }
                                            />
                                          </td>
                                        ) : (
                                          <td
                                            className={clsx(
                                              "col17 txt-center",
                                              this.hasHistory(schedule, HistoryColumn.CHECKPOINT2)
                                            )}
                                          >
                                            <GridCellWrap className="p-5">
                                              {this.omitText(schedule.checkpoint2_name, 2)}
                                            </GridCellWrap>
                                            <HistoryButton
                                              schedule={schedule}
                                              targetColumn={HistoryColumn.CHECKPOINT2}
                                              onOpenHistory={(id, col) =>
                                                this.handleShowRevisionHistory(schedule.item_name, id, col)
                                              }
                                            />
                                          </td>
                                        )
                                      }
                                      {
                                        /* 直接火気 */
                                        isValidRole(schedule.roles, 3) ? (
                                          <td
                                            className={clsx(
                                              "col17 grid-cell-clickable txt-center",
                                              this.hasHistory(schedule, HistoryColumn.CHECKPOINT3)
                                            )}
                                            onClick={() =>
                                              this.showScheduleEditor(
                                                "checkpoint3",
                                                t("hot_work_direct"),
                                                schedule.process_text,
                                                schedule.schedule_id,
                                                schedule.checkpoint3,
                                                schedule.timestamp
                                              )
                                            }
                                          >
                                            <GridCellWrap className="p-5">
                                              {this.omitText(schedule.checkpoint3_name, 2)}
                                              <EditIcon />
                                            </GridCellWrap>
                                            <HistoryButton
                                              schedule={schedule}
                                              targetColumn={HistoryColumn.CHECKPOINT3}
                                              onOpenHistory={(id, col) =>
                                                this.handleShowRevisionHistory(schedule.item_name, id, col)
                                              }
                                            />
                                          </td>
                                        ) : (
                                          <td
                                            className={clsx(
                                              "col17 txt-center",
                                              this.hasHistory(schedule, HistoryColumn.CHECKPOINT3)
                                            )}
                                          >
                                            <GridCellWrap className="p-5">
                                              {this.omitText(schedule.checkpoint3_name, 2)}
                                            </GridCellWrap>
                                            <HistoryButton
                                              schedule={schedule}
                                              targetColumn={HistoryColumn.CHECKPOINT3}
                                              onOpenHistory={(id, col) =>
                                                this.handleShowRevisionHistory(schedule.item_name, id, col)
                                              }
                                            />
                                          </td>
                                        )
                                      }
                                      {
                                        /* 間接火気 */
                                        isValidRole(schedule.roles, 4) ? (
                                          <td
                                            className={clsx(
                                              "col17 grid-cell-clickable txt-center",
                                              this.hasHistory(schedule, HistoryColumn.CHECKPOINT4)
                                            )}
                                            onClick={() =>
                                              this.showScheduleEditor(
                                                "checkpoint4",
                                                t("hot_work_indirect"),
                                                schedule.process_text,
                                                schedule.schedule_id,
                                                schedule.checkpoint4,
                                                schedule.timestamp
                                              )
                                            }
                                          >
                                            <GridCellWrap className="p-5">
                                              {this.omitText(schedule.checkpoint4_name, 2)}
                                              <EditIcon />
                                            </GridCellWrap>
                                            <HistoryButton
                                              schedule={schedule}
                                              targetColumn={HistoryColumn.CHECKPOINT4}
                                              onOpenHistory={(id, col) =>
                                                this.handleShowRevisionHistory(schedule.item_name, id, col)
                                              }
                                            />
                                          </td>
                                        ) : (
                                          <td
                                            className={clsx(
                                              "col17 txt-center",
                                              this.hasHistory(schedule, HistoryColumn.CHECKPOINT4)
                                            )}
                                          >
                                            <GridCellWrap className="p-5">
                                              {this.omitText(schedule.checkpoint4_name, 2)}
                                            </GridCellWrap>
                                            <HistoryButton
                                              schedule={schedule}
                                              targetColumn={HistoryColumn.CHECKPOINT4}
                                              onOpenHistory={(id, col) =>
                                                this.handleShowRevisionHistory(schedule.item_name, id, col)
                                              }
                                            />
                                          </td>
                                        )
                                      }
                                      {
                                        /* 容器内作業 */
                                        isValidRole(schedule.roles, 5) ? (
                                          <td
                                            className={clsx(
                                              "col17 grid-cell-clickable txt-center",
                                              this.hasHistory(schedule, HistoryColumn.CHECKPOINT5)
                                            )}
                                            onClick={() =>
                                              this.showScheduleEditor(
                                                "checkpoint5",
                                                t("container_work"),
                                                schedule.process_text,
                                                schedule.schedule_id,
                                                schedule.checkpoint5,
                                                schedule.timestamp
                                              )
                                            }
                                          >
                                            <GridCellWrap className="p-5">
                                              {this.omitText(schedule.checkpoint5_name, 2)}
                                              <EditIcon />
                                            </GridCellWrap>
                                            <HistoryButton
                                              schedule={schedule}
                                              targetColumn={HistoryColumn.CHECKPOINT5}
                                              onOpenHistory={(id, col) =>
                                                this.handleShowRevisionHistory(schedule.item_name, id, col)
                                              }
                                            />
                                          </td>
                                        ) : (
                                          <td
                                            className={clsx(
                                              "col17 txt-center",
                                              this.hasHistory(schedule, HistoryColumn.CHECKPOINT5)
                                            )}
                                          >
                                            <GridCellWrap className="p-5">
                                              {this.omitText(schedule.checkpoint5_name, 2)}
                                            </GridCellWrap>
                                            <HistoryButton
                                              schedule={schedule}
                                              targetColumn={HistoryColumn.CHECKPOINT5}
                                              onOpenHistory={(id, col) =>
                                                this.handleShowRevisionHistory(schedule.item_name, id, col)
                                              }
                                            />
                                          </td>
                                        )
                                      }
                                      {
                                        /* 非定常作業 */
                                        isValidRole(schedule.roles, 6) ? (
                                          <td
                                            className={clsx(
                                              "col17 grid-cell-clickable txt-center",
                                              this.hasHistory(schedule, HistoryColumn.CHECKPOINT6)
                                            )}
                                            onClick={() =>
                                              this.showScheduleEditor(
                                                "checkpoint6",
                                                t("abnormal_work"),
                                                schedule.process_text,
                                                schedule.schedule_id,
                                                schedule.checkpoint6,
                                                schedule.timestamp
                                              )
                                            }
                                          >
                                            <GridCellWrap className="p-5">
                                              {this.omitText(schedule.checkpoint6_name, 2)}
                                              <EditIcon />
                                            </GridCellWrap>
                                            <HistoryButton
                                              schedule={schedule}
                                              targetColumn={HistoryColumn.CHECKPOINT6}
                                              onOpenHistory={(id, col) =>
                                                this.handleShowRevisionHistory(schedule.item_name, id, col)
                                              }
                                            />
                                          </td>
                                        ) : (
                                          <td
                                            className={clsx(
                                              "col17 txt-center",
                                              this.hasHistory(schedule, HistoryColumn.CHECKPOINT6)
                                            )}
                                          >
                                            <GridCellWrap className="p-5">
                                              {this.omitText(schedule.checkpoint6_name, 2)}
                                            </GridCellWrap>
                                            <HistoryButton
                                              schedule={schedule}
                                              targetColumn={HistoryColumn.CHECKPOINT6}
                                              onOpenHistory={(id, col) =>
                                                this.handleShowRevisionHistory(schedule.item_name, id, col)
                                              }
                                            />
                                          </td>
                                        )
                                      }
                                      {
                                        /* 作業開始時間 */
                                        // 折りたたみ機能
                                        !this.state.isExpandedWorktime ? (
                                          <td className="folded">&nbsp;</td>
                                        ) : isValidRole(schedule.roles, 7) ? (
                                          schedule.schedule_id === this.state.editWorkStart ? (
                                            <td className="no-hover col-worktime grid-cell-clickable txt-center schedule-right-body">
                                              <GridCellWrap className="schedule-note txt-left">
                                                <textarea
                                                  className="grid-textarea txt-left p-5"
                                                  ref={(node) => (this.workStartTextarea = node)}
                                                  onBlur={(e) => this.endEditWorkStart(e.target.value, schedule)}
                                                  defaultValue={schedule.work_start_hour}
                                                />
                                              </GridCellWrap>
                                            </td>
                                          ) : (
                                            <td
                                              className={clsx(
                                                "col-worktime grid-cell-clickable txt-center schedule-right-body",
                                                this.hasHistory(schedule, HistoryColumn.WORK_START_HOUR)
                                              )}
                                              onClick={() => this.beginEditWorkStart(schedule.schedule_id)}
                                            >
                                              <GridCellWrap className="schedule-note txt-center p-5">
                                                {schedule.work_start_hour}
                                                <EditIcon />
                                              </GridCellWrap>
                                              <HistoryButton
                                                schedule={schedule}
                                                targetColumn={HistoryColumn.WORK_START_HOUR}
                                                onOpenHistory={(id, col) =>
                                                  this.handleShowRevisionHistory(schedule.item_name, id, col)
                                                }
                                              />
                                            </td>
                                          )
                                        ) : (
                                          <td
                                            className={clsx(
                                              "col-worktime txt-center schedule-right-body",
                                              this.hasHistory(schedule, HistoryColumn.WORK_START_HOUR)
                                            )}
                                          >
                                            <GridCellWrap className="schedule-note txt-center p-5">
                                              {schedule.work_start_hour}
                                            </GridCellWrap>
                                            <HistoryButton
                                              schedule={schedule}
                                              targetColumn={HistoryColumn.WORK_START_HOUR}
                                              onOpenHistory={(id, col) =>
                                                this.handleShowRevisionHistory(schedule.item_name, id, col)
                                              }
                                            />
                                          </td>
                                        )
                                      }
                                      {
                                        /* 作業終了時間 */
                                        // 折りたたみ機能
                                        !this.state.isExpandedWorktime ? null : isValidRole(schedule.roles, 8) ? (
                                          schedule.schedule_id === this.state.editWorkEnd ? (
                                            <td className="no-hover col-worktime grid-cell-clickable txt-center schedule-right-body">
                                              <GridCellWrap className="schedule-note txt-left">
                                                <textarea
                                                  className="grid-textarea txt-left p-5"
                                                  ref={(node) => (this.workEndTextarea = node)}
                                                  onBlur={(e) => this.endEditWorkEnd(e.target.value, schedule)}
                                                  defaultValue={schedule.work_end_hour}
                                                />
                                              </GridCellWrap>
                                            </td>
                                          ) : (
                                            <td
                                              className={clsx(
                                                "col-worktime grid-cell-clickable txt-center schedule-right-body",
                                                this.hasHistory(schedule, HistoryColumn.WORK_END_HOUR)
                                              )}
                                              onClick={() => this.beginEditWorkEnd(schedule.schedule_id)}
                                            >
                                              <GridCellWrap className="schedule-note txt-center p-5">
                                                {schedule.work_end_hour}
                                                <EditIcon />
                                              </GridCellWrap>
                                              <HistoryButton
                                                schedule={schedule}
                                                targetColumn={HistoryColumn.WORK_END_HOUR}
                                                onOpenHistory={(id, col) =>
                                                  this.handleShowRevisionHistory(schedule.item_name, id, col)
                                                }
                                              />
                                            </td>
                                          )
                                        ) : (
                                          <td
                                            className={clsx(
                                              "col-worktime txt-center schedule-right-body",
                                              this.hasHistory(schedule, HistoryColumn.WORK_END_HOUR)
                                            )}
                                          >
                                            <GridCellWrap className="schedule-note txt-center p-5">
                                              {schedule.work_end_hour}
                                            </GridCellWrap>
                                            <HistoryButton
                                              schedule={schedule}
                                              targetColumn={HistoryColumn.WORK_END_HOUR}
                                              onOpenHistory={(id, col) =>
                                                this.handleShowRevisionHistory(schedule.item_name, id, col)
                                              }
                                            />
                                          </td>
                                        )
                                      }
                                      {/* 特記事項（施工者）*/}
                                      {isValidRole(schedule.roles, 9) ? (
                                        schedule.schedule_id === this.state.editNote3 ? (
                                          <td className="no-hover col19 grid-cell-clickable txt-center schedule-right-body">
                                            <GridCellWrap className="schedule-note txt-left">
                                              <textarea
                                                className="grid-textarea txt-left p-5"
                                                ref={(node) => (this.note3Textarea = node)}
                                                onBlur={(e) => this.endEditNote3(e.target.value, schedule)}
                                                defaultValue={schedule.note3}
                                              />
                                            </GridCellWrap>
                                          </td>
                                        ) : (
                                          <td
                                            className={clsx(
                                              "col19 grid-cell-clickable txt-center schedule-right-body",
                                              this.hasHistory(schedule, HistoryColumn.NOTE3)
                                            )}
                                            onClick={() => this.beginEditNote3(schedule.schedule_id)}
                                            data-tip
                                            data-for="schedule-full-text"
                                            onMouseEnter={() => this.handleOnMouse(schedule.note3, 34)}
                                          >
                                            <GridCellWrap className="schedule-note txt-left p-5">
                                              {this.omitText(schedule.note3, 34)}
                                              <EditIcon />
                                            </GridCellWrap>
                                            <HistoryButton
                                              schedule={schedule}
                                              targetColumn={HistoryColumn.NOTE3}
                                              onOpenHistory={(id, col) =>
                                                this.handleShowRevisionHistory(schedule.item_name, id, col)
                                              }
                                            />
                                          </td>
                                        )
                                      ) : (
                                        <td
                                          className={clsx(
                                            "col19 txt-center schedule-right-body",
                                            this.hasHistory(schedule, HistoryColumn.NOTE3)
                                          )}
                                          data-tip
                                          data-for="schedule-full-text"
                                          onMouseEnter={() => this.handleOnMouse(schedule.note3, 34)}
                                        >
                                          <GridCellWrap className="schedule-note txt-left p-5">
                                            {this.omitText(schedule.note3, 34)}
                                          </GridCellWrap>
                                          <HistoryButton
                                            schedule={schedule}
                                            targetColumn={HistoryColumn.NOTE3}
                                            onOpenHistory={(id, col) =>
                                              this.handleShowRevisionHistory(schedule.item_name, id, col)
                                            }
                                          />
                                        </td>
                                      )}
                                      {
                                        /* 必要保護具 */
                                        isValidRole(schedule.roles, 10) ? (
                                          <td
                                            className={clsx(
                                              "no-hover col19 grid-cell-clickable txt-center schedule-right-body",
                                              this.hasHistory(schedule, HistoryColumn.PROTECTIVE_EQUIPMENT_TEXT)
                                            )}
                                            data-tip
                                            data-for="schedule-full-text"
                                            onMouseEnter={() =>
                                              this.handleOnMouse(
                                                `${schedule.protective_equipment_text}\n${t(
                                                  "all_time_protective_gear"
                                                )}`,
                                                34
                                              )
                                            }
                                            onClick={() => this.showProtection(schedule)}
                                          >
                                            <GridCellWrap className="txt-left p-5">
                                              {this.omitText(
                                                `${schedule.protective_equipment_text} ${t(
                                                  "all_time_protective_gear"
                                                )}`,
                                                34
                                              )}
                                              <EditIcon />
                                            </GridCellWrap>
                                            <HistoryButton
                                              schedule={schedule}
                                              targetColumn={HistoryColumn.PROTECTIVE_EQUIPMENT_TEXT}
                                              onOpenHistory={(id, col) =>
                                                this.handleShowRevisionHistory(schedule.item_name, id, col)
                                              }
                                            />
                                          </td>
                                        ) : (
                                          <td
                                            className={clsx(
                                              "col19",
                                              this.hasHistory(schedule, HistoryColumn.PROTECTIVE_EQUIPMENT_TEXT)
                                            )}
                                            data-tip
                                            data-for="schedule-full-text"
                                            onMouseEnter={() =>
                                              this.handleOnMouse(
                                                `${schedule.protective_equipment_text}\n${t(
                                                  "all_time_protective_gear"
                                                )}`,
                                                34
                                              )
                                            }
                                          >
                                            <GridCellWrap>
                                              {this.omitText(
                                                `${schedule.protective_equipment_text} ${t(
                                                  "all_time_protective_gear"
                                                )}`,
                                                34
                                              )}
                                            </GridCellWrap>
                                            <HistoryButton
                                              schedule={schedule}
                                              targetColumn={HistoryColumn.PROTECTIVE_EQUIPMENT_TEXT}
                                              onOpenHistory={(id, col) =>
                                                this.handleShowRevisionHistory(schedule.item_name, id, col)
                                              }
                                            />
                                          </td>
                                        )
                                      }
                                      {/* 折りたたみ機能（施工:会社名 & 監督名 */}
                                      {!this.state.isExpandedConstructionAttend ? (
                                        <td className="folded">&nbsp;</td>
                                      ) : (
                                        <>
                                          <td
                                            className="col18"
                                            data-tip
                                            data-for="schedule-full-text"
                                            onMouseEnter={() => this.handleOnMouse(schedule.company_name, 30)}
                                          >
                                            <GridCellWrap className="txt-left">
                                              {this.omitText(schedule.company_name, 30)}
                                            </GridCellWrap>
                                          </td>
                                          <td
                                            className="col4"
                                            data-tip
                                            data-for="schedule-full-text"
                                            onMouseEnter={() => this.handleOnMouse(schedule.user_name, 16)}
                                          >
                                            <GridCellWrap className="txt-left">
                                              {this.omitText(schedule.user_name, 16)}
                                            </GridCellWrap>
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                          <div className="grid-right-header" ref={(el) => (this.rightHeaderContainer = el)}>
                            <table className="tbl-basic tbl-data list-table-row schedule-table">
                              <thead className="schedule">
                                <tr className="schedule-chiba">
                                  <th colSpan={3} className="col-top">
                                    {t("application")}
                                  </th>
                                  <th colSpan={8} className="pos-rel">
                                    {t("operation_approval")}
                                    <button
                                      data-test-id="button-header-open"
                                      className={`grid-btn-collapse-chiba open ${
                                        this.state.isExpandedRightTable ? "disp-non" : ""
                                      }`}
                                      onClick={(e) => this.toggleWide(e, "rightTable")}
                                    >
                                      {"▶︎"}
                                    </button>
                                  </th>
                                  <th colSpan={3} className="pos-rel close" style={{ paddingLeft: "30px" }}>
                                    {t("presence")}({t("pre_construction_start")})
                                    <button
                                      data-test-id="button-header-close"
                                      className="grid-btn-collapse-chiba close"
                                      onClick={(e) => this.toggleWide(e, "rightTable")}
                                    >
                                      {"◀︎"}
                                    </button>
                                  </th>
                                  <th colSpan={3} className="pos-rel close">
                                    {t("presence")}({t("post_construction_start")})
                                  </th>
                                  <th colSpan={2} className="close">
                                    {t("completion")}
                                  </th>
                                </tr>
                                <tr className="chiba-list-header-right-3rd schedule-chiba">
                                  {isValidRole(bulkRoles, 11) ? (
                                    <th
                                      rowSpan={2}
                                      className={`header-bottom col11 col-bottom`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field1",
                                          t("application") + "：" + t("pic_maintenance")
                                        )
                                      }
                                    >
                                      <GridCellWrap>
                                        <span className="title">{t("pic_maintenance")}</span>
                                        <EditIcon />
                                      </GridCellWrap>
                                    </th>
                                  ) : (
                                    <th rowSpan={2} className="col11 col-bottom">
                                      <GridCellWrap>
                                        <span className="default">{t("pic_maintenance")}</span>
                                      </GridCellWrap>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 12) ? (
                                    <th
                                      rowSpan={2}
                                      className={`header-bottom col18`}
                                      onClick={() =>
                                        this.showScheduleEditorNoteBundle(
                                          "note1",
                                          t("application") + "：" + t("remarks_maintenance")
                                        )
                                      }
                                    >
                                      <GridCellWrap>
                                        <span className="title">{t("remarks_maintenance")}</span>
                                        <EditIcon />
                                      </GridCellWrap>
                                    </th>
                                  ) : (
                                    <th rowSpan={2} className="col18">
                                      <GridCellWrap>
                                        <span className="default">{t("remarks_maintenance")}</span>
                                      </GridCellWrap>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 13) ? (
                                    <th
                                      rowSpan={2}
                                      className={`header-bottom col11`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "request",
                                          t("application") + "：" + t("application")
                                        )
                                      }
                                    >
                                      <GridCellWrap>
                                        <span className="title">{t("request")}</span>
                                        <EditIcon />
                                      </GridCellWrap>
                                    </th>
                                  ) : (
                                    <th rowSpan={2} className="col11">
                                      <GridCellWrap>
                                        <span className="default">{t("request")}</span>
                                      </GridCellWrap>
                                    </th>
                                  )}
                                  {/* ガス検結果 */}
                                  <th colSpan={2} className="col11">
                                    <GridCellWrap>
                                      <span className="default">{t("gas_leakage")}</span>
                                    </GridCellWrap>
                                  </th>
                                  {isValidRole(bulkRoles, 16) ? (
                                    <th
                                      rowSpan={2}
                                      className={`header-bottom col11`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field3",
                                          t("operation_approval") + "：" + t("isolation_depressure")
                                        )
                                      }
                                    >
                                      <GridCellWrap>
                                        <span className="title">{t("isolation_depressure")}</span>
                                        <EditIcon />
                                      </GridCellWrap>
                                    </th>
                                  ) : (
                                    <th rowSpan={2} className="col11">
                                      <GridCellWrap>
                                        <span className="default">{t("isolation_depressure")}</span>
                                      </GridCellWrap>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 17) ? (
                                    <th
                                      rowSpan={2}
                                      className={`header-bottom col11`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field4",
                                          t("operation_approval") + "：" + t("deenergization")
                                        )
                                      }
                                    >
                                      <GridCellWrap>
                                        <span className="title">{t("deenergization")}</span>
                                        <EditIcon />
                                      </GridCellWrap>
                                    </th>
                                  ) : (
                                    <th rowSpan={2} className="col11">
                                      <GridCellWrap>
                                        <span className="default">{t("deenergization")}</span>
                                      </GridCellWrap>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 18) ? (
                                    <th
                                      rowSpan={2}
                                      className={`header-bottom col11`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field5",
                                          t("operation_approval") + "：" + t("pic")
                                        )
                                      }
                                    >
                                      <GridCellWrap>
                                        <span className="title">{t("pic")}</span>
                                        <EditIcon />
                                      </GridCellWrap>
                                    </th>
                                  ) : (
                                    <th rowSpan={2} className="col11">
                                      <GridCellWrap>
                                        <span className="default">{t("pic")}</span>
                                      </GridCellWrap>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 19) ? (
                                    <th
                                      rowSpan={2}
                                      className={`header-bottom col11`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field6",
                                          t("operation_approval") + "：" + t("oil_approval")
                                        )
                                      }
                                    >
                                      <GridCellWrap>
                                        <span className="title">{t("oil_approval")}</span>
                                        <EditIcon />
                                      </GridCellWrap>
                                    </th>
                                  ) : (
                                    <th rowSpan={2} className="col11">
                                      <GridCellWrap>
                                        <span className="default">{t("oil_approval")}</span>
                                      </GridCellWrap>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 20) ? (
                                    <th
                                      rowSpan={2}
                                      className={`header-bottom col18`}
                                      onClick={() =>
                                        this.showScheduleEditorNoteBundle(
                                          "note2",
                                          t("operation_approval") + "：" + t("remarks")
                                        )
                                      }
                                    >
                                      <GridCellWrap>
                                        <span className="title">{t("remarks")}</span>
                                        <EditIcon />
                                      </GridCellWrap>
                                    </th>
                                  ) : (
                                    <th rowSpan={2} className="col18">
                                      <GridCellWrap>
                                        <span className="default">{t("remarks")}</span>
                                      </GridCellWrap>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 21) ? (
                                    <th
                                      rowSpan={2}
                                      className={`header-bottom col11`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "approve",
                                          t("operation_approval") + "：" + t("approval")
                                        )
                                      }
                                    >
                                      <GridCellWrap>
                                        <span className="title">{t("approval")}</span>
                                        <EditIcon />
                                      </GridCellWrap>
                                    </th>
                                  ) : (
                                    <th rowSpan={2} className="col11">
                                      <GridCellWrap>
                                        <span className="default">{t("approval")}</span>
                                      </GridCellWrap>
                                    </th>
                                  )}
                                  {/* 立会者サイン（工事開始前） 製油 */}
                                  {isValidRole(bulkRoles, 22) ? (
                                    <th
                                      rowSpan={2}
                                      className={`header-bottom col11 close`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field7",
                                          `${t("presence")}（${t("pre_start")}）：${t("operation")}`
                                        )
                                      }
                                    >
                                      <GridCellWrap>
                                        <span className="title">{t("operation")}</span>
                                        <EditIcon />
                                      </GridCellWrap>
                                    </th>
                                  ) : (
                                    <th rowSpan={2} className="col11 close">
                                      <GridCellWrap>
                                        <span className="default">{t("operation")}</span>
                                      </GridCellWrap>
                                    </th>
                                  )}
                                  {/* 立会者サイン（工事開始前） 工事担当 */}
                                  {isValidRole(bulkRoles, 23) ? (
                                    <th
                                      rowSpan={2}
                                      className={`header-bottom col11 close`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field8",
                                          `${t("presence")}（${t("pre_start")}）：${t("maintenance")}`
                                        )
                                      }
                                    >
                                      <GridCellWrap>
                                        <span className="title">{t("maintenance")}</span>
                                        <EditIcon />
                                      </GridCellWrap>
                                    </th>
                                  ) : (
                                    <th rowSpan={2} className="col11 close">
                                      <GridCellWrap>
                                        <span className="default">{t("maintenance")}</span>
                                      </GridCellWrap>
                                    </th>
                                  )}
                                  {/* 立会者サイン（工事開始前） 施工者 */}
                                  {isValidRole(bulkRoles, 24) ? (
                                    <th
                                      rowSpan={2}
                                      className={`header-bottom col11 close`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field9",
                                          `${t("presence")}（${t("pre_start")}）：${t("contractor")}`
                                        )
                                      }
                                    >
                                      <GridCellWrap>
                                        <span className="title">{t("contractor")}</span>
                                        <EditIcon />
                                      </GridCellWrap>
                                    </th>
                                  ) : (
                                    <th rowSpan={2} className="col11 close">
                                      <GridCellWrap>
                                        <span className="default">{t("contractor")}</span>
                                      </GridCellWrap>
                                    </th>
                                  )}
                                  {/* 立会者サイン（工事開始後） 製油 */}
                                  {isValidRole(bulkRoles, 25) ? (
                                    <th
                                      rowSpan={2}
                                      className={`header-bottom col11 close`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field13",
                                          `${t("presence")}（${t("post_start")}）：${t("operation")}`
                                        )
                                      }
                                    >
                                      <GridCellWrap>
                                        <span className="title">{t("operation")}</span>
                                        <EditIcon />
                                      </GridCellWrap>
                                    </th>
                                  ) : (
                                    <th rowSpan={2} className="col11 close">
                                      <GridCellWrap>
                                        <span className="default">{t("operation")}</span>
                                      </GridCellWrap>
                                    </th>
                                  )}
                                  {/* 立会者サイン（工事開始後） 工事担当 */}
                                  {isValidRole(bulkRoles, 26) ? (
                                    <th
                                      rowSpan={2}
                                      className={`header-bottom col11 close`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field14",
                                          `${t("presence")}（${t("post_start")}）：${t("maintenance")}`
                                        )
                                      }
                                    >
                                      <GridCellWrap>
                                        <span className="title">{t("maintenance")}</span>
                                        <EditIcon />
                                      </GridCellWrap>
                                    </th>
                                  ) : (
                                    <th rowSpan={2} className="col11 close">
                                      <GridCellWrap>
                                        <span className="default">{t("maintenance")}</span>
                                      </GridCellWrap>
                                    </th>
                                  )}
                                  {/* 立会者サイン（工事開始後） 施工者 */}
                                  {isValidRole(bulkRoles, 27) ? (
                                    <th
                                      rowSpan={2}
                                      className={`header-bottom col11 close`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field15",
                                          `${t("presence")}（${t("post_start")}）：${t("contractor")}`
                                        )
                                      }
                                    >
                                      <GridCellWrap>
                                        <span className="title">{t("contractor")}</span>
                                        <EditIcon />
                                      </GridCellWrap>
                                    </th>
                                  ) : (
                                    <th rowSpan={2} className="col11 close">
                                      <GridCellWrap>
                                        <span className="default">{t("contractor")}</span>
                                      </GridCellWrap>
                                    </th>
                                  )}
                                  {/* 工事完了確認 施工者 */}
                                  {isValidRole(bulkRoles, 28) ? (
                                    <th
                                      rowSpan={2}
                                      className={`header-bottom col11 close`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field10",
                                          t("completion") + "：" + t("contractor")
                                        )
                                      }
                                    >
                                      <GridCellWrap>
                                        <span className="title">{t("contractor")}</span>
                                        <EditIcon />
                                      </GridCellWrap>
                                    </th>
                                  ) : (
                                    <th rowSpan={2} className="col11 close">
                                      <GridCellWrap>
                                        <span className="default">{t("contractor")}</span>
                                      </GridCellWrap>
                                    </th>
                                  )}
                                  {/* 工事完了確認 製油 */}
                                  {isValidRole(bulkRoles, 29) ? (
                                    <th
                                      rowSpan={2}
                                      className={`header-bottom col11 close`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field11",
                                          t("completion") + "：" + t("operation")
                                        )
                                      }
                                    >
                                      <GridCellWrap>
                                        <span className="title">{t("operation")}</span>
                                        <EditIcon />
                                      </GridCellWrap>
                                    </th>
                                  ) : (
                                    <th rowSpan={2} className="col11 close">
                                      <GridCellWrap>
                                        <span className="default">{t("operation")}</span>
                                      </GridCellWrap>
                                    </th>
                                  )}
                                </tr>
                                <tr>
                                  {/* 雰囲気 */}
                                  {isValidRole(bulkRoles, 14) ? (
                                    <th
                                      className="header-bottom col11"
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field2",
                                          `${t("operation_approval")}：${t("gas_leakage")}（${t("atmosphere")}）`
                                        )
                                      }
                                    >
                                      <GridCellWrap>
                                        <span className="title">{t("atmosphere")}</span>
                                        <EditIcon />
                                      </GridCellWrap>
                                    </th>
                                  ) : (
                                    <th className="col11">{t("atmosphere")}</th>
                                  )}
                                  {/* 入槽 */}
                                  {isValidRole(bulkRoles, 15) ? (
                                    <th
                                      className="header-bottom col11"
                                      style={{ borderRightColor: "white" }}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field12",
                                          `${t("operation_approval")}：${t("gas_leakage")}（${t("in_tank")}）`
                                        )
                                      }
                                    >
                                      <GridCellWrap>
                                        <span className="title">{t("in_tank")}</span>
                                        <EditIcon />
                                      </GridCellWrap>
                                    </th>
                                  ) : (
                                    <th className="col11" style={{ borderRightColor: "white" }}>
                                      {t("in_tank")}
                                    </th>
                                  )}
                                </tr>
                              </thead>
                            </table>
                          </div>
                          {!this.state.isEmpty && (
                            <div
                              id="right-body"
                              className="grid-right-body sch-chiba"
                              onScroll={(e) => this.syncScroll(e)}
                              ref={(node) => (this.rightBodyContainer = node)}
                            >
                              <table className="tbl-basic tbl-data list-table-row data-schedule grid-table-right-body-schedule schedule-table">
                                <tbody className="schedule">
                                  {items.map((schedule: ScheduleItemChiba, index) => (
                                    <ScheduleRightRowChibaContainer
                                      key={index}
                                      index={index}
                                      schedule={schedule}
                                      showScheduleEditor={(
                                        updateTarget,
                                        column,
                                        processText,
                                        scheduleId,
                                        optionId,
                                        timestamp
                                      ) =>
                                        this.showScheduleEditor(
                                          updateTarget,
                                          column,
                                          processText,
                                          scheduleId,
                                          optionId,
                                          timestamp
                                        )
                                      }
                                      handleOnMouse={(columnValue, validLength) =>
                                        this.handleOnMouse(columnValue, validLength)
                                      }
                                      omitText={(text, validLength) => this.omitText(text, validLength)}
                                      setTooltip={this.setTooltip}
                                      onOpenHistory={(id, col) =>
                                        this.handleShowRevisionHistory(schedule.item_name, id, col)
                                      }
                                    />
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  )}
            </div>
          </div>
          <a
            className="d-n"
            download={this.state.downloadName}
            href={this.state.downloadUrl}
            ref={(node) => (this.btnDownload = node)}
          >
            download
          </a>
        </div>
        {this.state.maxPage > 0 && (
          <div className="pagination">
            <div className="null-box grid1">
              {this.state.currentPage > 1 && (
                <div className="left-arrow-box" onClick={() => this.handlePrevPage()}>
                  <span>{t("prev")}</span>
                </div>
              )}
            </div>
            <span className="page-status">
              {this.state.currentPage} / {this.state.maxPage}
            </span>
            <div className="null-box grid3">
              {this.state.currentPage < this.state.maxPage && (
                <div className="right-arrow-box" onClick={() => this.handleNextPage()}>
                  <span>{t("next")}</span>
                </div>
              )}
            </div>
          </div>
        )}

        {this.state.showUpdateBundle && (
          <ScheduleEditorBundleChibaContainer
            scheduleIds={items.map((item) => item.schedule_id)}
            target={this.state.target}
            panelText={this.state.panelText}
            selectValues={this.state.selectValues}
            closeHandler={() => this.hideScheduleEditor()}
            searchHandler={() => this.handleBundleSearch()}
          />
        )}
        {this.state.showEditor && (
          <ScheduleEditorChibaContainer
            target={this.state.target}
            processText={this.state.processText}
            scheduleId={this.state.scheduleId}
            optionId={this.state.optionId}
            panelText={this.state.panelText}
            timeStamp={this.state.timeStamp}
            selectValues={this.state.selectValues}
            closeHandler={() => this.hideScheduleEditor()}
          />
        )}
        {this.state.showUpdatePanel && (
          <ScheduleUpdateChibaContainer
            categories={masters.categories}
            closeHandler={() => this.hideScheduleEditor()}
          />
        )}
        {this.state.showPermitTableDownloadPanel && (
          <PermitTableDownloadChibaContainer
            scheduleDateList={this.getPermitTableDownloadDateList()}
            closeHandler={() => this.hidePermitTableDownloadPanel()}
          />
        )}
        {this.state.tooltip !== null && (
          <ReactTooltip
            id="schedule-full-text"
            className="matrix-task-detail"
            delayShow={500}
            effect="solid"
            isCapture={true}
            scrollHide={true}
          >
            <TooltipContainer data={this.state.tooltip} />
          </ReactTooltip>
        )}
        {this.state.showSpecialBundleEditor && (
          <SpecialProcessBundleEditorContainer
            searchHandler={() => this.handleBundleSearch()}
            closeHandler={this.hideSpecialProcessBundle}
            scheduleIds={items.map((item) => item.schedule_id)}
          />
        )}
        {this.state.showProtectBundleEditor && (
          <ProtectionBundleEditorContainer
            searchHandler={() => this.handleBundleSearch()}
            closeHandler={this.hideProtectionBundle}
            scheduleIds={items.map((item) => item.schedule_id)}
          />
        )}
        {this.state.showSpecialEditor && (
          <SpecialProcessEditorContainer row={this.state.row} closeHandler={() => this.hideSpecialProcess()} />
        )}
        {this.state.showProtectionEditor && (
          <ProtectionEditorContainer row={this.state.row} closeHandler={() => this.hideProtection()} />
        )}
        {/* 変更履歴モーダル */}
        {this.state.historyData && (
          <RevisionHistoryModal historyData={this.state.historyData} onClose={() => this.handleHideRevisionHistory()} />
        )}
        {!!this.state.selectedScheduleForClipboardDialog && (
          <ClipboardDataDialog
            schedule={this.state.selectedScheduleForClipboardDialog}
            onClose={this.closeClipboardDialog}
          />
        )}
      </div>
    );
  }
}

const GridCellWrap = ({ className, children }: { className?: string; children: React.ReactNode }) => {
  return (
    <div className="grid-cell-wrap">
      <div className="grid-cell-con">
        <div className={clsx("grid-cell-inner", className)}>{children}</div>
      </div>
    </div>
  );
};

const EditIcon = () => {
  return (
    <button
      className="grid-icon grid-icon-edit icon-mode_edit icon-edit"
      style={{ width: "14px", height: "14px", bottom: "6px", left: "calc(50% - 5px)" }}
    />
  );
};

export default withTranslation()(ScheduleChiba);
