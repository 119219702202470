import { connect } from "react-redux";

import actions from "../../actions";
import Facility_management from "../../components/master/Facility_management";

const mapStateToProps = (state) => {
  return {
    masterItems: state.facility_management.masterItems,
    text: state.facility_management.text,
    items: state.facility_management.items,
    current: state.facility_management.current,
    fetching: state.facility_management.fetching,
    isError: state.facility_management.isError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSearchText: (text) => dispatch(actions.facility_management.changeSearchText(text)),
    fetchItems: (callback) => dispatch(actions.facility_management.fetchItems(callback)),
    searchItems: (text, callback) => dispatch(actions.facility_management.searchItems(text, callback)),
    fetchItem: (id, callback, failedCallback) =>
      dispatch(actions.facility_management.fetchItem(id, callback, failedCallback)),
    createItem: (data, handleSuccess, handleCancel) =>
      dispatch(actions.facility_management.createItem(data, handleSuccess, handleCancel)),
    updateItem: (data, handleSuccess, handleCancel, failedCallback) =>
      dispatch(actions.facility_management.updateItem(data, handleSuccess, handleCancel, failedCallback)),
    deleteItem: (id, name) => dispatch(actions.facility_management.deleteItem(id, name)),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Facility_management);
