import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import validator from "validator";

import Modal from "../Modal";
import Select from "../common/Select";
import { SubmitButton } from "@/components/common/SubmitButton";

class WorkEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      updating: false,
      title: "",
      category_id: 0,
      process_major_class_id: 0,
      process_middle_class_id: 0,
      company_id: 0,
      defaultCompanyId: 0,
      process_name: "",
      field_p1: "",
      field_p2: "",
      field_p3: "",
      output_flg: false,
      timestamp: null,
      schedule_roles: [],
      error: {},
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleChangeOutput = this.handleChangeOutput.bind(this);
    this.handleChangeProcessMajorClass = this.handleChangeProcessMajorClass.bind(this);
    this.handleChangeProcessMiddleClass = this.handleChangeProcessMiddleClass.bind(this);
    this.handleChangeCompany = this.handleChangeCompany.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeFieldP1 = this.handleChangeFieldP1.bind(this);
    this.handleChangeFieldP2 = this.handleChangeFieldP2.bind(this);
    this.handleChangeFieldP3 = this.handleChangeFieldP3.bind(this);
    this.handleBlurFieldP2 = this.handleBlurFieldP2.bind(this);
    this.handleBlurFieldP3 = this.handleBlurFieldP3.bind(this);
    this.validateId = this.validateId.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.fetchProcess(this.props.processId, (data) => {
      const {
        category_id,
        process_major_class_id,
        process_middle_class_id,
        company_id,
        process_name,
        field_p1,
        field_p2,
        field_p3,
        output_flg,
        timestamp,
        schedule_roles,
      } = data;

      this.setState({
        loading: false,
        title: process_name,
        category_id,
        process_major_class_id,
        process_middle_class_id,
        company_id,
        process_name,
        field_p1,
        field_p2,
        field_p3,
        output_flg,
        timestamp,
        defaultCompanyId: company_id,
        schedule_roles: schedule_roles || [],
      });
    });
    this.props.setUnloadAlert();

    const elem = document.getElementsByClassName("modal-dialog");
    elem[0].classList.add("work-panel");
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();
  }

  handleChangeOutput(output_flg) {
    this.setState({ output_flg });
  }

  handleChangeProcessMajorClass(e) {
    this.setState({ process_major_class_id: e });
  }

  handleChangeProcessMiddleClass(e) {
    this.setState({ process_middle_class_id: e });
  }

  handleChangeCompany(e) {
    const error = { ...this.state.error };

    if (this.props.masters.user_role === 2 && e !== this.state.defaultCompanyId) {
      error.companyChanged = `${this.props.t("company_changed_alert")}`;
    } else {
      error.companyChanged = null;
    }
    this.setState({ company_id: e, error });
  }

  handleChangeName(e) {
    this.setState({ process_name: e.target.value });
  }

  handleChangeFieldP1(e) {
    this.setState({ field_p1: e.target.value });
  }

  handleChangeFieldP2(e) {
    this.setState({ field_p2: e.target.value });
  }

  handleChangeFieldP3(e) {
    this.setState({ field_p3: e.target.value });
  }

  handleBlurFieldP2(e) {
    const lines = e.target.value.split("\n");
    const processedLines = lines.map((line) => {
      if (line.length > 5) {
        return line.match(/.{1,5}/g).join("\n");
      }
      return line;
    });
    this.setState({ field_p2: processedLines.join("\n") });
  }

  handleBlurFieldP3(e) {
    const lines = e.target.value.split("\n");
    const processedLines = lines.map((line) => {
      if (line.length > 10) {
        return line.match(/.{1,10}/g).join("\n");
      }
      return line;
    });
    this.setState({ field_p3: processedLines.join("\n") });
  }

  validateId(options, selectedIds) {
    if (!(options && options.length && selectedIds && selectedIds.length)) {
      return [];
    }

    const namePattern = "_name";
    const idPattern = "_id";

    const sampleOption = options[0];
    let idKey;
    const keys = Object.keys(sampleOption);
    keys.map((key) => {
      if (key.endsWith(namePattern)) {
        idKey = key.replace(namePattern, idPattern);
      }
    });

    const idOptions = options.map((option) => option[idKey]);

    return selectedIds.filter((selectedId) => {
      return idOptions.indexOf(selectedId) >= 0;
    }).length;
  }

  handleSave() {
    if (this.state.updating) return;
    const error = {};
    const { t } = this.props;
    const { field_p1, field_p2, field_p3 } = this.state;

    if (this.state.company_id === 0) {
      error.company_id = `${t("company")}${t("is_not_selected")}`;
    }

    const process_name = this.state.process_name ? this.state.process_name.trim() : "";

    if (validator.isEmpty(process_name)) {
      error.process_name = `${t("process_title")}${t("is_not_selected")}`;
    } else if (process_name.length > 100) {
      error.process_name = `${t("process_title")}${t("is_too_long")}`;
    }

    if (field_p1 && field_p1.length > 100) {
      error.field_p1 = `【${t("schedule_list")}】${t("company")}${t("is_too_long")}`;
    }

    if (field_p2 && field_p2.length > 100) {
      error.field_p2 = `${t("source_of_danger")}${t("is_too_long")}`;
    }

    if (field_p3 && field_p3.length > 100) {
      error.field_p3 = `${t("mitigation_measure")}${t("is_too_long")}`;
    }

    if (Object.keys(error).length > 0) {
      this.setState({
        error: {
          ...error,
          companyChanged: this.state.error.companyChanged,
        },
      });
    } else {
      const process_major_class_id =
        this.state.process_major_class_id === -1 ? null : this.state.process_major_class_id;
      const process_middle_class_id =
        this.state.process_middle_class_id === -1 ? null : this.state.process_middle_class_id;

      const { company_id, output_flg, timestamp, field_p1, field_p2, field_p3 } = this.state;

      const data = {
        process_major_class_id: process_major_class_id || null,
        process_middle_class_id: process_middle_class_id || null,
        company_id,
        process_name,
        field_p1,
        field_p2,
        field_p3,
        output_flg,
        timestamp,
      };

      const callback = () => {
        this.setState({ updating: false });
        this.props.closeHandler();
      };
      const failedCallback = () => this.setState({ updating: false });

      this.setState({ updating: true });
      this.props.updateProcess(this.props.processId, data, callback, failedCallback);
    }
  }

  render() {
    const {
      loading,
      title,
      category_id,
      process_major_class_id,
      process_middle_class_id,
      company_id,
      process_name,
      field_p1,
      field_p2,
      field_p3,
      output_flg,
      schedule_roles,
      error,
    } = this.state;

    const { t } = this.props;

    return (
      <Modal title={`${t("edit_process")} : [${title}]`} closeHandler={this.props.closeHandler} loading={loading}>
        <div className="modal-body">
          <div className="form-row">
            <div className="form-group w-460">
              <span className="form-label txt-bold">{t("print_schedule_tomorrow")}</span>
              <div className="toggle-buttons w-260">
                <label data-test-id="radio-work-editor-output-flg-on">
                  <input type="radio" checked={output_flg} onChange={() => this.handleChangeOutput(true)} />
                  <span className="btn">ON</span>
                </label>
                <label data-test-id="radio-work-editor-output-flg-off">
                  <input type="radio" checked={!output_flg} onChange={() => this.handleChangeOutput(false)} />
                  <span className="btn">OFF</span>
                </label>
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group w-460">
              <span className="form-label txt-bold">{t("process_major_class")}</span>
              <div className="w-260 d-ib ta-l">
                <Select
                  prefix="process_major_class"
                  options={this.props.masters.process_major_classes}
                  value={process_major_class_id}
                  onChange={this.handleChangeProcessMajorClass}
                  isClearable={true}
                />
              </div>
            </div>
            {error.process_major_class_id && (
              <div className="form-error w-460">
                <p className="form-error-message w-260">{error.process_major_class_id}</p>
              </div>
            )}
          </div>
          <div className="form-row">
            <div className="form-group w-460">
              <span className="form-label txt-bold">{t("process_middle_class")}</span>
              <div className="w-260 d-ib ta-l">
                <Select
                  prefix="process_middle_class"
                  options={this.props.masters.process_middle_classes}
                  value={process_middle_class_id}
                  onChange={this.handleChangeProcessMiddleClass}
                  isClearable={true}
                />
              </div>
            </div>
            {error.process_middle_class_id && (
              <div className="form-error w-460">
                <p className="form-error-message w-260">{error.process_middle_class_id}</p>
              </div>
            )}
          </div>
          <div className="form-row">
            <div className="form-group w-460">
              <span className="form-label txt-bold">{t("company")}</span>
              <div className="w-260 d-ib ta-l">
                <Select
                  prefix="company"
                  options={this.props.masters.companies}
                  value={company_id}
                  onChange={this.handleChangeCompany}
                />
              </div>
            </div>
            {error.company_id && (
              <div className="form-error w-460">
                <p className="form-error-message w-260">{error.company_id}</p>
              </div>
            )}
            {error.companyChanged && (
              <div className="form-error w-460">
                <p className="form-error-message w-260">{error.companyChanged}</p>
              </div>
            )}
          </div>
          <div className="form-row">
            <div className="form-group w-460">
              <span className="form-label txt-bold">{t("process_title")}</span>
              <input
                data-test-id="text-work-editor-process-name"
                type="text"
                className="form-control w-260"
                value={process_name}
                onChange={this.handleChangeName}
              />
            </div>
            {error.process_name && (
              <div className="form-error w-460">
                <p className="form-error-message w-260">{error.process_name}</p>
              </div>
            )}
          </div>
          <div className="form-row">
            <div className="form-group w-460">
              <span className="form-label txt-bold">{`【${t("schedule_list")}】${t("company")}`}</span>
              <input
                type="text"
                className="form-control w-260"
                value={field_p1 || ""}
                onChange={this.handleChangeFieldP1}
                disabled={!schedule_roles[0]}
              />
            </div>
            {error.field_p1 && (
              <div className="form-error w-460">
                <p className="form-error-message w-260">{error.field_p1}</p>
              </div>
            )}
          </div>
          <div className="form-row">
            <div className="form-group w-460">
              <span className="form-label txt-bold">{t("source_of_danger")}</span>
              <textarea
                rows={5}
                className="form-control w-260"
                value={field_p2 || ""}
                onChange={this.handleChangeFieldP2}
                onBlur={this.handleBlurFieldP2}
                disabled={!schedule_roles[1]}
              />
            </div>
            {error.field_p2 && (
              <div className="form-error w-460">
                <p className="form-error-message w-260">{error.field_p2}</p>
              </div>
            )}
          </div>
          <div className="form-row">
            <div className="form-group w-460">
              <span className="form-label txt-bold">{t("mitigation_measure")}</span>
              <textarea
                rows={5}
                className="form-control w-260"
                value={field_p3 || ""}
                onChange={this.handleChangeFieldP3}
                onBlur={this.handleBlurFieldP3}
                disabled={!schedule_roles[2]}
              />
            </div>
            {error.field_p3 && (
              <div className="form-error w-460">
                <p className="form-error-message w-260">{error.field_p3}</p>
              </div>
            )}
          </div>
        </div>
        <div className={"modal-footer"}>
          <button
            data-test-id="button-work-editor-cancel"
            type="button"
            className="btn btn-gray"
            onClick={this.props.closeHandler}
          >
            {t("cancel")}
          </button>
          <SubmitButton
            data-test-id="button-work-editor-save"
            onClick={this.handleSave}
            loading={this.state.updating}
          />
        </div>
      </Modal>
    );
  }
}

WorkEditor.propTypes = {
  processId: PropTypes.number.isRequired,
  masters: PropTypes.object.isRequired,
  fetchProcess: PropTypes.func.isRequired,
  updateProcess: PropTypes.func.isRequired,
  closeHandler: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
};

export default withTranslation()(WorkEditor);
