import { connect } from "react-redux";

import actions from "../../actions";
import Primary_charge from "../../components/master/Primary_charge";

const mapStateToProps = (state) => {
  return {
    masterItems: state.primary_charge.masterItems,
    text: state.primary_charge.text,
    items: state.primary_charge.items,
    current: state.primary_charge.current,
    fetching: state.primary_charge.fetching,
    isError: state.primary_charge.isError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSearchText: (text) => dispatch(actions.primary_charge.changeSearchText(text)),
    fetchItems: (callback) => dispatch(actions.primary_charge.fetchItems(callback)),
    searchItems: (text, callback) => dispatch(actions.primary_charge.searchItems(text, callback)),
    fetchItem: (id, callback, failedCallback) =>
      dispatch(actions.primary_charge.fetchItem(id, callback, failedCallback)),
    createItem: (data, handleSuccess, handleCancel) =>
      dispatch(actions.primary_charge.createItem(data, handleSuccess, handleCancel)),
    updateItem: (data, handleSuccess, handleCancel, failedCallback) =>
      dispatch(actions.primary_charge.updateItem(data, handleSuccess, handleCancel, failedCallback)),
    deleteItem: (id, name) => dispatch(actions.primary_charge.deleteItem(id, name)),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Primary_charge);
