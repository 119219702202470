import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  onClick: () => void;
};

const DetailButton: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <button
      className="progress-notification-detail-btn"
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      {t("detailBtn")}
    </button>
  );
};

export default DetailButton;
