import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { EXPANDED_ROW_HEIGHT, ROW_HEIGHT } from "@/components/list/Holiday/constants";
import { RootState } from "@/reducers/types";

type Props = {
  onScroll: (scrollTop: number) => void;
};

export const SummaryTable = forwardRef<HTMLDivElement, Props>(({ onScroll }, ref) => {
  const { t } = useTranslation();

  const { unworkDayGroups, expandedRowIndex } = useSelector((state: RootState) => state.holiday);

  const handleScroll = (e) => {
    e.currentTarget && onScroll(e.currentTarget.scrollTop);
  };

  return (
    <div className="holiday-table mr-[3px]">
      <div className="row header-row summary">
        <div>{t("machines_category")}</div>
        <div>{t("primary_charge")}</div>
        <div>{t("area")}</div>
        <div>{t("device")}</div>
        <div>{t("facility_management")}</div>
        <div>{t("construction_management")}</div>
        <div>{t("other")}</div>
      </div>
      <div className="table-body summary" ref={ref} onScroll={handleScroll}>
        {unworkDayGroups.map((group, index) => (
          <div
            key={index}
            className="row body-row summary"
            style={{ height: `${expandedRowIndex === index ? EXPANDED_ROW_HEIGHT : ROW_HEIGHT}px` }}
          >
            <div>
              <div className="data">{group.category_name}</div>
            </div>
            <div>
              <div className="data">{group.primary_charge_name}</div>
            </div>
            <div>
              <div className="data">{group.area_name}</div>
            </div>
            <div>
              <div className="data">{group.device_name}</div>
            </div>
            <div>
              <div className="data">{group.facility_management_name}</div>
            </div>
            <div>
              <div className="data">{group.construction_management_name}</div>
            </div>
            <div>
              <div className="data">{group.other_name}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

SummaryTable.displayName = "SummaryTable";
