import { connect } from "react-redux";

import actions from "../../actions";
import RightGridRow from "../../components/matrix/RightGridRow";

const mapStateToProps = (state) => {
  return {
    switcherStatus: state.matrix.switcherStatus,
    linkageTasks: state.construction.linkageTasks,
    categoryId: state.matrixSearch.categoryId,
    isChiba: state.construction.extensions?.extensions.some((item) => item.extension_type === 6),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeTaskStatus: (item_id, task, status) => {
      dispatch(actions.common.changeTaskStatus(item_id, task, status));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RightGridRow);
