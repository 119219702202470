import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import actions from "@/actions";
import { ScheduleScheduleItem } from "@/components/list/Schedule/types";

export const useNoteEditor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [item, setItem] = useState<ScheduleScheduleItem>();
  const [target, setTarget] = useState("");

  const enter = (props: { item: ScheduleScheduleItem; target: string }) => {
    setItem(props.item);
    setTarget(props.target);
  };

  const exit = () => {
    setItem(null);
    setTarget(null);
  };

  const submit = (value?: string, e?: HTMLTextAreaElement) => {
    // Validation
    if (["note1", "note2"].includes(target) && 500 < (value || "").length) {
      const field =
        target === "note1"
          ? `${t("construction")}${t("supervising_section")}${t("comments")}`
          : `${t("facility")}${t("supervising_section")}${t("comments")}`;
      dispatch(
        actions.app.showAlert(t("error"), [t("is_too_long_max", { field, length: 500 })], () =>
          dispatch(actions.app.hideAlert())
        )
      );
      e?.focus();

      return;
    }

    const request = {
      input: {
        schedule_task_id: item.schedule_task_id,
        target,
        value,
        update_date: item[`${target}_update_date`] ?? item.timestamp.update_date,
      },
    };
    dispatch(actions.schedule.updateSchedule(request));
    exit();
  };

  return { id: item?.schedule_task_id, target, enter, exit, submit };
};
