import { connect } from "react-redux";

import actions from "../../actions";
import ScheduleUpdateChiba from "../../components/list/ScheduleUpdateChiba";

const mapStateToProps = (state) => {
  let config = [];

  if (state.construction.extensions) {
    const extension = state.construction.extensions.extensions.filter((item) => item.extension_type === 6);
    if (extension.length > 0) config = JSON.parse(extension[0].config);
  }

  return { config };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSchedule: (data, handleSuccess, handleCancel) =>
      dispatch(actions.schedule_chiba.createSchedule(data, handleSuccess, handleCancel)),
    showAlert: (title, messages, okClickHandler) => dispatch(actions.app.showAlert(title, messages, okClickHandler)),
    hideAlert: () => dispatch(actions.app.hideAlert()),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleUpdateChiba);
