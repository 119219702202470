import { connect } from "react-redux";

import actions from "../../actions";
import SchedulePanel from "../../components/summary/SchedulePanel";

const mapStateToProps = (state) => {
  const { scheduleSelectedCells, loadingPanel } = state.summary;

  return {
    scheduleSelectedCells,
    loadingPanel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createScheduleNegishi: (params, callback) => dispatch(actions.summary.createScheduleNegishi(params, callback)),
    createScheduleTokuyama: (params, callback) => dispatch(actions.summary.createScheduleTokuyama(params, callback)),
    createScheduleIdemitsuChiba: (params, callback) =>
      dispatch(actions.summary.createScheduleIdemitsuChiba(params, callback)),
    endSelectSchedule: (callback) => dispatch(actions.summary.endSelectSchedule(callback)),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulePanel);
