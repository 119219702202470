import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { addHeightResizeListener, removeHeightResizeListener, getTableBodyHeight } from "../../lib/common";
import restoreState from "../../lib/restoreState";
import storageManager from "../../lib/storageManager";

import QrGridHeader from "./QrGridHeader";
import QrGridRow from "./QrGridRow";
import QrSearchArea from "./QrSearchArea";

let autoload = false; // IEの二重リクエスト対策フラグ

class Qrcode extends Component {
  constructor(props) {
    super(props);
    const urlState = restoreState();
    const notFirst = storageManager.getConstructionItem("qrSearchParams") !== null || (urlState && urlState.hasQuery);
    this.state = {
      showSearch: true,
      showTable: false,
      limit: 9999,
      start: 1,
      isEmpty: false,
      tableBodyMaxHeight: window.innerHeight - 420,
      notFirst,
      heightListener: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.infiniteSearch = this.infiniteSearch.bind(this);
    this.handleSearchBoxHeightChange = this.handleSearchBoxHeightChange.bind(this);
    this.isScrollable = this.isScrollable.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.fillList = this.fillList.bind(this);
  }

  componentDidMount() {
    if (this.searchBox) {
      this.setState({ heightListener: true }, () => {
        this.handleSearchBoxHeightChange();
        addHeightResizeListener(this.searchBox, this.handleSearchBoxHeightChange);
      });
    }

    this.resizeTimer = 0;
    window.addEventListener("resize", this.handleResize);

    if (this.state.notFirst && this.props.validRequiredParameter) {
      this.handleSearch();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.state.heightListener && this.searchBox) {
      this.setState({ heightListener: true }, () => {
        this.handleSearchBoxHeightChange();
        addHeightResizeListener(this.searchBox, this.handleSearchBoxHeightChange);
      });
    }

    const { validRequiredParameter } = this.props;
    if (typeof validRequiredParameter === "undefined") {
      return;
    }
    if (typeof prevProps.validRequiredParameter === "undefined" && validRequiredParameter && this.state.notFirst) {
      this.handleSearch();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    removeHeightResizeListener(this.searchBox);

    this.props.revertLocalCondition();
  }

  handleSearchBoxHeightChange() {
    const maxHeight = getTableBodyHeight("qrcode", this.searchBox, this.theader);
    this.setState({ tableBodyMaxHeight: maxHeight });
  }

  handleResize() {
    if (this.resizeTimer > 0) {
      clearTimeout(this.resizeTimer);
    }

    const callback = () => {
      this.handleSearchBoxHeightChange();
      this.fillList();
    };

    this.resizeTimer = setTimeout(callback, 200);
  }

  toggleSearch() {
    const callback = () => {
      this.handleSearchBoxHeightChange();
      addHeightResizeListener(this.searchBox, this.handleSearchBoxHeightChange);
      this.fillList();
    };
    this.setState({ showSearch: !this.state.showSearch }, () => setTimeout(callback, 100));
  }

  showTable(callback) {
    this.setState({ showTable: true }, () => {
      if (callback !== undefined) {
        setTimeout(callback, 100);
      }
    });
  }

  isScrollable() {
    const container = this.tbody;

    if (!container) {
      return false;
    }

    const diff = container.clientHeight - container.scrollHeight;

    return diff !== 0;
  }

  fillList() {
    const { showTable, end } = this.state;

    if (!showTable || end) {
      return;
    }

    if (!this.isScrollable()) {
      this.infiniteSearch();
    }
  }

  handleScroll() {
    const container = this.tbody;
    const containerHeight = container.clientHeight;
    const contentHeight = container.scrollHeight;
    const scrollTop = container.scrollTop;
    const diff = contentHeight - (containerHeight + scrollTop);

    if (diff <= 10 && !this.state.end) {
      this.infiniteSearch();
    }
  }

  handleSearch() {
    this.setState({ isEmpty: true });

    const {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      companyId,
      userId,
      groupId,
      search,
    } = this.props;

    const params = {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      companyId,
      userId,
      groupId,
      start: 1,
      limit: this.state.limit,
    };

    search(params, (data) => {
      this.setState(
        {
          start: data.length + 1,
          end: data.length < this.state.limit,
          isEmpty: data.length === 0,
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  infiniteSearch() {
    if (autoload) {
      return;
    }

    autoload = true;

    const {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      companyId,
      userId,
      groupId,
      search,
    } = this.props;

    const params = {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      companyId,
      userId,
      groupId,
      start: this.state.start,
      limit: this.state.limit,
    };

    search(params, (data) => {
      autoload = false;

      this.setState(
        {
          start: this.state.start + data.length,
          end: data.length < this.state.limit,
        },
        this.fillList
      );
    });
  }

  handleClear() {
    this.props.clearSearch();
    this.setState({ start: 1, showTable: false });

    const { masters } = this.props;
    let categoryId = 0;

    if (masters.categories.length > 0) {
      categoryId = masters.categories[0].category_id;
    }

    const params = {
      start: 1,
      limit: this.state.limit,
    };

    if (categoryId > 0) {
      params.categoryId = categoryId;
    }

    this.props.search(params, (data) => {
      this.setState(
        {
          start: data.length + 1,
          end: data.length < this.state.limit,
          isEmpty: data.length === 0,
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  render() {
    const { items, downloadZip, t, fetching, isError } = this.props;

    const titleClassName = `toggle icon-keyboard_arrow_up ${!this.state.showSearch ? "closed" : ""}`;

    return (
      <div>
        <div className="contents">
          <div className="inner">
            <h1 className="page-ttl">
              {t("qr_export")}
              <span
                data-test-id="button-qr-toggle-search"
                className={titleClassName}
                onClick={() => this.toggleSearch()}
              />
            </h1>
            {this.state.showSearch && (
              <QrSearchArea
                {...this.props}
                reference={(node) => (this.searchBox = node)}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            )}
            <div className={`tbl-approvals ${fetching ? "loading loading--list" : ""}`}>
              {this.state.isEmpty
                ? this.state.showTable && !isError && !fetching && <p className="empty-message">{t("no_data")}</p>
                : this.state.showTable &&
                  !isError && (
                    <React.Fragment>
                      <div
                        className="tbl-area tbl-approvals-header tbl-head-adjusted"
                        ref={(node) => (this.theader = node)}
                      >
                        <QrGridHeader />
                      </div>
                      <div
                        className={`tbl-area tbl-approvals-body ${!this.state.showSearch ? "is-large" : ""}`}
                        ref={(node) => (this.tbody = node)}
                        onScroll={this.handleScroll}
                        style={{ maxHeight: this.state.tableBodyMaxHeight }}
                      >
                        <table className="tbl-basic tbl-data qr-table-row">
                          <tbody className="tbl-area">
                            {items.map((item, index) => (
                              <QrGridRow key={index} item={item} downloadZip={downloadZip} />
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </React.Fragment>
                  )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Qrcode.propTypes = {
  areaIds: PropTypes.array.isRequired,
  deviceIds: PropTypes.array.isRequired,
  categoryId: PropTypes.number.isRequired,
  facilityManagementIds: PropTypes.array.isRequired,
  constructionManagementIds: PropTypes.array.isRequired,
  primaryChargeIds: PropTypes.array.isRequired,
  otherIds: PropTypes.array.isRequired,
  companyId: PropTypes.number.isRequired,
  groupId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  masters: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  changeArea: PropTypes.func.isRequired,
  changeDevice: PropTypes.func.isRequired,
  changeFacilityManagement: PropTypes.func.isRequired,
  changeCategory: PropTypes.func.isRequired,
  changeConstructionManagement: PropTypes.func.isRequired,
  changePrimaryCharge: PropTypes.func.isRequired,
  changeOther: PropTypes.func.isRequired,
  changeUser: PropTypes.func.isRequired,
  changeGroup: PropTypes.func.isRequired,
  changeCompany: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  downloadZip: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Qrcode);
