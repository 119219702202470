import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Modal from "../Modal";
import { SubmitButton } from "@/components/common/SubmitButton";

class ScheduleEditorEneosKawasaki extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule_id: 0,
      target: "",
      value: 0,
      update_date: "",
      timestamp: "",
      loading: false,
      process_text: "",
      panelText: "",
      error: {},
    };

    this.handleChangeTarget = this.handleChangeTarget.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    const { target, panelText, item, setUnloadAlert } = this.props;
    const { schedule_id, process_text, timestamp } = item;

    setUnloadAlert();
    this.setState({
      schedule_id: schedule_id,
      target: target,
      panelText: panelText,
      value: item[target] || 0,
      process_text: process_text,
      update_date: item[target + "_update_date"],
      timestamp: timestamp,
    });
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();
  }

  handleChangeTarget(e) {
    this.setState({
      value: parseInt(e.target.value),
    });
  }

  handleSave() {
    if (this.state.loading) return;
    const error = {};
    const data = { ...this.state };

    if (this.state.value == -1) {
      error.value = this.props.panelText;
      error.msg = "is_not_selected";
    }

    data.error = error;

    if (Object.keys(error).length > 0) {
      this.setState(data);
    } else {
      data.loading = true;
      this.setState(data);
      this.props.updateItem(
        data,
        () => {
          this.setState({ loading: false });
          this.props.closeHandler();
        },
        () => {
          this.setState({ loading: false });
        }
      );
    }
  }

  render() {
    const { selectValues, closeHandler, t } = this.props;
    const { process_text, panelText, value, loading, error } = this.state;

    return (
      <Modal title={t(panelText) + " " + t("update")} closeHandler={() => closeHandler()}>
        <div className="modal-body schedule-editor">
          <div className="form-row mb-5">
            <div className="form-group w-360 ta-u">
              {process_text ? <p className="form-text">{"[" + process_text + "]"}</p> : <p />}
              <div className="form-center">
                <select
                  data-test-id="text-import-file-type"
                  className="form-control w-170"
                  value={value}
                  onChange={this.handleChangeTarget}
                >
                  {selectValues.map((value) => (
                    <option key={value.id} value={value.id}>
                      {value.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {error.value && (
              <div className="form-error w-360">
                <p className="form-error-message w-260">
                  {t(error.value)}
                  {t(error.msg)}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className={"modal-footer"}>
          <button
            data-test-id="button-column-editor-cancel"
            type="button"
            className="btn btn-gray"
            onClick={() => closeHandler()}
          >
            {t("cancel")}
          </button>
          <SubmitButton data-test-id="button-column-editor-save" onClick={this.handleSave} loading={loading} />
        </div>
      </Modal>
    );
  }
}

ScheduleEditorEneosKawasaki.propTypes = {
  target: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
};

export default withTranslation()(ScheduleEditorEneosKawasaki);
