import _ from "lodash";

const isValidateArray = (roles, target, action) => {
  if (!roles) {
    return false;
  }
  const targets = roles.filter((item) => item.target === target);

  if (targets.length === 0) {
    return false;
  }

  const actions = targets[0].roles.filter((item) => item.action === action);

  if (actions.length === 0) {
    return false;
  }

  return actions[0]["role"] === 1;
};

const isValidateObject = (roles, target, action) => {
  const isRolesEmpty = !roles && !_.isEmpty(roles) && !_.isEmpty(roles.progress) && !_.isEmpty(roles.layout);
  if (isRolesEmpty) {
    return false;
  }

  const progressRoles = roles.progress;
  if (!progressRoles) {
    return false;
  }

  const roleActions = progressRoles[target];
  if (!roleActions) {
    return false;
  }

  const isActionAvailable = !!roleActions[action];

  return isActionAvailable;
};

export const isValid = (roles, target, action) => {
  if (Array.isArray(roles)) {
    return isValidateArray(roles, target, action);
  }
  if (typeof roles === "object") {
    return isValidateObject(roles, target, action);
  }

  return false;
};

export const isValidRole = (roles, index) => {
  if (!roles) {
    return false;
  }

  return roles[index] === 1;
};

// targetsの内いずれかが許可されていればtrueを返す
export const isValidTargets = (roles, targets, action) => {
  return targets.some((target) => isValid(roles, target, action));
};

// actionsの内いずれかが許可されていればtrueを返す
export const isValidActions = (roles, target, actions) => {
  return actions.some((action) => isValid(roles, target, action));
};

export const isValidStatusSwitch = (switcherStatus, roles) => {
  if (switcherStatus === -1) {
    return false;
  }

  switch (switcherStatus) {
    case 0:
      return isValid(roles, "status_valid_off", "update");
    case 1:
      return isValid(roles, "status_valid_on", "update");
    case 2:
      return isValid(roles, "status_scheduled", "update");
    case 3:
      return isValid(roles, "status_continue", "update");
    case 4:
      return isValid(roles, "status_completing", "update");
    case 5:
      return isValid(roles, "status_interrupt", "update");
    case 8:
      return isValid(roles, "status_completed", "update");
  }

  return false;
};
