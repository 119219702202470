import * as constants from "../constants";

const initialState = {
  name: "",
  password: "",
  error: {},
  authResult: null,
  failedCount: 0,
  visiblePasswordWarning: false,
  visiblePasswordEditor: false,
  visibleTwoFactorAuth: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGIN_RESET_STATE: {
      return {
        ...initialState,
      };
    }
    case constants.LOGIN_CHANGE_NAME: {
      return {
        ...state,
        name: action.payload,
      };
    }
    case constants.LOGIN_CHANGE_PASSWORD: {
      return {
        ...state,
        password: action.payload,
      };
    }
    case constants.LOGIN_VALIDATION_FAILED: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case constants.LOGIN_BEGIN_AUTH:
    case constants.LOGIN_BEGIN_NEXT_AUTH: {
      const { name, password } = state;

      return {
        ...initialState,
        name,
        password,
      };
    }
    case constants.LOGIN_END_AUTH:
    case constants.LOGIN_END_NEXT_AUTH: {
      return {
        ...state,
        authResult: action.payload,
      };
    }
    case constants.LOGIN_SHOW_PASSWORD_WARNING: {
      return {
        ...state,
        visiblePasswordWarning: true,
      };
    }
    case constants.LOGIN_HIDE_PASSWORD_WARNING: {
      return {
        ...state,
        visiblePasswordWarning: false,
      };
    }
    case constants.LOGIN_SHOW_PASSWORD_EDITOR: {
      return {
        ...state,
        visiblePasswordWarning: false,
        visiblePasswordEditor: true,
      };
    }
    case constants.LOGIN_HIDE_PASSWORD_EDITOR: {
      return {
        ...state,
        visiblePasswordEditor: false,
      };
    }
    case constants.LOGIN_SHOW_TWO_FACTOR_AUTH: {
      return {
        ...state,
        failedCount: 0,
        visibleTwoFactorAuth: true,
      };
    }
    case constants.LOGIN_HIDE_TWO_FACTOR_AUTH: {
      return {
        ...state,
        failedCount: 0,
        visibleTwoFactorAuth: false,
      };
    }
    case constants.LOGIN_FAILED_TWO_FACTOR_AUTH: {
      const { failedCount } = state;

      return {
        ...state,
        failedCount: failedCount + 1,
      };
    }
    default:
      return state;
  }
};

export default login;
