import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import * as constants from "../constants/index";

const Toast = ({ contents, t }) => {
  return (
    <TransitionGroup>
      {contents.map((content, index) => {
        let className = "toast-content";

        if (content.type === constants.TOAST_CONTENT_TYPES.ERROR) {
          className = `${className} toast-error`;
        }

        return (
          <CSSTransition key={index} timeout={{ enter: 300, exit: 300 }}>
            <ul className="toast">
              <li className={className}>{t(content.message)}</li>
            </ul>
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  );
};

Toast.propTypes = {
  contents: PropTypes.array.isRequired,
};

export default withTranslation()(Toast);
