import PropTypes from "prop-types";
import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";

import { LANG_OPTIONS } from "../constants/index";
import styles from "../constants/reactSelectStyle";

import PasswordEditor from "./PasswordEditor";
import PasswordWarning from "./PasswordWarning";
import TwoFactorAuth from "./TwoFactorAuth";

const Login = (props) => {
  const buttonRef = useRef(null);
  const handleChangeName = (e) => props.changeName(e.target.value);
  const handleChangePassword = (e) => props.changePassword(e.target.value);
  const handleSubmit = (e) => {
    if (buttonRef.current) {
      buttonRef.current.blur();
    }

    e.preventDefault();
    props.submit(props.name, props.password);
  };

  const handleClearStorage = (e) => {
    e.preventDefault();

    props.showConfirm(
      props.t("confirmation"),
      [props.t("clear_browser_history_sure")],
      () => {
        localStorage.clear();
        sessionStorage.clear();
        props.hideConfirm();
      },
      props.hideConfirm
    );
  };

  return (
    <div className="wrapper">
      <div id="contents">
        <div className="login-area">
          <form className="login-box" onSubmit={handleSubmit}>
            <div className="logo">
              {props.t("sankyu_system_name1")}
              <br />
              {props.t("sankyu_system_name2")}
              <br />
              {props.t("sankyu_system_name3")}
              <strong>
                <span className="sx-progress">SX-Progress</span>
                <span> / </span>
                <span className="layout">Layout</span>
              </strong>
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder={props.t("user_name")}
                className="form-control"
                value={props.name}
                data-test-id="text-login-login-id"
                onChange={handleChangeName}
              />
              {props.error.name && <p className="login-error-message">{props.t(props.error.name)}</p>}
            </div>
            <div className="form-group">
              <input
                type="password"
                placeholder={props.t("password")}
                className="form-control"
                value={props.password}
                data-test-id="text-login-password"
                onChange={handleChangePassword}
              />
              {props.error.password && <p className="login-error-message">{props.t(props.error.password)}</p>}
            </div>
            <div className="form-group" style={{ textAlign: "left" }}>
              <Select
                styles={styles}
                options={LANG_OPTIONS}
                onChange={props.changeLang}
                value={LANG_OPTIONS.find((option) => option.value === props.lang)}
                menuPortalTarget={document.querySelector("body")}
              />
            </div>
            <div className="btn-area">
              <button ref={buttonRef} type="submit" className="btn btn-blue" data-test-id="button-login">
                {props.t("login")}
              </button>
            </div>
            <div className="btn-clear-storage">
              <button onClick={handleClearStorage} type="button">
                {props.t("clear_browser_history")}
              </button>
            </div>
          </form>
          <div className="recommended-env">
            <dl className="clearfix">
              <dt className="subject">{props.t("recommended_env")}</dt>
              <dd></dd>
              <dt>Windows</dt>
              <dd>
                {props.t("chrome")}
                <br />
                {props.t("edge")}
              </dd>
            </dl>
          </div>
        </div>
        {props.visiblePasswordWarning && (
          <PasswordWarning
            expireDate={props.authResult ? props.authResult.password_expire_date : ""}
            onChangePasswordClick={props.showPasswordEditor}
            onClose={props.hidePasswordWarning}
          />
        )}
        {props.visiblePasswordEditor && (
          <PasswordEditor
            setUnloadAlert={props.setUnloadAlert}
            clearUnloadAlert={props.clearUnloadAlert}
            close={props.hidePasswordEditor}
            save={props.savePassword}
          />
        )}
        {props.visibleTwoFactorAuth && (
          <TwoFactorAuth
            setUnloadAlert={props.setUnloadAlert}
            clearUnloadAlert={props.clearUnloadAlert}
            close={props.hideTwoFactorAuth}
            verify={props.verifyTwoFactorAuth}
            securityCodeType={props.authResult.security_code_type}
            securityCodeDestination={props.authResult.security_code_destination}
          />
        )}
      </div>
    </div>
  );
};

Login.propTypes = {
  name: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  error: PropTypes.object.isRequired,
  authResult: PropTypes.object,
  visiblePasswordWarning: PropTypes.bool.isRequired,
  visiblePasswordEditor: PropTypes.bool.isRequired,
  visibleTwoFactorAuth: PropTypes.bool.isRequired,
  requestSecurityCode: PropTypes.func.isRequired,
  sendSecurityCode: PropTypes.func.isRequired,
  hidePasswordWarning: PropTypes.func.isRequired,
  showPasswordEditor: PropTypes.func.isRequired,
  hidePasswordEditor: PropTypes.func.isRequired,
  hideTwoFactorAuth: PropTypes.func.isRequired,
  changeName: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  verifyTwoFactorAuth: PropTypes.func.isRequired,
  showConfirm: PropTypes.func.isRequired,
  hideConfirm: PropTypes.func.isRequired,
  changeLang: PropTypes.func.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
  savePassword: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};

export default withTranslation()(Login);
