import { ComponentProps, FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import actions from "@/actions";
import { DeleteConfirmModal, Modal } from "@/sx-layout/common/Modal";
import { DeleteKeepOutAreasProps } from "@/sx-layout/components/plotmap/actions/types";
import { KeepOutArea } from "@/sx-layout/components/plotmap/models";

type Props = ComponentProps<typeof Modal> & {
  area: KeepOutArea;
  onSuccess?: () => void;
};

export const DeleteKeepOutAreaModal: FC<Props> = ({ area, onClose, onSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <DeleteConfirmModal
      message={t("remove_keep_out_area_sure")}
      onClose={onClose}
      onSubmit={() => {
        dispatch(
          actions.keepOutAreas.deleteKeepOutAreas({
            input: {
              keepout_area_id: area.keepout_area_id,
              machine_id: area.machine_id,
              timestamp: {
                update_date: area.timestamp?.update_date,
              },
            },

            onSuccess: () => {
              onClose();
              onSuccess?.();
            },
          } as DeleteKeepOutAreasProps)
        );
      }}
    />
  );
};
