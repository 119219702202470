import PropTypes from "prop-types";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { withTranslation } from "react-i18next";

import { isIE, addHeightResizeListener, removeHeightResizeListener } from "../../lib/common";
import storageManager from "../../lib/storageManager";
import Modal from "../Modal";

import ExportInazumaPanel from "./ExportInazumaPanel";

class InazumaPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      basePointItems: [],
      selectedBasePointId: 0,
      area_id: 0,
      area_name: "",
      sort: "",
      timestamp: "",
      error: {},
      term_start_date: null,
      term_end_date: null,
      term_disp_start_date: null,
      term_disp_end_date: null,
      base_date: moment().format("YYYY-MM-DD"),
      BaseDates: [],
      visibleExportInazumaPanel: false,
      visiblePastInazumaLine: false,
      inazumaGapDate: 1,

      scrollTop: 0,
      inputSchedule: moment().format("YYYY/MM/DD"),
      // inputResult: '',
      inputTermStartDate: "",
      inputTermEndDate: "",
      inputTermDispStartDate: "",
      inputTermDispEndDate: "",
      openCalender: null,
      onCalendar: false,
      datepickerPos: null,
      openCalender: null,
      panelHeight: 224,
    };

    this.handleChangeDateFrom = this.handleChangeDateFrom.bind(this);
    this.handleChangeDateTo = this.handleChangeDateTo.bind(this);
    this.handleChangeDateBase = this.handleChangeDateBase.bind(this);
    this.handleChangeDispDateFrom = this.handleChangeDispDateFrom.bind(this);
    this.handleChangeDispDateTo = this.handleChangeDispDateTo.bind(this);

    this.showExportInazumaPanel = this.showExportInazumaPanel.bind(this);
    this.hideExportInazumaPanel = this.hideExportInazumaPanel.bind(this);
    this.handleSelectBaseDate = this.handleSelectBaseDate.bind(this);
    this.handleDeleteBasePoint = this.handleDeleteBasePoint.bind(this);

    this.openBaseDateCalender = this.openBaseDateCalender.bind(this);
    this.openTermStartDateCalender = this.openTermStartDateCalender.bind(this);
    this.openTermEndDateCalender = this.openTermEndDateCalender.bind(this);
    this.openTermDispStartDateCalender = this.openTermDispStartDateCalender.bind(this);
    this.openTermDispEndDateCalender = this.openTermDispEndDateCalender.bind(this);
    this.showPastInazumaLine = this.showPastInazumaLine.bind(this);
    this.hidePastInazumaLine = this.hidePastInazumaLine.bind(this);
    this.handleInputBaseDate = this.handleInputBaseDate.bind(this);
    this.handleInputTermStartDate = this.handleInputTermStartDate.bind(this);
    this.handleInputTermEndDate = this.handleInputTermEndDate.bind(this);
    this.handleInputTermDispStartDate = this.handleInputTermDispStartDate.bind(this);
    this.handleInputTermDispEndDate = this.handleInputTermDispEndDate.bind(this);
    this.handleBaseDate = this.handleBaseDate.bind(this);
    this.handleTermStartDate = this.handleTermStartDate.bind(this);
    this.handleTermEndDate = this.handleTermEndDate.bind(this);
    this.handleTermDispStartDate = this.handleTermDispStartDate.bind(this);
    this.handleTermDispEndDate = this.handleTermDispEndDate.bind(this);
    this.closeCalendarBaseDate = this.closeCalendarBaseDate.bind(this);
    this.closeCalendarTermStartDate = this.closeCalendarTermStartDate.bind(this);
    this.closeCalendarTermEndDate = this.closeCalendarTermEndDate.bind(this);
    this.closeCalendarTermDispStartDate = this.closeCalendarTermDispStartDate.bind(this);
    this.closeCalendarTermDispEndDate = this.closeCalendarTermDispEndDate.bind(this);
    this.closeCalendar = this.closeCalendar.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.setDatepickerPos = this.setDatepickerPos.bind(this);
    this.getDatepickerStyle = this.getDatepickerStyle.bind(this);
    this.handleCreateInazumaLine = this.handleCreateInazumaLine.bind(this);
    this.handleChangeGapDate = this.handleChangeGapDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchTerm = this.fetchTerm.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.setDatepickerPos);
    setTimeout(this.setDatepickerPos, 500);

    this.props.fetchInazumaBasePoints((response) => {
      let selectedBasePointId = response.length > 0 ? response[0].inazuma_base_point_id : 0;

      if (storageManager.getConstructionItem("inazumaPanelParams") !== null) {
        const params = JSON.parse(storageManager.getConstructionItem("inazumaPanelParams"));
        selectedBasePointId = params.inazuma_base_point_id;
      }

      this.setState(
        {
          basePointItems: response,
          selectedBasePointId,
        },
        this.fetchTerm
      );
    });

    this.props.setUnloadAlert();

    this.handleResize();
    addHeightResizeListener(this.panelRight, this.handleResize, 100);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setDatepickerPos);
    this.props.clearUnloadAlert();

    removeHeightResizeListener(this.panelRight);
  }

  handleResize() {
    const panelHeight = this.panelRight.offsetHeight;
    this.setState({ panelHeight });
  }

  fetchTerm() {
    const { selectedBasePointId } = this.state;
    this.props.fetchInazumaTerm(selectedBasePointId, (response) => {
      let visiblePastInazumaLine = this.state.visiblePastInazumaLine;
      let term_disp_start_date = this.state.term_disp_start_date;
      let term_disp_end_date = this.state.term_disp_end_date;
      let inazumaGapDate = this.state.inazumaGapDate;

      if (storageManager.getConstructionItem("inazumaPanelParams") !== null) {
        const params = JSON.parse(storageManager.getConstructionItem("inazumaPanelParams"));

        if (params.past_inazuma_flg) {
          visiblePastInazumaLine = true;
          term_disp_end_date = moment().format("YYYY-MM-DD");
        }

        if (params.inazuma_start_date) {
          term_disp_start_date = params.inazuma_start_date;
        }

        if (params.inazuma_gap_date) {
          inazumaGapDate = params.inazuma_gap_date;
        }
      }

      const { schedule_start_date, schedule_end_date } = response;

      this.setState({
        term_start_date: this.convertDateToString(schedule_start_date),
        term_disp_start_date: term_disp_start_date,
        term_end_date: this.convertDateToString(schedule_end_date),
        term_disp_end_date: term_disp_end_date,
        inputTermStartDate: this.convertDateToString(schedule_start_date),
        inputTermDispStartDate: this.convertDateToString(term_disp_start_date),
        inputTermEndDate: this.convertDateToString(schedule_end_date),
        inputTermDispEndDate: this.convertDateToString(term_disp_end_date),
        visiblePastInazumaLine,
        inazumaGapDate,
        error: {},
      });
    });
  }

  handleChangeDateFrom(date) {
    this.setState({ term_start_date: date });
  }

  handleChangeDateTo(date) {
    this.setState({ term_end_date: date });
  }

  handleChangeDateBase(date) {
    this.setState({ base_date: date });
  }

  handleChangeDispDateFrom(date) {
    this.setState({ term_disp_start_date: date });
  }

  handleChangeDispDateTo(date) {
    this.setState({ term_disp_end_date: date });
  }

  showExportInazumaPanel() {
    this.setState({
      visibleExportInazumaPanel: true,
    });
  }
  hideExportInazumaPanel() {
    this.setState({
      visibleExportInazumaPanel: false,
    });
    this.props.closeHandler();
    this.props.backHandler();
  }

  handleSelectBaseDate(id) {
    this.setState({ selectedBasePointId: id }, this.fetchTerm);
  }

  handleDeleteBasePoint(id) {
    this.props.deleteInazumaBasePoint(id, (response) => {
      const basePointItems = this.state.basePointItems.filter((item) => item.inazuma_base_point_id !== id);
      let selectedBasePointId = this.state.selectedBasePointId;

      if (basePointItems.length === 0) {
        selectedBasePointId = 0;
      } else if (id === selectedBasePointId) {
        selectedBasePointId = basePointItems[0].inazuma_base_point_id;
      }

      this.setState({ basePointItems, selectedBasePointId });
    });
  }

  handleSubmit() {
    const { base_date, term_start_date, term_end_date, visiblePastInazumaLine, inazumaGapDate, selectedBasePointId } =
      this.state;
    let { term_disp_start_date, term_disp_end_date } = this.state;

    const error = {};

    const { t, inazumaSelectedCells } = this.props;

    if (!base_date || !term_start_date || !term_end_date) {
      if (!base_date) {
        error.base_date = t("base_unentered");
      }
      if (!term_start_date || !term_end_date) {
        error.term_date = t("term_unentered");
      }
    } else {
      const bd = moment(base_date);
      const sd = moment(term_start_date);
      const ed = moment(term_end_date);

      if (!(bd >= sd && ed >= bd)) {
        error.term_date = t("term_alert");
      }
    }

    if (visiblePastInazumaLine) {
      if (!term_disp_start_date || !term_disp_end_date) {
        error.term_disp_date = t("term_display_unentered");
      } else {
        const dsd = moment(term_disp_start_date);
        const ded = moment(term_disp_end_date);

        if (ded.diff(dsd, "days") + 1 < inazumaGapDate) {
          error.term_disp_date = t("term_display_alert");
        }
      }
    } else {
      term_disp_start_date = null;
      term_disp_end_date = null;
    }

    if (Object.keys(error).length > 0) {
      this.setState({ error });

      return;
    }

    const inazumaTargets = inazumaSelectedCells.map((target) => {
      const { category_id, area_id, device_id, facility_management_id, construction_management_id, primary_charge_id } =
        target;

      return {
        category_id,
        area_id,
        device_id,
        facility_management_id,
        construction_management_id,
        primary_charge_id,
      };
    });

    const params = {
      inazuma_base_point_id: selectedBasePointId,
      start_date: moment(term_start_date).format("YYYY-MM-DD").toString(),
      end_date: moment(term_end_date).format("YYYY-MM-DD").toString(),
      inazuma_date: base_date,
      past_inazuma_flg: visiblePastInazumaLine,
      inazuma_start_date: term_disp_start_date,
      inazuma_end_date: term_disp_end_date,
      inazuma_gap_date: inazumaGapDate,
      inazuma_targets: inazumaTargets,
    };

    this.props.requestInazumaLineCreation(params, this.showExportInazumaPanel);
  }

  handleChangeBaseDate(date) {
    this.setState({
      base_date: date
        ? date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
        : null,
    });
  }

  handleChangeTermStartDate(date) {
    this.setState({
      term_start_date: date
        ? date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
        : null,
    });
  }

  handleChangeTermEndDate(date) {
    this.setState({
      term_end_date: date
        ? date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
        : null,
    });
  }

  handleChangeTermDispStartDate(date) {
    this.setState({
      term_disp_start_date: date
        ? date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
        : null,
    });
  }

  handleChangeTermDispEndDate(date) {
    this.setState({
      term_disp_end_date: date
        ? date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
        : null,
    });
  }

  handleInputBaseDate(e) {
    const string = e.target.value;
    this.setState({ inputSchedule: string });
    const date = string ? moment(string, "YYYY/MM/DD").toDate() : "";
    if (date.toString() !== "Invalid Date") {
      this.handleChangeBaseDate(date);
    }
  }

  handleInputTermStartDate(e) {
    const string = e.target.value;
    this.setState({ inputTermStartDate: string });
    const date = string ? moment(string, "YYYY/MM/DD").toDate() : "";
    if (date.toString() !== "Invalid Date") {
      this.handleChangeTermStartDate(date);
    }
  }

  handleInputTermEndDate(e) {
    const string = e.target.value;
    this.setState({ inputTermEndDate: string });
    const date = string ? moment(string, "YYYY/MM/DD").toDate() : "";
    if (date.toString() !== "Invalid Date") {
      this.handleChangeTermEndDate(date);
    }
  }

  handleInputTermDispStartDate(e) {
    const string = e.target.value;
    this.setState({ inputTermDispStartDate: string });
    const date = string ? moment(string, "YYYY/MM/DD").toDate() : "";
    if (date.toString() !== "Invalid Date") {
      this.handleChangeTermDispStartDate(date);
    }
  }

  handleInputTermDispEndDate(e) {
    const string = e.target.value;
    this.setState({ inputTermDispEndDate: string });
    const date = string ? moment(string, "YYYY/MM/DD").toDate() : "";
    if (date.toString() !== "Invalid Date") {
      this.handleChangeTermDispEndDate(date);
    }
  }

  handleBaseDate(date) {
    this.setState({ inputSchedule: this.convertDateToString(date) });
    this.handleChangeBaseDate(date);
    this.closeCalendar();
  }

  convertDateToString(date) {
    return date ? moment(date).format("YYYY/MM/DD").toString() : "";
  }

  handleTermStartDate(date) {
    this.setState({ inputTermStartDate: this.convertDateToString(date) });
    this.handleChangeTermStartDate(date);
    this.closeCalendar();
  }

  handleTermEndDate(date) {
    this.setState({ inputTermEndDate: this.convertDateToString(date) });
    this.handleChangeTermEndDate(date);
    this.closeCalendar();
  }

  handleTermDispStartDate(date) {
    this.setState({ inputTermDispStartDate: this.convertDateToString(date) });
    this.handleChangeTermDispStartDate(date);
    this.closeCalendar();
  }

  handleTermDispEndDate(date) {
    this.setState({ inputTermDispEndDate: this.convertDateToString(date) });
    this.handleChangeTermDispEndDate(date);
    this.closeCalendar();
  }

  openBaseDateCalender() {
    const calendarName = "baseDate";
    if (this.state.openCalender !== calendarName) {
      this.setState({ openCalender: calendarName });
    }
  }

  showPastInazumaLine() {
    this.setState({ visiblePastInazumaLine: true });

    let { term_disp_end_date } = this.state;
    if (!term_disp_end_date) {
      term_disp_end_date = moment().format("YYYY-MM-DD");

      this.setState({
        term_disp_end_date: term_disp_end_date,
        inputTermDispEndDate: this.convertDateToString(term_disp_end_date),
      });
    }
  }

  hidePastInazumaLine() {
    this.setState({ visiblePastInazumaLine: false });
  }

  openTermStartDateCalender() {
    const calendarName = "termStartDate";
    if (this.state.openCalender !== calendarName) {
      this.setState({ openCalender: calendarName });
    }
  }

  openTermEndDateCalender() {
    const calendarName = "termEndDate";
    if (this.state.openCalender !== calendarName) {
      this.setState({ openCalender: calendarName });
    }
  }

  openTermDispStartDateCalender() {
    const calendarName = "termDispStartDate";
    if (this.state.openCalender !== calendarName) {
      this.setState({ openCalender: calendarName });
    }
  }

  openTermDispEndDateCalender() {
    const calendarName = "termDispEndDate";
    if (this.state.openCalender !== calendarName) {
      this.setState({ openCalender: calendarName });
    }
  }

  closeCalendar() {
    if (!this.state.onCalendar && this.state.openCalender) {
      this.setState({ openCalender: null, onCalendar: false });
    }
  }

  closeCalendarBaseDate() {
    const { selected } = this.datePickerSchedule.props;
    if (selected) {
      this.setState({ inputSchedule: moment(selected).format("YYYY/MM/DD").toString() });
    }
    if (!this.state.onCalendar && this.state.openCalender) {
      this.setState({ openCalender: null, onCalendar: false });
    }
  }

  closeCalendarTermStartDate() {
    const { selected } = this.datePickerTermStartDate.props;
    if (selected) {
      this.setState({ inputTermStartDate: moment(selected).format("YYYY/MM/DD").toString() });
    }
    if (!this.state.onCalendar && this.state.openCalender) {
      this.setState({ openCalender: null, onCalendar: false });
    }
  }

  closeCalendarTermEndDate() {
    const { selected } = this.datePickerTermEndDate.props;
    if (selected) {
      this.setState({ inputTermEndDate: moment(selected).format("YYYY/MM/DD").toString() });
    }
    if (!this.state.onCalendar && this.state.openCalender) {
      this.setState({ openCalender: null, onCalendar: false });
    }
  }

  closeCalendarTermDispStartDate() {
    const { selected } = this.datePickerTermDispStartDate.props;
    if (selected) {
      this.setState({ inputTermDispStartDate: moment(selected).format("YYYY/MM/DD").toString() });
    }
    if (!this.state.onCalendar && this.state.openCalender) {
      this.setState({ openCalender: null, onCalendar: false });
    }
  }

  closeCalendarTermDispEndDate() {
    const { selected } = this.datePickerTermDispEndDate.props;
    if (selected) {
      this.setState({ inputTermDispEndDate: moment(selected).format("YYYY/MM/DD").toString() });
    }
    if (!this.state.onCalendar && this.state.openCalender) {
      this.setState({ openCalender: null, onCalendar: false });
    }
  }

  handleScroll() {
    const container = this.body;
    const scrollTop = container.scrollTop;
    this.setState({ scrollTop });
  }

  setDatepickerPos() {
    if (!isIE()) {
      return;
    }

    this.setState({
      datepickerPos: {
        scheduleDate: this.getDatepickerPos(this.inputScheduleDate),
        termStartDate: this.getDatepickerPos(this.inputTermStartDate),
        termEndDate: this.getDatepickerPos(this.inputTermEndDate),
        termDispStartDate: this.getDatepickerPos(this.inputTermDispStartDate),
        termDispEndDate: this.getDatepickerPos(this.inputTermDispEndDate),
      },
    });
  }

  getDatepickerPos(elm) {
    const rect = elm.getBoundingClientRect();

    return {
      x: rect.left,
      y: rect.top + rect.height + 2,
    };
  }

  getDatepickerStyle(key, style) {
    if (isIE()) {
      return {
        position: "fixed",
        zIndex: 1300,
        left: this.state.datepickerPos[key].x,
        top: this.state.datepickerPos[key].y,
      };
    }

    return style;
  }

  handleChangeGapDate(e) {
    const inazumaGapDate = Number(e.target.value);
    this.setState({ inazumaGapDate });
  }

  handleCreateInazumaLine() {
    const title = moment().format("YYYY/MM/DD HH:mm");
    this.props.createInazumaBasePoint(title, (response) => {
      this.setState(
        {
          basePointItems: [response, ...this.state.basePointItems],
        },
        () => {
          this.handleSelectBaseDate(response.inazuma_base_point_id);
        }
      );
    });
  }

  render() {
    const {
      term_start_date,
      term_end_date,
      term_disp_start_date,
      term_disp_end_date,
      base_date,
      scrollTop,
      error,
      panelHeight,
    } = this.state;
    const { loadingPanel, closeHandler, selectedCategoryCount, targetItemCount, t } = this.props;

    const datepickerModalStyleStart = {
      position: "fixed",
      zIndex: 1300,
      marginTop: 2 - this.state.scrollTop,
      marginLeft: 120,
    };

    const datepickerModalStyleEnd = {
      ...datepickerModalStyleStart,
      marginLeft: 262,
    };

    const datepickerModalStyle = {
      position: "fixed",
      zIndex: 1300,
      marginTop: 30 - scrollTop,
      marginLeft: 120,
    };

    const panelLeftStyle = {
      height: panelHeight,
    };

    return (
      <Modal
        title={t("putting_inazuma_lines")}
        loading={loadingPanel}
        closeHandler={closeHandler}
        onDrag={this.setDatepickerPos}
      >
        <div className="modal-body w-710 inazuma-panel" onScroll={this.handleScroll} ref={(node) => (this.body = node)}>
          <p className="disp-area">
            {t("selected_category")}：<span className="categoryNum txt-bold ">{selectedCategoryCount}</span>
            {t("selected_items_sum")}：<span className="targetItems txt-bold ">{targetItemCount}</span>
          </p>
          <div className="panel-top">
            <span className="form-label txt-bold">{t("planning_base_date")}</span>
            <button
              data-test-id="button-inazuma-allclear"
              className="btn btn-blue base-date"
              onClick={this.handleCreateInazumaLine}
              disabled={loadingPanel}
            >
              {t("putting_planning_base_date")}
            </button>
            <span className="form-label txt-bold condition">{t("condition")}</span>
          </div>
          <div className="inazuma-inner">
            <div
              className={`inazuma-panel-left ${loadingPanel ? "disabled" : ""}`}
              ref={(node) => (this.panelLeft = node)}
              style={panelLeftStyle}
            >
              <ul className="form-target-date-list">
                {this.state.basePointItems.map((item) => (
                  <li key={item.inazuma_base_point_id}>
                    <p className="comment-info">
                      <button
                        data-test-id="button-inazuma-apply"
                        className={
                          this.state.selectedBasePointId === item.inazuma_base_point_id
                            ? "btn-apply select"
                            : "btn-apply"
                        }
                        onClick={() => this.handleSelectBaseDate(item.inazuma_base_point_id)}
                        disabled={loadingPanel}
                      >
                        {t("apply")}
                      </button>
                      <span className="baseDate">{item.title}</span>
                      <span
                        className="trush icon icon-delete"
                        onClick={() => this.handleDeleteBasePoint(item.inazuma_base_point_id)}
                      ></span>
                    </p>
                  </li>
                ))}
              </ul>
            </div>
            <div className="inazuma-panel-right clearfix" ref={(node) => (this.panelRight = node)}>
              <div className="form-row">
                <div className="form-group w-378">
                  <span className="form-label">{t("term")}</span>
                  <div
                    className="schedule-datepicker"
                    onMouseEnter={() => this.setState({ onCalendar: true })}
                    onMouseLeave={() => this.setState({ onCalendar: false })}
                  >
                    <input
                      type="text"
                      onFocus={this.openTermStartDateCalender}
                      className="form-control w-120"
                      value={this.state.inputTermStartDate}
                      onChange={this.handleInputTermStartDate}
                      ref={(node) => (this.inputTermStartDate = node)}
                      disabled={loadingPanel}
                    />
                  </div>
                  {this.state.openCalender === "termStartDate" && (
                    <div style={this.getDatepickerStyle("termStartDate", datepickerModalStyleStart)}>
                      <DatePicker
                        ref={(node) => (this.datePickerTermStartDate = node)}
                        selected={term_start_date ? moment(term_start_date, "YYYY/MM/DD").toDate() : null}
                        dateFormat="yyyy/MM/dd"
                        onChange={this.handleTermStartDate}
                        allowSameDay
                        inline
                        onClickOutside={this.closeCalendarTermStartDate}
                        locale={t("calender_locale")}
                      />
                    </div>
                  )}
                  &nbsp;〜&nbsp;
                  <div
                    className="schedule-datepicker"
                    onMouseEnter={() => this.setState({ onCalendar: true })}
                    onMouseLeave={() => this.setState({ onCalendar: false })}
                  >
                    <input
                      type="text"
                      onFocus={this.openTermEndDateCalender}
                      className="form-control w-120"
                      value={this.state.inputTermEndDate}
                      onChange={this.handleInputTermEndDate}
                      ref={(node) => (this.inputTermEndDate = node)}
                      disabled={loadingPanel}
                    />
                  </div>
                  {this.state.openCalender === "termEndDate" && (
                    <div style={this.getDatepickerStyle("termEndDate", datepickerModalStyleEnd)}>
                      <DatePicker
                        ref={(node) => (this.datePickerTermEndDate = node)}
                        selected={term_end_date ? moment(term_end_date, "YYYY/MM/DD").toDate() : null}
                        dateFormat="yyyy/MM/dd"
                        onChange={this.handleTermEndDate}
                        allowSameDay
                        inline
                        onClickOutside={this.closeCalendarTermEndDate}
                        locale={t("calender_locale")}
                      />
                    </div>
                  )}
                </div>
                {error.term_date && (
                  <div className="form-error w-380">
                    <p className="form-error-message w-260">{error.term_date}</p>
                  </div>
                )}
              </div>
              <div className="form-row">
                <div className="form-group w-base-date">
                  <span className="form-label">{t("base_date")}</span>
                  <div
                    className="schedule-datepicker"
                    onMouseEnter={() => this.setState({ onCalendar: true })}
                    onMouseLeave={() => this.setState({ onCalendar: false })}
                  >
                    <input
                      type="text"
                      onFocus={this.openBaseDateCalender}
                      className="form-control w-120"
                      value={this.state.inputSchedule}
                      onChange={this.handleInputBaseDate}
                      ref={(node) => (this.inputScheduleDate = node)}
                      disabled={loadingPanel}
                    />
                  </div>
                </div>
                {this.state.openCalender === "baseDate" && (
                  <div style={this.getDatepickerStyle("scheduleDate", datepickerModalStyle)}>
                    <DatePicker
                      ref={(node) => (this.datePickerSchedule = node)}
                      selected={base_date ? moment(base_date, "YYYY/MM/DD").toDate() : null}
                      onChange={this.handleBaseDate}
                      dateFormat="yyyy/MM/dd"
                      allowSameDay
                      inline
                      onClickOutside={this.closeCalendarBaseDate}
                      locale={t("calender_locale")}
                    />
                  </div>
                )}
                {error.base_date && (
                  <div className="form-error w-380">
                    <p className="form-error-message w-260">{error.base_date}</p>
                  </div>
                )}
              </div>
              <div className="form-row">
                <div className="form-group w-378">
                  <span className="form-label">{t("past_inazuma_lines")}</span>
                  <div className="toggle-buttons w-past-line">
                    <label>
                      <input
                        type="radio"
                        name="radio-1"
                        checked={this.state.visiblePastInazumaLine}
                        onChange={this.showPastInazumaLine}
                        disabled={loadingPanel}
                      />
                      <span className="btn">{t("display")}</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="radio-1"
                        checked={!this.state.visiblePastInazumaLine}
                        onChange={this.hidePastInazumaLine}
                        disabled={loadingPanel}
                      />
                      <span className="btn">{t("non_display")}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group w-378">
                  <span className="form-label">{t("term_display")}</span>
                  <div
                    className="schedule-datepicker"
                    onMouseEnter={() => this.setState({ onCalendar: true })}
                    onMouseLeave={() => this.setState({ onCalendar: false })}
                  >
                    <input
                      type="text"
                      disabled={!this.state.visiblePastInazumaLine || loadingPanel}
                      onFocus={this.openTermDispStartDateCalender}
                      className="form-control w-120"
                      value={this.state.inputTermDispStartDate}
                      onChange={this.handleInputTermDispStartDate}
                      ref={(node) => (this.inputTermDispStartDate = node)}
                    />
                  </div>
                  {this.state.openCalender === "termDispStartDate" && (
                    <div style={this.getDatepickerStyle("termDispStartDate", datepickerModalStyleStart)}>
                      <DatePicker
                        ref={(node) => (this.datePickerTermDispStartDate = node)}
                        selected={term_disp_start_date ? moment(term_disp_start_date, "YYYY/MM/DD").toDate() : null}
                        dateFormat="yyyy/MM/dd"
                        onChange={this.handleTermDispStartDate}
                        allowSameDay
                        inline
                        onClickOutside={this.closeCalendarTermDispStartDate}
                        locale={t("calender_locale")}
                      />
                    </div>
                  )}
                  &nbsp;〜&nbsp;
                  <div
                    className="schedule-datepicker"
                    onMouseEnter={() => this.setState({ onCalendar: true })}
                    onMouseLeave={() => this.setState({ onCalendar: false })}
                  >
                    <input
                      type="text"
                      disabled={!this.state.visiblePastInazumaLine || loadingPanel}
                      onFocus={this.openTermDispEndDateCalender}
                      className="form-control w-120"
                      value={this.state.inputTermDispEndDate}
                      onChange={this.handleInputTermDispEndDate}
                      ref={(node) => (this.inputTermDispEndDate = node)}
                    />
                  </div>
                  {this.state.openCalender === "termDispEndDate" && (
                    <div style={this.getDatepickerStyle("termDispEndDate", datepickerModalStyleEnd)}>
                      <DatePicker
                        ref={(node) => (this.datePickerTermDispEndDate = node)}
                        selected={term_disp_end_date ? moment(term_disp_end_date, "YYYY/MM/DD").toDate() : null}
                        dateFormat="yyyy/MM/dd"
                        onChange={this.handleTermDispEndDate}
                        allowSameDay
                        inline
                        onClickOutside={this.closeCalendarTermDispEndDate}
                        locale={t("calender_locale")}
                      />
                    </div>
                  )}
                </div>
                {error.term_disp_date && (
                  <div className="form-error w-380">
                    <p className="form-error-message w-260">{error.term_disp_date}</p>
                  </div>
                )}
              </div>
              <div className="form-row mb-0">
                <div className="form-group w-interval">
                  <span className="form-label">{t("interval")}</span>
                  <select
                    data-test-id="select-system-completed"
                    className="form-control w-120"
                    value={this.state.inazumaGapDate}
                    onChange={this.handleChangeGapDate}
                    disabled={!this.state.visiblePastInazumaLine || loadingPanel}
                  >
                    <option key={0} value={1}>
                      {"1日"}
                    </option>
                    <option key={1} value={2}>
                      {"2日"}
                    </option>
                    <option key={2} value={3}>
                      {"3日"}
                    </option>
                    <option key={3} value={4}>
                      {"4日"}
                    </option>
                    <option key={4} value={5}>
                      {"5日"}
                    </option>
                    <option key={5} value={6}>
                      {"6日"}
                    </option>
                    <option key={6} value={7}>
                      {"7日"}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`modal-footer ${loadingPanel ? "loading--dialog" : ""}`}>
          <button
            data-test-id="button-area-edit-cancel"
            type="button"
            className="btn btn-gray"
            disabled={loadingPanel}
            onClick={closeHandler}
          >
            {t("cancel")}
          </button>
          <button
            data-test-id="button-area-edit-save"
            type="button"
            className="btn btn-blue"
            disabled={loadingPanel}
            onClick={this.handleSubmit}
          >
            {t("decision")}
          </button>
        </div>
        {this.state.visibleExportInazumaPanel && <ExportInazumaPanel closeHandler={this.hideExportInazumaPanel} />}
      </Modal>
    );
  }
}

InazumaPanel.defaultProps = {
  data: null,
};

InazumaPanel.propTypes = {
  selectedCategoryCount: PropTypes.number.isRequired,
  targetItemCount: PropTypes.number.isRequired,
  fetchInazumaBasePoints: PropTypes.func.isRequired,
  fetchInazumaTerm: PropTypes.func.isRequired,
  createInazumaBasePoint: PropTypes.func.isRequired,
  requestInazumaLineCreation: PropTypes.func.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
  inazumaSelectedCells: PropTypes.array.isRequired,
  loadingPanel: PropTypes.bool.isRequired,
};

export default withTranslation()(InazumaPanel);
