import React from "react";

type Props = {
  data: string;
};

const Title: React.FC<Props> = ({ data }) => {
  return <div className="progress-notification-title subject">{data}</div>;
};

export default Title;
