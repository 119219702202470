import * as constants from "../constants";

import { ConstructionExtension } from "@/models/constructionExtensions";

type InitialState = {
  isLoading: boolean;
  extensions: ConstructionExtension[];
};

const initialState: InitialState = {
  isLoading: false,
  extensions: [],
};

const constructionExtensions = (state = initialState, action): InitialState => {
  switch (action.type) {
    case constants.CONSTRUCTION_BEGIN_FETCH_EXTENSIONS: {
      return {
        isLoading: true,
        extensions: state.extensions.map((extension) => {
          return {
            ...extension,
            config: JSON.parse(JSON.stringify(extension.config)),
          };
        }),
      };
    }
    case constants.CONSTRUCTION_END_FETCH_EXTENSIONS: {
      return {
        isLoading: false,
        extensions: action.payload?.extensions ?? [],
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default constructionExtensions;
