import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import validator from "validator";

import Modal from "../Modal";

class ExportSchedulePanel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;

    return (
      <Modal title={t("output_schedule")} closeHandler={this.props.closeHandler}>
        <div className="modal-body modal-schedule-confirm">
          <p>
            {t("output_schedule_1")}
            <br />
            {t("output_schedule_2")}
          </p>
          <p>
            <br />
            {t("output_schedule_3")}
            <br />
            {t("output_schedule_4")}
            <br />
            {t("output_schedule_5")}
          </p>
        </div>
        <div className="modal-footer">
          <button
            data-test-id="button-comment-editor-cancel"
            type="button"
            className="btn btn-blue"
            onClick={this.props.closeHandler}
          >
            {t("close")}
          </button>
        </div>
      </Modal>
    );
  }
}

ExportSchedulePanel.propTypes = {
  closeHandler: PropTypes.func.isRequired,
};

export default withTranslation()(ExportSchedulePanel);
