import PropTypes from "prop-types";
import React, { Component } from "react";

import LeftGridRow from "./LeftGridRow";

class LeftGridBody extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <table className="grid-table grid-table-body grid-table-left grid-table-left-body">
        <tbody>
          {this.props.rows.map((row, index) => {
            return <LeftGridRow key={index} index={index} row={row} />;
          })}
        </tbody>
      </table>
    );
  }
}

LeftGridBody.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default LeftGridBody;
