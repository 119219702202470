// TODO Remove it
export const getStatusNumber = (validFlg, status, scheduleType) => {
  let result = 0;

  if (validFlg > 0) {
    if (status === 0) {
      switch (scheduleType) {
        case 0:
          result = 1;

          break;
        case 1:
          result = 2;

          break;
        case 2:
          result = 3;

          break;
        case 3:
          result = 4;

          break;
        case 4:
          result = 5;

          break;
      }
    } else if (status === 1) {
      result = 6;
    } else if (status === 2) {
      result = 7;
    } else if (status === 9) {
      result = 8;
    }
  }

  return result;
};

export const getPropsByStatusNumber = (statusNumber) => {
  switch (statusNumber) {
    case 0:
      return { valid_flg: 0, status: 0, schedule_type: 0 };
    case 1:
      return { valid_flg: 1, status: 0, schedule_type: 0 };
    case 2:
      return { valid_flg: 1, status: 0, schedule_type: 1 };
    case 3:
      return { valid_flg: 1, status: 0, schedule_type: 2 };
    case 4:
      return { valid_flg: 1, status: 0, schedule_type: 3 };
    case 5:
      return { valid_flg: 1, status: 0, schedule_type: 4 };
    case 6:
      return { valid_flg: 1, status: 1, schedule_type: 0 };
    case 7:
      return { valid_flg: 1, status: 2, schedule_type: 0 };
    case 8:
      return { valid_flg: 1, status: 9, schedule_type: 0 };
  }
};
