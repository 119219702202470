import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import MasterSelect from "@/components/common/Select";
import { useSearchInput } from "@/components/list/Holiday/hooks/useSearchInput";
import { NONE_VALUE } from "@/constants";
import { RootState } from "@/reducers/types";

export const SearchBox: React.FC = () => {
  const { t } = useTranslation();

  const { masters } = useSelector((state: RootState) => state.construction);

  const input = useSearchInput();

  if (!masters) return <></>;
  const { categories, primary_charges, areas, devices, facility_managements, construction_managements } = masters;

  return (
    <div className="search-box w-1200 mb-0">
      <div className="form-row">
        <div className="form-group w-240">
          <span className="form-label">{t("machines_category")}</span>
          <div className="w-170 d-ib ta-l">
            <MasterSelect
              prefix="category"
              isMulti={true}
              options={categories ?? []}
              value={input.categoryIds ?? NONE_VALUE}
              onChange={(ids) => input.setCategoryIds(ids)}
            />
          </div>
        </div>
        <div className="form-group w-270">
          <span className="form-label">{t("primary_charge")}</span>
          <div className="w-170 d-ib ta-l">
            <MasterSelect
              prefix="primary_charge"
              isMulti={true}
              options={primary_charges ?? []}
              value={input.primaryChargeIds ?? NONE_VALUE}
              onChange={(ids) => input.setPrimaryChargeIds(ids)}
            />
          </div>
        </div>
        <div className="form-group w-270">
          <span className="form-label">{t("area")}</span>
          <div className="w-170 d-ib ta-l">
            <MasterSelect
              prefix="area"
              isMulti={true}
              options={areas ?? []}
              value={input.areaIds}
              onChange={(ids) => input.setAreaIds(ids)}
            />
          </div>
        </div>
      </div>
      <div className="form-row mb-0">
        <div className="form-group w-240">
          <span className="form-label">{t("device")}</span>
          <div className="w-170 d-ib ta-l">
            <MasterSelect
              prefix="device"
              isMulti={true}
              options={devices ?? []}
              value={input.deviceIds}
              onChange={(ids) => input.setDeviceIds(ids)}
            />
          </div>
        </div>
        <div className="form-group w-270">
          <span className="form-label">{t("facility_management")}</span>
          <div className="w-170 d-ib ta-l">
            <MasterSelect
              prefix="facility_management"
              isMulti={true}
              options={facility_managements ?? []}
              value={input.facilityManagementIds ?? NONE_VALUE}
              onChange={(ids) => input.setFacilityManagementIds(ids)}
            />
          </div>
        </div>
        <div className="form-group w-270">
          <span className="form-label">{t("construction_management")}</span>
          <div className="w-170 d-ib ta-l">
            <MasterSelect
              prefix="construction_management"
              isMulti={true}
              options={construction_managements ?? []}
              value={input.constructionManagementIds ?? NONE_VALUE}
              onChange={(ids) => input.setConstructionManagementIds(ids)}
            />
          </div>
        </div>
        <div className="form-group btn-area pull-right mt-0">
          <button data-test-id="button-holiday-reset" className="btn btn-gray" onClick={input.reset}>
            {t("reset")}
          </button>
          <button data-test-id="button-holiday-search" className="btn btn-blue" onClick={input.search}>
            {t("search")}
          </button>
        </div>
      </div>
    </div>
  );
};
