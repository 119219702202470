import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import actions from "@/actions";
import { getConstructionId } from "@/lib/common";
import storageManager from "@/lib/storageManager";
import { RootState } from "@/reducers/types";
import { Button } from "@/sx-layout/common/Button";
import { FormError, FormLabel, FormRow, Select } from "@/sx-layout/common/Form";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "@/sx-layout/common/Modal";
import { PlotPlanState } from "@/sx-layout/components/settings/plotPlanSetting/reducers/plotPlan";
import { Menu } from "@/sx-layout/models";

type Props = {
  readonly onClose: () => void;
};

export const PlotPlanSelector: FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();
  const constructionId = getConstructionId();
  const plotPlanMenu: Menu = useSelector((state) => {
    return state.app.menus.find((menu) => menu.menu_id === 2003);
  });

  const { control, setValue } = useForm();

  useEffect(() => {
    if (constructionId) {
      dispatch(actions.plotPlan.fetchPlotPlans());
    }
  }, []);

  const { plotPlans, fetching } = useSelector<RootState, PlotPlanState>((state) => state.plotPlan);

  const defaultPlotPlanId = useSelector<RootState, string>((state) => state.plotmap.plotPlanId);

  const [plotPlanId, setPlotPlanId] = useState<string>(defaultPlotPlanId);

  const [error, setError] = useState({
    notSelected: false,
    noPlotPlans: false,
  });

  useEffect(() => {
    if (fetching) {
      return;
    }

    if (!defaultPlotPlanId) {
      setPlotPlanId(String(plotPlans[0]?.plot_plan_id));
      setValue("plot_plan", String(plotPlans[0]?.plot_plan_id));
    } else {
      setValue("plot_plan", defaultPlotPlanId);
    }
  }, [fetching, defaultPlotPlanId]);

  const onSubmit = () => {
    if (!plotPlanId || (typeof plotPlanId === "string" && plotPlanId === "undefined")) {
      setError({
        ...error,
        notSelected: true,
      });

      return;
    }

    setError({
      notSelected: false,
      noPlotPlans: false,
    });

    dispatch(actions.plotmap.selectPlotPlan(plotPlanId));
    storageManager.setPlotPlanId(plotPlanId);
    handleClose();
  };

  const handleClose = () => {
    if (!plotPlanId || (typeof plotPlanId === "string" && plotPlanId === "undefined")) {
      setError({
        ...error,
        notSelected: true,
      });

      return;
    }

    const localPlotPlanId = storageManager.getPlotPlanId();
    if (!localPlotPlanId || (typeof localPlotPlanId === "string" && localPlotPlanId === "undefined")) {
      setError({
        ...error,
        notSelected: true,
      });

      return;
    }

    onClose();
  };

  const { t } = useTranslation();

  const isDisabledPlotPlan = plotPlans.length === 0 && !plotPlanMenu?.active;
  const shouldGoToPlotPlanSettings = plotPlans.length === 0 && plotPlanMenu?.active;

  const renderErrorMsg = () => {
    if (error.notSelected) {
      return <FormError className="ml-[136px]">{t("select_plot_plan_validation")}</FormError>;
    }

    if (plotPlans.length === 0) {
      if (plotPlanMenu?.active) {
        return (
          <>
            <FormError className="ml-[136px]">{t("no_plot_plan")}</FormError>
            <FormError className="ml-[136px]">{t("create_plot_plan_validation")}</FormError>
          </>
        );
      } else {
        return (
          <>
            <FormError className="ml-[136px]">{t("no_plot_plan")}</FormError>
            <FormError className="ml-[136px]">{t("contact_admin")}</FormError>
          </>
        );
      }
    }

    return null;
  };

  const goToPlotPlanSettings = () => {
    dispatch(actions.layoutApp.changeLayoutMenu("plot_plan"));

    history.pushState(undefined, undefined, `/#/${constructionId}/plot_plan`);
  };

  return (
    <Modal onClose={handleClose} shouldCloseOnEsc={false} shouldCloseOnOverlayClick={false}>
      <ModalHeader>{t("select_plot_plan")}</ModalHeader>
      <ModalBody className="min-w-[540px] min-h-[130px] items-start mt-[20px]">
        <form>
          <FormRow className="mt-0" errorComponent={renderErrorMsg()}>
            <FormLabel className="w-[120px]">{t("plot_plan")}</FormLabel>
            <div className="flex-col">
              <Select
                className="w-260"
                name="plot_plan"
                control={control}
                handleChange={(value) => setPlotPlanId(String(value))}
                options={plotPlans.map((plotPlan) => ({
                  label: plotPlan.plot_plan_name,
                  value: plotPlan.plot_plan_id,
                }))}
              />
            </div>
          </FormRow>
        </form>
      </ModalBody>

      <ModalFooter>
        {shouldGoToPlotPlanSettings ? (
          <Button onClick={goToPlotPlanSettings}>{t("plot_plan_settings")}</Button>
        ) : isDisabledPlotPlan ? (
          <Button onClick={() => dispatch(actions.login.logout())}>{t("log_out")}</Button>
        ) : (
          <>
            <Button buttonType="cancel" onClick={handleClose}>
              {t("cancel")}
            </Button>
            <Button onClick={onSubmit}>{t("decision")}</Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};
