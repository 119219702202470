import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as z from "zod";

import actions from "@/actions";
import { TimeStamp } from "@/models/timestamp";
import { Button } from "@/sx-layout/common/Button";
import { FormLabel, FormRow, InputText, TextArea, Radio, FileUploader, FormError } from "@/sx-layout/common/Form";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "@/sx-layout/common/Modal";
import {
  CreatePlotPlanProps,
  FetchPlotPlanProps,
  UpdatePlotPlanProps,
} from "@/sx-layout/components/settings/plotPlanSetting/actions/types";
import { PlotPlan } from "@/sx-layout/components/settings/plotPlanSetting/models";
import { qualityMasters } from "@/sx-layout/components/settings/plotPlanSetting/utils/quality";

export type PlotPlanEditorProps = {
  readonly editPlotPlan?: PlotPlan;
  readonly onClose: () => void;
};

export const PlotPlanEditor: FC<PlotPlanEditorProps> = ({ editPlotPlan, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const mode: "create" | "update" = editPlotPlan === undefined ? "create" : "update";

  const formSchema = z.object({
    plot_plan_name: z
      .string()
      .min(1, { message: t("input_plot_plan_name_validation") })
      .max(100, { message: t("plot_plan_name_is_too_long_100") }),
    uploadfile: z.z
      .instanceof(FileList)
      .nullish()
      .refine((file) => mode === "update" || !!file, { message: t("select_floor_plan_validation") }),
    quality: z.string(),
    note: z.string().max(500, { message: t("note_is_too_long_500") }),
  });
  type FormSchema = z.infer<typeof formSchema>;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    reValidateMode: "onSubmit",
    defaultValues: {
      plot_plan_name: "",
      uploadfile: null,
      quality: mode === "create" ? qualityMasters.find((quality) => quality.id === "middle").value.toString() : null,
      note: "",
    },
  });
  const { onChange: onFileChange } = register("uploadfile");
  const [loading, setLoading] = useState<boolean>(editPlotPlan !== undefined);
  const [updateTargetId, setUpdateTargetId] = useState<number>();
  const [fileName, setFileName] = useState<string>();
  const [timestamp, setTimestamp] = useState<TimeStamp>();

  useEffect(() => {
    if (mode === "update") {
      dispatch(
        actions.plotPlan.fetchPlotPlan({
          input: editPlotPlan,
          onSuccess: (data) => {
            const { file_name, plot_plan_id, plot_plan_name, quality, note } = data;
            setUpdateTargetId(plot_plan_id);
            setTimestamp(data.timestamp);
            reset({ plot_plan_name, quality: quality.toString(), note });
            setFileName(file_name);
            setLoading(false);
          },
        } as FetchPlotPlanProps)
      );
    }
  }, []);

  const handleImageUpload = (event) => {
    setFileName(event.target.files[0]?.name);
    onFileChange(event);
  };

  const onSubmit = useCallback(
    ({ plot_plan_name, uploadfile, quality, note }: FormSchema) => {
      setLoading(true);
      if (mode === "create") {
        dispatch(
          actions.plotPlan.createPlotPlan({
            input: {
              plot_plan_name,
              uploadfile: uploadfile?.[0],
              quality: Number(quality),
              note,
            },
            onSuccess: () => {
              onClose();
              setLoading(false);
            },
            onFailure: () => {
              setLoading(false);
            },
          } as CreatePlotPlanProps)
        );
      } else {
        dispatch(
          actions.plotPlan.updatePlotPlan({
            input: {
              plot_plan_id: updateTargetId,
              plot_plan_name,
              quality: Number(quality),
              note,
              timestamp,
            },
            onSuccess: () => {
              onClose();
              setLoading(false);
            },
            onFailure: () => {
              setLoading(false);
            },
          } as UpdatePlotPlanProps)
        );
      }
    },
    [updateTargetId, timestamp]
  );

  return (
    <Modal onClose={onClose}>
      <form>
        <ModalHeader>{t("edit_plot_plan")}</ModalHeader>
        <ModalBody>
          <div>
            <FormRow className="mt-0" errorComponent={<FormError>{errors.plot_plan_name?.message}</FormError>}>
              <FormLabel>{t("plot_plan_name")}</FormLabel>
              <InputText {...register("plot_plan_name")} />
            </FormRow>
            <FormRow errorComponent={<FormError>{errors.uploadfile?.message}</FormError>}>
              <FormLabel>{t("floor_plan")}</FormLabel>
              {editPlotPlan ? (
                !loading && <div>{fileName}</div>
              ) : (
                <>
                  <FileUploader className="min-w-[110px]" {...register("uploadfile")} onChange={handleImageUpload} />
                  {fileName && <div className="ml-5">{fileName}</div>}
                </>
              )}
            </FormRow>
            <FormRow>
              <FormLabel>{t("image_quality")}</FormLabel>
              <div className="flex">
                {qualityMasters.map((quality) => (
                  <Radio key={quality.id} id={quality.id} value={quality.value} {...register("quality")}>
                    {t(quality.label)}
                  </Radio>
                ))}
              </div>
            </FormRow>
            <FormRow
              className="items-start"
              errorComponent={errors.note && <FormError>{errors.note.message}</FormError>}
            >
              <FormLabel className="mt-[4px]">{t("note")}</FormLabel>
              <TextArea {...register("note")} />
            </FormRow>
            {/* プロットプラン名のinput textでEnterを押したときにsubmitされるのを防ぐため、非表示のinput textを配置 #472 */}
            {/* ref. https://kasumiblog.org/form-enter/ */}
            <input type="text" className="hidden" />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button buttonType="cancel" onClick={onClose} disabled={loading}>
            {t("cancel")}
          </Button>
          <Button buttonType="save" onClick={handleSubmit(onSubmit)} disabled={loading} isLoading={loading}>
            {t("save")}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
