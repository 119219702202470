import { useDispatch } from "react-redux";

import actions from "@/actions";
import {
  APPROVE_SCHEDULE_TARGET,
  UPDATE_SCHEDULE_TARGET,
  UPDATE_SCHEDULE_WITH_TASK_TARGET,
} from "@/components/list/Schedule/constants";
import { ContinuousUpdateStatusType, ScheduleScheduleItem } from "@/components/list/Schedule/types";
import { UpdateScheduleRequest } from "@/components/list/Schedule/types/api";
import { convertToValidSafetyMattersString } from "@/lib/common";

export const useContinuousUpdate = () => {
  const dispatch = useDispatch();

  const update = (item: ScheduleScheduleItem, target: string, value: ContinuousUpdateStatusType) => {
    const requestTarget = target.replaceAll("_status", "");
    const request: UpdateScheduleRequest = {
      input: {
        schedule_task_id: item.schedule_task_id,
        target: requestTarget,
        value: target === "field_t8" ? convertToValidSafetyMattersString(value) : value,
        update_date: item[`${requestTarget}_update_date`] ?? item.timestamp.update_date,
      },
    };
    if (UPDATE_SCHEDULE_TARGET.includes(target)) {
      dispatch(actions.schedule.updateSchedule(request));
    } else if (UPDATE_SCHEDULE_WITH_TASK_TARGET.includes(target)) {
      dispatch(actions.schedule.updateScheduleWithTask(request));
    } else if (APPROVE_SCHEDULE_TARGET.includes(target)) {
      dispatch(actions.schedule.approveSchedule(request));
    }
  };

  return { update };
};
