import { connect } from "react-redux";

import actions from "../../actions";
import SpecialProcessEditor from "../../components/list/SpecialProcessEditor";

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateItem: (data, handleSuccess, handleCancel) =>
      dispatch(actions.schedule_chiba.updateItem(data, handleSuccess, handleCancel)),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialProcessEditor);
