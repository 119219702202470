import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";

import styles from "../../constants/reactSelectStyle";
import Modal from "../Modal";

class ScheduleUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryId: -1,
      scheduleDate: this.props.scheduleDateList[0].value || "",
      loading: false,
      loadingAll: false,
      categoryName: "",
      categoryNum: this.props.categories.length,
      count: 1,
      isAllCategory: false,
      isDone: false,
      isSuspend: false,
      isUpdating: false,
    };

    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.handleChangeScheduleDate = this.handleChangeScheduleDate.bind(this);
    this.handleSuspend = this.handleSuspend.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleAllCreate = this.handleAllCreate.bind(this);
  }

  componentDidMount() {
    this.props.setUnloadAlert();
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();
  }

  handleChangeCategory(e) {
    this.setState({
      categoryId: e.target.value,
      isAllCategory: e.target.value == -1 ? true : false,
    });
  }

  handleChangeScheduleDate(e) {
    this.setState({
      scheduleDate: e.target.value,
    });
  }

  handleSuspend() {
    this.setState({ isSuspend: true, loading: false, loadingAll: false });
  }

  handleCancel() {
    this.props.closeHandler();
  }

  handleSave() {
    const data = { ...this.state };
    const { t } = this.props;

    if (data.categoryId == -1) {
      this.handleAllCreate();
    } else {
      data.loading = true;
      this.setState(data);

      this.props.createSchedule(
        data,
        (res) => {
          this.setState({ loading: false });
          this.props.closeHandler();
          this.props.showAlert(
            t("confirmation"),
            [t("updated_schedule") + "（" + res.count + t("unit") + "）"],
            this.props.hideAlert
          );
        },
        () => {
          this.setState({ loading: false });
        }
      );
    }
  }

  handleAllCreate() {
    const { categories, createSchedule } = this.props;
    const getSuspend = () => this.state.isSuspend;
    const execCreateSchedule = (data) => {
      return new Promise((resolve) => {
        createSchedule(
          data,
          (res) => {
            resolve(true);
          },
          () => {
            reject(false);
          }
        );
      });
    };

    const run = async () => {
      let suspend = false;
      this.setState({ isSuspend: false });
      for (let i = 0; i < categories.length; i += 1) {
        const data = { ...this.state };
        data.loadingAll = true;
        data.isAllCategory = true;
        data.categoryId = categories[i].category_id;
        data.categoryName = categories[i].category_name;
        data.isUpdating = true;
        data.count = i + 1;

        if (getSuspend()) {
          suspend = true;
          data.isSuspend = false;
          this.setState(data);

          break;
        }
        this.setState(data);

        const result = await execCreateSchedule(data);
        if (!result) break;
      }

      return suspend;
    };
    run().then((suspend) => {
      this.setState({
        loading: false,
        loadingAll: false,
        categoryId: -1,
        isDone: suspend ? false : true,
        isUpdating: false,
      });
    });
  }

  render() {
    const { categories, scheduleDateList, closeHandler, t } = this.props;
    const { loading, loadingAll, scheduleDate, categoryName, categoryNum, count, isAllCategory, isDone, isUpdating } =
      this.state;

    return (
      <Modal title={t("schedule_update")} closeHandler={() => closeHandler()}>
        <div className="modal-body schedule-update">
          {isAllCategory && (isUpdating || isDone) ? (
            <React.Fragment>
              <div className="updating-msg">
                <p>{`${t("scheduled_date")}：${scheduleDate}`}</p>
                {isDone && (
                  <React.Fragment>
                    <br />
                    <p>
                      {t("all_categories_no_braket")} {t("update_done")}
                    </p>
                  </React.Fragment>
                )}
                {isUpdating && (
                  <React.Fragment>
                    <p>{`${categoryName} ${t("updating")}`}</p>
                    <p>{`${count}/${categoryNum}`}</p>
                  </React.Fragment>
                )}
              </div>
              <div className={`loading-area ${loadingAll ? "loading loading--dialog" : ""}`}></div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="form-row">
                <div className="form-group w-280">
                  <div>
                    <span className="form-label txt-bold">{t("scheduled_date_shorten")}</span>
                    <select
                      data-test-id="select-schedule-date"
                      className="form-control w-220"
                      onChange={this.handleChangeScheduleDate}
                    >
                      {scheduleDateList.map((item) => (
                        <option key={item.id} value={item.value}>
                          {moment(item.value).format("YYYY/MM/DD")}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-row mb-5">
                <div className="form-group w-280">
                  <div>
                    <span className="form-label txt-bold">{t("machines_category")}</span>
                    <select
                      data-test-id="text-import-file-type"
                      className="form-control w-220"
                      onChange={this.handleChangeCategory}
                    >
                      <option key={-1} value={-1}>
                        {t("all_categories")}
                      </option>
                      {categories.map((category) => (
                        <option key={category.category_id} value={category.category_id}>
                          {category.category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
        <div className={`modal-footer ${loading ? "loading loading--dialog" : ""}`}>
          {loadingAll ? (
            <button
              data-test-id="button-update-cancel"
              type="button"
              className={`btn btn-gray ${isDone ? "disp-non" : ""}`}
              onClick={this.handleSuspend}
            >
              {t("cancel")}
            </button>
          ) : (
            <button
              data-test-id="button-update-cancel"
              type="button"
              className={`btn btn-gray ${isDone ? "disp-non" : ""}`}
              onClick={this.handleCancel}
            >
              {t("cancel")}
            </button>
          )}
          <button
            disabled={loading || loadingAll ? "disabled" : ""}
            data-test-id="button-update-save"
            type="button"
            className={`btn btn-blue ${isDone ? "disp-non" : ""}`}
            onClick={this.handleSave}
          >
            {t("update")}
          </button>
          <button
            data-test-id="button-update-ok"
            type="button"
            className={`btn btn-blue ${isDone ? "" : "disp-non"}`}
            onClick={this.handleCancel}
          >
            {t("alert_ok")}
          </button>
        </div>
      </Modal>
    );
  }
}

ScheduleUpdate.propTypes = {
  scheduleDateList: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  closeHandler: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ScheduleUpdate);
