import { connect } from "react-redux";

import actions from "../../actions";
import SpecialProcessBundleEditor from "../../components/matrix/SpecialProcessBundleEditor";

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSave: (data, callback, cancelHandler, failedCallback) =>
      dispatch(actions.matrix.bulkUpdateSpecialProcess(data, callback, cancelHandler, failedCallback)),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialProcessBundleEditor);
