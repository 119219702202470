import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";

import styles from "../../constants/reactSelectStyle";
import Modal from "../Modal";
import { SubmitButton } from "@/components/common/SubmitButton";

class ScheduleEditorBundleChita extends Component {
  constructor(props) {
    super(props);
    this.state = {
      target: "",
      value: this.props.target == "note" ? "" : -1,
      loading: false,
      error: {},
    };

    this.handleChangeTarget = this.handleChangeTarget.bind(this);
    this.handleChangeNote = this.handleChangeNote.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.props.setUnloadAlert();
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();
  }

  handleChangeTarget(e) {
    this.setState({
      target: this.props.target,
      value: parseInt(e.target.value),
    });
  }

  handleChangeNote(e) {
    this.setState({
      target: this.props.target,
      value: e.target.value,
    });
  }

  handleSave() {
    if (this.state.loading) return;
    const error = {};
    const { panelText, requestParam } = this.props;
    const data = { ...this.state, ...requestParam };

    if (this.state.value == -1) {
      error.value = panelText;
      error.msg = "is_not_selected";
    }

    data.error = error;

    if (Object.keys(error).length > 0) {
      this.setState(data);
    } else {
      data.loading = true;
      this.setState(data);
      this.props.updateItemBundle(
        data,
        () => {
          this.setState({ loading: false });
          this.props.searchHandler();
          this.props.closeHandler();
        },
        () => {
          this.setState({ loading: false });
        }
      );
    }
  }

  render() {
    const { target, panelText, selectValues, closeHandler, t } = this.props;
    const { error } = this.state;

    return (
      <Modal title={panelText + " " + t("update_bulk")} closeHandler={() => closeHandler()}>
        <div className="modal-body schedule-editor">
          <div className="form-row mb-5">
            <div className="form-group w-360 ta-u">
              {target == "note" ? (
                <textarea
                  data-test-id="text-note-editor"
                  rows="4"
                  className="form-control w-360"
                  value={this.state.value}
                  onChange={this.handleChangeNote}
                />
              ) : (
                <div className="form-center">
                  <select
                    data-test-id="text-import-file-type"
                    className="form-control w-170"
                    onChange={this.handleChangeTarget}
                  >
                    <option key={-1} value={-1}>
                      -----
                    </option>
                    {selectValues.map((value) => (
                      <option key={value.id} value={value.id}>
                        {value.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            {error.value && (
              <div className="form-error w-360">
                <p className="form-error-message w-260">
                  {t(error.value)}
                  {t(error.msg)}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className={"modal-footer"}>
          <button
            data-test-id="button-column-editor-cancel"
            type="button"
            className="btn btn-gray"
            onClick={() => closeHandler()}
          >
            {t("cancel")}
          </button>
          <SubmitButton
            data-test-id="button-column-editor-save"
            onClick={this.handleSave}
            loading={this.state.loading}
          />
        </div>
      </Modal>
    );
  }
}

ScheduleEditorBundleChita.propTypes = {
  target: PropTypes.string.isRequired,
  panelText: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
};

export default withTranslation()(ScheduleEditorBundleChita);
