import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import DeviceEditor from "./DeviceEditor";

class Device extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditor: false,
      editId: 0,
      fetchingItem: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.showEditor = this.showEditor.bind(this);
    this.hideEditor = this.hideEditor.bind(this);
  }

  componentDidMount() {
    this.setState({ fetchingItem: true });
    this.props.fetchItems(() => this.setState({ fetchingItem: false }));
  }

  showEditor(id) {
    const newState = {
      showEditor: true,
      editId: id,
      fetchingItem: false,
    };

    if (id === 0) {
      this.setState(newState);
    } else {
      newState.fetchingItem = true;
      this.setState(newState);

      this.props.fetchItem(
        id,
        () => this.setState({ fetchingItem: false }),
        () => this.setState({ fetchingItem: false, showEditor: false })
      );
    }
  }

  hideEditor() {
    this.setState({ ...this.state, showEditor: false });
  }

  handleChange(e) {
    this.props.changeSearchText(e.target.value);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.searchItems(this.props.text);
  }

  handleReset(e) {
    e.preventDefault();
    this.props.changeSearchText("");
    this.props.searchItems("");
  }

  handleDelete(id, name) {
    this.props.deleteItem(id, name);
  }

  handleSave(data, cancelHandler, errorHandler) {
    if (data.device_id === 0) {
      this.props.createItem(data, this.hideEditor, cancelHandler);
    } else {
      this.props.updateItem(data, this.hideEditor, cancelHandler, errorHandler);
    }
  }

  render() {
    const { text, items, current, fetching, isError, t } = this.props;

    return (
      <div>
        <div className="contents">
          <div className="inner">
            <h1 className="page-ttl">
              {t("master_manage")}：{t("device")}
              <span
                data-test-id="button-device-create"
                className="toggle icon-add_circle"
                onClick={() => this.showEditor(0)}
              ></span>
            </h1>
            <form className="search-box w-550" onSubmit={this.handleSubmit}>
              <div className="form-row mb-0">
                <div className="form-group w-350">
                  <span className="form-label">{t("device_name")}</span>
                  <input
                    data-test-id="text-device-device-name"
                    type="text"
                    name="text"
                    value={text}
                    onChange={this.handleChange}
                    className="form-control w-270"
                  />
                </div>
                <div className="form-group btn-area pull-right">
                  <button
                    data-test-id="button-device-search"
                    type="submit"
                    className="btn btn-blue"
                    disabled={fetching}
                  >
                    {t("search")}
                  </button>
                </div>
              </div>
            </form>
            <div className={`tbl-category ${fetching ? "loading loading--list" : ""} mb-20`}>
              {this.props.items.length === 0 ? (
                !fetching && <p className="empty-message">{t("no_data")}</p>
              ) : (
                <table className="tbl-basic tbl-data">
                  <thead>
                    <tr>
                      <th>{t("device_name")}</th>
                      <th width="160">{t("device_code")}</th>
                      <th width="120">{t("display_order")}</th>
                      <th width="80">{t("edit")}</th>
                      <th width="80">{t("remove")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.device_name}</td>
                          <td className="txt-center">{item.device_code}</td>
                          <td className="txt-center">{item.sort}</td>
                          <td className="txt-center">
                            <a onClick={() => this.showEditor(item.device_id)}>{t("edit")}</a>
                          </td>
                          <td className="txt-center">
                            <a onClick={() => this.handleDelete(item.device_id, item.device_name)}>{t("remove")}</a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        {this.state.showEditor && (
          <DeviceEditor
            data={this.state.editId === 0 ? null : current}
            closeHandler={this.hideEditor}
            saveHandler={this.handleSave}
            items={items}
            fetching={this.state.fetchingItem}
            setUnloadAlert={this.props.setUnloadAlert}
            clearUnloadAlert={this.props.clearUnloadAlert}
          />
        )}
      </div>
    );
  }
}

Device.defaultProps = {
  masterItems: [],
  text: "",
  items: [],
  current: null,
};

Device.propTypes = {
  masterItems: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  current: PropTypes.object,
  fetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  fetchItems: PropTypes.func.isRequired,
  changeSearchText: PropTypes.func.isRequired,
  searchItems: PropTypes.func.isRequired,
  fetchItem: PropTypes.func.isRequired,
  createItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
};

export default withTranslation()(Device);
