import PropTypes from "prop-types";
import React, { Component } from "react";

import RightGridRowContainer from "../../containers/summary/RightGridRowContainer";

class RightGridBody extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <table className="grid-table grid-table-body grid-table-right grid-table-right-body">
        <tbody>
          {this.props.rows.map((row, index) => {
            return (
              <RightGridRowContainer
                key={index}
                index={index}
                row={row}
                inazuma={this.props.inazuma}
                countUpCategories={this.props.countUpCategories}
                countDownCategories={this.props.countDownCategories}
                addTargetItems={this.props.addTargetItems}
              />
            );
          })}
        </tbody>
      </table>
    );
  }
}

RightGridBody.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default RightGridBody;
