import { connect } from "react-redux";

import actions from "../../actions";
import ScheduleUpdateChita from "../../components/list/ScheduleUpdateChita";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSchedule: (data, handleSuccess, handleCancel) =>
      dispatch(actions.schedule_chita.createSchedule(data, handleSuccess, handleCancel)),
    showAlert: (title, messages, okClickHandler) => dispatch(actions.app.showAlert(title, messages, okClickHandler)),
    hideAlert: () => dispatch(actions.app.hideAlert()),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleUpdateChita);
