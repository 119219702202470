import { useState } from "react";
import { useDispatch } from "react-redux";

import actions from "@/actions";
import { Machine, MachineStatus } from "@/sx-layout/components/plotmap/models";

export const useChangeMachineUseEndHourModal = (): {
  open: () => void;
  isOpen: boolean;
  close: () => void;
  submit: (machine: Machine, hour?: string, option?: { onSuccess: (res) => void }) => void;
} => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const submit = (machine: Machine, changedUseEndHour?: string, option?: { onSuccess: (res) => void }) => {
    const status = machine.status === MachineStatus.STAND_BY ? MachineStatus.RUNNING : MachineStatus.STAND_BY;
    dispatch(
      actions.machineEdit.updateMachineStatus({
        input: {
          machine_id: String(machine.machine_id),
          status,
          use_end_hour: changedUseEndHour ?? machine.use_end_hour,
          timestamp: {
            update_date: machine.timestamp.update_date,
          },
        },
        onSuccess: (response) => {
          option?.onSuccess?.(response);
        },
      })
    );
  };

  return {
    isOpen,
    open,
    close,
    submit,
  };
};
