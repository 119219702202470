import actions from "../actions";
import * as constants from "../constants";

import loginHooks from "./hooks/login";

const hooks = {
  ...loginHooks,
  [constants.COMMON_ERROR_CHANGE_TASK_STATUS]: (store, action) => {
    store.dispatch(actions.app.addToastContent("failed_change_status", constants.TOAST_CONTENT_TYPES.ERROR));
  },
};

const chainMiddleware = (store) => (next) => (action) => {
  next(action);

  // アクションチェーンは最後に実行する
  const hook = hooks[action.type];
  hook && hook(store, action);
};

export default chainMiddleware;
