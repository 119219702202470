import React from "react";
import { useSelector } from "react-redux";

import RightGridRowContainer from "../../containers/matrix/RightGridRowContainer";

import { MATRIX_TABLE_DATA_WIDTH } from "@/models/matrix";
import { RootState } from "@/reducers/types";

type Props = {
  rows: any[];
  showTaskEditor: (taskId, isStatus, linkageInfo) => void;
  setTooltip: (_) => void;
  offset: number;
  maxLength: number;
};

const RightGridBody: React.FC<Props> = (props) => {
  const { dragColumn, dropIndex } = useSelector((state: RootState) => state.matrix);

  return (
    <>
      <table className="grid-table grid-table-body grid-table-right grid-table-right-body">
        <tbody>
          {props.rows.map((row, index) => {
            const { offset, maxLength } = props;

            return index >= offset && index < offset + maxLength ? (
              <RightGridRowContainer
                key={index}
                // @ts-ignore
                showTaskEditor={props.showTaskEditor}
                row={row}
              />
            ) : (
              <tr key={index} style={{ height: 80 }} />
            );
          })}
        </tbody>
      </table>
      <div
        className="grid-dragging-body"
        style={{
          visibility: dragColumn ? "visible" : "hidden",
          left: dragColumn?.offsetLeft ?? 0,
          top: 0,
          width: dragColumn?.offsetWidth ?? 0,
          height: `${80 * props.rows.length}px`,
        }}
      />
      <div
        className="grid-drop-body"
        style={{
          visibility:
            dragColumn && ![dragColumn.index, dragColumn.index + 1].includes(dropIndex) ? "visible" : "hidden",
          left: dragColumn ? dragColumn.offsetWidth * dropIndex : 0,
          top: 0,
          height: `${MATRIX_TABLE_DATA_WIDTH * props.rows.length}px`,
        }}
      />
    </>
  );
};

export default RightGridBody;
