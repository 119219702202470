import React, { useState } from "react";

const Toggle = (props) => {
  const { label, defaultChecked, onChange, className = "", isBold = false } = props;

  const [checked, setChecked] = useState(defaultChecked && true);

  const handleChange = (e) => {
    setChecked(!checked);
    onChange(e);
  };

  return (
    <label htmlFor="default-toggle" className={`inline-flex relative items-center cursor-pointer ${className}`}>
      <input type="checkbox" id="default-toggle" className="sr-only peer" onChange={handleChange} checked={checked} />
      <div className="w-[44px] h-[24px] bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[20px] after:w-[20px] after:transition-all peer-checked:bg-[#dea321]"></div>
      <span className={`ml-6 text-[13px] ${isBold ? "font-bold" : "font-medium"}`}>{label}</span>
    </label>
  );
};

export { Toggle };
