import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";

import { isValidRole } from "../../lib/roleChecker";

import LeftGridCell from "./LeftGridCell";
import clsx from "clsx";
import { omitString } from "@/lib/common";

type Props = {
  index: number;
  isLastIndex: boolean;
  row: any;
  startPos: number;
  totalNum: number;
  showItemEditor: (itemId?: string) => void;
  showSortEditor: (itemId?: string) => void;
  copyItemChiba: (index, data, callback?) => void;
  updateNote: (itemId, data, callback?) => void;
  updateDelivery: (itemId, data, callback?) => void;
  updateAttention: (itemId, data, callback?) => void;
  addItemPrev: (index: number) => void;
  addItemNext: (index: number) => void;
  deleteItem: (itemId, data, callback?) => void;
  bulkUpdateItemStatus: (itemId, data, callback?) => void;
  showAlert: (title, messages) => void;
  showTitleSubmenu: boolean;
  setTitleSubmenu: (itemId: number) => void;
  clearTitleSubmenu: () => void;
  clearProcessSubmenu: () => void;
  handleSearch: (option?: { keepPage?: boolean }) => void;
};

const LeftGridRowChiba: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const [editCommentId, setEditCommentId] = useState(null);

  const commentTextArea = useRef<HTMLTextAreaElement>();

  const beginEditComment = (id) => {
    setEditCommentId(id);
  };

  const endEditComment = (comment, row) => {
    if (100 < (comment ?? "").length) {
      const { showAlert } = props;
      showAlert(t("error"), [t("note") + t("is_too_long")]);
      commentTextArea.current?.focus();
      return;
    }

    const data = {
      note: comment,
      timestamp: row.timestamp,
    };

    props.updateNote(editCommentId, data, () => setEditCommentId(null));
  };

  const focusNote = _.debounce(() => {
    commentTextArea.current?.focus();
  }, 100);

  useEffect(() => {
    props.row.item_id === editCommentId && focusNote();
  }, [props.row, editCommentId]);

  const omitText = (text) => {
    if (text && text.length > 40) {
      return `${text.substr(0, 40)}...`;
    }

    return text;
  };

  const handleClickDelivery = (itemId, row) => {
    const data = {
      delivery_flg: !row.delivery_flg,
      timestamp: row.timestamp,
    };

    props.updateDelivery(itemId, data);
  };

  const handleClickAttention = (itemId, row) => {
    const data = {
      attention_flg: !row.attention_flg,
      timestamp: row.timestamp,
    };

    props.updateAttention(itemId, data);
  };

  const handleClickSubmenu = (e) => {
    props.clearProcessSubmenu();
    props.setTitleSubmenu(row.item_id);
    e.stopPropagation();
  };

  const handleClickContextMenu = (e, row, action) => {
    e.preventDefault();
    e.stopPropagation();

    if (action === "insert-prev") {
      props.addItemPrev(props.index);
      props.showItemEditor();
    } else if (action === "insert-next") {
      props.addItemNext(props.index);
      props.showItemEditor();
    } else if (action === "copy") {
      props.copyItemChiba(props.index, row);
    } else if (action === "delete") {
      props.deleteItem(row.item_id, row);
    } else if (action === "bulk-off") {
      props.bulkUpdateItemStatus(row.item_id, row, () => props.handleSearch({ keepPage: true }));
    } else if (action === "sort") {
      props.showSortEditor(row.item_id);
    }
    props.clearTitleSubmenu();
  };

  const { row, index, startPos } = props;
  const canCopy = isValidRole(row.roles, 2);
  const canEdit = isValidRole(row.roles, 0);
  const canDelete = isValidRole(row.roles, 3);
  const canBulkOff = isValidRole(row.roles, 4);
  const canAttentionUpdate = isValidRole(row.roles, 1);

  return (
    <React.Fragment>
      <tr>
        <td className={`${row.attention_flg ? "cell-yellow" : ""} ${canAttentionUpdate ? "grid-cell-clickable" : ""}`}>
          <div
            className="grid-cell-wrap"
            onClick={() => {
              if (canAttentionUpdate) {
                handleClickAttention(row.item_id, row);
              }
            }}
          >
            <div className="grid-cell-con">
              <div className="grid-cell-inner">{startPos + index}</div>
            </div>
          </div>
        </td>
        <td
          className={`grid-cell-delivery ${row.delivery_flg ? "is-on" : "is-off"} ${
            canAttentionUpdate ? "grid-cell-clickable" : ""
          }`}
          onClick={() => {
            if (canAttentionUpdate) {
              handleClickDelivery(row.item_id, row);
            }
          }}
        >
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner">
                <i className="grid-btn-delivery" />
              </div>
            </div>
          </div>
        </td>
        <td>
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner">{row.complete ? "●" : ""}</div>
            </div>
          </div>
        </td>
        <td>
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner">{row.totaltest_process ? "●" : ""}</div>
            </div>
          </div>
        </td>
        <td>
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">{row.area_name}</div>
            </div>
          </div>
        </td>
        <td>
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">{row.device_name}</div>
            </div>
          </div>
        </td>
        <td>
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">{row.primary_charge_name}</div>
            </div>
          </div>
        </td>
        <td>
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">{row.document_no}</div>
            </div>
          </div>
        </td>
        <td
          className={`grid-item_name ${canEdit ? "grid-cell-clickable" : ""}`}
          onClick={() => {
            if (canEdit) {
              props.showItemEditor(row.item_id);
            }
          }}
          onContextMenu={(e) => {
            if (!(canCopy || canDelete || canBulkOff)) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          <div className="grid-cell-wrap" style={{ overflow: "visible" }}>
            {canCopy || canDelete || canBulkOff ? (
              <React.Fragment>
                {props.showTitleSubmenu && (
                  <div
                    id={`matrix-subject-contextmenu-${row.item_id}`}
                    className="grid-context-menu context-menu"
                    style={{
                      left: "110px",
                      ...(props.index !== 0 && props.isLastIndex ? { bottom: 0 } : { top: "10px" }),
                    }}
                  >
                    {canCopy && (
                      <div className="menu-item" onClick={(e) => handleClickContextMenu(e, row, "copy")}>
                        {t("copy_row")}
                      </div>
                    )}
                    {canDelete && (
                      <div className="menu-item" onClick={(e) => handleClickContextMenu(e, row, "delete")}>
                        {t("delete_row")}
                      </div>
                    )}
                    {canBulkOff && (
                      <div className="menu-item" onClick={(e) => handleClickContextMenu(e, row, "bulk-off")}>
                        {t("off_all_task")}
                      </div>
                    )}
                    <div
                      className={clsx("menu-item", 1 < props.totalNum ? "" : "disabled")}
                      onClick={(e) => {
                        1 < props.totalNum && handleClickContextMenu(e, row, "sort");
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      {t("sort_row")}
                    </div>
                  </div>
                )}
                <div className="grid-cell-con">
                  <div className="grid-cell-inner txt-left p-5">
                    {omitString(row.item_name, 35)}
                    <div className="grid-icon-submenu-wrapper" onClick={handleClickSubmenu}>
                      <button className="grid-icon grid-icon-submenu icon-submenu" />
                    </div>
                    {row.file_status && <span className="grid-icon grid-icon-information icon-attach_file"></span>}
                    <button className="grid-icon grid-icon-edit icon-mode_edit"></button>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <div className="grid-cell-con">
                <div className="grid-cell-inner txt-left p-5">
                  {omitString(row.item_name, 35)}
                  {row.file_status && <span className="grid-icon grid-icon-information icon-attach_file"></span>}
                  <button className="grid-icon grid-icon-edit icon-mode_edit"></button>
                </div>
              </div>
            )}
          </div>
        </td>
        <LeftGridCell cellType={"special"} row={props.row} />
        <LeftGridCell cellType={"equip"} row={props.row} />
        <td>
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">{row.regulation}</div>
            </div>
          </div>
        </td>
        <td>
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">
                <ul>
                  {row &&
                    row.systems &&
                    row.systems.map((system, index) => {
                      return <li key={index}>{system.system_name}</li>;
                    })}
                </ul>
              </div>
            </div>
          </div>
        </td>
        {row.item_id === editCommentId ? (
          <td className="no-hover">
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner">
                  <textarea
                    className="grid-textarea txt-left p-5"
                    ref={commentTextArea}
                    onBlur={(e) => endEditComment(e.target.value, row)}
                    defaultValue={row.note}
                  ></textarea>
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className={canAttentionUpdate ? "grid-cell-clickable" : ""}
            onClick={() => {
              if (canAttentionUpdate) {
                beginEditComment(row.item_id);
              }
            }}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner txt-left p-5">
                  {omitText(row.note)}
                  {canAttentionUpdate && <button className="grid-icon grid-icon-edit icon-mode_edit"></button>}
                </div>
              </div>
            </div>
          </td>
        )}
        <td>
          <div className="grid-cell-wrap">
            <div className="grid-cell-con">
              <div className="grid-cell-inner txt-left p-5">{row.user_name}</div>
            </div>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default withTranslation()(LeftGridRowChiba);
