import React, { FC } from "react";
import Select from "react-select";

import styles from "../../constants/reactSelectStyle";

import { NONE_VALUE } from "@/constants";

type Props = {
  prefix?: string;
  disabled?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  options: any[];
  value?: string | number | string[] | number[];
  onChange: (_) => void;
  withNone?: boolean;
  noneLabel?: string;
  placeholder?: string | React.ReactNode;
};

const SelectBase: FC<Props> = ({
  prefix,
  disabled,
  isClearable,
  isMulti,
  options,
  value,
  onChange,
  withNone,
  noneLabel,
  placeholder,
}) => {
  const defaultOptions = withNone ? [{ value: NONE_VALUE, label: noneLabel }] : [];

  let optionsId = "id";
  let optionsName = "name";

  if (prefix) {
    optionsId = prefix + "_" + optionsId;
    optionsName = prefix + "_" + optionsName;
  }

  return (
    <Select
      styles={styles}
      options={defaultOptions.concat(
        options.map((item) => ({
          value: item[`${optionsId}`],
          label: item[`${optionsName}`],
        }))
      )}
      isDisabled={disabled}
      isClearable={isClearable}
      isMulti={isMulti}
      onChange={(e) => {
        if (isMulti) {
          // TODO Conditional type
          // @ts-ignore
          onChange(e ? e.map((item) => item.value) : []);
        } else {
          // @ts-ignore
          onChange(e ? e.value : 0);
        }
      }}
      value={((value) => {
        const values = Array.isArray(value) ? value : [value];

        if (withNone && values.includes(-1)) {
          return defaultOptions;
        }

        return options
          .filter((option) => values.some((val) => val === option[`${optionsId}`]))
          .map((option) => ({ value: option[`${optionsId}`], label: option[`${optionsName}`] }));
      })(value)}
      menuPortalTarget={document.querySelector("body")}
      placeholder={placeholder}
    />
  );
};

SelectBase.defaultProps = {
  disabled: false,
  isClearable: false,
  isMulti: false,
  withNone: false,
  noneLabel: "設定なし",
};

export default SelectBase;
