import actions from "../actions";
import { validateCondition } from "../lib/validate";

const validateInspection = (store) => (next) => (action) => {
  next(action);
  const state = store.getState();
  if (!(state.app.menu === "inspection" && state.construction.masters)) {
    return;
  }

  const {
    areaIds,
    deviceIds,
    categoryId,
    facilityManagementIds,
    constructionManagementIds,
    primaryChargeIds,
    otherIds,
    processMajorClassIds,
    checkpointIds,
    validated,
  } = state.inspection;

  if (validated) {
    return;
  }

  const {
    areas,
    devices,
    categories,
    facility_managements,
    construction_managements,
    primary_charges,
    others,
    process_major_classes,
    checkpoints,
  } = state.construction.masters;

  const {
    changeArea,
    changeDevice,
    changeCategory,
    changeFacilityManagement,
    changeConstructionManagement,
    changePrimaryCharge,
    changeOther,
    changeProcessMajorClass,
    changeCheckpoint,
    setValidated,
  } = actions.inspection;

  const validatedAreaId = validateCondition(areas, areaIds);
  if (validatedAreaId.length !== areaIds.length) {
    next(changeArea(validatedAreaId));
  }
  const validatedDeviceIds = validateCondition(devices, deviceIds);
  if (validatedDeviceIds.length !== deviceIds.length) {
    next(changeDevice(validatedDeviceIds));
  }

  const validatedCategoryIds = validateCondition(categories, categoryId);
  if (categoryId && !validatedCategoryIds.length) {
    next(changeCategory(null));
  }

  const validatedFacilityManagementIds = validateCondition(facility_managements, facilityManagementIds);
  if (validatedFacilityManagementIds.length !== facilityManagementIds.length) {
    next(changeFacilityManagement(validatedFacilityManagementIds));
  }
  const validatedConstructionManagements = validateCondition(construction_managements, constructionManagementIds);
  if (validatedConstructionManagements.length !== constructionManagementIds.length) {
    next(changeConstructionManagement(validatedConstructionManagements));
  }
  const validatedPrimaryChargeIds = validateCondition(primary_charges, primaryChargeIds);
  if (validatedPrimaryChargeIds.length !== primaryChargeIds.length) {
    next(changePrimaryCharge(validatedPrimaryChargeIds));
  }
  const validatedOtherIds = validateCondition(others, otherIds);
  if (validatedOtherIds.length !== otherIds.length) {
    next(changeOther(validatedOtherIds));
  }

  const validatedProcessMajorClassIds = validateCondition(process_major_classes, processMajorClassIds);
  if (validatedProcessMajorClassIds.length !== processMajorClassIds.length) {
    next(changeProcessMajorClass(validatedProcessMajorClassIds));
  }
  const validatedCheckpointIds = validateCondition(checkpoints, checkpointIds);
  if (validatedCheckpointIds.length !== checkpointIds.length) {
    next(changeCheckpoint(validatedCheckpointIds));
  }

  next(setValidated(true));

  return;
};

export default validateInspection;
