import React from "react";
import DatePicker from "react-datepicker";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  popperPlacement: "right" | "left";
}

export const DatePickerController = <T extends FieldValues>({ name, control, popperPlacement }: Props<T>) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <DatePicker
          className="!w-[150px] text-left text-[14px]"
          wrapperClassName={`
            !w-[150px]
            text-left
            text-[14px]
            [&_input]:!cursor-pointer
            [&_input]:border
            [&_input]:border-solid
            [&_input]:border-gray-300
            [&_input]:h-[35px]
            [&_input]:!w-[150px]
            [&_input]:!pl-[12px]
          `}
          selected={value}
          dateFormat="yyyy/MM/dd"
          locale={t("calender_locale")}
          onChange={onChange}
          showPopperArrow={false}
          popperPlacement={popperPlacement}
        />
      )}
    />
  );
};
