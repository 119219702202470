import actions from "@/actions";
import * as constants from "@/constants";
import environments from "@/environments";
import { FetchNotificationsProps, ReadNotificationProps } from "@/sx-layout/components/notification/actions/types";

export const clearLayoutNotifications = () => {
  return {
    type: constants.LAYOUT_NOTIFICATION_CLEAR_NOTIFICATIONS,
  };
};

export const fetchLayoutNotifications = (props: FetchNotificationsProps) => {
  const endpoint = `${environments.API_BASE_URI}/layout_notifications?notification_type=${props.input.notification_type}&start=1&limit=99999`;

  return (dispatch) => {
    const action = {
      type: constants.APP_CALL_API,
      endpoint,
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      callbacks: {
        begin: () => {
          return {
            type: constants.LAYOUT_NOTIFICATION_BEGIN_FETCH_NOTIFICATIONS,
          };
        },
        success: (response) => {
          dispatch(actions.notifications.fetchNotificationsSummary());
          if (props?.onSuccess !== undefined) {
            props.onSuccess(response);
          }

          return {
            type: constants.LAYOUT_NOTIFICATION_END_FETCH_NOTIFICATIONS,
            payload: response,
          };
        },
        error: (response) => {
          dispatch(actions.notifications.fetchNotificationsSummary());

          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    };
    dispatch(action);
  };
};

export const readLayoutNotification = (props: ReadNotificationProps) => {
  const endpoint = `${environments.API_BASE_URI}/layout_notifications/read/${props.input.layout_notification_id}`;

  return (dispatch) => {
    const action = {
      type: constants.APP_CALL_API,
      endpoint,
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      callbacks: {
        begin: () => {
          return {
            type: constants.LAYOUT_NOTIFICATION_BEGIN_READ_NOTIFICATION,
          };
        },
        success: (response) => {
          dispatch(actions.notifications.fetchNotificationsSummary());
          if (props?.onSuccess !== undefined) {
            props.onSuccess(response);
          }

          return {
            type: constants.LAYOUT_NOTIFICATION_END_READ_NOTIFICATION,
            payload: response,
          };
        },
        error: (response) => {
          dispatch(actions.notifications.fetchNotificationsSummary());

          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    };
    dispatch(action);
  };
};
