import { useEffect } from "react";

const useEvent = <K extends keyof DocumentEventMap>(
  eventType: K,
  fnc: (event: DocumentEventMap[K]) => void,
  deps: any[] = [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- 暫定対処でdisableしてごめんなさい
  removeFnc: () => void = () => {}
) => {
  useEffect(() => {
    document.addEventListener(eventType, fnc);

    return () => {
      document.removeEventListener(eventType, removeFnc);
    };
  }, [...deps]);
};

export { useEvent };
