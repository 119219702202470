import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePopper } from "react-popper";

import { Machine, MachineStatus } from "@/sx-layout/components/plotmap/models";

type Props = {
  referenceElement: HTMLElement;
  machine: Machine;
  scale: number;
  control: {
    isPast: boolean;
    isToday: boolean;
    isAdmin: boolean;
    isUser: boolean;
    isMachineFromOtherCompany: boolean;
    isDisableMachineStatusButton: boolean;
  };
  onEdit: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onDuplicate: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onDelete: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onCreateKeepOutArea: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onChangeStatus: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export const MachineSubMenu: FC<Props> = ({
  referenceElement,
  machine,
  scale,
  control,
  onEdit,
  onDuplicate,
  onDelete,
  onCreateKeepOutArea,
  onChangeStatus,
}) => {
  const { t } = useTranslation();

  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "right",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 20],
        },
      },
    ],
  });

  const position = attributes?.popper?.["data-popper-placement"];

  const isUserAndOwnCompany = control.isUser && !control.isMachineFromOtherCompany;
  if (!(control.isAdmin || isUserAndOwnCompany)) {
    return <></>;
  }

  return (
    referenceElement && (
      <div
        id="layout-target-submenu"
        className="z-10"
        style={{
          ...styles.popper,
          transform: `${styles.popper?.transform ?? ""} scale(${scale})`,
          transformOrigin:
            position === "left" ? "right" : position === "top" ? "bottom" : position === "bottom" ? "top" : "left",
        }}
        ref={setPopperElement}
        {...attributes.popper}
      >
        <div id="layout-target-submenu-arrow" data-popper-arrow />
        <div className="bg-[#f3f2ef] p-[5px] w-[140px] font-bold rounded-[5px]">
          <ul id={`machine-submenu-${machine.machine_id}`} className="bg-[#f3f2ef]">
            {!control.isPast && (control.isAdmin || isUserAndOwnCompany) ? (
              <li
                onClick={onEdit}
                className="bg-[#fff] hover:bg-[#fff8ee] cursor-pointer rounded flex items-center justify-center p-[5px] text-[13px] mb-[5px]"
              >
                {t("edit")}
              </li>
            ) : null}
            {control.isAdmin || isUserAndOwnCompany ? (
              <li
                onClick={onDuplicate}
                className={
                  `bg-[#fff] hover:bg-[#fff8ee] cursor-pointer rounded flex items-center justify-center p-[5px] text-[13px]` +
                  (control.isPast ? "" : " mb-[5px]")
                }
              >
                <button className="w-[100%]" onClick={onDuplicate}>
                  {t("duplicate")}
                </button>
              </li>
            ) : null}
            {!control.isPast && (control.isAdmin || isUserAndOwnCompany) ? (
              <li className="bg-[#fff] hover:bg-[#fff8ee] cursor-pointer rounded flex items-center justify-center p-[5px] text-[13px] mb-[5px]">
                <button
                  onClick={onCreateKeepOutArea}
                  className="w-[100%] disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={!!machine.keepout_area_id}
                >
                  {t("create_keep_out_area")}
                </button>
              </li>
            ) : null}
            {control.isToday && (control.isAdmin || isUserAndOwnCompany) ? (
              <li className="bg-[#fff] hover:bg-[#fff8ee] cursor-pointer rounded flex items-center justify-center p-[5px] text-[13px] mb-[5px]">
                <button
                  onClick={onChangeStatus}
                  className="w-[100%] disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={control.isDisableMachineStatusButton}
                >
                  {machine.status === MachineStatus.STAND_BY && t("resume_machine_operation")}
                  {machine.status === MachineStatus.RUNNING && t("complete_process")}
                </button>
              </li>
            ) : null}
            {!control.isPast && (control.isAdmin || isUserAndOwnCompany) ? (
              <li
                onClick={onDelete}
                className="bg-[#ffdddd] hover:bg-[#f8d0d0] cursor-pointer rounded flex items-center justify-center p-[5px] text-[13px]"
              >
                {t("delete")}
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    )
  );
};
