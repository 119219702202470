export const SCHEDULE_DATA_PER_PAGE = 20;

export const ContinuousUpdateStatus = {
  OFF: -1,
  CANCEL: 0,
  DO: 1,
  APPROVE: 2,
  DENY: 3,
};

export const ContinuousUpdateStatusImage = {
  [ContinuousUpdateStatus.OFF]: -1,
  [ContinuousUpdateStatus.CANCEL]: 0,
  [ContinuousUpdateStatus.DO]: 8,
  [ContinuousUpdateStatus.APPROVE]: 5,
  [ContinuousUpdateStatus.DENY]: 7,
};

export const ScheduleExportFile = {
  SCHEDULE: 1,
  CONSTRUCTION_NOTICE: 2,
};

export const SafetyInstructionNumbers = {
  "1": "①",
  "2": "②",
  "3": "③",
  "4": "④",
  "5": "⑤",
  "6": "⑥",
  "7": "⑦",
  "8": "⑧",
};

// 予定表1件更新対象
export const UPDATE_SCHEDULE_TARGET = [
  "field1", // 危険度ランク
  "field2", // 立会
  "note1", // 工事主管課コメント
  "note2", // 設備主管課コメント
];

// 予定表１件更新対象(タスク)
export const UPDATE_SCHEDULE_WITH_TASK_TARGET = [
  "field_t1", // 人数
  "field_t2", // 火気：裸火
  "field_t3", // 火気：一般火気
  "field_t4", // 火気：使用火気
  "field_t5", // 配管切断
  "field_t7", // 重機
  "field_t6", // 酸欠
  "field_t8", // 安全指示
  "field_t9", // 残業予定時間
];

// 予定表１件更新対象(作業)
export const UPDATE_SCHEDULE_WITH_PROCESS_TARGET = [
  "field_p1", // 施工会社
  "field_p2", // 個別安全確保事項：危険源
  "field_p3", // 個別安全確保事項：低減策
];

// 予定表1件承認対象
export const APPROVE_SCHEDULE_TARGET = [
  "approve1_status", // 工事主管課
  "approve2_status", // PL
  "approve3_status", // TL
  "approve4_status", // 設備主管課
];
