import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

const AlertDialog = ({ title, messages, okClickHandler, t, from, statusCode }) => {
  const onReload = () => {
    okClickHandler({ reload: true });
  };

  const shouldReload =
    statusCode &&
    statusCode === 400 &&
    messages.some((m: string) => {
      return m.includes("E0000");
    });

  return (
    <div className="modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-ttl">{t(title)}</h3>
          </div>
          <div className="modal-body modal-body-alert">
            {messages.map((message, i) => (
              <React.Fragment key={i}>
                {message.split("\n").map((line, i) => (
                  <React.Fragment key={i}>{line === "" ? <br /> : t(line)}</React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </div>
          <div className="modal-footer">
            {shouldReload ? (
              <button
                type="button"
                className={`btn ${from === "layout" ? "bg-[#dea321]" : "btn-blue"}`}
                onClick={onReload}
              >
                <img src="./img/icon-reload.svg" className="w-[20px] h-[16px] m-[3px]" />
                {t("reload")}
              </button>
            ) : (
              <button
                type="button"
                className={`btn ${from === "layout" ? "bg-[#dea321]" : "btn-blue"}`}
                onClick={okClickHandler}
              >
                {t("alert_ok")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

AlertDialog.defaultProps = {
  title: "alert",
  from: "",
};

AlertDialog.propTypes = {
  title: PropTypes.string,
  messages: PropTypes.array.isRequired,
  okClickHandler: PropTypes.func.isRequired,
  from: PropTypes.string,
};

export default withTranslation()(AlertDialog);
