import Konva from "konva";
import React, { useEffect, useMemo } from "react";
import { Arrow, Group, KonvaNodeEvents, Rect } from "react-konva";

import { FreeDrawArrow } from "@/sx-layout/components/plotmap/components/freeDraw/models";

type Props = Konva.ArrowConfig &
  KonvaNodeEvents & {
    freeDrawArrow: FreeDrawArrow;
    mapRatio: number;
    selected?: boolean;
    setSelectedRect: (_: { x: number; y: number; width: number; height: number }) => void;
  };

export const ArrowObject = ({
  freeDrawArrow,
  mapRatio,
  selected,
  draggable,
  setSelectedRect,
  onClick,
  onMouseDown,
  onDragStart,
  onDragMove,
  onDragEnd,
}: Props) => {
  const {
    x,
    y,
    width,
    height,
    arrowPoints,
    marginX,
    marginY,
  }: {
    x: number;
    y: number;
    width: number;
    height: number;
    arrowPoints: number[];
    marginX: number;
    marginY: number;
  } = useMemo(() => {
    if (!freeDrawArrow)
      return {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        arrowPoints: [],
        marginX: 0,
        marginY: 0,
      };
    const coords = freeDrawArrow.coords.map((c) => [c[0] * mapRatio, c[1] * mapRatio]);
    const xs = coords.map((p) => p[0]);
    const ys = coords.map((p) => p[1]);
    const maxX = Math.max(...xs);
    const maxY = Math.max(...ys);
    const minX = Math.min(...xs);
    const minY = Math.min(...ys);

    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
      arrowPoints: coords.map((p) => [p[0] - minX, p[1] - minY]).flat(),
      marginX: freeDrawArrow.thickness + (maxX - minX < 10 ? 5 : 0),
      marginY: freeDrawArrow.thickness + (maxY - minY < 10 ? 5 : 0),
    };
  }, [freeDrawArrow, mapRatio]);

  useEffect(() => {
    if (selected && x + y + width + height !== 0) {
      setSelectedRect({ x, y, width, height });
    }
  }, [x, y, width, height, selected]);

  return (
    <Group
      x={x}
      y={y}
      width={width}
      height={height}
      draggable={draggable}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onDragStart={onDragStart}
      onDragMove={onDragMove}
      onDragEnd={onDragEnd}
    >
      {selected && (
        <Rect
          x={-marginX}
          y={-marginY}
          width={width + marginX * 2}
          height={height + marginY * 2}
          strokeWidth={2}
          stroke="#dea321"
          dash={[5, 5]}
        />
      )}
      <Arrow
        fill={freeDrawArrow.color}
        stroke={freeDrawArrow.color}
        strokeWidth={freeDrawArrow.thickness}
        hitStrokeWidth={freeDrawArrow.thickness + 10}
        points={arrowPoints}
        shadowColor={selected ? "black" : undefined}
        shadowBlur={selected ? 5 : undefined}
        shadowOpacity={selected ? 0.7 : undefined}
      />
    </Group>
  );
};
