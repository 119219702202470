import moment from "moment/moment";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { downloadSchedules } from "@/components/list/Schedule/actions/schedule";
import { ScheduleExportSettingDialog } from "@/components/list/Schedule/components/ScheduleExportSettingDialog";
import { ScheduleExportFile } from "@/components/list/Schedule/constants";
import { UseSearchInputType } from "@/components/list/Schedule/hooks/useSearchInput";
import { ScheduleExportSetting, SearchInput } from "@/components/list/Schedule/types";
import { ScheduleFilterParams } from "@/components/list/Schedule/types/api";
import { downloadFile, getConstructionId, getUserId } from "@/lib/common";

type Props = {
  scheduleDate: Date;
  condition: UseSearchInputType;
};

export const ScheduleExport: FC<Props> = ({ scheduleDate, condition }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showItem, setShowItem] = useState(false);

  const [showSetting, setShowSetting] = useState(false);

  const openScheduleSettingDialog = () => {
    setShowItem(false);
    setShowSetting(true);
  };

  const downloadCallback = (blob, filename) => {
    const fname = !filename
      ? `schedule_${getUserId()}_${getConstructionId()}_${moment().format("YYYY-MM-DD_HHmmssSSS")}.xlsx`
      : filename;
    if (window.navigator?.["msSaveOrOpenBlob"]) {
      window.navigator["msSaveOrOpenBlob"](blob, fname);
    } else {
      downloadFile(blob, filename);
    }
  };

  const removeEmptyValue = (v?: string[]) => (!v?.length ? undefined : v);

  const toDownloadRequest = (searchFilter: SearchInput): ScheduleFilterParams => {
    const filter = [searchFilter.approve1_unprocessed ? 1 : null, searchFilter.approve4_unprocessed ? 2 : null].filter(
      (v) => v
    );

    return {
      schedule_date: moment(scheduleDate).format("YYYY-MM-DD"),
      category_id: removeEmptyValue(searchFilter.category_id),
      area_id: removeEmptyValue(searchFilter.area_id),
      device_id: removeEmptyValue(searchFilter.device_id),
      manager_user_id: removeEmptyValue(searchFilter.manager_user_id),
      field_p1: searchFilter.field_p1,
      field1: removeEmptyValue(searchFilter.field1),
      field2: searchFilter.field2,
      approve1_user_id: removeEmptyValue(searchFilter.approve1_user_id),
      approve4_user_id: removeEmptyValue(searchFilter.approve4_user_id),
      field_t9: searchFilter.field_t9 ? true : undefined,
      filter: !filter.length ? undefined : filter,
    };
  };

  const handleExportSchedule = (setting?: ScheduleExportSetting) => {
    setShowSetting(false);

    // 数値項目かつ.0を持つ値の場合、整数値に変換
    setting &&
      Object.entries(setting).forEach(([key, value]) => {
        setting[key] = Number.isSafeInteger(-value) ? Number.parseInt(value).toString() : value;
      });

    dispatch(
      downloadSchedules(
        {
          filetype: setting ? ScheduleExportFile.SCHEDULE : ScheduleExportFile.CONSTRUCTION_NOTICE,
          format: "excel",
          ...setting,
          ...toDownloadRequest(condition.values()),
        },
        downloadCallback
      )
    );
  };

  return (
    <div className="relative" onMouseLeave={() => setShowItem(false)}>
      <button
        data-test-id="button-summary-file-export"
        className="btn btn-light-blue btn-dropdown w-[170px]"
        onClick={() => setShowItem(true)}
      >
        <span className="icon icon-get_app" />
        {t("file_export")}
      </button>
      {showItem && (
        <div className="dropdown-box">
          <div onClick={openScheduleSettingDialog}>
            <img src="./img/icon_xls.svg" alt="Excel" className="icon-file" />
            {t("schedule_list")}
          </div>
          <div onClick={() => handleExportSchedule()}>
            <img src="./img/icon_xls.svg" alt="Excel" className="icon-file" />
            {t("construction_notice_memo")}
          </div>
        </div>
      )}
      {showSetting && (
        <ScheduleExportSettingDialog onClose={() => setShowSetting(false)} onExport={handleExportSchedule} />
      )}
    </div>
  );
};
