import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { withTranslation } from "react-i18next";

import { SubmitButton } from "@/components/common/SubmitButton";

const ConfirmDialog = ({ title, messages, okClickHandler, cancelClickHandler, t, id, styleSxLayout }) => {
  // ダブルクリックできないようにする
  // 共通のコンポーネントは呼び出し元でloadingを渡すと実装コストがかかるのでコンポーネントで実装
  const [loading, setLoading] = React.useState(false);
  const clearLoading = useMemo(() => _.debounce(() => setLoading(false), 1000), [setLoading]);
  const handleConfirm = () => {
    if (loading) return;
    setLoading(true);
    okClickHandler();
    clearLoading();
  };

  return (
    <div className="modal">
      <div className="modal-dialog">
        <div className="modal-content" id={id}>
          <div className="modal-header">
            <h3 className="modal-ttl">{t(title)}</h3>
          </div>
          <div className="modal-body modal-body-confirm">
            {messages.map((message, index) => (
              <React.Fragment key={index}>
                {/* {t(message)} */}
                {message.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line === "" ? (
                      // 空文字列の場合は改行する
                      <br />
                    ) : line.startsWith("*") && line.endsWith("*") ? (
                      // *で囲っている場合は細字にする
                      <span className="font-thin">{t(line.replaceAll("*", ""))}</span>
                    ) : (
                      t(line)
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </div>
          <div className="modal-footer">
            <button
              data-test-id="button-confirm-cancel"
              type="button"
              className="btn btn-gray"
              onClick={cancelClickHandler}
            >
              {t("confirm_dismiss")}
            </button>
            <SubmitButton data-test-id="button-confirm-ok" onClick={handleConfirm} loading={loading}>
              {t("confirm_accept")}
            </SubmitButton>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmDialog.defaultProps = {
  title: "confirmation",
};

ConfirmDialog.propTypes = {
  title: PropTypes.string,
  messages: PropTypes.array.isRequired,
  okClickHandler: PropTypes.func.isRequired,
  cancelClickHandler: PropTypes.func.isRequired,
  id: PropTypes.string,
  styleSxLayout: PropTypes.bool,
};

export default withTranslation()(ConfirmDialog);
