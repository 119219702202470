import clsx from "clsx";
import React, { CSSProperties, FC, ReactNode } from "react";

type Props = {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  editable?: boolean;
  underColor?: "green" | "red";
  onClick?: () => void;
};

export const GridHeader: FC<Props> = ({ children, className, style, editable, underColor, onClick }) => {
  return (
    <div className={clsx(className, editable ? "editable" : "")} onClick={editable ? onClick : undefined} style={style}>
      {children}
      {underColor && <span className={`label-${underColor}`} />}
    </div>
  );
};
