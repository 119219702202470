import PropTypes from "prop-types";
import React, { Component } from "react";

class FileItemImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLightbox: false,
      lightboxSrc: "",
    };

    this.handleCloseLightbox = this.handleCloseLightbox.bind(this);
    this.handleOpenLightbox = this.handleOpenLightbox.bind(this);
  }

  handleOpenLightbox(lightboxSrc) {
    this.setState({
      showLightbox: true,
      lightboxSrc,
    });
  }

  handleCloseLightbox() {
    this.setState({ showLightbox: false });
  }

  render() {
    const {
      file,
      disabled,
      upload_data,
      onChange,
      categories,
      primaryCharges,
      filetypes,
      statuses,
      checked_flg,
      hrefFile,
      hrefError,
    } = this.props;
    const download = false;
    const href = "";
    const img = null;
    const format = "";
    let category_name = "";
    let primary_charge_name = "";
    let filetype_name = "";
    let status_name = "";
    let importDate = "";
    let importDateConv = "";
    const className_chk = upload_data.status !== 2 && upload_data.status !== 3 ? "ckbox disp-non" : "ckbox";
    const className_status = upload_data.status === 2 ? "txt-center cell-orange" : "txt-center";
    const category = categories.filter((category) => category.category_id === upload_data.category_id);
    for (const key in category) {
      category_name = category[key].category_name;
    }
    const primaryCharge = primaryCharges.filter((p) => p.primary_charge_id === upload_data.primary_charge_id);
    for (const key in primaryCharge) {
      primary_charge_name = primaryCharge[key].primary_charge_name;
    }
    const filetype = filetypes.filter((filetype) => filetype.type_id === upload_data.file_type);
    for (const key in filetype) {
      filetype_name = filetype[key].type_name;
    }
    const status = statuses.filter((status) => status.status_id === upload_data.status);
    for (const key in status) {
      status_name = status[key].status_name;
    }

    if (upload_data.import_date) {
      importDate = new Date(upload_data.import_date);
      importDateConv =
        importDate.getFullYear() +
        "/" +
        ("0" + (importDate.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + importDate.getDate()).slice(-2) +
        " " +
        ("0" + importDate.getHours()).slice(-2) +
        ":" +
        ("0" + importDate.getMinutes()).slice(-2) +
        ":" +
        ("0" + importDate.getSeconds()).slice(-2);
    }

    return (
      <React.Fragment>
        <tr>
          <td className="txt-center">
            <label className={className_chk}>
              <input type="checkbox" checked={checked_flg} onChange={onChange} />
              <span></span>
            </label>
          </td>
          <td>
            {file.temp && file.progress < 100 && (
              <span className="progress-container">
                <span className="progress-value" style={{ width: `${file.progress}%` }}></span>
              </span>
            )}
            {file.temp && file.progress === 100 && (
              <React.Fragment>
                <a href={hrefFile + upload_data.request_id}>{file.file_name}</a>
              </React.Fragment>
            )}
            {!file.temp && (
              <React.Fragment>
                <span className="img-area">{img}</span>
                {format === "img" ? (
                  <span className="file-name">
                    <a disabled={disabled} onClick={() => this.handleOpenLightbox(href)}>
                      {file.file_name}
                    </a>
                  </span>
                ) : (
                  <span className="file-name">
                    <a disabled={disabled} href={href} download={download}>
                      {file.file_name}
                    </a>
                  </span>
                )}
              </React.Fragment>
            )}
          </td>
          {file.error ? (
            <td className="txt-error" colSpan="7">
              {file.errmsg}
            </td>
          ) : (
            <React.Fragment>
              <td className="txt-center">{category_name}</td>
              <td className="txt-center">{primary_charge_name}</td>
              <td className="txt-center">{filetype_name}</td>
              <td className="txt-center">{importDateConv}</td>
              <td className="txt-center">{upload_data.import_user_name}</td>
              <td className={className_status}>{status_name}</td>
              <td className="txt-center">
                <a href={hrefError + upload_data.request_id}>
                  {upload_data.status === 2 || upload_data.status === 3 ? "出力" : ""}
                </a>
              </td>
            </React.Fragment>
          )}
        </tr>
      </React.Fragment>
    );
  }
}

FileItemImport.defaultProps = {
  disabled: false,
};

FileItemImport.proptypes = {
  disabled: PropTypes.bool,
  file: PropTypes.object.isRequired,
};

export default FileItemImport;
