import clsx from "clsx";
import format from "date-fns/format";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { HolidayTable } from "@/components/list/Holiday/components/HolidayTable";
import { SearchBox } from "@/components/list/Holiday/components/SearchBox";
import { SummaryTable } from "@/components/list/Holiday/components/SummaryTable";
import { RootState } from "@/reducers/types";

export const Holiday: React.FC = () => {
  const { t } = useTranslation();

  // 工事の期間
  const masters = useSelector((state: RootState) => state.construction.masters);
  const [constructionStartDate, constructionEndDate] = useMemo(() => {
    if (!masters) return [new Date(), new Date()];

    return [new Date(masters.construction_start_date), new Date(masters.construction_end_date)];
  }, [masters]);

  const [constructionStartLabel, constructionEndLabel] = useMemo(() => {
    if (!constructionStartDate || !constructionEndDate) return ["", ""];
    const constructionStart = new Date(constructionStartDate);
    const constructionEnd = new Date(constructionEndDate);

    return [
      format(constructionStart, "yyyy年M月d日"),
      format(
        constructionEnd,
        constructionStart.getFullYear() === constructionEnd.getFullYear() ? "M月d日" : "yyyy年M月d日"
      ),
    ];
  }, [constructionStartDate, constructionEndDate]);
  const { fetching, unworkDayGroups } = useSelector((state: RootState) => state.holiday);

  // 検索条件 表示/非表示
  const [showSearch, setShowSearch] = useState(true);
  const toggleSearch = useCallback(() => {
    setShowSearch(!showSearch);
  }, [showSearch]);

  // 左右のどちらかのテーブルでスクロールしたら片方もスクロールする
  const summaryTableRef = useRef<HTMLDivElement>();
  const holidayTableRef = useRef<HTMLDivElement>();
  const syncScrollBySummary = (scrollTop: number) => {
    if (holidayTableRef.current) {
      holidayTableRef.current.scrollTop = scrollTop;
    }
  };
  const syncScrollByHoliday = (scrollTop: number) => {
    if (summaryTableRef.current) {
      summaryTableRef.current.scrollTop = scrollTop;
    }
  };

  if (!masters) return <></>;

  return (
    <div className="inner schedule-container">
      {/* ヘッダ */}
      <div className="tbl-top-area clearfix">
        <div className="tbl-top">
          <h1 className="page-ttl">
            {t("unwork_days") + t("settings")}
            <span
              data-test-id="button-holiday-toggle-search"
              className={`toggle icon-keyboard_arrow_up ${!showSearch ? "closed" : ""}`}
              onClick={() => toggleSearch()}
            />
          </h1>
        </div>
      </div>
      {/* 検索ボックス */}
      <div className={clsx("search-box-transition", showSearch ? "" : "h-0")}>
        <SearchBox />
      </div>
      <div className={clsx("grow flex flex-col", fetching ? "loading" : "")}>
        {0 < unworkDayGroups?.length ? (
          <>
            {/* 工事期間 */}
            <div className="list-title mt-15 mb-5">
              {t("construction") + t("term")}：{constructionStartLabel}〜{constructionEndLabel}
            </div>
            {/* 一覧 */}
            <div className="holiday-container">
              <SummaryTable ref={summaryTableRef} onScroll={syncScrollBySummary} />
              <HolidayTable
                ref={holidayTableRef}
                onScroll={syncScrollByHoliday}
                startDate={constructionStartDate}
                endDate={constructionEndDate}
              />
            </div>
          </>
        ) : unworkDayGroups ? (
          <p className={clsx("py-[20px]", fetching ? "hidden" : "")}>{t("no_data")}</p>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
