import actions from "../actions";
import { validateCondition } from "../lib/validate";

import { RootState } from "@/reducers/types";

const validateMatrix = (store) => (next) => (action) => {
  next(action);
  const state: RootState = store.getState();
  if (!((state.app.menu === "progress" || state.app.menu === "matrix") && state.construction.masters)) {
    return;
  }

  const {
    categoryId,
    primaryChargeId,
    areaIds,
    deviceIds,
    facilityManagementIds,
    constructionManagementIds,
    otherIds,
    systemIds,
    userIds,
    groupIds,
    validRequired,
  } = state.matrixSearch;

  if (typeof validRequired !== "undefined") {
    return;
  }

  const {
    categories,
    primary_charges,
    categories_primary_charges,
    areas,
    devices,
    facility_managements,
    construction_managements,
    others,
    systems,
    users,
    groups,
  } = state.construction.masters;

  const {
    changeCategory,
    changePrimaryCharge,
    changeArea,
    changeDevice,
    changeFacilityManagement,
    changeConstructionManagement,
    changeOther,
    changeSystem,
    changeUser,
    changeGroup,
    setValidRequired,
  } = actions.matrixSearch;

  const validatedAreaId = validateCondition(areas, areaIds);
  if (validatedAreaId.length !== areaIds.length) {
    next(changeArea(validatedAreaId));
  }
  const validatedDeviceIds = validateCondition(devices, deviceIds);
  if (validatedDeviceIds.length !== deviceIds.length) {
    next(changeDevice(validatedDeviceIds));
  }
  const validatedFacilityManagementIds = validateCondition(facility_managements, facilityManagementIds);
  if (validatedFacilityManagementIds.length !== facilityManagementIds.length) {
    next(changeFacilityManagement(validatedFacilityManagementIds));
  }
  const validatedConstructionManagements = validateCondition(construction_managements, constructionManagementIds);
  if (validatedConstructionManagements.length !== constructionManagementIds.length) {
    next(changeConstructionManagement(validatedConstructionManagements));
  }
  const validatedOtherIds = validateCondition(others, otherIds);
  if (validatedOtherIds.length !== otherIds.length) {
    next(changeOther(validatedOtherIds));
  }
  const validatedSystemIds = validateCondition(systems, systemIds);
  if (validatedSystemIds.length !== systemIds.length) {
    next(changeSystem(validatedSystemIds));
  }
  // changeCompany(validateCondition(companies, [companyId])[0]);
  const validatedUserIds = validateCondition(users, userIds);
  if (validatedUserIds.length !== userIds.length) {
    next(changeUser(validatedUserIds));
  }
  const validatedGroupIds = validateCondition(groups, groupIds);
  if (validatedGroupIds.length !== groupIds.length) {
    next(changeGroup(validatedGroupIds));
  }

  const validatedCategoryId = Boolean(validateCondition(categories, categoryId).length);
  if (!validatedCategoryId && categories.length > 0) {
    const validCategoryId = categories[0].category_id;
    next(changeCategory(validCategoryId));
  }
  const categoryPrimaryCharge = categories_primary_charges.find((c) => c.category_id === categoryId);
  const fixedPrimaryChargeId =
    categoryPrimaryCharge?.primary_charges?.find((p) => p.primary_charge_id === primaryChargeId)?.primary_charge_id ??
    0;
  const validatedPrimaryChargeId = Boolean(validateCondition(primary_charges, fixedPrimaryChargeId).length);
  if (!validatedPrimaryChargeId && primary_charges.length > 0) {
    const validPrimaryChargeId = primary_charges[0].primary_charge_id;
    next(changePrimaryCharge(validPrimaryChargeId));
  }
  next(setValidRequired(validatedCategoryId && validatedPrimaryChargeId));

  return;
};

export default validateMatrix;
