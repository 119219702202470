import { connect } from "react-redux";

import actions from "../../actions";
import InspectionGridRow from "../../components/list/InspectionGridRow";

const mapStateToProps = (state) => {
  let areas = [];
  let devices = [];
  let categories = [];
  let facilityManagements = [];
  let constructionManagements = [];
  let primaryCharges = [];
  let others = [];
  let processMajorClasses = [];
  let checkpoints = [];
  let systems = [];
  let companies = [];
  let users = [];
  let groups = [];
  let userRole;

  if (state.construction.masters) {
    areas = state.construction.masters.areas;
    devices = state.construction.masters.devices;
    categories = state.construction.masters.categories;
    facilityManagements = state.construction.masters.facility_managements;
    constructionManagements = state.construction.masters.construction_managements;
    primaryCharges = state.construction.masters.primary_charges;
    others = state.construction.masters.others;
    processMajorClasses = state.construction.masters.process_major_classes;
    checkpoints = state.construction.masters.checkpoints;
    systems = state.construction.masters.systems;
    companies = state.construction.masters.companies;
    users = state.construction.masters.users;
    groups = state.construction.masters.groups;
    userRole = state.construction.masters.user_role;
  }

  return {
    masters: {
      areas,
      devices,
      categories,
      facilityManagements,
      constructionManagements,
      primaryCharges,
      others,
      processMajorClasses,
      checkpoints,
      systems,
      companies,
      users,
      groups,
      userRole,
    },
    switcherStatus: state.inspection.switcherStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeTaskStatus: (item_id, task, status) => {
      dispatch(actions.common.changeTaskStatus(item_id, task, status));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InspectionGridRow);
