import { zodResolver } from "@hookform/resolvers/zod";
import moment, { Moment } from "moment";
import { ComponentProps, FC, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as z from "zod";

import { RootState } from "@/reducers/types";
import { Button } from "@/sx-layout/common/Button";
import { FormError, FormLabel, FormRow } from "@/sx-layout/common/Form";
import { DatePickerController } from "@/sx-layout/common/Form/DatePickerController";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "@/sx-layout/common/Modal";

type CopyForm = {
  date_from: Date;
  date_to: Date;
};

export type CopyMachineKeepOutAreaModal = ComponentProps<typeof Modal> & {
  onSubmit: ({ layoutDateFrom, layoutDateTo }: { layoutDateFrom: string; layoutDateTo: string }) => void;
  onClose: () => void;
};

const today = moment();

export const CopyFreeDrawModal: FC<CopyMachineKeepOutAreaModal> = ({ onClose, onSubmit }) => {
  const { t } = useTranslation();
  const layoutDate = useSelector<RootState, Moment>((state) => moment(state.plotmap.layoutDate, "day"));

  const dateValidation = useCallback((date) => {
    if (!date) return false;
    const value = moment(date, "day");

    return value.isSameOrAfter(today, "day") && value.isAfter(layoutDate, "day");
  }, []);
  const formSchema = z
    .object({
      // From・Toともに、操作日の当日以降かつ表示中の配置日より後の日付を指定すること
      date_from: z
        .date()
        .nullish()
        .refine(dateValidation, { message: t("duplicate_error") }),
      date_to: z
        .date()
        .nullish()
        .refine(dateValidation, { message: t("duplicate_error") }),
    })
    // From <= Toであること
    .refine((data) => moment(data.date_from, "day").isSameOrBefore(moment(data.date_to, "day")), {
      message: t("duplicate_error"),
      path: ["date_from"],
    })
    // 最大60日間コピーが可能
    .refine((data) => moment(data.date_to, "day").diff(moment(data.date_from, "day"), "days") + 1 <= 60, {
      message: t("duplicate_calendar_range_validation"),
      path: ["date_from"],
    });

  const defaultDate = useMemo(
    () => (layoutDate.isBefore(today, "day") ? today.toDate() : layoutDate.clone().add(1, "day").toDate()),
    [layoutDate]
  );
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CopyForm>({
    resolver: zodResolver(formSchema),
    reValidateMode: "onSubmit",
    defaultValues: {
      date_from: defaultDate,
      date_to: defaultDate,
    },
  });

  const submit = (values: CopyForm) => {
    const formatDate = (date: Date) => moment(date).format("YYYY-MM-DD").toString();
    onSubmit({
      layoutDateFrom: formatDate(values.date_from),
      layoutDateTo: formatDate(values.date_to),
    });
    onClose();
  };

  return (
    <Modal id="free-draw-copy-modal" onClose={onClose} shouldCloseOnEsc={false} shouldCloseOnOverlayClick={false}>
      <ModalHeader>{t("duplicate")}</ModalHeader>

      <ModalBody className="flex-col w-500 min-h-[150px] h-full items-start p-0 mt-[30px] mb-[30px]" size="small">
        <FormRow
          className="mt-0"
          errorComponent={
            <>
              {(errors.date_from?.message || errors.date_to?.message) && (
                <FormError>{errors.date_from?.message ?? errors.date_to?.message}</FormError>
              )}
            </>
          }
        >
          <FormLabel className="w-[100px] mr-[10px]">{t("duplicate_direction")}</FormLabel>
          <DatePickerController name={"date_from"} control={control} popperPlacement="right" />
          &nbsp;〜&nbsp;
          <DatePickerController name={"date_to"} control={control} popperPlacement="left" />
        </FormRow>
      </ModalBody>

      <ModalFooter>
        <Button buttonType="cancel" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button buttonType="save" onClick={handleSubmit(submit)}>
          {t("save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
