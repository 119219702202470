import clsx from "clsx";
import React, { FC, ReactNode, useEffect, useMemo, useState } from "react";

import { ContinuousUpdateStatus, ContinuousUpdateStatusImage } from "@/components/list/Schedule/constants";
import { ContinuousUpdateStatusType } from "@/components/list/Schedule/types";

type Props = {
  children?: ReactNode;
  label?: string;
  className?: string;
  align?: "center" | "left";
  editable?: boolean;
  setTooltip?: (text?: string) => void;
  disableContinuousUpdate?: boolean;
  continuousUpdateStatus?: ContinuousUpdateStatusType;
  isLoading?: boolean;
  onClick?: () => void;
  editing?: boolean;
  onEnter?: () => void;
  // テキスト入力でエラーがある場合はフォーカスを外さないように制御するためonBlurにElementを渡す
  onBlur?: (value: string, e: HTMLTextAreaElement) => void;
};

export const GridCell: FC<Props> = ({
  children,
  label,
  className,
  align,
  editable,
  setTooltip,
  disableContinuousUpdate,
  continuousUpdateStatus,
  isLoading,
  onClick,
  editing,
  onEnter,
  onBlur,
}) => {
  const isOverflow = useMemo(() => isOver30Characters(label) || isOver4Lines(label), [label]);
  const isContinuousUpdateMode =
    continuousUpdateStatus !== undefined && continuousUpdateStatus !== ContinuousUpdateStatus.OFF;

  const [text, setText] = useState(label);
  useEffect(() => {
    if (!onEnter) return;
    setText(label);
  }, [label]);

  return (
    <>
      {isLoading ? (
        <div className={className} style={{ backgroundColor: "#7f7f7f" }}>
          <div className="continuous-update-loading" />
        </div>
      ) : editing ? (
        <div className={clsx(className, "note-editor")}>
          <textarea
            draggable={false}
            value={text}
            onChange={(e) => setText(e.target.value)}
            onBlur={(e) => onBlur(text, e.currentTarget)}
            autoFocus={true}
          />
        </div>
      ) : (
        <div
          data-tip
          data-for="schedule-cell-tooltip"
          className={clsx(
            className,
            "grid-cell",
            align ?? "center",
            isContinuousUpdateMode
              ? !editable || disableContinuousUpdate
                ? "schedule-cursor-nix"
                : `continuous-editable schedule-cursor-${ContinuousUpdateStatusImage[continuousUpdateStatus]}`
              : editable
              ? "editable"
              : "cursor-default"
          )}
          onMouseOver={() => isOverflow && setTooltip(label)}
          onMouseLeave={() => isOverflow && setTooltip(null)}
          onClick={() => {
            if (editable && (!isContinuousUpdateMode || !disableContinuousUpdate)) {
              onClick?.();
              onEnter?.();
            }
          }}
        >
          {children ? children : <>{substringValue(label)}</>}
        </div>
      )}
    </>
  );
};

const MAX_LINES = 4;
const ELLIPSIS = "...";

const isOver30Characters = (value?: string) => {
  return 30 < (value ?? "").length;
};

const isOver4Lines = (value?: string) => {
  // 改行を含む場合、4行まで表示できる
  return MAX_LINES <= (value ?? "").match(/\n/g)?.length;
};

const substringValue = (value?: string) => {
  const v = value ?? "";
  if (isOver4Lines(v)) {
    return value.split("\n").splice(0, MAX_LINES).join("\n") + ELLIPSIS;
  }
  if (isOver30Characters(v)) {
    return value.substring(0, 30) + ELLIPSIS;
  }

  return v;
};
