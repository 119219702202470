import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

const QrGridHeader = (props) => {
  const { t } = props;

  return (
    <table className="tbl-basic tbl-data qr-table-row">
      <thead>
        <tr>
          <th>{t("area")}</th>
          <th>{t("device")}</th>
          <th>{t("facility_management")}</th>
          <th>{t("construction_management")}</th>
          <th>{t("primary_charge")}</th>
          <th>{t("other")}</th>
          <th>{t("machines_category")}</th>
          <th>{t("title_sum")}</th>
          <th>{t("qr_code")}</th>
        </tr>
      </thead>
    </table>
  );
};

QrGridHeader.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(QrGridHeader);
