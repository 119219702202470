import * as constants from "../constants/index";

const construction_management = (
  state = {
    text: "",
    masterItems: [],
    items: [],
    current: null,
    fetching: false,
    isError: false,
  },
  action
) => {
  switch (action.type) {
    case constants.CONSTRUCTION_MANAGEMENT_BEGIN_FETCH_ITEMS: {
      return {
        ...state,
        fetching: true,
        isError: false,
      };
    }
    case constants.CONSTRUCTION_MANAGEMENT_END_FETCH_ITEMS: {
      return {
        ...state,
        masterItems: action.payload,
        items: action.payload,
        fetching: false,
      };
    }
    case constants.CONSTRUCTION_MANAGEMENT_CHANGE_SEARCH_TEXT: {
      return {
        ...state,
        text: action.payload,
      };
    }
    case constants.CONSTRUCTION_MANAGEMENT_BEGIN_SEARCH_ITEMS: {
      return {
        ...state,
        fetching: true,
        isError: false,
      };
    }
    case constants.CONSTRUCTION_MANAGEMENT_END_SEARCH_ITEMS: {
      return {
        ...state,
        items: action.payload,
        fetching: false,
      };
    }
    case constants.CONSTRUCTION_MANAGEMENT_END_FETCH_ITEM: {
      return {
        ...state,
        current: action.payload,
      };
    }
    case constants.CONSTRUCTION_MANAGEMENT_END_CREATE_ITEM: {
      const masterItems = [...state.masterItems, action.payload];
      const items = masterItems.filter((item) => item.construction_management_name.indexOf(state.text) >= 0);

      return {
        ...state,
        masterItems,
        items,
      };
    }
    case constants.CONSTRUCTION_MANAGEMENT_END_UPDATE_ITEM: {
      const masterItems = state.masterItems.map((item) => {
        if (item.construction_management_id === action.payload.construction_management_id) {
          return action.payload;
        }

        return item;
      });

      const items = masterItems.filter((item) => item.construction_management_name.indexOf(state.text) >= 0);

      return {
        ...state,
        masterItems,
        items,
      };
    }
    case constants.CONSTRUCTION_MANAGEMENT_END_MERGE_ITEM: {
      const masterItems = state.masterItems.filter(
        (item) => item.construction_management_id != action.payload.construction_management_id
      );
      const items = masterItems;

      return {
        ...state,
        masterItems,
        items,
      };
    }
    case constants.CONSTRUCTION_MANAGEMENT_BEGIN_DELETE_ITEM: {
      return {
        ...state,
        fetching: true,
        isError: false,
      };
    }
    case constants.CONSTRUCTION_MANAGEMENT_END_DELETE_ITEM: {
      return {
        ...state,
        masterItems: state.masterItems.filter((item) => item.construction_management_id !== action.payload.id),
        items: state.items.filter((item) => item.construction_management_id !== action.payload.id),
        fetching: false,
      };
    }
    case constants.APP_CHANGE_MENU: {
      return {
        ...state,
        text: "",
        items: [...state.masterItems],
      };
    }
    case constants.APP_SHOW_ERROR: {
      if (action.errorFrom === "construction_management") {
        return {
          ...state,
          fetching: false,
          isError: true,
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export default construction_management;
