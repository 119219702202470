import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

type Props = {
  isSelected?: boolean;
  onClick: () => void;
};

const CancelReportPill: React.FC<Props> = ({ isSelected, ...props }) => {
  const { t } = useTranslation();
  const notReadTaskCancel = useSelector(
    (state) => state.notifications.summary.progress_notification.not_read_num_by_type?.task_cancel
  );

  return (
    <div
      id="cancel-report-pill"
      className={`pill ${isSelected ? "is-pill-selected" : ""} ${notReadTaskCancel > 0 ? "badge" : ""}`}
      {...props}
    >
      {t("cancel_contact")}
    </div>
  );
};

export default CancelReportPill;
