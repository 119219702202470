import { connect } from "react-redux";

import actions from "../../actions/index";
import SearchPanel from "../../components/matrix/SearchPanel";
import { RootState } from "@/reducers/types";

const mapStateToProps = (state: RootState) => {
  return {
    masters: state.construction.masters,
    searchParams: state.matrixSearch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCategory: (categoryId) => dispatch(actions.matrixSearch.changeCategory(categoryId)),
    changePrimaryCharge: (primaryChargeId) => dispatch(actions.matrixSearch.changePrimaryCharge(primaryChargeId)),
    changeArea: (areaIds) => dispatch(actions.matrixSearch.changeArea(areaIds)),
    changeDevice: (deviceIds) => dispatch(actions.matrixSearch.changeDevice(deviceIds)),
    changeFacilityManagement: (facilityManagementIds) =>
      dispatch(actions.matrixSearch.changeFacilityManagement(facilityManagementIds)),
    changeConstructionManagement: (constructionManagementIds) =>
      dispatch(actions.matrixSearch.changeConstructionManagement(constructionManagementIds)),
    changeOther: (otherIds) => dispatch(actions.matrixSearch.changeOther(otherIds)),
    changeSystem: (systemIds) => dispatch(actions.matrixSearch.changeSystem(systemIds)),
    changeCompany: (companyIds) => dispatch(actions.matrixSearch.changeCompany(companyIds)),
    changeUser: (userIds) => dispatch(actions.matrixSearch.changeUser(userIds)),
    changeGroup: (groupIds) => dispatch(actions.matrixSearch.changeGroup(groupIds)),
    changeProcessMiddleClass: (middleClassIds) =>
      dispatch(actions.matrixSearch.changeProcessMiddleClass(middleClassIds)),
    changeItemText: (text) => dispatch(actions.matrixSearch.changeItemText(text)),
    changeProcessText: (text) => dispatch(actions.matrixSearch.changeProcessText(text)),
    changeRegulation: (text) => dispatch(actions.matrixSearch.changeRegulation(text)),
    changeFilter: (filterId) => dispatch(actions.matrixSearch.changeFilter(filterId)),
    clearConditions: () => dispatch(actions.matrixSearch.clearConditions()),
    showAlert: (title, messages) =>
      dispatch(actions.app.showAlert(title, messages, () => dispatch(actions.app.hideAlert()))),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPanel);
