import * as constants from "../constants";
import environments from "../environments";
import storageManager from "../lib/storageManager";

export const changeArea = (areaIds) => ({
  type: constants.SUMMARY_CHANGE_AREA,
  payload: areaIds,
});

export const changeDevice = (deviceIds) => ({
  type: constants.SUMMARY_CHANGE_DEVICE,
  payload: deviceIds,
});

export const changeCategory = (categoryIds) => ({
  type: constants.SUMMARY_CHANGE_CATEGORY,
  payload: categoryIds,
});

export const changeFacilityManagement = (items) => ({
  type: constants.SUMMARY_CHANGE_FACILITY_MANAGEMENT,
  payload: items,
});

export const changeConstructionManagement = (items) => ({
  type: constants.SUMMARY_CHANGE_CONSTRUCTION_MANAGEMENT,
  payload: items,
});

export const changePrimaryCharge = (items) => ({
  type: constants.SUMMARY_CHANGE_PRIMARY_CHARGE,
  payload: items,
});

export const setValidated = (validated) => ({
  type: constants.SUMMARY_SET_VALIDATED,
  payload: validated,
});

export const revertLocalCondition = () => ({
  type: constants.SUMMARY_REVERT_CONDITION,
});

export const resetSearch = () => {
  storageManager.removeConstructionItem("summarySearchParams");

  return {
    type: constants.SUMMARY_RESET_SEARCH,
  };
};

export const searchItems = (params = null, callback) => {
  let endpoint = `${environments.API_BASE_URI}/summaries`;
  const query = new URLSearchParams();
  let infinite = false;

  if (params && params.areaIds.length > 0) {
    params.areaIds.forEach((param) => query.append("area_id", param));
  }

  if (params && params.deviceIds.length > 0) {
    params.deviceIds.forEach((param) => query.append("device_id", param));
  }

  if (params && params.categoryIds.length > 0) {
    params.categoryIds.forEach((param) => query.append("category_id", param));
  }

  if (params && params.facilityManagementIds && params.facilityManagementIds.length > 0) {
    params.facilityManagementIds.forEach((param) => query.append("facility_management_id", param));
  }

  if (params && params.constructionManagementIds && params.constructionManagementIds.length > 0) {
    params.constructionManagementIds.forEach((param) => query.append("construction_management_id", param));
  }

  if (params && params.primaryChargeIds && params.primaryChargeIds.length > 0) {
    params.primaryChargeIds.forEach((param) => query.append("primary_charge_id", param));
  }

  if (params && params.limit) {
    query.set("limit", params.limit);
  }

  if (params && params.start) {
    query.set("start", params.start);
  }

  if (params && params.start > 1) {
    infinite = true;
  }

  if (query.toString() === "") {
    storageManager.removeConstructionItem("summarySearchParams");
  } else {
    storageManager.setConstructionItem("summarySearchParams", JSON.stringify(params));
    endpoint = `${endpoint}?${query.toString()}`;
  }

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: infinite ? constants.SUMMARY_BEGIN_SEARCH_ITEMS_INFINITE : constants.SUMMARY_BEGIN_SEARCH_ITEMS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: infinite ? constants.SUMMARY_END_SEARCH_ITEMS_INFINITE : constants.SUMMARY_END_SEARCH_ITEMS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const download = (params = null, callback, fallback) => {
  let endpoint = `${environments.API_BASE_URI}/summary/files`;
  const query = new URLSearchParams();

  query.set("filetype", 1);
  query.set("format", "excel");

  if (params && params.areaIds.length > 0) {
    params.areaIds.forEach((param) => query.append("area_id", param));
  }

  if (params && params.deviceIds.length > 0) {
    params.deviceIds.forEach((param) => query.append("device_id", param));
  }

  if (params && params.categoryIds.length > 0) {
    params.categoryIds.forEach((param) => query.append("category_id", param));
  }

  if (params && params.facilityManagementIds && params.facilityManagementIds.length > 0) {
    params.facilityManagementIds.forEach((param) => query.append("facility_management_id", param));
  }

  if (params && params.constructionManagementIds && params.constructionManagementIds.length > 0) {
    params.constructionManagementIds.forEach((param) => query.append("construction_management_id", param));
  }

  if (params && params.primaryChargeIds && params.primaryChargeIds.length > 0) {
    params.primaryChargeIds.forEach((param) => query.append("primary_charge_id", param));
  }

  endpoint = `${endpoint}?${query.toString()}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      success: (response, fileName) => {
        if (callback !== undefined) {
          callback(response, fileName);
        }

        return {
          type: constants.SUMMARY_END_DOWNLOAD,
        };
      },
      error: (response) => {
        fallback && fallback();

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const selectCategorySummary = (row, data) => ({
  type: constants.SUMMARY_SELECT_CATEGORY_SUMMARY,
  payload: {
    row,
    data,
  },
});

export const beginSelectInazuma = () => ({
  type: constants.SUMMARY_BEGIN_SELECT_INAZUMA,
});

export const endSelectInazuma = () => ({
  type: constants.SUMMARY_END_SELECT_INAZUMA,
});

export const toggleInazumaCell = (row, data) => {
  return {
    type: constants.SUMMARY_TOGGLE_INAZUMA_CELL,
    payload: {
      ...row,
      ...data,
    },
  };
};

export const clearInazumaCells = () => ({
  type: constants.SUMMARY_CLEAR_INAZUMA_CELLS,
});

export const fetchInazumaBasePoints = (callback) => {
  const endpoint = `${environments.API_BASE_URI}/inazuma/base_points`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => ({
        type: constants.SUMMARY_BEGIN_INAZUMA_PANEL_REQUEST,
      }),
      success: (response) => {
        if (callback !== undefined) {
          callback(response.list);
        }

        return {
          type: constants.SUMMARY_END_INAZUMA_PANEL_REQUEST,
          payload: response.list,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const createInazumaBasePoint = (title, callback) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/inazuma/base_points/create`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({ title }),
    callbacks: {
      begin: () => ({
        type: constants.SUMMARY_BEGIN_INAZUMA_PANEL_CREATE_BASE,
      }),
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.SUMMARY_END_INAZUMA_PANEL_CREATE_BASE,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
    meta: {
      confirm: {
        messages: ["put_base_date", "of_inazuma_lines", "is_it_ok"],
      },
    },
  };
};

export const deleteInazumaBasePoint = (id, callback) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/inazuma/base_points/delete/${id}`,
    method: "POST",
    callbacks: {
      begin: () => ({
        type: constants.SUMMARY_BEGIN_INAZUMA_PANEL_REQUEST,
      }),
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.SUMMARY_END_INAZUMA_PANEL_REQUEST,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
    meta: {
      confirm: {
        messages: ["delete_base_date", "is_it_ok"],
      },
    },
  };
};

export const fetchInazumaTerm = (inazumaBasePointId, callback) => {
  return (dispatch, getState) => {
    const state = getState();
    const cells = state.summary.inazumaSelectedCells;
    const inazumaTargets = cells.map((c) => ({
      category_id: c.category_id,
      area_id: c.area_id,
      device_id: c.device_id,
      facility_management_id: c.facility_management_id,
      construction_management_id: c.construction_management_id,
      primary_charge_id: c.primary_charge_id,
    }));

    const params = {
      inazuma_targets: inazumaTargets,
      inazuma_base_point_id: inazumaBasePointId,
    };

    dispatch({
      type: constants.APP_CALL_API,
      endpoint: `${environments.API_BASE_URI}/inazuma/term`,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(params),
      callbacks: {
        begin: () => ({
          type: constants.SUMMARY_BEGIN_INAZUMA_PANEL_REQUEST,
        }),
        success: (response) => {
          if (callback !== undefined) {
            callback(response);
          }

          return {
            type: constants.SUMMARY_END_INAZUMA_PANEL_REQUEST,
            payload: response,
          };
        },
        error: (response) => {
          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    });
  };
};

export const requestInazumaLineCreation = (params, callback) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/inazuma/create`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(params),
    callbacks: {
      begin: () => ({
        type: constants.SUMMARY_BEGIN_INAZUMA_PANEL_REQUEST_CREATION,
      }),
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        const { inazuma_base_point_id, past_inazuma_flg, inazuma_start_date, inazuma_end_date, inazuma_gap_date } =
          params;

        const storage = {
          inazuma_base_point_id,
          past_inazuma_flg,
        };

        if (past_inazuma_flg) {
          storage.inazuma_start_date = inazuma_start_date;
          storage.inazuma_end_date = inazuma_end_date;
          storage.inazuma_gap_date = inazuma_gap_date;
        }

        storageManager.setConstructionItem("inazumaPanelParams", JSON.stringify(storage));

        return {
          type: constants.SUMMARY_END_INAZUMA_PANEL_REQUEST_CREATION,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const beginSelectGraph = () => ({
  type: constants.SUMMARY_BEGIN_SELECT_GRAPH,
});

export const endSelectGraph = () => ({
  type: constants.SUMMARY_END_SELECT_GRAPH,
});

export const beginSelectDelay = () => ({
  type: constants.SUMMARY_BEGIN_SELECT_DELAY,
});

export const endSelectDelay = () => ({
  type: constants.SUMMARY_END_SELECT_DELAY,
});

export const toggleGraphCell = (row, data) => {
  return {
    type: constants.SUMMARY_TOGGLE_GRAPH_CELL,
    payload: {
      ...row,
      ...data,
    },
  };
};

export const toggleDelayCell = (row, data) => {
  return {
    type: constants.SUMMARY_TOGGLE_DELAY_CELL,
    payload: {
      ...row,
      ...data,
    },
  };
};

export const clearGraphCells = () => ({
  type: constants.SUMMARY_CLEAR_GRAPH_CELLS,
});

export const clearDelayCells = () => ({
  type: constants.SUMMARY_CLEAR_DELAY_CELLS,
});

export const createGraph = (callback) => {
  return (dispatch, getState) => {
    const state = getState();
    const cells = state.summary.graphSelectedCells;
    const graphTargets = cells.map((c) => ({
      category_id: c.category_id,
      area_id: c.area_id,
      device_id: c.device_id,
      facility_management_id: c.facility_management_id,
      construction_management_id: c.construction_management_id,
      primary_charge_id: c.primary_charge_id,
    }));

    const params = {
      graph_targets: graphTargets,
    };

    dispatch({
      type: constants.APP_CALL_API,
      endpoint: `${environments.API_BASE_URI}/graph/create`,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(params),
      callbacks: {
        begin: () => ({
          type: constants.SUMMARY_BEGIN_CREATE_GRAPH,
        }),
        success: (response) => {
          if (callback !== undefined) {
            callback(response);
          }

          return {
            type: constants.SUMMARY_END_CREATE_GRAPH,
            payload: response,
          };
        },
        error: (response) => {
          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    });
  };
};

export const createDelayList = (callback) => {
  return (dispatch, getState) => {
    const state = getState();
    const cells = state.summary.delaySelectedCells;
    const delayTargets = cells.map((c) => ({
      category_id: c.category_id,
      area_id: c.area_id,
      device_id: c.device_id,
      facility_management_id: c.facility_management_id,
      construction_management_id: c.construction_management_id,
      primary_charge_id: c.primary_charge_id,
    }));

    const params = {
      delay_list_targets: delayTargets,
    };

    dispatch({
      type: constants.APP_CALL_API,
      endpoint: `${environments.API_BASE_URI}/delay_list/create`,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(params),
      callbacks: {
        begin: () => ({
          type: constants.SUMMARY_BEGIN_CREATE_DELAY_LIST,
        }),
        success: (response) => {
          if (callback !== undefined) {
            callback(response);
          }

          return {
            type: constants.SUMMARY_END_CREATE_DELAY_LIST,
            payload: response,
          };
        },
        error: (response) => {
          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    });
  };
};

export const beginSelectSchedule = () => ({
  type: constants.SUMMARY_BEGIN_SELECT_SCHEDULE,
});

export const endSelectSchedule = () => ({
  type: constants.SUMMARY_END_SELECT_SCHEDULE,
});

export const toggleScheduleCell = (row, data) => {
  return {
    type: constants.SUMMARY_TOGGLE_SCHEDULE_CELL,
    payload: {
      ...row,
      ...data,
    },
  };
};

export const clearScheduleCells = () => ({
  type: constants.SUMMARY_CLEAR_SCHEDULE_CELLS,
});

export const createScheduleNegishi = (schedule_dates, callback) => {
  return (dispatch, getState) => {
    const state = getState();
    const cells = state.summary.scheduleSelectedCells;
    const scheduleTargets = cells.map((c) => ({
      category_id: c.category_id,
      area_id: c.area_id,
      device_id: c.device_id,
      facility_management_id: c.facility_management_id,
      construction_management_id: c.construction_management_id,
      primary_charge_id: c.primary_charge_id,
    }));

    const params = {
      schedule_targets: scheduleTargets,
      schedule_dates,
    };

    dispatch({
      type: constants.APP_CALL_API,
      endpoint: `${environments.API_BASE_URI}/schedule_negishi/create`,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(params),
      callbacks: {
        begin: () => ({
          type: constants.SUMMARY_BEGIN_CREATE_SCHEDULE,
        }),
        success: (response) => {
          if (callback !== undefined) {
            callback(response);
          }

          return {
            type: constants.SUMMARY_END_CREATE_SCHEDULE,
            payload: response,
          };
        },
        error: (response) => {
          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    });
  };
};

export const createScheduleTokuyama = (schedule_dates, callback) => {
  return (dispatch, getState) => {
    const state = getState();
    const cells = state.summary.scheduleSelectedCells;
    const scheduleTargets = cells.map((c) => ({
      category_id: c.category_id,
      area_id: c.area_id,
      device_id: c.device_id,
      facility_management_id: c.facility_management_id,
      construction_management_id: c.construction_management_id,
      primary_charge_id: c.primary_charge_id,
    }));

    const params = {
      schedule_targets: scheduleTargets,
      schedule_dates,
    };

    dispatch({
      type: constants.APP_CALL_API,
      endpoint: `${environments.API_BASE_URI}/schedule_tokuyama/create`,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(params),
      callbacks: {
        begin: () => ({
          type: constants.SUMMARY_BEGIN_CREATE_SCHEDULE,
        }),
        success: (response) => {
          if (callback !== undefined) {
            callback(response);
          }

          return {
            type: constants.SUMMARY_END_CREATE_SCHEDULE,
            payload: response,
          };
        },
        error: (response) => {
          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    });
  };
};

export const createScheduleIdemitsuChiba = (schedule_dates, callback) => {
  return (dispatch, getState) => {
    const state = getState();
    const cells = state.summary.scheduleSelectedCells;
    const scheduleTargets = cells.map((c) => ({
      category_id: c.category_id,
      area_id: c.area_id,
      device_id: c.device_id,
      facility_management_id: c.facility_management_id,
      construction_management_id: c.construction_management_id,
      primary_charge_id: c.primary_charge_id,
    }));

    const params = {
      schedule_targets: scheduleTargets,
      schedule_dates,
    };

    dispatch({
      type: constants.APP_CALL_API,
      endpoint: `${environments.API_BASE_URI}/schedule_idemitsu_chiba/create`,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(params),
      callbacks: {
        begin: () => ({
          type: constants.SUMMARY_BEGIN_CREATE_SCHEDULE,
        }),
        success: (response) => {
          if (callback !== undefined) {
            callback(response);
          }

          return {
            type: constants.SUMMARY_END_CREATE_SCHEDULE,
            payload: response,
          };
        },
        error: (response) => {
          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    });
  };
};

export const createScheduleOpcOsaka = (request, callback) => {
  return (dispatch, getState) => {
    const state = getState();
    const cells = state.summary.scheduleSelectedCells;
    const scheduleTargets = cells.map((c) => ({
      category_id: c.category_id,
      area_id: c.area_id,
      device_id: c.device_id,
      facility_management_id: c.facility_management_id,
      construction_management_id: c.construction_management_id,
      primary_charge_id: c.primary_charge_id,
    }));

    const params = {
      schedule_targets: scheduleTargets,
      schedule_dates: request.schedule_dates,
      schedule_format_type: request.schedule_format_type,
    };

    dispatch({
      type: constants.APP_CALL_API,
      endpoint: `${environments.API_BASE_URI}/schedule_opc_osaka/create`,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(params),
      callbacks: {
        begin: () => ({
          type: constants.SUMMARY_BEGIN_CREATE_SCHEDULE,
        }),
        success: (response) => {
          if (callback !== undefined) {
            callback(response);
          }

          return {
            type: constants.SUMMARY_END_CREATE_SCHEDULE,
            payload: response,
          };
        },
        error: (response) => {
          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    });
  };
};

export const createScheduleMitsuiIwakuni = (request, callback) => {
  return (dispatch, getState) => {
    const state = getState();
    const cells = state.summary.scheduleSelectedCells;
    const scheduleTargets = cells.map((c) => ({
      category_id: c.category_id,
      area_id: c.area_id,
      device_id: c.device_id,
      facility_management_id: c.facility_management_id,
      construction_management_id: c.construction_management_id,
      primary_charge_id: c.primary_charge_id,
    }));

    const params = {
      schedule_targets: scheduleTargets,
      schedule_dates: request.schedule_dates,
      schedule_format_type: request.schedule_format_type,
    };

    dispatch({
      type: constants.APP_CALL_API,
      endpoint: `${environments.API_BASE_URI}/schedule_mitsui_iwakuni/create`,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(params),
      callbacks: {
        begin: () => ({
          type: constants.SUMMARY_BEGIN_CREATE_SCHEDULE,
        }),
        success: (response) => {
          if (callback !== undefined) {
            callback(response);
          }

          return {
            type: constants.SUMMARY_END_CREATE_SCHEDULE,
            payload: response,
          };
        },
        error: (response) => {
          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    });
  };
};

export const createScheduleCosmoChiba = (request, callback, fallback) => {
  return (dispatch, getState) => {
    const state = getState();
    const cells = state.summary.scheduleSelectedCells;
    const scheduleTargets = cells.map((c) => ({
      category_id: c.category_id,
      area_id: c.area_id,
      device_id: c.device_id,
      facility_management_id: c.facility_management_id,
      construction_management_id: c.construction_management_id,
      primary_charge_id: c.primary_charge_id,
    }));

    const params = {
      schedule_targets: scheduleTargets,
      schedule_format_type: request.schedule_format_type,
    };

    dispatch({
      type: constants.APP_CALL_API,
      endpoint: `${environments.API_BASE_URI}/schedule_cosmo_chiba/create`,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(params),
      callbacks: {
        begin: () => ({
          type: constants.SUMMARY_BEGIN_CREATE_SCHEDULE,
        }),
        success: (response, filename) => {
          if (callback !== undefined) {
            callback(response, filename);
          }

          return {
            type: constants.SUMMARY_END_CREATE_SCHEDULE,
            payload: response,
          };
        },
        error: (response) => {
          fallback && fallback();

          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    });
  };
};
