import * as constants from "../constants/index";

const information = (
  state = {
    items: [],
    current: null,
    start: 1,
    limit: 999999,
    end: false,
  },
  action
) => {
  switch (action.type) {
    case constants.INFORMATION_END_FETCH_ITEM: {
      return {
        ...state,
        current: action.payload,
      };
    }
    case constants.INFORMATION_END_FETCH_ITEMS: {
      return {
        ...state,
        items: [...state.items, ...action.payload],
      };
    }
    case constants.INFORMATION_END_CREATE_ITEM: {
      const { files } = action.payload;
      const file_status = Boolean(files.length);
      const item = {
        ...action.payload,
        file_status,
      };

      return {
        ...state,
        items: [item, ...state.items],
      };
    }
    case constants.INFORMATION_END_UPDATE_ITEM: {
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.information_id === action.payload.information_id) {
            const { files } = action.payload;
            const file_status = Boolean(files.length);

            return { ...action.payload, file_status };
          }

          return item;
        }),
      };
    }
    case constants.INFORMATION_END_DELETE_ITEM: {
      return {
        ...state,
        items: state.items.filter((item) => item.information_id !== action.payload),
      };
    }
    case constants.INFORMATION_SET_START: {
      return {
        ...state,
        start: action.payload,
      };
    }
    case constants.INFORMATION_SET_LIMIT: {
      return {
        ...state,
        limit: action.payload,
      };
    }
    case constants.INFORMATION_SET_END: {
      return {
        ...state,
        end: action.payload,
      };
    }
    case constants.INFORMATION_RESET_STATE: {
      return {
        ...state,
        items: [],
      };
    }
    default:
      return state;
  }
};

export default information;
