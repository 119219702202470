import * as constants from "../constants";

import storageManager from "@/lib/storageManager";

const initialState = {
  valid: false,
  userId: 0,
  userName: "",
  shouldLogout: false,
  funcProgress: 0,
  funcLayout: 0,
  system: "",
};

const authenticatedDataJSON = localStorage.getItem("authenticatedData");

if (authenticatedDataJSON !== null) {
  const authenticatedData = JSON.parse(authenticatedDataJSON);

  initialState.valid = true;
  initialState.userId = authenticatedData.user_id;
  initialState.userName = authenticatedData.user_name;
  initialState.funcLayout = authenticatedData.func_layout;
  initialState.funcProgress = authenticatedData.func_progress;

  const defaultSystem = storageManager.getUserItem("system") ?? "progress";
  if (defaultSystem) {
    initialState.system = defaultSystem;
  } else {
    if (authenticatedData.func_layout === 1 && authenticatedData.func_progress === 0) {
      initialState.system = "layout";
    } else {
      initialState.system = "progress";
    }
  }
}

const session = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGIN_SUCCESS: {
      return {
        valid: true,
        userId: action.payload.user_id,
        userName: action.payload.user_name,
        shouldLogout: false,
        funcLayout: action.payload.func_layout,
        funcProgress: action.payload.func_progress,
        system: action.payload.system,
      };
    }
    case constants.APP_SWITCH_SYSTEM: {
      return {
        ...state,
        system: action.payload,
      };
    }
    case constants.LOGOUT_SUCCESS: {
      return {
        ...state,
        valid: false,
        userId: 0,
        userName: "",
        shouldLogout: false,
        funcLayout: 0,
        funcProgress: 0,
      };
    }
    case constants.LOGIN_SHOULD_LOGOUT: {
      return {
        ...state,
        shouldLogout: true,
      };
    }
    default:
      return state;
  }
};

export default session;
