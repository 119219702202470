import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import validator from "validator";

import Modal from "../Modal";

class ExportInazumaPanel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;

    return (
      <Modal title={t("output_inazuma_lines")} closeHandler={this.props.closeHandler}>
        <div className="modal-body modal-inazuma-confirm">
          <p>
            {t("output_inazuma_1")}
            <br />
            {t("output_inazuma_2")}
          </p>
          <p>
            <br />
            {t("output_inazuma_3")}
            <br />
            {t("output_inazuma_4")}
            <br />
            {t("output_inazuma_5")}
          </p>
        </div>
        <div className="modal-footer">
          <button
            data-test-id="button-comment-editor-cancel"
            type="button"
            className="btn btn-blue"
            onClick={this.props.closeHandler}
          >
            {t("close")}
          </button>
        </div>
      </Modal>
    );
  }
}

ExportInazumaPanel.propTypes = {
  closeHandler: PropTypes.func.isRequired,
};

export default withTranslation()(ExportInazumaPanel);
