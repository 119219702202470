type Props = {
  value: string;
  options: { label: string; value: string }[];
  onChange: (_) => void;
  optionWidth?: string;
};

export const ToggleButtonGroup = ({ value, options, onChange, optionWidth }: Props) => {
  return (
    <div className="toggle-buttons" style={{ marginLeft: 0, paddingLeft: 0 }}>
      {options.map((option, index) => {
        const selected = value === option.value;
        const isFirst = index === 0;

        return (
          <label key={index} style={{ width: optionWidth ?? "80px", marginLeft: isFirst ? 0 : "-1px" }}>
            <input type="radio" checked={selected} onChange={() => !selected && onChange(option.value)} />
            <span className="btn">{option.label}</span>
          </label>
        );
      })}
    </div>
  );
};
