import { TimeStamp } from "ts/models/timestamp";

export * from "./authorization";

export * from "./copy";

export type File = {
  mode: number;
  file_id: number;
  file_name: string;
  file_size: number;
  timestamp: TimeStamp;
};

export type Rectangle = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type Machine = {
  machine_id: number;
  construction_id: number;
  layout_date: string;
  plot_plan_id: number;
  company_id: number;
  company_name: string;
  icon_color: string;
  keepout_area_id: number;
  machine_type_id: number;
  machine_type_name: string;
  abbr: string;
  no: number;
  x: number;
  y: number;
  status: MachineStatusType;
  completion_date: string;
  use_start_hour: string;
  use_end_hour: string;
  work_content: string;
  user_id: number;
  charge_user_name: string;
  phone_number: string;
  note: string;
  substitute: number;
  wireless_flg: boolean;
  jib_flg: boolean;
  files: File[];
  timestamp: TimeStamp;
  file_num: number;
};

export const MachineStatus = {
  STAND_BY: 1, // "待機中"
  RUNNING: 2, // "稼働中"
  COMPLETED: 3, // "終了"
} as const;

export type MachineStatusType = (typeof MachineStatus)[keyof typeof MachineStatus];

export const MachineStatusLabel = {
  [MachineStatus.STAND_BY]: "waiting",
  [MachineStatus.RUNNING]: "in_operation",
  [MachineStatus.COMPLETED]: "end",
};

export const AreaShapeType = {
  RECTANGLE: 1, // 四角形
  POLYGON: 2, // 多角形
} as const;

export type AreaShapeTypeType = (typeof AreaShapeType)[keyof typeof AreaShapeType];

export type KeepOutArea = {
  keepout_area_id: number;
  construction_id: number;
  plot_plan_id: number;
  layout_date: string;
  company_id: number;
  company_name: string;
  icon_color: string;
  machine_id?: number;
  machine_type_id?: number;
  use_start_hour: string;
  use_end_hour: string;
  work_content: string;
  user_id: number;
  charge_user_name?: string;
  phone_number?: string;
  note: string;
  timestamp: TimeStamp;
  machine_type_name?: string;
  status?: MachineStatusType;
  shape_type: AreaShapeTypeType;
  x?: number;
  y?: number;
  w?: number;
  h?: number;
  coords?: [number, number][];
};

export type KeepOutAreaRectangle = Omit<KeepOutArea, "x" | "y" | "w" | "h"> & {
  x: number;
  y: number;
  w: number;
  h: number;
};

export type KeepOutAreaPolygon = Omit<KeepOutArea, "coords"> & {
  coords: [number, number][];
};

export const filterAreaRectangle = (keepOutAreas: KeepOutArea[]): KeepOutAreaRectangle[] => {
  return keepOutAreas
    .filter((v) => v.shape_type === AreaShapeType.RECTANGLE)
    .map((v) => ({
      ...v,
      x: v.x ?? 0,
      y: v.y ?? 0,
      w: v.w ?? 0,
      h: v.h ?? 0,
    }));
};

export const filterAreaPolygon = (
  keepOutAreas: (Omit<KeepOutArea, "coords"> & {
    coords: string;
  })[]
): KeepOutAreaPolygon[] => {
  return keepOutAreas
    .filter((v) => v.shape_type === AreaShapeType.POLYGON)
    .map((v) => ({
      ...v,
      coords: v.coords ? JSON.parse(v.coords) : [],
    }));
};
