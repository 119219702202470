import { useTranslation } from "react-i18next";

export const FireTypeAbbreviationNote = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-white flex flex-col p-[10px] whitespace-nowrap">
      <div>{t("fire_type_abbreviation_note_title")}</div>
      <div className="flex flex-col m-[10px] white">
        <span>{t("fire_type_abbreviation_note_1")}</span>
        <span>{t("fire_type_abbreviation_note_2")}</span>
        <span>{t("fire_type_abbreviation_note_3")}</span>
        <span>{t("fire_type_abbreviation_note_4")}</span>
        <span>{t("fire_type_abbreviation_note_5")}</span>
      </div>
    </div>
  );
};
