import PropTypes from "prop-types";
import React, { Component } from "react";

class RightGridHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <table className="grid-table grid-table-header grid-table-right grid-table-right-header">
        <thead>
          <tr>
            {this.props.columns.map((column, index) => {
              return <th key={index}>{column.category_name}</th>;
            })}
          </tr>
        </thead>
      </table>
    );
  }
}

RightGridHeader.propTypes = {
  columns: PropTypes.array.isRequired,
};

export default RightGridHeader;
