import PropTypes from "prop-types";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { withTranslation } from "react-i18next";

import MasterSelect from "../common/Select";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { removeHeightResizeListener } from "../../lib/common";

class AssetSearchArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: [],
    };

    this.handleChangeDateFrom = this.handleChangeDateFrom.bind(this);
    this.handleChangeDateTo = this.handleChangeDateTo.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  handleChangeDateFrom(date) {
    this.props.changeDateFrom(
      date
        ? date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
        : ""
    );
  }

  handleChangeDateTo(date) {
    this.props.changeDateTo(
      date
        ? date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
        : ""
    );
  }

  handleSearch() {
    const error = [];
    const { uploadTarget, handleSearch, insertStartDate, insertEndDate } = this.props;

    // 日付のチェック
    if (insertStartDate !== null && insertEndDate !== null) {
      const sd = moment(insertStartDate);
      const ed = moment(insertEndDate);
      error.insertDate = ed.diff(sd, "days") < 0 ? true : false;
    }

    error.upload = uploadTarget.length < 1 ? true : false;
    if (!error.upload) {
      handleSearch();
    }
    this.setState({ error });
  }

  handleClear() {
    this.props.handleClear();
    this.setState({ error: [] });
  }

  render() {
    const {
      masters,
      categoryIds,
      insertStartDate,
      insertEndDate,
      fileName,
      uploadTarget,
      changeCategory,
      changeFilename,
      changeUploadTarget,
      reference,
      t,
    } = this.props;

    const { error } = this.state;

    return (
      <div className="search-box" ref={reference}>
        <div className="form-row">
          <div className="form-group w-360">
            <span className="form-label">{t("machines_category")}</span>
            <div className="w-270 d-ib ta-l">
              <MasterSelect
                prefix="category"
                isMulti={true}
                options={masters.categories}
                value={categoryIds}
                onChange={changeCategory}
              />
            </div>
          </div>
          <div className="form-group w-330">
            <span className="form-label">{t("file_name")}</span>
            <input
              data-test-id="text-import-file-name"
              type="text"
              className="form-control w-230"
              value={fileName}
              onChange={(e) => changeFilename(e.target.value)}
            />
          </div>
        </div>
        <div className="form-row mb-0">
          <div className="form-group w-360">
            <span className="form-label">{t("registered_day")}</span>
            <div className="d-ib">
              <DatePicker
                wrapperClassName="w-120"
                disabled={false}
                selected={insertStartDate ? moment(insertStartDate).toDate() : null}
                dateFormat="yyyy/MM/dd"
                locale={t("calender_locale")}
                onChange={(date) => this.handleChangeDateFrom(date)}
              />
            </div>
            <div className="d-ib w-30 ta-c">&nbsp;〜&nbsp;</div>
            <div className="d-ib">
              <DatePicker
                wrapperClassName="w-120"
                disabled={false}
                selected={insertEndDate ? moment(insertEndDate).toDate() : null}
                dateFormat="yyyy/MM/dd"
                locale={t("calender_locale")}
                onChange={(date) => this.handleChangeDateTo(date)}
              />
            </div>
            {error.insertDate && (
              <div className="form-error w-380">
                <p className="form-error-message w-300">{t("schedule_alert")}</p>
              </div>
            )}
          </div>
          <div className="form-group w-450 mt-5 ml-25 ta-l">
            <span className="form-label">{t("upload_destination")}</span>
            <label data-test-id="checkbox-search-panel-expired" className="ckbox">
              <input type="checkbox" checked={_.includes(uploadTarget, 1)} onChange={() => changeUploadTarget(1)} />
              <span>{t("board")}</span>
            </label>
            <label data-test-id="checkbox-search-panel-assigned" className="ckbox">
              <input type="checkbox" checked={_.includes(uploadTarget, 2)} onChange={() => changeUploadTarget(2)} />
              <span>{t("title")}</span>
            </label>
            <label data-test-id="checkbox-search-panel-not-completed" className="ckbox">
              <input type="checkbox" checked={_.includes(uploadTarget, 3)} onChange={() => changeUploadTarget(3)} />
              <span>{t("detail")}</span>
            </label>
            {error.upload && (
              <div className="form-error w-360">
                <p className="form-error-message w-230">{t("set_upload_target")}</p>
              </div>
            )}
          </div>
          <div className="form-group btn-area pull-right">
            <button data-test-id="button-import-reset" className="btn btn-gray" onClick={() => this.handleClear()}>
              {t("reset")}
            </button>
            <button data-test-id="button-import-search" className="btn btn-blue" onClick={() => this.handleSearch()}>
              {t("search")}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

AssetSearchArea.propTypes = {
  categoryIds: PropTypes.array.isRequired,
  masters: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  changeCategory: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  reference: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AssetSearchArea);
