import React, { FC } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/reducers/types";
import { MachineIcon } from "@/sx-layout/common/MachineIcon";
import { Machine } from "@/sx-layout/components/plotmap/models";
import { MachineType } from "@/sx-layout/components/settings/machineSetting/models";

type Props = {
  machine: Machine;
  selected: boolean;
};

export const MachineObject: FC<Props> = ({ machine, selected }) => {
  const machineTypes = useSelector<RootState, MachineType[]>((state) => state.machine.machineTypes);
  const convertMachineTypeToIcon = (machine_type_id: number) => {
    const machineType = machineTypes.find((mt) => mt.machine_type_id === machine_type_id);
    return machineType ? machineType.machine_icon_id : null;
  };

  return (
    <>
      <div id={`machine-moveable-${machine.machine_id}`}>
        <MachineIcon
          active={machine.status !== 1}
          shapeColor={`#${machine.icon_color}`}
          machineIconId={convertMachineTypeToIcon(machine.machine_type_id)}
          selected={selected}
        />
      </div>
      <div
        className="font-bold min-w-max"
        style={{
          position: "absolute",
          bottom: 30,
          right: 38,
        }}
      >
        {machine.abbr}
      </div>
      <div className="font-bold" style={{ position: "absolute", top: 28, left: 35 }}>
        {machine.no}
      </div>
    </>
  );
};
