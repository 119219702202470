import { connect } from "react-redux";

import actions from "../../actions";
import Import from "../../components/import/Import";

const mapStateToProps = (state) => {
  let categories = [];
  let primaryCharges = [];
  let users = [];
  let companies = [];

  if (state.construction.masters) {
    categories = state.construction.masters.categories;
    primaryCharges = state.construction.masters.primary_charges;
    users = state.construction.masters.users;
    companies = state.construction.masters.companies;
  }

  return {
    companyId: state.dataimport.companyId,
    userId: state.dataimport.userId,
    categoryId: state.dataimport.categoryId,
    fileName: state.dataimport.fileName,
    fileType: state.dataimport.fileType,
    statusId: state.dataimport.statusId,
    import_start_date: state.dataimport.import_start_date,
    import_end_date: state.dataimport.import_end_date,
    items: state.dataimport.items,
    current: state.dataimport.current,
    fetching: state.dataimport.fetching,
    impFileType: state.dataimport.impFileType,
    categories,
    primaryCharges,
    users,
    companies,
    isError: state.dataimport.isError,
    validated: state.dataimport.validated,
    downloading: state.dataimport.downloading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchItems: () => dispatch(actions.dataimport.fetchItems()),
    search: (params, callback) => dispatch(actions.dataimport.searchItems(params, callback)),
    errors: (params, callback) => dispatch(actions.dataimport.exportErrorFile(params, callback)),
    fetchItem: (id, callback) => dispatch(actions.dataimport.fetchItem(id, callback)),
    resetSearch: () => dispatch(actions.dataimport.resetSearch()),
    changeUser: (userId) => dispatch(actions.dataimport.changeUser(userId)),
    changeCategory: (categoryId) => dispatch(actions.dataimport.changeCategory(categoryId)),
    changeCompany: (companyId) => dispatch(actions.dataimport.changeCompany(companyId)),
    changeFilename: (fileName) => dispatch(actions.dataimport.changeFilename(fileName)),
    changeStatus: (statusId) => dispatch(actions.dataimport.changeStatus(statusId)),
    changeDateFrom: (dateFrom) => dispatch(actions.dataimport.changeDateFrom(dateFrom)),
    changeDateTo: (dateTo) => dispatch(actions.dataimport.changeDateTo(dateTo)),
    changeFiletype: (fileType) => dispatch(actions.dataimport.changeFiletype(fileType)),
    importFiletype: (fileType) => dispatch(actions.dataimport.importFiletype(fileType)),
    uploadFile: (data, onProgress, onSuccess, callback) =>
      dispatch(actions.dataimport.uploadFile(data, onProgress, onSuccess, callback)),
    download: (kind, fileId, qrFlag, callback) =>
      dispatch(actions.common.downloadAttachmentFile(kind, fileId, qrFlag, callback)),
    downloadErrors: (kind, fileIds, callback, fallback) =>
      dispatch(actions.common.downloadErrorFiles(kind, fileIds, callback, fallback)),
    downloadFormats: (filetype, callback) => dispatch(actions.dataimport.downloadFormatFiles(filetype, callback)),
    revertLocalCondition: () => dispatch(actions.dataimport.revertLocalCondition()),
    toggleDownloading: (downloading) => dispatch(actions.dataimport.toggleDownloading(downloading)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Import);
