export type Errors = {
  isOverDuration: boolean;
  isError: boolean;
};

export type AssociatedCopy = "1";

export type SingleCopy = "2";

export type CopyOption = AssociatedCopy | SingleCopy;
