import { connect } from "react-redux";

import actions from "../../actions";
import WorkEditor from "../../components/matrix/WorkEditor";

const mapStateToProps = (state) => {
  return {
    masters: state.construction.masters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProcess: (processId, callback) => dispatch(actions.matrix.fetchProcess(processId, callback)),
    updateProcess: (processId, data, callback, failedCallback) =>
      dispatch(actions.matrix.updateProcess(processId, data, callback, failedCallback)),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkEditor);
