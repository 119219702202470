import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import actions from "@/actions";
import { SCHEDULE_DATA_PER_PAGE } from "@/components/list/Schedule/constants";
import { SearchInput } from "@/components/list/Schedule/types";
import { ScheduleResultsRequest } from "@/components/list/Schedule/types/api";
import { RootState } from "@/reducers/types";

export const useResultList = (): {
  search: (input: SearchInput, pageInput?: number) => void;
  prev: () => void;
  next: () => void;
} => {
  const dispatch = useDispatch();

  const { requestForResultList, currentPageOfResultList } = useSelector((state: RootState) => state.schedule);

  const toRequest = (input: SearchInput): Omit<ScheduleResultsRequest, "start" | "limit"> => {
    return {
      result_date: moment(input.schedule_date).subtract(1, "day").format("YYYY-MM-DD"),
      category_id: input.category_id,
      area_id: input.area_id,
      device_id: input.device_id,
      manager_user_id: input.manager_user_id,
    };
  };

  const search = (input: SearchInput, pageInput?: number) => {
    const p = pageInput ?? 1;
    dispatch(
      actions.schedule.getScheduleResults(
        {
          ...toRequest(input),
          start: (p - 1) * SCHEDULE_DATA_PER_PAGE + 1,
          limit: SCHEDULE_DATA_PER_PAGE,
        },
        p
      )
    );
  };

  const prev = () => {
    const p = currentPageOfResultList - 1;
    dispatch(
      actions.schedule.getScheduleResults(
        {
          ...requestForResultList,
          start: (p - 1) * SCHEDULE_DATA_PER_PAGE + 1,
        },
        p
      )
    );
  };

  const next = () => {
    const p = currentPageOfResultList + 1;
    dispatch(
      actions.schedule.getScheduleResults(
        {
          ...requestForResultList,
          start: (p - 1) * SCHEDULE_DATA_PER_PAGE + 1,
        },
        p
      )
    );
  };

  return { search, prev, next };
};
