import { ComponentProps, forwardRef } from "react";

export type CheckboxProps = Omit<ComponentProps<"input">, "className"> & {
  size?: "normal";
  id: ComponentProps<"input">["id"];
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({ id, ...restProps }, ref) => {
  return (
    <label htmlFor={id} className="cursor-pointer">
      <input ref={ref} type="checkbox" className="peer hidden" id={id} {...restProps} />
      <span
        className={`
          relative
          block
          w-[18px]
          h-[18px]
          border-2
          border-[#000]
          peer-checked:before:content-['']
          peer-checked:before:absolute
          peer-checked:before:top-[-2px]
          peer-checked:before:left-[2px]
          peer-checked:before:block
          peer-checked:before:w-[18px]
          peer-checked:before:h-[8px]
          peer-checked:before:border-l-[3px]
          peer-checked:before:border-b-[3px]
          peer-checked:before:border-[#dea221]
          peer-checked:before:-rotate-45
        `}
      />
    </label>
  );
});

Checkbox.displayName = "Checkbox";
