import { connect } from "react-redux";

import actions from "../actions";
import App from "../components/App";

const mapStateToProps = (state) => {
  return {
    authenticated: state.session.valid,
    system: state.session.system,
    confirm: state.app.confirm,
    alert: state.app.alert,
    fetchingMenus: state.app.fetchingMenus,
    fetchingMasters: state.app.fetchingMasters,
    fetchingProgressRate: state.app.fetchingProgressRate,
    fetchingConstructions: state.app.fetchingConstructions,
    fetchingRoles: state.app.fetchingRoles,
    unloadAlert: state.app.unloadAlert,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showConstructionSelector: () => dispatch(actions.app.showConstructionSelector()),
    showPasswordEditor: () => dispatch(actions.app.showPasswordEditor()),
    popState: (e) => dispatch(actions.app.popState(e)),
    changeMenu: (menu) => dispatch(actions.app.changeMenu(menu)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
