import { useCallback, useState } from "react";

export const useDrawingArea = () => {
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [coords, setCoords] = useState<[number, number][]>([]);

  const init = useCallback(() => {
    setIsDrawing(true);
    setCoords([]);
  }, []);

  const end = useCallback(() => {
    setIsDrawing(false);
  }, []);

  const clear = useCallback(() => {
    setIsDrawing(false);
    setCoords([]);
  }, []);

  const addCoord = useCallback(
    (coord: [number, number]) => {
      setCoords([...coords, coord]);
    },
    [coords]
  );

  return { init, end, clear, isDrawing, coords, addCoord };
};
