import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { SearchInput } from "@/components/list/Schedule/types";
import { getConstructionId, getUserId } from "@/lib/common";

const STORED_KEY_PREFIX = "scheduleInput";

export type UseSearchInputType = {
  reset: () => void;
  values: () => SearchInput;
  defaultValues: () => SearchInput;
  areaId: string[];
  deviceId: string[];
  categoryId: string[];
  managerUserId: string[];
  fieldP1: string;
  field1: string[];
  field2: string;
  approve1Unprocessed: boolean;
  approve4Unprocessed: boolean;
  approve1UserId: string[];
  approve4UserId: string[];
  fieldT9: boolean;
  setScheduleDate: (_: Date) => void;
  setAreaId: (_: string[]) => void;
  setDeviceId: (_: string[]) => void;
  setCategoryId: (_: string[]) => void;
  setManagerUserId: (_: string[]) => void;
  setFieldP1: (_: string) => void;
  setField1: (_: string[]) => void;
  setField2: (_: string) => void;
  setApprove1Unprocessed: (_: boolean) => void;
  setApprove4Unprocessed: (_: boolean) => void;
  setFieldT9: (_: boolean) => void;
  setApprove1UserId: (_: string[]) => void;
  setApprove4UserId: (_: string[]) => void;
  hasStoredCondition: boolean;
};

const DEFAULT_VALUES: Omit<SearchInput, "schedule_date"> = {
  area_id: [],
  device_id: [],
  category_id: [],
  manager_user_id: [],
  field_p1: "",
  field1: [],
  field2: undefined,
  approve1_unprocessed: false,
  approve4_unprocessed: false,
  approve1_user_id: [],
  approve4_user_id: [],
  field_t9: false,
};

export const useSearchInput = (scheduleDate: Date): UseSearchInputType => {
  const storedConditionKey = useMemo(() => `${STORED_KEY_PREFIX}-${getUserId()}-${getConstructionId()}`, []);

  const [inputScheduleDate, setScheduleDate] = useState(scheduleDate);
  const [areaId, setAreaId] = useState<string[]>(DEFAULT_VALUES.area_id);
  const [deviceId, setDeviceId] = useState<string[]>(DEFAULT_VALUES.device_id);
  const [categoryId, setCategoryId] = useState<string[]>(DEFAULT_VALUES.category_id);
  const [managerUserId, setManagerUserId] = useState<string[]>(DEFAULT_VALUES.manager_user_id);
  const [fieldP1, setFieldP1] = useState<string>(DEFAULT_VALUES.field_p1); // 施工会社
  const [field1, setField1] = useState<string[]>(DEFAULT_VALUES.field1); // 危険度ランク
  const [field2, setField2] = useState<string>(DEFAULT_VALUES.field2); // 立会
  const [approve1Unprocessed, setApprove1Unprocessed] = useState<boolean>(DEFAULT_VALUES.approve1_unprocessed); // 未処理: 工事主管課
  const [approve4Unprocessed, setApprove4Unprocessed] = useState<boolean>(DEFAULT_VALUES.approve4_unprocessed); // 未処理: 設備主管課
  const [approve1UserId, setApprove1UserId] = useState<string[]>(DEFAULT_VALUES.approve1_user_id); // 工事主管課
  const [approve4UserId, setApprove4UserId] = useState<string[]>(DEFAULT_VALUES.approve4_user_id); // 設備主管課
  const [fieldT9, setFieldT9] = useState<boolean>(DEFAULT_VALUES.field_t9); // 残業時間

  const [hasStoredCondition, setHasStoredCondition] = useState<boolean>(false);

  const values = useCallback((): SearchInput => {
    return {
      schedule_date: inputScheduleDate,
      area_id: areaId,
      device_id: deviceId,
      category_id: categoryId,
      manager_user_id: managerUserId,
      field_p1: fieldP1,
      field1: field1,
      field2: field2,
      approve1_unprocessed: approve1Unprocessed,
      approve4_unprocessed: approve4Unprocessed,
      approve1_user_id: approve1UserId,
      approve4_user_id: approve4UserId,
      field_t9: fieldT9,
    };
  }, [
    inputScheduleDate,
    areaId,
    deviceId,
    categoryId,
    managerUserId,
    fieldP1,
    field1,
    field2,
    approve1Unprocessed,
    approve4Unprocessed,
    approve1UserId,
    approve4UserId,
    fieldT9,
  ]);

  const defaultValues = (): SearchInput => {
    return {
      schedule_date: moment().startOf("day").add(1, "day").toDate(),
      ...DEFAULT_VALUES,
    };
  };

  const initialized = useRef(false);
  const dataReset = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      const storedCondition = localStorage.getItem(storedConditionKey);
      if (storedCondition) {
        const s: SearchInput = JSON.parse(storedCondition);
        setAreaId(s.area_id);
        setDeviceId(s.device_id);
        setCategoryId(s.category_id);
        setManagerUserId(s.manager_user_id);
        setFieldP1(s.field_p1);
        setField1(s.field1);
        setField2(s.field2);
        setApprove1Unprocessed(s.approve1_unprocessed);
        setApprove4Unprocessed(s.approve4_unprocessed);
        setApprove1UserId(s.approve1_user_id);
        setApprove4UserId(s.approve4_user_id);
        setFieldT9(s.field_t9);
        setTimeout(() => {
          setHasStoredCondition(true);
        }, 300);
      }
      initialized.current = true;
    } else {
      if (dataReset.current) {
        // 検索条件がリセットされたらlocalStorageに保存したものは消したままでいい
        dataReset.current = false;
      } else {
        localStorage.setItem(storedConditionKey, JSON.stringify(values()));
      }
    }
  }, [values, storedConditionKey]);

  const reset = () => {
    setScheduleDate(moment().startOf("day").add(1, "day").toDate());
    setAreaId(DEFAULT_VALUES.area_id);
    setDeviceId(DEFAULT_VALUES.device_id);
    setCategoryId(DEFAULT_VALUES.category_id);
    setManagerUserId(DEFAULT_VALUES.manager_user_id);
    setFieldP1(DEFAULT_VALUES.field_p1);
    setField1(DEFAULT_VALUES.field1);
    setField2(DEFAULT_VALUES.field2);
    setApprove1Unprocessed(DEFAULT_VALUES.approve1_unprocessed);
    setApprove4Unprocessed(DEFAULT_VALUES.approve4_unprocessed);
    setApprove1UserId(DEFAULT_VALUES.approve1_user_id);
    setApprove4UserId(DEFAULT_VALUES.approve4_user_id);
    setFieldT9(DEFAULT_VALUES.field_t9);
    dataReset.current = true;
    localStorage.removeItem(storedConditionKey);
  };

  return {
    values,
    defaultValues,
    reset,

    areaId,
    deviceId,
    categoryId,
    managerUserId,
    fieldP1,
    field1,
    field2,
    approve1Unprocessed,
    approve4Unprocessed,
    approve1UserId,
    approve4UserId,
    fieldT9,

    setScheduleDate,
    setAreaId,
    setDeviceId,
    setCategoryId,
    setManagerUserId,
    setFieldP1,
    setField1,
    setField2,
    setApprove1Unprocessed,
    setApprove4Unprocessed,
    setApprove1UserId,
    setApprove4UserId,
    setFieldT9,

    hasStoredCondition,
  };
};
