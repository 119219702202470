import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState } from "@/reducers/types";
import { Button } from "@/sx-layout/common/Button";
import { FreeDrawText } from "@/sx-layout/components/plotmap/components/freeDraw/models";
import { useTerm } from "@/sx-layout/hooks";

type Props = {
  selectedText?: FreeDrawText;
  color: string;
  fontSize: number;
  onClickEdit: () => void;
  onClickCopy: () => void;
  onClickDelete: () => void;
};

export const TextTool: FC<Props> = (props) => {
  const { t } = useTranslation();
  const layoutDate = useSelector<RootState, Date>((state) => state.plotmap.layoutDate);
  const { isPast } = useTerm(layoutDate);

  return (
    <div
      id="text-tool"
      className="flex items-center grow space-x-6 mx-2 h-[100%] border-solid border-gray-400 border-[1px] rounded bg-white"
    >
      <p className="mx-3 text-[#dea321] text-[14px] whitespace-nowrap">{t("text")}</p>
      <div className="flex justify-center items-center whitespace-nowrap">
        {t("color") + ": "}
        <div className="m-2 h-[20px] w-[20px]" style={{ backgroundColor: props.color }} />
      </div>
      <div className="flex justify-center items-center whitespace-nowrap">
        {t("text_size") + ": "}
        {`(${props.fontSize}pt)`}
      </div>
      <div className="flex justify-center items-center ml-auto mx-2">
        {!!props.selectedText && (
          <>
            {!isPast && (
              <Button className="mx-2 p-[4px] min-w-[80px]" onClick={props.onClickEdit}>
                {t("edit")}
              </Button>
            )}
            <Button className="mx-2 p-[4px] min-w-[80px]" onClick={props.onClickCopy}>
              {t("duplicate")}
            </Button>
            {!isPast && (
              <Button
                className="mx-2 p-[4px] min-w-[80px] bg-[#ffdddd] hover:bg-[#f8d0d0] text-gray-800"
                onClick={props.onClickDelete}
              >
                {t("delete")}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};
