import { connect } from "react-redux";

import actions from "../../actions";
import ScheduleEditorChita from "../../components/list/ScheduleEditorChita";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateItem: (data, handleSuccess, handleCancel) =>
      dispatch(actions.schedule_chita.updateItem(data, handleSuccess, handleCancel)),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleEditorChita);
