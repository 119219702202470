import clsx from "clsx";
import React, { useCallback, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import actions from "@/actions";
import { CalendarTextEditorDialog } from "@/components/list/Holiday/components/CalendarTextEditorDialog";
import { UnworkDayGroup } from "@/models/holiday";

type Props = {
  unworkDayGroup: UnworkDayGroup;
  startDate: Date;
  endDate: Date;
  openToDate: Date;
  numberOfMonths: number;
  isEndMonthInitDisplayed: boolean;
  reactDatepickerEndMonthLabel: string;
};

export const HolidayCalendarData = ({
  unworkDayGroup,
  startDate,
  endDate,
  openToDate,
  numberOfMonths,
  isEndMonthInitDisplayed,
  reactDatepickerEndMonthLabel,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // テキスト編集パネル
  const [openEditor, setOpenEditor] = useState(false);
  const handleOpenTextEditor = useCallback(() => {
    setOpenEditor(true);
  }, []);
  const handleCloseTextEditor = useCallback(() => {
    setOpenEditor(false);
  }, []);
  const handleChangeWithTextEditor = (dates: Date[]) => {
    handleCloseTextEditor();
    onChangeHolidays(dates);
  };

  // 全休日更新
  const onChangeHolidays = (dates: Date[]) => {
    dispatch(actions.holiday.updateUnworkDays(unworkDayGroup, dates));
  };

  // カレンダーの次の月を表示する矢印の制御。最終月が表示されている場合は表示しないようにする。
  const [disableNextNav, setDisableNextNav] = useState(isEndMonthInitDisplayed);
  const onMonthChange = () => {
    const headerMonthTitles = document.getElementsByClassName("react-datepicker__current-month");
    const count = headerMonthTitles.length;
    setDisableNextNav(0 < count && headerMonthTitles.item(count - 1).innerHTML === reactDatepickerEndMonthLabel);
  };

  return (
    <div className="h-[100%] w-[100%] text-[1.2rem] flex flex-col justify-between">
      <div
        className={clsx(
          "flex flex-row min-w-[760px] p-[5px] items-center holiday-datepicker",
          disableNextNav ? "disable-nav-next" : ""
        )}
      >
        <DatePicker
          key="date-picker-current"
          selectedDates={unworkDayGroup.unwork_days}
          selectsMultiple={true}
          inline={true}
          onChange={onChangeHolidays}
          monthsShown={numberOfMonths < 3 ? numberOfMonths : 3} // 最大3つの月のカレンダーを並べる
          minDate={startDate}
          maxDate={endDate}
          openToDate={openToDate}
          onMonthChange={onMonthChange}
        />
      </div>
      <div className="bg-[#e5e5e5] p-[5px] text-end">
        <button
          data-test-id="button-holiday-change"
          className="btn btn-blue h-[20px]"
          style={{ padding: "0 5px" }}
          onClick={handleOpenTextEditor}
        >
          {t("edit_with_text")}
        </button>
      </div>
      {openEditor && (
        <CalendarTextEditorDialog
          holidays={unworkDayGroup.unwork_days}
          onClose={handleCloseTextEditor}
          onChange={handleChangeWithTextEditor}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </div>
  );
};
