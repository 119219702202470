import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import { isValidRole } from "../../lib/roleChecker";

class ScheduleRightRowEneosKawasaki extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editNote3: null,
      editComment1: null,
      editComment2: null,

      isExcluse: false,
      loading: false,
    };
  }

  beginEditTextarea(stateName, id) {
    if (!this.state.isExcluse) {
      this.setState({
        ...this.state,
        [stateName]: id,
        isExcluse: true,
      });
    }
  }

  endEditTextarea(stateName, target, comment, row) {
    const { showAlert, hideAlert, t } = this.props;

    const data = {
      schedule_id: row.schedule_id,
      target: target,
      value: comment,
      update_date: row[target + "_update_date"],
      timestamp: row.timestamp,
    };

    if (comment.length > 500) {
      showAlert(t("confirmation"), [t(target + "_is_too_long_500")], hideAlert);

      return;
    }

    this.props.updateItem(
      data,
      () => {
        this.setState({ [stateName]: null, isExcluse: false });
      },
      () => {
        this.setState({ [stateName]: null, isExcluse: false });
      }
    );
  }

  componentDidUpdate() {
    if (this.note3Textarea) {
      this.note3Textarea.focus();
    }
    if (this.comment1Textarea) {
      this.comment1Textarea.focus();
    }
    if (this.comment2Textarea) {
      this.comment2Textarea.focus();
    }
  }

  render() {
    const { index, schedule, isScheduleDate, showScheduleEditor, handleOnMouse, omitText, isWideD, isWideE, t } =
      this.props;
    const { roles, schedule_date } = schedule;

    return (
      <tr key={index}>
        {isValidRole(roles, 9) && isScheduleDate("comment1", schedule_date) ? (
          schedule.item_id === this.state.editComment1 ? (
            <td className="no-hover col_comment grid-cell-clickable txt-center schedule-right-body">
              <div className="grid-cell-wrap">
                <div className="grid-cell-con">
                  <div className="grid-cell-inner schedule-note">
                    <textarea
                      className="grid-textarea txt-left p-5"
                      ref={(node) => (this.comment1Textarea = node)}
                      onBlur={(e) => this.endEditTextarea("editComment1", "comment1", e.target.value, schedule)}
                      defaultValue={schedule.comment1}
                    ></textarea>
                  </div>
                </div>
              </div>
            </td>
          ) : (
            <td
              className="col_comment grid-cell-clickable txt-center schedule-right-body"
              onClick={() => this.beginEditTextarea("editComment1", schedule.item_id)}
              data-tip
              data-for="schedule-full-text"
              onMouseEnter={() => handleOnMouse(schedule.comment1, 16)}
            >
              <div className="grid-cell-wrap">
                <div className="grid-cell-con">
                  <div className="grid-cell-inner schedule-note txt-left p-5">
                    {omitText(schedule.comment1, 16)}
                    <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                  </div>
                </div>
              </div>
            </td>
          )
        ) : (
          <td
            className="col_comment txt-center schedule-right-body"
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.comment1, 16)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner schedule-note">{omitText(schedule.comment1, 16)}</div>
              </div>
            </div>
          </td>
        )}
        {isValidRole(roles, 10) && isScheduleDate("field1", schedule_date) ? (
          <td
            className="col11 grid-cell-clickable txt-center schedule-right-body"
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field1_name, 10)}
            onClick={() => showScheduleEditor("field1", t("application") + "：" + t("construction"), schedule)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field1_name, 10)}
                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className="col11 txt-center schedule-right-body"
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field1_name, 10)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field1_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {isValidRole(roles, 11) && isScheduleDate("field2", schedule_date) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body wided-td ${isWideD ? "" : "d-n"}`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field2_name, 10)}
            onClick={() => showScheduleEditor("field2", t("readiness") + "：" + t("assignee"), schedule)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field2_name, 10)}
                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className={`col11 txt-center schedule-right-body wided-td ${isWideD ? "" : "d-n"}`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field2_name, 10)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field2_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {isValidRole(roles, 12) && isScheduleDate("field3", schedule_date) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body wided-td ${isWideD ? "" : "d-n"}`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field3_name, 10)}
            onClick={() => showScheduleEditor("field3", t("driver") + "：" + t("assignee"), schedule)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field3_name, 10)}
                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className={`col11 txt-center schedule-right-body wided-td ${isWideD ? "" : "d-n"}`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field3_name, 10)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field3_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {isValidRole(roles, 13) && isScheduleDate("field4", schedule_date) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body wided-td ${isWideD ? "" : "d-n"}`}
            onClick={() => showScheduleEditor("field4", t("driver_witness"), schedule)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {schedule.field4_name}
                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td className={`col11 txt-center schedule-right-body wided-td ${isWideD ? "" : "d-n"}`}>
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{schedule.field4_name}</div>
              </div>
            </div>
          </td>
        )}
        {isValidRole(roles, 14) && isScheduleDate("field5", schedule_date) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body wided-td ${isWideD ? "" : "d-n"}`}
            onClick={() => showScheduleEditor("field5", t("she_hirisk"), schedule)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {schedule.field5_name}
                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td className={`col11 txt-center schedule-right-body wided-td ${isWideD ? "" : "d-n"}`}>
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{schedule.field5_name}</div>
              </div>
            </div>
          </td>
        )}
        {isValidRole(roles, 15) && isScheduleDate("field6", schedule_date) ? (
          <td
            className={`col9 grid-cell-clickable txt-center schedule-right-body wided-td ${isWideD ? "" : "d-n"}`}
            onClick={() => showScheduleEditor("field6", t("airline"), schedule)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {schedule.field6_name}
                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td className={`col9 txt-center schedule-right-body wided-td ${isWideD ? "" : "d-n"}`}>
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{schedule.field6_name}</div>
              </div>
            </div>
          </td>
        )}
        {isValidRole(roles, 16) && isScheduleDate("field7", schedule_date) ? (
          <td
            className="col11 grid-cell-clickable txt-center schedule-right-body"
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field7_name, 10)}
            onClick={() => showScheduleEditor("field7", t("approval") + "：" + t("senior_staff"), schedule)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field7_name, 10)}
                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className="col11 txt-center schedule-right-body"
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field7_name, 10)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field7_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {isValidRole(roles, 17) && isScheduleDate("field8", schedule_date) ? (
          <td
            className="col11 grid-cell-clickable txt-center schedule-right-body"
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field8_name, 10)}
            onClick={() => showScheduleEditor("field8", t("permission") + "：" + t("team_leader"), schedule)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field8_name, 10)}
                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className="col11 txt-center schedule-right-body"
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field8_name, 10)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field8_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {isValidRole(roles, 18) && isScheduleDate("note3", schedule_date) ? (
          schedule.item_id === this.state.editNote3 ? (
            <td className="no-hover col9 grid-cell-clickable txt-center schedule-right-body">
              <div className="grid-cell-wrap">
                <div className="grid-cell-con">
                  <div className="grid-cell-inner schedule-note">
                    <textarea
                      className="grid-textarea txt-left p-5"
                      ref={(node) => (this.note3Textarea = node)}
                      onBlur={(e) => this.endEditTextarea("editNote3", "note3", e.target.value, schedule)}
                      defaultValue={schedule.note3}
                    ></textarea>
                  </div>
                </div>
              </div>
            </td>
          ) : (
            <td
              className="col9 grid-cell-clickable txt-center schedule-right-body"
              onClick={() => this.beginEditTextarea("editNote3", schedule.item_id)}
              data-tip
              data-for="schedule-full-text"
              onMouseEnter={() => handleOnMouse(schedule.note3, 20)}
            >
              <div className="grid-cell-wrap">
                <div className="grid-cell-con">
                  <div className="grid-cell-inner schedule-note txt-left p-5">
                    {omitText(schedule.note3, 20)}
                    <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                  </div>
                </div>
              </div>
            </td>
          )
        ) : (
          <td
            className="col9 txt-center schedule-right-body"
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.note3, 20)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner schedule-note">{omitText(schedule.note3, 20)}</div>
              </div>
            </div>
          </td>
        )}
        {isValidRole(roles, 19) && isScheduleDate("field9", schedule_date) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body widee-td ${isWideE ? "" : "d-n"}`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field9_name, 10)}
            onClick={() => showScheduleEditor("field9", t("end_check") + "：" + t("drive"), schedule)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field9_name, 10)}
                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className={`col11 txt-center schedule-right-body widee-td ${isWideE ? "" : "d-n"}`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field9_name, 10)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field9_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {isValidRole(roles, 20) && isScheduleDate("field10", schedule_date) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body widee-td ${isWideE ? "" : "d-n"}`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field10_name, 10)}
            onClick={() => showScheduleEditor("field10", t("end_check") + "：" + t("builder"), schedule)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field10_name, 10)}
                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className={`col11 txt-center schedule-right-body widee-td ${isWideE ? "" : "d-n"}`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field10_name, 10)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field10_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {isValidRole(roles, 21) && isScheduleDate("comment2", schedule_date) ? (
          schedule.item_id === this.state.editComment2 ? (
            <td className="no-hover col_comment grid-cell-clickable txt-center schedule-right-body">
              <div className="grid-cell-wrap">
                <div className="grid-cell-con">
                  <div className="grid-cell-inner schedule-note">
                    <textarea
                      className="grid-textarea txt-left p-5"
                      ref={(node) => (this.comment2Textarea = node)}
                      onBlur={(e) => this.endEditTextarea("editComment2", "comment2", e.target.value, schedule)}
                      defaultValue={schedule.comment2}
                    ></textarea>
                  </div>
                </div>
              </div>
            </td>
          ) : (
            <td
              className="col_comment grid-cell-clickable txt-center schedule-right-body"
              onClick={() => this.beginEditTextarea("editComment2", schedule.item_id)}
              data-tip
              data-for="schedule-full-text"
              onMouseEnter={() => handleOnMouse(schedule.comment2, 16)}
            >
              <div className="grid-cell-wrap">
                <div className="grid-cell-con">
                  <div className="grid-cell-inner schedule-note txt-left p-5">
                    {omitText(schedule.comment2, 16)}
                    <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                  </div>
                </div>
              </div>
            </td>
          )
        ) : (
          <td
            className="col_comment txt-center schedule-right-body"
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.comment2, 16)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner schedule-note">{omitText(schedule.comment2, 16)}</div>
              </div>
            </div>
          </td>
        )}
      </tr>
    );
  }
}

ScheduleRightRowEneosKawasaki.propTypes = {
  index: PropTypes.number.isRequired,
  schedule: PropTypes.object.isRequired,
  showScheduleEditor: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
};

export default withTranslation()(ScheduleRightRowEneosKawasaki);
