export type ProgressNotificationInformation = {
  [key: string]: string;
};

export type ProgressNotification = {
  progress_notification_id: number;
  notification_type_name: string;
  notification_date: string;
  read_date?: string;
  information: ProgressNotificationInformation;
  comment_num: number;
  category_id: number;
  item_id: number;
  task_id: number;
};

export type ProgressNotificationsResponse = {
  total_num: number;
  list: ProgressNotification[];
};

export const PROGRESS_NOTIFICATION_TYPE_COMPLETION_REPORT = 1 as const;

export const PROGRESS_NOTIFICATION_TYPE_CANCEL_REPORT = 2 as const;

export type ProgressNotificationType =
  | typeof PROGRESS_NOTIFICATION_TYPE_COMPLETION_REPORT
  | typeof PROGRESS_NOTIFICATION_TYPE_CANCEL_REPORT;

export type ProgressNotificationsPaginationParams = {
  notification_type: ProgressNotificationType;
  category_id?: number;
  process_text?: string;
  start: number;
  limit: number;
};

export type ProgressNotificationNotReadNum = {
  not_read_num: number;
};

export type ProgressNotificationSummary = {
  download_job: ProgressNotificationNotReadNum;
  progress_notification: ProgressNotificationNotReadNum;
  layout_notification: ProgressNotificationNotReadNum;
};

// eslint-disable-next-line @typescript-eslint/ban-types -- 暫定処置でdisableしている
export type ProgressNotificationSearchConditions = {};

export type ProgressNotificationFetchedNotificationLargestId = {
  [key in ProgressNotificationType]: number;
};

export type ProgressNotificationState = {
  fetching: boolean;
  isError: boolean;
  notifications: ProgressNotification[];
  isRefreshRequested: boolean;
  end: boolean;
  fetchedNotificationLargestId: ProgressNotificationFetchedNotificationLargestId;
};

export type NotificationType =
  | typeof PROGRESS_NOTIFICATION_TYPE_COMPLETION_REPORT
  | typeof PROGRESS_NOTIFICATION_TYPE_CANCEL_REPORT;

export type ProgressNotificationSearchParam = {
  notification_type: NotificationType;
  category_id?: number;
  process_id?: number;
  process_text?: string;
};
