import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation, withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as z from "zod";

import Modal from "../Modal";

import { CreateProcessInput } from "@/actions/types/matrix";
import { FormSelect } from "@/components/common/Form/Select";
import { SubmitButton } from "@/components/common/SubmitButton";
import { getUserId } from "@/lib/common";
import { UserRole } from "@/models/authorization";
import { ConstructionMasters } from "@/models/masters";
import { Process } from "@/models/matrix";
import { RootState } from "@/reducers/types";
import { isPositiveFiniteNumber, isToSecondDecimalPlace, isValidNumberRange } from "@/lib/validate";

type Props = {
  processId: number;
  onClose: () => void;
  onComplete: () => void;
};
type DispatchProps = {
  masters: ConstructionMasters;
  scheduleRoles: [number, number, number]; // [施工会社, 危険源, 低減策]の変更不可フラグ
  fetchProcess: (processId, callback) => void;
  createProcess: (data, callback, failedCallback?) => void;
};

type FormInput = {
  output_flg: "on" | "off";
  weight: string;
  process_major_class_id: number;
  process_middle_class_id: number;
  company_id: number;
  user_id: number;
  process_name: string;
  field_p1: string;
  field_p2: string;
  field_p3: string;
};

const WorkCreateEditor: React.FC<Props> = (props: Props & DispatchProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState<Process>();

  const { categoryId, primaryChargeId } = useSelector((state: RootState) => state.matrixSearch);
  const { columns } = useSelector((state: RootState) => state.matrix);
  const [previousProcess, nextProcess] = useMemo(() => {
    const i = columns.findIndex((c) => c.process_id === props.processId);
    return [columns[i], columns[i + 1]];
  }, [columns, props.processId]);

  useEffect(() => {
    props.fetchProcess(props.processId, (data: Process) => setProcess(data));
  }, []);

  const formSchema = z.object({
    output_flg: z.string(),
    weight: z
      .string()
      .refine((v) => !!v, `${t("weight")}${t("input_required_field")}`)
      .refine(isPositiveFiniteNumber, t("invalid_number_format", { field: t("weight") }))
      .refine(isToSecondDecimalPlace, t("weight_invalid_format"))
      .refine(isValidNumberRange, t("maximum_validation")),
    process_major_class_id: z.number(),
    process_middle_class_id: z.number(),
    company_id: z.number().gt(0, `${t("company")}${t("is_not_selected")}`),
    user_id: z.number().gt(0, `${t("receiver")}${t("is_not_selected")}`),
    process_name: z
      .string()
      .trim()
      .refine((v) => 0 < v.length, `${t("process_title")}${t("input_required_field")}`)
      .refine((v) => v.length <= 100, `${t("process_title")}${t("is_too_long")}`),
    field_p1: z
      .string()
      .nullish()
      .refine((v) => v.length <= 100, `【${t("schedule_list")}】${t("company")}${t("is_too_long")}`),
    field_p2: z
      .string()
      .nullish()
      .refine((v) => v.length <= 100, `${t("source_of_danger")}${t("is_too_long")}`),
    field_p3: z
      .string()
      .nullish()
      .refine((v) => v.length <= 100, `${t("mitigation_measure")}${t("is_too_long")}`),
  });
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<FormInput>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      output_flg: "on",
      weight: "0",
      process_major_class_id: 0,
      process_middle_class_id: 0,
      company_id: 0,
      user_id: 0,
      process_name: "",
      field_p1: "",
      field_p2: "",
      field_p3: "",
    },
  });

  const { company_id } = watch();
  const users = useMemo(
    () => props.masters.users.filter((v) => v.company_id === company_id),
    [props.masters.users, company_id]
  );
  const alertCompanyId = useMemo(() => {
    const userId = getUserId();
    const user = props.masters.users.find((v) => v.user_id === userId);

    return props.masters.user_role === UserRole.USER_ROLE && !!company_id && company_id !== user?.company_id;
  }, [props.masters.users, props.masters.user_role, company_id]);

  const handleSave = (values: FormInput) => {
    if (loading) return;
    setLoading(true);
    let coordinate_flg = false;
    if (values.process_middle_class_id) {
      const neighborProcess = [previousProcess, nextProcess]
        .filter((v) => v)
        .find((v) => v.process_middle_class_id === values.process_middle_class_id);
      if (neighborProcess) {
        coordinate_flg = neighborProcess.coordinate_flg || false;
      }
    }
    const params: CreateProcessInput = {
      category_id: categoryId,
      primary_charge_id: primaryChargeId,
      process_major_class_id: values.process_major_class_id || null,
      process_middle_class_id: values.process_middle_class_id || null,
      coordinate_flg,
      company_id: values.company_id,
      user_id: values.user_id,
      process_name: values.process_name,
      weight: Number(values.weight),
      output_flg: values.output_flg === "on",
      field_p1: values.field_p1,
      field_p2: values.field_p2,
      field_p3: values.field_p3,
      sort: process.sort + 1,
    };
    props.createProcess(
      params,
      () => {
        setLoading(false);
        props.onClose();
        props.onComplete();
      },
      () => setLoading(false)
    );
  };

  return (
    <Modal title={t("create_new_process")} closeHandler={props.onClose}>
      <div className="modal-body mx-h-[calc(100vh-100px)]">
        <div className="form-row">
          <div className="form-group w-390">
            <span className="form-label txt-bold">{t("print_schedule_tomorrow")}</span>
            <div className="toggle-buttons w-260">
              <label data-test-id="radio-work-editor-output-flg-on">
                <input type="radio" value="on" {...register("output_flg")} />
                <span className="btn">ON</span>
              </label>
              <label data-test-id="radio-work-editor-output-flg-off">
                <input type="radio" value="off" {...register("output_flg")} />
                <span className="btn">OFF</span>
              </label>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group w-390">
            <span className="form-label txt-bold">{t("weight")}</span>
            <input
              data-test-id="work-editor-weight"
              type="text"
              className="form-control w-120 mr-[140px]"
              {...register("weight")}
            />
          </div>
          {errors.weight?.message && (
            <div className="form-error w-390">
              <p className="form-error-message w-260">{errors.weight.message}</p>
            </div>
          )}
        </div>
        <div className="form-row">
          <div className="form-group w-390">
            <span className="form-label txt-bold">{t("process_major_class")}</span>
            <div className="w-260 d-ib ta-l">
              <FormSelect
                name="process_major_class_id"
                // @ts-ignore
                control={control}
                prefix="process_major_class"
                options={props.masters.process_major_classes}
                isClearable={true}
              />
            </div>
          </div>
          {errors.process_major_class_id?.message && (
            <div className="form-error w-390">
              <p className="form-error-message w-260">{errors.process_major_class_id.message}</p>
            </div>
          )}
        </div>
        <div className="form-row">
          <div className="form-group w-390">
            <span className="form-label txt-bold">{t("process_middle_class")}</span>
            <div className="w-260 d-ib ta-l">
              <FormSelect
                name="process_middle_class_id"
                // @ts-ignore
                control={control}
                prefix="process_middle_class"
                options={props.masters.process_middle_classes}
                isClearable={true}
              />
            </div>
          </div>
          {errors.process_middle_class_id?.message && (
            <div className="form-error w-390">
              <p className="form-error-message w-260">{errors.process_middle_class_id.message}</p>
            </div>
          )}
        </div>
        <div className="form-row">
          <div className="form-group w-390">
            <span className="form-label txt-bold">{t("company")}</span>
            <div className="w-260 d-ib ta-l">
              <FormSelect
                name="company_id"
                // @ts-ignore
                control={control}
                prefix="company"
                options={props.masters.companies}
              />
            </div>
          </div>
          {errors.company_id?.message && (
            <div className="form-error w-390">
              <p className="form-error-message w-260">{errors.company_id.message}</p>
            </div>
          )}
          {alertCompanyId && (
            <div className="form-error w-390">
              <p className="form-error-message w-260">{t("company_changed_alert")}</p>
            </div>
          )}
        </div>
        <div className="form-row">
          <div className="form-group w-390">
            <span className="form-label txt-bold">{t("receiver")}</span>
            <div className="w-260 d-ib ta-l">
              <FormSelect
                name="user_id"
                // @ts-ignore
                control={control}
                prefix="user"
                options={users}
              />
            </div>
          </div>
          {errors.user_id?.message && (
            <div className="form-error w-390">
              <p className="form-error-message w-260">{errors.user_id.message}</p>
            </div>
          )}
        </div>
        <div className="form-row">
          <div className="form-group w-390">
            <span className="form-label txt-bold">{t("process_title")}</span>
            <input
              data-test-id="text-work-editor-process-name"
              type="text"
              className="form-control w-260"
              {...register("process_name")}
            />
          </div>
          {errors.process_name?.message && (
            <div className="form-error w-390">
              <p className="form-error-message w-260">{errors.process_name.message}</p>
            </div>
          )}
        </div>
        <div className="form-row">
          <div className="form-group w-390">
            <span className="form-label txt-bold">{`【${t("schedule_list")}】${t("company")}`}</span>
            <input
              type="text"
              className="form-control w-260"
              {...register("field_p1")}
              disabled={!props.scheduleRoles[0]}
            />
          </div>
          {errors.field_p1?.message && (
            <div className="form-error w-390">
              <p className="form-error-message w-260">{errors.field_p1.message}</p>
            </div>
          )}
        </div>
        <div className="form-row">
          <div className="form-group w-390">
            <span className="form-label txt-bold">{t("source_of_danger")}</span>
            <textarea
              rows={5}
              className="form-control w-260"
              {...register("field_p2", {
                onBlur: () => {
                  const formatted = getValues("field_p2")
                    .split("\n")
                    .map((line) => (line.length > 5 ? line.match(/.{1,5}/g).join("\n") : line));
                  setValue("field_p2", formatted.join("\n"));
                },
              })}
              disabled={!props.scheduleRoles[1]}
            />
          </div>
          {errors.field_p2?.message && (
            <div className="form-error w-390">
              <p className="form-error-message w-260">{errors.field_p2.message}</p>
            </div>
          )}
        </div>
        <div className="form-row">
          <div className="form-group w-390">
            <span className="form-label txt-bold">{t("mitigation_measure")}</span>
            <textarea
              rows={5}
              className="form-control w-260"
              {...register("field_p3", {
                onBlur: () => {
                  const formatted = getValues("field_p3")
                    .split("\n")
                    .map((line) => (line.length > 10 ? line.match(/.{1,10}/g).join("\n") : line));
                  setValue("field_p3", formatted.join("\n"));
                },
              })}
              disabled={!props.scheduleRoles[2]}
            />
          </div>
          {errors.field_p3?.message && (
            <div className="form-error w-390">
              <p className="form-error-message w-260">{errors.field_p3.message}</p>
            </div>
          )}
        </div>
      </div>
      <div className={"modal-footer"}>
        <button data-test-id="button-work-editor-cancel" type="button" className="btn btn-gray" onClick={props.onClose}>
          {t("cancel")}
        </button>
        <SubmitButton data-test-id="button-work-editor-save" onClick={handleSubmit(handleSave)} loading={loading}>
          {t("save")}
        </SubmitButton>
      </div>
    </Modal>
  );
};

export default withTranslation()(WorkCreateEditor);
