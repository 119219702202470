import { KonvaEventObject } from "konva/lib/Node";
import moment from "moment/moment";
import React, { useCallback, useEffect, useState } from "react";
import { Circle, Layer, Line, Stage } from "react-konva";
import { useDispatch, useSelector } from "react-redux";
import { useKeyPress, useKeyPressEvent } from "react-use";

import { Size } from "../models";

import actions from "@/actions";
import { RootState } from "@/reducers/types";

type Props = {
  isDrawing: boolean;
  mainScreenElem: HTMLDivElement;
  mapRatio: number;
  companyId: number;
  plotPlanId: number;
  drawingColor: string;
  drawingThickness: number;
  drawingCoords: [number, number][];
  editing: boolean;
  onAddFreeDrawingCoord: (coord: [number, number]) => void;
  onFreeDrawEnd: () => void;
};

export const ArrowDrawStage: React.FC<Props> = (props) => {
  const layoutDate = useSelector<RootState, Date>((state) => state.plotmap.layoutDate);

  const [isVerticalAndHorizontalMode] = useKeyPress("Shift");
  const [mousePoint, setMousePoint] = useState<[number, number]>();
  const [drawTargetPoint, setDrawTargetPoint] = useState<[number, number]>();
  const [stageSize, setStageSize] = useState<Size>({ width: 0, height: 0 });

  const dispatch = useDispatch();
  const createArrow = useCallback(() => {
    dispatch(
      actions.arrows.createArrow({
        input: {
          company_id: props.companyId,
          layout_date: moment(layoutDate).format("YYYY-MM-DD").toString(),
          plot_plan_id: Number(props.plotPlanId),
          coords: props.drawingCoords,
          color: props.drawingColor,
          thickness: props.drawingThickness,
        },
        onSuccess: () => props.onFreeDrawEnd(),
        onError: () => props.onFreeDrawEnd(),
      })
    );
  }, [dispatch, layoutDate, props]);

  const getPointVerticalAndHorizontal = useCallback(
    (drawingPoints: [number, number][], mousePoint: [number, number] | undefined): [number, number] | undefined => {
      // Shiftを押したときは水平もしくは垂直の位置にスナップする
      const lastDrawPoint = drawingPoints.at(-1);
      if (lastDrawPoint === undefined || mousePoint == undefined) {
        return undefined;
      }
      const lastDrawPointX = lastDrawPoint[0] * props.mapRatio;
      const lastDrawPointY = lastDrawPoint[1] * props.mapRatio;
      if (Math.abs(mousePoint[0] - lastDrawPointX) < Math.abs(mousePoint[1] - lastDrawPointY)) {
        return [lastDrawPointX, mousePoint[1]];
      }

      return [mousePoint[0], lastDrawPointY];
    },
    [props.mapRatio]
  );

  const entryVerticalAndHorizontalMode = useCallback(() => {
    setDrawTargetPoint(getPointVerticalAndHorizontal(props.drawingCoords, mousePoint));
  }, [props.drawingCoords, mousePoint]);

  const exitVerticalAndHorizontalMode = useCallback(() => {
    setDrawTargetPoint(mousePoint);
  }, [mousePoint]);

  useKeyPressEvent("Shift", entryVerticalAndHorizontalMode, exitVerticalAndHorizontalMode);
  useKeyPressEvent("Escape", () => props.onFreeDrawEnd());

  const handleClick = useCallback(
    (event) => {
      // 矢印描画範囲外をクリックしたときにキャンセルする
      const paths = event.composedPath();
      if (
        props.isDrawing &&
        !props.editing &&
        !paths.some((path: HTMLElement) =>
          ["arrow-drawer", "arrow-add-button", "arrow-tool", "arrow-edit-modal"].includes(path.id)
        )
      ) {
        props.onFreeDrawEnd();
      }
    },
    [props.isDrawing, props.editing]
  );
  useEffect(() => {
    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  const handleMouseDown = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      if (e.evt.button === 2) {
        // 右クリックを押したとき、矢印があれば完了、そうでなければキャンセルとする
        if (props.drawingCoords.length < 2) {
          props.onFreeDrawEnd();
        } else {
          createArrow();
        }
        return;
      }
      props.onAddFreeDrawingCoord([drawTargetPoint[0] / props.mapRatio, drawTargetPoint[1] / props.mapRatio]);
    },
    [isVerticalAndHorizontalMode, drawTargetPoint, props.drawingCoords, createArrow]
  );

  const handleMouseMove = useCallback(
    (event: KonvaEventObject<MouseEvent>) => {
      const pos = event.target.getStage().getPointerPosition();
      setMousePoint([pos.x, pos.y]);

      if (isVerticalAndHorizontalMode) {
        setDrawTargetPoint(getPointVerticalAndHorizontal(props.drawingCoords, [pos.x, pos.y]));
      } else {
        setDrawTargetPoint([pos.x, pos.y]);
      }
    },
    [isVerticalAndHorizontalMode, props.drawingCoords]
  );

  const linePoints = props.drawingCoords.map((p) => [p[0] * props.mapRatio, p[1] * props.mapRatio]);

  useEffect(() => {
    if (!props.mainScreenElem) return;
    setStageSize({ width: props.mainScreenElem.clientWidth, height: props.mainScreenElem.clientHeight });
  }, [props.mainScreenElem?.clientWidth, props.mainScreenElem?.clientHeight]);

  if (!props.isDrawing) return <></>;
  return (
    <Stage
      id="arrow-drawer"
      {...stageSize}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      className="cursor-crosshair"
      onContextMenu={(e) => e.evt.preventDefault()}
    >
      <Layer>
        {/* 描画中の線 */}
        <Line points={linePoints.flat()} stroke={props.drawingColor} strokeWidth={props.drawingThickness} />
        {/* 描画中の線(破線) */}
        {linePoints.length > 0 && (
          <Line
            stroke="black"
            points={[linePoints.at(-1)[0], linePoints.at(-1)[1], drawTargetPoint[0], drawTargetPoint[1]]}
            dash={[10, 10]}
            strokeWidth={props.drawingThickness}
          />
        )}
        {/* 描画中の線のつなぎ目 */}
        {linePoints.map((point, index) => (
          <Circle
            key={`free-drawing-point-${index}`}
            onMouseDown={(event) => {
              if (linePoints.length === 1 && index === 0) {
                props.onFreeDrawEnd();
              } else if (index === linePoints.length - 1) {
                createArrow();
                event.cancelBubble = true;
              }
            }}
            x={point[0]}
            y={point[1]}
            stroke="black"
            fill="white"
            radius={8}
          />
        ))}
      </Layer>
    </Stage>
  );
};
