import { ConstructionMasters } from "@/models/masters";
import * as constants from "../constants/index";
import * as util from "../lib/common";

export type ConstructionState = any & {
  masters: ConstructionMasters;
};

const construction: ConstructionState = (
  state = {
    constructions: [],
    customers: [],
    places: [],
    constructionId: util.getConstructionId() || 0,
    customerId: 0,
    placeId: 0,
    resultProgressRate: 0,
    scheduleProgressRate: 0,
    masters: null,
    fetchedExtensions: false,
    fetchedLinkage: false,
    extensions: null,
    linkageTasks: null,
  },
  action
) => {
  switch (action.type) {
    case constants.LOGIN_SUCCESS: {
      return {
        ...state,
        constructionId: util.getConstructionId() || 0,
      };
    }
    case constants.CONSTRUCTION_END_FETCH_MASTERS: {
      return {
        ...state,
        masters: action.payload,
      };
    }
    case constants.CONSTRUCTION_END_FETCH_ITEMS: {
      const { constructions, customers, places } = action.payload;
      const newState = {
        ...state,
        constructions,
        customers,
        places,
      };

      if (state.constructionId > 0) {
        const items = constructions.filter((construction) => construction.construction_id === state.constructionId);

        if (items.length > 0) {
          newState.customerId = items[0].customer_id;
          newState.placeId = items[0].place_id;
        }
      }

      return newState;
    }
    case constants.CONSTRUCTION_END_FETCH_PROGRESS_RATE: {
      return {
        ...state,
        resultProgressRate: action.payload.result_progress_rate,
        scheduleProgressRate: action.payload.schedule_progress_rate,
      };
    }
    case constants.CONSTRUCTION_END_FETCH_EXTENSIONS: {
      return {
        ...state,
        fetchedExtensions: true,
        extensions: action.payload,
      };
    }
    case constants.CONSTRUCTION_END_FETCH_LINKAGE_TASKS: {
      return {
        ...state,
        fetchedLinkage: true,
        linkageTasks: action.payload.linkage_tasks,
      };
    }
    default:
      return state;
  }
};

export default construction;
