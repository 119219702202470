import { connect } from "react-redux";

import actions from "../../actions/index";
import Matrix from "../../components/matrix/Matrix";

const mapStateToProps = (state) => {
  let isChiba = false;

  if (state.construction.extensions) {
    const extension = state.construction.extensions.extensions.filter((item) => item.extension_type === 6).shift();
    if (extension) isChiba = true;
  }

  const fetchedExtensions = state.construction.fetchedExtensions;
  let validRequired;

  if (fetchedExtensions) {
    validRequired = state.matrixSearch.validRequired;
  } else {
    validRequired = undefined;
  }

  let displayRemarks = false;
  if (state.construction.extensions) {
    displayRemarks = (state.construction.extensions.extensions || []).some((item) => item.extension_type === 16);
  }

  return {
    switcherStatus: state.matrix.switcherStatus,
    columns: state.matrix.columns,
    dragScroll: state.matrix.dragScroll,
    rows: state.matrix.rows,
    paramItemId: state.matrix.paramItemId,
    taskRoles: state.matrix.taskRoles,
    fetching: state.matrix.fetching,
    fetchingProgressRate: state.matrix.fetchingProgressRate,
    fetchingItemTasks: state.matrix.fetchingItemTasks,
    fetchingProcesses: state.matrix.fetchingProcesses,
    scheduleProgressRate: state.matrix.scheduleProgressRate,
    resultProgressRate: state.matrix.resultProgressRate,
    itemNum: state.matrix.itemNum,
    searchParams: state.matrixSearch,
    validRequired,
    isError: state.matrix.isError,
    inheritingSearchConditions: state.matrix.inheritingSearchConditions,
    downloading: state.matrix.downloading,
    roles: state.app.roles,
    isChiba: isChiba,
    titleSubmenuItemId: state.matrix.titleSubmenuItemId,
    processSubmenu: state.matrix.processSubmenu,
    displayRemarks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    search: (isChiba, params, callback) => {
      dispatch(actions.matrixSearch.beginSearch());

      const p1 = new Promise((resolve) =>
        dispatch(actions.matrixSearch.searchProcesses(params, (data) => resolve(data)))
      );
      const p2 = new Promise((resolve) =>
        dispatch(
          isChiba
            ? actions.matrixSearch.searchTasksChiba(params, (data) => resolve(data))
            : actions.matrixSearch.searchTasks(params, (data) => resolve(data))
        )
      );
      const p3 = new Promise((resolve) =>
        dispatch(actions.matrixSearch.searchProgressRate(params, (data) => resolve(data)))
      );

      Promise.all([p1, p2, p3]).then((results) => {
        const data = {
          processes: results[0],
          tasks: results[1],
          progressRate: results[2],
        };

        dispatch(actions.matrixSearch.endSearch(data));

        if (callback !== undefined) {
          callback(data);
        }
      });
    },
    changeSwitcherStatus: (status) => {
      dispatch(actions.matrix.changeSwitcherStatus(status));
    },
    removeEmptyItem: () => dispatch(actions.matrix.removeEmptyItem()),
    reloadProgressRate: (callback) => dispatch(actions.matrix.reloadProgressRate(callback)),
    download: (params, callback, fallback) => dispatch(actions.matrix.download(params, callback, fallback)),
    revertLocalCondition: () => dispatch(actions.matrixSearch.revertLocalCondition()),
    toggleDownloading: (downloading) => dispatch(actions.matrix.toggleDownloading(downloading)),
    fetchTaskNum: (scheduleType, aheadDays, callback, fallback) =>
      dispatch(actions.matrix.fetchTaskNum(scheduleType, aheadDays, callback, fallback)),
    bulkUpdateTaskStatus: (params, callback, fallback) =>
      dispatch(actions.matrix.bulkUpdateTaskStatus(params, callback, fallback)),
    clearTitleSubmenu: () => dispatch(actions.matrix.clearTitleSubmenu()),
    clearProcessSubmenu: () => dispatch(actions.matrix.clearProcessSubmenu()),
    deleteProcess: (processId, data, callback, fallback) =>
      dispatch(actions.matrix.deleteProcess(processId, data, callback, fallback)),
    bulkUpdateProcess: (data, callback) => dispatch(actions.matrix.bulkUpdateProcess(data, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Matrix);
