import { useState } from "react";
import { useDispatch } from "react-redux";

import actions from "@/actions";
import {
  APPROVE_SCHEDULE_TARGET,
  UPDATE_SCHEDULE_TARGET,
  UPDATE_SCHEDULE_WITH_PROCESS_TARGET,
  UPDATE_SCHEDULE_WITH_TASK_TARGET,
} from "@/components/list/Schedule/constants";
import { BulkUpdateOption, OptionItem, ScheduleScheduleItem } from "@/components/list/Schedule/types";
import { UpdateScheduleRequest } from "@/components/list/Schedule/types/api";
import { formatScheduleValue } from "@/lib/common";

export const useEditorDialog = (props: {
  currentPage: number;
  bulkUpdateOptions?: BulkUpdateOption[];
  onSearch: (page?: number, isPartialUpdate?: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const { bulkUpdateOptions, currentPage, onSearch } = props;

  const [inputType, setInputType] = useState<"text" | "textarea" | "select" | null>(null);

  const [item, setItem] = useState<ScheduleScheduleItem>();
  const [targetName, setTargetName] = useState("");
  const [target, setTarget] = useState("");
  const [value, setValue] = useState<string | number>("");
  const [options, setOptions] = useState<OptionItem[]>([]);

  const openText = (props: {
    item: ScheduleScheduleItem;
    targetName: string;
    target: string;
    value?: string;
    multiline?: boolean;
  }) => {
    setInputType(props.multiline ? "textarea" : "text");
    setItem(props.item);
    setTargetName(props.targetName);
    setTarget(props.target);
    setValue(props.value);
  };

  const openSelect = (props: {
    item: ScheduleScheduleItem;
    targetName: string;
    target: string;
    value?: number;
    optionKey?: string;
  }) => {
    setInputType("select");
    setItem(props.item);
    setTargetName(props.targetName);
    setTarget(props.target);
    setValue(props.value);
    setOptions(bulkUpdateOptions?.find((v) => v.target === (props.optionKey ?? props.target))?.options ?? []);
  };

  const close = () => {
    setInputType(null);
    setItem(null);
    setTargetName("");
    setTarget(null);
    setValue(null);
    setOptions([]);
  };

  const update = (v?: string | number) => {
    const requestTarget = target.replaceAll("_status", "");
    const request: UpdateScheduleRequest = {
      input: {
        schedule_task_id: item.schedule_task_id,
        target: requestTarget,
        value: formatScheduleValue(target, v),
        update_date: item[`${requestTarget}_update_date`] ?? item.timestamp.update_date,
      },
      diff_number_of_workers: diffNumberOfWorkers(v),
      onSuccess: UPDATE_SCHEDULE_WITH_PROCESS_TARGET.includes(target)
        ? () => {
            onSearch(currentPage);
          }
        : target === "field_t9"
        ? () => {
            onSearch(currentPage, true);
          }
        : undefined,
    };
    if (UPDATE_SCHEDULE_TARGET.includes(target)) {
      dispatch(actions.schedule.updateSchedule(request));
    } else if (UPDATE_SCHEDULE_WITH_TASK_TARGET.includes(target)) {
      dispatch(actions.schedule.updateScheduleWithTask(request));
    } else if (UPDATE_SCHEDULE_WITH_PROCESS_TARGET.includes(target)) {
      dispatch(actions.schedule.updateScheduleWithProcess(request));
    } else if (APPROVE_SCHEDULE_TARGET.includes(target)) {
      dispatch(actions.schedule.approveSchedule(request));
    }
    close();
  };

  const diffNumberOfWorkers = (v?: string | number): number => {
    if (target !== "field_t1") return 0;
    const current = Number(value || 0);
    const newValue = isNaN(Number(v)) ? 0 : Number(v || 0);

    return newValue - current;
  };

  return { inputType, item, target, targetName, value, options, openText, openSelect, close, update };
};
