import { array } from "prop-types";
import { connect } from "react-redux";

import actions from "../../actions";
import Summary from "../../components/summary/Summary";

const mapStateToProps = (state) => {
  let areas = [];
  let devices = [];
  let categories = [];
  let facilityManagements = [];
  let constructionManagements = [];
  let primaryCharges = [];
  let extensions = [];

  if (state.construction.masters) {
    areas = state.construction.masters.areas;
    devices = state.construction.masters.devices;
    categories = state.construction.masters.categories;
    facilityManagements = state.construction.masters.facility_managements;
    constructionManagements = state.construction.masters.construction_managements;
    primaryCharges = state.construction.masters.primary_charges;
  }

  if (state.construction.extensions) {
    extensions = state.construction.extensions;
  }

  const selectedCategoryIds = state.summary.inazumaSelectedCells.reduce((acc, cur) => {
    if (!acc.includes(cur.category_id)) {
      acc = [...acc, cur.category_id];
    }

    return acc;
  }, []);

  const selectedItemCount = state.summary.inazumaSelectedCells.reduce((acc, cur) => acc + cur.item_num, 0);
  const selectedItemCountGraph = state.summary.graphSelectedCells.reduce((acc, cur) => acc + cur.item_num, 0);
  const selectedItemCountSchedule = state.summary.scheduleSelectedCells.reduce((acc, cur) => acc + cur.item_num, 0);
  const selectedItemCountDelay = state.summary.delaySelectedCells.reduce((acc, cur) => acc + cur.item_num, 0);

  return {
    fetching: state.summary.fetching,
    areaIds: state.summary.areaIds,
    deviceIds: state.summary.deviceIds,
    categoryIds: state.summary.categoryIds,
    facilityManagementIds: state.summary.facilityManagementIds,
    constructionManagementIds: state.summary.constructionManagementIds,
    primaryChargeIds: state.summary.primaryChargeIds,
    masters: {
      areas,
      devices,
      categories,
      facilityManagements,
      constructionManagements,
      primaryCharges,
    },
    categories: state.summary.categories,
    summaries: state.summary.summaries,
    validated: state.summary.validated,
    isInazumaSelecting: state.summary.isInazumaSelecting,
    inazumaSelectedCells: state.summary.inazumaSelectedCells,
    isGraphSelecting: state.summary.isGraphSelecting,
    graphSelectedCells: state.summary.graphSelectedCells,
    isDelaySelecting: state.summary.isDelaySelecting,
    delaySelectedCells: state.summary.delaySelectedCells,
    isScheduleSelecting: state.summary.isScheduleSelecting,
    scheduleSelectedCells: state.summary.scheduleSelectedCells,
    selectedCategoryIds,
    selectedItemCount,
    selectedItemCountGraph,
    selectedItemCountSchedule,
    selectedItemCountDelay,
    extensions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    search: (params, callback) => dispatch(actions.summary.searchItems(params, callback)),
    resetSearch: () => dispatch(actions.summary.resetSearch()),
    changeArea: (areaId) => dispatch(actions.summary.changeArea(areaId)),
    changeDevice: (deviceId) => dispatch(actions.summary.changeDevice(deviceId)),
    changeCategory: (categoryId) => dispatch(actions.summary.changeCategory(categoryId)),
    changeFacilityManagement: (items) => dispatch(actions.summary.changeFacilityManagement(items)),
    changeConstructionManagement: (items) => dispatch(actions.summary.changeConstructionManagement(items)),
    changePrimaryCharge: (items) => dispatch(actions.summary.changePrimaryCharge(items)),
    revertLocalCondition: () => dispatch(actions.summary.revertLocalCondition()),
    download: (params, callback, fallback) => dispatch(actions.summary.download(params, callback, fallback)),
    beginSelectInazuma: () => dispatch(actions.summary.beginSelectInazuma()),
    endSelectInazuma: () => dispatch(actions.summary.endSelectInazuma()),
    clearInazumaCells: () => dispatch(actions.summary.clearInazumaCells()),
    beginSelectGraph: () => dispatch(actions.summary.beginSelectGraph()),
    endSelectGraph: () => dispatch(actions.summary.endSelectGraph()),
    clearGraphCells: () => dispatch(actions.summary.clearGraphCells()),
    beginSelectDelay: () => dispatch(actions.summary.beginSelectDelay()),
    endSelectDelay: () => dispatch(actions.summary.endSelectDelay()),
    clearDelayCells: () => dispatch(actions.summary.clearDelayCells()),
    createGraph: (callback) => dispatch(actions.summary.createGraph(callback)),
    createDelayList: (callback) => dispatch(actions.summary.createDelayList(callback)),
    beginSelectSchedule: () => dispatch(actions.summary.beginSelectSchedule()),
    endSelectSchedule: () => dispatch(actions.summary.endSelectSchedule()),
    clearScheduleCells: () => dispatch(actions.summary.clearScheduleCells()),
    copyInazumaCell: (row, data) => dispatch(actions.summary.toggleInazumaCell(row, data)),
    copyGraphCell: (row, data) => dispatch(actions.summary.toggleGraphCell(row, data)),
    copyScheduleCell: (row, data) => dispatch(actions.summary.toggleScheduleCell(row, data)),
    copyDelayCell: (row, data) => dispatch(actions.summary.toggleDelayCell(row, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
