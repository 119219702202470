import React, { FC } from "react";
import { useTranslation, withTranslation } from "react-i18next";

import Modal from "../Modal";

import { ScheduleHistory } from "@/models/scheduleChiba";

type Props = {
  historyData?: {
    itemName: string;
    columnName: string;
    data: ScheduleHistory[];
  };
  onClose: () => void;
};

const RevisionHistoryModal: FC<Props> = ({ historyData, onClose }) => {
  const { t } = useTranslation();

  if (!historyData) return <></>;

  return (
    <Modal title={`${t("revision_history")}`} closeHandler={onClose}>
      <div className="modal-body">
        <div className="form-row mb-0">
          <div className="form-group w-360 txt-center">
            <p className="form-text">{`${historyData.itemName}：${historyData.columnName}`}</p>
            <ul className="schedule-revision-history">
              {historyData.data.map((history, index) => (
                <li key={index} className="schedule-revision-history__item">
                  <p className="schedule-revision-history__title">{history.content}</p>
                  <div className="schedule-revision-history__meta">
                    <span>{history.insert_date}</span>
                    <span>{history.insert_user_name}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className={"modal-footer"}>
        <button data-test-id="button-column-editor-cancel" type="button" className="btn btn-gray" onClick={onClose}>
          {t("close")}
        </button>
      </div>
    </Modal>
  );
};

export default withTranslation()(RevisionHistoryModal);
