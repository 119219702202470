import * as constants from "../constants/index";

const fetchedJobsLargestId = Number(localStorage.getItem("fetched_jobs_largest_id")) ?? 0;

const initialState = {
  fetching: false,
  isError: false,
  jobs: [],
  end: false,
  fetchedJobsLargestId,
};

const dataExport = (state = initialState, action) => {
  switch (action.type) {
    case constants.DATAEXPORT_BEGIN_FETCH_JOBS: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.DATAEXPORT_END_FETCH_JOBS: {
      const { payload } = action;
      const { init, response } = payload;
      const { list, total_num } = response;
      const newJobs = init ? list : [...state.jobs, ...list];
      const end = newJobs.length >= total_num;
      let { fetchedJobsLargestId } = state;

      if (list.length > 0 && list[0].download_job_id > fetchedJobsLargestId) {
        localStorage.setItem("fetched_jobs_largest_id", list[0].download_job_id);
        fetchedJobsLargestId = list[0].download_job_id;
      }

      return {
        ...state,
        fetching: false,
        jobs: newJobs,
        end,
        fetchedJobsLargestId,
      };
    }

    case constants.DATAEXPORT_BEGIN_DOWNLOAD_JOBS: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.DATAEXPORT_END_DOWNLOAD_JOBS: {
      return {
        ...state,
        fetching: false,
      };
    }

    case constants.DATAEXPORT_BEGIN_CANCEL_JOBS: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.DATAEXPORT_END_CANCEL_JOBS: {
      const canceledJob = action.payload;
      const newJobs = state.jobs.map((job) => {
        if (job.download_job_id === canceledJob.download_job_id) {
          return canceledJob;
        }

        return job;
      });

      return {
        ...state,
        fetching: false,
        jobs: newJobs,
      };
    }
    case constants.DATAEXPORT_CLEAR_JOBS: {
      return {
        ...state,
        jobs: [],
      };
    }
    case constants.DATAEXPORT_BEGIN_READ_JOB: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.DATAEXPORT_END_READ_JOB: {
      const readJob = action.payload.response;
      const newJobs = state.jobs.map((job) => {
        if (job.download_job_id === readJob.download_job_id) {
          return readJob;
        }

        return job;
      });

      return {
        ...state,
        fetching: false,
        jobs: newJobs,
      };
    }
    case constants.DATAEXPORT_BEGIN_READ_ALL_JOB: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.DATAEXPORT_END_READ_ALL_JOB: {
      return {
        ...state,
        fetching: false,
      };
    }
    case constants.APP_SHOW_ERROR: {
      return {
        ...state,
        fetching: false,
      };
    }
    default:
      return state;
  }
};

export default dataExport;
