import Konva from "konva";
import React, { useEffect, useState } from "react";
import { Group, KonvaNodeEvents, Rect, Text } from "react-konva";

import { FreeDrawText } from "@/sx-layout/components/plotmap/components/freeDraw/models";

type Props = Konva.TextConfig &
  KonvaNodeEvents & {
    freeDrawText: FreeDrawText;
    mapRatio: number;
    selected?: boolean;
    setSelectedRect: (_: { x: number; y: number; width: number; height: number }) => void;
  };

export const TextObject = ({
  freeDrawText,
  mapRatio,
  selected,
  draggable,
  setSelectedRect,
  x,
  y,
  onClick,
  onMouseDown,
  onDragStart,
  onDragMove,
  onDragEnd,
}: Props) => {
  const [textRef, setTextRef] = useState(null);
  const [rect, setRect] = useState<{ x: number; y: number; width: number; height: number }>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setRect({
      x: textRef?.x() || 0,
      y: textRef?.y() || 0,
      width: textRef?.width() || 0,
      height: textRef?.height() || 0,
    });
  }, [textRef, freeDrawText.content, freeDrawText.font_size, mapRatio]);

  useEffect(() => {
    if (selected && rect.x + rect.y + rect.width + rect.height !== 0) {
      setSelectedRect(rect);
    }
  }, [rect, selected]);

  return (
    <Group
      x={x * mapRatio}
      y={y * mapRatio}
      width={rect.width}
      height={rect.height}
      draggable={draggable}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onDragStart={onDragStart}
      onDragMove={onDragMove}
      onDragEnd={onDragEnd}
    >
      <Rect
        x={-3}
        y={-5}
        width={rect.width + 6}
        // 日本語表示だと下の余白が大きくなるので狭くなるように調整する
        height={rect.height + 7}
        fill={"white"}
        strokeEnabled={!!rect.width}
        stroke="black"
        strokeWidth={1}
        shadowColor={selected ? "black" : undefined}
        shadowBlur={selected ? 5 : undefined}
        shadowOpacity={selected ? 0.7 : undefined}
      />
      <Text
        ref={setTextRef}
        x={0}
        y={0}
        text={freeDrawText.content}
        fontSize={freeDrawText.font_size * mapRatio}
        fontStyle="bold"
        align="left"
        verticalAlign="middle"
        fill={freeDrawText.color}
      />
    </Group>
  );
};
