import PropTypes from "prop-types";
import React, { Component } from "react";

import InspectionGridRowContainer from "../../containers/list/InspectionGridRowContainer";
import * as listUtil from "../../lib/list";

import InspectionGridRow from "./InspectionGridRow";

class InspectionGridBody extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <table className="tbl-basic tbl-data list-table-row">
        <tbody className={`tbl-area ${this.props.switcherStatus === -1 ? "" : `cursor-${this.props.switcherStatus}`}`}>
          {this.props.rows.map((row, index) => {
            const statusNumber = listUtil.getStatusNumber(row.valid_flg, row.status, row.schedule_type);
            let className = "";

            if (row.processing) {
              className = "row-processing";
            }

            if (row.error) {
              className = className + " grid-cell-error";
            }

            return (
              <InspectionGridRowContainer
                key={index}
                no={index + 1}
                row={row}
                statusNumber={statusNumber}
                className={className}
                showTaskEditor={this.props.showTaskEditor}
              />
            );
          })}
        </tbody>
      </table>
    );
  }
}

InspectionGridBody.propTypes = {
  // currentRowIndex: PropTypes.number.isRequired,
  // enterRow: PropTypes.func.isRequired,
  // leaveRow: PropTypes.func.isRequired,
  // rows: PropTypes.array.isRequired,
  // showTaskEditor: PropTypes.func.isRequired,
  // switcherStatus: PropTypes.number.isRequired
};

export default InspectionGridBody;
