import PropTypes from "prop-types";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { withTranslation } from "react-i18next";
import validator from "validator";

import Modal from "../Modal";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { isIE } from "../../lib/common";
import { SubmitButton } from "@/components/common/SubmitButton";

class SystemEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      system_id: 0,
      system_name: "",
      totaltest_schedule_date: "",
      totaltest_result_date: "",
      system_comment: "",
      error: {},
      loading: false,
      // isOpenScheduleDate: false,
      // isOpenResultDate: false,
      scrollTop: 0,
      inputSchedule: "",
      inputResult: "",
      openCalender: null,
      onCalendar: false,
      datepickerPos: null,
    };

    this.handleChangeSystemName = this.handleChangeSystemName.bind(this);
    this.handleChangeScheduleDate = this.handleChangeScheduleDate.bind(this);
    this.handleChangeResultDate = this.handleChangeResultDate.bind(this);
    this.handleChangeComment = this.handleChangeComment.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDateSchedule = this.handleDateSchedule.bind(this);
    this.handleDateResult = this.handleDateResult.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleInputSchedule = this.handleInputSchedule.bind(this);
    this.handleInputResult = this.handleInputResult.bind(this);
    this.openScheduleCalendar = this.openScheduleCalendar.bind(this);
    this.openResultCalendar = this.openResultCalendar.bind(this);
    this.closeCalendarResult = this.closeCalendarResult.bind(this);
    this.closeCalendarSchedule = this.closeCalendarSchedule.bind(this);
    this.closeCalendar = this.closeCalendar.bind(this);
    this.setDatepickerPos = this.setDatepickerPos.bind(this);
    this.getDatepickerStyle = this.getDatepickerStyle.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    window.addEventListener("resize", this.setDatepickerPos);
    setTimeout(this.setDatepickerPos, 500);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setDatepickerPos);
  }

  fetchData() {
    const { data } = this.props;

    if (data) {
      const { system_id, system_name, totaltest_schedule_date, totaltest_result_date, system_comment, timestamp } =
        data;

      this.setState({
        system_id,
        system_name: system_name ? system_name : "",
        totaltest_schedule_date,
        totaltest_result_date,
        inputSchedule: totaltest_schedule_date ? moment(totaltest_schedule_date).format("YYYY/MM/DD").toString() : "",
        inputResult: totaltest_result_date ? moment(totaltest_result_date).format("YYYY/MM/DD").toString() : "",
        system_comment: system_comment ? system_comment : "",
        timestamp,
        loading: false,
        error: {},
      });
    }
  }

  handleChangeSystemName(e) {
    this.setState({
      system_name: e.target.value,
    });
  }

  handleChangeScheduleDate(date) {
    this.setState({
      totaltest_schedule_date: date
        ? date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
        : null,
    });
  }
  handleChangeResultDate(date) {
    this.setState({
      totaltest_result_date: date
        ? date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
        : null,
    });
  }

  handleChangeComment(e) {
    this.setState({
      system_comment: e.target.value,
    });
  }

  handleSave() {
    if (this.state.loading) return;
    const error = {};
    const system_name = this.state.system_name ? this.state.system_name.trim() : "";
    const system_comment = this.state.system_comment ? this.state.system_comment.trim() : "";
    const newState = { ...this.state, system_name };

    if (validator.isEmpty(system_name)) {
      error.system_name = "system_name";
      error.msg = "is_required";
    } else if (system_name.length > 100) {
      error.system_name = "system_name";
      error.msg = "is_too_long";
    }
    if (system_comment.length > 100) {
      error.system_comment = "note";
      error.msg = "is_too_long";
    }

    newState.error = error;

    if (Object.keys(error).length > 0) {
      this.setState(newState);
    } else {
      newState.loading = true;
      this.setState(newState);
      this.props.updateItem(
        newState,
        () => {
          this.setState({ loading: false });
          this.props.closeHandler();
        },
        () => {
          this.setState({ loading: false });
        }
      );
    }
  }

  handleInputSchedule(e) {
    const string = e.target.value;
    this.setState({ inputSchedule: string });
    const date = string ? moment(string, "YYYY/MM/DD").toDate() : "";
    if (date.toString() !== "Invalid Date") {
      this.handleChangeScheduleDate(date);
    }
  }

  handleInputResult(e) {
    const string = e.target.value;
    this.setState({ inputResult: string });
    const date = string ? moment(string, "YYYY/MM/DD").toDate() : "";
    if (date.toString() !== "Invalid Date") {
      this.handleChangeResultDate(date);
    }
  }

  handleDateSchedule(date) {
    this.setState({ inputSchedule: date ? moment(date).format("YYYY/MM/DD").toString() : "" });
    this.handleChangeScheduleDate(date);
    this.closeCalendar();
  }

  handleDateResult(date) {
    this.setState({ inputResult: date ? moment(date).format("YYYY/MM/DD").toString() : "" });
    this.handleChangeResultDate(date);
    this.closeCalendar();
  }

  openScheduleCalendar() {
    const calendarName = "schedule";
    if (this.state.openCalender !== calendarName) {
      this.setState({ openCalender: calendarName });
    }
  }

  openResultCalendar() {
    const calendarName = "result";
    if (this.state.openCalender !== calendarName) {
      this.setState({ openCalender: calendarName });
    }
  }

  closeCalendar() {
    if (!this.state.onCalendar && this.state.openCalender) {
      this.setState({ openCalender: null, onCalendar: false });
    }
  }

  closeCalendarSchedule() {
    const { selected } = this.datePickerSchedule.props;
    if (selected) {
      this.setState({ inputSchedule: moment(selected).format("YYYY/MM/DD").toString() });
    }
    if (!this.state.onCalendar && this.state.openCalender) {
      this.setState({ openCalender: null, onCalendar: false });
    }
  }

  closeCalendarResult() {
    const { selected } = this.datePickerResult.props;
    if (selected) {
      this.setState({ inputResult: moment(selected).format("YYYY/MM/DD").toString() });
    }
    if (!this.state.onCalendar && this.state.openCalender) {
      this.setState({ openCalender: null, onCalendar: false });
    }
  }

  handleScroll() {
    const container = this.body;
    const scrollTop = container.scrollTop;
    this.setState({ scrollTop });
  }

  setDatepickerPos() {
    if (!isIE()) {
      return;
    }

    this.setState({
      datepickerPos: {
        scheduleDate: this.getDatepickerPos(this.inputScheduleDate),
        resultDate: this.getDatepickerPos(this.inputResultDate),
      },
    });
  }

  getDatepickerPos(elm) {
    const rect = elm.getBoundingClientRect();

    return {
      x: rect.left,
      y: rect.top + rect.height + 2,
    };
  }

  getDatepickerStyle(key, style) {
    if (isIE()) {
      return {
        position: "fixed",
        zIndex: 1300,
        left: this.state.datepickerPos[key].x,
        top: this.state.datepickerPos[key].y,
      };
    }

    return style;
  }

  render() {
    const { system_name, totaltest_schedule_date, totaltest_result_date, system_comment, error, loading, scrollTop } =
      this.state;
    const { titleName, fetching, closeHandler, t } = this.props;

    const datepickerModalStyle = {
      position: "fixed",
      zIndex: 1300,
      marginTop: 30 - scrollTop,
      marginLeft: 110,
    };

    return (
      <Modal title={titleName} loading={fetching} closeHandler={closeHandler} onDrag={this.setDatepickerPos}>
        <div className="modal-body system-editor" onScroll={this.handleScroll} ref={(node) => (this.body = node)}>
          <div className="form-row">
            <div className="form-group w-400">
              <span className="form-label txt-bold">{t("system_name")}</span>
              <input
                data-test-id="text-system-edit-system-name"
                type="text"
                className="form-control w-290"
                value={system_name}
                onChange={this.handleChangeSystemName}
              />
            </div>
            {error.system_name && (
              <div className="form-error w-400">
                <p className="form-error-message w-290">
                  {t(error.system_name)}
                  {t(error.msg)}
                </p>
              </div>
            )}
          </div>
          <div className="form-row">
            <div className="form-group w-250">
              <span className="form-label txt-bold">{t("airproof_sche_date")}</span>
              <div
                className="schedule-datepicker"
                onMouseEnter={() => this.setState({ onCalendar: true })}
                onMouseLeave={() => this.setState({ onCalendar: false })}
              >
                <input
                  type="text"
                  onFocus={this.openScheduleCalendar}
                  className="form-control w-140"
                  value={this.state.inputSchedule}
                  onChange={this.handleInputSchedule}
                  ref={(node) => (this.inputScheduleDate = node)}
                />
              </div>
            </div>
            {this.state.openCalender === "schedule" && (
              <div style={this.getDatepickerStyle("scheduleDate", datepickerModalStyle)}>
                <DatePicker
                  ref={(node) => (this.datePickerSchedule = node)}
                  selected={totaltest_schedule_date ? moment(totaltest_schedule_date, "YYYY/MM/DD").toDate() : null}
                  onChange={this.handleDateSchedule}
                  dateFormat="yyyy/MM/dd"
                  allowSameDay
                  inline
                  onClickOutside={this.closeCalendarSchedule}
                  locale={t("calender_locale")}
                />
              </div>
            )}
          </div>
          <div className="form-row">
            <div className="form-group w-250">
              <span className="form-label txt-bold">{t("airproof_result_date")}</span>
              <div
                className="schedule-datepicker"
                onMouseEnter={() => this.setState({ onCalendar: true })}
                onMouseLeave={() => this.setState({ onCalendar: false })}
              >
                <input
                  type="text"
                  onFocus={this.openResultCalendar}
                  className="form-control w-140"
                  value={this.state.inputResult}
                  onChange={this.handleInputResult}
                  ref={(node) => (this.inputResultDate = node)}
                />
              </div>
            </div>
            {this.state.openCalender === "result" && (
              <div style={this.getDatepickerStyle("resultDate", datepickerModalStyle)}>
                <DatePicker
                  ref={(node) => (this.datePickerResult = node)}
                  selected={totaltest_result_date ? moment(totaltest_result_date, "YYYY/MM/DD").toDate() : null}
                  onChange={this.handleDateResult}
                  dateFormat="yyyy/MM/dd"
                  allowSameDay
                  inline
                  onClickOutside={this.closeCalendarResult}
                  locale={t("calender_locale")}
                />
              </div>
            )}
          </div>
          <div className="form-row">
            <div className="form-group w-400">
              <span className="form-label txt-bold">{t("note")}</span>
              <textarea
                data-test-id="text-system-edit-note"
                rows="4"
                className="form-control w-290"
                value={system_comment}
                onChange={this.handleChangeComment}
              ></textarea>
            </div>
            {error.system_comment && (
              <div className="form-error w-400">
                <p className="form-error-message w-290">
                  {t(error.system_comment)}
                  {t(error.msg)}
                </p>
              </div>
            )}
          </div>
        </div>

        <div className={"modal-footer"}>
          <button
            data-test-id="button-system-edit-cancel"
            type="button"
            className="btn btn-gray"
            disabled={loading}
            onClick={closeHandler}
          >
            {t("cancel")}
          </button>
          <SubmitButton data-test-id="button-system-edit-save" onClick={this.handleSave} loading={loading} />
        </div>
      </Modal>
    );
  }
}

SystemEditor.defaultProps = {
  // data: null
};

SystemEditor.propTypes = {
  closeHandler: PropTypes.func.isRequired,
};

export default withTranslation()(SystemEditor);
