import { connect } from "react-redux";

import actions from "../../actions";
import TaskEditor from "../../components/common/TaskEditor";

const mapStateToProps = (state) => {
  let extensions = [];
  let fileUpload = true;

  // 工事拡張種別：作業人数追加
  let canEditNumberOfWorkers = false;

  if (state.construction.extensions) {
    extensions = state.construction.extensions.extensions.filter((item) => item.extension_type === 5);
    if (extensions.length > 0) {
      const config = JSON.parse(extensions[0].config);
      fileUpload = config.file_upload.task;
    }

    canEditNumberOfWorkers = state.construction.extensions.extensions.some((item) => item.extension_type === 15);
  }

  return {
    masters: state.construction.masters,
    rows: state.matrix.rows,
    columns: state.matrix.columns,
    session: state.session,
    fileUpload,
    canEditNumberOfWorkers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetch: (taskId, callback) => dispatch(actions.matrix.fetchTaskDetail(taskId, callback)),
    upload: (data, onProgress, onSuccess, onError) =>
      dispatch(actions.app.upload(data, onProgress, onSuccess, onError)),
    download: (kind, fileId, qrFlag, callback) =>
      dispatch(actions.common.downloadAttachmentFile(kind, fileId, qrFlag, callback)),
    removeFile: (title, messages, okClickHandler) => {
      dispatch(
        actions.app.showConfirm(
          title,
          messages,
          () => {
            okClickHandler();
            dispatch(actions.app.hideConfirm());
          },
          () => {
            dispatch(actions.app.hideConfirm());
          }
        )
      );
    },
    removeComment: (title, messages, okClickHandler) => {
      dispatch(
        actions.app.showConfirm(
          title,
          messages,
          () => {
            okClickHandler();
            dispatch(actions.app.hideConfirm());
          },
          () => {
            dispatch(actions.app.hideConfirm());
          }
        )
      );
    },
    updateTaskDetail: (itemId, taskId, data, callback, failedCallback, reload) =>
      dispatch(actions.matrix.updateTaskDetail(itemId, taskId, data, callback, failedCallback, reload)),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
    showAlert: (title, messages) =>
      dispatch(actions.app.showAlert(title, messages, () => dispatch(actions.app.hideAlert()))),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskEditor);
