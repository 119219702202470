import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import * as util from "../lib/common";

import Modal from "./Modal";

class TwoFactorAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      securityCode: "",
      authenticating: false,
      error: {},
    };

    this.handleChangeSecurityCode = this.handleChangeSecurityCode.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.props.setUnloadAlert();
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();
  }

  handleChangeSecurityCode(e) {
    this.setState({ securityCode: e.target.value });
  }

  handleSave() {
    const error = {};
    const { securityCode } = this.state;
    const { verify, close, t } = this.props;

    if (securityCode === "") {
      error.securityCode = t("two_step_auth_error_unentered");
    } else if (!securityCode.match(/^[0-9]+$/)) {
      error.securityCode = t("two_step_auth_error_invalid");
    }

    if (Object.keys(error).length > 0) {
      this.setState({ error });
    } else {
      this.setState({ authenticating: true });
      verify(securityCode, () => {
        this.setState({ authenticating: false });
      });
    }
  }

  render() {
    const { close, t, securityCodeType, securityCodeDestination } = this.props;
    const { securityCode, error, authenticating } = this.state;

    return (
      <Modal title={t("two_step_auth")} closeHandler={close}>
        <div className="modal-body">
          <div className="form-row">
            <p className="w-300 mlr-auto mb-20">
              {securityCodeType === 1 ? t("two_step_auth_tel_description") : t("two_step_auth_email_description")}
            </p>
            <p className="txt-center mb-20">
              {t("security_code_destination")}
              {securityCodeDestination}
            </p>
            <div className="form-group w-360 txt-center">
              <input
                className="form-control w-300"
                value={securityCode}
                placeholder={t("security_code_placeholder")}
                onChange={this.handleChangeSecurityCode}
              />
            </div>
            {error.securityCode && (
              <div className="form-error w-300">
                <p className="form-error-message w-300 ml-30">{error.securityCode}</p>
              </div>
            )}
          </div>
        </div>
        <div className={`modal-footer ${authenticating ? "loading loading--dialog" : ""}`}>
          <button type="button" className="btn btn-gray" onClick={close}>
            {t("cancel")}
          </button>
          <button type="button" className="btn btn-blue" onClick={this.handleSave}>
            {t("alert_ok")}
          </button>
        </div>
      </Modal>
    );
  }
}

TwoFactorAuth.propTypes = {
  close: PropTypes.func.isRequired,
  verify: PropTypes.func.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
  securityCodeType: PropTypes.number,
  securityCodeDestination: PropTypes.string,
};

export default withTranslation()(TwoFactorAuth);
