import PropTypes from "prop-types";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { withTranslation } from "react-i18next";
import validator from "validator";

import { ACCEPT_UPLOAD_EXTENSIONS } from "../../constants";
import * as util_com from "../../lib/common";
import { convertToValidSafetyMattersString, isIE } from "../../lib/common";
import { isValidRole } from "../../lib/roleChecker";
import FileItem from "../FileItem";
import Modal from "../Modal";

import CommentEditor from "./CommentEditor";

import {
  isPositiveFiniteNumber,
  isPositiveInteger,
  isToSecondDecimalPlace,
  isValidNumberRangeMax,
  isValidNumberRange,
} from "@/lib/validate";

import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";
import _ from "lodash";

import ChargeSelect from "../common/ChargeSelect";

import { ExplanatoryNote } from "@/components/list/Schedule/components/ExplanatoryNote";
import { FireTypeAbbreviationNote } from "@/components/list/Schedule/components/FireTypeAbbreviationNote";

import { SubmitButton } from "@/components/common/SubmitButton";
import { Task, TaskScheduleType, TaskScheduleTypeType, TaskStatus } from "@/models/tasks";

const scheduleStart = "scheduleStart";
const scheduleEnd = "scheduleEnd";
const resultStart = "resultStart";
const resultEnd = "resultEnd";

type dateTypeText = "scheduleStartText" | "scheduleEndText" | "resultStartText" | "resultEndText";

type dateTypeDate = "schedule_start_date" | "schedule_end_date" | "result_start_date" | "result_end_date";

const dateStateMap: Record<
  typeof scheduleStart | typeof scheduleEnd | typeof resultStart | typeof resultEnd,
  { textState: dateTypeText; dateState: dateTypeDate }
> = {
  scheduleStart: { textState: "scheduleStartText", dateState: "schedule_start_date" },
  scheduleEnd: { textState: "scheduleEndText", dateState: "schedule_end_date" },
  resultStart: { textState: "resultStartText", dateState: "result_start_date" },
  resultEnd: { textState: "resultEndText", dateState: "result_end_date" },
};

class TaskEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      item_id: 0,
      initial_status: 0,
      visibleCommentEditor: false,
      fetching: false,
      valid_flg: false,
      initial_schedule_type: 0,
      schedule_type: TaskScheduleType.EMPTY,
      ahead_days: null,
      status: 0,
      schedule_start_date: null,
      schedule_end_date: null,
      result_start_date: null,
      result_end_date: null,
      fetchedScheduleType: null,
      fetchedAheadDays: null,
      scheduleStartText: "",
      scheduleEndText: "",
      resultStartText: "",
      resultEndText: "",
      checkpoints: [],
      document_no: "",
      weight: 0,
      weight_default: 0,
      cost: 0,
      company_id: 0,
      user_id: 0,
      group_id: 0,
      comments: [],
      files: [],
      showLightbox: false,
      lightboxSrc: "",
      error: {},
      updating: false,
      editingComment: null,
      timestamp: null,
      openCalender: null,
      onCalendar: false,
      scrollTop: 0,
      roles: [],
      category_id: 0,
      datepickerPos: null,
      scheduleDateText: "",
      progress: 0,
      fetchedProgress: 0,
      initial_progress: 0,
      progressDisp: 0,
      tooltipLink: null,
      prevScheduleType: -1,
      prevProgress: 0,
      field_t1: null,
      field_t2: null,
      field_t3: null,
      field_t4: null,
      field_t5: null,
      field_t6: null,
      field_t7: null,
      field_t8: null,
      field_t9: null,
      unworkDays: [],
      schedule_roles: [],

      tooltipData: null,
      isTooltip: false,
      showSafetyMattersExplanatoryNote: false,
      explanatoryNoteTop: 0,
      explanatoryNoteLeft: 0,
      showUsedFireTypeAbbreviationNote: false,
      fireTypeAbbreviationNoteTop: 0,
      fireTypeAbbreviationNoteLeft: 0,
    };

    this.handleChangeValidFlag = this.handleChangeValidFlag.bind(this);
    this.getProgressByScheduleType = this.getProgressByScheduleType.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.changeStatusDone = this.changeStatusDone.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeWeight = this.handleChangeWeight.bind(this);
    this.handleChangeCheckpoint = this.handleChangeCheckpoint.bind(this);
    this.handleChangeDocumentNo = this.handleChangeDocumentNo.bind(this);
    this.handleChangeCompany = this.handleChangeCompany.bind(this);
    this.handleChangeCharge = this.handleChangeCharge.bind(this);
    this.handleOpenLightbox = this.handleOpenLightbox.bind(this);
    this.handleCloseLightbox = this.handleCloseLightbox.bind(this);
    this.handleRemoveFile = this.handleRemoveFile.bind(this);
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.handleUploadProgress = this.handleUploadProgress.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
    this.handleUploadError = this.handleUploadError.bind(this);
    this.handleRemoveComment = this.handleRemoveComment.bind(this);
    this.showCommentEditor = this.showCommentEditor.bind(this);
    this.hideCommentEditor = this.hideCommentEditor.bind(this);
    this.saveCommentEditor = this.saveCommentEditor.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChangeDatePicker = this.handleChangeDatePicker.bind(this);
    this.handleDateText = this.handleDateText.bind(this);
    this.handleCloseResultEnd = this.handleCloseResultEnd.bind(this);
    this.convertDateToString = this.convertDateToString.bind(this);
    this.openDatePicker = this.openDatePicker.bind(this);
    this.closeDatePicker = this.closeDatePicker.bind(this);
    this.closeCalendar = this.closeCalendar.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.setDatepickerPos = this.setDatepickerPos.bind(this);
    this.getDatepickerStyle = this.getDatepickerStyle.bind(this);
    this.handleChangeFieldT1 = this.handleChangeFieldT1.bind(this);
    this.handleChangeFieldT2 = this.handleChangeFieldT2.bind(this);
    this.handleChangeFieldT3 = this.handleChangeFieldT3.bind(this);
    this.handleChangeFieldT4 = this.handleChangeFieldT4.bind(this);
    this.handleChangeFieldT5 = this.handleChangeFieldT5.bind(this);
    this.handleChangeFieldT6 = this.handleChangeFieldT6.bind(this);
    this.handleChangeFieldT7 = this.handleChangeFieldT7.bind(this);
    this.handleChangeFieldT8 = this.handleChangeFieldT8.bind(this);
    this.handleFocusFieldT4 = this.handleFocusFieldT4.bind(this);
    this.handleBlurFieldT4 = this.handleBlurFieldT4.bind(this);
    this.handleChangeFieldT9 = this.handleChangeFieldT9.bind(this);
    this.handleFocusFieldT8 = this.handleFocusFieldT8.bind(this);
    this.handleBlurFieldT8 = this.handleBlurFieldT8.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.setDatepickerPos);

    this.setState({ fetching: true });
    this.fetch();
    this.props.setUnloadAlert();
    setTimeout(this.setDatepickerPos, 500);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setDatepickerPos);
    this.props.clearUnloadAlert();
  }

  fetch() {
    this.setState({ fetching: true });
    this.props.fetch(this.props.taskId, (data: Task) => {
      const {
        process_id,
        item_id,
        valid_flg,
        schedule_type,
        ahead_days,
        status,
        schedule_start_date,
        schedule_end_date,
        result_start_date,
        result_end_date,
        checkpoints,
        document_no,
        weight,
        weight_default,
        cost,
        timestamp,
        roles,
        schedule_date,
        process_company_id,
        progress,
        field_t1,
        field_t2,
        field_t3,
        field_t4,
        field_t5,
        field_t6,
        field_t7,
        field_t8,
        field_t9,
        schedule_roles,
        unwork_days,
      } = data;

      const user_id = data.user_id || 0;
      const group_id = data.group_id || 0;
      const files = data.files.map((f) => {
        f.mode = 0;

        return f;
      });

      const comments = data.comments.map((c) => {
        c.mode = 0;

        return c;
      });

      let company_id = 0;

      if (user_id > 0) {
        this.props.masters.users.forEach((user) => {
          if (user.user_id === user_id) {
            company_id = user.company_id;
          }
        });
      }

      if (group_id > 0) {
        this.props.masters.groups.forEach((group) => {
          if (group.group_id === group_id) {
            company_id = group.company_id;
          }
        });
      }

      const rows = this.props.rows.filter((row) => row.item_id === item_id);
      const itemName = rows.length > 0 ? rows[0].item_name : "";
      const categoryId = rows.length > 0 ? rows[0].category_id : this.props.categoryId;
      const columns = this.props.columns.filter((column) => column.process_id === process_id);
      const processName = columns.length > 0 ? columns[0].process_name : "";

      let title = `[${this.props.itemName}] - [${this.props.processName}]`;

      if (itemName !== "" && processName !== "") {
        title = `[${itemName}] - [${processName}]`;
      }

      const fixedProgress =
        status === TaskStatus.COMPLETE ? 1 : this.getProgressByScheduleType(schedule_type) ?? progress;
      const fetchedAheadDays =
        schedule_type === TaskScheduleType.SCHEDULED || schedule_type === TaskScheduleType.CONTINUE
          ? ahead_days || 1
          : ahead_days;
      this.setState({
        item_id,
        title,
        valid_flg,
        initial_schedule_type: schedule_type,
        schedule_type,
        fetchedScheduleType: schedule_type,
        // 予定・継続だけどahead_daysがない場合は1(日)とする
        ahead_days: fetchedAheadDays,
        fetchedAheadDays,
        initial_status: status,
        status,
        schedule_start_date,
        schedule_end_date,
        result_start_date,
        result_end_date,
        initial_result_end_date: result_end_date,
        scheduleStartText: this.convertDateToString(schedule_start_date),
        scheduleEndText: this.convertDateToString(schedule_end_date),
        resultStartText: this.convertDateToString(result_start_date),
        resultEndText: this.convertDateToString(result_end_date),
        checkpoints,
        document_no,
        weight,
        weight_default,
        cost,
        user_id,
        group_id,
        comments,
        files,
        company_id,
        timestamp,
        fetching: false,
        roles,
        category_id: categoryId,
        scheduleDateText: this.convertDateToString(schedule_date),
        process_company_id,
        progress: fixedProgress,
        fetchedProgress: fixedProgress,
        initial_progress: fixedProgress,
        progressDisp: Math.trunc(fixedProgress * 100),
        prevScheduleType: -1,
        flagContinue: !valid_flg && schedule_type === TaskScheduleType.CONTINUE,
        field_t1,
        field_t2,
        field_t3,
        field_t4,
        field_t5,
        field_t6,
        field_t7,
        field_t8,
        field_t9,
        schedule_roles: schedule_roles || [],
        unworkDays: unwork_days.map((v) => ({ date: v, holidayName: "" })),
      });
    });
  }

  getProgressByScheduleType(type: TaskScheduleTypeType): number | undefined {
    switch (type) {
      case TaskScheduleType.EMPTY:
        return 0;
      case TaskScheduleType.SCHEDULED:
        return 0;
      case TaskScheduleType.CONTINUE:
        return undefined;
      case TaskScheduleType.COMPLETING:
        return 0.8;
      case TaskScheduleType.INTERRUPT:
        return 0.5;
      default:
        return undefined;
    }
  }
  handleChangeValidFlag(valid_flg) {
    this.setState({ valid_flg });

    const { status, schedule_type, flagContinue, result_end_date } = this.state;

    if (valid_flg && status === TaskStatus.COMPLETE && !result_end_date) {
      const resultEndDate = moment().format("YYYY-MM-DD").toString();
      const resultEndText = this.convertDateToString(resultEndDate);
      this.setState({ result_end_date: resultEndDate, resultEndText });
    }
    if (valid_flg) {
      this.setState({
        schedule_type: this.state.fetchedScheduleType,
        ahead_days: this.state.fetchedAheadDays,
      });
    }
    if (flagContinue) {
      this.setState({
        progressDisp:
          status === TaskStatus.COMPLETE
            ? 100
            : Math.trunc((this.getProgressByScheduleType(schedule_type) ?? 0.5) * 100),
        flagContinue: false,
        initial_progress: 0.5,
      });
    }
    if (!valid_flg) {
      const result_end_date = null;
      const resultEndText = "";
      this.setState({ result_end_date, resultEndText });
    }
  }

  handleChangeStatus(status, schedule_type, ahead_days?: 1 | 2 | 3) {
    // 進捗が完了状態から直前の状態に戻すときの自動補完のために、予定日数はnullにしない。必要なければsubmitするときにnullにする。
    const o = { status, ahead_days: ahead_days ?? this.state.ahead_days ?? 1 };

    if (schedule_type !== null) {
      o.schedule_type = schedule_type;
    }

    if (status === 1) {
      // 完了の場合は実績終了日が未入力の場合は当日を設定
      o.result_end_date = moment().format("YYYY-MM-DD");
      o.resultEndText = moment().format("YYYY/MM/DD");
    } else {
      // 完了以外の場合は実績終了日をクリア
      o.result_end_date = null;
      o.resultEndText = "";
    }

    o.prevProgress = this.state.progressDisp;
    const isContinue =
      this.state.status === TaskStatus.INCOMPLETE &&
      schedule_type === TaskScheduleType.CONTINUE &&
      this.state.schedule_type === schedule_type;
    o.progressDisp =
      status === TaskStatus.COMPLETE
        ? 100
        : isContinue
        ? this.state.progressDisp
        : (this.getProgressByScheduleType(schedule_type) ?? 0.5) * 100;

    o.prevScheduleType = this.state.schedule_type;

    this.setState(o);
  }

  changeStatusDone() {
    this.setState({
      status: TaskStatus.COMPLETE,
      schedule_type: null,
      prevProgress: this.state.progressDisp,
      progressDisp: 100,
      prevScheduleType: this.state.schedule_type,
    });
  }

  handleChangeDate(date, target) {
    let formatedDate = null;
    if (date) {
      formatedDate = moment(date).format("YYYY-MM-DD").toString();
    }
    this.setState({ [target]: formatedDate });
  }

  handleChangeWeight(e) {
    this.setState({ weight: e.target.value });
  }

  handleChangeCheckpoint(checkpoint_id, checked) {
    if (checked) {
      this.setState({ checkpoints: [...this.state.checkpoints, checkpoint_id] });
    } else {
      this.setState({ checkpoints: this.state.checkpoints.filter((item) => item != checkpoint_id) });
    }
  }

  handleChangeDocumentNo(e) {
    this.setState({ document_no: e.target.value });
  }

  handleChangeCompany(e) {
    this.setState({
      company_id: Number(e.target.value),
      user_id: 0,
      group_id: 0,
    });
  }

  handleChangeCharge(e) {
    this.setState({
      company_id: e.company_id,
      user_id: e.user_id,
      group_id: e.group_id,
    });
  }

  handleChangeFieldT1(e) {
    this.setState({ field_t1: e.target.value === "" ? null : e.target.value });
  }

  handleChangeFieldT2() {
    this.setState({ field_t2: this.state.field_t2 ? 0 : 1 });
  }

  handleChangeFieldT3() {
    this.setState({ field_t3: this.state.field_t3 ? 0 : 1 });
  }

  handleChangeFieldT4(e) {
    this.setState({ field_t4: e.target.value === "" ? null : e.target.value });
  }

  handleChangeFieldT5() {
    this.setState({ field_t5: this.state.field_t5 ? 0 : 1 });
  }

  handleChangeFieldT6() {
    this.setState({ field_t6: this.state.field_t6 ? 0 : 1 });
  }

  handleChangeFieldT7(e) {
    this.setState({ field_t7: e.target.value === "" ? null : e.target.value });
  }

  handleChangeFieldT8(e) {
    this.setState({ field_t8: e.target.value === "" ? null : e.target.value });
  }

  handleFocusFieldT4() {
    this.setState({
      showUsedFireTypeAbbreviationNote: true,
      fireTypeAbbreviationNoteTop: this.inputFieldT4.offsetTop - 60,
      fireTypeAbbreviationNoteLeft: this.inputFieldT4.offsetLeft + this.inputFieldT4.offsetWidth + 10,
    });
  }

  handleBlurFieldT4() {
    this.setState({ showUsedFireTypeAbbreviationNote: false });
  }

  handleChangeFieldT9(e) {
    this.setState({ field_t9: e.target.value === "" ? null : e.target.value });
  }

  handleFocusFieldT8() {
    this.setState({
      showSafetyMattersExplanatoryNote: true,
      explanatoryNoteTop: this.inputFieldT8.offsetTop - 90,
      explanatoryNoteLeft: this.inputFieldT8.offsetLeft + this.inputFieldT8.offsetWidth + 10,
    });
  }

  handleBlurFieldT8(e) {
    const value = e.target.value;
    if (value) {
      this.setState({ field_t8: convertToValidSafetyMattersString(value) });
    }
    this.setState({ showSafetyMattersExplanatoryNote: false });
  }

  handleOpenLightbox(lightboxSrc) {
    this.setState({
      showLightbox: true,
      lightboxSrc,
    });
  }

  handleCloseLightbox() {
    this.setState({ showLightbox: false });
  }

  handleRemoveFile(fileId, isTemp) {
    const { t } = this.props;
    this.props.removeFile(
      t("delete_file"),
      [t("delete_file_alert")],
      () => {
        this.setState({
          files: this.state.files
            .map((f) => {
              if (isTemp && f.temp_file_id === fileId) {
                f.mode = -1;
              } else if (!isTemp && f.file_id === fileId) {
                f.mode = 2;
              }

              return f;
            })
            .filter((f) => f.mode !== -1),
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function -- 暫定処置でdisableしている
      () => {}
    );
  }

  handleFileSelect() {
    this.file.click();
  }

  handleUploadProgress(key, e) {
    this.setState({
      files: this.state.files.map((file) => {
        if (file.temp && file.key === key) {
          file.progress = (e.loaded / e.total) * 100;
        }

        return file;
      }),
    });
  }

  handleUploadComplete(key, e) {
    this.setState({
      files: this.state.files.map((file) => {
        if (file.temp && file.key === key) {
          file.progress = 100;
          file.temp_file_id = e.temp_file_id;
        }

        return file;
      }),
    });
  }

  handleUploadError(key, e) {
    const { t } = this.props;

    const msgs = e.response.errors?.map((m) => m.err_message).filter((m) => m);
    if (msgs) {
      this.props.showAlert(t("error"), msgs);
    }

    const file = this.state.files.find((f) => f.key === key);

    if (file) {
      this.setState({ files: this.state.files.filter((f) => f.key !== key) });
    }
  }

  handleChangeFile(e) {
    let files = [];

    Array.prototype.forEach.call(e.target.files, (file) => {
      files = [
        ...files,
        {
          key: Symbol(),
          file_name: file.name,
          file_format: file.type,
          mode: 1,
          temp: true,
          progress: 0,
          done: false,
          data: file,
        },
      ];
    });

    if (files.some((v) => 100 < v.file_name.length)) {
      const { t } = this.props;
      this.setState({ error: { ...this.state.error, file: t("file_name_is_too_long") } });

      return;
    } else {
      this.setState({ error: { ...this.state.error, file: null } });
    }

    files.forEach((file) => {
      const formData = new FormData();

      formData.append("target", 3);
      formData.append("uploadfile", file.data);
      this.props.upload(
        formData,
        (e) => this.handleUploadProgress(file.key, e),
        (e) => this.handleUploadComplete(file.key, e),
        (e) => this.handleUploadError(file.key, e)
      );
    });

    this.setState({
      files: [...files, ...this.state.files],
    });
  }

  handleRemoveComment(comment_id) {
    const { t } = this.props;
    this.props.removeComment(t("confirm_delete"), [t("delete_comment_alert")], () =>
      this.setState({
        comments: this.state.comments
          .map((c) => {
            if (c.comment_id === comment_id) {
              c.mode = c.mode === 1 ? -1 : 2;
            }

            return c;
          })
          .filter((c) => c.mode !== -1),
      })
    );
  }

  showCommentEditor(comment) {
    this.setState({
      visibleCommentEditor: true,
      editingComment: comment,
    });
  }

  hideCommentEditor() {
    this.setState({
      visibleCommentEditor: false,
      editingComment: null,
    });
  }

  saveCommentEditor(commentId, text) {
    let comments = [...this.state.comments];
    const { userId, userName } = this.props.session;
    if (commentId === 0) {
      let newId = 1;

      if (comments.length > 0) {
        newId = _.maxBy(comments, (item) => item.comment_id).comment_id + 1;
      }

      const comment = {
        comment_id: newId,
        task_id: this.props.taskId,
        user_id: userId,
        user_name: userName,
        comment: text,
        mode: 1,
        timestamp: {
          insert_date: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          insert_user_id: userId,
          insert_user_name: userName,
        },
      };

      comments = [comment, ...comments];
    } else {
      comments = comments.map((comment) => {
        if (comment.comment_id === commentId && comment.comment !== text) {
          comment.comment = text;
          comment.mode = comment.mode === 1 ? 1 : 3;
        }

        return comment;
      });
    }

    this.setState({ comments }, this.hideCommentEditor);
  }

  handleSave() {
    if (this.state.updating) return;
    const error = {};
    const {
      item_id,
      valid_flg,
      schedule_type,
      ahead_days,
      status,
      schedule_start_date,
      schedule_end_date,
      result_start_date,
      result_end_date,
      checkpoints,
      document_no,
      weight,
      user_id,
      group_id,
      files,
      timestamp,
      progressDisp,
      field_t1,
      field_t2,
      field_t3,
      field_t4,
      field_t5,
      field_t6,
      field_t7,
      field_t8,
      field_t9,
    } = this.state;

    const comments = this.state.comments.map((c) => {
      const o = {
        task_id: c.task_id,
        user_id: c.user_id,
        comment: c.comment,
        mode: c.mode,
        timestamp: c.timestamp,
      };

      if (c.mode !== 1) {
        o.comment_id = c.comment_id;
      }

      return o;
    });

    const { t } = this.props;

    // 日付のチェック
    if (schedule_start_date !== null && schedule_end_date !== null) {
      const sd = moment(schedule_start_date);
      const ed = moment(schedule_end_date);
      if (ed.diff(sd, "days") < 0) {
        error.schedule_start_date = t("schedule_alert");
      }
    }

    if (result_start_date !== null && result_end_date !== null) {
      const sd = moment(result_start_date);
      const ed = moment(result_end_date);

      if (ed.diff(sd, "days") < 0) {
        error.result_start_date = t("schedule_alert");
      }
    }

    if (!weight && weight !== 0) {
      error.weight = `${t("weight")}${t("input_required_field")}`;
    } else if (!isPositiveFiniteNumber(weight)) {
      error.weight = t("invalid_number_format", { field: t("weight") });
    } else if (!isToSecondDecimalPlace(weight)) {
      error.weight = t("weight_invalid_format");
    } else if (!isValidNumberRange(weight)) {
      error.weight = t("maximum_validation");
    }

    if (progressDisp.length === 0) {
      error.progressDisp = t("empty_progress");
    }
    if (progressDisp !== null && !validator.isEmpty(progressDisp.toString())) {
      if (!validator.isNumeric(progressDisp.toString())) {
        error.progressDisp = t("invalid_progress");
      } else if (Number(progressDisp) < 0 || Number(progressDisp) > 100 || String(progressDisp).indexOf(".") > -1) {
        error.progressDisp = t("percent_validation");
      }
    }

    if (document_no !== null && !validator.isEmpty(document_no) && document_no.length > 60) {
      error.document_no = t("additional_construction") + t("specification") + t("is_too_long_60");
    }

    if (field_t1) {
      if (!isPositiveInteger(field_t1)) {
        error.field_t1 = t("invalid_worker_num");
      } else if (!isValidNumberRangeMax(field_t1, 9999)) {
        error.field_t1 = t("maximum_validation_9999");
      }
    }
    if (field_t1 && !/^\d+$/.test(field_t1)) {
      error.field_t1 = t("invalid_worker_num");
    }

    if (field_t4 && field_t4.length > 100) {
      error.field_t4 = t("used_fire_is_too_long_100");
    }

    if (field_t7 && field_t7.length > 100) {
      error.field_t7 = t("heavy_machine_is_too_long_100");
    }

    if (field_t9) {
      if (!isPositiveFiniteNumber(field_t9)) {
        error.field_t9 = t("invalid_number_format", { field: t("schedule_overtime_hour") });
      } else if (!isToSecondDecimalPlace(field_t9)) {
        error.field_t9 = t("invalid_overtime_format");
      } else if (!isValidNumberRangeMax(field_t9, 99.99)) {
        error.field_t9 = t("overtime_is_max_99");
      }
    }

    if (Object.keys(error).length > 0) {
      this.setState({ error });
    } else {
      const data = {
        valid_flg,
        status,
        // 作業なしまたは完了の場合はAPIで取得したステータスをPOSTする
        schedule_type: !valid_flg || status === TaskStatus.COMPLETE ? TaskScheduleType.EMPTY : schedule_type,
        ahead_days:
          !valid_flg || status === TaskStatus.COMPLETE
            ? null
            : [TaskScheduleType.SCHEDULED, TaskScheduleType.CONTINUE].includes(schedule_type)
            ? ahead_days
            : null,
        timestamp,
        schedule_start_date,
        schedule_end_date,
        result_start_date,
        result_end_date,
        checkpoints,
        document_no,
        user_id,
        group_id,
        weight,
        comments,
        progress: !valid_flg ? 0 : (progressDisp / 100).toFixed(2),
        field_t1: field_t1 !== null ? Number(field_t1) : null,
        field_t2,
        field_t3,
        field_t4,
        field_t5,
        field_t6,
        field_t7,
        field_t8,
        field_t9,
      };

      if (weight !== null && !validator.isEmpty(weight.toString())) {
        data.weight = Number(weight);
      }

      if (!valid_flg) {
        data.status = 0;
      }

      data.files = files
        // アップロード未完了ファイルはPOSTしない
        .filter((f) => {
          if (f.mode === 1 && f.progress && f.progress < 100) {
            return false;
          }

          return true;
        })
        .map((f) => {
          const file = {
            file_name: f.file_name,
            mode: f.mode,
          };

          if (f.temp) {
            file.temp_file_id = f.temp_file_id;
          } else {
            file.file_id = f.file_id;
            file.timestamp = f.timestamp;
          }

          return file;
        });

      this.setState({ updating: true });
      this.props.updateTaskDetail(
        item_id,
        this.props.taskId,
        data,
        () => {
          this.setState({ updating: false });
          this.props.closeHandler();
          this.props.reloadProgressRate && this.props.reloadProgressRate();
        },
        () => {
          this.setState({ updating: false });
        },
        () => {
          this.fetch();
          this.setState({ updating: false });
        }
      );
    }
  }

  convertDateToString(date: Date, format?: string) {
    return date ? moment(date).format(format ?? "YYYY/MM/DD") : "";
  }

  handleDateText(value: string, type: dateTypeText) {
    this.setState({ [type]: value });
  }

  handleCloseResultEnd(dateString: string) {
    if (this.state.status === TaskStatus.COMPLETE && !dateString) {
      // 完了した作業の実績終了日を空にした場合、ステータスを戻す
      if (this.state.prevScheduleType === -1) {
        // 明細編集パネルを開いてから一度もステータスを更新していない場合
        if (this.state.initial_schedule_type === TaskScheduleType.CONTINUE) {
          // 取得した予定種別が継続の場合 (statusが1だったのにこんなパターンあるか?)
          this.setState({ status: TaskStatus.INCOMPLETE, schedule_type: TaskScheduleType.CONTINUE, progressDisp: 50 });
        } else {
          // 現在設定されている予定種別を設定する
          this.setState({
            status: TaskStatus.INCOMPLETE,
            schedule_type: this.state.schedule_type,
            progressDisp: Math.trunc((this.getProgressByScheduleType(this.state.schedule_type) ?? 0) * 100),
          });
        }
      } else {
        // 最初にAPIで取得した値に戻す
        const progressByType = this.getProgressByScheduleType(this.state.fetchedScheduleType) ?? 0.5;
        this.setState({
          status: TaskStatus.INCOMPLETE,
          schedule_type: this.state.fetchedScheduleType,
          ahead_days: this.state.fetchedAheadDays,
          progress: progressByType,
          progressDisp: Math.trunc(progressByType * 100),
        });
      }
    }
    if (this.state.status !== 1 && dateString) {
      this.changeStatusDone();
    }
  }

  handleChangeDatePicker(date: Date, { textState, dateState }: (typeof dateStateMap)[keyof typeof dateStateMap]) {
    const dateString = this.convertDateToString(date);
    this.setState({ [textState]: dateString });
    this.handleChangeDate(date, dateState);

    // 実績終了日でステータスが完了以外の場合は完了にする
    if (textState === "resultEndText" && this.state.status !== 1) {
      this.changeStatusDone();
    }

    this.closeCalendar();
  }

  openDatePicker(e, type: typeof scheduleStart | typeof scheduleEnd | typeof resultStart | typeof resultEnd) {
    e && e.stopPropagation();
    if (this.state.openCalender !== type) {
      this.setState({ openCalender: type });
    }
  }

  closeCalendar() {
    if (!this.state.onCalendar && this.state.openCalender) {
      this.setState({ openCalender: null, onCalendar: false });
    }
  }

  getDateWithYear(dateText: string): Date {
    const currentDate = moment();
    const currentDateAfter6m = moment().add(6, "M");
    const baseDate = moment("7/1", "MM/DD");
    const inputDate = moment(dateText, "MM/DD");

    // 入力実行日が現在日より前の場合
    if (currentDate.isBefore(baseDate)) {
      // 入力値が現在日から6ヶ月後と12/31の間の場合（ちょうど６ヶ月後の日は除外）は前年の日付を返す
      if (inputDate.isBetween(currentDateAfter6m, moment("12/31", "MM/DD"), undefined, "(]")) {
        return inputDate.subtract(1, "y").toDate();
      }
    }
    // 入力実行日が現在日より後の場合
    if (currentDate.isAfter(baseDate)) {
      // 入力値が1/1と現在日から6ヶ月後の間の場合は翌年の日付を返す
      if (inputDate.isBetween(moment("1/1", "MM/DD").add(1, "y"), currentDateAfter6m, undefined, "[]")) {
        return inputDate.add(1, "y").toDate();
      }
    }

    return inputDate.toDate();
  }

  getConvertedDate(dateText: string): Date {
    // MM/DD形式の場合、YYYYを補完する
    if (
      moment(dateText, "MM/DD", true).isValid() ||
      moment(dateText, "M/D", true).isValid() ||
      moment(dateText, "M/DD", true).isValid() ||
      moment(dateText, "MM/D", true).isValid()
    ) {
      return this.getDateWithYear(dateText);
      // YYYY/MM/DD形式の場合、0埋めを行う
    } else if (moment(dateText, "YYYY/MM/DD").isValid()) {
      return moment(dateText, "YYYY/MM/DD").toDate();
      // それ以外の場合は空にする
    } else {
      return null;
    }
  }

  closeDatePicker({ textState, dateState }: (typeof dateStateMap)[keyof typeof dateStateMap]) {
    const convertedDate = this.getConvertedDate(this.state[textState]);

    this.setState(
      {
        [textState]: convertedDate ? this.convertDateToString(convertedDate) : "",
        [dateState]: convertedDate ? this.convertDateToString(convertedDate, "YYYY-MM-DD") : null,
      },
      () => {
        // 実績終了日の場合はステータスを変更する
        if (textState === "resultEndText") {
          this.handleCloseResultEnd(convertedDate ? this.convertDateToString(convertedDate) : "");
        }
      }
    );

    this.closeCalendar();
  }

  handleScroll() {
    const container = this.body;
    const scrollTop = container.scrollTop;
    this.setState({ scrollTop });
  }

  setDatepickerPos() {
    if (!isIE()) {
      return;
    }

    this.setState({
      datepickerPos: {
        scheduleStart: this.getDatepickerPos(this.inputScheduleStart),
        scheduleEnd: this.getDatepickerPos(this.inputScheduleEnd),
        resultStart: this.getDatepickerPos(this.inputResultStart),
        resultEnd: this.getDatepickerPos(this.inputResultEnd),
      },
    });
  }

  getDatepickerPos(elm) {
    const rect = elm.getBoundingClientRect();

    return {
      x: rect.left,
      y: rect.top + rect.height + 2,
    };
  }

  getDatepickerStyle(key, style) {
    if (isIE()) {
      return {
        position: "fixed",
        zIndex: 1300,
        left: this.state.datepickerPos[key].x,
        top: this.state.datepickerPos[key].y,
      };
    }

    return style;
  }

  changeProgressInput(e) {
    this.setState({ progressDisp: e.target.value, progress: e.target.value / 100 });
  }

  render() {
    const error = this.state.error;
    const isInvalid = this.state.valid_flg === false;
    const { download, fileUpload, linkageInfo, t } = this.props;
    const { status, schedule_type, ahead_days, roles, schedule_roles, progressDisp } = this.state;
    const uploading = this.state.files.some((f) => f.mode === 1 && !f.temp_file_id);

    const datepickerModalStyleStart = {
      position: "fixed",
      zIndex: 1300,
      marginTop: 2 - this.state.scrollTop,
      marginLeft: 80,
    };

    const datepickerModalStyleEnd = {
      ...datepickerModalStyleStart,
      marginLeft: 240,
    };

    const schedule_start_date = this.state.schedule_start_date
      ? moment(this.state.schedule_start_date, "YYYY/MM/DD").toDate()
      : null;
    const schedule_end_date = this.state.schedule_end_date
      ? moment(this.state.schedule_end_date, "YYYY/MM/DD").toDate()
      : null;
    const result_start_date = this.state.result_start_date
      ? moment(this.state.result_start_date, "YYYY/MM/DD").toDate()
      : null;
    const result_end_date = this.state.result_end_date
      ? moment(this.state.result_end_date, "YYYY/MM/DD").toDate()
      : null;

    const mastersCopy = { ...this.props.masters };

    const user = this.props.masters.users.filter((item) => item.user_id == util_com.getUserId());

    if (this.props.masters.user_role === 2 && user[0].company_id == this.state.process_company_id) {
      mastersCopy.users = this.props.masters.users.filter((item) => item.company_id == this.state.process_company_id);
      mastersCopy.groups = this.props.masters.groups.filter((item) => item.company_id == this.state.process_company_id);
    }

    const canEdit = isValidRole(roles, 1);
    const canCreComment = isValidRole(roles, 2);
    const canDispComment = isValidRole(roles, 3);
    const canEditComment = isValidRole(roles, 4);

    return (
      <Modal
        title={`${t("edit_detail")}：${this.state.title}`}
        closeHandler={this.props.closeHandler}
        loading={this.state.fetching}
        className="matrix-task-editor"
        onDrag={this.setDatepickerPos}
      >
        <div className="modal-body-top w-1000" style={{ padding: "10px 0" }}>
          <div className="form-row mb-0">
            <div className="form-group w-180 mr-10 txt-right">
              <span className="form-label txt-bold">{t("process")}</span>
              <div className="toggle-buttons">
                <label data-test-id="radio-task-editor-process-on">
                  <input
                    type="radio"
                    checked={this.state.valid_flg}
                    disabled={!canEdit}
                    onChange={() => this.handleChangeValidFlag(true)}
                  />
                  <span className="btn">{t("on")}</span>
                </label>
                <label data-test-id="radio-task-editor-process-off">
                  <input
                    type="radio"
                    checked={!this.state.valid_flg}
                    disabled={!canEdit}
                    onChange={() => this.handleChangeValidFlag(false)}
                  />
                  <span className="btn">{t("off")}</span>
                </label>
              </div>
            </div>
            <div className="form-group status-group mw-590">
              <span className="form-label txt-bold">{t("status")}</span>
              <ul className="status-list status-mark ml-10">
                <li>
                  <button
                    data-test-id="radio-task-editor-status-valid-on"
                    disabled={isInvalid || !canEdit}
                    className={status === 0 && schedule_type === TaskScheduleType.EMPTY ? "on" : ""}
                    onClick={() => this.handleChangeStatus(0, TaskScheduleType.EMPTY)}
                  >
                    {t("blank")}
                  </button>
                </li>
                <li>
                  <button
                    data-test-id="radio-task-editor-status-scheduled"
                    disabled={isInvalid || !canEdit}
                    className={
                      status === 0 && schedule_type === TaskScheduleType.SCHEDULED && ahead_days === 1 ? "on" : ""
                    }
                    onClick={() => this.handleChangeStatus(0, TaskScheduleType.SCHEDULED, 1)}
                  >
                    ○
                  </button>
                </li>
                <li>
                  <button
                    data-test-id="radio-task-editor-status-continue"
                    disabled={isInvalid || !canEdit}
                    className={
                      status === 0 && schedule_type === TaskScheduleType.CONTINUE && ahead_days === 1 ? "on" : ""
                    }
                    onClick={() => this.handleChangeStatus(0, TaskScheduleType.CONTINUE, 1)}
                  >
                    ◎
                  </button>
                </li>
                <li>
                  <button
                    data-test-id="radio-task-editor-status-continue"
                    disabled={isInvalid || !canEdit}
                    className={
                      status === 0 && schedule_type === TaskScheduleType.SCHEDULED && ahead_days === 2 ? "on" : ""
                    }
                    onClick={() => this.handleChangeStatus(0, TaskScheduleType.SCHEDULED, 2)}
                  >
                    ②
                  </button>
                </li>
                <li>
                  <button
                    data-test-id="radio-task-editor-status-continue"
                    disabled={isInvalid || !canEdit}
                    className={
                      status === 0 && schedule_type === TaskScheduleType.CONTINUE && ahead_days === 2 ? "on" : ""
                    }
                    onClick={() => this.handleChangeStatus(0, TaskScheduleType.CONTINUE, 2)}
                  >
                    ⓶
                  </button>
                </li>
                <li>
                  <button
                    data-test-id="radio-task-editor-status-continue"
                    disabled={isInvalid || !canEdit}
                    className={
                      status === 0 && schedule_type === TaskScheduleType.SCHEDULED && ahead_days === 3 ? "on" : ""
                    }
                    onClick={() => this.handleChangeStatus(0, TaskScheduleType.SCHEDULED, 3)}
                  >
                    ③
                  </button>
                </li>
                <li>
                  <button
                    data-test-id="radio-task-editor-status-continue"
                    disabled={isInvalid || !canEdit}
                    className={
                      status === 0 && schedule_type === TaskScheduleType.CONTINUE && ahead_days === 3 ? "on" : ""
                    }
                    onClick={() => this.handleChangeStatus(0, TaskScheduleType.CONTINUE, 3)}
                  >
                    ⓷
                  </button>
                </li>
                <li>
                  <button
                    data-test-id="radio-task-editor-status-completing"
                    disabled={isInvalid || !canEdit}
                    className={status === 0 && schedule_type === TaskScheduleType.COMPLETING ? "on" : ""}
                    onClick={() => this.handleChangeStatus(0, TaskScheduleType.COMPLETING)}
                  >
                    ■
                  </button>
                </li>
                <li>
                  <button
                    data-test-id="radio-task-editor-status-completing"
                    disabled={isInvalid || !canEdit}
                    className={status === 0 && schedule_type === TaskScheduleType.INTERRUPT ? "on" : ""}
                    onClick={() => this.handleChangeStatus(0, TaskScheduleType.INTERRUPT)}
                  >
                    △
                  </button>
                </li>
                <li>
                  <button
                    data-test-id="radio-task-editor-status-completed"
                    disabled={isInvalid || !canEdit}
                    className={status === 1 ? "on" : ""}
                    onClick={() => this.handleChangeStatus(1, null)}
                  >
                    ●
                  </button>
                </li>
              </ul>
            </div>
            <div className="form-group ml-10 w-[125px]">
              {!isInvalid && (
                <>
                  <span className="form-label txt-bold">{t("task_progress")}</span>
                  {status === TaskStatus.INCOMPLETE && schedule_type === TaskScheduleType.CONTINUE ? (
                    <div style={{ display: "inline-block" }}>
                      <div className="mr-10" style={{ display: "inline-block" }}>
                        <input
                          data-test-id="text-task-progress"
                          disabled={!canEdit}
                          type="text"
                          className="form-control w-50 mr-5"
                          value={progressDisp}
                          onChange={(e) => this.changeProgressInput(e)}
                        />
                        %
                      </div>
                    </div>
                  ) : (
                    <span>{progressDisp}%</span>
                  )}
                </>
              )}
            </div>
          </div>
          {error.progressDisp && (
            <div className="form-error w-1000">
              <p className="form-error-message w-260">{error.progressDisp}</p>
            </div>
          )}
        </div>
        <div className="modal-body w-1000 clearfix" onScroll={this.handleScroll} ref={(node) => (this.body = node)}>
          <div className="modal-body-left w-380">
            <div className="form-row">
              <div className="form-group w-380">
                <span className="form-label txt-bold">{t("scheduled_date")}</span>
                <div
                  className="schedule-datepicker"
                  onMouseEnter={() => this.setState({ onCalendar: true })}
                  onMouseLeave={() => this.setState({ onCalendar: false })}
                >
                  <input
                    ref={(node) => (this.inputScheduleStart = node)}
                    type="text"
                    className="form-control w-140"
                    disabled={isInvalid || !canEdit}
                    value={this.state.scheduleStartText}
                    onFocus={(e) => this.openDatePicker(e, scheduleStart)}
                    onChange={(e) => this.handleDateText(e.target.value, "scheduleStartText")}
                  />
                </div>
                {this.state.openCalender === scheduleStart && !isInvalid && (
                  <div style={this.getDatepickerStyle("scheduleStart", datepickerModalStyleStart)}>
                    <DatePicker
                      ref={(node) => (this.scheduleStart = node)}
                      selected={schedule_start_date}
                      dateFormat="yyyy-MM-dd"
                      allowSameDay
                      inline
                      onClickOutside={() => this.closeDatePicker(dateStateMap.scheduleStart)}
                      onChange={(date) => this.handleChangeDatePicker(date, dateStateMap.scheduleStart)}
                      locale={t("calender_locale")}
                      holidays={this.state.unworkDays}
                    />
                  </div>
                )}
                &nbsp;〜&nbsp;
                <div
                  className="schedule-datepicker"
                  onMouseEnter={() => this.setState({ onCalendar: true })}
                  onMouseLeave={() => this.setState({ onCalendar: false })}
                >
                  <input
                    ref={(node) => (this.inputScheduleEnd = node)}
                    type="text"
                    className="form-control w-140"
                    disabled={isInvalid || !canEdit}
                    value={this.state.scheduleEndText}
                    onFocus={(e) => this.openDatePicker(e, scheduleEnd)}
                    onChange={(e) => this.handleDateText(e.target.value, "scheduleEndText")}
                  />
                </div>
                {this.state.openCalender === scheduleEnd && !isInvalid && (
                  <div style={this.getDatepickerStyle("scheduleEnd", datepickerModalStyleEnd)}>
                    <DatePicker
                      ref={(node) => (this.scheduleEnd = node)}
                      selected={schedule_end_date}
                      dateFormat="yyyy-MM-dd"
                      allowSameDay
                      inline
                      onClickOutside={() => this.closeDatePicker(dateStateMap.scheduleEnd)}
                      onChange={(date) => this.handleChangeDatePicker(date, dateStateMap.scheduleEnd)}
                      locale={t("calender_locale")}
                      holidays={this.state.unworkDays}
                    />
                  </div>
                )}
              </div>
              {error.schedule_start_date && (
                <div className="form-error w-380">
                  <p className="form-error-message w-300">{error.schedule_start_date}</p>
                </div>
              )}
            </div>
            <div className="form-row">
              <div className="form-group w-380">
                <span className="form-label txt-bold">
                  {t("results")}
                  {t("space")}
                  {t("date")}
                </span>
                <div
                  className="schedule-datepicker"
                  onMouseEnter={() => this.setState({ onCalendar: true })}
                  onMouseLeave={() => this.setState({ onCalendar: false })}
                >
                  <input
                    ref={(node) => (this.inputResultStart = node)}
                    type="text"
                    className="form-control w-140"
                    disabled={isInvalid || !canEdit}
                    value={this.state.resultStartText}
                    onFocus={(e) => this.openDatePicker(e, resultStart)}
                    onChange={(e) => this.handleDateText(e.target.value, "resultStartText")}
                  />
                </div>
                {this.state.openCalender === resultStart && !isInvalid && (
                  <div style={this.getDatepickerStyle("resultStart", datepickerModalStyleStart)}>
                    <DatePicker
                      ref={(node) => (this.resultStart = node)}
                      selected={result_start_date}
                      dateFormat="yyyy-MM-dd"
                      allowSameDay
                      inline
                      onClickOutside={() => this.closeDatePicker(dateStateMap.resultStart)}
                      onChange={(date) => this.handleChangeDatePicker(date, dateStateMap.resultStart)}
                      locale={t("calender_locale")}
                      holidays={this.state.unworkDays}
                    />
                  </div>
                )}
                &nbsp;〜&nbsp;
                <div
                  className="schedule-datepicker"
                  onMouseEnter={() => this.setState({ onCalendar: true })}
                  onMouseLeave={() => this.setState({ onCalendar: false })}
                >
                  <input
                    ref={(node) => (this.inputResultEnd = node)}
                    type="text"
                    className="form-control w-140"
                    disabled={isInvalid || !canEdit}
                    value={this.state.resultEndText}
                    onFocus={(e) => this.openDatePicker(e, resultEnd)}
                    onChange={(e) => this.handleDateText(e.target.value, "resultEndText")}
                  />
                </div>
                {this.state.openCalender === resultEnd && !isInvalid && (
                  <div style={this.getDatepickerStyle("resultEnd", datepickerModalStyleEnd)}>
                    <DatePicker
                      ref={(node) => (this.resultEnd = node)}
                      selected={result_end_date}
                      dateFormat="yyyy-MM-dd"
                      allowSameDay
                      inline
                      onClickOutside={() => this.closeDatePicker(dateStateMap.resultEnd)}
                      onChange={(date) => this.handleChangeDatePicker(date, dateStateMap.resultEnd)}
                      locale={t("calender_locale")}
                      holidays={this.state.unworkDays}
                    />
                  </div>
                )}
              </div>
              {error.result_start_date && (
                <div className="form-error w-380">
                  <p className="form-error-message w-300">{error.result_start_date}</p>
                </div>
              )}
            </div>
            <div className="form-row">
              <div className="form-group" style={{ width: 318 }}>
                <span className="form-label txt-bold">{t("weight")}</span>
                <input
                  data-test-id="text-task-editor-weight"
                  type="text"
                  className="form-control w-110"
                  disabled={isInvalid || !(this.state.cost === 0 || this.state.cost === null) || !canEdit}
                  value={this.state.weight ?? ""}
                  onChange={this.handleChangeWeight}
                />
                <span className="note d-ib w-120 ml-10 ta-l">
                  {t("initial_value")}：{this.state.weight_default}
                </span>
              </div>
              {error.weight && (
                <div className="form-error w-380">
                  <p className="form-error-message w-300">{error.weight}</p>
                </div>
              )}
            </div>
            {this.state.cost !== 0 && this.state.cost !== null && (
              <div className="form-row">
                <div className="form-group w-180">
                  <span className="form-label txt-bold txt-middle">{t("task_cost")}</span>
                  <span className="d-ib w-100 ta-l">{this.state.cost}</span>
                </div>
              </div>
            )}
            <div className="form-row">
              <div className="form-group w-380">
                <span className="form-label txt-bold">{t("checkpoint")}</span>
                <div className="ckbox-vertically w-300">
                  {this.props.masters.checkpoints
                    .filter((c) => c.category_id == this.state.category_id)
                    .map((item, index) => (
                      <label key={index} className="ckbox">
                        <input
                          type="checkbox"
                          disabled={isInvalid || !canEdit}
                          value={item.checkpoint_id || undefined}
                          checked={this.state.checkpoints.some((checkpoint) => checkpoint === item.checkpoint_id)}
                          onChange={(e) => this.handleChangeCheckpoint(item.checkpoint_id, e.target.checked)}
                        />
                        <span>{item.checkpoint_name}</span>
                      </label>
                    ))}
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group w-380">
                <span className="form-label txt-bold mt-5">{t("permissions")}</span>
                <div className="ckbox-vertically w-300 relative">
                  <div className="d-if items-center w-300">
                    <label className="ckbox">
                      <input
                        type="checkbox"
                        disabled={isInvalid || !canEdit || !schedule_roles[1]}
                        checked={this.state.field_t2 === 1}
                        onChange={this.handleChangeFieldT2}
                      />
                      <span>{t("open_frame")}</span>
                    </label>
                    <label className="ckbox">
                      <input
                        type="checkbox"
                        disabled={isInvalid || !canEdit || !schedule_roles[2]}
                        checked={this.state.field_t3 === 1}
                        onChange={this.handleChangeFieldT3}
                      />
                      <span>{t("general_fire")}</span>
                    </label>
                    <div className="d-ib mb-5">
                      <span className="mr-10">{t("used_fire")}</span>
                      <input
                        type="text"
                        disabled={isInvalid || !canEdit || !schedule_roles[3]}
                        className="form-control w-80"
                        value={this.state.field_t4 || ""}
                        onChange={this.handleChangeFieldT4}
                        onFocus={this.handleFocusFieldT4}
                        onBlur={this.handleBlurFieldT4}
                        ref={(node) => (this.inputFieldT4 = node)}
                      />
                      {this.state.showUsedFireTypeAbbreviationNote && (
                        <div
                          className="absolute text-start"
                          style={{
                            border: "1px solid rgba(0, 0, 0, 0.15)",
                            boxShadow: "0 1px 5px 1px rgba(0,0,0,0.1)",
                            top: this.state.fireTypeAbbreviationNoteTop,
                            left: this.state.fireTypeAbbreviationNoteLeft,
                          }}
                        >
                          <FireTypeAbbreviationNote />
                        </div>
                      )}
                    </div>
                  </div>
                  {error.field_t4 && (
                    <div className="form-error w-380 absolute" style={{ top: 25, left: 73 }}>
                      <p className="form-error-message w-300">{error.field_t4}</p>
                    </div>
                  )}
                  <label className="ckbox">
                    <input
                      type="checkbox"
                      disabled={isInvalid || !canEdit || !schedule_roles[4]}
                      checked={this.state.field_t5 === 1}
                      onChange={this.handleChangeFieldT5}
                    />
                    <span>{t("pipe_cutting")}</span>
                  </label>
                  <label className="ckbox">
                    <input
                      type="checkbox"
                      disabled={isInvalid || !canEdit || !schedule_roles[5]}
                      checked={this.state.field_t6 === 1}
                      onChange={this.handleChangeFieldT6}
                    />
                    <span>{t("lack_of_oxygen")}</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group w-190">
                <span className="form-label txt-bold">{t("heavy_machine")}</span>
                <input
                  type="text"
                  disabled={isInvalid || !canEdit || !schedule_roles[6]}
                  className="form-control w-110"
                  value={this.state.field_t7 || ""}
                  onChange={this.handleChangeFieldT7}
                />
              </div>
              {error.field_t7 && (
                <div className="form-error w-380">
                  <p className="form-error-message w-300">{error.field_t7}</p>
                </div>
              )}
            </div>
            <div className="flex items-center mb-[15px] ml-[-6px]">
              <span className="form-label txt-bold mr-[8px]">{t("safety_matters")}</span>
              <input
                type="text"
                disabled={isInvalid || !canEdit || !schedule_roles[7]}
                className="form-control w-110"
                value={this.state.field_t8 || ""}
                onChange={this.handleChangeFieldT8}
                onFocus={this.handleFocusFieldT8}
                onBlur={this.handleBlurFieldT8}
                ref={(node) => (this.inputFieldT8 = node)}
              />
              {this.state.showSafetyMattersExplanatoryNote && (
                <div
                  className="absolute text-start"
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.15)",
                    boxShadow: "0 1px 5px 1px rgba(0,0,0,0.1)",
                    top: this.state.explanatoryNoteTop,
                    left: this.state.explanatoryNoteLeft,
                  }}
                >
                  <ExplanatoryNote />
                </div>
              )}
            </div>
            <div className="form-row">
              <div className="form-group w-208">
                <span className="form-label txt-bold">{t("worker_num")}</span>
                <input
                  type="text"
                  disabled={isInvalid || !canEdit || !schedule_roles[0]}
                  className="form-control w-110"
                  value={this.state.field_t1 ?? ""}
                  onChange={this.handleChangeFieldT1}
                />
                <span className="ml-5">{t("unit_number_of_people")}</span>
              </div>
              {error.field_t1 && (
                <div className="form-error w-380">
                  <p className="form-error-message w-300">{error.field_t1}</p>
                </div>
              )}
            </div>
            <div className="form-row">
              <div className="form-group w-212">
                <span className="form-label txt-bold">{t("overtime_schedule")}</span>
                <input
                  type="text"
                  disabled={isInvalid || !canEdit || !schedule_roles[8]}
                  className="form-control w-110"
                  value={this.state.field_t9 ?? ""}
                  onChange={this.handleChangeFieldT9}
                />
                <span className="ml-5">Hr</span>
              </div>
              {error.field_t9 && (
                <div className="form-error w-380">
                  <p className="form-error-message w-300">{error.field_t9}</p>
                </div>
              )}
            </div>
            <div className="form-row">
              <div className="form-group w-380">
                <span className="form-label txt-bold txt-middle">
                  {t("additional_construction")}
                  <br />
                  {t("specification")}
                </span>
                <input
                  data-test-id="text-task-editor-document-no"
                  type="text"
                  disabled={isInvalid || !canEdit}
                  className="form-control w-300"
                  value={this.state.document_no || ""}
                  onChange={this.handleChangeDocumentNo}
                />
              </div>
              {error.document_no && (
                <div className="form-error w-380">
                  <p className="form-error-message w-300">{error.document_no}</p>
                </div>
              )}
            </div>
            <div className="form-row">
              <div className="form-group w-380">
                <span className="form-label txt-bold">{t("receiver")}</span>
                <div className="d-ib w-300 ta-l">
                  <ChargeSelect
                    disabled={isInvalid || !canEdit}
                    masters={mastersCopy}
                    userId={this.state.user_id}
                    groupId={this.state.group_id}
                    onChange={this.handleChangeCharge}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-body-center w-260 ml-25">
            <div className="form-row">
              <div className="form-group">
                <span className="form-label txt-bold">{t("comments")}</span>
                {isInvalid || !canCreComment ? (
                  <span disabled={true} className="icon icon-add_circle_disabled"></span>
                ) : (
                  <span
                    data-test-id="button-task-editor-comment-create"
                    className="icon icon-add_circle"
                    onClick={() => this.showCommentEditor(null)}
                  ></span>
                )}
              </div>
            </div>
            <div className="comment-upload-box">
              {canDispComment && (
                <ul className="form-comment-list">
                  {this.state.comments
                    .filter((comment) => comment.mode !== 2)
                    .map((comment, index) => (
                      <li key={index}>
                        <p className="comment-txt">{comment.comment}</p>
                        <p className="comment-info">
                          <span className="date">{moment(comment.timestamp.insert_date).format("YYYY/MM/DD")}</span>
                          <span className="user">{comment.user_name}</span>
                          <span className="acttion">
                            {isInvalid || !canEditComment ? (
                              <span className="txt-disabled">{t("edit")}</span>
                            ) : (
                              <a onClick={() => this.showCommentEditor(comment)}>{t("edit")}</a>
                            )}
                            &nbsp;&nbsp;
                            {isInvalid || !canEditComment ? (
                              <span className="txt-disabled">{t("delete")}</span>
                            ) : (
                              <a onClick={() => this.handleRemoveComment(comment.comment_id)}>{t("delete")}</a>
                            )}
                          </span>
                        </p>
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
          <div className="modal-body-right w-260 mr-10 mt-n10">
            <div className="form-row" style={{ marginBottom: "7px" }}>
              <div className="form-group">
                <span className="form-label txt-bold">{t("upload_file")}</span>
                <button
                  data-test-id="button-task-editor-file-select"
                  disabled={isInvalid || !isValidRole(roles, 5) || !fileUpload}
                  className="btn btn-light-blue w-120"
                  onClick={this.handleFileSelect}
                >
                  {t("browze_file")}
                </button>
                <form ref={(node) => (this.form = node)} style={{ display: "none" }} encType={"multipart/form-data"}>
                  <input
                    type="file"
                    name="uploadFiles"
                    accept={ACCEPT_UPLOAD_EXTENSIONS.join(",")}
                    multiple={true}
                    onChange={this.handleChangeFile}
                    onClick={(e) => (e.target.value = null)}
                    ref={(node) => (this.file = node)}
                  />
                </form>
              </div>
            </div>
            {error.file && (
              <div className="form-error" style={{ textAlign: "left" }}>
                <p className="form-error-message">{error.file}</p>
              </div>
            )}
            {isValidRole(roles, 6) && (
              <div className="file-upload-box">
                <ul className="form-file-list">
                  {this.state.files
                    .filter((f) => f.mode !== 2)
                    .map((file, index) => (
                      <FileItem
                        disabled={isInvalid || !isValidRole(roles, 7)}
                        key={index}
                        file={file}
                        kind="task"
                        readOnly={false}
                        onDelete={this.handleRemoveFile}
                        onDownload={download}
                        additionalFilenameClass="mw-160"
                      />
                    ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button
            data-test-id="button-task-editor-cancel"
            type="button"
            className="btn btn-gray"
            onClick={this.props.closeHandler}
          >
            {t("cancel")}
          </button>
          <SubmitButton
            data-test-id="button-task-editor-save"
            onClick={this.handleSave}
            loading={this.state.updating}
            disabled={!isValidRole(roles, 0) || uploading}
          />
          <div className="hidden absolute bottom-1 right-1">{this.state.scheduleDateText}</div>

          {linkageInfo && linkageInfo.num > 0 && (
            <div
              className="icon-linkage-editor"
              data-tip
              data-for="task-editor-tooltip"
              onMouseEnter={() => {
                const { content, type, num } = linkageInfo;
                const sbj1 = type === "from" ? t("tasks_linkage_forward") : t("tasks_linkage_source");
                const sbj2 = num > 1 ? "（" + num + "）" : "";

                setTimeout(() => {
                  this.setState({
                    subject: sbj1 + sbj2 + "\n",
                    content: content.join("\n"),
                    isTooltip: true,
                  });
                }, 500);
              }}
              onMouseLeave={() => {
                this.setState({
                  tooltipData: null,
                  isTooltip: false,
                });
              }}
            >
              <img
                src={`img/icon-linkage-${linkageInfo.type}-gray.svg`}
                width="20"
                height="20"
                className="icon"
                style={{ marginRight: "2px" }}
              />
              <span>{linkageInfo.num > 1 ? linkageInfo.num : ""}</span>
            </div>
          )}
        </div>
        {this.state.visibleCommentEditor && (
          <CommentEditor
            comment={this.state.editingComment}
            closeHandler={this.hideCommentEditor}
            saveHandler={this.saveCommentEditor}
          />
        )}
        {this.state.isTooltip && (
          <div className="linkage-tooltip">
            <div className="tooltip-inner">
              <div className="form-txt wrap">
                <span style={{ fontWeight: "bold" }}>{this.state.subject}</span>
                {this.state.content}
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

TaskEditor.defaultProps = {
  itemName: "",
  processName: "",
};

TaskEditor.propTypes = {
  itemName: PropTypes.string,
  processName: PropTypes.string,
  session: PropTypes.object.isRequired,
  taskId: PropTypes.number.isRequired,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  fetch: PropTypes.func.isRequired,
  masters: PropTypes.object.isRequired,
  download: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  removeComment: PropTypes.func.isRequired,
  updateTaskDetail: PropTypes.func.isRequired,
  closeHandler: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
  reloadProgressRate: PropTypes.func,
  showAlert: PropTypes.func.isRequired,
};

export default withTranslation()(TaskEditor);
