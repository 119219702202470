import * as constants from "../constants/index";
import environments from "../environments";
import storageManager from "../lib/storageManager";

export const setStart = (start) => ({
  type: constants.INFORMATION_SET_START,
  payload: start,
});

export const setLimit = (limit) => ({
  type: constants.INFORMATION_SET_LIMIT,
  payload: limit,
});

export const setEnd = (end) => ({
  type: constants.INFORMATION_SET_END,
  payload: end,
});

export const fetchItems = (params, callback) => {
  let endpoint = `${environments.API_BASE_URI}/informations`;

  const query = new URLSearchParams();
  // let infinite = false;

  if (params && params.limit) {
    query.set("limit", params.limit);
  }

  if (params && params.start) {
    query.set("start", params.start);
  }

  // if (params && params.start > 1) {
  //   infinite = true;
  // }

  storageManager.setConstructionItem("informationsSearchParams", JSON.stringify(params));
  endpoint = `${endpoint}?${query.toString()}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.INFORMATION_BEGIN_FETCH_ITEMS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.INFORMATION_END_FETCH_ITEMS,
          payload: response.informations,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchItem = (informationId, callback) => {
  const endpoint = `${environments.API_BASE_URI}/informations/${informationId}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.INFORMATION_BEGIN_FETCH_ITEM,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.INFORMATION_END_FETCH_ITEM,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const createItem = (data, callback, failedCallbak) => {
  const endpoint = `${environments.API_BASE_URI}/informations/create`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
    callbacks: {
      begin: () => {
        return {
          type: constants.INFORMATION_BEGIN_CREATE_ITEM,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.INFORMATION_END_CREATE_ITEM,
          payload: response,
        };
      },
      error: (response) => {
        if (failedCallbak !== undefined) {
          failedCallbak();
        }

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateItem = (informationId, data, callback, failedCallbak) => {
  const endpoint = `${environments.API_BASE_URI}/informations/update/${informationId}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
    callbacks: {
      begin: () => {
        return {
          type: constants.INFORMATION_BEGIN_UPDATE_ITEM,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.INFORMATION_END_UPDATE_ITEM,
          payload: response,
        };
      },
      error: (response) => {
        if (failedCallbak !== undefined) {
          failedCallbak();
        }

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const deleteItem = (informationId, callback, handleCancel) => {
  const endpoint = `${environments.API_BASE_URI}/informations/delete/${informationId}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.INFORMATION_BEGIN_DELETE_ITEM,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.INFORMATION_END_DELETE_ITEM,
          payload: informationId,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
    meta: {
      confirm: {
        messages: ["delete_article_alert"],
        cancelHandler: handleCancel,
      },
    },
  };
};

export const resetItems = () => {
  return {
    type: constants.INFORMATION_RESET_STATE,
  };
};
