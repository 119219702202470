import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import validator from "validator";

import Modal from "../Modal";

class CommentEditor extends Component {
  constructor(props) {
    super(props);
    const { comment } = this.props;
    const text = comment === null ? "" : comment.comment;

    this.state = {
      text,
      error: {},
    };
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleChangeText(e) {
    this.setState({ text: e.target.value });
  }

  handleSave() {
    const error = {};
    const { comment } = this.props;
    const id = comment === null ? 0 : comment.comment_id;
    const text = this.state.text;

    if (validator.isEmpty(text)) {
      error.text = "comment_unentered";
    } else if (text.length > 100) {
      error.text = "comment_too_long";
    }

    if (Object.keys(error).length > 0) {
      this.setState({ error });
    } else {
      this.props.saveHandler(id, text);
    }
  }

  render() {
    const { error } = this.state;
    const { t } = this.props;

    return (
      <Modal title={t("comment_edit")} closeHandler={this.props.closeHandler}>
        <div className="modal-body">
          <div className="form-row mb-5">
            <div className="form-group w-370">
              <textarea
                data-test-id="text-comment-editor-comment"
                rows="4"
                className="form-control w-370"
                value={this.state.text}
                onChange={this.handleChangeText}
              />
            </div>
            {error.text && (
              <div className="form-error w-370">
                <p className="form-error-message w-370">{t(error.text)}</p>
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button
            data-test-id="button-comment-editor-cancel"
            type="button"
            className="btn btn-gray"
            onClick={this.props.closeHandler}
          >
            {t("cancel")}
          </button>
          <button
            data-test-id="button-comment-editor-save"
            type="button"
            className="btn btn-blue"
            onClick={this.handleSave}
          >
            {t("decision")}
          </button>
        </div>
      </Modal>
    );
  }
}

CommentEditor.propTypes = {
  comment: PropTypes.object,
  closeHandler: PropTypes.func.isRequired,
  saveHandler: PropTypes.func.isRequired,
};

export default withTranslation()(CommentEditor);
