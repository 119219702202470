import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import * as commonUtil from "../../lib/common";
import * as progressUtil from "../../lib/matrix";
import { isValidStatusSwitch } from "../../lib/roleChecker";

class InspectionGridRow extends Component {
  constructor(props) {
    super(props);

    this.handleEdit = this.handleEdit.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }

  handleChangeStatus() {
    const { item_id } = this.props.row;

    if (this.props.switcherStatus >= 0) {
      this.props.changeTaskStatus(item_id, this.props.row, this.props.switcherStatus);
    }
  }

  handleEdit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.props.switcherStatus === -1) {
      this.props.showTaskEditor(this.props.row);
    }
  }

  render() {
    const notAllowed =
      this.props.switcherStatus === -1 ? false : !isValidStatusSwitch(this.props.switcherStatus, this.props.row.roles);

    let { className } = this.props;

    if (notAllowed) {
      className = `${className} row-not-allowed`;
    }

    const { t, row } = this.props;
    const scheduleDate = row.schedule_date ? moment(row.schedule_date).format("YYYY/MM/DD").toString() : "";

    return (
      <tr
        className={className}
        key={this.props.row.item_id}
        onClick={() => (notAllowed ? false : this.handleChangeStatus())}
      >
        <td className="insp_col1 txt-center">{this.props.no}</td>
        <td className="insp_col2">{this.props.row.category_name}</td>
        <td className="insp_col3">{this.props.row.item_name}</td>
        <td className="insp_col4">{this.props.row.pre_process_name}</td>
        <td className="insp_col5">{this.props.row.pre_display_status}</td>
        <td className="insp_col6">{this.props.row.process_major_class_name}</td>
        <td className="insp_col7">{this.props.row.process_middle_class_name}</td>
        <td className="insp_col8">{this.props.row.process_name}</td>
        <td className="insp_col9 txt-center">{scheduleDate}</td>
        <td className="insp_col10 txt-center cell-inner">{this.props.row.display_status}</td>
        <td className="insp_col11 txt-center">
          <a onClick={(e) => this.handleEdit(e)}>{this.props.masters.userRole !== 3 ? t("edit") : t("display")}</a>
        </td>
      </tr>
    );
  }
}

InspectionGridRow.propTypes = {
  masters: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  switcherStatus: PropTypes.number.isRequired,
  showTaskEditor: PropTypes.func.isRequired,
  changeTaskStatus: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  no: PropTypes.number.isRequired,
};

export default withTranslation()(InspectionGridRow);
