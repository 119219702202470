import React, { FC } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  page: number;
  pageTotal: number;
  onPrevPage: () => void;
  onNextPage: () => void;
};

export const ScheduleGridFooter: FC<Props> = ({ page, pageTotal, onPrevPage, onNextPage }) => {
  const { t } = useTranslation();
  return (
    <div className="paging-footer">
      <div className="pager" onClick={onPrevPage}>
        <span style={{ display: 1 < page ? "inherit" : "none" }}>{t("prev")}</span>
      </div>
      {pageTotal !== 0 && (
        <span className="page">
          {page}&nbsp;/&nbsp;{pageTotal}
        </span>
      )}
      <div className="pager" onClick={onNextPage}>
        <span style={{ display: page < pageTotal ? "inherit" : "none" }}>{t("next")}</span>
      </div>
    </div>
  );
};
