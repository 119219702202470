import actions from "../actions";
import * as constants from "../constants/index";
import environments from "../environments";
import { changeLanguage } from "../i18n";

export const contentsReady = () => ({
  type: constants.APP_CONTENTS_READY,
});

export const popState = (e) => ({
  type: constants.APP_POPSTATE,
  payload: e,
});

export const setUnloadAlert = () => ({
  type: constants.APP_SET_UNLOAD_ALERT,
});

export const clearUnloadAlert = () => ({
  type: constants.APP_CLEAR_UNLOAD_ALERT,
});

export const fetchMenus = (callback) => {
  const endpoint = `${environments.API_BASE_URI}/menus`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.APP_BEGIN_FETCH_MENUS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback();
        }

        return {
          type: constants.APP_END_FETCH_MENUS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchRoles = (callback) => {
  const endpoint = `${environments.API_BASE_URI}/roles`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.APP_BEGIN_FETCH_ROLES,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback();
        }

        return {
          type: constants.APP_END_FETCH_ROLES,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const changeMenu = (menu) => {
  return {
    type: constants.APP_CHANGE_MENU,
    payload: menu,
  };
};

export const showConstructionSelector = () => {
  return {
    type: constants.APP_SHOW_CONSTRUCTION_SELECTOR,
  };
};

export const hideConstructionSelector = () => {
  return {
    type: constants.APP_HIDE_CONSTRUCTION_SELECTOR,
  };
};

export const showPasswordEditor = () => ({
  type: constants.APP_SHOW_PASSWORD_EDITOR,
});

export const hidePasswordEditor = () => ({
  type: constants.APP_HIDE_PASSWORD_EDITOR,
});

export const showLangSelector = () => ({
  type: constants.APP_SHOW_LANG_SELECTOR,
});

export const hideLangSelector = () => ({
  type: constants.APP_HIDE_LANG_SELECTOR,
});

export const updatePassword = (old_password, new_password, okHandler, ngHandler, isLogin = false) => {
  const endpoint = `${environments.API_BASE_URI}/password/update`;

  return (dispatch) => {
    const action = {
      type: constants.APP_CALL_API,
      endpoint,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        old_password,
        new_password,
      }),
      callbacks: {
        begin: () => {
          if (isLogin) {
            return {
              type: constants.LOGIN_BEGIN_UPDATE_PASSWORD,
            };
          }

          return {
            type: constants.APP_BEGIN_UPDATE_PASSWORD,
          };
        },
        success: (response) => {
          if (isLogin) {
            return {
              type: constants.LOGIN_END_UPDATE_PASSWORD,
              payload: response,
            };
          }

          dispatch(
            actions.app.showAlert("change_password", ["changed_password"], () => {
              okHandler();
              dispatch(actions.app.hideAlert());
            })
          );

          return {
            type: constants.APP_END_UPDATE_PASSWORD,
            payload: response,
          };
        },
        error: (response) => {
          ngHandler();

          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    };

    dispatch(action);
  };
};

export const addToastContent = (message, type) => {
  return (dispatch) => {
    const id = Symbol();

    dispatch({
      type: constants.APP_ADD_TOAST_CONTENT,
      payload: {
        id,
        message,
        type,
      },
    });

    setTimeout(() => {
      dispatch({
        type: constants.APP_REMOVE_TOAST_CONTENT,
        payload: id,
      });
    }, 3000);
  };
};

export const showAlert = (title, messages, okClickHandler, statusCode = undefined) => {
  return {
    type: constants.APP_SHOW_ALERT,
    payload: {
      title,
      messages,
      okClickHandler,
      statusCode,
    },
  };
};

export const hideAlert = () => {
  return {
    type: constants.APP_HIDE_ALERT,
  };
};

export const showConfirm = (title, messages, okClickHandler, cancelClickHandler) => {
  return {
    type: constants.APP_SHOW_CONFIRM,
    payload: {
      title,
      messages,
      okClickHandler,
      cancelClickHandler,
    },
  };
};

export const hideConfirm = () => {
  return {
    type: constants.APP_HIDE_CONFIRM,
  };
};

export const upload = (data, onProgress, onSuccess, onError) => {
  const endpoint = `${environments.API_BASE_URI}/upload`;

  return {
    type: constants.APP_FILE_UPLOAD,
    endpoint,
    method: "POST",
    body: data,
    callbacks: {
      progress: (response) => {
        onProgress(response);
      },
      success: (response) => {
        onSuccess(response);
      },
      error: (response) => {
        if (onError !== undefined) {
          onError({ data, response });
        }

        return {
          type: constants.APP_FAILED_UPLOAD,
        };
      },
    },
  };
};

export const changeLang = (e) => {
  return {
    type: constants.APP_CHANGE_LANG,
    payload: {
      lang: e.value,
    },
  };
};

export const saveLang = () => {
  return (dispatch, getState) => {
    const state = getState();
    changeLanguage(state.app.lang);

    dispatch({
      type: constants.APP_SAVE_LANG,
    });
  };
};

export const switchSystem = (system) => {
  return {
    type: constants.APP_SWITCH_SYSTEM,
    payload: system,
  };
};
