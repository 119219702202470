import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Modal from "../../../Modal";
import Select from "../../../common/Select";
import { ProgressNotificationSearchParam } from "../../models";

type Props = {
  initialCategoryId?: number;
  initialProcessTitle?: string;
  searchParam: ProgressNotificationSearchParam;
  onClose: () => void;
  onSearch: (searchParam: ProgressNotificationSearchParam) => void;
};

const NotificationSearchModal: React.FC<Props> = ({
  initialCategoryId,
  initialProcessTitle = "",
  searchParam,
  onClose,
  onSearch,
}) => {
  const { t } = useTranslation();
  const [categoryId, setCategoryId] = useState<number | undefined>(initialCategoryId);
  const [processTitle, setProcessTitle] = useState<string>(initialProcessTitle);
  const categories = useSelector((state) => state.construction?.masters?.categories);

  const onCategoryIdChange = (id?: number) => {
    setCategoryId(() => {
      return id;
    });
  };

  const onProcessTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const title = event.target.value;
    setProcessTitle(() => {
      return title;
    });
  };

  const onCancel = () => {
    onClose();
  };

  const onClearAllConditions = () => {
    setCategoryId(() => {
      return undefined;
    });
    setProcessTitle(() => {
      return "";
    });
  };

  const onSubmit = () => {
    // onSearch(categoryId, processTitle);
    onSearch({
      ...searchParam,
      category_id: categoryId !== 0 ? categoryId : undefined,
      process_text: processTitle !== "" ? processTitle : undefined,
    });
    onClose();
  };

  return (
    <Modal title={t("progress_notification_search")} closeHandler={onClose}>
      <div id="progress-notification-search-modal" className="modal-body w-400">
        <div className="form-row mt-15">
          <div className="form-group w-290">
            <span className="form-label">{t("machines_category")}</span>
            <div className="d-ib ta-l w-180">
              <Select
                prefix="category"
                value={categoryId}
                onChange={onCategoryIdChange}
                options={categories}
                isClearable
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group w-290">
            <span className="form-label">{t("process_title")}</span>
            <input type="text" className="form-control w-180" value={processTitle} onChange={onProcessTitleChange} />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-gray" onClick={onCancel}>
          {t("cancel")}
        </button>
        <button type="button" className="btn btn-gray" onClick={onClearAllConditions}>
          {t("clear")}
        </button>
        <button type="button" className="btn btn-blue" onClick={onSubmit}>
          {t("search")}
        </button>
      </div>
    </Modal>
  );
};

export default NotificationSearchModal;
