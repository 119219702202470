import React from "react";

import ApprovalGridRowContainer from "../../containers/list/ApprovalGridRowContainer";

import {
  labelFromByLinkageTask,
  labelToByLinkageTask,
  TaskSwitcherStatus,
  TaskSwitcherStatusType,
} from "@/models/tasks";

type Props = {
  no: number;
  rows: any[];
  showTaskEditor: (item, linkageInfo) => void;
  switcherStatus: TaskSwitcherStatusType;
  linkageTasks: any[];
};

const ApprovalGridBody = (props: Props) => {
  return (
    <table className="tbl-basic tbl-data list-table-row">
      <tbody
        className={`tbl-area ${
          props.switcherStatus === TaskSwitcherStatus.OFF ? "" : `cursor-${props.switcherStatus}`
        }`}
      >
        {props.rows.map((row, index) => {
          let className = "";

          if (row.processing) {
            className = "row-processing";
          }

          if (row.error) {
            className = className + " grid-cell-error";
          }

          const { linkageTasks } = props;
          let content = [];
          let type = "from";

          if (row.valid_flg && linkageTasks) {
            content = linkageTasks
              .filter((val) => val.from_task_id === row.task_id)
              .map((val) => labelToByLinkageTask(val));

            if (content.length === 0) {
              content = linkageTasks
                .filter((val) => val.to_task_id === row.task_id)
                .map((val) => labelFromByLinkageTask(val));
              if (content.length > 0) type = "to";
            }
          }

          return (
            <ApprovalGridRowContainer
              key={index}
              no={props.no + index}
              row={row}
              className={className}
              showTaskEditor={props.showTaskEditor}
              linkageInfo={{ type, num: content.length, content }}
            />
          );
        })}
      </tbody>
    </table>
  );
};

export default ApprovalGridBody;
