import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";

import styles from "../../constants/reactSelectStyle";
import Modal from "../Modal";
import { SubmitButton } from "@/components/common/SubmitButton";

class ScheduleEditorChiba extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule_id: 0,
      target: "",
      value: 0,
      timestamp: "",
      loading: false,
      error: {},
    };

    this.handleChangeTarget = this.handleChangeTarget.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.props.setUnloadAlert();
    this.setState({
      schedule_id: this.props.scheduleId,
      target: this.props.target,
      value: this.props.optionId,
      timestamp: this.props.timeStamp,
    });
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();
  }

  handleChangeTarget(e) {
    this.setState({
      schedule_id: this.props.scheduleId,
      target: this.props.target,
      value: parseInt(e.target.value),
      timestamp: this.props.timeStamp,
    });
  }

  handleSave() {
    if (this.state.loading) return;
    const error = {};
    const data = { ...this.state };

    if (this.state.value == -1) {
      error.value = this.props.panelText;
      error.msg = "is_not_selected";
    }

    data.error = error;

    if (Object.keys(error).length > 0) {
      this.setState(data);
    } else {
      data.loading = true;
      this.setState(data);
      this.props.updateItem(
        data,
        () => {
          this.setState({ loading: false });
          this.props.closeHandler();
        },
        () => {
          this.setState({ loading: false });
        }
      );
    }
  }

  render() {
    const { processText, panelText, selectValues, closeHandler, t } = this.props;
    const { value, error } = this.state;

    return (
      <Modal title={panelText + " " + t("update")} closeHandler={() => closeHandler()}>
        <div className="modal-body schedule-editor">
          <div className="form-row mb-5">
            <div className="form-group w-360 ta-u">
              <p className="form-text">{processText ? "[" + processText + "]" : t("no_process_content")}</p>
              <div className="form-center">
                <select
                  data-test-id="text-import-file-type"
                  className="form-control w-170"
                  value={value}
                  onChange={this.handleChangeTarget}
                >
                  {selectValues.map((value) => (
                    <option key={value.id} value={value.id}>
                      {value.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {error.value && (
              <div className="form-error w-360">
                <p className="form-error-message w-260">
                  {t(error.value)}
                  {t(error.msg)}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className={"modal-footer"}>
          <button
            data-test-id="button-column-editor-cancel"
            type="button"
            className="btn btn-gray"
            onClick={() => closeHandler()}
          >
            {t("cancel")}
          </button>
          <SubmitButton
            data-test-id="button-column-editor-save"
            onClick={this.handleSave}
            loading={this.state.loading}
          />
        </div>
      </Modal>
    );
  }
}

ScheduleEditorChiba.propTypes = {
  processText: PropTypes.string.isRequired,
  panelText: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
};

export default withTranslation()(ScheduleEditorChiba);
