import { connect } from "react-redux";

import actions from "../actions";
import LangSelector from "../components/LangSelector";

const mapStateToProps = (state) => {
  const { lang } = state.app;
  const { constructionId } = state.construction;

  return {
    lang,
    constructionId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideLangSelector: () => dispatch(actions.app.hideLangSelector()),
    changeLang: (e) => dispatch(actions.app.changeLang(e)),
    saveLang: () => dispatch(actions.app.saveLang()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LangSelector);
