import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import actions from "@/actions";
import { RootState } from "@/reducers/types";

export const DateSelector: React.FC = () => {
  const dispatch = useDispatch();
  const handleChange = (date: Date) => {
    dispatch(actions.plotmap.setLayoutDate(date));
  };

  const layoutDate = useSelector<RootState, Date>((state) => state.plotmap.layoutDate);

  const handleClickPrevDate = () => {
    const prevDate = new Date(layoutDate);
    prevDate.setDate(prevDate.getDate() - 1);
    handleChange(prevDate);
  };

  const handleClickNextDate = () => {
    const nextDate = new Date(layoutDate);
    nextDate.setDate(nextDate.getDate() + 1);
    handleChange(nextDate);
  };

  const { t } = useTranslation();

  return (
    <div className="flex items-center">
      <span onClick={handleClickPrevDate} className="underline text-[13px] text-[#1c77ff] cursor-pointer">
        &lt; {t("previous_day")}
      </span>
      <DatePicker
        wrapperClassName="!w-[140px] mx-10 text-center text-[14px] [&_input]:!border-none [&_input]:!cursor-pointer [&_input]:text-center"
        disabled={false}
        selected={layoutDate}
        dateFormat="yyyy/MM/dd"
        locale={t("calender_locale")}
        onChange={handleChange}
        customInput={<div className="bg-white text-[1.3rem] py-2 px-3">{moment(layoutDate).format("YYYY/MM/DD")}</div>}
      />
      <span onClick={handleClickNextDate} className="underline text-[13px] text-[#1c77ff] cursor-pointer">
        {t("next_day")} &gt;
      </span>
    </div>
  );
};
