import { parse } from "node-html-parser";

export type TextLink = {
  text: string;
  href: string;
};

export const getTextLinkFromClipboard = async (): Promise<TextLink[]> => {
  const htmlText = await getTextHtmlFromClipboard();
  if (!htmlText) return [];
  const root = parse(htmlText);
  const links = root.querySelectorAll("a");
  return links.map((v) => ({ text: v.innerText, href: v.rawAttributes.href }));
};

const getTextHtmlFromClipboard = async (): Promise<string> => {
  const clipboardContents = await navigator.clipboard.read();
  const item = clipboardContents[0];
  if (item && item.types.includes("text/html")) {
    const blob = await item.getType("text/html");
    return blob ? blob.text() : "";
  }
  return "";
};
