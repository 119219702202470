import { format } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";

import actions from "../../../../actions";
import FileItem from "../../../../components/FileItem";
import Modal from "../../../Modal";
import { ProgressNotification } from "../../models";

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTaskDetail: (taskId, callback) => dispatch(actions.matrix.fetchTaskDetail(taskId, callback)),
  };
};

interface Props {
  isVisible?: boolean;
  onClose: () => void;
  notification: ProgressNotification;
  // eslint-disable-next-line @typescript-eslint/ban-types -- 暫定処置でdisableしている
  fetchTaskDetail: (taskId, callback) => {};
}

const NotificationDetailModal: React.FC<Props> = ({ fetchTaskDetail, isVisible, onClose, notification }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { constructionId } = useSelector((state) => state.construction);

  const [task, setTask] = useState();

  useEffect(() => {
    fetchTaskDetail(notification.task_id, (response) => {
      setTask(response);
    });
  }, []);

  const matrixPath = useMemo<string | undefined>((): string | undefined => {
    if (task === undefined) return undefined;

    return `/#/${constructionId}/matrix?category_id=${notification.category_id}&item_id=${notification.item_id}&process_id=${task.process_id}`;
  }, [notification, constructionId, task]);

  const onClickOpenTitle = useCallback(() => {
    dispatch(
      actions.matrix.fetchSubject(notification.item_id, (response) => {
        history.pushState(undefined, undefined, `${matrixPath}&primary_charge_id=${response.primary_charge_id || ""}`);
        // aタグの画面遷移だと検索結果なしになることがあるのでlocation.reload()とする
        // (例:現在matrix画面表示中等の場合など)
        location.reload();
      })
    );
  }, [notification.item_id, matrixPath]);

  const onDownload = (kind: string, fileId: number, isQr: boolean, callback: (blob: any) => void) => {
    dispatch(actions.common.downloadAttachmentFile(kind, fileId, isQr, callback));
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Modal title={notification.notification_type_name} closeHandler={onClose}>
      <div id="progress-notification-detail-modal" className="modal-body">
        <div className="column">
          {Object.entries(notification.information)
            .filter(([key]) => ["担当者", "機器分類", "件名", "作業名"].includes(key))
            .map(([key, value]) => (
              <div className="row" key={key}>
                <div className="label">
                  {t(
                    {
                      担当者: "assignee",
                      機器分類: "machines_category",
                      件名: "title",
                      作業名: "process_title",
                    }[key]
                  )}
                </div>
                <div className="value">
                  {value}
                  {key === "件名" && !!matrixPath && (
                    <img className="icon-link" src="./img/icon-link-black.svg" alt="link" onClick={onClickOpenTitle} />
                  )}
                </div>
              </div>
            ))}
          <div className="row">
            <div className="label">{t("comments")}</div>
            {!!task?.comments?.length && (
              <ul className="comments form-comment-list">
                {task?.comments?.map((comment) => {
                  return (
                    <li key={comment.comment_id}>
                      <p className="comment-txt">{comment.comment}</p>
                      <p className="comment-info">
                        <span className="date">{format(new Date(comment.timestamp.insert_date), "yyyy/MM/dd")}</span>
                        <span className="user">{comment.user_name}</span>
                      </p>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
        <div className="column pl-60 pr-30">
          <div className="label mb-5">{t("uploaded_file")}</div>
          <ul className="file-list form-file-list">
            {task?.files?.map((file, index) => {
              return (
                <FileItem
                  key={index}
                  file={file}
                  kind="task"
                  readOnly
                  onDownload={onDownload}
                  additionalFilenameClass="mw-160"
                />
              );
            })}
          </ul>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-gray" onClick={onClose}>
          {t("close")}
        </button>
      </div>
    </Modal>
  );
};

export default connect(null, mapDispatchToProps)(NotificationDetailModal);
