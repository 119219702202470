import React from "react";

import Timestamp from "./Timestamp";
import Title from "./Title";

type Props = {
  title: string;
  timestamp?: string;
};

const Header: React.FC<Props> = ({ title, timestamp }) => {
  return (
    <div className="progress-notification-header">
      <Title data={title} />
      <Timestamp data={timestamp} />
    </div>
  );
};

export default Header;
