import React, { ComponentProps, FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../Button";

import { Modal } from "./Modal";
import { ModalBody } from "./ModalBody";
import { ModalFooter } from "./ModalFooter";
import { ModalHeader } from "./ModalHeader";

export type ConfirmModalProps = ComponentProps<typeof Modal> & {
  title: string;
  message: string;
  okClickHandler?: () => void;
};

export const ConfirmModal: FC<ConfirmModalProps> = ({ title, message, okClickHandler, onClose, ...restProps }) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} {...restProps}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody size="small" className="whitespace-pre-wrap">
        {message}
      </ModalBody>
      <ModalFooter>
        <Button buttonType="bg-[#dea321]" onClick={onClose}>
          {t("alert_ok")}
        </Button>
        {okClickHandler && <Button onClick={okClickHandler}>{t("confirm_accept")}</Button>}
      </ModalFooter>
    </Modal>
  );
};
