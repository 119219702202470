import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  unworkDays: Date[];
  onClick: () => void;
};

export const HolidaySummaryData = ({ unworkDays, onClick }: Props) => {
  const { t } = useTranslation();

  const label = useMemo(() => {
    if (unworkDays.length === 0) return `（${t("not_set")}）`;
    const holidayCounts: { month: number; count: number }[] = [];
    const date = new Date(unworkDays[0]);
    const end = unworkDays[unworkDays.length - 1];
    while (date.getFullYear() <= end.getFullYear() && date.getMonth() <= end.getMonth()) {
      const targets = unworkDays.filter(
        (v) => v.getFullYear() === date.getFullYear() && v.getMonth() === date.getMonth()
      );
      holidayCounts.push({ month: date.getMonth(), count: targets.length });
      date.setMonth(date.getMonth() + 1);
    }

    return holidayCounts.map((v) => t(`month_${v.month + 1}`) + "（" + v.count + "）").join("・");
  }, [unworkDays]);

  return (
    <div className="data" onClick={onClick}>
      <a>{label}</a>
    </div>
  );
};
