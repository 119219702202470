import * as constants from "../constants/index";

const checkpoint = (
  state = {
    category_id: 0,
    text: "",
    masterItems: [],
    items: [],
    current: null,
    fetching: false,
    isError: false,
  },
  action
) => {
  switch (action.type) {
    case constants.CHECKPOINT_BEGIN_FETCH_ITEMS: {
      return {
        ...state,
        fetching: true,
        isError: false,
      };
    }
    case constants.CHECKPOINT_END_FETCH_ITEMS: {
      return {
        ...state,
        masterItems: action.payload,
        items: action.payload,
        fetching: false,
      };
    }
    case constants.CHECKPOINT_CHANGE_SEARCH_CATEGORY_ID: {
      return {
        ...state,
        category_id: action.payload == 0 ? "" : action.payload,
      };
    }
    case constants.CHECKPOINT_CHANGE_SEARCH_TEXT: {
      return {
        ...state,
        text: action.payload,
      };
    }
    case constants.CHECKPOINT_BEGIN_SEARCH_ITEMS: {
      return {
        ...state,
        fetching: true,
        isError: false,
      };
    }
    case constants.CHECKPOINT_END_SEARCH_ITEMS: {
      return {
        ...state,
        items: action.payload,
        fetching: false,
      };
    }
    case constants.CHECKPOINT_END_FETCH_ITEM: {
      return {
        ...state,
        current: action.payload,
      };
    }
    case constants.CHECKPOINT_END_CREATE_ITEM: {
      const masterItems = [...state.masterItems, action.payload];
      const items = masterItems.filter(
        (item) =>
          item.checkpoint_name.indexOf(state.text) >= 0 &&
          item.category_id.toString().indexOf(state.category_id == 0 ? "" : state.category_id) >= 0
      );

      return {
        ...state,
        masterItems,
        items,
      };
    }
    case constants.CHECKPOINT_END_UPDATE_ITEM: {
      const masterItems = state.masterItems.map((item) => {
        if (item.checkpoint_id === action.payload.checkpoint_id) {
          return action.payload;
        }

        return item;
      });

      const items = masterItems.filter(
        (item) =>
          item.checkpoint_name.indexOf(state.text) >= 0 &&
          item.category_id.toString().indexOf(state.category_id == 0 ? "" : state.category_id) >= 0
      );

      return {
        ...state,
        masterItems,
        items,
      };
    }
    case constants.CHECKPOINT_BEGIN_DELETE_ITEM: {
      return {
        ...state,
        fetching: true,
        isError: false,
      };
    }
    case constants.CHECKPOINT_END_DELETE_ITEM: {
      return {
        ...state,
        masterItems: state.masterItems.filter((item) => item.checkpoint_id !== action.payload.id),
        items: state.items.filter((item) => item.checkpoint_id !== action.payload.id),
        fetching: false,
      };
    }
    case constants.APP_CHANGE_MENU: {
      return {
        ...state,
        text: "",
        category_id: 0,
        items: [...state.masterItems],
      };
    }
    case constants.APP_SHOW_ERROR: {
      if (action.errorFrom === "checkpoint") {
        return {
          ...state,
          fetching: false,
          isError: true,
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export default checkpoint;
