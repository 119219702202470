import { connect } from "react-redux";

import actions from "../../actions";
import Category from "../../components/master/Category";

const mapStateToProps = (state) => {
  return {
    masterItems: state.category.masterItems,
    text: state.category.text,
    items: state.category.items,
    current: state.category.current,
    fetching: state.category.fetching,
    isError: state.category.isError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSearchText: (text) => dispatch(actions.category.changeSearchText(text)),
    fetchItems: (callback) => dispatch(actions.category.fetchItems(callback)),
    searchItems: (text, callback) => dispatch(actions.category.searchItems(text, callback)),
    fetchItem: (id, callback, failedCallback) => dispatch(actions.category.fetchItem(id, callback, failedCallback)),
    createItem: (data, handleSuccess, handleCancel) =>
      dispatch(actions.category.createItem(data, handleSuccess, handleCancel)),
    updateItem: (data, handleSuccess, handleCancel, failedCallback) =>
      dispatch(actions.category.updateItem(data, handleSuccess, handleCancel, failedCallback)),
    deleteItem: (id, name) => dispatch(actions.category.deleteItem(id, name)),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);
