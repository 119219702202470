import moment from "moment";

import * as constants from "../constants/index";
import storageManager from "../lib/storageManager";

const getInitialSearchConditions = () => {
  const scheduleChibaSearchParams = storageManager.getConstructionItem("scheduleChibaSearchParams");
  if (scheduleChibaSearchParams !== null) {
    const params = JSON.parse(scheduleChibaSearchParams);

    return {
      ...params,
      scheduleDate: moment().format("YYYY-MM-DD"),
      checkpoint2: typeof params.checkpoint2 === "object" ? 0 : params.checkpoint2,
      checkpoint3: typeof params.checkpoint3 === "object" ? 0 : params.checkpoint3,
      checkpoint4: typeof params.checkpoint4 === "object" ? 0 : params.checkpoint4,
      checkpoint5: typeof params.checkpoint5 === "object" ? 0 : params.checkpoint5,
    };
  } else {
    return {
      areaIds: [],
      deviceIds: [],
      categoryIds: [],
      facilityManagementIds: [],
      constructionManagementIds: [],
      primaryChargeIds: [],
      otherIds: [],
      companyParams: [],
      field1Params: [],
      userParams: [],
      groupParams: [],
      approveParams: [],
      checkpoint1: 0,
      checkpoint2: 0,
      checkpoint3: 0,
      checkpoint4: 0,
      checkpoint5: 0,
      checkpoint6: 0,
      filter: 0,
      requestUser: 0,
      field5: 0,
      scheduleDate: moment().format("YYYY-MM-DD"),
    };
  }
};

const schedule_chiba = (
  state = {
    areaIds: [],
    deviceIds: [],
    categoryIds: [],
    facilityManagementIds: [],
    constructionManagementIds: [],
    primaryChargeIds: [],
    otherIds: [],
    companyParams: [],
    field1Params: [],
    userParams: [],
    groupParams: [],
    approveParams: [],
    checkpoint1: 0,
    checkpoint2: 0,
    checkpoint3: 0,
    checkpoint4: 0,
    checkpoint5: 0,
    checkpoint6: 0,
    filter: 0,
    requestUser: 0,
    field5: 0,
    scheduleDate: "",
    items: [],
    chiba: [],
    bulkRoles: [],
    scheduleUpdateDate: "",
    fetching: false,
    isError: false,
    validated: false,
    validRequiredParameter: undefined,
  },
  action
) => {
  switch (action.type) {
    case constants.APP_CONTENTS_READY: {
      return {
        ...state,
        ...getInitialSearchConditions(),
      };
    }
    case constants.SCHEDULE_CHIBA_SET_VALID_REQUIRED: {
      return {
        ...state,
        validRequiredParameter: Boolean(action.payload),
      };
    }
    case constants.CONSTRUCTION_END_FETCH_MASTERS: {
      const { areas, devices, categories } = action.payload;

      return {
        ...state,
        categoryIds: state.categoryIds.length === 0 && categories.length > 0 ? [] : state.categoryIds,
        areaIds: state.areaIds.length === 0 && areas.length > 0 ? [] : state.areaIds,
        deviceIds: state.deviceIds.length === 0 && devices.length > 0 ? [] : state.deviceIds,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_AREA: {
      return {
        ...state,
        areaIds: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_DEVICE: {
      return {
        ...state,
        deviceIds: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_FACILITY_MANAGEMENT: {
      return {
        ...state,
        facilityManagementIds: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_CATEGORY: {
      return {
        ...state,
        categoryIds: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_CONSTRUCTION_MANAGEMENT: {
      return {
        ...state,
        constructionManagementIds: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_PRIMARY_CHARGE: {
      return {
        ...state,
        primaryChargeIds: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_OTHER: {
      return {
        ...state,
        otherIds: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_CONSTRUCTION_COMPANY: {
      return {
        ...state,
        companyParams: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_FIELD1: {
      return {
        ...state,
        field1Params: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_USER: {
      return {
        ...state,
        userParams: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_GROUP: {
      return {
        ...state,
        groupParams: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_APPROVE_USER: {
      return {
        ...state,
        approveParams: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_CHECKPOINT1: {
      return {
        ...state,
        checkpoint1: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_CHECKPOINT2: {
      return {
        ...state,
        checkpoint2: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_CHECKPOINT3: {
      return {
        ...state,
        checkpoint3: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_CHECKPOINT4: {
      return {
        ...state,
        checkpoint4: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_CHECKPOINT5: {
      return {
        ...state,
        checkpoint5: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_CHECKPOINT6: {
      return {
        ...state,
        checkpoint6: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_REQUEST_USER: {
      return {
        ...state,
        requestUser: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_FIELD5: {
      return {
        ...state,
        field5: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CHANGE_SCHEDULE_DATE: {
      return {
        ...state,
        scheduleDate: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_BEGIN_SEARCH: {
      return {
        ...state,
        fetching: true,
        isError: false,
      };
    }
    case constants.SCHEDULE_CHIBA_END_SEARCH: {
      return {
        ...state,
        fetching: false,
        items: action.payload.list,
        bulkRoles: action.payload.roles,
        scheduleUpdateDate: action.payload.schedule_update_date,
        firstScheduleDate: action.payload.list.length > 0 ? action.payload.list[0].schedule_date : "",
        chiba: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_CLEAR_SEARCH: {
      return {
        ...state,
        areaIds: action.payload.areaIds,
        deviceIds: action.payload.deviceIds,
        categoryIds: action.payload.categoryIds,
        facilityManagementIds: action.payload.facilityManagementIds,
        constructionManagementIds: action.payload.constructionManagementIds,
        primaryChargeIds: action.payload.primaryChargeIds,
        otherIds: action.payload.otherIds,
        companyParams: action.payload.companyParams,
        field1Params: action.payload.field1Params,
        userParams: action.payload.userParams,
        groupParams: action.payload.groupParams,
        approveParams: action.payload.approveParams,
        checkpoint1: action.payload.checkpoint1,
        checkpoint2: action.payload.checkpoint2,
        checkpoint3: action.payload.checkpoint3,
        checkpoint4: action.payload.checkpoint4,
        checkpoint5: action.payload.checkpoint5,
        checkpoint6: action.payload.checkpoint6,
        filter: action.payload.filter,
        requestUser: action.payload.requestUser,
        field5: action.payload.field5,
        scheduleDate: "",
      };
    }
    case constants.COMMON_BEGIN_CHANGE_TASK_STATUS: {
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.task_id === action.payload.task_id) {
            return {
              ...item,
              processing: true,
              error: false,
            };
          }

          return item;
        }),
      };
    }
    case constants.COMMON_END_CHANGE_TASK_STATUS: {
      const { task_id, status, display_status, timestamp } = action.payload.task;

      return {
        ...state,
        items: state.items.map((item) => {
          if (item.task_id === task_id) {
            return {
              ...item,
              status,
              display_status,
              timestamp,
              processing: false,
              error: false,
            };
          }

          return item;
        }),
      };
    }
    case constants.COMMON_ERROR_CHANGE_TASK_STATUS: {
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.task_id === action.payload.task_id) {
            return {
              ...item,
              processing: false,
              error: true,
            };
          }

          return item;
        }),
      };
    }
    case constants.MATRIX_END_UPDATE_TASK_DETAIL: {
      const { itemId, taskId } = action.payload;
      const { schedule_end_date, display_status, schedule_type, status, timestamp } = action.payload.data;

      return {
        ...state,
        items: state.items.map((item) => {
          if (item.task_id === taskId && item.item_id === itemId) {
            return {
              ...item,
              schedule_date: schedule_end_date,
              display_status,
              schedule_type,
              status,
              timestamp,
            };
          }

          return item;
        }),
      };
    }
    case constants.SCHEDULE_CHIBA_END_UPDATE_ITEM: {
      const items = state.items.map((item) => {
        if (item.schedule_id === action.payload.schedule_id) {
          return action.payload;
        }

        return item;
      });

      return {
        ...state,
        items,
      };
    }
    case constants.SCHEDULE_CHIBA_END_UPDATE_ITEM_BUNDLE: {
      const items = state.items.map((item) => {
        if (item.schedule_id === action.payload.schedule_id) {
          return action.payload;
        }

        return item;
      });

      return {
        ...state,
        items,
      };
    }
    case constants.SCHEDULE_CHIBA_BEGIN_SEARCH_INFINITE: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.SCHEDULE_CHIBA_END_SEARCH_INFINITE: {
      return {
        ...state,
        items: [...state.items, ...action.payload],
        fetching: false,
      };
    }
    case constants.SCHEDULE_CHIBA_SET_VALIDATED: {
      return {
        ...state,
        validated: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_REVERT_CONDITION: {
      return {
        ...state,
        ...getInitialSearchConditions(),
      };
    }
    case constants.SCHEDULE_CHIBA_BEGIN_GET_OPTIONS: {
      return {
        ...state,
      };
    }
    case constants.SCHEDULE_CHIBA_END_GET_OPTIONS: {
      return {
        ...state,
        options: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_END_CREATE_SCHEDULE: {
      return {
        ...state,
        updates: action.payload,
      };
    }
    case constants.SCHEDULE_CHIBA_END_EDIT_SCHEDULE_URL: {
      // リンクの数を更新する
      const { itemId, urlNum } = action.payload;
      const items = state.items.concat().map((v) => {
        if (v.item_id === itemId) {
          return { ...v, item_url_num: urlNum };
        }
        return v;
      });
      return { ...state, items };
    }
    case constants.APP_SHOW_ERROR: {
      if (action.errorFrom === "schedule_chiba") {
        return {
          ...state,
          fetching: false,
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export default schedule_chiba;
