import React, { FC } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  displayMachineList: boolean;
  onToggleMachineList: () => void;
  filter: "all" | "inOperation" | "waiting";
  onFilter: (_: "all" | "inOperation" | "waiting") => void;
};

export const MachineListHeader: FC<Props> = ({ displayMachineList, onToggleMachineList, filter, onFilter }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center space-x-8 text-[13px] h-[40px]">
      <h3 className="my-10 font-bold text-[14px]">{t("machine_list")}</h3>
      <button onClick={onToggleMachineList}>
        <img src="./img/icon-down.svg" className={`mr-4 w-[24px] ${displayMachineList ? "" : "rotate-180"}`} />
      </button>
      <button
        type="button"
        onClick={() => onFilter("all")}
        className={`${
          filter === "all"
            ? "font-bold cursor-default text-[#1c77ff]"
            : "font-normal cursor hover:underline text-[#888888]"
        }`}
      >
        {t("all")}
      </button>
      <button
        type="button"
        onClick={() => onFilter("inOperation")}
        className={`${
          filter === "inOperation"
            ? "font-bold cursor-default text-[#1c77ff]"
            : "font-normal cursor hover:underline text-[#888888]"
        }`}
      >
        {t("in_operation")}
      </button>
      <button
        type="button"
        onClick={() => onFilter("waiting")}
        className={`${
          filter === "waiting"
            ? "font-bold cursor-default text-[#1c77ff]"
            : "font-normal cursor hover:underline text-[#888888]"
        }`}
      >
        {t("waiting")}
      </button>
    </div>
  );
};
