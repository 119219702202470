import React from "react";

type Props = {
  count?: number;
  onClick: () => void;
};

export const DataExportNotificationIcon: React.FC<Props> = ({ count, onClick }) => {
  return (
    <div id="button-header-export" data-test-id="button-header-export" className="data-export" onClick={onClick}>
      <img src="./img/icon-download-white.svg" alt="download" className="icon-file" />
      {!!count && (
        <div className="label-badge">
          {/* ブラウザの限界を超えてフォントを小さくするためscaleを使う */}
          <span style={{ transform: "scale(0.85)" }}>{count}</span>
        </div>
      )}
    </div>
  );
};
