import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

class ScheduleRightRowChita extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editCommentId: null,
      loading: false,
    };
  }

  beginEditComment(id) {
    this.setState({
      ...this.state,
      editCommentId: id,
    });
  }

  endEditComment(comment, row) {
    const { showAlert, hideAlert, t } = this.props;

    const data = {
      schedule_id: row.schedule_id,
      target: "note",
      value: comment,
      timestamp: row.timestamp,
    };

    if (comment.length > 500) {
      showAlert(t("confirmation"), [t("note_is_too_long_500")], hideAlert);

      return;
    }

    this.props.updateItem(
      data,
      () => {
        this.setState({ editCommentId: null });
      },
      () => {
        this.setState({ editCommentId: null });
      }
    );
  }

  componentDidUpdate() {
    if (this.commentTextarea) {
      this.commentTextarea.focus();
    }
  }

  render() {
    const { index, schedule, checkOpenEditorNolimit, showScheduleEditor, handleOnMouse, omitText, t } = this.props;

    return (
      <tr key={index}>
        {checkOpenEditorNolimit(schedule.roles, "field1") ? (
          <td
            className="col_chita grid-cell-clickable txt-center schedule-right-body"
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field1_name, 10)}
            onClick={() =>
              showScheduleEditor(
                "field1_id",
                t("gas_testing_hot_work") + "：" + t("assignee"),
                "[" + schedule.process_text + "]",
                schedule.schedule_id,
                schedule.field1_id,
                schedule.timestamp
              )
            }
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field1_name, 10)}
                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className="col_chita txt-center schedule-right-body"
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field1_name, 10)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field1_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {checkOpenEditorNolimit(schedule.roles, "field2") ? (
          <td
            className="col_chita grid-cell-clickable txt-center schedule-right-body"
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field2_name, 10)}
            onClick={() =>
              showScheduleEditor(
                "field2_id",
                t("gas_testing_confined_space") + "：" + t("assignee"),
                "[" + schedule.process_text + "]",
                schedule.schedule_id,
                schedule.field2_id,
                schedule.timestamp
              )
            }
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field2_name, 10)}
                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className="col_chita txt-center schedule-right-body"
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field2_name, 10)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field2_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {checkOpenEditorNolimit(schedule.roles, "field3") ? (
          <td
            className="col_chita grid-cell-clickable txt-center schedule-right-body"
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field3_name, 10)}
            onClick={() =>
              showScheduleEditor(
                "field3_id",
                t("permit_work") + "：" + t("permit"),
                "[" + schedule.process_text + "]",
                schedule.schedule_id,
                schedule.field3_id,
                schedule.timestamp
              )
            }
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field3_name, 10)}
                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className="col_chita txt-center schedule-right-body"
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field3_name, 10)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field3_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {checkOpenEditorNolimit(schedule.roles, "field4") ? (
          <td
            className="col4 grid-cell-clickable txt-center schedule-right-body"
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field4_name, 10)}
            onClick={() =>
              showScheduleEditor(
                "field4_id",
                t("approval") + "：" + t("tl_gm"),
                "[" + schedule.process_text + "]",
                schedule.schedule_id,
                schedule.field4_id,
                schedule.timestamp
              )
            }
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field4_name, 10)}
                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className="col4 txt-center schedule-right-body"
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field4_name, 10)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field4_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {checkOpenEditorNolimit(schedule.roles, "field5") ? (
          <td
            className="col_chita grid-cell-clickable txt-center schedule-right-body"
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field5_name, 10)}
            onClick={() =>
              showScheduleEditor(
                "field5_id",
                t("complete_process") + "：" + t("comp_continue"),
                "[" + schedule.process_text + "]",
                schedule.schedule_id,
                schedule.field5_id,
                schedule.timestamp
              )
            }
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field5_name, 10)}
                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className="col_chita txt-center schedule-right-body"
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field5_name, 10)}
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field5_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
      </tr>
    );
  }
}

ScheduleRightRowChita.propTypes = {
  index: PropTypes.number.isRequired,
  schedule: PropTypes.object.isRequired,
  checkOpenEditorNolimit: PropTypes.func.isRequired,
  showScheduleEditor: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
};

export default withTranslation()(ScheduleRightRowChita);
