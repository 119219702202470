import React from "react";

import BellIcon from "../../icons/BellIcon";

type Props = {
  count?: number;
  onClick: () => void;
};

const ProgressNotificationBell: React.FC<Props> = ({ count, onClick }) => {
  return (
    <div id="progress-notification-bell" onClick={onClick}>
      <BellIcon />
      {!!count && (
        <div className="label-badge">
          {/* ブラウザの限界を超えてフォントを小さくするためscaleを使う */}
          <span style={{ transform: "scale(0.85)" }}>{count}</span>
        </div>
      )}
    </div>
  );
};

export default ProgressNotificationBell;
