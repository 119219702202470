import React from "react";
import { useSelector } from "react-redux";

import BellIcon from "@/components/ProgressNotificationDrawer/icons/BellIcon";

export const LayoutNotificationBell: React.FC = () => {
  const count = useSelector((state) => state.notifications.summary.layout_notification.not_read_num);

  return (
    <div>
      <BellIcon />
      {!!count && (
        // 進捗管理と同じスタイル
        <div
          className={`
            bg-[#cc0000] 
            text-[white] text-[8px] 
            absolute top-[5px] right-[4px] h-[20px] w-[20px] 
            flex justify-center items-center 
            border-[1px] border-solid border-[#FFC79E] rounded-[50%]`}
        >
          {/* ブラウザの限界を超えてフォントを小さくするためscaleを使う */}
          <span className="scale-[0.85]">{count}</span>
        </div>
      )}
    </div>
  );
};
