import { connect } from "react-redux";

import actions from "../../actions";
import SchedulePanelMitsuiIwakuni from "../../components/summary/SchedulePanelMitsuiIwakuni";

const mapStateToProps = (state) => {
  const { scheduleSelectedCells, loadingPanel } = state.summary;

  return {
    scheduleSelectedCells,
    loadingPanel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createScheduleMitsuiIwakuni: (params, callback) =>
      dispatch(actions.summary.createScheduleMitsuiIwakuni(params, callback)),
    endSelectSchedule: (callback) => dispatch(actions.summary.endSelectSchedule(callback)),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulePanelMitsuiIwakuni);
