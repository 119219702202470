import "babel-polyfill";
import "whatwg-fetch";
import "url-search-params-polyfill";
import "../scss/styles.scss";
import "react-datepicker/src/stylesheets/datepicker.scss";
import React from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";

import AppContainer from "./containers/AppContainer";
import i18n from "./i18n";
import alert from "./middlewares/alert";
import api from "./middlewares/api";
import chain from "./middlewares/chain";
import confirm from "./middlewares/confirm";
import error from "./middlewares/error";
import stateUrl from "./middlewares/stateUrl";
import upload from "./middlewares/upload";
import validateApproval from "./middlewares/validateApproval";
import validateAsset from "./middlewares/validateAsset";
import validateImport from "./middlewares/validateImport";
import validateInspection from "./middlewares/validateInspection";
import validateMatrix from "./middlewares/validateMatrix";
import validateQrcode from "./middlewares/validateQrcode";
import validateSchedule from "./middlewares/validateSchedule";
import validateScheduleChiba from "./middlewares/validateScheduleChiba";
import validateScheduleChita from "./middlewares/validateScheduleChita";
import validateSummary from "./middlewares/validateSummary";
import validateSystem from "./middlewares/validateSystem";
import reducer from "./reducers/index";

const createStoreWithMiddleware = applyMiddleware(
  thunkMiddleware,
  alert,
  confirm,
  api,
  upload,
  error,
  chain,
  stateUrl,
  validateMatrix,
  validateQrcode,
  validateImport,
  validateSummary,
  validateApproval,
  validateInspection,
  validateSchedule,
  validateScheduleChita,
  validateScheduleChiba,
  validateSystem,
  validateAsset
)(createStore);

const store = createStoreWithMiddleware(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const container = document.getElementById("app");
const app = createRoot(container);

app.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <AppContainer />
    </I18nextProvider>
  </Provider>
);
