import { ComponentProps, FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../Button";

import { Modal } from "./Modal";
import { ModalBody } from "./ModalBody";
import { ModalFooter } from "./ModalFooter";
import { ModalHeader } from "./ModalHeader";

import { MachineIcon } from "@/sx-layout/common/MachineIcon";
import { Company } from "@/sx-layout/models";

export type HelpModalProps = ComponentProps<typeof Modal> & {
  companies: Company[];
};

export const HelpModal: FC<HelpModalProps> = ({ companies, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose}>
      <ModalHeader>{t("help")}</ModalHeader>
      <ModalBody size="normal" style={{ padding: "20px 40px" }} className="modal-body w-1000 items-start">
        <div
          style={{
            width: "500px",
            paddingTop: "0",
            paddingBottom: "0",
          }}
        >
          <div className="bg-white p-5">
            <table>
              <tbody>
                {companies.map((machine: Company) => (
                  <tr key={machine.company_id}>
                    <td
                      style={{
                        padding: "5px 10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: 45,
                          height: 45,
                          marginRight: "15px",
                        }}
                      >
                        <MachineIcon
                          active={true}
                          shapeColor={`#${machine.icon_color}`}
                          machineIconId={2}
                          selected={false}
                        />
                      </div>
                      <span>{machine.company_name}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button buttonType="cancel" onClick={onClose}>
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
