import PropTypes from "prop-types";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { withTranslation } from "react-i18next";
import Select from "react-select";

import styles from "../../constants/reactSelectStyle";
import TaskEditorContainer from "../../containers/list/TaskEditorContainer";
import { addHeightResizeListener, removeHeightResizeListener, getTableBodyHeight } from "../../lib/common";
import restoreState from "../../lib/restoreState";
import storageManager from "../../lib/storageManager";
import StatusSwitcher from "../common/StatusSwitcher";

import InspectionGridBody from "./InspectionGridBody";
import InspectionGridHeader from "./InspectionGridHeader";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

let autoload = false; // IEの二重リクエスト対策フラグ

class Inspection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch: true,
      showTable: false,
      showTaskEditor: false,
      currentRowIndex: -1,
      limit: 20,
      start: 1,
      end: false,
      isEmpty: false,
      editingItem: null,
      tableBodyMaxHeight: window.innerHeight - 420,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.infiniteSearch = this.infiniteSearch.bind(this);
    this.handleSearchBoxHeightChange = this.handleSearchBoxHeightChange.bind(this);
    this.isScrollable = this.isScrollable.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.fillList = this.fillList.bind(this);
    this.resetScroll = this.resetScroll.bind(this);
  }

  componentDidMount() {
    // 初期リリースでは検査リストはDisabled
    this.props.changeMenu("home");

    return;

    this.handleSearchBoxHeightChange();
    addHeightResizeListener(this.searchBox, this.handleSearchBoxHeightChange);

    this.props.changeSwitcherStatus(-1);
    this.resizeTimer = 0;
    window.addEventListener("resize", this.handleResize);

    const urlState = restoreState();
    const { validated } = this.props;
    if (
      validated &&
      (storageManager.getConstructionItem("inspectionSearchParams") !== null || (urlState && urlState.hasQuery))
    ) {
      this.handleSearch();
    }
  }

  componentDidUpdate(prevProps) {
    const { validated } = this.props;
    if (!validated) {
      return;
    }

    const urlState = restoreState();
    const notFirst =
      storageManager.getConstructionItem("inspectionSearchParams") !== null || (urlState && urlState.hasQuery);
    if (!prevProps.validated && validated && notFirst) {
      this.handleSearch();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    removeHeightResizeListener(this.searchBox);

    this.props.revertLocalCondition();
  }

  handleSearchBoxHeightChange() {
    const maxHeight = getTableBodyHeight("inspection", this.searchBox, this.theader);
    this.setState({ tableBodyMaxHeight: maxHeight });
  }

  handleResize() {
    if (this.resizeTimer > 0) {
      clearTimeout(this.resizeTimer);
    }

    const callback = () => {
      this.handleSearchBoxHeightChange();
      this.fillList();
    };

    this.resizeTimer = setTimeout(callback, 200);
  }

  toggleSearch() {
    const callback = () => {
      this.handleSearchBoxHeightChange();
      addHeightResizeListener(this.searchBox, this.handleSearchBoxHeightChange);
      this.fillList();
    };
    this.setState({ showSearch: !this.state.showSearch }, () => setTimeout(callback, 100));
  }

  showTable(callback) {
    this.setState({ showTable: true }, () => {
      if (callback !== undefined) {
        // コールバックにしてもsetState直後はDOMに反映していない
        // ことがあるため、時間差で実行する
        setTimeout(callback, 100);
      }
    });
  }

  showTaskEditor(item) {
    this.setState({
      editingItem: item,
      showTaskEditor: true,
    });
  }

  hideTaskEditor() {
    this.setState({ showTaskEditor: false });
  }

  isScrollable() {
    const container = this.tbody;

    if (!container) {
      return false;
    }

    const diff = container.clientHeight - container.scrollHeight;

    return diff !== 0;
  }

  fillList() {
    const { showTable, end } = this.state;

    if (!showTable || end) {
      return;
    }

    if (!this.isScrollable()) {
      this.infiniteSearch();
    }
  }

  handleScroll() {
    const container = this.tbody;
    const containerHeight = container.clientHeight;
    const contentHeight = container.scrollHeight;
    const scrollTop = container.scrollTop;
    const diff = contentHeight - (containerHeight + scrollTop);

    if (diff <= 10 && !this.state.end) {
      this.infiniteSearch();
    }
  }

  handleSearch() {
    this.setState({ isEmpty: true });

    const {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      processMajorClassIds,
      scheduleDate,
      checkpointIds,
      itemText,
      processText,
      filter,
      search,
    } = this.props;

    const params = {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      processMajorClassIds,
      scheduleDate,
      checkpointIds,
      itemText,
      processText,
      filter,
      start: 1,
      limit: this.state.limit,
    };

    search(params, (data) => {
      this.setState(
        {
          start: data.length + 1,
          end: data.length < this.state.limit,
          isEmpty: data.length === 0,
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  resetScroll() {
    const container = this.tbody;

    if (container) {
      container.scrollTop = 0;
    }
  }

  handleClear() {
    this.props.clearSearch();
    this.resetScroll();
    this.setState({ start: 1, showTable: false });

    const params = {
      start: 1,
      limit: this.state.limit,
    };

    this.props.search(params, (data) => {
      this.setState(
        {
          start: data.length + 1,
          end: data.length < this.state.limit,
          isEmpty: data.length === 0,
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  infiniteSearch() {
    if (autoload) {
      return;
    }

    autoload = true;

    const {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      processMajorClassIds,
      scheduleDate,
      checkpointIds,
      itemText,
      processText,
      filter,
      search,
    } = this.props;

    const params = {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      processMajorClassIds,
      scheduleDate,
      checkpointIds,
      itemText,
      processText,
      filter,
      start: this.state.start,
      limit: this.state.limit,
    };

    search(params, (data) => {
      autoload = false;

      this.setState(
        {
          start: this.state.start + data.length,
          end: data.length < this.state.limit,
        },
        this.fillList
      );
    });
  }

  render() {
    const titleClassName = `toggle icon-keyboard_arrow_up ${!this.state.showSearch ? "closed" : ""}`;
    const {
      masters,
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      processMajorClassIds,
      scheduleDate,
      checkpointIds,
      itemText,
      processText,
      filter,
      changeArea,
      changeDevice,
      changeFacilityManagement,
      changeCategory,
      changeConstructionManagement,
      changePrimaryCharge,
      changeOther,
      changeProcessMajorClass,
      changeScheduleDate,
      changeCheckpoint,
      changeItemText,
      changeProcessText,
      changeFilter,
      t,
      fetching,
      isError,
    } = this.props;

    return (
      <div>
        <div className="contents">
          <div className="inner">
            <h1 className="page-ttl">
              {t("inspection_list")}
              <span className={titleClassName} onClick={() => this.toggleSearch()}></span>
            </h1>
            {this.state.showSearch && (
              <div className="search-box" ref={(node) => (this.searchBox = node)}>
                <div className="form-row">
                  <div className="form-group w-240">
                    <span className="form-label">{t("area")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        styles={styles}
                        isMulti={true}
                        options={masters.areas.map((area) => ({ value: area.area_id, label: area.area_name }))}
                        onChange={(e) => changeArea(e)}
                        value={masters.areas
                          .filter((area) => _.includes(areaIds, area.area_id))
                          .map((area) => ({ value: area.area_id, label: area.area_name }))}
                      />
                    </div>
                  </div>
                  <div className="form-group w-260">
                    <span className="form-label">{t("device")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        styles={styles}
                        isMulti={true}
                        options={masters.devices.map((device) => ({
                          value: device.device_id,
                          label: device.device_name,
                        }))}
                        onChange={(e) => changeDevice(e)}
                        value={masters.devices
                          .filter((device) => _.includes(deviceIds, device.device_id))
                          .map((device) => ({ value: device.device_id, label: device.device_name }))}
                      />
                    </div>
                  </div>
                  <div className="form-group w-290">
                    <span className="form-label">{t("facility_management")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        styles={styles}
                        isMulti={true}
                        options={masters.facilityManagements.map((facilityManagement) => ({
                          value: facilityManagement.facility_management_id,
                          label: facilityManagement.facility_management_name,
                        }))}
                        onChange={(e) => changeFacilityManagement(e)}
                        value={masters.facilityManagements
                          .filter((facilityManagement) =>
                            _.includes(facilityManagementIds, facilityManagement.facility_management_id)
                          )
                          .map((facilityManagement) => ({
                            value: facilityManagement.facility_management_id,
                            label: facilityManagement.facility_management_name,
                          }))}
                      />
                    </div>
                  </div>
                  <div className="form-group w-370">
                    <span className="form-label">{t("machines_category")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        styles={styles}
                        isMulti={false}
                        isClearable={true}
                        options={masters.categories.map((category) => ({
                          value: category.category_id,
                          label: category.category_name,
                        }))}
                        onChange={(e) => changeCategory(e)}
                        value={masters.categories
                          .filter((category) => category.category_id === categoryId)
                          .map((category) => ({ value: category.category_id, label: category.category_name }))}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group w-240">
                    <span className="form-label">{t("construction_management")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        styles={styles}
                        isMulti={true}
                        options={masters.constructionManagements.map((constructionManagement) => ({
                          value: constructionManagement.construction_management_id,
                          label: constructionManagement.construction_management_name,
                        }))}
                        onChange={(e) => changeConstructionManagement(e)}
                        value={masters.constructionManagements
                          .filter((constructionManagement) =>
                            _.includes(constructionManagementIds, constructionManagement.construction_management_id)
                          )
                          .map((constructionManagement) => ({
                            value: constructionManagement.construction_management_id,
                            label: constructionManagement.construction_management_name,
                          }))}
                      />
                    </div>
                  </div>
                  <div className="form-group w-260">
                    <span className="form-label">{t("primary_charge")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        styles={styles}
                        isMulti={true}
                        options={masters.primaryCharges.map((primaryCharge) => ({
                          value: primaryCharge.primary_charge_id,
                          label: primaryCharge.primary_charge_name,
                        }))}
                        onChange={(e) => changePrimaryCharge(e)}
                        value={masters.primaryCharges
                          .filter((primaryCharge) => _.includes(primaryChargeIds, primaryCharge.primary_charge_id))
                          .map((primaryCharge) => ({
                            value: primaryCharge.primary_charge_id,
                            label: primaryCharge.primary_charge_name,
                          }))}
                      />
                    </div>
                  </div>
                  <div className="form-group w-290">
                    <span className="form-label">{t("other")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        styles={styles}
                        isMulti={true}
                        options={masters.others.map((other) => ({ value: other.other_id, label: other.other_name }))}
                        onChange={(e) => changeOther(e)}
                        value={masters.others
                          .filter((other) => _.includes(otherIds, other.other_id))
                          .map((other) => ({ value: other.other_id, label: other.other_name }))}
                      />
                    </div>
                  </div>
                  <div className="form-group w-370">
                    <span className="form-label">{t("process_major_class")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        styles={styles}
                        isMulti={true}
                        options={masters.processMajorClasses.map((processMajorClass) => ({
                          value: processMajorClass.process_major_class_id,
                          label: processMajorClass.process_major_class_name,
                        }))}
                        onChange={(e) => changeProcessMajorClass(e)}
                        value={masters.processMajorClasses
                          .filter((processMajorClass) =>
                            _.includes(processMajorClassIds, processMajorClass.process_major_class_id)
                          )
                          .map((processMajorClass) => ({
                            value: processMajorClass.process_major_class_id,
                            label: processMajorClass.process_major_class_name,
                          }))}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group w-240">
                    <span className="form-label">{t("scheduled_date")}</span>
                    <div className="w-170 d-ib ta-l">
                      <DatePicker
                        selected={scheduleDate === "" ? null : moment(scheduleDate, "YYYY/MM/DD").toDate()}
                        dateFormat="yyyy/MM/dd"
                        locale={t("calender_locale")}
                        onChange={(date) =>
                          changeScheduleDate(date === null ? "" : moment(date).format("YYYY/MM/DD").toString())
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group w-260">
                    <span className="form-label">{t("confirmation")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        styles={styles}
                        isMulti={true}
                        options={masters.checkpoints.map((checkpoint) => ({
                          value: checkpoint.checkpoint_id,
                          label: checkpoint.checkpoint_name,
                        }))}
                        onChange={(e) => changeCheckpoint(e)}
                        value={masters.checkpoints
                          .filter((checkpoint) => _.includes(checkpointIds, checkpoint.checkpoint_id))
                          .map((checkpoint) => ({
                            value: checkpoint.checkpoint_id,
                            label: checkpoint.checkpoint_name,
                          }))}
                      />
                    </div>
                  </div>
                  <div className="form-group w-290">
                    <span className="form-label">{t("title")}</span>
                    <input
                      type="text"
                      className="form-control w-170"
                      value={itemText}
                      onChange={(e) => changeItemText(e.target.value)}
                    />
                  </div>
                  <div className="form-group w-370">
                    <span className="form-label">{t("process_title")}</span>
                    <input
                      type="text"
                      className="form-control w-250"
                      value={processText}
                      onChange={(e) => changeProcessText(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-row mb-0">
                  <div className="form-group mt-10 ml-10">
                    <label className="ckbox">
                      <input type="checkbox" checked={_.includes(filter, 1)} onChange={() => changeFilter(1)} />
                      <span>{t("inspection_ready")}</span>
                    </label>
                    <label className="ckbox">
                      <input type="checkbox" checked={_.includes(filter, 2)} onChange={() => changeFilter(2)} />
                      <span>{t("expired")}</span>
                    </label>
                    <label className="ckbox">
                      <input type="checkbox" checked={_.includes(filter, 3)} onChange={() => changeFilter(3)} />
                      <span>{t("completed")}</span>
                    </label>
                  </div>
                  <div className="form-group btn-area pull-right mt-0">
                    <button className="btn btn-gray" onClick={this.handleClear}>
                      {t("reset")}
                    </button>
                    <button className="btn btn-blue" onClick={this.handleSearch}>
                      {t("search")}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {this.state.showTable && !this.state.isEmpty && !isError && (
              <div className="tbl-top-area clearfix">
                <div className="tbl-top-left">
                  <StatusSwitcher
                    value={this.props.switcherStatus}
                    onChange={(value) => this.props.changeSwitcherStatus(value)}
                  />
                </div>
              </div>
            )}
            <div className={`tbl-inspections ${fetching ? "loading loading--list" : ""}`}>
              {this.state.isEmpty
                ? this.state.showTable && !isError && !fetching && <p className="empty-message">{t("no_data")}</p>
                : this.state.showTable &&
                  !isError && (
                    <React.Fragment>
                      <div
                        className="tbl-area tbl-inspections-header tbl-head-adjusted"
                        ref={(node) => (this.theader = node)}
                      >
                        <InspectionGridHeader userRole={masters.userRole} />
                      </div>
                      <div
                        className={`tbl-area tbl-inspections-body ${!this.state.showSearch ? "is-large" : ""} ${
                          this.props.switcherStatus === -1 ? "" : `cursor-${this.props.switcherStatus}`
                        }`}
                        ref={(node) => (this.tbody = node)}
                        onScroll={this.handleScroll}
                        style={{ maxHeight: this.state.tableBodyMaxHeight }}
                      >
                        <InspectionGridBody
                          rows={this.props.items}
                          switcherStatus={this.props.switcherStatus}
                          showTaskEditor={(item) => this.showTaskEditor(item)}
                        />
                      </div>
                    </React.Fragment>
                  )}
            </div>
          </div>
        </div>
        {this.state.showTaskEditor && (
          <TaskEditorContainer
            taskId={this.state.editingItem.task_id}
            itemName={this.state.editingItem.item_name}
            processName={this.state.editingItem.process_name}
            categoryId={this.state.editingItem.category_id}
            closeHandler={() => this.hideTaskEditor()}
            saveHandler={() => this.hideTaskEditor()}
          />
        )}
      </div>
    );
  }
}

Inspection.propTypes = {
  menu: PropTypes.string.isRequired,
  areaIds: PropTypes.array.isRequired,
  deviceIds: PropTypes.array.isRequired,
  categoryId: PropTypes.number.isRequired,
  facilityManagementIds: PropTypes.array.isRequired,
  constructionManagementIds: PropTypes.array.isRequired,
  primaryChargeIds: PropTypes.array.isRequired,
  otherIds: PropTypes.array.isRequired,
  processMajorClassIds: PropTypes.array.isRequired,
  scheduleDate: PropTypes.string.isRequired,
  checkpointIds: PropTypes.array.isRequired,
  itemText: PropTypes.string.isRequired,
  processText: PropTypes.string.isRequired,
  filter: PropTypes.array.isRequired,
  masters: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  changeArea: PropTypes.func.isRequired,
  changeDevice: PropTypes.func.isRequired,
  changeFacilityManagement: PropTypes.func.isRequired,
  changeCategory: PropTypes.func.isRequired,
  changeConstructionManagement: PropTypes.func.isRequired,
  changePrimaryCharge: PropTypes.func.isRequired,
  changeOther: PropTypes.func.isRequired,
  changeProcessMajorClass: PropTypes.func.isRequired,
  changeScheduleDate: PropTypes.func.isRequired,
  changeCheckpoint: PropTypes.func.isRequired,
  changeItemText: PropTypes.func.isRequired,
  changeProcessText: PropTypes.func.isRequired,
  changeFilter: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  changeSwitcherStatus: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  revertLocalCondition: PropTypes.func.isRequired,
  validated: PropTypes.bool.isRequired,
};

export default withTranslation()(Inspection);
