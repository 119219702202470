import React from "react";

type Props = {
  label: string;
  content: string;
  comment_num: number | undefined;
};

const DetailRow: React.FC<Props> = ({ label, content, comment_num }) => {
  return (
    <span className="progress-notification-detail-row">
      <span className="progress-notification-detail-row-label">{label}</span>
      <span>：</span>
      <span className="progress-notification-detail-row-content">
        {!!content && (
          <>
            {content.split("\n").map((val, index) => (
              <p key={"notification-detail-row-content-" + index}>{val}</p>
            ))}
            {label === "コメント" && comment_num > 1 && <p>（+{comment_num - 1}件）</p>}
          </>
        )}
      </span>
    </span>
  );
};

export default DetailRow;
