import React, { ComponentProps } from "react";

import { cn } from "@/lib/classNames";

// MEMO: サイズのパターンを増やすときに"NormalLabelSize" | "LargeLabelSize" のように増やしていく
type FormErrorMarginLeftSize = "NormalLabelSize" | "0";

type FormErrorProps = ComponentProps<"p"> & {
  marginLeftSize?: FormErrorMarginLeftSize;
};

export const FormError: React.FC<FormErrorProps> = ({
  children,
  className,
  marginLeftSize = "NormalLabelSize",
  ...props
}) => {
  return (
    <p
      className={cn(
        "p-0 mt-0 text-[13px] text-[#c00]",
        marginLeftSize === "NormalLabelSize" && "ml-[124px]",
        className
      )}
      {...props}
    >
      {children}
    </p>
  );
};
