import actions from "../actions";
import { validateCondition } from "../lib/validate";

const validateScheduleChiba = (store) => (next) => (action) => {
  next(action);
  const state = store.getState();
  if (!(state.app.menu === "schedule_chiba" && state.construction.masters)) {
    return;
  }

  const {
    areaIds,
    deviceIds,
    categoryIds,
    facilityManagementIds,
    constructionManagementIds,
    primaryChargeIds,
    otherIds,
    validated,
  } = state.schedule_chiba;

  if (validated) {
    return;
  }

  const { areas, devices, categories, facility_managements, construction_managements, primary_charges, others } =
    state.construction.masters;

  const {
    changeArea,
    changeDevice,
    changeCategory,
    changeFacilityManagement,
    changeConstructionManagement,
    changePrimaryCharge,
    changeOther,
    setValidated,
  } = actions.schedule_chiba;

  const validatedAreaIds = validateCondition(areas, areaIds);
  if (areaIds.length !== validatedAreaIds.length) {
    next(changeArea(validatedAreaIds));
  }

  const validatedDeviceIds = validateCondition(devices, deviceIds);
  if (deviceIds.length !== validatedDeviceIds.length) {
    next(changeDevice(validatedDeviceIds));
  }

  const validatedCategoryIds = validateCondition(categories, categoryIds);
  if (categoryIds.length !== validatedCategoryIds.length) {
    next(changeCategory(validatedCategoryIds));
  }

  const validatedFacilityManagementIds = validateCondition(facility_managements, facilityManagementIds);
  if (validatedFacilityManagementIds.length !== facilityManagementIds.length) {
    next(changeFacilityManagement(validatedFacilityManagementIds));
  }
  const validatedConstructionManagements = validateCondition(construction_managements, constructionManagementIds);
  if (validatedConstructionManagements.length !== constructionManagementIds.length) {
    next(changeConstructionManagement(validatedConstructionManagements));
  }
  const validatedPrimaryChargeIds = validateCondition(primary_charges, primaryChargeIds);
  if (validatedPrimaryChargeIds.length !== primaryChargeIds.length) {
    next(changePrimaryCharge(validatedPrimaryChargeIds));
  }
  const validatedOtherIds = validateCondition(others, otherIds);
  if (validatedOtherIds.length !== otherIds.length) {
    next(changeOther(validatedOtherIds));
  }

  next(setValidated(true));

  return;
};

export default validateScheduleChiba;
