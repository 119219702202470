import * as constants from "@/constants";
import environments from "@/environments";

export const fetchLayoutMasters = () => {
  const endpoint = `${environments.API_BASE_URI}/construction/layout_masters`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    callbacks: {
      begin: () => {
        return {
          type: constants.LAYOUT_MASTERS_BEGIN_FETCH_MASTERS,
        };
      },
      success: (response) => {
        return {
          type: constants.LAYOUT_MASTERS_END_FETCH_MASTERS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};
