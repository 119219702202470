import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class InspectionGridHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t, userRole } = this.props;

    return (
      <table className="tbl-basic tbl-data list-table-row">
        <tbody>
          <tr>
            <th className="insp_col1">No</th>
            <th className="insp_col2">{t("machines_category")}</th>
            <th className="insp_col3">{t("title")}</th>
            <th className="insp_col4">{t("preprocess_title")}</th>
            <th className="insp_col5">{t("preprocess_status")}</th>
            <th className="insp_col6">{t("process_major_class")}</th>
            <th className="insp_col7">{t("process_middle_class")}</th>
            <th className="insp_col8">{t("process_title")}</th>
            <th className="insp_col9">{t("scheduled_date")}</th>
            <th className="insp_col10">{t("status")}</th>
            <th className="insp_col11">{userRole !== 3 ? t("edit") : t("display")}</th>
          </tr>
        </tbody>
      </table>
    );
  }
}

InspectionGridHeader.propTypes = {
  t: PropTypes.func.isRequired,
  userRole: PropTypes.number,
};

export default withTranslation()(InspectionGridHeader);
