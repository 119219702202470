import * as constants from "@/constants";
import { NotificationList } from "@/sx-layout/components/notification/models";

const initState = {
  total_num: 0,
  list: [],
};

export type LayoutNotificationState = {
  notifications: NotificationList;
  fetching: boolean;
};

export const layoutNotifications = (
  state: LayoutNotificationState = {
    notifications: initState,
    fetching: false,
  },
  action
) => {
  switch (action.type) {
    case constants.LAYOUT_NOTIFICATION_BEGIN_FETCH_NOTIFICATIONS: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.LAYOUT_NOTIFICATION_END_FETCH_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.payload,
        fetching: false,
      };
    }
    case constants.LAYOUT_NOTIFICATION_CLEAR_NOTIFICATIONS: {
      return {
        ...state,
        notifications: initState,
      };
    }
    case constants.APP_SHOW_ERROR: {
      return {
        ...state,
        fetching: false,
      };
    }
    default:
      return state;
  }
};
