import { TimeStamp } from "@/models/timestamp";

export const MATRIX_TABLE_DATA_WIDTH = 80;

export type DragColumn = {
  id: number;
  index: number;
  offsetLeft: number;
  offsetTop: number;
  offsetWidth: number;
};

export type MatrixSearchParams = {
  categoryId: number;
  primaryChargeId: number;
  areaIds?: number[];
  deviceIds?: number[];
  facilityManagementIds?: number[];
  constructionManagementIds?: number[];
  otherIds?: number[];
  systemIds?: number[];
  companyIds?: number[];
  userIds?: number[];
  groupIds?: number[];
  processMiddleClassIds?: number[];
  itemText?: string;
  processText?: string;
  regulation?: string;
  filters?: number[];
};

export type MatrixItem = {
  item_id: number;
  item_name: string;
  sort: number;
};

export type Process = {
  process_id: number;
  category_id: number;
  process_major_class_id: number;
  process_middle_class_id: number;
  coordinate_flg: boolean;
  company_id: number;
  process_name: string;
  field_p1: string;
  field_p2: string;
  field_p3: string;
  output_flg: boolean;
  schedule_roles: number[];
  weight: number;
  sort: number;
  roles: number[];
  timestamp: TimeStamp;
};

export type ProcessSegmentLabel = {
  segment_label_id: number;
  category_id: number;
  primary_charge_id: number;
  sort: number;
  segment_label_name: string;
};
