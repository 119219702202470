import * as constants from "../constants/index";
import environments from "../environments";

export const fetchItems = (callback) => {
  const endpoint = `${environments.API_BASE_URI}/checkpoints`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.CHECKPOINT_BEGIN_FETCH_ITEMS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.CHECKPOINT_END_FETCH_ITEMS,
          payload: response.checkpoints,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const changeSearchId = (category_id) => {
  return {
    type: constants.CHECKPOINT_CHANGE_SEARCH_CATEGORY_ID,
    payload: category_id,
  };
};

export const changeSearchText = (text) => {
  return {
    type: constants.CHECKPOINT_CHANGE_SEARCH_TEXT,
    payload: text,
  };
};

export const searchItems = (category_id, text, callback) => {
  let endpoint = `${environments.API_BASE_URI}/checkpoints`;
  const params = new URLSearchParams();

  if (category_id && category_id.length > 0) {
    params.set("category_id", category_id);
  }

  if (text && text.length > 0) {
    params.set("text", text);
  }

  if (params.toString() !== "") {
    endpoint = `${endpoint}?${params.toString()}`;
  }

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.CHECKPOINT_BEGIN_SEARCH_ITEMS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.CHECKPOINT_END_SEARCH_ITEMS,
          payload: response.checkpoints,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchItem = (id, callback, failedCallback) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/checkpoints/${id}`,
    method: "GET",
    callbacks: {
      success: (response) => {
        callback();

        return {
          type: constants.CHECKPOINT_END_FETCH_ITEM,
          payload: response,
        };
      },
      error: (response) => {
        if (failedCallback !== undefined) {
          failedCallback();
        }

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const createItem = (data, handleSuccess, handleCancel) => {
  return (dispatch, getState) => {
    const { category_id, checkpoint_name, sort } = data;
    const action = {
      type: constants.APP_CALL_API,
      endpoint: `${environments.API_BASE_URI}/checkpoints/create`,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        category_id,
        checkpoint_name,
        sort,
      }),
      callbacks: {
        success: (response) => {
          handleSuccess();

          return {
            type: constants.CHECKPOINT_END_CREATE_ITEM,
            payload: response,
          };
        },
        error: (response) => {
          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    };

    // 機器分類名の重複チェック（重複している場合はアラート＆登録中止）
    const state = getState();
    const items = state.checkpoint.masterItems;

    if (items.some((item) => item.checkpoint_name === checkpoint_name && item.category_id === category_id)) {
      action.meta = {
        alert: {
          messages: ["msg_already", "checkpoint_name", "msg_exists"],
          okHandler: handleCancel,
        },
      };
    }

    dispatch(action);
  };
};

export const updateItem = (data, handleSuccess, handleCancel, failedCallback) => {
  return (dispatch, getState) => {
    const { checkpoint_id, category_id, checkpoint_name, sort, timestamp } = data;
    const action = {
      type: constants.APP_CALL_API,
      endpoint: `${environments.API_BASE_URI}/checkpoints/update/${checkpoint_id}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        category_id,
        checkpoint_name,
        sort,
        timestamp,
      }),
      callbacks: {
        success: (response) => {
          handleSuccess();

          return {
            type: constants.CHECKPOINT_END_UPDATE_ITEM,
            payload: response,
          };
        },
        error: (response) => {
          if (failedCallback !== undefined) {
            failedCallback(response);
          }

          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    };

    // 確認項目名の重複チェック（重複している場合はアラート＆登録中止）
    const state = getState();
    const items = state.checkpoint.masterItems;

    if (
      items.some(
        (item) =>
          item.checkpoint_id !== checkpoint_id &&
          item.checkpoint_name === checkpoint_name &&
          item.category_id === category_id
      )
    ) {
      action.meta = {
        alert: {
          messages: ["msg_already", "checkpoint_name", "msg_exists"],
          okHandler: handleCancel,
        },
      };
    }

    dispatch(action);
  };
};

export const deleteItem = (id, name) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/checkpoints/delete/${id}`,
    method: "POST",
    callbacks: {
      begin: () => {
        return {
          type: constants.CHECKPOINT_BEGIN_DELETE_ITEM,
        };
      },
      success: (response) => {
        return {
          type: constants.CHECKPOINT_END_DELETE_ITEM,
          payload: {
            id,
            ...response,
          },
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
    meta: {
      confirm: {
        messages: [name, "msg_deleted"],
      },
    },
  };
};
