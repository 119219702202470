import React from "react";
import { useTranslation } from "react-i18next";

import NotificationRow from "@/sx-layout/components/notification/components/NotificationRow";
import {
  NotificationList,
  LayoutNotificationFetchedNotificationLargestId,
  LayoutNotificationType,
} from "@/sx-layout/components/notification/models";
import { Loading } from "@/sx-layout/components/plotmap/components/Loading";

type Props = {
  notifications: NotificationList["list"];
  fetchedNotificationLargestIdBeforeOpen: LayoutNotificationFetchedNotificationLargestId;
  notificationType: LayoutNotificationType;
  isLoading: boolean;
};

const LayoutNotificationList: React.FC<Props> = ({
  notifications,
  fetchedNotificationLargestIdBeforeOpen,
  notificationType,
  isLoading,
}) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div id="layout-notification-list">
      {notifications.length === 0 && !isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          {t("no_data")}
        </div>
      )}
      {notifications.map((notification) => (
        <NotificationRow
          key={notification.layout_notification_id}
          notification={notification}
          fetchedNotificationLargestIdBeforeOpen={fetchedNotificationLargestIdBeforeOpen}
          notificationType={notificationType}
        />
      ))}
    </div>
  );
};

export default LayoutNotificationList;
