import React, { FC, useEffect } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import actions from "@/actions";
import { getTextLinkFromClipboard } from "@/lib/clipboard";
import { ScheduleUrl } from "@/models/scheduleChiba";

type Props = {
  itemId: number;
  onCreateUrl: (url: ScheduleUrl) => void;
  onClose: () => void;
};

export const AddClipboardDataDialog: FC<Props> = ({ itemId, onCreateUrl, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handlePaste = async () => {
    const links = await getTextLinkFromClipboard();
    if (links.length) {
      dispatch(
        actions.schedule_chiba.createScheduleUrl(
          {
            item_id: itemId,
            label: links[0].text,
            url: links[0].href,
          },
          (res) => {
            onCreateUrl(res);
            onClose();
          }
        )
      );
    } else {
      dispatch(
        actions.app.showAlert(t("paste_link"), [t("link_not_found_on_clipboard")], () => {
          dispatch(actions.app.hideAlert());
        })
      );
    }
  };

  const keydownHandler = (e: KeyboardEvent) => {
    if (e.key === "v" && (e.ctrlKey || e.metaKey)) {
      handlePaste();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keydownHandler);

    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, []);

  return (
    <div className="modal">
      <Draggable handle=".modal-header">
        <div className="modal-drag-container">
          <div className="modal-dialog">
            <div className={"modal-content"}>
              <div className="modal-header">
                <h3 className="modal-ttl">{t("paste_link")}</h3>
                <div className="btn-close" onClick={onClose}>
                  <span className="icon icon-close"></span>
                </div>
              </div>
              <div className="modal-body clipboard-dialog">
                <div className="paste-area">
                  <p className="mb-1.5">{t("paste_link_here")}</p>
                  <p className="mb-1.5">{t("or")}</p>
                  <button
                    data-test-id="button-paste"
                    className="btn btn-blue h-[20px]"
                    style={{ padding: "0 5px" }}
                    onClick={handlePaste}
                  >
                    {t("paste")}
                  </button>
                </div>
              </div>
              <div className={"modal-footer"}>
                <button
                  data-test-id="button-paste-editor-cancel"
                  type="button"
                  className="btn btn-gray"
                  onClick={onClose}
                >
                  {t("cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    </div>
  );
};
