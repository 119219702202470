import actions from "../actions";
import * as constants from "../constants";
import * as util from "../lib/common";

const X_SXP_USER_ID = "X-SXP-User-Id";
const X_SXP_CONSTRUCTION_ID = "X-SXP-Construction-Id";
const X_SXP_LANGUAGE = "X-SXP-Language";
const X_SXP_OPTIONS = "X-SXP-Options";

const blobTypes = ["excel", "pdf", "image", "application/zip"];

const convert = (data, contentType) => {
  if (isBlob(contentType)) {
    return data.blob();
  }

  if (contentType.indexOf("text/csv") !== -1) {
    return data.text();
  }

  return data.json();
};

const isBlob = (contentType) => {
  return blobTypes.some((t) => contentType.indexOf(t) !== -1);
};

const getFilenameFromContentDisposition = (contentDisposition) => {
  const regex = /filename[^;=\n]*=(UTF-8(['"]*))?(.*)/;
  const matches = regex.exec(contentDisposition);
  let filename;

  if (matches !== null && matches[3]) {
    filename = matches[3].replace(/['"]/g, "");
  }

  return decodeURIComponent(filename);
};

const api = (store) => (next) => (action) => {
  next(action);

  if (action.type === constants.APP_CALL_API) {
    const method = action.method || "GET";
    const headers = action.headers || {};
    const sessionToken = util.getSessionToken();
    const userId = util.getUserId();
    const state = store.getState();
    const constructionId = state.construction.constructionId;
    const { lang } = state.app;

    if (sessionToken === null) {
      const { authResult } = state.login;

      if (authResult && authResult.session_token !== "") {
        const authorizationToken = `Bearer ${authResult.session_token}`;
        headers.Authorization = authorizationToken;
      }
    } else {
      const authorizationToken = `Bearer ${sessionToken}`;
      headers.Authorization = authorizationToken;
    }

    if (userId === null) {
      const { authResult } = state.login;

      if (authResult) {
        headers[X_SXP_USER_ID] = authResult.user_id;
      }
    } else {
      headers[X_SXP_USER_ID] = userId;
    }

    if (constructionId !== 0) {
      headers[X_SXP_CONSTRUCTION_ID] = constructionId;
    }

    headers[X_SXP_LANGUAGE] = lang;
    const xSxpOptions = JSON.stringify({ device: "pc" });
    headers[X_SXP_OPTIONS] = xSxpOptions;

    const params = {
      method,
      mode: "cors",
      cache: "no-cache",
      headers,
    };

    if (action.body) {
      params.body = action.body;
    }

    const callbacks = action.callbacks || {};

    if (callbacks.begin) {
      next(callbacks.begin());

      if (callbacks.begin().type === constants.LOGIN_BEGIN_AUTH) {
        delete params.headers["X-SXP-User-Id"];
        delete params.headers["Authorization"];
      }
    }

    let contentType = "";
    let fileName = "";

    fetch(action.endpoint, params)
      .then((response) => {
        if (response.status === 401 || response.status === 503) {
          next(actions.login.shouldLogout());
        }

        if (response.ok) {
          contentType = response.headers.get("content-type");
          const contentDisposition = response.headers.get("content-disposition");

          if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
            fileName = getFilenameFromContentDisposition(contentDisposition);
          }

          return convert(response, contentType);
        }

        throw response;
      })
      .then((response) => {
        if (callbacks.success) {
          if (isBlob(contentType) && fileName !== "") {
            next(callbacks.success(response, fileName));
          } else {
            next(callbacks.success(response));
          }
        }
      })
      .catch((response) => {
        if (!response.json) {
          if (callbacks.error) {
            return next(callbacks.error(response));
          } else {
            console.error(response);
          }

          return;
        }

        const statusCode = response.status;

        response.json().then((response) => {
          if (callbacks.error) {
            return next(callbacks.error({ ...response, statusCode }));
          }

          console.error(response);
        });
      });
  }
};

export default api;
