import React, { FC } from "react";
import { useTranslation } from "react-i18next";

type PlanListHeaderProps = {
  headerDateLabel: string;
  totalWorkers: number;
  maxOvertime: number;
};

export const PlanListHeader: FC<PlanListHeaderProps> = ({ headerDateLabel, totalWorkers, maxOvertime }) => {
  const { t } = useTranslation();

  return (
    <div className="description">
      {t("schedule_plan_header", { date: headerDateLabel, totalWorkers })}
      <span className="ml-20" />
      {t("schedule_max_overtime", { maxOvertime: Number(maxOvertime || 0) })}
    </div>
  );
};
