import * as constants from "../constants/index";
import environments from "../environments";
import storageManager from "../lib/storageManager";

export const setValidRequired = (valid) => {
  return {
    type: constants.QRCODE_SET_VALID_REQUIRED,
    payload: valid,
  };
};

export const changeArea = (items) => ({
  type: constants.QRCODE_CHANGE_AREA,
  payload: items ? items.map((item) => item.value) : [],
});

export const changeDevice = (items) => ({
  type: constants.QRCODE_CHANGE_DEVICE,
  payload: items ? items.map((item) => item.value) : [],
});

export const changeCategory = (item) => ({
  type: constants.QRCODE_CHANGE_CATEGORY,
  payload: item !== null ? item.value : 0,
});

export const changeFacilityManagement = (items) => ({
  type: constants.QRCODE_CHANGE_FACILITY_MANAGEMENT,
  payload: items ? items.map((item) => item.value) : [],
});

export const changeConstructionManagement = (items) => ({
  type: constants.QRCODE_CHANGE_CONSTRUCTION_MANAGEMENT,
  payload: items ? items.map((item) => item.value) : [],
});

export const changePrimaryCharge = (items) => ({
  type: constants.QRCODE_CHANGE_PRIMARY_CHARGE,
  payload: items ? items.map((item) => item.value) : [],
});

export const changeOther = (items) => ({
  type: constants.QRCODE_CHANGE_OTHER,
  payload: items ? items.map((item) => item.value) : [],
});

export const changeCompany = (item) => ({
  type: constants.QRCODE_CHANGE_COMPANY,
  payload: item !== null ? item.value : 0,
});

export const changeUser = (userId) => ({
  type: constants.QRCODE_CHANGE_USER,
  payload: userId,
});

export const changeGroup = (groupId) => ({
  type: constants.QRCODE_CHANGE_GROUP,
  payload: groupId,
});

export const clearSearch = () => ({
  type: constants.QRCODE_CLEAR_SEARCH,
});

export const revertLocalCondition = () => ({
  type: constants.QRCODE_REVERT_CONDITION,
});

const downloadFile = (data, fileName) => {
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(data, fileName);
  } else if (window.URL && window.URL.createObjectURL) {
    const uri = URL.createObjectURL(data);
    // window.open(uri,'_blank');
    const a = document.createElement("a");
    a.href = uri;
    a.download = fileName;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(uri);
    document.body.removeChild(a);
  }
};

export const downloadZip = (params, callback) => {
  let endpoint = `${environments.API_BASE_URI}/qrcode/files`;
  const query = new URLSearchParams();

  const {
    category_id,
    area_id,
    device_id,
    facility_management_id,
    construction_management_id,
    primary_charge_id,
    other_id,
    user_id,
    group_id,
  } = params;

  if (category_id) {
    query.set("category_id", category_id);
  }

  if (area_id) {
    query.set("area_id", area_id);
  }

  if (device_id) {
    query.set("device_id", device_id);
  }

  if (facility_management_id) {
    query.set("facility_management_id", facility_management_id);
  }

  if (construction_management_id) {
    query.set("construction_management_id", construction_management_id);
  }

  if (primary_charge_id) {
    query.set("primary_charge_id", primary_charge_id);
  }

  if (other_id) {
    query.set("other_id", other_id);
  }

  if (user_id && user_id > 0) {
    query.set("user_id", user_id);
  }

  if (group_id && group_id > 0) {
    query.set("group_id", group_id);
  }

  endpoint = `${endpoint}?${query.toString()}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      success: (response, filename) => {
        downloadFile(response, filename);
        if (callback) {
          callback(response);
        }

        return {
          type: constants.DOWNLOAD_ZIP,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const search = (params, callback) => {
  let endpoint = `${environments.API_BASE_URI}/qrcodes`;
  const query = new URLSearchParams();
  let infinite = false;

  if (params && params.areaIds && params.areaIds.length > 0) {
    params.areaIds.forEach((param) => query.append("area_id", param));
  }

  if (params && params.deviceIds && params.deviceIds.length > 0) {
    params.deviceIds.forEach((param) => query.append("device_id", param));
  }

  if (params && params.categoryId && params.categoryId > 0) {
    query.set("category_id", params.categoryId);
  }

  if (params && params.facilityManagementIds && params.facilityManagementIds.length > 0) {
    params.facilityManagementIds.forEach((param) => query.append("facility_management_id", param));
  }

  if (params && params.constructionManagementIds && params.constructionManagementIds.length > 0) {
    params.constructionManagementIds.forEach((param) => query.append("construction_management_id", param));
  }

  if (params && params.primaryChargeIds && params.primaryChargeIds.length > 0) {
    params.primaryChargeIds.forEach((param) => query.append("primary_charge_id", param));
  }

  if (params && params.otherIds && params.otherIds.length > 0) {
    params.otherIds.forEach((param) => query.append("other_id", param));
  }

  if (params && params.companyId) {
    query.set("companyId", params.companyId);
  }

  if (params && params.userId && params.userId > 0) {
    query.set("user_id", params.userId);
  }

  if (params && params.groupId && params.groupId > 0) {
    query.set("group_id", params.groupId);
  }

  if (params && params.limit) {
    query.set("limit", params.limit);
  }

  if (params && params.start) {
    query.set("start", params.start);
  }

  if (params && params.start && params.start > 1) {
    infinite = true;
  }

  storageManager.setConstructionItem("qrSearchParams", JSON.stringify(params));
  endpoint = `${endpoint}?${query.toString()}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: infinite ? constants.QRCODE_BEGIN_SEARCH_INFINITE : constants.QRCODE_BEGIN_SEARCH,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: infinite ? constants.QRCODE_END_SEARCH_INFINITE : constants.QRCODE_END_SEARCH,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};
