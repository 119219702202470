import { ClassValue } from "clsx";
import { ComponentProps, FC } from "react";

import { cn } from "@/lib/classNames";

export type FormLabelProps = Omit<ComponentProps<"label">, "className"> & {
  size?: "normal";
  className?: ClassValue;
};

export const FormLabel: FC<FormLabelProps> = ({ size = "normal", className, ...restProps }) => {
  return <label className={cn("text-right mr-[16px]", size === "normal" && "w-[110px]", className)} {...restProps} />;
};
