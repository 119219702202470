import actions from "../actions";
import { validateCondition } from "../lib/validate";

const validateSchedule = (store) => (next) => (action) => {
  next(action);
  const state = store.getState();
  if (!(state.app.menu === "schedule_eneos_kawasaki" && state.construction.masters)) {
    return;
  }

  const {
    areaIds,
    deviceIds,
    categoryId,
    facilityManagementIds,
    constructionManagementIds,
    primaryChargeIds,
    validated,
  } = state.schedule_eneos_kawasaki;

  if (validated) {
    return;
  }

  const { areas, devices, categories, facility_managements, construction_managements, primary_charges } =
    state.construction.masters;

  const {
    changeArea,
    changeDevice,
    changeCategory,
    changeFacilityManagement,
    changeConstructionManagement,
    changePrimaryCharge,
    setValidated,
    setValidRequired,
  } = actions.schedule_eneos_kawasaki;

  const validatedAreaId = validateCondition(areas, areaIds);
  if (validatedAreaId.length !== areaIds.length) {
    next(changeArea(validatedAreaId));
  }
  const validatedDeviceIds = validateCondition(devices, deviceIds);
  if (validatedDeviceIds.length !== deviceIds.length) {
    next(changeDevice(validatedDeviceIds));
  }

  const validatedCategoryIds = validateCondition(categories, categoryId);
  if (categoryId && !validatedCategoryIds.length) {
    next(changeCategory(null));
  }

  const validatedFacilityManagementIds = validateCondition(facility_managements, facilityManagementIds);
  if (validatedFacilityManagementIds.length !== facilityManagementIds.length) {
    next(changeFacilityManagement(validatedFacilityManagementIds));
  }
  const validatedConstructionManagements = validateCondition(construction_managements, constructionManagementIds);
  if (validatedConstructionManagements.length !== constructionManagementIds.length) {
    next(changeConstructionManagement(validatedConstructionManagements));
  }
  const validatedPrimaryChargeIds = validateCondition(primary_charges, primaryChargeIds);
  if (validatedPrimaryChargeIds.length !== primaryChargeIds.length) {
    next(changePrimaryCharge(validatedPrimaryChargeIds));
  }

  const valid = Boolean(validateCondition(categories, categoryId).length);
  next(setValidRequired(valid));
  if (!valid && categories.length > 0) {
    const validCategoryId = categories[0].category_id;
    const validCategory = { value: validCategoryId };
    next(changeCategory(validCategory));
  }

  next(setValidated(true));

  return;
};

export default validateSchedule;
