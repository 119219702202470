import { connect } from "react-redux";

import actions from "../../actions";
import ApprovalGridRow from "../../components/list/ApprovalGridRow";

const mapStateToProps = (state) => {
  return {
    masters: state.construction.masters,
    switcherStatus: state.approval.switcherStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeTaskStatus: (item_id, task, status) => {
      dispatch(actions.common.changeTaskStatus(item_id, task, status));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalGridRow);
