import PropTypes from "prop-types";
import React, { Component } from "react";
import Draggable from "react-draggable";

const Modal = ({ className, title, loading, children, closeHandler, onDrag }) => {
  return (
    <div className="modal">
      <Draggable handle=".modal-header" onDrag={onDrag}>
        <div className="modal-drag-container">
          <div className="modal-dialog">
            <div className={`modal-content ${loading ? "loading" : ""} ${className ? className : ""}`}>
              <div className="modal-header">
                <h3 className="modal-ttl">{title}</h3>
                <div className="btn-close" onClick={() => closeHandler()}>
                  <span className="icon icon-close"></span>
                </div>
              </div>
              {children}
            </div>
          </div>
        </div>
      </Draggable>
    </div>
  );
};

Modal.defaultProps = {
  loading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- 暫定処置でdisableしている
  onDrag: () => {},
};

Modal.propTypes = {
  onDrag: PropTypes.func,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.node),
  loading: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
};

export default Modal;
