import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import actions from "@/actions";
import Modal from "@/components/Modal";
import { SubmitButton } from "@/components/common/SubmitButton";
import { ProcessSegmentLabel } from "@/models/matrix";
import { RootState } from "@/reducers/types";

type Props = {
  categoryId: number;
  primaryChargeId: number;
  numberOfSegmentLabels: number;
  segmentLabel?: ProcessSegmentLabel;
  onUpdate: (_: ProcessSegmentLabel) => void;
  onClose: () => void;
};

export const EditSegmentLabelModal: React.FC<Props> = ({
  categoryId,
  primaryChargeId,
  numberOfSegmentLabels,
  segmentLabel,
  onUpdate,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { categories } = useSelector((state: RootState) => state.construction.masters);
  const categoryLabel = useMemo(
    () => categories?.find((v) => v.category_id === categoryId)?.category_name ?? "",
    [categoryId, categories]
  );

  const [label, setLabel] = useState<string>(segmentLabel?.segment_label_name);
  const [labelError, setLabelError] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (!label) {
      setLabelError(t("enter_segment_label_name"));

      return;
    }
    if (100 < label.length) {
      setLabelError(t("segment_group_name") + t("is_too_long"));

      return;
    }
    setLoading(true);
    setLabelError(null);

    dispatch(
      actions.matrix.updateSegmentLabel(
        {
          category_id: categoryId,
          primary_charge_id: primaryChargeId,
          sort: segmentLabel?.sort ?? numberOfSegmentLabels + 1,
          segment_label_name: label,
        },
        (res) => {
          setLoading(false);
          onUpdate(res);
          onClose();
        },
        () => setLoading(false)
      )
    );
  };

  return (
    <Modal title={segmentLabel ? t("edit_segment_group") : t("add_segment_group")} closeHandler={onClose}>
      <div className="modal-body">
        <p className="form-text">{`${t("machines_category")}：${categoryLabel}`}</p>
        <div className="form-row">
          <div className="form-group txt-center w-350">
            <span className="form-label">{t("segment_group_name")}</span>
            <input
              type="text"
              className="form-control w-220"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
          </div>
          {labelError && (
            <div className="form-error w-[325px]">
              <p className="form-error-message w-210">{labelError}</p>
            </div>
          )}
        </div>
      </div>
      <div className={"modal-footer"}>
        <button data-test-id="button-column-editor-cancel" type="button" className="btn btn-gray" onClick={onClose}>
          {t("cancel")}
        </button>
        <SubmitButton onClick={handleSubmit} loading={loading} />
      </div>
    </Modal>
  );
};
