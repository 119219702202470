import { ClassValue } from "clsx";
import { ComponentProps, FC } from "react";

import { cn } from "@/lib/classNames";

export type TdProps = Omit<ComponentProps<"td">, "className"> & {
  className?: ClassValue;
};

export const Td: FC<TdProps> = ({ children, className, ...restProps }) => {
  return (
    <td className={cn("border border-collapse border-[#888] bg-[#fff] px-[12px] py-[4px]", className)} {...restProps}>
      {children}
    </td>
  );
};
