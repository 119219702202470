import { useCallback, useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePopper } from "react-popper";

const PRESET_COLORS = [
  "#b02318",
  "#eb3323",
  "#f6c243",
  "#feff54",
  "#9fcf63",
  "#4eae5b",
  "#4eadea",
  "#2d6eba",
  "#08215b",
  "#68349a",
];

type Props<T extends FieldValues> = UseControllerProps<T> & {
  defaultColor: string;
};

export const ColorPickerFormController = <T extends FieldValues>({ name, control, defaultColor }: Props<T>) => {
  const { t } = useTranslation();

  const [colorText, setColorText] = useState<string>(defaultColor);

  const [colorRef, setColorRef] = useState(null);
  const [colorPickerRef, setColorPickerRef] = useState(null);
  const { styles, attributes } = usePopper(colorRef, colorPickerRef, {
    placement: "bottom-start",
  });

  const [openColorPicker, setOpenColorPicker] = useState(false);
  const handleClick = useCallback(
    (event) => {
      const paths = event.composedPath();
      if (openColorPicker && !paths.some((path: HTMLElement) => ["color-picker", "color-box"].includes(path.id))) {
        setOpenColorPicker(false);
      }
    },
    [openColorPicker]
  );
  useEffect(() => {
    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  const isValidColorText = useCallback((value: string) => {
    return value.match("#[a-fA-F0-9]{6}");
  }, []);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <div className="flex-col">
          <div className="flex flex-row">
            <div
              id="color-box"
              className="h-[28px] w-[28px] border-y border-l border-[#888] cursor-pointer"
              style={{ backgroundColor: value }}
              ref={setColorRef}
              onClick={() => setOpenColorPicker(!openColorPicker)}
            />
            <input
              type="text"
              className="w-[100px] px-[8px] py-[4px] border border-[#888]"
              value={colorText ?? value}
              maxLength={7}
              onChange={(e) => {
                const v = `${e.target.value.match("^#") ? "" : "#"}${e.target.value}`;
                setColorText(v);
                if (isValidColorText(v)) {
                  onChange(v);
                }
              }}
              onBlur={(e) => {
                if (!isValidColorText(e.target.value)) {
                  setColorText(value);
                }
              }}
            />
          </div>
          <br />
          <p>{t("color_picker_description")}</p>
          <div className="flex flex-row space-x-2">
            {PRESET_COLORS.map((c) => (
              <div
                key={`preset-color-${c}`}
                className="h-[20px] w-[20px]"
                style={{ backgroundColor: c }}
                onClick={() => {
                  onChange(c);
                  setColorText(c);
                }}
              />
            ))}
          </div>
          {openColorPicker && (
            <div
              id="color-picker"
              ref={setColorPickerRef}
              className="z-10"
              style={{ ...styles.popper }}
              {...attributes.popper}
            >
              <SketchPicker
                color={value}
                disableAlpha={true}
                presetColors={[]}
                onChange={(v) => {
                  onChange(v.hex);
                  setColorText(v.hex);
                }}
              />
            </div>
          )}
        </div>
      )}
    />
  );
};
