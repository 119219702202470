import { connect } from "react-redux";

import actions from "../../actions";
import Checkpoint from "../../components/master/Checkpoint";

const mapStateToProps = (state) => {
  let categories = [];

  if (state.construction.masters) {
    categories = state.construction.masters.categories;
  }

  return {
    masterItems: state.checkpoint.masterItems,
    category_id: state.checkpoint.category_id,
    text: state.checkpoint.text,
    items: state.checkpoint.items,
    current: state.checkpoint.current,
    fetching: state.checkpoint.fetching,
    isError: state.checkpoint.isError,
    categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSearchId: (category_id) => dispatch(actions.checkpoint.changeSearchId(category_id)),
    changeSearchText: (text) => dispatch(actions.checkpoint.changeSearchText(text)),
    fetchItems: (callback) => dispatch(actions.checkpoint.fetchItems(callback)),
    searchItems: (category_id, text, callback) => dispatch(actions.checkpoint.searchItems(category_id, text, callback)),
    fetchItem: (id, callback, failedCallback) => dispatch(actions.checkpoint.fetchItem(id, callback, failedCallback)),
    createItem: (data, handleSuccess, handleCancel) =>
      dispatch(actions.checkpoint.createItem(data, handleSuccess, handleCancel)),
    updateItem: (data, handleSuccess, handleCancel, failedCallback) =>
      dispatch(actions.checkpoint.updateItem(data, handleSuccess, handleCancel, failedCallback)),
    deleteItem: (id, name) => dispatch(actions.checkpoint.deleteItem(id, name)),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkpoint);
