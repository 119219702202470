import moment from "moment";

import * as constants from "../constants/index";
import restoreState from "../lib/restoreState";
import storageManager from "../lib/storageManager";

const getInitialSearchConditions = (onlyLocal = false) => {
  const urlState = restoreState();
  let userId = 0;
  let companyId = 0;
  let categoryId = 0;
  let fileName = "";
  let statusId = -1;
  let fileType = 0;
  let dateFrom = "";
  let dateTo = "";

  if (storageManager.getConstructionItem("importSearchParams") !== null) {
    const params = JSON.parse(storageManager.getConstructionItem("importSearchParams"));
    userId = params.userId;
    companyId = params.companyId;
    categoryId = params.categoryId;
    fileName = params.fileName ? decodeURIComponent(params.fileName) : "";
    statusId = params.statusId;
    fileType = params.fileType;
    dateFrom = params.import_start_date;
    dateTo = params.import_end_date;
  }

  if (!onlyLocal && urlState && urlState.menu === "import") {
    userId = urlState.user_id ? Number(urlState.user_id) : 0;
    categoryId = urlState.category_id ? Number(urlState.category_id) : 0;
    fileName = urlState.file_name ? decodeURIComponent(urlState.file_name) : "";
    statusId = urlState.status ? Number(urlState.status) : -1;
    fileType = urlState.format ? Number(urlState.format) : 0;
    dateFrom = urlState.import_date_from ? urlState.import_date_from : "";
    dateTo = urlState.import_date_to ? urlState.import_date_to : "";
  }

  const statusIdMaster = [0, 1, 2, 3];
  if (statusIdMaster.indexOf(statusId) === -1) {
    statusId = -1;
  }

  const fileTypeMaster = [1, 2, 3];
  if (fileTypeMaster.indexOf(fileType) === -1) {
    fileType = 0;
  }

  if (dateFrom) {
    const startDate = moment(dateFrom, "YYYY/MM/DD").format("YYYY-MM-DD").toString();
    if (startDate === "Invalid date") {
      dateFrom = "";
    } else if (dateFrom !== startDate) {
      dateFrom = startDate;
    }
  }

  if (dateTo) {
    const endDate = moment(dateTo, "YYYY/MM/DD").format("YYYY-MM-DD").toString();
    if (endDate === "Invalid date") {
      dateTo = "";
    } else if (dateFrom !== endDate) {
      dateTo = endDate;
    }
  }

  return {
    userId,
    companyId,
    categoryId,
    fileName,
    statusId,
    fileType,
    import_start_date: dateFrom,
    import_end_date: dateTo,
    validated: false,
  };
};

const dataimport = (
  state = {
    userId: 0,
    companyId: 0,
    categoryId: 0,
    fileName: "",
    statusId: -1,
    fileType: 0,
    import_start_date: "",
    import_end_date: "",
    impFileType: 0,
    masterItems: [],
    items: [],
    current: null,
    dataimports: [],
    categories: [],
    fetching: false,
    isError: false,
    downloading: false,
  },
  action
) => {
  switch (action.type) {
    case constants.APP_CONTENTS_READY: {
      return {
        ...state,
        ...getInitialSearchConditions(),
      };
    }
    case constants.IMPORT_CHANGE_CATEGORY: {
      return {
        ...state,
        categoryId: action.payload,
      };
    }
    case constants.IMPORT_CHANGE_FILENAME: {
      return {
        ...state,
        fileName: action.payload,
      };
    }
    case constants.IMPORT_CHANGE_STATUS: {
      return {
        ...state,
        statusId: action.payload,
      };
    }
    case constants.IMPORT_CHANGE_DATE_FROM: {
      return {
        ...state,
        import_start_date: action.payload,
      };
    }
    case constants.IMPORT_CHANGE_DATE_TO: {
      return {
        ...state,
        import_end_date: action.payload,
      };
    }
    case constants.IMPORT_CHANGE_COMPANY: {
      return {
        ...state,
        companyId: action.payload,
      };
    }
    case constants.IMPORT_CHANGE_USER: {
      return {
        ...state,
        userId: action.payload,
      };
    }
    case constants.IMPORT_CHANGE_FILETYPE: {
      return {
        ...state,
        fileType: action.payload,
      };
    }
    case constants.IMPORT_IMPORT_FILETYPE: {
      return {
        ...state,
        impFileType: action.payload,
      };
    }
    case constants.IMPORT_RESET_SEARCH: {
      return {
        ...state,
        companyId: 0,
        userId: 0,
        categoryId: 0,
        statusId: -1,
        fileName: "",
        fileType: 0,
        import_start_date: "",
        import_end_date: "",
        items: [],
        fetching: false,
        isError: false,
      };
    }

    case constants.IMPORT_BEGIN_FETCH_ITEMS: {
      return {
        ...state,
        fetching: true,
        isError: false,
      };
    }
    case constants.IMPORT_END_FETCH_ITEMS: {
      return {
        ...state,
        masterItems: action.payload,
        fetching: false,
      };
    }
    case constants.IMPORT_CHANGE_SEARCH_TEXT: {
      return {
        ...state,
        text: action.payload,
      };
    }
    case constants.IMPORT_BEGIN_SEARCH_ITEMS: {
      return {
        ...state,
        fetching: true,
        isError: false,
      };
    }
    case constants.IMPORT_END_SEARCH_ITEMS: {
      return {
        ...state,
        items: action.payload.list,
        fetching: false,
      };
    }
    case constants.IMPORT_END_FETCH_ITEM: {
      return {
        ...state,
        current: action.payload,
      };
    }
    case constants.IMPORT_END_UPLOAD_FILE: {
      return {
        ...state,
        upload: action.payload,
      };
    }
    case constants.IMPORT_SET_VALIDATED: {
      return {
        ...state,
        validated: Boolean(action.payload),
      };
    }
    case constants.IMPORT_REVERT_CONDITION: {
      return {
        ...state,
        ...getInitialSearchConditions(true),
      };
    }
    case constants.IMPORT_TOGGLE_DOWNLOADING: {
      return {
        ...state,
        downloading: action.payload,
      };
    }
    case constants.APP_SHOW_ERROR: {
      if (action.errorFrom === "dataimport") {
        return {
          ...state,
          fetching: false,
          isError: true,
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export default dataimport;
