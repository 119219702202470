import { TimeStamp } from "./timestamp";

export type File = {
  file_id: number;
  file_name: string;
  file_size: number;
  timestamp: TimeStamp;
};

export type UploadFile = {
  file_name: string;
  mode: UploadFileModeType;
  file_id?: number;
  temp_file_id?: number;
  timestamp?: TimeStamp;
};

export const UploadFileMode = {
  TEMP: -1,
  UPLOADED: 0,
  CREATE: 1,
  DELETE: 2,
} as const;

export type UploadFileModeType = (typeof UploadFileMode)[keyof typeof UploadFileMode];
