import React from "react";

import { HistoryColumnType, ScheduleItemChiba } from "@/models/scheduleChiba";

type HistoryButtonProps = {
  schedule: ScheduleItemChiba;
  targetColumn: HistoryColumnType;
  onOpenHistory: (scheduleId: number, targetColumn: HistoryColumnType) => void;
  additionalStyles?: React.CSSProperties;
};

export const HistoryButton = ({ schedule, targetColumn, onOpenHistory, additionalStyles }: HistoryButtonProps) => {
  if (!(schedule.history_alert ?? []).includes(targetColumn)) return <></>;

  return (
    <button
      className="grid-icon cursor-pointer grid-icon-edit-history icon-history icon-edit-history hover:opacity-50"
      style={additionalStyles}
      onClick={(e) => {
        e.stopPropagation();
        onOpenHistory(schedule.schedule_id, targetColumn);
      }}
    />
  );
};
