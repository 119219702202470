import React from "react";
import { useTranslation } from "react-i18next";

import RefreshIcon from "../../../icons/RefreshIcon";

type Props = {
  isLoading?: boolean;
  onRefreshPress: () => void;
};

const Title: React.FC<Props> = ({ isLoading, onRefreshPress }) => {
  const { t } = useTranslation();

  return (
    <div id="title-area">
      <span id="title-text">{t("progress_notifications")}</span>
      <div id="refresh-icon" className={isLoading ? "animation-rotate-progress" : undefined} onClick={onRefreshPress}>
        <RefreshIcon />
      </div>
    </div>
  );
};

export default Title;
