import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { isValidRole } from "../../lib/roleChecker";
import clsx from "clsx";
import { HistoryColumn, ScheduleItemChiba } from "@/models/scheduleChiba";
import { HistoryButton } from "@/components/list/ScheduleChiba/components/HistoryButton";

class ScheduleRightRowChiba extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editNote1: null,
      editNote2: null,
      isExcluse: false,
      loading: false,
    };
  }

  beginEditNote1(id) {
    if (!this.state.isExcluse) {
      this.setState({
        ...this.state,
        editNote1: id,
        isExcluse: true,
      });
    }
  }

  beginEditNote2(id) {
    if (!this.state.isExcluse) {
      this.setState({
        ...this.state,
        editNote2: id,
        isExcluse: true,
      });
    }
  }

  endEditNote1(comment, row: ScheduleItemChiba) {
    if ((!comment && !row.note1) || comment === row.note1) {
      this.setState({ editNote1: null, isExcluse: false });
      return;
    }

    const { showAlert, hideAlert, t } = this.props;

    const data = {
      schedule_id: row.schedule_id,
      target: "note1",
      value: comment,
      timestamp: row.timestamp,
    };

    if (comment.length > 500) {
      showAlert(t("confirmation"), [t("request") + "：" + t("mainte_is_too_long_500")], hideAlert);

      return;
    }

    this.props.updateItem(
      data,
      () => {
        this.setState({ editNote1: null, isExcluse: false });
      },
      () => {
        this.setState({ editNote1: null });
      }
    );
  }

  endEditNote2(comment, row: ScheduleItemChiba) {
    if ((!comment && !row.note2) || comment === row.note2) {
      this.setState({ editNote2: null, isExcluse: false });
      return;
    }

    const { showAlert, hideAlert, t } = this.props;

    const data = {
      schedule_id: row.schedule_id,
      target: "note2",
      value: comment,
      timestamp: row.timestamp,
    };

    if (comment.length > 500) {
      showAlert(t("confirmation"), [t("permission") + "：" + t("remarks_is_too_long_500")], hideAlert);

      return;
    }

    this.props.updateItem(
      data,
      () => {
        this.setState({ editNote2: null, isExcluse: false });
      },
      () => {
        this.setState({ editNote2: null });
      }
    );
  }

  focusNote = _.debounce(() => {
    this.commentTextarea?.focus();
    this.note2Textarea?.focus();
  }, 100);

  componentDidUpdate() {
    this.focusNote();
  }

  hasHistory(schedule: ScheduleItemChiba, target: string): "cell-red" | "" {
    return (schedule?.history_alert ?? []).includes(target) ? "cell-red" : "";
  }

  render() {
    const { index, schedule, showScheduleEditor, handleOnMouse, omitText, t, onOpenHistory } = this.props;

    return (
      <tr key={index} className="schedule-chiba">
        {/* 申請: 工事担当部署担当者 */}
        {isValidRole(schedule.roles, 11) ? (
          <td
            className={clsx(
              "col11 grid-cell-clickable txt-center schedule-right-body",
              this.hasHistory(schedule, HistoryColumn.FIELD1)
            )}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field1_name, 10)}
            onClick={() =>
              showScheduleEditor(
                "field1",
                t("application") + "：" + t("pic_maintenance"),
                schedule.process_text,
                schedule.schedule_id,
                schedule.field1,
                schedule.timestamp
              )
            }
          >
            <div
              className={clsx(
                "grid-cell-wrap",
                this.hasHistory(schedule, HistoryColumn.FIELD1) ||
                  (schedule.field6 == 2 || schedule.field1_name == "" || schedule.field1_name == null
                    ? "color-gray"
                    : "")
              )}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field1_name, 10)}
                  <EditIcon />
                </div>
              </div>
            </div>
            <HistoryButton
              schedule={schedule}
              targetColumn={HistoryColumn.FIELD1}
              onOpenHistory={(id, col) => onOpenHistory(id, col)}
            />
          </td>
        ) : (
          <td
            className={clsx("col11 txt-center schedule-right-body", this.hasHistory(schedule, HistoryColumn.FIELD1))}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field1_name, 10)}
          >
            <div
              className={clsx(
                "grid-cell-wrap not-editable",
                this.hasHistory(schedule, HistoryColumn.FIELD1) ||
                  (schedule.field6 == 2 || schedule.field1_name == "" || schedule.field1_name == null
                    ? "color-gray"
                    : "")
              )}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field1_name, 10)}</div>
              </div>
            </div>
            <HistoryButton
              schedule={schedule}
              targetColumn={HistoryColumn.FIELD1}
              onOpenHistory={(id, col) => onOpenHistory(id, col)}
            />
          </td>
        )}
        {/* 申請：特記事項(工事担当) */}
        {isValidRole(schedule.roles, 12) ? (
          schedule.schedule_id === this.state.editNote1 ? (
            <td className={`no-hover col18 grid-cell-clickable txt-center schedule-right-body`}>
              <div className={`grid-cell-wrap`}>
                <div className="grid-cell-con">
                  <div className="grid-cell-inner schedule-note txt-left">
                    <textarea
                      className="grid-textarea txt-left p-5"
                      ref={(node) => (this.commentTextarea = node)}
                      onBlur={(e) => this.endEditNote1(e.target.value, schedule)}
                      defaultValue={schedule.note1}
                    />
                  </div>
                </div>
              </div>
            </td>
          ) : (
            <td
              className={clsx(
                "col18 grid-cell-clickable txt-center schedule-right-body",
                this.hasHistory(schedule, HistoryColumn.NOTE1)
              )}
              onClick={() => this.beginEditNote1(schedule.schedule_id)}
              data-tip
              data-for="schedule-full-text"
              onMouseEnter={() => handleOnMouse(schedule.note1, 34)}
            >
              <div
                className={clsx(
                  "grid-cell-wrap",
                  this.hasHistory(schedule, HistoryColumn.NOTE1) ||
                    (schedule.field6 == 2 || schedule.field1_name == "" || schedule.field1_name == null
                      ? "color-gray"
                      : "")
                )}
              >
                <div className="grid-cell-con">
                  <div className="grid-cell-inner schedule-note txt-left p-5">
                    {omitText(schedule.note1, 34)}
                    <EditIcon />
                  </div>
                </div>
              </div>
              <HistoryButton
                schedule={schedule}
                targetColumn={HistoryColumn.NOTE1}
                onOpenHistory={(id, col) => onOpenHistory(id, col)}
              />
            </td>
          )
        ) : (
          <td
            className={clsx("col18 txt-center schedule-right-body", this.hasHistory(schedule, HistoryColumn.NOTE1))}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.note1, 34)}
          >
            <div
              className={clsx(
                "grid-cell-wrap not-editable",
                this.hasHistory(schedule, HistoryColumn.NOTE1) ||
                  (schedule.field6 == 2 || schedule.field1_name == "" || schedule.field1_name == null
                    ? "color-gray"
                    : "")
              )}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner schedule-note txt-left p-5">{omitText(schedule.note1, 34)}</div>
              </div>
            </div>
            <HistoryButton
              schedule={schedule}
              targetColumn={HistoryColumn.NOTE1}
              onOpenHistory={(id, col) => onOpenHistory(id, col)}
            />
          </td>
        )}
        {/* 申請：申請 */}
        {isValidRole(schedule.roles, 13) ? (
          <td
            className={clsx(
              "col11 grid-cell-clickable txt-center schedule-right-body",
              this.hasHistory(schedule, HistoryColumn.REQUEST_USER_ID)
            )}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.request_user_name, 10)}
            onClick={() =>
              showScheduleEditor(
                "request",
                t("request") + "：" + t("request"),
                schedule.process_text,
                schedule.schedule_id,
                schedule.request_user_id,
                schedule.timestamp
              )
            }
          >
            <div
              className={clsx(
                "grid-cell-wrap",
                this.hasHistory(schedule, HistoryColumn.REQUEST_USER_ID) ||
                  (schedule.field6 == 2 || schedule.request_user_name == "" || schedule.request_user_name == null
                    ? "color-gray"
                    : "")
              )}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.request_user_name, 10)}
                  <EditIcon />
                </div>
              </div>
            </div>
            <HistoryButton
              schedule={schedule}
              targetColumn={HistoryColumn.REQUEST_USER_ID}
              onOpenHistory={(id, col) => onOpenHistory(id, col)}
            />
          </td>
        ) : (
          <td
            className={clsx(
              "col11 txt-center schedule-right-body",
              this.hasHistory(schedule, HistoryColumn.REQUEST_USER_ID)
            )}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.request_user_name, 10)}
          >
            <div
              className={clsx(
                "grid-cell-wrap not-editable",
                this.hasHistory(schedule, HistoryColumn.REQUEST_USER_ID) ||
                  (schedule.field6 == 2 || schedule.request_user_name == "" || schedule.request_user_name == null
                    ? "color-gray"
                    : "")
              )}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.request_user_name, 10)}</div>
              </div>
            </div>
            <HistoryButton
              schedule={schedule}
              targetColumn={HistoryColumn.REQUEST_USER_ID}
              onOpenHistory={(id, col) => onOpenHistory(id, col)}
            />
          </td>
        )}
        {/* ガス検結果 雰囲気 */}
        {isValidRole(schedule.roles, 14) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body`}
            onClick={() =>
              showScheduleEditor(
                "field2",
                `${t("operation_approval")}：${t("gas_leakage")}（${t("atmosphere")}）`,
                schedule.process_text,
                schedule.schedule_id,
                schedule.field2,
                schedule.timestamp
              )
            }
          >
            <div
              className={`grid-cell-wrap ${
                schedule.field6 == 2 || schedule.field2_name == "" || schedule.field2_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field2_name, 10)}
                  <EditIcon />
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td className={`col11 txt-center schedule-right-body`}>
            <div
              className={`grid-cell-wrap not-editable ${
                schedule.field6 == 2 || schedule.field2_name == "" || schedule.field2_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field2_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {/* ガス検結果 入槽 */}
        {isValidRole(schedule.roles, 15) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body`}
            onClick={() =>
              showScheduleEditor(
                "field12",
                `${t("operation_approval")}：${t("gas_leakage")}（${t("in_tank")}）`,
                schedule.process_text,
                schedule.schedule_id,
                schedule.field12,
                schedule.timestamp
              )
            }
          >
            <div
              className={`grid-cell-wrap ${
                schedule.field6 == 2 || schedule.field12_name == "" || schedule.field12_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field12_name, 10)}
                  <EditIcon />
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td className={`col11 txt-center schedule-right-body`}>
            <div
              className={`grid-cell-wrap not-editable ${
                schedule.field6 == 2 || schedule.field12_name == "" || schedule.field12_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field12_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {isValidRole(schedule.roles, 16) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body`}
            onClick={() =>
              showScheduleEditor(
                "field3",
                t("operation_approval") + "：" + t("isolation_depressure"),
                schedule.process_text,
                schedule.schedule_id,
                schedule.field3,
                schedule.timestamp
              )
            }
          >
            <div
              className={`grid-cell-wrap ${
                schedule.field6 == 2 || schedule.field3_name == "" || schedule.field3_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field3_name, 10)}
                  <EditIcon />
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td className={`col11 txt-center schedule-right-body`}>
            <div
              className={`grid-cell-wrap not-editable ${
                schedule.field6 == 2 || schedule.field3_name == "" || schedule.field3_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field3_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {isValidRole(schedule.roles, 17) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body`}
            onClick={() =>
              showScheduleEditor(
                "field4",
                t("operation_approval") + "：" + t("deenergization"),
                schedule.process_text,
                schedule.schedule_id,
                schedule.field4,
                schedule.timestamp
              )
            }
          >
            <div
              className={`grid-cell-wrap ${
                schedule.field6 == 2 || schedule.field4_name == "" || schedule.field4_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field4_name, 10)}
                  <EditIcon />
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td className={`col11 txt-center schedule-right-body`}>
            <div
              className={`grid-cell-wrap not-editable ${
                schedule.field6 == 2 || schedule.field4_name == "" || schedule.field4_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field4_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {isValidRole(schedule.roles, 18) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field5_name, 10)}
            onClick={() =>
              showScheduleEditor(
                "field5",
                t("operation_approval") + "：" + t("pic"),
                schedule.process_text,
                schedule.schedule_id,
                schedule.field5,
                schedule.timestamp
              )
            }
          >
            <div
              className={`grid-cell-wrap ${
                schedule.field6 == 2 || schedule.field5_name == "" || schedule.field5_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field5_name, 10)}
                  <EditIcon />
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className={`col11 txt-center schedule-right-body`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field5_name, 10)}
          >
            <div
              className={`grid-cell-wrap not-editable ${
                schedule.field6 == 2 || schedule.field5_name == "" || schedule.field5_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field5_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {isValidRole(schedule.roles, 19) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body`}
            onClick={() =>
              showScheduleEditor(
                "field6",
                t("operation_approval") + "：" + t("oil_approval"),
                schedule.process_text,
                schedule.schedule_id,
                schedule.field6,
                schedule.timestamp
              )
            }
          >
            <div
              className={`grid-cell-wrap ${
                schedule.field6 == 2 || schedule.field6_name == "" || schedule.field6_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field6_name, 10)}
                  <EditIcon />
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td className={`col11 txt-center schedule-right-body`}>
            <div
              className={`grid-cell-wrap not-editable ${
                schedule.field6 == 2 || schedule.field6_name == "" || schedule.field6_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field6_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {isValidRole(schedule.roles, 20) ? (
          schedule.schedule_id === this.state.editNote2 ? (
            <td className="no-hover col18 grid-cell-clickable txt-center schedule-right-body">
              <div className={`grid-cell-wrap `}>
                <div className="grid-cell-con">
                  <div className="grid-cell-inner schedule-note">
                    <textarea
                      className="grid-textarea txt-left txt-left p-5"
                      ref={(node) => (this.note2Textarea = node)}
                      onBlur={(e) => this.endEditNote2(e.target.value, schedule)}
                      defaultValue={schedule.note2}
                    />
                  </div>
                </div>
              </div>
            </td>
          ) : (
            <td
              className={`col18 grid-cell-clickable txt-center schedule-right-body`}
              onClick={() => this.beginEditNote2(schedule.schedule_id)}
              data-tip
              data-for="schedule-full-text"
              onMouseEnter={() => handleOnMouse(schedule.note2, 34)}
            >
              <div className={`grid-cell-wrap ${schedule.field6 == 2 || schedule.field6 != 1 ? "color-gray" : ""}`}>
                <div className="grid-cell-con">
                  <div className="grid-cell-inner schedule-note txt-left p-5">
                    {omitText(schedule.note2, 34)}
                    <EditIcon />
                  </div>
                </div>
              </div>
            </td>
          )
        ) : (
          <td
            className={`col18 txt-center schedule-right-body`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.note2, 34)}
          >
            <div
              className={`grid-cell-wrap not-editable ${
                schedule.field6 == 2 || schedule.field6 != 1 ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner schedule-note txt-left p-5">{omitText(schedule.note2, 34)}</div>
              </div>
            </div>
          </td>
        )}
        {isValidRole(schedule.roles, 21) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.approve_user_name, 10)}
            onClick={() =>
              showScheduleEditor(
                "approve",
                t("operation_approval") + "：" + t("approval"),
                schedule.process_text,
                schedule.schedule_id,
                schedule.approve_user_id,
                schedule.timestamp
              )
            }
          >
            <div
              className={`grid-cell-wrap ${
                schedule.field6 == 2 || schedule.approve_user_name == "" || schedule.approve_user_name == null
                  ? "color-gray"
                  : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.approve_user_name, 10)}
                  <EditIcon />
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className={`col11 txt-center schedule-right-body`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.approve_user_name, 10)}
          >
            <div
              className={`grid-cell-wrap not-editable ${
                schedule.field6 == 2 || schedule.approve_user_name == "" || schedule.approve_user_name == null
                  ? "color-gray"
                  : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.approve_user_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {/* 立会者サイン(工事開始前) 製油 */}
        {isValidRole(schedule.roles, 22) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body close`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field7_name, 10)}
            onClick={() =>
              showScheduleEditor(
                "field7",
                `${t("presence")}（${t("pre_start")}）：${t("operation")}`,
                schedule.process_text,
                schedule.schedule_id,
                schedule.field7,
                schedule.timestamp
              )
            }
          >
            <div
              className={`grid-cell-wrap ${
                schedule.field6 == 2 || schedule.field7_name == "" || schedule.field7_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field7_name, 10)}
                  <EditIcon />
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className={`col11 txt-center schedule-right-body close`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field7_name, 10)}
          >
            <div
              className={`grid-cell-wrap not-editable ${
                schedule.field6 == 2 || schedule.field7_name == "" || schedule.field7_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field7_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {/* 立会者サイン(工事開始前) 工事担当 */}
        {isValidRole(schedule.roles, 23) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body close`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field8_name, 10)}
            onClick={() =>
              showScheduleEditor(
                "field8",
                `${t("presence")}（${t("pre_start")}）：${t("maintenance")}`,
                schedule.process_text,
                schedule.schedule_id,
                schedule.field8,
                schedule.timestamp
              )
            }
          >
            <div
              className={`grid-cell-wrap ${
                schedule.field6 == 2 || schedule.field8_name == "" || schedule.field8_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field8_name, 10)}
                  <EditIcon />
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className={`col11 txt-center schedule-right-body close`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field8_name, 10)}
          >
            <div
              className={`grid-cell-wrap not-editable ${
                schedule.field6 == 2 || schedule.field8_name == "" || schedule.field8_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field8_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {/* 立会者サイン(工事開始前) 施工者 */}
        {isValidRole(schedule.roles, 24) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body close`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field9_name, 10)}
            onClick={() =>
              showScheduleEditor(
                "field9",
                `${t("presence")}（${t("pre_start")}）：${t("contractor")}`,
                schedule.process_text,
                schedule.schedule_id,
                schedule.field9,
                schedule.timestamp
              )
            }
          >
            <div
              className={`grid-cell-wrap ${
                schedule.field6 == 2 || schedule.field9_name == "" || schedule.field9_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field9_name, 10)}
                  <EditIcon />
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className={`col11 txt-center schedule-right-body close`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field9_name, 10)}
          >
            <div
              className={`grid-cell-wrap not-editable ${
                schedule.field6 == 2 || schedule.field9_name == "" || schedule.field9_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field9_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {/* 立会者サイン(工事開始後) 製油 */}
        {isValidRole(schedule.roles, 25) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body close`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field13_name, 10)}
            onClick={() =>
              showScheduleEditor(
                "field13",
                `${t("presence")}（${t("post_start")}）：${t("operation")}`,
                schedule.process_text,
                schedule.schedule_id,
                schedule.field13,
                schedule.timestamp
              )
            }
          >
            <div
              className={`grid-cell-wrap ${
                schedule.field6 == 2 || schedule.field13_name == "" || schedule.field13_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field13_name, 10)}
                  <EditIcon />
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className={`col11 txt-center schedule-right-body close`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field13_name, 10)}
          >
            <div
              className={`grid-cell-wrap not-editable ${
                schedule.field6 == 2 || schedule.field13_name == "" || schedule.field13_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field13_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {/* 立会者サイン(工事開始後) 工事担当 */}
        {isValidRole(schedule.roles, 26) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body close`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field14_name, 10)}
            onClick={() =>
              showScheduleEditor(
                "field14",
                `${t("presence")}（${t("post_start")}）：${t("maintenance")}`,
                schedule.process_text,
                schedule.schedule_id,
                schedule.field14,
                schedule.timestamp
              )
            }
          >
            <div
              className={`grid-cell-wrap ${
                schedule.field6 == 2 || schedule.field14_name == "" || schedule.field14_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field14_name, 10)}
                  <EditIcon />
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className={`col11 txt-center schedule-right-body close`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field14_name, 10)}
          >
            <div
              className={`grid-cell-wrap not-editable ${
                schedule.field6 == 2 || schedule.field14_name == "" || schedule.field14_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field14_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {/* 立会者サイン(工事開始後) 施工者 */}
        {isValidRole(schedule.roles, 27) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body close`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field15_name, 10)}
            onClick={() =>
              showScheduleEditor(
                "field15",
                `${t("presence")}（${t("post_start")}）：${t("contractor")}`,
                schedule.process_text,
                schedule.schedule_id,
                schedule.field15,
                schedule.timestamp
              )
            }
          >
            <div
              className={`grid-cell-wrap ${
                schedule.field6 == 2 || schedule.field15_name == "" || schedule.field15_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field15_name, 10)}
                  <EditIcon />
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className={`col11 txt-center schedule-right-body close`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field15_name, 10)}
          >
            <div
              className={`grid-cell-wrap not-editable ${
                schedule.field6 == 2 || schedule.field15_name == "" || schedule.field15_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field15_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {/* 工事完了確認 施工者 */}
        {isValidRole(schedule.roles, 28) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body close`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field10_name, 10)}
            onClick={() =>
              showScheduleEditor(
                "field10",
                t("completion") + "：" + t("contractor"),
                schedule.process_text,
                schedule.schedule_id,
                schedule.field10,
                schedule.timestamp
              )
            }
          >
            <div
              className={`grid-cell-wrap ${
                schedule.field6 == 2 || schedule.field10_name == "" || schedule.field10_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field10_name, 10)}
                  <EditIcon />
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className={`col11 txt-center schedule-right-body close`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field10_name, 10)}
          >
            <div
              className={`grid-cell-wrap not-editable ${
                schedule.field6 == 2 || schedule.field10_name == "" || schedule.field10_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field10_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
        {/* 工事完了確認 製油 */}
        {isValidRole(schedule.roles, 29) ? (
          <td
            className={`col11 grid-cell-clickable txt-center schedule-right-body close`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field11_name, 10)}
            onClick={() =>
              showScheduleEditor(
                "field11",
                t("completion") + "：" + t("operation"),
                schedule.process_text,
                schedule.schedule_id,
                schedule.field11,
                schedule.timestamp
              )
            }
          >
            <div
              className={`grid-cell-wrap ${
                schedule.field6 == 2 || schedule.field11_name == "" || schedule.field11_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">
                  {omitText(schedule.field11_name, 10)}
                  <EditIcon />
                </div>
              </div>
            </div>
          </td>
        ) : (
          <td
            className={`col11 txt-center schedule-right-body close`}
            data-tip
            data-for="schedule-full-text"
            onMouseEnter={() => handleOnMouse(schedule.field11_name, 10)}
          >
            <div
              className={`grid-cell-wrap not-editable ${
                schedule.field6 == 2 || schedule.field11_name == "" || schedule.field11_name == null ? "color-gray" : ""
              }`}
            >
              <div className="grid-cell-con">
                <div className="grid-cell-inner p-5">{omitText(schedule.field11_name, 10)}</div>
              </div>
            </div>
          </td>
        )}
      </tr>
    );
  }
}

const EditIcon = () => {
  return (
    <button
      className="grid-icon grid-icon-edit icon-mode_edit icon-edit"
      style={{ width: "14px", height: "14px", bottom: "6px", left: "calc(50% - 5px)" }}
    />
  );
};

ScheduleRightRowChiba.propTypes = {
  index: PropTypes.number.isRequired,
  schedule: PropTypes.object.isRequired,
  showScheduleEditor: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
};

export default withTranslation()(ScheduleRightRowChiba);
