import { zodResolver } from "@hookform/resolvers/zod";
import moment from "moment";
import { ChangeEventHandler, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import * as z from "zod";

import actions from "@/actions";
import FileItem from "@/components/FileItem";
import { ACCEPT_UPLOAD_EXTENSIONS } from "@/constants";
import { UploadFile, UploadFileMode } from "@/models/files";
import { RootState } from "@/reducers/types";
import { Button } from "@/sx-layout/common/Button";
import { FormLabel, FormRow, InputText, TextArea, Select, FileUploader, FormError } from "@/sx-layout/common/Form";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "@/sx-layout/common/Modal";
import { ToggleWithWords } from "@/sx-layout/common/ToggleWithWords";
import {
  CreateMachineProps,
  CreateMachineInput,
  UpdateMachineProps,
  UpdateMachineInput,
} from "@/sx-layout/components/plotmap/actions/types";
import { Machine, MachineStatusLabel, UserRole } from "@/sx-layout/components/plotmap/models";
import { formatUseHour, formatUseHourString } from "@/sx-layout/components/plotmap/util/formatUseHour";
import { MachineType, User } from "@/sx-layout/models";
import { HHMMRegex } from "@/sx-layout/utils";

export type MachineEditorProps = {
  readonly machineData?: Machine;
  readonly plotPlanId: string;
  readonly onClose: () => void;
  readonly initialX: number;
  readonly initialY: number;
};

export const MachineEditor: FC<MachineEditorProps> = ({ machineData, plotPlanId, onClose, initialX, initialY }) => {
  const { t } = useTranslation();
  const userRole = useSelector((state) => state.layoutApp.layoutMasters.user_role);
  const isAdmin = userRole === UserRole.MASTER_ROLE;

  const formSchema = z
    .object({
      company_id: z.union([z.number(), z.null()]).refine((value) => !isAdmin || value !== null, {
        message: t("select_company"),
      }),
      machine_type_id: z.union([z.number(), z.null()]).refine((value) => value !== null, {
        message: t("select_machine_owner_company"),
      }),
      wireless_flg: z.boolean(),
      jib_flg: z.boolean(),
      substitute: z.number(),
      use_start_hour: z
        .string()
        .refine((hour) => hour !== "", { message: `${t("operation_time")}：${t("input_start_hour_validation")}` })
        .refine((hour) => HHMMRegex.test(hour) && hour !== "24:00", {
          message: `${t("operation_time")}：${t("error_start_hour")}`,
        }),
      use_end_hour: z
        .string()
        .refine((hour) => hour !== "", { message: `${t("operation_time")}：${t("input_end_hour_validation")}` })
        .refine((hour) => HHMMRegex.test(hour) && hour !== "24:00", {
          message: `${t("operation_time")}：${t("error_end_hour")}`,
        }),
      work_content: z
        .string()
        .min(1, { message: t("input_content_validation") })
        .max(100, { message: t("content_is_too_long_100") }),
      user_id: z.union([z.number(), z.null()]).refine((value) => value !== null, {
        message: t("input_machine_user_validation"),
      }),
      note: z.union([z.string().max(100, { message: t("note_is_too_long_100") }), z.null()]),
    })
    .refine((data) => formatUseHour(data.use_start_hour) < formatUseHour(data.use_end_hour), {
      message: t("work_time_alert"),
      path: ["use_end_hour"],
    });

  const mode: "create" | "update" = !machineData ? "create" : "update";

  const [files, setFiles] = useState<any[]>([]);
  const uploading = useMemo(() => files.some((f) => f.mode === UploadFileMode.CREATE && !f.temp_file_id), [files]);

  const [fileError, setFileError] = useState<string>();

  const dispatch = useDispatch();
  useEffectOnce(() => {
    if (mode === "update") {
      dispatch(
        actions.machineEdit.fetchMachine({
          machine_id: machineData.machine_id,
          onSuccess: (data) => {
            setFiles(data.files.map((f) => ({ ...f, mode: UploadFileMode.UPLOADED } as UploadFile)));
          },
        })
      );
    }
  });

  const layoutDate = useSelector<RootState, Date>((state) => state.plotmap.layoutDate);
  const layoutMasterUsers = useSelector((state) => state.layoutApp.layoutMasters.users);
  const companies = useSelector((state) => state.layoutApp.layoutMasters.companies);
  const companiesMachineTypes = useSelector((state) => state.layoutApp.layoutMasters.companies_machine_types);

  const userId = useSelector((state) => state.session.userId);
  const user = layoutMasterUsers.find((user) => user.user_id === userId);
  const company = companies?.find((c) => c.company_id === user.company_id);
  const initCompanyId = Number(machineData?.company_id ?? company.company_id);

  const companyMachineTypeIds = useSelector((state) => state.machine.companyMachineTypeIds);
  const machineTypes = useSelector((state) => state.layoutApp.layoutMasters.machine_types);

  const [phoneNumber, setPhoneNumber] = useState(mode === "create" ? "-----" : machineData?.phone_number);
  const [companyName, setCompanyName] = useState(mode === "create" ? "-----" : machineData?.company_name);

  const getCompanyMachines = (companyId: number): MachineType[] => {
    if (!isAdmin) {
      // ログインユーザーの所属会社に紐づく重機種別の一覧を取得
      return machineTypes.filter((m) => companyMachineTypeIds.includes(m.machine_type_id));
    } else {
      // 管理ユーザーの場合
      const companyMachineTypeIds = companiesMachineTypes
        .filter((c) => c.company_id === companyId)
        .map((c) => c.machine_type_id);

      return machineTypes.filter((m) => companyMachineTypeIds.includes(m.machine_type_id));
    }
  };

  const getUsers = (companyId: number): User[] => {
    return layoutMasterUsers?.filter((u) => u.company_id === companyId);
  };

  const [companyMachines, setCompanyMachines] = useState(() => getCompanyMachines(initCompanyId));
  const [users, setUsers] = useState(() => getUsers(initCompanyId));

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<CreateMachineInput | UpdateMachineInput>({
    resolver: zodResolver(formSchema),
    reValidateMode: "onSubmit",
    defaultValues: {
      company_id: initCompanyId,
      machine_type_id: machineData?.machine_type_id ?? null,
      wireless_flg: machineData ? machineData.wireless_flg : true,
      jib_flg: machineData ? machineData.jib_flg : false,
      substitute: machineData?.substitute ?? 0,
      use_start_hour: machineData?.use_start_hour ?? "",
      use_end_hour: machineData?.use_end_hour ?? "",
      work_content: machineData?.work_content ?? "",
      user_id: machineData?.user_id ?? null,
      note: machineData?.note ?? "",
    },
  });

  // 時間文字列のフォーマット
  const useStartHour = watch("use_start_hour");
  const onBlurUseStartHour = useCallback(() => {
    setValue("use_start_hour", formatUseHourString(useStartHour));
  }, [useStartHour]);
  const useEndHour = watch("use_end_hour");
  const onBlurUseEndHour = useCallback(() => {
    setValue("use_end_hour", formatUseHourString(useEndHour));
  }, [useEndHour]);

  // 会社の選択肢に連動して重機・使用者を表示する
  const onChangeCompany = (companyId: number) => {
    setCompanyMachines(getCompanyMachines(companyId));
    setUsers(getUsers(companyId));
    setValue("user_id", null);
    setValue("machine_type_id", null);
    setCompanyName("-----");
    setPhoneNumber("-----");
  };

  // 使用者の選択肢に連動して所属・連絡先を表示する
  const onChangeUser = (userId: string) => {
    const user = layoutMasterUsers.find((user) => String(user.user_id) === userId);
    setPhoneNumber(user?.phone_number ?? "-----");
    const company = companies.find((c) => c.company_id === user?.company_id);
    setCompanyName(company?.company_name ?? "-----");
  };

  const createMachine = useCallback(
    (props: CreateMachineProps) => dispatch(actions.machineEdit.createMachine(props)),
    []
  );

  const updateMachine = useCallback(
    (props: UpdateMachineProps) => dispatch(actions.machineEdit.updateMachine(props)),
    []
  );

  const onSubmit = (values: CreateMachineInput | UpdateMachineInput) => {
    const user = layoutMasterUsers.find((user) => String(user.user_id) === String(values.user_id));
    const company = companies.find((c) => c.company_id === user.company_id);

    const submitFiles = files
      .filter((f) => {
        // アップロード未完了ファイルはPOSTしない
        if (f.mode === UploadFileMode.CREATE && f.progress && f.progress < 100) {
          return false;
        }
        return true;
      })
      .map((f) => {
        const file: UploadFile = {
          file_name: f.file_name,
          mode: f.mode,
        };

        if (f.temp) {
          file.temp_file_id = f.temp_file_id;
        } else {
          file.file_id = f.file_id;
          file.timestamp = f.timestamp;
        }

        return file;
      });

    if (mode === "create") {
      createMachine({
        input: {
          ...values,
          company_id: company.company_id,
          layout_date: moment(layoutDate).format("YYYY-MM-DD").toString(),
          plot_plan_id: Number(plotPlanId),
          x: initialX,
          y: initialY,
          files: submitFiles,
        },
        onSuccess: () => {
          onClose();
        },
      } as CreateMachineProps);
    } else {
      updateMachine({
        input: {
          ...values,
          plot_plan_id: Number(plotPlanId),
          layout_date: moment(layoutDate).format("YYYY-MM-DD").toString(),
          machine_id: machineData.machine_id,
          timestamp: {
            update_date: machineData.timestamp.update_date,
          },
          files: submitFiles,
        },
        onSuccess: () => {
          onClose();
        },
      } as UpdateMachineProps);
    }
  };

  const handleToggleChange = (name: string, value: boolean) => {
    if (name.includes("wireless_flg")) {
      setValue("wireless_flg", value);
    }
    if (name.includes("jib_flg")) {
      setValue("jib_flg", value);
    }
  };

  const handleUploadProgress = (key, e) => {
    setFiles((files) => {
      return files.map((file) => {
        if (file.temp && file.key == key) {
          file.progress = (e.loaded / e.total) * 100;
        }
        return file;
      });
    });
  };

  const handleUploadComplete = (key, e) => {
    setFiles((files) => {
      return files.map((file) => {
        if (file.temp && file.key == key) {
          file.progress = 100;
          file.temp_file_id = e.temp_file_id;
        }
        return file;
      });
    });
  };

  const handleUploadError = (key, _e) => {
    const msgs = _e.response.errors?.map((m) => m.err_message).filter((m) => m);
    if (msgs) {
      dispatch(actions.app.showAlert(t("error"), msgs, () => dispatch(actions.app.hideAlert())));
    }

    const file = files.find((f) => f.key === key);

    if (file) {
      setFiles((files) => files.filter((f) => f.key !== key));
    }
  };

  // NOTE: コールバックで渡すとStateの変更が効かないため最新のStateで関数を実行する
  const uploadProgress = useRef<(k, e) => void>();
  const uploadComplete = useRef<(k, e) => void>();
  const uploadError = useRef<(k, e) => void>();
  useEffect(() => {
    uploadProgress.current = handleUploadProgress;
    uploadComplete.current = handleUploadComplete;
    uploadError.current = handleUploadError;
  }, [files.length]);

  const handleChangeFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    const tempFiles = Array.from(e.target.files).map((file) => ({
      key: Symbol(),
      file_name: file.name,
      file_format: file.type,
      mode: 1,
      temp: true,
      progress: 0,
      done: false,
      data: file,
    }));

    if (tempFiles.some((v) => 100 < v.file_name.length)) {
      setFileError(t("file_name_is_too_long"));
      return;
    } else {
      setFileError(null);
    }

    tempFiles.forEach((file) => {
      const formData = new FormData();
      formData.append("target", "4");
      formData.append("uploadfile", file.data);
      dispatch(
        actions.app.upload(
          formData,
          (e) => uploadProgress.current(file.key, e),
          (e) => uploadComplete.current(file.key, e),
          (e) => uploadError.current(file.key, e)
        )
      );
    });

    setFiles((files) => [...tempFiles, ...files]);
  };

  const handleRemoveFile = (fileId, isTemp) => {
    dispatch(
      actions.app.showConfirm(
        t("delete_file"),
        [t("delete_file_alert")],
        () => {
          setFiles((files) => {
            return files
              .map((f) => {
                if (isTemp && f.temp_file_id === fileId) {
                  f.mode = UploadFileMode.TEMP;
                } else if (!isTemp && f.file_id === fileId) {
                  f.mode = UploadFileMode.DELETE;
                }

                return f;
              })
              .filter((f) => f.mode !== UploadFileMode.TEMP);
          });
          dispatch(actions.app.hideConfirm());
        },
        () => {
          dispatch(actions.app.hideConfirm());
        }
      )
    );
  };

  return (
    <Modal onClose={onClose} id="machine-editor-modal" shouldCloseOnEsc={false} shouldCloseOnOverlayClick={false}>
      <ModalHeader>{t("edit_heavy_machine")}</ModalHeader>
      <ModalBody className="modal-body w-1000 items-start">
        <div className="mr-[60px]">
          <form>
            {isAdmin && (
              <FormRow errorComponent={errors.company_id && <FormError>{errors.company_id.message}</FormError>}>
                <FormLabel>{t("company_of_machine_owner")}</FormLabel>
                {mode === "create" ? (
                  <Select
                    className="w-200"
                    // @ts-ignore
                    control={control}
                    name="company_id"
                    handleChange={onChangeCompany}
                    options={companies.map((c) => ({
                      label: c.company_name,
                      value: c.company_id,
                    }))}
                  />
                ) : (
                  <span className="w-[160px] px-[8px] ">{machineData.company_name}</span>
                )}
              </FormRow>
            )}

            <FormRow errorComponent={errors.machine_type_id && <FormError>{errors.machine_type_id.message}</FormError>}>
              <FormLabel>{t("heavy_machine")}</FormLabel>
              <Select
                className="w-200"
                // @ts-ignore
                control={control}
                name="machine_type_id"
                isClearable={true}
                options={companyMachines.map((m) => ({
                  label: m.machine_type_name,
                  value: m.machine_type_id,
                }))}
              />
            </FormRow>

            <div className="flex">
              <FormRow className="mr-0">
                <FormLabel>{t("radio")}</FormLabel>
                <ToggleWithWords
                  id="wireless_flg"
                  onChange={handleToggleChange}
                  isDefaultOn={machineData?.wireless_flg}
                  labelOn={t("use")}
                  labelOff={t("negation")}
                />
              </FormRow>
              <FormRow className="mr-0">
                <FormLabel>{t("jib_extension")}</FormLabel>
                <ToggleWithWords
                  id="jib_flg"
                  onChange={handleToggleChange}
                  isDefaultOn={!!machineData?.jib_flg}
                  labelOn={t("extension")}
                  labelOff={t("not_extension")}
                />
              </FormRow>
            </div>

            <FormRow>
              <FormLabel>{t("substitute")}</FormLabel>
              <Select
                className="w-200"
                // @ts-ignore
                control={control}
                name="substitute"
                options={[
                  {
                    label: t("disuse"),
                    value: 0,
                  },
                  ...companyMachines.map((m) => ({
                    label: m.machine_type_name,
                    value: m.machine_type_id,
                  })),
                ]}
              />
            </FormRow>

            <FormRow
              errorComponent={
                <>
                  {errors.use_start_hour && <FormError>{errors.use_start_hour.message}</FormError>}
                  {errors.use_end_hour && <FormError>{errors.use_end_hour.message}</FormError>}
                </>
              }
            >
              <FormLabel>{t("operation_time")}</FormLabel>
              <InputText className="w-[80px]" {...register("use_start_hour")} onBlur={onBlurUseStartHour} />
              <span className="mx-3">〜</span>
              <InputText
                className="w-[80px]"
                {...register("use_end_hour", { deps: ["use_start_hour"] })}
                onBlur={onBlurUseEndHour}
              />
            </FormRow>
            <FormRow errorComponent={errors.work_content && <FormError>{errors.work_content.message}</FormError>}>
              <FormLabel>{t("content")}</FormLabel>
              <InputText
                className="w-[300px] px-[8px] py-[4px] border border-[#000] rounded"
                {...register("work_content")}
              />
            </FormRow>

            <FormRow>
              <FormLabel>{t("belonging")}</FormLabel>
              <span className="w-[300px] px-[8px] py-[4px]">{companyName}</span>
            </FormRow>

            <FormRow errorComponent={errors.user_id && <FormError>{errors.user_id.message}</FormError>}>
              <FormLabel>{t("machine_user")}</FormLabel>
              <Select
                className="w-200"
                // @ts-ignore
                control={control}
                name="user_id"
                handleChange={(value) => onChangeUser(String(value))}
                isClearable={true}
                options={users.map((user) => ({
                  label: user.user_name,
                  value: user.user_id,
                }))}
              />
            </FormRow>

            <FormRow>
              <FormLabel>{t("contact")}</FormLabel>
              <span className="w-[160px] px-[8px] py-[4px]">{phoneNumber}</span>
            </FormRow>

            <FormRow>
              <FormLabel>{t("machine_status")}</FormLabel>
              {mode === "create" ? (
                <span className="w-[160px] px-[8px] py-[4px]">-----</span>
              ) : (
                <span className="w-[160px] px-[8px] py-[4px]">{t(MachineStatusLabel[machineData?.status])}</span>
              )}
            </FormRow>

            <FormRow
              className="items-start"
              errorComponent={errors.note && <FormError>{errors.note.message}</FormError>}
            >
              <FormLabel className="mt-[4px]">{t("note")}</FormLabel>
              <TextArea
                className="w-[300px] border border-[#000] rounded px-[8px] py-[4px]"
                rows={5}
                {...register("note")}
              />
            </FormRow>
          </form>
        </div>

        <div className="w-[300px]">
          <FormRow
            className="mb-[7px] mt-[20px] mr-0"
            errorComponent={fileError && <FormError marginLeftSize={"0"}>{fileError}</FormError>}
          >
            <FormLabel className="w-160">{t("upload_file")}</FormLabel>
            <FileUploader
              name="uploadFiles"
              accept={ACCEPT_UPLOAD_EXTENSIONS.join(",")}
              multiple={true}
              onChange={handleChangeFile}
            />
          </FormRow>
          {files?.length > 0 && (
            <div className="file-upload-box">
              <ul className="form-file-list">
                {files
                  .filter((f) => f.mode !== UploadFileMode.DELETE)
                  .map((file, index) => (
                    <FileItem
                      key={index}
                      // @ts-ignore
                      kind="machines"
                      // @ts-ignore
                      file={file}
                      readOnly={false}
                      onDelete={handleRemoveFile}
                      onDownload={(kind, fileId, qrFlag, callback) =>
                        dispatch(actions.common.downloadAttachmentFile(kind, fileId, qrFlag, callback))
                      }
                    />
                  ))}
              </ul>
            </div>
          )}
        </div>
      </ModalBody>

      <ModalFooter>
        <Button buttonType="cancel" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button buttonType="save" onClick={handleSubmit(onSubmit)} disabled={uploading}>
          {t("save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
