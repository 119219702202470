import { useTranslation } from "react-i18next";

export const ExplanatoryNote = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-white flex flex-col p-[10px]">
      <div>{t("safety_instruction_note_title")}</div>
      <div className="flex flex-col m-[10px]">
        <span>{t("safety_instruction_note_1")}</span>
        <span>{t("safety_instruction_note_2")}</span>
        <span>{t("safety_instruction_note_3")}</span>
        <span>{t("safety_instruction_note_4")}</span>
        <span>{t("safety_instruction_note_5")}</span>
        <span>{t("safety_instruction_note_6")}</span>
        <span>{t("safety_instruction_note_7")}</span>
        <span>{t("safety_instruction_note_8")}</span>
      </div>
    </div>
  );
};
