import * as constants from "@/constants";
import environments from "@/environments";
import { DUMMY_TIMESTAMP } from "@/models/timestamp";
import {
  CopyKeepOutAreasProps,
  CreateCustomKeepOutAreaParams,
  CreateKeepOutAreaRequest,
  DeleteKeepOutAreasProps,
  UpdateCustomKeepOutAreasCoordinatesParams,
  UpdateKeepOutAreaRequest,
  UpdateKeepOutAreasCoordinatesProps,
  UpdateKeepOutAreasCoordinatesResponse,
} from "@/sx-layout/components/plotmap/actions/types";
import { AreaShapeType, KeepOutAreaPolygon } from "@/sx-layout/components/plotmap/models";

export const createKeepOutAreas = ({ params, onSuccess, onFailure }: CreateKeepOutAreaRequest) => {
  const endpoint = `${environments.API_BASE_URI}/keepout_areas/create`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(params),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_CREATE_KEEP_OUT_AREAS,
        };
      },
      success: (response) => {
        if (onSuccess !== undefined) {
          onSuccess(response);
        }

        return {
          type: constants.PLOT_MAP_END_CREATE_KEEP_OUT_AREAS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
          callback: () => {
            onFailure?.();
          },
        };
      },
    },
  };
};

export const updateKeepOutAreas = ({ params, onSuccess, onFailure }: UpdateKeepOutAreaRequest) => {
  const endpoint = `${environments.API_BASE_URI}/keepout_areas/update/${params.keepout_area_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(toRequest(params)),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_UPDATE_KEEP_OUT_AREAS,
        };
      },
      success: (response) => {
        onSuccess?.(response);

        return {
          type: constants.PLOT_MAP_END_UPDATE_KEEP_OUT_AREAS,
          payload: { ...response, coords: response.coords ? JSON.parse(response.coords) : null },
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
          callback: () => {
            onFailure?.();
          },
        };
      },
    },
  };
};

export const copyKeepOutAreas = (props: CopyKeepOutAreasProps) => {
  const endpoint = `${environments.API_BASE_URI}/keepout_areas/copy`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(props.input),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_COPY_KEEP_OUT_AREAS,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess();
        }

        return {
          type: constants.PLOT_MAP_END_COPY_KEEP_OUT_AREAS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const deleteKeepOutAreas = (props: DeleteKeepOutAreasProps) => {
  const endpoint = `${environments.API_BASE_URI}/keepout_areas/delete/${props.input.keepout_area_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(props.input),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_DELETE_KEEP_OUT_AREAS,
        };
      },
      success: (response) => {
        props?.onSuccess?.();

        return {
          type: constants.PLOT_MAP_END_DELETE_KEEP_OUT_AREAS,
          payload: {
            ...response,
            machine_id: props.input.machine_id,
            keepout_area_id: props.input.keepout_area_id,
          },
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateKeepOutAreasCoordinates = (props: UpdateKeepOutAreasCoordinatesProps) => {
  const endpoint = `${environments.API_BASE_URI}/keepout_areas/rect/update/${props.input.keepout_area_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(props.input),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_UPDATE_KEEP_OUT_AREAS_COORDINATES,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess(response);
        }

        return {
          type: constants.PLOT_MAP_END_UPDATE_KEEP_OUT_AREAS_COORDINATES,
          params: props.input,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

// 多角形の立入禁止エリアは、作成と移動だけが四角形の立入禁止エリアとAPIが別れている
export const createKeepOutAreaPolygon = (
  keepOutArea: CreateCustomKeepOutAreaParams,
  callback?: (data: KeepOutAreaPolygon) => void,
  fallback?: () => void
) => {
  const endpoint = `${environments.API_BASE_URI}/custom_keepout_areas/create`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(toRequest(keepOutArea)),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_CREATE_KEEP_OUT_AREAS_POLYGON,
        };
      },
      success: (response) => {
        callback?.(response);

        return {
          type: constants.PLOT_MAP_END_CREATE_KEEP_OUT_AREAS_POLYGON,
          payload: { ...response, coords: keepOutArea.coords },
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
          callback: () => {
            fallback?.();
          },
        };
      },
    },
  };
};

export const updateKeepOutAreaPolygonCoordinates = (
  params: UpdateCustomKeepOutAreasCoordinatesParams,
  callback?: (response: UpdateKeepOutAreasCoordinatesResponse) => void
) => {
  const { keepout_area_id, ...body } = params;
  const endpoint = `${environments.API_BASE_URI}/custom_keepout_areas/coord/update/${keepout_area_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(toRequest(body)),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_UPDATE_KEEP_OUT_AREAS_POLYGON_COORDINATES,
        };
      },
      success: (response) => {
        callback?.(response);

        return {
          type: constants.PLOT_MAP_END_UPDATE_KEEP_OUT_AREAS_POLYGON_COORDINATES,
          params,
          payload: { ...response, coords: body.coords },
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const addWipKeepOutAreaPolygon = (
  area: Partial<KeepOutAreaPolygon> & {
    icon_color: string;
    coords: [number, number][];
  }
) => {
  const wip: KeepOutAreaPolygon = {
    keepout_area_id: 0,
    construction_id: 0,
    plot_plan_id: 0,
    layout_date: "",
    company_id: 0,
    company_name: "",
    use_start_hour: "6:00",
    use_end_hour: "12:00",
    work_content: "WIP",
    user_id: 0,
    note: "",
    shape_type: AreaShapeType.POLYGON,
    timestamp: DUMMY_TIMESTAMP,
    ...area,
  };

  return {
    type: constants.PLOT_MAP_ADD_WIP_KEEP_OUT_AREA_POLYGON,
    payload: wip,
  };
};

export const changeWipKeepOutAreaPolygonCompany = (area: Pick<KeepOutAreaPolygon, "company_id" | "icon_color">) => {
  return {
    type: constants.PLOT_MAP_CHANGE_WIP_KEEP_OUT_AREA_POLYGON_COMPANY,
    payload: area,
  };
};

export const removeWipKeepOutAreaPolygon = () => {
  return {
    type: constants.PLOT_MAP_REMOVE_WIP_KEEP_OUT_AREA_POLYGON,
  };
};

const toRequest = (input) => {
  return {
    ...input,
    coords: input.coords
      ? JSON.stringify(input.coords.map((coord) => [Math.round(coord[0]), Math.round(coord[1])]))
      : null,
  };
};
