import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import InformationEditorContainer from "../../containers/home/InformationEditorContainer";
import InformationViewerContainer from "../../containers/home/InformationViewerContainer";
import { addHeightResizeListener, removeHeightResizeListener } from "../../lib/common";
import { isValid } from "../../lib/roleChecker";

let autoload = false; // IEの二重リクエスト対策フラグ

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInformationEditor: false,
      showInformationViewer: false,
      fetchingInformation: false,
      information: null,
      tableMaxHeight: 615,
      fetching: false,
      currentPosition: 0,
    };

    this.handleTableHeight = this.handleTableHeight.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.HandleSearch = this.HandleSearch.bind(this);
  }

  componentDidMount() {
    this.handleTableHeight();
    this.resizeTimer = 0;
    window.addEventListener("resize", this.handleResize);
    addHeightResizeListener(this.menuBox, this.handleTableHeight);
    addHeightResizeListener(this.masterBox, this.handleTableHeight);
    window.addEventListener("scroll", (event) => this.watchCurrentPosition(), true);

    this.HandleSearch();
  }

  componentWillUnmount() {
    this.props.resetInformation();

    window.removeEventListener("resize", this.handleResize);
    removeHeightResizeListener(this.menuBox);
    removeHeightResizeListener(this.masterBox);
    window.removeEventListener("scroll", null);
  }

  watchCurrentPosition() {
    const scrollTop = this.scrollTop();

    const obj = document.getElementById("data-export-area");
    if (obj) {
      obj.style.top = 65 - scrollTop + "px";
    }

    const progressNotifications = document.getElementById("progressNotifications");
    if (progressNotifications) {
      progressNotifications.style.top = 65 - scrollTop + "px";
    }
  }

  scrollTop() {
    return Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
  }

  HandleSearch() {
    if (autoload) {
      return;
    }

    autoload = true;

    const { fetchInformations } = this.props;

    this.setState({ fetching: true });

    const params = {
      start: 1,
      limit: 999999,
    };

    fetchInformations(params, () => {
      autoload = false;

      const callback = () => setTimeout(this.fillList, 200);

      this.setState(
        {
          fetching: false,
        },
        callback
      );
    });
  }

  handleTableHeight() {
    const headerHeight = 45;
    const tableHeaderHeight = 32;

    let maxHeight = window.innerHeight - headerHeight;

    const pageTtlMenuHeight = this.pageTtlMenu ? this.pageTtlMenu.offsetHeight + 30 : 0;
    const menuBoxHeight = this.menuBox ? this.menuBox.offsetHeight + 20 : 0;
    const pageTtlMasterHeight = this.pageTtlMaster ? this.pageTtlMaster.offsetHeight + 30 : 0;
    const masterBoxHeight = this.masterBox ? this.masterBox.offsetHeight + 20 : 0;
    const leftAreaHeight = pageTtlMenuHeight + menuBoxHeight + pageTtlMasterHeight + masterBoxHeight;
    if (maxHeight < leftAreaHeight) {
      const scrollbarHeight = 15;
      maxHeight = leftAreaHeight - scrollbarHeight;
    }

    const pageTtlBoardHeight = this.pageTtlBoard ? this.pageTtlBoard.offsetHeight + 30 : 0;

    const marginBottom = 20;
    maxHeight = maxHeight - pageTtlBoardHeight - marginBottom - tableHeaderHeight;

    this.setState({ tableMaxHeight: maxHeight });
  }

  handleResize() {
    if (this.resizeTimer > 0) {
      clearTimeout(this.resizeTimer);
    }

    const callback = () => {
      this.handleTableHeight();
    };

    this.resizeTimer = setTimeout(callback, 200);
  }

  showInformationEditor(informationId) {
    if (informationId > 0) {
      this.setState({
        showInformationEditor: true,
        fetchingInformation: true,
      });

      this.props.fetchInformation(informationId, (data) => {
        this.setState({
          fetchingInformation: false,
          information: data,
        });
      });
    } else {
      this.setState({
        showInformationEditor: true,
        information: null,
        fetchingInformation: false,
      });
    }
  }

  hideInformationEditor() {
    this.setState({
      showInformationEditor: false,
      information: null,
    });
  }

  showInformationViewer(informationId) {
    this.setState({
      showInformationViewer: true,
      fetchingInformation: true,
    });

    this.props.fetchInformation(informationId, () => this.setState({ fetchingInformation: false }));
  }

  hideInformationViewer() {
    this.setState({
      showInformationViewer: false,
    });
  }

  isActiveMenu(menuId) {
    return this.props.menus.some((menu) => menu.menu_id === menuId && menu.active);
  }

  isActiveMenuSome(menuIds) {
    return menuIds.some((id) => this.props.menus.some((menu) => menu.menu_id === id && menu.active));
  }

  render() {
    const { roles, informations, changeMenu, t } = this.props;

    const noDataMsg = <p className="empty-message">{t("no_data")}</p>;

    const informationBoard =
      this.state.fetching || informations.length ? (
        <div className={`tbl-box ${this.state.fetching ? "loading--list" : ""}`}>
          <table className="tbl-basic">
            <thead className="tbl-head-adjusted">
              <tr>
                <th>{t("board_subject_full")}</th>
                <th>{t("created_by")}</th>
                <th>{t("created_at")}</th>
                <th>{t("edit")}</th>
              </tr>
            </thead>
            <tbody style={{ maxHeight: this.state.tableMaxHeight }}>
              {informations.map((information, index) => (
                <tr key={index}>
                  <td>
                    <a onClick={() => this.showInformationViewer(information.information_id)}>{information.subject}</a>
                    {information.file_status && (
                      <span className="icon">
                        <img className="attach_file" src="./img/icon-clip-black.svg" />
                      </span>
                    )}
                  </td>
                  <td className="txt-center">{information.timestamp.insert_user_name}</td>
                  <td className="txt-center">{moment(information.timestamp.insert_date).format("YYYY/MM/DD HH:mm")}</td>
                  <td className="txt-center">
                    {isValid(information.roles, "__self__", "update") && (
                      <a onClick={() => this.showInformationEditor(information.information_id)}>{t("edit")}</a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        noDataMsg
      );

    return (
      <div>
        <div className="contents home-contents">
          <div className="inner">
            <div className="home-left" ref={(node) => (this.homeLeft = node)}>
              <h1 className="page-ttl" ref={(node) => (this.pageTtlMenu = node)}>
                {t("main_menu")}
                <span className="link_maual">
                  <img src="./img/icon-beginner.svg" />
                  <a href="/api/manual" target="_blank">
                    {t("operation_manual")}
                  </a>
                </span>
              </h1>
              <div className="menu-box" ref={(node) => (this.menuBox = node)}>
                <ul className="main-menu">
                  {this.isActiveMenu(1001) && (
                    <li data-test-id="button-home-import" className="btn btn-blue">
                      <a onClick={() => changeMenu("dataimport")}>
                        <span className="icon">
                          <img src="./img/icon-import-white.svg" />
                        </span>
                        <span>{t("data_import")}</span>
                      </a>
                    </li>
                  )}
                  {this.isActiveMenu(1002) && (
                    <li data-test-id="button-home-summary" className="btn btn-blue">
                      <a onClick={() => changeMenu("summary")}>
                        <span className="icon">
                          <img src="./img/icon-summary-white.svg" />
                        </span>
                        <span>{t("construction_summary")}</span>
                      </a>
                    </li>
                  )}
                  {this.isActiveMenu(1003) && (
                    <li data-test-id="button-home-matrix" className="btn btn-blue">
                      <a onClick={() => changeMenu("matrix")}>
                        <span className="icon">
                          <img src="./img/icon-matrix-white.svg" />
                        </span>
                        <span>
                          {t("progress_manage")}
                          <br />
                          {t("matrix")}
                        </span>
                      </a>
                    </li>
                  )}
                  {this.isActiveMenu(1009) && (
                    <li data-test-id="button-home-schedule" className="btn btn-blue">
                      <a onClick={() => changeMenu("schedule")}>
                        <span className="icon">
                          <img src="./img/icon-list-white.svg" />
                        </span>
                        <span>{t("schedule_list")}</span>
                      </a>
                    </li>
                  )}
                  {this.isActiveMenu(1004) && (
                    <li data-test-id="button-home-system" className="btn btn-blue">
                      <a onClick={() => changeMenu("system")}>
                        <span className="icon">
                          <img src="./img/icon-system-white.svg" />
                        </span>
                        <span>{t("system_list")}</span>
                      </a>
                    </li>
                  )}
                  {this.isActiveMenu(1005) && (
                    <li data-test-id="button-home-approval" className="btn btn-blue">
                      <a onClick={() => changeMenu("approval")}>
                        <span className="icon">
                          <img src="./img/icon-list-white.svg" />
                        </span>
                        <span>{t("plan_approval_list")}</span>
                      </a>
                    </li>
                  )}
                  {this.isActiveMenu(1101) && (
                    <li data-test-id="button-home-schedule" className="btn btn-blue">
                      <a onClick={() => changeMenu("schedule_eneos_kawasaki")}>
                        <span className="icon">
                          <img src="./img/icon-list-white.svg" />
                        </span>
                        <span>{t("schedule_list")}</span>
                      </a>
                    </li>
                  )}
                  {this.isActiveMenu(1102) && (
                    <li data-test-id="button-home-schedule" className="btn btn-blue">
                      <a onClick={() => changeMenu("schedule_chita")}>
                        <span className="icon">
                          <img src="./img/icon-list-white.svg" />
                        </span>
                        <span>{t("schedule_list")}</span>
                      </a>
                    </li>
                  )}
                  {this.isActiveMenu(1103) && (
                    <li data-test-id="button-home-schedule" className="btn btn-blue">
                      <a onClick={() => changeMenu("schedule_chiba")}>
                        <span className="icon">
                          <img src="./img/icon-list-white.svg" />
                        </span>
                        <span>{t("schedule_list")}</span>
                      </a>
                    </li>
                  )}
                  {this.isActiveMenu(1007) && (
                    <li data-test-id="button-home-qrcode" className="btn btn-blue">
                      <a onClick={() => changeMenu("qrcode")}>
                        <span className="icon">
                          <img src="./img/icon-qr-white.svg" />
                        </span>
                        <span>{t("qr_export")}</span>
                      </a>
                    </li>
                  )}
                  {this.isActiveMenu(1008) && (
                    <li data-test-id="button-home-asset" className="btn btn-blue">
                      <a onClick={() => changeMenu("asset")}>
                        <span className="icon">
                          <img src="./img/icon-asset-white.svg" />
                        </span>
                        <span>{t("asset_list")}</span>
                      </a>
                    </li>
                  )}
                  {this.isActiveMenu(1010) && (
                    <li data-test-id="button-home-asset" className="btn btn-blue">
                      <a onClick={() => changeMenu("unworkdays")}>
                        <span className="icon">
                          <img src="./img/icon-unworkdays-white.svg" />
                        </span>
                        <span>{t("unwork_days") + t("settings")}</span>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              {this.isActiveMenuSome([1011, 1012, 1021, 1022, 1023, 1024, 1025, 1026, 1031, 1032]) && (
                <React.Fragment>
                  <h2 className="page-ttl" ref={(node) => (this.pageTtlMaster = node)}>
                    {t("master_manage")}
                  </h2>
                  <div className="menu-box" ref={(node) => (this.masterBox = node)}>
                    <ul className="menu">
                      {this.isActiveMenu(1011) && (
                        <li
                          data-test-id="button-home-category"
                          className="btn btn-light-blue"
                          onClick={() => changeMenu("category")}
                        >
                          <span>{t("machines_category")}</span>
                        </li>
                      )}
                      {this.isActiveMenu(1025) && (
                        <li
                          data-test-id="button-home-primary_charge"
                          className="btn btn-light-blue"
                          onClick={() => changeMenu("primary_charge")}
                        >
                          <span>{t("primary_charge")}</span>
                        </li>
                      )}
                      {this.isActiveMenu(1012) && (
                        <li
                          data-test-id="button-home-checkpoint"
                          className="btn btn-light-blue"
                          onClick={() => changeMenu("checkpoint")}
                        >
                          <span>{t("checkpoint")}</span>
                        </li>
                      )}
                    </ul>
                    {this.isActiveMenuSome([1021, 1022, 1023, 1024, 1025, 1026]) && (
                      <React.Fragment>
                        <h3 className="menu-ttl">{t("category")}</h3>
                        <ul className="menu">
                          {this.isActiveMenu(1021) && (
                            <li
                              data-test-id="button-home-area"
                              className="btn btn-light-blue"
                              onClick={() => changeMenu("area")}
                            >
                              <span>{t("area")}</span>
                            </li>
                          )}
                          {this.isActiveMenu(1022) && (
                            <li
                              data-test-id="button-home-device"
                              className="btn btn-light-blue"
                              onClick={() => changeMenu("device")}
                            >
                              <span>{t("device")}</span>
                            </li>
                          )}
                          {this.isActiveMenu(1023) && (
                            <li
                              data-test-id="button-home-facility_management"
                              className="btn btn-light-blue"
                              onClick={() => changeMenu("facility_management")}
                            >
                              <span>{t("facility_management")}</span>
                            </li>
                          )}
                          {this.isActiveMenu(1024) && (
                            <li
                              data-test-id="button-home-construction_management"
                              className="btn btn-light-blue"
                              onClick={() => changeMenu("construction_management")}
                            >
                              <span>{t("construction_management")}</span>
                            </li>
                          )}
                          {this.isActiveMenu(1026) && (
                            <li
                              data-test-id="button-home-other"
                              className="btn btn-light-blue"
                              onClick={() => changeMenu("other")}
                            >
                              <span>{t("other")}</span>
                            </li>
                          )}
                        </ul>
                      </React.Fragment>
                    )}
                    {this.isActiveMenuSome([1031, 1032]) && (
                      <React.Fragment>
                        <h3 className="menu-ttl">{t("job_category")}</h3>
                        <ul className="menu">
                          {this.isActiveMenu(1031) && (
                            <li
                              data-test-id="button-home-process_major_class"
                              className="btn btn-light-blue"
                              onClick={() => changeMenu("process_major_class")}
                            >
                              <span>{t("major_class")}</span>
                            </li>
                          )}
                          {this.isActiveMenu(1032) && (
                            <li
                              data-test-id="button-home-process_middle_class"
                              className="btn btn-light-blue"
                              onClick={() => changeMenu("process_middle_class")}
                            >
                              <span>{t("middle_class")}</span>
                            </li>
                          )}
                        </ul>
                      </React.Fragment>
                    )}
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="home-right">
              <h2 className="page-ttl" ref={(node) => (this.pageTtlBoard = node)}>
                {t("board")}
                {isValid(roles, "information", "create") && (
                  <span
                    data-test-id="button-home-create_information"
                    className="icon"
                    onClick={() => this.showInformationEditor(0)}
                  >
                    <img src="./img/icon-add-blue.svg" />
                  </span>
                )}
              </h2>
              {informationBoard}
            </div>
          </div>
        </div>
        {this.state.showInformationEditor && (
          <InformationEditorContainer
            data={this.state.information}
            fetching={this.state.fetchingInformation}
            closeHandler={() => this.hideInformationEditor()}
          />
        )}
        {this.state.showInformationViewer && (
          <InformationViewerContainer
            data={this.props.currentInformation}
            fetching={this.state.fetchingInformation}
            closeHandler={() => this.hideInformationViewer()}
          />
        )}
      </div>
    );
  }
}

Home.propTypes = {
  menus: PropTypes.array.isRequired,
  menu: PropTypes.string.isRequired,
  roles: PropTypes.object.isRequired,
  informations: PropTypes.array.isRequired,
  currentInformation: PropTypes.object,
  changeMenu: PropTypes.func.isRequired,
  fetchInformations: PropTypes.func.isRequired,
  fetchInformation: PropTypes.func.isRequired,
  resetInformation: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Home);
