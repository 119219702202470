import PropTypes from "prop-types";
import React, { Component, useCallback } from "react";
import DatePicker from "react-datepicker";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import ReactTooltip from "react-tooltip";

import * as actions from "../../actions/index";
import styles from "../../constants/reactSelectStyle";
import ScheduleCategoryUpdateEneosKawasakiContainer from "../../containers/list/ScheduleCategoryUpdateEneosKawasakiContainer";
import ScheduleEditorBundleEneosKawasakiContainer from "../../containers/list/ScheduleEditorBundleEneosKawasakiContainer";
import ScheduleEditorEneosKawasakiContainer from "../../containers/list/ScheduleEditorEneosKawasakiContainer";
import ScheduleRightRowEneosKawasakiContainer from "../../containers/list/ScheduleRightRowEneosKawasakiContainer";
import TooltipContainer from "../../containers/list/TooltipContainer";
import { addHeightResizeListener, removeHeightResizeListener, getTableBodyHeight } from "../../lib/common";
import ChargeSelect from "../common/ChargeSelect";
import MasterSelect from "../common/Select";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import * as util from "../../lib/common";
import storageManager from "../../lib/storageManager";
import restoreState from "../../lib/restoreState";
import { isValid, isValidRole } from "../../lib/roleChecker";

const autoload = false; // IEの二重リクエスト対策フラグ
class ScheduleEneosKawasaki extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch: true,
      showTable: false,
      currentRowIndex: -1,
      start: 1,
      end: false,
      isEmpty: false,
      tableBodyMaxHeight: window.innerHeight - 350,
      offset: 0,
      maxLength: 60,
      isWide: false,
      scheduleId: 0,
      downloadUrl: "",
      downloadName: "",
      downloading: false,

      panelText: "",
      requestParam: [],
      showUpdateBundle: false,
      showEditor: false,
      showUpdatePanel: false,

      timeStamp: "",
      updateDate: "",
      target: "",
      item: null,
      processText: "",
      scheduleId: 0,
      scheduleUpdateDate: "",
      switchTime: "",
      selectValues: [],

      field1ListIds: [],
      field7ListIds: [],
      field8ListIds: [],
      userListIds: [],
      groupListIds: [],

      editNote1: null,
      editNote2: null,

      options: [],
      tooltip: null,

      limit: 20,
      currentPage: 1,
      maxPage: 0,

      isWideA: true,
      isWideB: true,
      isWideC: true,
      isWideD: true,
      isWideE: true,

      initGridLeftLen: 1125,
      currentGridLeftLen: 0,
      wideALen: 80,

      totalNum: 0,
    };

    this.judgeScheduleDate = this.judgeScheduleDate.bind(this);
    this.judgeScheduleDateUpdate = this.judgeScheduleDateUpdate.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleSearchBundle = this.handleSearchBundle.bind(this);
    this.handleSearchBoxHeightChange = this.handleSearchBoxHeightChange.bind(this);
    this.isScrollable = this.isScrollable.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.fillList = this.fillList.bind(this);
    this.download = this.download.bind(this);
    this.showUpdatePanel = this.showUpdatePanel.bind(this);
    this.setTooltip = this.setTooltip.bind(this);
    this.handleOnMouse = this.handleOnMouse.bind(this);
    this.isScheduleDate = this.isScheduleDate.bind(this);
  }

  componentDidMount() {
    this.resizeTimer = 0;
    window.addEventListener("resize", this.handleResize);

    const urlState = restoreState();
    const { getOptions, validated } = this.props;
    let isSearch = false;
    if (
      validated &&
      (storageManager.getConstructionItem("scheduleSearchParams") !== null || (urlState && urlState.hasQuery))
    ) {
      isSearch = true;
    }

    this.handleSearchBoxHeightChange();
    addHeightResizeListener(this.searchBox, this.handleSearchBoxHeightChange);

    getOptions((data) => {
      this.setState(
        {
          options: data,
          switchTime: data.extension_config.schedule.schedule_date_switch_time,
          editEndTime: data.extension_config.schedule.edit_end_time,
          field1ListIds: data.search_condition_options
            .filter((option) => {
              return option.target == "field1";
            })
            .shift()
            .options.map((user) => {
              return { id: parseInt(user.id), name: user.name };
            }),
          field7ListIds: data.search_condition_options
            .filter((option) => {
              return option.target == "field7";
            })
            .shift()
            .options.map((user) => {
              return { id: parseInt(user.id), name: user.name };
            }),
          field8ListIds: data.search_condition_options
            .filter((option) => {
              return option.target == "field8";
            })
            .shift()
            .options.map((user) => {
              return { id: parseInt(user.id), name: user.name };
            }),
          userListIds: data.search_condition_options
            .filter((option) => {
              return option.target == "user_id";
            })
            .shift()
            .options.filter((opt) => {
              return parseInt(opt.id) > 0;
            })
            .map((user) => {
              return { id: parseInt(user.id), company_id: user.company_id, name: user.name };
            }),
          groupListIds: data.search_condition_options
            .filter((option) => {
              return option.target == "group_id";
            })
            .shift()
            .options.filter((opt) => {
              return parseInt(opt.id) > 0;
            })
            .map((group) => {
              return { id: parseInt(group.id), company_id: group.company_id, name: group.name };
            }),
        },
        () => {
          if (isSearch) {
            this.props.changeScheduleDate(this.judgeScheduleDate());
            this.handleSearch(this.judgeScheduleDate());
          }
        }
      );
    });
  }

  componentDidUpdate(prevProps) {
    const { validated } = this.props;
    if (!validated) {
      return;
    }
    const urlState = restoreState();
    const notFirst =
      storageManager.getConstructionItem("scheduleSearchParams") !== null || (urlState && urlState.hasQuery);
    if (!prevProps.validated && validated && notFirst) {
      this.handleSearch(false);
    }

    if (this.note1Textarea) {
      this.note1Textarea.focus();
    }
    if (this.note2Textarea) {
      this.note2Textarea.focus();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    removeHeightResizeListener(this.searchBox);

    this.props.revertLocalCondition();
  }

  //予定日の補正
  judgeScheduleDate() {
    if (this.state.switchTime.trim().length < 1) {
      return moment().format("YYYY/MM/DD");
    }

    const switchTime = moment(moment().format("YYYY-MM-DD") + " " + ("0" + this.state.switchTime).slice(-5) + ":00");
    const scheduleDate =
      moment() > switchTime ? moment().add(1, "days").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");

    return scheduleDate;
  }

  judgeScheduleDateUpdate() {
    const scheduleDateList1 = [
      { id: 0, value: moment().add(1, "days").format("YYYY-MM-DD") },
      { id: 1, value: moment().add(2, "days").format("YYYY-MM-DD") },
      { id: 2, value: moment().add(3, "days").format("YYYY-MM-DD") },
    ];

    const scheduleDateList2 = [
      { id: 0, value: moment().add(2, "days").format("YYYY-MM-DD") },
      { id: 1, value: moment().add(3, "days").format("YYYY-MM-DD") },
    ];

    const editEndTime = moment(moment().format("YYYY-MM-DD") + " " + ("0" + this.state.editEndTime).slice(-5) + ":00");

    if (moment() > editEndTime) {
      return scheduleDateList2;
    } else {
      return scheduleDateList1;
    }
  }

  handleSearchBoxHeightChange() {
    const maxHeight = getTableBodyHeight("schedule_eneos_kawasaki", this.searchBox, this.theader);
    this.setState({ tableBodyMaxHeight: "calc(100vh - " + maxHeight + "px)" });
  }

  handleResize() {
    if (this.resizeTimer > 0) {
      clearTimeout(this.resizeTimer);
    }

    const callback = () => {
      this.handleSearchBoxHeightChange();
      this.fillList();
    };

    this.resizeTimer = setTimeout(callback, 200);
  }

  toggleSearch() {
    const callback = () => {
      this.handleSearchBoxHeightChange();
      addHeightResizeListener(this.searchBox, this.handleSearchBoxHeightChange);
      this.fillList();
    };
    this.setState({ showSearch: !this.state.showSearch }, () => setTimeout(callback, 100));
  }

  showTable(callback) {
    this.setState({ showTable: true }, () => {
      if (callback !== undefined) {
        // コールバックにしてもsetState直後はDOMに反映していない
        // ことがあるため、時間差で実行する
        setTimeout(callback, 100);
      }
    });
  }

  isScrollable() {
    const container = this.tbody;

    if (!container) {
      return false;
    }

    const diff = container.clientHeight - container.scrollHeight;

    return diff !== 0;
  }

  fillList() {
    const { showTable, end } = this.state;

    if (!showTable || end) {
      return;
    }
  }

  handleSearch(sche_date) {
    this.setState({ isEmpty: true });

    const {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      field1Ids,
      field7Ids,
      field8Ids,
      userIds,
      groupIds,
      scheduleDate,
      itemText,
      processText,
      search,
    } = this.props;

    const params = {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      field1Ids,
      field7Ids,
      field8Ids,
      userIds,
      groupIds,
      scheduleDate: sche_date || scheduleDate,
      itemText,
      processText,
      start: 1,
      limit: this.state.limit,
    };

    search(params, (data) => {
      this.setState(
        {
          isEmpty: data.list.length === 0,
          maxPage: Math.ceil(data.total_num / this.state.limit),
          currentPage: Math.ceil(params.start / this.state.limit),
          totalNum: data.total_num,
          requestParam: {
            category_id: categoryId,
            area_id: areaIds,
            device_id: deviceIds,
            facility_management_id: facilityManagementIds,
            construction_management_id: constructionManagementIds,
            primary_charge_id: primaryChargeIds,
            field1: field1Ids,
            field7: field7Ids,
            field8: field8Ids,
            user_id: userIds,
            group_id: groupIds,
            item_text: itemText,
            process_text: processText,
            schedule_date: scheduleDate,
          },
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  handleClear() {
    const { changeScheduleDate, search, clearSearch, masters } = this.props;

    const categoryId = masters.categories[0].category_id;
    const scheduleDate = this.judgeScheduleDate();

    this.setState({ start: 1, showTable: false });

    const params = {
      areaIds: [],
      deviceIds: [],
      categoryId: categoryId,
      facilityManagementIds: [],
      constructionManagementIds: [],
      primaryChargeIds: [],
      field1Ids: [],
      field7Ids: [],
      field8Ids: [],
      userIds: [],
      groupIds: [],
      scheduleDate: scheduleDate,
      itemText: "",
      processText: "",
      start: 1,
      limit: this.state.limit,
    };

    clearSearch(params);
    changeScheduleDate(this.judgeScheduleDate());

    search(params, (data) => {
      this.setState(
        {
          start: data.list.length + 1,
          end: data.list.length < this.state.limit,
          isEmpty: data.list.length === 0,
          maxPage: Math.ceil(data.total_num / this.state.limit),
          currentPage: Math.ceil(params.start / this.state.limit),
          totalNum: data.total_num,
          requestParam: {
            category_id: categoryId,
            area_id: [],
            device_id: [],
            facility_management_id: [],
            construction_management_id: [],
            primary_charge_id: [],
            field1: [],
            field7: [],
            field8: [],
            user_id: [],
            group_id: [],
            item_text: "",
            process_text: "",
            schedule_date: scheduleDate,
          },
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  handleSearchPager(startPos = 1) {
    this.setState({ isEmpty: true });
    const { clearSearch, changeScheduleDate, search } = this.props;

    const { requestParam } = this.state;
    const params = {
      areaIds: requestParam.area_id,
      deviceIds: requestParam.device_id,
      categoryId: requestParam.category_id,
      facilityManagementIds: requestParam.facility_management_id,
      constructionManagementIds: requestParam.construction_management_id,
      primaryChargeIds: requestParam.primary_charge_id,
      field1Ids: requestParam.field1,
      field7Ids: requestParam.field7,
      field8Ids: requestParam.field8,
      userIds: requestParam.user_id,
      groupIds: requestParam.group_id,
      scheduleDate: requestParam.schedule_date,
      itemText: requestParam.item_text,
      processText: requestParam.process_text,
      start: startPos,
      limit: this.state.limit,
    };

    clearSearch(params);
    changeScheduleDate(this.state.requestParam.schedule_date);

    search(params, (data) => {
      this.setState(
        {
          isEmpty: data.list.length === 0,
          maxPage: Math.ceil(data.total_num / this.state.limit),
          currentPage: Math.ceil(params.start / this.state.limit),
          totalNum: data.total_num,
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  handleSearchBundle() {
    this.setState({ isEmpty: true });
    const { clearSearch, changeScheduleDate, search, showAlert, hideAlert, t } = this.props;

    const params = {
      areaIds: this.state.requestParam.area_id,
      deviceIds: this.state.requestParam.device_id,
      categoryId: this.state.requestParam.category_id,
      facilityManagementIds: this.state.requestParam.facility_management_id,
      constructionManagementIds: this.state.requestParam.construction_management_id,
      primaryChargeIds: this.state.requestParam.primary_charge_id,
      field1Ids: this.state.requestParam.field1,
      field7Ids: this.state.requestParam.field7,
      field8Ids: this.state.requestParam.field8,
      userIds: this.state.requestParam.user_id,
      groupIds: this.state.requestParam.group_id,
      scheduleDate: this.state.requestParam.schedule_date,
      itemText: this.state.requestParam.item_text,
      processText: this.state.requestParam.process_text,
      start: 1 + (this.state.currentPage - 1) * this.state.limit,
      limit: this.state.limit,
    };

    clearSearch(params);
    changeScheduleDate(this.state.requestParam.schedule_date);

    search(params, (data) => {
      if (this.state.totalNum !== data.total_num) {
        if (this.state.currentPage > 1) {
          showAlert(t("confirmation"), [t("return_first_page")], () => {
            hideAlert();
            search({ ...params, start: 1 }, (data) => {
              this.setState(
                {
                  isEmpty: data.list.length === 0,
                  maxPage: Math.ceil(data.total_num / this.state.limit),
                  currentPage: 1,
                },
                () => {
                  this.showTable(this.fillList);
                }
              );
            });
          });
        } else {
          this.setState(
            {
              isEmpty: data.list.length === 0,
              maxPage: Math.ceil(data.total_num / this.state.limit),
            },
            () => {
              this.showTable(this.fillList);
            }
          );
        }
      } else {
        this.setState(
          {
            isEmpty: data.list.length === 0,
            totalNum: data.total_num,
            maxPage: Math.ceil(data.total_num / this.state.limit),
          },
          () => {
            this.showTable(this.fillList);
          }
        );
      }
    });
  }

  syncScroll(e) {
    this.rightHeaderContainer.scrollLeft = e.target.scrollLeft;
    this.leftBodyContainer.scrollTop = e.target.scrollTop;

    const container = this.rightBodyContainer;
    const containerHeight = container.clientHeight;
    const contentHeight = container.scrollHeight;
    const scrollTop = container.scrollTop;

    const { offset, maxLength, schedules } = this.state;
    const { items } = this.props;
    const rowHeight = 80;
    const hiddenBottomHeight =
      items.length - (offset + maxLength) < 0 ? 0 : (schedules.length - (offset + maxLength)) * rowHeight;

    const diff = contentHeight - (containerHeight + scrollTop + hiddenBottomHeight);

    const top = scrollTop < containerHeight + offset * rowHeight;
    const bottom = diff < containerHeight * 0.6;

    if (top) {
      this.decreaseOffset();
    }

    if (bottom && items.length > offset + maxLength) {
      this.increaseOffset();
    } else if (diff <= 10 && !this.state.end) {
      // this.handleSearchInfinite();
    }
  }

  increaseOffset() {
    const { offset, maxLength, limit } = this.state;
    if (this.props.rows.length >= maxLength * 0.5) {
      const nextOffset = offset + limit;
      this.setState({ offset: nextOffset });
    }
  }

  decreaseOffset() {
    const { offset, limit } = this.state;
    if (offset !== 0) {
      const nextOffset = offset < limit * 2 ? 0 : offset - limit;
      this.setState({ offset: nextOffset });
    }
  }

  syncWheel(e) {
    this.rightBodyContainer.scrollTop += e.deltaY;
    this.leftBodyContainer.scrollTop += e.deltaY;
  }

  toggleWide() {
    this.setState({ isWide: !this.state.isWide });
  }

  toggleWideA() {
    const { initGridLeftLen } = this.state;

    const current = this.state.isWideA ? initGridLeftLen - 80 : initGridLeftLen + 80;
    this.setState({ isWideA: !this.state.isWideA, initGridLeftLen: current });
  }

  toggleWideB() {
    const { initGridLeftLen } = this.state;

    const current = this.state.isWideB ? initGridLeftLen - 80 : initGridLeftLen + 80;
    this.setState({ isWideB: !this.state.isWideB, initGridLeftLen: current });
  }

  toggleWideC() {
    const { initGridLeftLen, isWideC } = this.state;

    this.setState({ isWideC: !isWideC, initGridLeftLen: isWideC ? initGridLeftLen - 300 : initGridLeftLen + 300 });
  }

  toggleWideD() {
    const { isWideD } = this.state;

    this.setState({ isWideD: !isWideD });
  }

  toggleWideE() {
    const { isWideE } = this.state;

    this.setState({ isWideE: !isWideE });
  }

  showDropdown(name) {
    this.setState({ activeButton: name });
  }

  hideDropdown() {
    this.setState({ activeButton: "" });
  }

  showScheduleEditor(target, column, processText, scheduleId, optionId, timestamp) {
    this.setState({
      showEditor: true,
      target: target,
      processText: processText,
      scheduleId: scheduleId,
      optionId: optionId || 0,
      panelText: column,
      timeStamp: timestamp,
      selectValues: this.state.options.bulk_update_options
        .filter((option) => {
          return option.target == target;
        })
        .shift().options,
    });
  }

  showScheduleEditorNew(target, columnName, item) {
    this.setState({
      showEditor: true,
      target: target,
      panelText: columnName,
      item: item,
      selectValues: this.state.options.bulk_update_options
        .filter((option) => {
          return option.target === target;
        })
        .shift().options,
    });
  }

  showScheduleEditorBundle(bulkTarget, column) {
    this.setState({
      showUpdateBundle: true,
      target: bulkTarget,
      panelText: column,
      selectValues: this.state.options.bulk_update_options
        .filter((option) => {
          return option.target == bulkTarget;
        })
        .shift().options,
    });
  }

  showScheduleEditorNoteBundle(bulkTarget, column) {
    this.setState({
      showUpdateBundle: true,
      target: bulkTarget,
      panelText: column,
    });
  }

  showUpdatePanel() {
    this.setState({
      showUpdatePanel: true,
    });
  }

  hideScheduleEditor() {
    this.setState({
      showUpdateBundle: false,
      showEditor: false,
      showUpdatePanel: false,
    });
  }

  download() {
    this.setState({ downloading: true });

    const {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      field1Ids,
      field7Ids,
      field8Ids,
      userIds,
      groupIds,
      itemText,
      processText,
      scheduleDate,
    } = this.props;

    const params = {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      field1Ids,
      field7Ids,
      field8Ids,
      userIds,
      groupIds,
      scheduleDate,
      itemText,
      processText,
    };

    const fallback = () => this.setState({ downloading: false });

    const constId = util.getConstructionId();
    const userId = util.getUserId();

    this.props.download(
      params,
      (blob, fileName) => {
        const fname = !fileName
          ? "schedule_" + userId + "_" + constId + "_" + moment().format("YYYY-MM-DD_HHmmssSSS") + ".xlsx"
          : fileName;

        this.setState({ downloadUrl: URL.createObjectURL(blob), downloadName: fname }, () => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fname);
          } else {
            const evt = util.createClickEvent();
            this.btnDownload.dispatchEvent(evt);
          }
          this.setState({ downloading: false });
        });
      },
      fallback
    );
  }

  isScheduleDate(target, scheduleDate) {
    const config = this.state.options.extension_config.field_options
      .filter((option) => {
        return option.target == target;
      })
      .shift();
    const { edit_end_days, edit_end_time } = config;

    if (edit_end_days === "" || scheduleDate === "") {
      return true;
    }

    const schedule =
      moment(scheduleDate, "YYYY-MM-DD").add(parseInt(edit_end_days), "days").format("YYYY-MM-DD") +
      " " +
      ("0" + edit_end_time).slice(-5) +
      ":00";

    return moment().isBefore(schedule, "minute");
  }

  omitText(text, validLength) {
    if (text && text.length > validLength) {
      return `${text.substr(0, validLength)}...`;
    }

    return text;
  }

  isOmit(text, validLength) {
    return text && text.length > validLength ? true : false;
  }

  setTooltip(data) {
    this.setState({ tooltip: data });
  }

  handleOnMouse(columnValue, validLength) {
    if (this.isOmit(columnValue, validLength)) {
      this.setTooltip({
        value: columnValue,
      });
      ReactTooltip.rebuild();
    } else {
      this.setTooltip(null);
    }
  }

  beginEditTextarea(stateName, id) {
    if (!this.state.isExcluse) {
      this.setState({
        ...this.state,
        [stateName]: id,
        isExcluse: true,
      });
    }
  }

  endEditTextarea(stateName, target, comment, row) {
    const { showAlert, hideAlert, t } = this.props;

    const data = {
      schedule_id: row.schedule_id,
      target: target,
      value: comment,
      update_date: row[target + "_update_date"],
      timestamp: row.timestamp,
    };

    if (comment.length > 500) {
      showAlert(t("confirmation"), [t(target + "_is_too_long_500")], hideAlert);

      return;
    }

    this.props.updateItem(
      data,
      () => {
        this.setState({ [stateName]: null, isExcluse: false });
      },
      () => {
        this.setState({ [stateName]: null, isExcluse: false });
      }
    );
  }

  handleNextPage() {
    const _currentPage = this.state.currentPage;

    if (_currentPage < this.state.maxPage) {
      const startPos = 1 + _currentPage * this.state.limit;
      this.handleSearchPager(startPos);
    }
  }

  handlePrevPage() {
    const _currentPage = this.state.currentPage;

    if (_currentPage > 1) {
      const startPos = 1 + (_currentPage - 2) * this.state.limit;
      this.handleSearchPager(startPos);
    }
  }

  render() {
    const titleClassName = `toggle icon-keyboard_arrow_up ${!this.state.showSearch ? "closed" : ""}`;
    const {
      field1ListIds,
      field7ListIds,
      field8ListIds,
      userListIds,
      groupListIds,
      isWideA,
      isWideB,
      isWideC,
      isWideD,
      isWideE,
    } = this.state;
    const {
      masters,
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      field1Ids,
      field7Ids,
      field8Ids,
      userIds,
      groupIds,
      scheduleDate,
      itemText,
      processText,
      changeArea,
      changeDevice,
      changeFacilityManagement,
      changeCategory,
      changeConstructionManagement,
      changePrimaryCharge,
      changeField1,
      changeField7,
      changeField8,
      changeUser,
      changeGroup,
      changeScheduleDate,
      changeItemText,
      changeProcessText,
      t,
      items,
      bulkRoles,
      scheduleUpdateDate,
      firstScheduleDate,
      fetching,
      isError,
      roles,
    } = this.props;

    const options = {
      users: userListIds.map((user) => {
        return { user_id: user.id, company_id: user.company_id, user_name: user.name };
      }),
      groups: groupListIds.map((group) => {
        return { group_id: group.id, company_id: group.company_id, group_name: group.name };
      }),
    };

    return (
      <div>
        <div className="contents">
          <div className="inner schedule">
            <div className="tbl-top-area mt-15 clearfix">
              <div className="tbl-top">
                <h1 className="page-ttl">
                  {t("schedule_list")}
                  <span
                    data-test-id="button-schedule-toggle-search"
                    className={titleClassName}
                    onClick={() => this.toggleSearch()}
                  ></span>
                </h1>
                {isValid(roles, "schedule_eneos_kawasaki", "create") && (
                  <button
                    data-test-id="button-schedule-search"
                    className="btn btn-blue w-170 schedule-reload"
                    onClick={this.showUpdatePanel}
                  >
                    <i className="icon icon-reload"></i>
                    {t("update_schedule")}
                  </button>
                )}
              </div>
            </div>
            {this.state.showSearch && (
              <div className="search-box" ref={(node) => (this.searchBox = node)}>
                <div className="form-row">
                  <div className="form-group w-250 scheduled-date">
                    <span className="form-label">{t("scheduled_date")}</span>
                    <DatePicker
                      wrapperClassName="w-170"
                      selected={scheduleDate === "" ? null : moment(scheduleDate, "YYYY/MM/DD").toDate()}
                      dateFormat="yyyy/MM/dd"
                      locale={t("calender_locale")}
                      onChange={(date) =>
                        changeScheduleDate(date === null ? "" : moment(date).format("YYYY-MM-DD").toString())
                      }
                    />
                  </div>
                  <div className="form-group w-280">
                    <span className="form-label">{t("area")}</span>
                    <div className="w-170 d-ib ta-l">
                      <MasterSelect
                        prefix="area"
                        isMulti={true}
                        options={masters.areas}
                        value={areaIds}
                        onChange={changeArea}
                      />
                    </div>
                  </div>
                  <div className="form-group w-290">
                    <span className="form-label">{t("device")}</span>
                    <div className="w-170 d-ib ta-l">
                      <MasterSelect
                        prefix="device"
                        isMulti={true}
                        options={masters.devices}
                        value={deviceIds}
                        onChange={changeDevice}
                      />
                    </div>
                  </div>
                  <div className="form-group w-290">
                    <span className="form-label">{t("machines_category")}</span>
                    <div className="w-170 d-ib ta-l">
                      <MasterSelect
                        prefix="category"
                        isMulti={false}
                        options={masters.categories}
                        value={categoryId}
                        onChange={changeCategory}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group w-250">
                    <span className="form-label">{t("facility_management")}</span>
                    <div className="w-170 d-ib ta-l">
                      <MasterSelect
                        prefix="facility_management"
                        isMulti={true}
                        options={masters.facilityManagements}
                        value={facilityManagementIds}
                        onChange={changeFacilityManagement}
                      />
                    </div>
                  </div>
                  <div className="form-group w-280">
                    <span className="form-label">{t("construction_management")}</span>
                    <div className="w-170 d-ib ta-l">
                      <MasterSelect
                        prefix="construction_management"
                        isMulti={true}
                        options={masters.constructionManagements}
                        value={constructionManagementIds}
                        onChange={changeConstructionManagement}
                      />
                    </div>
                  </div>
                  <div className="form-group w-290">
                    <span className="form-label">{t("primary_charge")}</span>
                    <div className="w-170 d-ib ta-l">
                      <MasterSelect
                        prefix="primary_charge"
                        isMulti={true}
                        options={masters.primaryCharges}
                        value={primaryChargeIds}
                        onChange={changePrimaryCharge}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row-flex">
                  <div className="form-group-flex w-250">
                    <span className="form-label">{t("schedule_contact")}</span>
                    <div className="w-170 d-ib ta-l">
                      <ChargeSelect
                        masters={options}
                        userId={userIds}
                        groupId={groupIds}
                        isMulti={true}
                        onChange={(e) => {
                          const users = e ? e.filter((d) => d.category === "user") : [];
                          const groups = e ? e.filter((d) => d.category === "group") : [];

                          changeUser(users.map((u) => u.value));
                          changeGroup(groups.map((g) => g.value));
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group-flex w-280">
                    <span className="form-label">{t("construction_item_name")}</span>
                    <input
                      data-test-id="text-schedule-item-name"
                      type="text"
                      className="form-control w-170"
                      value={itemText}
                      onChange={(e) => changeItemText(e.target.value)}
                    />
                  </div>
                  <div className="form-group-flex w-290">
                    <span className="form-label">{t("content")}</span>
                    <input
                      data-test-id="text-schedule-process-name"
                      type="text"
                      className="form-control w-170"
                      value={processText}
                      onChange={(e) => changeProcessText(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group w-250">
                    <span className="form-label">{t("application")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        styles={styles}
                        isMulti={true}
                        options={field1ListIds.map((user) => ({ value: user.id, label: user.name }))}
                        onChange={(e) => changeField1(e)}
                        value={field1ListIds
                          .filter((user) => _.includes(field1Ids, user.id))
                          .map((user) => ({ value: user.id, label: user.name }))}
                      />
                    </div>
                  </div>
                  <div className="form-group w-280">
                    <span className="form-label">{t("approval")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        styles={styles}
                        isMulti={true}
                        options={field7ListIds.map((user) => ({ value: user.id, label: user.name }))}
                        onChange={(e) => changeField7(e)}
                        value={field7ListIds
                          .filter((user) => _.includes(field7Ids, user.id))
                          .map((user) => ({ value: user.id, label: user.name }))}
                      />
                    </div>
                  </div>
                  <div className="form-group w-290">
                    <span className="form-label">{t("permission")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        styles={styles}
                        isMulti={true}
                        options={field8ListIds.map((user) => ({ value: user.id, label: user.name }))}
                        onChange={(e) => changeField8(e)}
                        value={field8ListIds
                          .filter((user) => _.includes(field8Ids, user.id))
                          .map((user) => ({ value: user.id, label: user.name }))}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row mb-0">
                  <div className="form-group btn-area pull-right mt-0">
                    <button data-test-id="button-schedule-reset" className="btn btn-gray" onClick={this.handleClear}>
                      {t("reset")}
                    </button>
                    <button
                      data-test-id="button-schedule-search"
                      className="btn btn-blue"
                      onClick={() => this.handleSearch(false)}
                    >
                      {t("search")}
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className={`tbl-schedules ${fetching ? "loading loading--list" : ""}`}>
              {items.length === 0
                ? this.state.showTable && !isError && !fetching && <p className="empty-message">{t("no_data")}</p>
                : this.state.showTable &&
                  !isError && (
                    <React.Fragment>
                      <div className="tbl-top-area clearfix relative">
                        <div className="tbl-top-left">
                          <div className="btn-dropdown-area" onMouseLeave={() => this.hideDropdown()}>
                            <button
                              data-test-id="button-summary-file-export"
                              className="btn btn-light-blue btn-dropdown"
                              onClick={() => this.showDropdown("list")}
                            >
                              <span className="icon icon-get_app" />
                              {t("file_export")}
                            </button>
                            {this.state.activeButton === "list" && (
                              <ul
                                className="dropdown-menu"
                                style={{ dispkay: "none" }}
                                onClick={() => this.hideDropdown()}
                              >
                                <li data-test-id="button-summary-list" onClick={this.download}>
                                  <img src="./img/icon_xls.svg" alt="XLS" className="icon-file" />
                                  {t("table")}
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                        <span className="update-date">
                          {t("updated_date")}
                          {moment(scheduleUpdateDate).format("YYYY/MM/DD HH:mm")}
                        </span>
                        <div className={`${this.state.downloading ? "loading-small loading-small-download" : ""}`} />
                      </div>
                      <div
                        className="tbl-area tbl-schedules-header tbl-head-adjusted"
                        ref={(node) => (this.theader = node)}
                      >
                        <div
                          id="grid-schedule"
                          className={`grid-container grid-container-schedule grid-container-progress`}
                          style={{
                            height: this.state.tableBodyMaxHeight,
                            gridTemplateColumns: this.state.initGridLeftLen + "px 1fr",
                            msGridColumns: this.state.initGridLeftLen + "px 1fr",
                          }}
                        >
                          <div className="grid-left-header">
                            <table className="tbl-basic tbl-data list-table-row">
                              <thead className="schedule">
                                <tr className="addHeight">
                                  <th className="col2 pos-rel">
                                    {t("device_name")}
                                    <br />
                                    {t("include_area")}
                                  </th>
                                  <th className="col3">{t("schedule_category_name")}</th>
                                  <th className={`col4 ${this.state.isWideA ? "" : "d-n"}`}>
                                    {t("construction_charge_tm")}
                                    <button
                                      data-test-id="button-matrix-collapse"
                                      className={`grid-btn-collapse ${this.state.isWideA ? "" : "d-n"}`}
                                      onClick={() => this.toggleWideA()}
                                    >
                                      {"◀︎"}
                                    </button>
                                  </th>
                                  <th className="col5">
                                    {t("company")}
                                    <button
                                      data-test-id="button-matrix-collapse"
                                      className={`grid-btn-collapse ${this.state.isWideA ? "d-n" : ""}`}
                                      onClick={() => this.toggleWideA()}
                                    >
                                      {"▶︎"}
                                    </button>
                                  </th>
                                  <th className={`col7 ${this.state.isWideB ? "" : "d-n"}`}>
                                    {t("name_contact_person")}
                                    <button
                                      data-test-id="button-matrix-collapse"
                                      className={`grid-btn-collapse ${this.state.isWideB ? "" : "d-n"}`}
                                      onClick={() => this.toggleWideB()}
                                    >
                                      {"◀︎"}
                                    </button>
                                  </th>
                                  <th className="col8">
                                    {t("construction_item_name")}
                                    <button
                                      data-test-id="button-matrix-collapse"
                                      className={`grid-btn-collapse ${this.state.isWideB ? "d-n" : ""}`}
                                      onClick={() => this.toggleWideB()}
                                    >
                                      {"▶︎"}
                                    </button>
                                  </th>
                                  <th className="col10">{t("content")}</th>
                                  <th className="col9">{t("limited_work")}</th>
                                  {isValidRole(bulkRoles, 1) && this.isScheduleDate("priority", firstScheduleDate) ? (
                                    <th className={`col16 header-bottom grid-cell-clickable ${isWideC ? "" : "d-n"}`}>
                                      <button
                                        data-test-id="button-matrix-collapse"
                                        className={`grid-btn-collapse ${isWideC ? "" : "d-n"}`}
                                        onClick={() => this.toggleWideC()}
                                      >
                                        {"◀︎"}
                                      </button>
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div
                                            className="grid-cell-inner"
                                            onClick={() => this.showScheduleEditorBundle("priority", t("priority"))}
                                          >
                                            <span className="title rotate left-prior">{t("priority")}</span>
                                            <button
                                              className={`grid-icon grid-icon-edit icon-edit icon-mode_edit`}
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className={`col16 ${isWideC ? "" : "d-n"}`}>
                                      <button
                                        data-test-id="button-matrix-collapse"
                                        className={`grid-btn-collapse ${isWideC ? "" : "d-n"}`}
                                        onClick={() => this.toggleWideC()}
                                      >
                                        {"◀︎"}
                                      </button>
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="title-disp rotate left-prior">{t("priority")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 2) && this.isScheduleDate("check_type", firstScheduleDate) ? (
                                    <th
                                      className={`col17 header-bottom grid-cell-clickable ${isWideC ? "" : "d-n"}`}
                                      onClick={() => this.showScheduleEditorBundle("check_type", t("new_keep"))}
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner vertical">
                                            <span className="title rotate left-new">{t("new_keep")}</span>
                                            <button
                                              className={`grid-icon grid-icon-edit icon-edit icon-mode_edit`}
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className={`col17 ${isWideC ? "" : "d-n"}`}>
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner vertical">
                                            <span className="title-disp rotate left-new">{t("new_keep")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 3) &&
                                  this.isScheduleDate("checkpoint1", firstScheduleDate) ? (
                                    <th
                                      className={`col17 header-bottom grid-cell-clickable ${isWideC ? "" : "d-n"}`}
                                      onClick={() => this.showScheduleEditorBundle("checkpoint1", t("fire"))}
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner vertical">
                                            <span className="title rotate left-fire">{t("fire")}</span>
                                            <button
                                              className={`grid-icon grid-icon-edit icon-edit icon-mode_edit`}
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className={`col17 ${isWideC ? "" : "d-n"}`}>
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner vertical">
                                            <span className="title-disp rotate left-fire">{t("fire")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 4) &&
                                  this.isScheduleDate("checkpoint2", firstScheduleDate) ? (
                                    <th
                                      className={`col17 header-bottom grid-cell-clickable ${isWideC ? "" : "d-n"}`}
                                      onClick={() => this.showScheduleEditorBundle("checkpoint2", t("container_job"))}
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner vertical">
                                            <span className="title rotate left-inside">{t("container_job")}</span>
                                            <button
                                              className={`grid-icon grid-icon-edit icon-edit icon-mode_edit`}
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className={`col17 ${isWideC ? "" : "d-n"}`}>
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner vertical">
                                            <span className="title-disp rotate left-inside">{t("container_job")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 5) &&
                                  this.isScheduleDate("checkpoint3", firstScheduleDate) ? (
                                    <th
                                      className={`col17 header-bottom grid-cell-clickable ${isWideC ? "" : "d-n"}`}
                                      onClick={() => this.showScheduleEditorBundle("checkpoint3", t("she_hirisk"))}
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner vertical">
                                            <span className="title rotate left-she">{t("she_hirisk")}</span>
                                            <button
                                              className={`grid-icon grid-icon-edit icon-edit icon-mode_edit`}
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className={`col17 ${isWideC ? "" : "d-n"}`}>
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner vertical">
                                            <span className="title-disp rotate left-she">{t("she_hirisk")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 6) &&
                                  this.isScheduleDate("checkpoint4", firstScheduleDate) ? (
                                    <th
                                      className={`col17 header-bottom grid-cell-clickable ${isWideC ? "" : "d-n"}`}
                                      onClick={() => this.showScheduleEditorBundle("checkpoint4", t("airline"))}
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner vertical">
                                            <span className="title rotate left-air">{t("airline")}</span>
                                            <button
                                              className={`grid-icon grid-icon-edit icon-edit icon-mode_edit`}
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className={`col17 ${isWideC ? "" : "d-n"}`}>
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner vertical">
                                            <span className="title-disp rotate left-air">{t("airline")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 7) &&
                                  this.isScheduleDate("checkpoint5", firstScheduleDate) ? (
                                    <th
                                      className={`col17 header-bottom grid-cell-clickable ${isWideC ? "" : "d-n"}`}
                                      onClick={() => this.showScheduleEditorBundle("checkpoint5", t("night_job"))}
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner vertical">
                                            <span className="title rotate left-night">{t("night_job")}</span>
                                            <button
                                              className={`grid-icon grid-icon-edit icon-edit icon-mode_edit`}
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className={`col17 ${isWideC ? "" : "d-n"}`}>
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner vertical">
                                            <span className="title-disp rotate left-night">{t("night_job")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  <th className="col9">
                                    {t("complement")}
                                    <button
                                      data-test-id="button-matrix-collapse"
                                      className={`grid-btn-collapse ${this.state.isWideC ? "d-n" : ""}`}
                                      onClick={() => this.toggleWideC()}
                                    >
                                      {"▶︎"}
                                    </button>
                                  </th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                          {!this.state.isEmpty && (
                            <div
                              className="grid-left-body"
                              ref={(el) => (this.leftBodyContainer = el)}
                              onWheel={(e) => this.syncWheel(e)}
                            >
                              <table className="tbl-basic tbl-data list-table-row data-schedule grid-table-left-body-schedule">
                                <tbody className="schedule">
                                  {items.map((item, index) => {
                                    const {
                                      device_name,
                                      category_name,
                                      construction_management_name,
                                      primary_charge_name,
                                      item_user_name,
                                      item_name,
                                      schedule_id,
                                      process_text,
                                      schedule_date,
                                      roles,
                                    } = item;

                                    return (
                                      <tr key={index}>
                                        <td
                                          className="col2"
                                          data-tip
                                          data-for="schedule-full-text"
                                          onMouseEnter={() => this.handleOnMouse(device_name, 15)}
                                        >
                                          <div className="grid-cell-wrap">
                                            <div className="grid-cell-con">
                                              <div className="grid-cell-inner txt-left">
                                                {this.omitText(device_name, 15)}
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          className="col3"
                                          data-tip
                                          data-for="schedule-full-text"
                                          onMouseEnter={() => this.handleOnMouse(category_name, 15)}
                                        >
                                          <div className="grid-cell-wrap">
                                            <div className="grid-cell-con">
                                              <div className="grid-cell-inner txt-left">
                                                {this.omitText(category_name, 15)}
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          className={`col4 ${this.state.isWideA ? "" : "d-n"}`}
                                          data-tip
                                          data-for="schedule-full-text"
                                          onMouseEnter={() => this.handleOnMouse(construction_management_name, 15)}
                                        >
                                          <div className="grid-cell-wrap">
                                            <div className="grid-cell-con">
                                              <div className="grid-cell-inner txt-left">
                                                {this.omitText(construction_management_name, 15)}
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          className="col5"
                                          data-tip
                                          data-for="schedule-full-text"
                                          onMouseEnter={() => this.handleOnMouse(primary_charge_name, 13)}
                                        >
                                          <div className="grid-cell-wrap">
                                            <div className="grid-cell-con">
                                              <div className="grid-cell-inner txt-left">
                                                {this.omitText(primary_charge_name, 13)}
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          className={`col7 ${this.state.isWideB ? "" : "d-n"}`}
                                          data-tip
                                          data-for="schedule-full-text"
                                          onMouseEnter={() => this.handleOnMouse(item_user_name, 15)}
                                        >
                                          <div className="grid-cell-wrap">
                                            <div className="grid-cell-con">
                                              <div className="grid-cell-inner txt-left">
                                                {this.omitText(item_user_name, 15)}
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          className="col8"
                                          data-tip
                                          data-for="schedule-full-text"
                                          onMouseEnter={() => this.handleOnMouse(item_name, 15)}
                                        >
                                          <div className="grid-cell-wrap">
                                            <div className="grid-cell-con">
                                              <div className="grid-cell-inner txt-left">
                                                {this.omitText(item_name, 15)}
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          className="col10"
                                          data-tip
                                          data-for="schedule-full-text"
                                          onMouseEnter={() => this.handleOnMouse(process_text, 48)}
                                        >
                                          <div className="grid-cell-wrap">
                                            <div className="grid-cell-con">
                                              <div className="grid-cell-inner txt-left">
                                                {this.omitText(process_text, 48)}
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        {/* 限定工事*/}
                                        {isValidRole(roles, 0) && this.isScheduleDate("note1", schedule_date) ? (
                                          schedule_id === this.state.editNote1 ? (
                                            <td
                                              className={`no-hover col9 grid-cell-clickable txt-center schedule-right-body`}
                                            >
                                              <div className="grid-cell-wrap">
                                                <div className="grid-cell-con">
                                                  <div className="grid-cell-inner schedule-note txt-left">
                                                    <textarea
                                                      className="grid-textarea txt-left p-5"
                                                      ref={(node) => (this.note1Textarea = node)}
                                                      onBlur={(e) =>
                                                        this.endEditTextarea("editNote1", "note1", e.target.value, item)
                                                      }
                                                      defaultValue={item.note1}
                                                    ></textarea>
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          ) : (
                                            <td
                                              className={`col9 grid-cell-clickable txt-center schedule-right-body`}
                                              onClick={() => this.beginEditTextarea("editNote1", schedule_id)}
                                              data-tip
                                              data-for="schedule-full-text"
                                              onMouseEnter={() => this.handleOnMouse(item.note1, 21)}
                                            >
                                              <div className="grid-cell-wrap">
                                                <div className="grid-cell-con">
                                                  <div className="grid-cell-inner schedule-note txt-left p-5">
                                                    {this.omitText(item.note1, 21)}
                                                    <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          )
                                        ) : (
                                          <td
                                            className={`col9 txt-center schedule-right-body`}
                                            data-tip
                                            data-for="schedule-full-text"
                                            onMouseEnter={() => this.handleOnMouse(item.note1, 21)}
                                          >
                                            <div className="grid-cell-wrap">
                                              <div className="grid-cell-con">
                                                <div className="grid-cell-inner schedule-note txt-left p-5">
                                                  {this.omitText(item.note1, 34)}
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        )}
                                        {isValidRole(roles, 1) && this.isScheduleDate("priority", schedule_date) ? (
                                          <td
                                            className={`col16 grid-cell-clickable txt-center schedule-right-body ${
                                              isWideC ? "" : "d-n"
                                            }`}
                                            onClick={() => this.showScheduleEditorNew("priority", "priority", item)}
                                          >
                                            <div className="grid-cell-wrap">
                                              <div className="grid-cell-con">
                                                <div className="grid-cell-inner">
                                                  {item.priority_name}
                                                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        ) : (
                                          <td
                                            className={`col16 txt-center schedule-right-body ${isWideC ? "" : "d-n"}`}
                                          >
                                            <div className="grid-cell-wrap">
                                              <div className="grid-cell-con">
                                                <div className="grid-cell-inner">{item.priority_name}</div>
                                              </div>
                                            </div>
                                          </td>
                                        )}
                                        {isValidRole(roles, 2) && this.isScheduleDate("check_type", schedule_date) ? (
                                          <td
                                            className={`col17 grid-cell-clickable txt-center schedule-right-body ${
                                              isWideC ? "" : "d-n"
                                            }`}
                                            onClick={() => this.showScheduleEditorNew("check_type", "new_keep", item)}
                                          >
                                            <div className="grid-cell-wrap">
                                              <div className="grid-cell-con">
                                                <div className="grid-cell-inner">
                                                  {item.check_type_name}
                                                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        ) : (
                                          <td
                                            className={`col17 txt-center schedule-right-body ${isWideC ? "" : "d-n"}`}
                                          >
                                            <div className="grid-cell-wrap">
                                              <div className="grid-cell-con">
                                                <div className="grid-cell-inner">{item.check_type_name}</div>
                                              </div>
                                            </div>
                                          </td>
                                        )}
                                        {isValidRole(roles, 3) && this.isScheduleDate("checkpoint1", schedule_date) ? (
                                          <td
                                            className={`col17 grid-cell-clickable txt-center schedule-right-body ${
                                              isWideC ? "" : "d-n"
                                            }`}
                                            onClick={() => this.showScheduleEditorNew("checkpoint1", "fire", item)}
                                          >
                                            <div className="grid-cell-wrap">
                                              <div className="grid-cell-con">
                                                <div className="grid-cell-inner">
                                                  {item.checkpoint1_name}
                                                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        ) : (
                                          <td
                                            className={`col17 txt-center schedule-right-body ${isWideC ? "" : "d-n"}`}
                                          >
                                            <div className="grid-cell-wrap">
                                              <div className="grid-cell-con">
                                                <div className="grid-cell-inner">{item.checkpoint1_name}</div>
                                              </div>
                                            </div>
                                          </td>
                                        )}
                                        {isValidRole(roles, 4) && this.isScheduleDate("checkpoint2", schedule_date) ? (
                                          <td
                                            className={`col17 grid-cell-clickable txt-center schedule-right-body ${
                                              isWideC ? "" : "d-n"
                                            }`}
                                            onClick={() =>
                                              this.showScheduleEditorNew("checkpoint2", "container_job", item)
                                            }
                                          >
                                            <div className="grid-cell-wrap">
                                              <div className="grid-cell-con">
                                                <div className="grid-cell-inner">
                                                  {item.checkpoint2_name}
                                                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        ) : (
                                          <td
                                            className={`col17 txt-center schedule-right-body ${isWideC ? "" : "d-n"}`}
                                          >
                                            <div className="grid-cell-wrap">
                                              <div className="grid-cell-con">
                                                <div className="grid-cell-inner">{item.checkpoint2_name}</div>
                                              </div>
                                            </div>
                                          </td>
                                        )}
                                        {isValidRole(roles, 5) && this.isScheduleDate("checkpoint3", schedule_date) ? (
                                          <td
                                            className={`col17 grid-cell-clickable txt-center schedule-right-body ${
                                              isWideC ? "" : "d-n"
                                            }`}
                                            onClick={() =>
                                              this.showScheduleEditorNew("checkpoint3", "she_hirisk", item)
                                            }
                                          >
                                            <div className="grid-cell-wrap">
                                              <div className="grid-cell-con">
                                                <div className="grid-cell-inner">
                                                  {item.checkpoint3_name}
                                                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        ) : (
                                          <td
                                            className={`col17 txt-center schedule-right-body ${isWideC ? "" : "d-n"}`}
                                          >
                                            <div className="grid-cell-wrap">
                                              <div className="grid-cell-con">
                                                <div className="grid-cell-inner">{item.checkpoint3_name}</div>
                                              </div>
                                            </div>
                                          </td>
                                        )}
                                        {isValidRole(roles, 6) && this.isScheduleDate("checkpoint4", schedule_date) ? (
                                          <td
                                            className={`col17 grid-cell-clickable txt-center schedule-right-body ${
                                              isWideC ? "" : "d-n"
                                            }`}
                                            onClick={() => this.showScheduleEditorNew("checkpoint4", "airline", item)}
                                          >
                                            <div className="grid-cell-wrap">
                                              <div className="grid-cell-con">
                                                <div className="grid-cell-inner">
                                                  {item.checkpoint4_name}
                                                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        ) : (
                                          <td
                                            className={`col17 txt-center schedule-right-body ${isWideC ? "" : "d-n"}`}
                                          >
                                            <div className="grid-cell-wrap">
                                              <div className="grid-cell-con">
                                                <div className="grid-cell-inner">{item.checkpoint4_name}</div>
                                              </div>
                                            </div>
                                          </td>
                                        )}
                                        {isValidRole(roles, 7) && this.isScheduleDate("checkpoint5", schedule_date) ? (
                                          <td
                                            className={`col17 grid-cell-clickable txt-center schedule-right-body ${
                                              isWideC ? "" : "d-n"
                                            }`}
                                            onClick={() => this.showScheduleEditorNew("checkpoint5", "night_job", item)}
                                          >
                                            <div className="grid-cell-wrap">
                                              <div className="grid-cell-con">
                                                <div className="grid-cell-inner">
                                                  {item.checkpoint5_name}
                                                  <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        ) : (
                                          <td
                                            className={`col17 txt-center schedule-right-body ${isWideC ? "" : "d-n"}`}
                                          >
                                            <div className="grid-cell-wrap">
                                              <div className="grid-cell-con">
                                                <div className="grid-cell-inner">{item.checkpoint5_name}</div>
                                              </div>
                                            </div>
                                          </td>
                                        )}

                                        {/* 工事補足*/}
                                        {isValidRole(roles, 8) && this.isScheduleDate("note2", schedule_date) ? (
                                          schedule_id === this.state.editNote2 ? (
                                            <td
                                              className={`no-hover col9 grid-cell-clickable txt-center schedule-right-body`}
                                            >
                                              <div className="grid-cell-wrap">
                                                <div className="grid-cell-con">
                                                  <div className="grid-cell-inner schedule-note txt-left">
                                                    <textarea
                                                      className="grid-textarea txt-left p-5"
                                                      ref={(node) => (this.note2Textarea = node)}
                                                      onBlur={(e) =>
                                                        this.endEditTextarea("editNote2", "note2", e.target.value, item)
                                                      }
                                                      defaultValue={item.note2}
                                                    ></textarea>
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          ) : (
                                            <td
                                              className={`col9 grid-cell-clickable txt-center schedule-right-body`}
                                              onClick={() => this.beginEditTextarea("editNote2", schedule_id)}
                                              data-tip
                                              data-for="schedule-full-text"
                                              onMouseEnter={() => this.handleOnMouse(item.note2, 20)}
                                            >
                                              <div className="grid-cell-wrap">
                                                <div className="grid-cell-con">
                                                  <div className="grid-cell-inner schedule-note txt-left p-5">
                                                    {this.omitText(item.note2, 20)}
                                                    <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          )
                                        ) : (
                                          <td
                                            className={`col9 txt-center schedule-right-body`}
                                            data-tip
                                            data-for="schedule-full-text"
                                            onMouseEnter={() => this.handleOnMouse(item.note2, 20)}
                                          >
                                            <div className="grid-cell-wrap">
                                              <div className="grid-cell-con">
                                                <div className="grid-cell-inner schedule-note txt-left p-5">
                                                  {this.omitText(item.note2, 34)}
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        )}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          )}
                          <div className="grid-right-header" ref={(el) => (this.rightHeaderContainer = el)}>
                            <table className="tbl-basic tbl-data list-table-row schedule-table">
                              <thead className="schedule">
                                <tr>
                                  <th colSpan="13" className="col-gb">
                                    {t("groundbreaking_end_check")}
                                  </th>
                                </tr>
                                <tr style={{ height: "45px" }}>
                                  <th rowSpan="2" className="col9 col_comment">
                                    {t("comment1")}
                                  </th>
                                  <th className="col11">{t("application")}</th>
                                  <th className={`col11 va-btm ${isWideD ? "" : "d-n"}`}>
                                    {t("readiness")}
                                    <button
                                      data-test-id="button-matrix-collapse"
                                      className={`grid-btn-collapse ${isWideD ? "" : "d-n"}`}
                                      onClick={() => this.toggleWideD()}
                                    >
                                      {"◀︎"}
                                    </button>
                                  </th>
                                  <th className={`col11 ${isWideD ? "" : "d-n"}`}>{t("driver")}</th>
                                  <th className={`col11 ${isWideD ? "" : "d-n"}`}>{t("driver_witness")}</th>
                                  <th className={`col11 ${isWideD ? "" : "d-n"}`}>{t("she_hirisk")}</th>
                                  <th className={`col9 ${isWideD ? "" : "d-n"}`}>{t("airline")}</th>
                                  <th id="approval" className="col11">
                                    {t("approval")}
                                    <button
                                      data-test-id="button-matrix-collapse"
                                      className={`grid-btn-collapse ${isWideD ? "d-n" : ""}`}
                                      onClick={() => this.toggleWideD()}
                                    >
                                      {"▶︎"}
                                    </button>
                                  </th>
                                  <th className="col11">{t("permission")}</th>
                                  <th className="col9">{t("schedule_note")}</th>
                                  <th colSpan="2" className={`va-btm ${isWideE ? "" : "d-n"}`}>
                                    {t("end_check")}
                                    <button
                                      data-test-id="button-matrix-collapse"
                                      className={`grid-btn-collapse ${isWideE ? "" : "d-n"}`}
                                      onClick={() => this.toggleWideE()}
                                    >
                                      {"◀︎"}
                                    </button>
                                  </th>
                                  <th rowSpan="2" className="col9 col_comment">
                                    {t("comment2")}
                                    <button
                                      data-test-id="button-matrix-collapse"
                                      className={`grid-btn-collapse ${isWideE ? "d-n" : ""}`}
                                      onClick={() => this.toggleWideE()}
                                    >
                                      {"▶︎"}
                                    </button>
                                  </th>
                                </tr>
                                <tr>
                                  {isValidRole(bulkRoles, 10) && this.isScheduleDate("field1", firstScheduleDate) ? (
                                    <th
                                      className={`header-bottom col11 header-height`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field1",
                                          t("application") + "：" + t("construction")
                                        )
                                      }
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="title">{t("construction")}</span>
                                            <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className="col11 header-height">
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="default">{t("construction")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 11) && this.isScheduleDate("field2", firstScheduleDate) ? (
                                    <th
                                      className={`header-bottom col11 header-height ${isWideD ? "" : "d-n"}`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle("field2", t("readiness") + "：" + t("assignee"))
                                      }
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="title">{t("assignee")}</span>
                                            <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className={`col11 header-height ${isWideD ? "" : "d-n"}`}>
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="default">{t("assignee")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 12) && this.isScheduleDate("field3", firstScheduleDate) ? (
                                    <th
                                      className={`header-bottom col11 header-height ${isWideD ? "" : "d-n"}`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle("field3", t("driver") + "：" + t("assignee"))
                                      }
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="title">{t("assignee")}</span>
                                            <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className={`col11 header-height ${isWideD ? "" : "d-n"}`}>
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="default">{t("assignee")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 13) && this.isScheduleDate("field4", firstScheduleDate) ? (
                                    <th
                                      className={`header-bottom col11 header-height ${isWideD ? "" : "d-n"}`}
                                      onClick={() => this.showScheduleEditorBundle("field4", t("driver_witness"))}
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="title">{""}</span>
                                            <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className={`col11 header-height ${isWideD ? "" : "d-n"}`}>
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="default">{""}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 14) && this.isScheduleDate("field5", firstScheduleDate) ? (
                                    <th
                                      className={`header-bottom col11 header-height ${isWideD ? "" : "d-n"}`}
                                      onClick={() => this.showScheduleEditorBundle("field5", t("she_hirisk"))}
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="title">{""}</span>
                                            <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className={`col11 header-height ${isWideD ? "" : "d-n"}`}>
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="default">{""}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 15) && this.isScheduleDate("field6", firstScheduleDate) ? (
                                    <th
                                      className={`header-bottom col11 header-height ${isWideD ? "" : "d-n"}`}
                                      onClick={() => this.showScheduleEditorBundle("field6", t("airline"))}
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="title">{""}</span>
                                            <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className={`col11 header-height ${isWideD ? "" : "d-n"}`}>
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="default">{""}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 16) && this.isScheduleDate("field7", firstScheduleDate) ? (
                                    <th
                                      className={`header-bottom col11 header-height`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field7",
                                          t("approval") + "：" + t("senior_staff")
                                        )
                                      }
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="title">{t("senior_staff")}</span>
                                            <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className="col11 header-height">
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="default">{t("senior_staff")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 17) && this.isScheduleDate("field8", firstScheduleDate) ? (
                                    <th
                                      className={`header-bottom col11 header-height`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field8",
                                          t("permission") + "：" + t("team_leader")
                                        )
                                      }
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="title">{t("team_leader")}</span>
                                            <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className="col11 header-height">
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="default">{t("team_leader")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  <th className={`col11 header-height`}>
                                    <div className="grid-cell-wrap">
                                      <div className="grid-cell-con">
                                        <div className="grid-cell-inner">
                                          <span className="title">{""}</span>
                                          <button className="grid-icon grid-icon-edit icon-edit"></button>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  {isValidRole(bulkRoles, 19) && this.isScheduleDate("field9", firstScheduleDate) ? (
                                    <th
                                      className={`header-bottom col11 header-height ${isWideE ? "" : "d-n"}`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle("field9", t("end_check") + "：" + t("drive"))
                                      }
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="title">{t("drive")}</span>
                                            <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className={`col11 header-height ${isWideE ? "" : "d-n"}`}>
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="default">{t("drive")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  {isValidRole(bulkRoles, 20) && this.isScheduleDate("field10", firstScheduleDate) ? (
                                    <th
                                      className={`header-bottom col11 header-height ${isWideE ? "" : "d-n"}`}
                                      style={{ borderRight: "1px solid #fff" }}
                                      onClick={() =>
                                        this.showScheduleEditorBundle("field10", t("end_check") + "：" + t("builder"))
                                      }
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="title">{t("builder")}</span>
                                            <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th
                                      className={`col11 header-height ${isWideE ? "" : "d-n"}`}
                                      style={{ borderRight: "1px solid #fff" }}
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="default">{t("builder")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                </tr>
                              </thead>
                            </table>
                          </div>
                          {!this.state.isEmpty && (
                            <div
                              className="grid-right-body"
                              onScroll={(e) => this.syncScroll(e)}
                              ref={(node) => (this.rightBodyContainer = node)}
                            >
                              <table className="tbl-basic tbl-data list-table-row data-schedule grid-table-right-body schedule-table">
                                <tbody className="schedule">
                                  {items.map((schedule, index) => (
                                    <ScheduleRightRowEneosKawasakiContainer
                                      key={index}
                                      index={index}
                                      schedule={schedule}
                                      isScheduleDate={(target, scheduleDate) =>
                                        this.isScheduleDate(target, scheduleDate)
                                      }
                                      showScheduleEditor={(target, columnName, item) =>
                                        this.showScheduleEditorNew(target, columnName, item)
                                      }
                                      handleOnMouse={(columnValue, validLength) =>
                                        this.handleOnMouse(columnValue, validLength)
                                      }
                                      omitText={(text, validLength) => this.omitText(text, validLength)}
                                      setTooltip={this.setTooltip}
                                      isWideD={isWideD}
                                      isWideE={isWideE}
                                    />
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  )}
            </div>
          </div>
          <a
            className="d-n"
            download={this.state.downloadName}
            href={this.state.downloadUrl}
            ref={(node) => (this.btnDownload = node)}
          >
            download
          </a>
        </div>
        {this.state.maxPage > 0 && (
          <div className="pagination">
            <div className="null-box grid1">
              {this.state.currentPage > 1 && (
                <div className="left-arrow-box" onClick={() => this.handlePrevPage()}>
                  <span>{t("prev")}</span>
                </div>
              )}
            </div>
            <span className="page-status">
              {this.state.currentPage} / {this.state.maxPage}
            </span>
            <div className="null-box grid3">
              {this.state.currentPage < this.state.maxPage && (
                <div className="right-arrow-box" onClick={() => this.handleNextPage()}>
                  <span>{t("next")}</span>
                </div>
              )}
            </div>
          </div>
        )}

        {this.state.showUpdateBundle && (
          <ScheduleEditorBundleEneosKawasakiContainer
            target={this.state.target}
            panelText={this.state.panelText}
            selectValues={this.state.selectValues}
            scheduleIds={this.props.items.map((item) => item.schedule_id)}
            closeHandler={() => this.hideScheduleEditor()}
            searchHandler={() => this.handleSearchBundle()}
          />
        )}
        {this.state.showEditor && (
          <ScheduleEditorEneosKawasakiContainer
            target={this.state.target}
            panelText={this.state.panelText}
            item={this.state.item}
            selectValues={this.state.selectValues}
            closeHandler={() => this.hideScheduleEditor()}
          />
        )}
        {this.state.showUpdatePanel && (
          <ScheduleCategoryUpdateEneosKawasakiContainer
            scheduleDateList={this.judgeScheduleDateUpdate()}
            categories={masters.categories}
            closeHandler={() => this.hideScheduleEditor()}
          />
        )}
        {this.state.tooltip !== null && (
          <ReactTooltip
            id="schedule-full-text"
            className="matrix-task-detail"
            delayShow={500}
            effect="solid"
            isCapture={true}
            scrollHide={true}
          >
            <TooltipContainer data={this.state.tooltip} />
          </ReactTooltip>
        )}
      </div>
    );
  }
}

ScheduleEneosKawasaki.propTypes = {
  areaIds: PropTypes.array.isRequired,
  deviceIds: PropTypes.array.isRequired,
  categoryId: PropTypes.number.isRequired,
  facilityManagementIds: PropTypes.array.isRequired,
  constructionManagementIds: PropTypes.array.isRequired,
  primaryChargeIds: PropTypes.array.isRequired,
  scheduleDate: PropTypes.string.isRequired,
  field1Ids: PropTypes.array.isRequired,
  field7Ids: PropTypes.array.isRequired,
  field8Ids: PropTypes.array.isRequired,
  itemText: PropTypes.string.isRequired,
  processText: PropTypes.string.isRequired,
  masters: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  changeArea: PropTypes.func.isRequired,
  changeDevice: PropTypes.func.isRequired,
  changeFacilityManagement: PropTypes.func.isRequired,
  changeCategory: PropTypes.func.isRequired,
  changeConstructionManagement: PropTypes.func.isRequired,
  changePrimaryCharge: PropTypes.func.isRequired,
  changeScheduleDate: PropTypes.func.isRequired,
  changeItemText: PropTypes.func.isRequired,
  changeProcessText: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  revertLocalCondition: PropTypes.func.isRequired,
  validated: PropTypes.bool.isRequired,
};

export default withTranslation()(ScheduleEneosKawasaki);
