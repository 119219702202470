import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import ChargeSelect from "../common/ChargeSelect";
import SearchConditionItem from "../common/SearchConditionItem";

const QrSearchArea = (props) => {
  const {
    masters,
    areaIds,
    deviceIds,
    categoryId,
    facilityManagementIds,
    constructionManagementIds,
    primaryChargeIds,
    otherIds,
    userId,
    companyId,
    groupId,
    changeArea,
    changeDevice,
    changeFacilityManagement,
    changeCategory,
    changeConstructionManagement,
    changePrimaryCharge,
    changeOther,
    changeUser,
    changeGroup,
    changeCompany,
    handleSearch,
    handleClear,
    reference,
    t,
  } = props;

  return (
    <div
      className="search-box"
      ref={reference}
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        id="search-area-left"
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <div className="form-row">
          <SearchConditionItem
            className="w-220"
            selectClassName="w-150"
            labelName={t("area")}
            isMulti
            options={masters.areas.map((area) => ({ value: area.area_id, label: area.area_name }))}
            onChange={(e) => changeArea(e)}
            value={masters.areas
              .filter((area) => _.includes(areaIds, area.area_id))
              .map((area) => ({ value: area.area_id, label: area.area_name }))}
          />
          <SearchConditionItem
            className="w-220"
            selectClassName="w-150"
            labelName={t("device")}
            isMulti
            options={masters.devices.map((device) => ({ value: device.device_id, label: device.device_name }))}
            onChange={(e) => changeDevice(e)}
            value={masters.devices
              .filter((device) => _.includes(deviceIds, device.device_id))
              .map((device) => ({ value: device.device_id, label: device.device_name }))}
          />
          <SearchConditionItem
            className="w-220"
            selectClassName="w-150"
            labelName={t("facility_management")}
            isMulti
            options={masters.facilityManagements.map((facilityManagement) => ({
              value: facilityManagement.facility_management_id,
              label: facilityManagement.facility_management_name,
            }))}
            onChange={(e) => changeFacilityManagement(e)}
            value={masters.facilityManagements
              .filter((facilityManagement) =>
                _.includes(facilityManagementIds, facilityManagement.facility_management_id)
              )
              .map((facilityManagement) => ({
                value: facilityManagement.facility_management_id,
                label: facilityManagement.facility_management_name,
              }))}
          />
          <SearchConditionItem
            className="w-300"
            selectClassName="w-220"
            labelName={t("machines_category")}
            isClearable={false}
            options={masters.categories.map((category) => ({
              value: category.category_id,
              label: category.category_name,
            }))}
            onChange={(e) => changeCategory(e)}
            value={masters.categories
              .filter((category) => category.category_id === categoryId)
              .map((category) => ({ value: category.category_id, label: category.category_name }))}
          />
        </div>
        <div className="form-row">
          <SearchConditionItem
            className="w-220"
            selectClassName="w-150"
            labelName={t("construction_management")}
            isMulti
            options={masters.constructionManagements.map((constructionManagement) => ({
              value: constructionManagement.construction_management_id,
              label: constructionManagement.construction_management_name,
            }))}
            onChange={(e) => changeConstructionManagement(e)}
            value={masters.constructionManagements
              .filter((constructionManagement) =>
                _.includes(constructionManagementIds, constructionManagement.construction_management_id)
              )
              .map((constructionManagement) => ({
                value: constructionManagement.construction_management_id,
                label: constructionManagement.construction_management_name,
              }))}
          />
          <SearchConditionItem
            className="w-220"
            selectClassName="w-150"
            labelName={t("primary_charge")}
            isMulti
            options={masters.primaryCharges.map((primaryCharge) => ({
              value: primaryCharge.primary_charge_id,
              label: primaryCharge.primary_charge_name,
            }))}
            onChange={(e) => changePrimaryCharge(e)}
            value={masters.primaryCharges
              .filter((primaryCharge) => _.includes(primaryChargeIds, primaryCharge.primary_charge_id))
              .map((primaryCharge) => ({
                value: primaryCharge.primary_charge_id,
                label: primaryCharge.primary_charge_name,
              }))}
          />
          <SearchConditionItem
            className="w-220"
            selectClassName="w-150"
            labelName={t("other")}
            isMulti
            options={masters.others.map((other) => ({ value: other.other_id, label: other.other_name }))}
            onChange={(e) => changeOther(e)}
            value={masters.others
              .filter((other) => _.includes(otherIds, other.other_id))
              .map((other) => ({ value: other.other_id, label: other.other_name }))}
          />
          <SearchConditionItem
            hide
            className="w-300"
            selectClassName="w-220"
            labelName={t("assignee")}
            options={masters.companies.map((company) => ({ value: company.company_id, label: company.company_name }))}
            onChange={(e) => changeCompany(e)}
            value={masters.companies
              .filter((company) => company.company_id === companyId)
              .map((company) => ({ value: company.company_id, label: company.company_name }))}
          />
          <SearchConditionItem
            hide
            className="w-300"
            selectClassName="w-220"
            options={masters.groups.map((group) => ({ value: group.group_id, label: group.group_name }))}
            onChange={(e) => changeGroup(e)}
            value={masters.groups
              .filter((group) => group.group_id === groupId)
              .map((group) => ({ value: group.group_id, label: group.group_name }))}
          />
          <div className="form-group w-300">
            <span className="form-label">{t("assignee")}</span>
            <div className="d-ib ta-l w-220">
              <ChargeSelect
                isClearable={true}
                masters={masters}
                userId={userId}
                groupId={groupId}
                onChange={(e) => {
                  changeUser(e.user_id);
                  changeGroup(e.group_id);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        id="search-area-right"
        style={{
          display: "flex",
          width: 120,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="form-group btn-area pull-right mt-0">
          <button data-test-id="button-qr-search" className="btn qr btn-blue" onClick={handleSearch}>
            {t("search")}
          </button>
          <button data-test-id="button-qr-reset" className="btn qr btn-gray" onClick={handleClear}>
            {t("reset")}
          </button>
        </div>
      </div>
    </div>
  );
};

QrSearchArea.propTypes = {
  areaIds: PropTypes.array.isRequired,
  deviceIds: PropTypes.array.isRequired,
  categoryId: PropTypes.number.isRequired,
  facilityManagementIds: PropTypes.array.isRequired,
  constructionManagementIds: PropTypes.array.isRequired,
  primaryChargeIds: PropTypes.array.isRequired,
  otherIds: PropTypes.array.isRequired,
  companyId: PropTypes.number.isRequired,
  groupId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  masters: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  changeArea: PropTypes.func.isRequired,
  changeDevice: PropTypes.func.isRequired,
  changeFacilityManagement: PropTypes.func.isRequired,
  changeCategory: PropTypes.func.isRequired,
  changeConstructionManagement: PropTypes.func.isRequired,
  changePrimaryCharge: PropTypes.func.isRequired,
  changeOther: PropTypes.func.isRequired,
  changeUser: PropTypes.func.isRequired,
  changeGroup: PropTypes.func.isRequired,
  changeCompany: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  downloadZip: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  reference: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(QrSearchArea);
