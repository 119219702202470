import React, { useMemo } from "react";
import Select from "react-select";

import styles from "../../constants/reactSelectStyle";

import { ConstructionMasters, MasterGroup, MasterUser } from "@/models/masters";

type Props = {
  masters: Pick<ConstructionMasters, "users" | "groups">;
  userId: number | number[];
  groupId: number | number[];
  onChange: (_) => void;
  isMulti?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
};

type ChargeSelectOption = {
  value: number;
  label: string;
  category: "user" | "group";
  data: MasterUser | MasterGroup;
};

const ChargeSelect = ({ disabled, masters, userId, groupId, isMulti, isClearable, onChange }: Props) => {
  const { users, groups } = masters;

  const userOptions: ChargeSelectOption[] = useMemo(
    () =>
      users.map((user) => ({
        value: user.user_id,
        label: user.user_name,
        category: "user",
        data: user,
      })),
    [users]
  );
  const groupOptions: ChargeSelectOption[] = useMemo(
    () =>
      groups.map((group) => ({
        value: group.group_id,
        label: group.group_name,
        category: "group",
        data: group,
      })),
    [groups]
  );
  const options: ChargeSelectOption[] = useMemo(() => [...userOptions, ...groupOptions], [userOptions, groupOptions]);

  const value = useMemo(() => {
    if (isMulti) {
      return [
        ...userOptions.filter((option) => (userId as number[])?.some((id) => id === option.value)),
        ...groupOptions.filter((option) => (groupId as number[])?.some((id) => id === option.value)),
      ];
    }
    if (userId === 0 && groupId === 0) {
      return null;
    }

    if (userId === 0) {
      return groupOptions.find((group) => group.value === groupId);
    }

    return userOptions.find((user) => user.value === userId);
  }, [userOptions, groupOptions, userId, groupId, isMulti]);

  const menuPortalTarget = useMemo(() => document.body, []);

  return (
    <Select
      styles={styles}
      value={value}
      options={options}
      onChange={(v) => {
        if (isMulti) {
          onChange(v as ChargeSelectOption[]);
          return;
        }
        const e = v as ChargeSelectOption;
        onChange({
          company_id: e === null ? 0 : e.data.company_id,
          // @ts-ignore TODO 型修正
          user_id: e === null ? 0 : e.category === "user" ? e.data.user_id : 0,
          // @ts-ignore
          group_id: e === null ? 0 : e.category === "group" ? e.data.group_id : 0,
        });
      }}
      menuPortalTarget={menuPortalTarget}
      menuPlacement="auto"
      isMulti={isMulti}
      isClearable={isClearable}
      isDisabled={disabled}
    />
  );
};

export default ChargeSelect;
