import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { hideConstructionSelector } from "../../actions/app";
import { isIE, addHeightResizeListener, removeHeightResizeListener } from "../../lib/common";
import storageManager from "../../lib/storageManager";
import Modal from "../Modal";

import ExportSchedulePanel from "./ExportSchedulePanel";

class SchedulePanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scheduleDateIds: this.props.scheduleInfo.initCheck == -1 ? [] : [this.props.scheduleInfo.initCheck],
      visibleExportSchedulePanel: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.showExportSchedulePanel = this.showExportSchedulePanel.bind(this);
    this.hideExportSchedulePanel = this.hideExportSchedulePanel.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.setDatepickerPos);
    this.props.setUnloadAlert();

    this.handleResize();
    addHeightResizeListener(this.panelLeft, this.handleResize, 100);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setDatepickerPos);
    this.props.clearUnloadAlert();

    removeHeightResizeListener(this.panelLeft);
  }

  handleResize() {
    const panelHeight = this.panelLeft.offsetHeight;
    this.setState({ panelHeight });
  }

  handleSubmit() {
    let schedule_dates = [];

    this.props.scheduleInfo.scheduleDateLists.map((item) => {
      if (this.state.scheduleDateIds.some((id) => id === item.id)) {
        schedule_dates = [...schedule_dates, item.value];
      }
    });

    if (this.props.extensionType === 3) {
      this.props.createScheduleNegishi(schedule_dates, this.showExportSchedulePanel);
    } else if (this.props.extensionType === 4) {
      this.props.createScheduleTokuyama(schedule_dates, this.showExportSchedulePanel);
    } else if (this.props.extensionType === 7) {
      this.props.createScheduleIdemitsuChiba(schedule_dates, this.showExportSchedulePanel);
    }
  }

  handleScroll() {
    const container = this.body;
    const scrollTop = container.scrollTop;
    this.setState({ scrollTop });
  }

  toggleCheckBox(id, checked) {
    if (checked) {
      this.setState({ scheduleDateIds: [...this.state.scheduleDateIds, id] });
    } else {
      this.setState({ scheduleDateIds: this.state.scheduleDateIds.filter((item) => item != id) });
    }
  }

  showExportSchedulePanel() {
    this.setState({
      visibleExportSchedulePanel: true,
    });
  }
  hideExportSchedulePanel() {
    this.setState({
      visibleExportSchedulePanel: false,
    });
    this.props.closeHandler();
    this.props.backHandler();
  }

  render() {
    const { loadingPanel, closeHandler, selectedCategoryCount, targetItemCount, scheduleInfo, t } = this.props;

    return (
      <Modal
        title={t("putting_schedule_list")}
        loading={loadingPanel}
        closeHandler={closeHandler}
        onDrag={this.setDatepickerPos}
      >
        <div
          className="modal-body w-540 schedule-panel"
          onScroll={this.handleScroll}
          ref={(node) => (this.body = node)}
        >
          <p className="disp-area">
            {t("selected_category")}：<span className="categoryNum txt-bold ">{selectedCategoryCount}</span>
            {t("selected_items_sum")}：<span className="targetItems txt-bold ">{targetItemCount}</span>
          </p>
          <div className="schedule-inner">
            <div
              className={`schedule-panel-left ${loadingPanel ? "disabled" : ""}`}
              ref={(node) => (this.panelLeft = node)}
            >
              <p>{t("date_create_schedule_list")}</p>
              <ul className="schedule-date-list">
                {scheduleInfo.scheduleDateLists.map((item) => (
                  <li key={item.id}>
                    <label className={"ckbox"}>
                      <input
                        type="checkbox"
                        value={item.id || undefined}
                        disabled={item.active ? "" : "disabled"}
                        checked={this.state.scheduleDateIds.some((id) => id === item.id)}
                        onChange={(e) => this.toggleCheckBox(item.id, e.target.checked)}
                      />
                      <span></span>
                    </label>
                    <span className="baseDate">{item.value}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={`modal-footer ${loadingPanel ? "loading--dialog" : ""}`}>
          <button
            disabled={loadingPanel}
            data-test-id="button-area-edit-cancel"
            type="button"
            className="btn btn-gray"
            onClick={closeHandler}
          >
            {t("cancel")}
          </button>
          <button
            disabled={this.state.scheduleDateIds.length > 0 && !loadingPanel ? false : true}
            data-test-id="button-area-edit-save"
            type="button"
            className="btn btn-blue"
            onClick={this.handleSubmit}
          >
            {t("decision")}
          </button>
        </div>
        {this.state.visibleExportSchedulePanel && <ExportSchedulePanel closeHandler={this.hideExportSchedulePanel} />}
      </Modal>
    );
  }
}

SchedulePanel.defaultProps = {
  data: null,
};

SchedulePanel.propTypes = {
  selectedCategoryCount: PropTypes.number.isRequired,
  targetItemCount: PropTypes.number.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
  scheduleSelectedCells: PropTypes.array.isRequired,
  loadingPanel: PropTypes.bool.isRequired,
};

export default withTranslation()(SchedulePanel);
