import { ComponentProps, FC } from "react";
import ReactModal from "react-modal";

export type ModalProps = Omit<ComponentProps<typeof ReactModal>, "isOpen"> & {
  onClose: () => void;
};

export const Modal: FC<ModalProps> = ({ style, onClose, ...restProps }) => {
  const defaultStyles = {
    overlay: {
      background: "rgba(0, 0, 0, 0.5)",
      ...style?.overlay,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0",
      background: "#f3f2ef",
      borderRadius: "1px",
      ...style?.content,
    },
  };

  return (
    <ReactModal
      isOpen
      style={{ ...style, ...defaultStyles }}
      ariaHideApp={false}
      shouldCloseOnEsc={false}
      onRequestClose={onClose}
      {...restProps}
    />
  );
};
