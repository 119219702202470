import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import * as constants from "../../constants";
import * as commonUtil from "../../lib/common";

import { MatrixSearchState } from "@/reducers/matrixSearch";

type Props = {
  searchParams: MatrixSearchState;
  downloading: boolean;
  onClickDownloadSchedule: () => void;
};
type DispatchProps = {
  download: (params, callback, fallback) => void;
  toggleDownloading: (downloading) => void;
};

const PulldownExport = ({
  downloading,
  searchParams,
  onClickDownloadSchedule,
  download,
  toggleDownloading,
}: Props & DispatchProps) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const btnDownload = useRef<HTMLAnchorElement>();

  const showDropdown = () => {
    setIsActive(true);
  };

  const hideDropdown = () => {
    setIsActive(false);
  };

  const handleDownload = (searchParams, filetype, format) => {
    toggleDownloading(true);

    const params = {
      ...searchParams,
      filetype,
      format,
    };

    delete params.start;
    delete params.limit;

    const callback = (blob, filename) => {
      // @ts-ignore: MS用
      if (window.navigator?.msSaveOrOpenBlob) {
        // @ts-ignore: MS用
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else if (btnDownload.current) {
        btnDownload.current.href = URL.createObjectURL(blob);
        btnDownload.current.download = filename;
        const evt = commonUtil.createClickEvent();
        btnDownload.current.dispatchEvent(evt);
      }
      toggleDownloading(false);
    };

    const fallback = () => toggleDownloading(false);
    download(params, callback, fallback);
  };

  const handleDownloadSchedule = () => {
    onClickDownloadSchedule();
  };

  return (
    <React.Fragment>
      <div className="btn-dropdown-area" onMouseLeave={hideDropdown}>
        <button
          data-test-id="button-matrix-file-export"
          className="btn btn-light-blue btn-dropdown relative"
          onClick={showDropdown}
        >
          <i className="icon icon-get_app" />
          {t("file_export")}
          <div className={`${downloading ? "loading-small loading-small-download loading-small-matrix" : ""}`} />
        </button>
        {isActive === true && (
          <ul className="dropdown-menu" onClick={hideDropdown}>
            {constants.MATRIX_DOWNLOADS.filter((d) => d.format !== "pdf").map((d, i) => {
              // PDFのファイル出力を一旦非表示にする
              return (
                <li
                  key={i}
                  // @ts-ignore 暫定処置でts-ignoreしている
                  // eslint-disable-next-line react/no-unknown-property
                  download={d.filename}
                  onClick={() => {
                    if (d.filename === "schedule.xls") {
                      // 予定表の場合は条件を入力するモーダルを表示する
                      handleDownloadSchedule();
                    } else {
                      handleDownload(searchParams, d.filetype, d.format);
                    }
                  }}
                >
                  {d.format === "excel" && (
                    <React.Fragment>
                      <img src="./img/icon_xls.svg" alt="Excel" className="icon-file" />
                      {t(d.label)}
                    </React.Fragment>
                  )}
                  {d.format === "pdf" && (
                    <React.Fragment>
                      <img src="./img/icon_pdf.svg" alt="PDF" className="icon-file" />
                      {t(d.label)}
                    </React.Fragment>
                  )}
                  {d.format === "csv" && (
                    <React.Fragment>
                      <img src="./img/icon_csv.svg" alt="CSV" className="icon-file" />
                      {t(d.label)}
                    </React.Fragment>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <a className="d-n" ref={btnDownload}>
        download
      </a>
    </React.Fragment>
  );
};

export default PulldownExport;
