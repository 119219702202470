import { connect } from "react-redux";

import actions from "../actions";
import Header from "../components/Header";

const mapStateToProps = (state) => {
  return {
    userName: state.session.userName,
    resultProgressRate: state.construction.resultProgressRate,
    scheduleProgressRate: state.construction.scheduleProgressRate,
    fetchingProgressRate: state.app.fetchingProgressRate,
    notifications: state.notifications,
    fetchedJobsLargestId: state.dataExport.fetchedJobsLargestId,
    construction: (() => {
      const c = {
        customerName: "",
        placeName: "",
        constructionName: "",
      };

      const { customerId, placeId, constructionId, customers, places, constructions } = state.construction;

      customers.forEach((customer) => {
        if (customer.customer_id === customerId) {
          c.customerName = customer.customer_name;
        }
      });

      places.forEach((place) => {
        if (place.place_id === placeId) {
          c.placeName = place.place_name;
        }
      });

      constructions.forEach((construction) => {
        if (construction.construction_id === constructionId) {
          c.constructionName = construction.construction_name;
        }
      });

      return c;
    })(),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.login.logout()),
    reloadProgressRate: () => dispatch(actions.construction.fetchProgressRate()),
    fetchJobs: () => dispatch(actions.dataExport.fetchJobs()),
    showLangSelector: () => dispatch(actions.app.showLangSelector()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
