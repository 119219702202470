import { connect } from "react-redux";

import actions from "../actions";
import ConstructionSelector from "../components/ConstructionSelector";

const mapStateToProps = (state) => {
  return {
    customerId: state.construction.customerId,
    placeId: state.construction.placeId,
    constructionId: state.construction.constructionId,
    customers: state.construction.customers,
    places: state.construction.places,
    constructions: state.construction.constructions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    save: (customerId, placeId, constructionId) =>
      dispatch(actions.construction.save(customerId, placeId, constructionId)),
    showAlert: (title, messages, okClickHandler) => dispatch(actions.app.showAlert(title, messages, okClickHandler)),
    hideAlert: () => dispatch(actions.app.hideAlert()),
    close: () => dispatch(actions.app.hideConstructionSelector()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConstructionSelector);
