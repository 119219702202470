import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  showStatusBulkUpdatePanel: (type, value) => void;
};
const PulldownStatus = ({ showStatusBulkUpdatePanel }: Props) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  const showDropdown = () => {
    setIsActive(true);
  };

  const hideDropdown = () => {
    setIsActive(false);
  };

  return (
    <React.Fragment>
      <div className="btn-dropdown-area" onMouseLeave={() => hideDropdown()}>
        <button
          data-test-id="button-matrix-status-update"
          className="btn btn-light-blue btn-dropdown relative"
          onClick={() => showDropdown()}
        >
          {t("status_bulk_update")}
        </button>
        {isActive === true && (
          <ul className="dropdown-menu" onClick={() => hideDropdown()}>
            <li onClick={() => showStatusBulkUpdatePanel(1, 2)}>
              <span className="status-mark">◯ → ②</span>
            </li>
            <li onClick={() => showStatusBulkUpdatePanel(1, 3)}>
              <span className="status-mark">◯ → ③</span>
            </li>
            <li onClick={() => showStatusBulkUpdatePanel(2, 2)}>
              <span className="status-mark">◎ → ⓶</span>
            </li>
            <li onClick={() => showStatusBulkUpdatePanel(2, 3)}>
              <span className="status-mark">◎ → ⓷</span>
            </li>
          </ul>
        )}
      </div>
    </React.Fragment>
  );
};

export default PulldownStatus;
