import Immutable from "immutable";

import * as constants from "../constants/index";
import { LANG_OPTIONS } from "../constants/index";
import { changeLanguage } from "../i18n";
import * as util from "../lib/common";
import getDefaultLang from "../lib/getDefaultLang";
import restoreState from "../lib/restoreState";
import storageManager from "../lib/storageManager";

const urlState = restoreState();
let initialMenu = urlState ? urlState.menu : "home";

if (util.getMenuByName(initialMenu)) {
  initialMenu = util.getMenuByName(initialMenu).alias;
}

let initialLang = storageManager.getLocale();
if (!initialLang) {
  let defaultLang = getDefaultLang();
  if (defaultLang.includes("en")) {
    defaultLang = "en";
  }
  const defaultOption = LANG_OPTIONS.find((option) => option.value === defaultLang);
  initialLang = defaultOption ? defaultOption.value : LANG_OPTIONS[0].value;
}

changeLanguage(initialLang);

const app = (
  state = {
    menu: initialMenu,
    visibleConstructionSelector: false,
    visiblePasswordEditor: false,
    visibleLangSelector: false,
    toastContents: [],
    confirm: null,
    alert: null,
    menus: [],
    roles: {
      progress: {},
      layout: {},
    },
    fetchingMenus: false,
    fetchingMasters: false,
    fetchingProgressRate: false,
    fetchingConstructions: false,
    fetchingRoles: false,
    unloadAlert: false,
    activeMenu: false,
    lang: initialLang,
  },
  action
) => {
  let newState = Immutable.fromJS(state);

  switch (action.type) {
    case constants.APP_SWITCH_SYSTEM: {
      if (action.payload === "progress") {
        newState = newState.set("menu", "home");
      }

      return newState.toJS();
    }
    case constants.LOGIN_SUCCESS: {
      newState = newState.set("visibleConstructionSelector", !util.getConstructionId());

      return newState.toJS();
    }
    case constants.LOGOUT_SUCCESS: {
      const system = storageManager.getUserItem("system") ?? "progress";

      if (system === "progress") {
        newState = newState.set("menu", "home");
      } else if (system === "layout") {
        newState = newState.set("menu", "machine");
      }

      return newState.toJS();
    }
    case constants.APP_POPSTATE: {
      // 戻る・進むボタンキャンセル時はメニューを戻す
      // （Reducerに副作用を持たせてしまうが、唯一成功した方法）
      // const confirmMessage = 'このページを離れますか？\n行った変更が保存されない可能性があります。';

      // component以外ではwithTranslationを使用できないため、ハードコーディングとする
      const locale = localStorage.getItem("locale");
      const confirmMessage =
        locale === "ja"
          ? "このページを離れますか？\n行った変更が保存されない可能性があります。"
          : "Leave this page?\nThe changes may not be saved.";

      if (state.unloadAlert && !confirm(confirmMessage)) {
        history.pushState(null, null, `/#/${util.getConstructionId()}/${state.menu}/`);

        return state;
      }

      const restore = restoreState();
      let menuName = restore.menu;

      if (menuName === "import") {
        menuName = "dataimport";
      }

      let menuData = util.getMenuByAlias(menuName);

      // メニューが有効かつアクセス権がある、
      // またはメニューリストがまだストアされていなければリクエスト通りにメニューをセット
      if ((menuData && util.isAvailableMenu(menuData.id, state.menus)) || state.menus.length === 0) {
        newState = newState.set("menu", menuName);
      } else {
        menuData = util.getMenuByName("home");
        newState = newState.set("menu", menuData.alias);
      }

      let scheMenu = util.getMenuByAlias("schedule_eneos_kawasaki");
      if (scheMenu.id && util.isAvailableMenu(scheMenu.id, state.menus)) {
        newState = newState.set("activeMenu", true);
      }

      scheMenu = util.getMenuByAlias("schedule_chita");
      if (scheMenu.id && util.isAvailableMenu(scheMenu.id, state.menus)) {
        newState = newState.set("activeMenu", true);
      }

      scheMenu = util.getMenuByAlias("schedule_chiba");
      if (scheMenu.id && util.isAvailableMenu(scheMenu.id, state.menus)) {
        newState = newState.set("activeMenu", true);
      }

      return newState.toJS();
    }
    case constants.APP_SET_UNLOAD_ALERT: {
      newState = newState.set("unloadAlert", true);

      return newState.toJS();
    }
    case constants.APP_CLEAR_UNLOAD_ALERT: {
      newState = newState.set("unloadAlert", false);

      return newState.toJS();
    }
    case constants.APP_BEGIN_FETCH_MENUS: {
      newState = newState.set("fetchingMenus", true);

      return newState.toJS();
    }
    case constants.APP_END_FETCH_MENUS: {
      const menus: any[] = action.payload;
      const restore = restoreState();
      let menuName = restore.menu;

      if (menuName === "import") {
        menuName = "dataimport";
      }

      let menuData = util.getMenuByAlias(menuName);

      // 無効なメニュー、もしくはメニューへのアクセス権がなければホーム画面を表示
      if (!menuData || !util.isAvailableMenu(menuData.id, menus)) {
        menuData = util.getMenuByName("home");
        newState = newState.set("menu", menuData.alias);
      }

      let scheMenu = util.getMenuByAlias("schedule_eneos_kawasaki");
      if (scheMenu.id && util.isAvailableMenu(scheMenu.id, menus)) {
        newState = newState.set("activeMenu", true);
      }

      scheMenu = util.getMenuByAlias("schedule_chita");
      if (scheMenu.id && util.isAvailableMenu(scheMenu.id, menus)) {
        newState = newState.set("activeMenu", true);
      }

      scheMenu = util.getMenuByAlias("schedule_chiba");
      if (scheMenu.id && util.isAvailableMenu(scheMenu.id, menus)) {
        newState = newState.set("activeMenu", true);
      }

      newState = newState.set("menus", menus);
      newState = newState.set("fetchingMenus", false);

      return newState.toJS();
    }
    case constants.APP_BEGIN_FETCH_ROLES: {
      newState = newState.set("fetchingRoles", true);

      return newState.toJS();
    }
    case constants.APP_END_FETCH_ROLES: {
      newState = newState.set("roles", action.payload);
      newState = newState.set("fetchingRoles", false);

      return newState.toJS();
    }
    case constants.CONSTRUCTION_BEGIN_FETCH_MASTERS: {
      newState = newState.set("fetchingMasters", true);

      return newState.toJS();
    }
    case constants.CONSTRUCTION_END_FETCH_MASTERS: {
      newState = newState.set("fetchingMasters", false);

      return newState.toJS();
    }
    case constants.CONSTRUCTION_BEGIN_FETCH_PROGRESS_RATE: {
      newState = newState.set("fetchingProgressRate", true);

      return newState.toJS();
    }
    case constants.CONSTRUCTION_END_FETCH_PROGRESS_RATE: {
      newState = newState.set("fetchingProgressRate", false);

      return newState.toJS();
    }
    case constants.CONSTRUCTION_BEGIN_FETCH_ITEMS: {
      newState = newState.set("fetchingConstructions", true);

      return newState.toJS();
    }
    case constants.CONSTRUCTION_END_FETCH_ITEMS: {
      newState = newState.set("fetchingConstructions", false);

      return newState.toJS();
    }
    case constants.APP_CHANGE_MENU: {
      let menu = util.getMenuByAlias(action.payload);

      if (menu === undefined) {
        return state;
      }

      if (!util.isAvailableMenu(menu.id, state.menus)) {
        // SXLayout App
        if (state.menu === "machine" || state.menu === "machine_type" || state.menu === "plot_plan") {
          newState = newState.set("menu", menu.alias);
        } else {
          menu = util.getMenuByName("home");
          newState = newState.set("menu", menu.alias);
        }
      } else {
        newState = newState.set("menu", action.payload);
      }

      return newState.toJS();
    }
    case constants.SUMMARY_SELECT_CATEGORY_SUMMARY: {
      newState = newState.set("menu", "matrix");

      return newState.toJS();
    }
    case constants.APP_SHOW_CONSTRUCTION_SELECTOR: {
      newState = newState.set("visibleConstructionSelector", true);

      return newState.toJS();
    }
    case constants.APP_HIDE_CONSTRUCTION_SELECTOR: {
      newState = newState.set("visibleConstructionSelector", false);

      return newState.toJS();
    }
    case constants.APP_SHOW_PASSWORD_EDITOR: {
      newState = newState.set("visiblePasswordEditor", true);

      return newState.toJS();
    }
    case constants.APP_HIDE_PASSWORD_EDITOR: {
      newState = newState.set("visiblePasswordEditor", false);

      return newState.toJS();
    }
    case constants.APP_SHOW_LANG_SELECTOR: {
      newState = newState.set("visibleLangSelector", true);

      return newState.toJS();
    }
    case constants.APP_HIDE_LANG_SELECTOR: {
      newState = newState.set("visibleLangSelector", false);

      return newState.toJS();
    }
    case constants.APP_ADD_TOAST_CONTENT: {
      const { id, message, type } = action.payload;

      newState = newState.update("toastContents", (toastContents) =>
        toastContents.push({
          id,
          message,
          type,
        })
      );

      return newState.toJS();
    }
    case constants.APP_REMOVE_TOAST_CONTENT: {
      newState = newState.update("toastContents", (toastContents) =>
        toastContents.filter((content) => {
          return content.get("id") !== action.payload;
        })
      );

      return newState.toJS();
    }
    case constants.APP_SHOW_ALERT: {
      newState = newState.set("alert", action.payload);

      return newState.toJS();
    }
    case constants.APP_HIDE_ALERT: {
      newState = newState.set("alert", null);

      return newState.toJS();
    }
    case constants.APP_SHOW_CONFIRM: {
      newState = newState.set("confirm", action.payload);

      return newState.toJS();
    }
    case constants.APP_HIDE_CONFIRM: {
      newState = newState.set("confirm", null);

      return newState.toJS();
    }
    case constants.APP_CHANGE_LANG: {
      const { lang } = action.payload;
      newState = newState.set("lang", lang);

      return newState.toJS();
    }
    case constants.APP_SAVE_LANG: {
      initialLang = state.lang;

      return state;
    }

    default:
      return state;
  }
};

export default app;
