import { ClassValue } from "clsx";
import { ComponentProps, FC } from "react";

import { cn } from "@/lib/classNames";

export type ModalFooterProps = Omit<ComponentProps<"div">, "className"> & {
  className?: ClassValue;
};

export const ModalFooter: FC<ModalFooterProps> = ({ className, ...restProps }) => {
  return (
    <div className={cn("flex items-center justify-center p-[12px] gap-x-[10px] bg-white", className)} {...restProps} />
  );
};
