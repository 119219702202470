import { connect } from "react-redux";

import actions from "../../actions/index";
import Inspection from "../../components/list/Inspection";

const mapStateToProps = (state) => {
  let areas = [];
  let devices = [];
  let categories = [];
  let facilityManagements = [];
  let constructionManagements = [];
  let primaryCharges = [];
  let others = [];
  let processMajorClasses = [];
  let checkpoints = [];
  let systems = [];
  let companies = [];
  let users = [];
  let groups = [];
  let userRole;

  if (state.construction.masters) {
    areas = state.construction.masters.areas;
    devices = state.construction.masters.devices;
    categories = state.construction.masters.categories;
    facilityManagements = state.construction.masters.facility_managements;
    constructionManagements = state.construction.masters.construction_managements;
    primaryCharges = state.construction.masters.primary_charges;
    others = state.construction.masters.others;
    processMajorClasses = state.construction.masters.process_major_classes;
    checkpoints = state.construction.masters.checkpoints;
    systems = state.construction.masters.systems;
    companies = state.construction.masters.companies;
    users = state.construction.masters.users;
    groups = state.construction.masters.groups;
    userRole = state.construction.masters.user_role;
  }

  return {
    masters: {
      areas,
      devices,
      categories,
      facilityManagements,
      constructionManagements,
      primaryCharges,
      others,
      processMajorClasses,
      checkpoints,
      systems,
      companies,
      users,
      groups,
      userRole,
    },
    areaIds: state.inspection.areaIds,
    deviceIds: state.inspection.deviceIds,
    categoryId: state.inspection.categoryId,
    facilityManagementIds: state.inspection.facilityManagementIds,
    constructionManagementIds: state.inspection.constructionManagementIds,
    primaryChargeIds: state.inspection.primaryChargeIds,
    otherIds: state.inspection.otherIds,
    processMajorClassIds: state.inspection.processMajorClassIds,
    scheduleDate: state.inspection.scheduleDate,
    checkpointIds: state.inspection.checkpointIds,
    itemText: state.inspection.itemText,
    processText: state.inspection.processText,
    filter: state.inspection.filter,
    items: state.inspection.items,
    switcherStatus: state.inspection.switcherStatus,
    fetching: state.inspection.fetching,
    isError: state.inspection.isError,
    validated: state.inspection.validated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeMenu: (menu) => dispatch(actions.app.changeMenu(menu)),
    changeArea: (items) => dispatch(actions.inspection.changeArea(items)),
    changeDevice: (items) => dispatch(actions.inspection.changeDevice(items)),
    changeCategory: (item) => dispatch(actions.inspection.changeCategory(item)),
    changeFacilityManagement: (items) => dispatch(actions.inspection.changeFacilityManagement(items)),
    changeConstructionManagement: (items) => dispatch(actions.inspection.changeConstructionManagement(items)),
    changePrimaryCharge: (items) => dispatch(actions.inspection.changePrimaryCharge(items)),
    changeOther: (items) => dispatch(actions.inspection.changeOther(items)),
    changeProcessMajorClass: (items) => dispatch(actions.inspection.changeProcessMajorClass(items)),
    changeScheduleDate: (scheduleDate) => dispatch(actions.inspection.changeScheduleDate(scheduleDate)),
    changeCheckpoint: (items) => dispatch(actions.inspection.changeCheckpoint(items)),
    changeItemText: (itemText) => dispatch(actions.inspection.changeItemText(itemText)),
    changeProcessText: (processText) => dispatch(actions.inspection.changeProcessText(processText)),
    changeFilter: (flag) => dispatch(actions.inspection.changeFilter(flag)),
    search: (params, callback) => dispatch(actions.inspection.search(params, callback)),
    clearSearch: () => dispatch(actions.inspection.clearSearch()),
    revertLocalCondition: () => dispatch(actions.inspection.revertLocalCondition()),
    changeSwitcherStatus: (status) => {
      dispatch(actions.inspection.changeSwitcherStatus(status));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inspection);
