import * as constants from "@/constants";
import { UnworkDayGroup, UnworkDaySearchResult } from "@/models/holiday";

export type HolidayState = {
  unworkDayGroups: UnworkDayGroup[] | undefined; // データ検索していない場合はundefined, 検索して0件の場合は空の配列とする
  expandedRowIndex: number;
  fetching: boolean; // TODO 取得に時間かからないのであまり意味ないかも
};

export const holiday = (
  state: HolidayState = {
    unworkDayGroups: undefined,
    expandedRowIndex: -1,
    fetching: true,
  },
  action
) => {
  switch (action.type) {
    case constants.UNWORK_DAYS_START_LOADING:
      return { ...state, fetching: true };
    case constants.UNWORK_DAYS_END_LOADING:
      return { ...state, fetching: false };
    case constants.UNWORK_DAYS_END_FETCH: {
      const list: UnworkDaySearchResult[] = action.payload?.list ?? [];
      const groups: UnworkDayGroup[] = list.map((v) => ({ ...v, unwork_days: v.unwork_days.map((d) => new Date(d)) }));
      return { ...state, unworkDayGroups: groups };
    }
    case constants.HOLIDAY_EXPAND_ROW:
      return { ...state, expandedRowIndex: action.payload };
    case constants.UNWORK_DAYS_END_UPDATE: {
      const groups = state.unworkDayGroups.concat();
      const result: UnworkDaySearchResult = action.payload;
      const target = { ...result, unwork_days: result.unwork_days.map((d) => new Date(d)) };
      groups.splice(state.expandedRowIndex, 1, target);
      return { ...state, unworkDayGroups: groups };
    }
    default:
      return state;
  }
};
