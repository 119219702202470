import * as constants from "../constants";
import restoreState from "../lib/restoreState";
import storageManager from "../lib/storageManager";

// OS判定
export const isWinOS = window.navigator.userAgent.toLowerCase().indexOf("windows") !== -1;

export const isIE = () => {
  const ua = window.navigator.userAgent.toLocaleLowerCase();

  return ua.indexOf("msie") !== -1 || ua.indexOf("trident") !== -1;
};

export const addHeightResizeListener = (element, callback, duration = 200) => {
  if (!element) {
    return;
  }
  let lastHeight = element.clientHeight;
  const run = () => {
    const newHeight = element.clientHeight;
    if (lastHeight != newHeight) {
      callback && callback();
    }
    lastHeight = newHeight;
    if (element.onElementHeightChangeTimer) {
      clearTimeout(element.onElementHeightChangeTimer);
    }
    element.onElementHeightChangeTimer = setTimeout(run, duration);
  };
  run();
};

export const removeHeightResizeListener = (element) => {
  if (element && element.onElementHeightChangeTimer) clearTimeout(element.onElementHeightChangeTimer);
};

export const getTableBodyHeight = (menu, searchAreaElement, tableHeader): number => {
  // TODO もっと楽にテーブルの高さ調整できないか
  const searchAreaHeight = searchAreaElement ? searchAreaElement.offsetHeight : 0;
  const searchAreaMargin = searchAreaElement ? 20 : 0;
  const searchHeight = searchAreaHeight + searchAreaMargin;
  const headerHeight = 65;
  let pageTtl = 55.45;
  const statusSwitcherHeight = 44;
  let tableHeaderHeight = tableHeader ? tableHeader.offsetHeight : 28;
  const fileExport = 45;
  const buttonHeight = 45;
  let marginBottom = 16;
  const pagination = 30;

  let tableBodyMaxHeight;

  switch (menu) {
    case "approval":
      tableBodyMaxHeight =
        window.innerHeight -
        headerHeight -
        pageTtl -
        searchHeight -
        statusSwitcherHeight -
        tableHeaderHeight -
        pagination;

      return tableBodyMaxHeight;

    case "schedule_eneos_kawasaki":
      tableHeaderHeight = tableHeader ? tableHeader.offsetHeight : 42;
      pageTtl = 60;
      tableBodyMaxHeight = headerHeight + pageTtl + buttonHeight + searchHeight + pagination;

      return tableBodyMaxHeight;

    case "schedule_chiba":
      tableHeaderHeight = tableHeader ? tableHeader.offsetHeight : 42;
      pageTtl = 60;
      tableBodyMaxHeight = headerHeight + pageTtl + buttonHeight + searchHeight + pagination;

      return tableBodyMaxHeight;

    case "qrcode":
      tableBodyMaxHeight =
        window.innerHeight - headerHeight - pageTtl - searchHeight - tableHeaderHeight - marginBottom;

      return tableBodyMaxHeight;

    case "inspection":
      tableBodyMaxHeight =
        window.innerHeight -
        headerHeight -
        pageTtl -
        searchHeight -
        statusSwitcherHeight -
        tableHeaderHeight -
        marginBottom;

      return tableBodyMaxHeight;

    case "import":
      pageTtl = 60;
      tableBodyMaxHeight =
        window.innerHeight -
        headerHeight -
        pageTtl -
        searchHeight -
        statusSwitcherHeight -
        tableHeaderHeight -
        pagination;

      return tableBodyMaxHeight;

    case "summary":
      pageTtl = 60;
      tableBodyMaxHeight =
        window.innerHeight - headerHeight - pageTtl - searchHeight - fileExport - tableHeaderHeight - marginBottom;

      return tableBodyMaxHeight;

    case "system":
      tableBodyMaxHeight =
        window.innerHeight -
        headerHeight -
        pageTtl -
        searchHeight -
        statusSwitcherHeight -
        tableHeaderHeight -
        pagination;

      return tableBodyMaxHeight;

    case "asset":
      tableBodyMaxHeight =
        window.innerHeight - headerHeight - pageTtl - searchHeight - buttonHeight - tableHeaderHeight - pagination;

      return tableBodyMaxHeight;

    case "home":
      marginBottom = 10;
      tableBodyMaxHeight = window.innerHeight - headerHeight - pageTtl - marginBottom;

      return tableBodyMaxHeight;

    default:
      return window.innerHeight - 440;
  }
};

export const getStatusText = (statusNumber) => {
  switch (statusNumber) {
    case 0:
      return "−";
    case 1:
      return "";
    case 2:
      return "○";
    case 3:
      return "◎";
    case 4:
      return "■";
    case 5:
      return "△";
    case 6:
      return "◇";
    case 7:
      return "×";
    case 8:
      return "●";
    case 9:
      return "②";
    case 10:
      return "③";
    case 11:
      return "⓶";
    case 12:
      return "⓷";
    default:
      return "";
  }
};

export const getStatusTextLong = (statusNumber) => {
  let result = "";

  switch (statusNumber) {
    case 0:
      result = "−(作業なし)";

      break;
    case 1:
      result = "";

      break;
    case 2:
      result = "○(予定)";

      break;
    case 3:
      result = "◎(継続)";

      break;
    case 4:
      result = "■(完了見込み)";

      break;
    case 5:
      result = "△(中断)";

      break;
    case 6:
      result = "◇";

      break;
    case 7:
      result = "×";

      break;
    case 8:
      result = "●(完了)";

      break;
  }

  return result;
};

export const getConstructionId = () => {
  const urlState = restoreState();

  if (urlState) {
    return urlState.constructionId;
  }

  const constructionId = storageManager.getUserItem("construction_id");

  if (constructionId === null) {
    return false;
  }

  return constructionId;
};

export const setConstructionId = (id) => {
  storageManager.setUserItem("construction_id", id);
};

export const getSessionToken = () => {
  const authenticatedDataJSON = localStorage.getItem("authenticatedData");

  if (authenticatedDataJSON !== null) {
    const authenticatedData = JSON.parse(authenticatedDataJSON);

    return authenticatedData.session_token;
  }

  return null;
};

export const getFunctions = () => {
  const authenticatedDataJSON = localStorage.getItem("authenticatedData");

  if (authenticatedDataJSON !== null) {
    const authenticatedData = JSON.parse(authenticatedDataJSON);

    return {
      func_progress: authenticatedData.func_progress,
      func_layout: authenticatedData.func_layout,
    };
  }

  return null;
};

export const getUserId = () => {
  const authenticatedDataJSON = localStorage.getItem("authenticatedData");

  if (authenticatedDataJSON !== null) {
    const authenticatedData = JSON.parse(authenticatedDataJSON);

    return authenticatedData.user_id;
  }

  return null;
};

export const getData = (url, callback) => {
  const params = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
  };

  const sessionToken = getSessionToken();

  if (sessionToken !== null) {
    params.headers = {
      session_token: sessionToken,
    };
  }

  fetch(url, params)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      throw response;
    })
    .then((response) => {
      callback(response);
    })
    .catch((response) => {
      callback(null);
    });
};

export const getColorClassName = (colorNumber) => {
  let result = "";

  switch (colorNumber) {
    case 1:
      result = "gray";

      break;
    case 2:
      result = "blue";

      break;
    case 3:
      result = "orange";

      break;
    case 4:
      result = "yellow";

      break;
  }

  if (result === "") {
    return "";
  }

  return `grid-cell-${result}`;
};

export const getMenuByName = (name) => {
  return constants.MENULIST.find((m) => m.name === name);
};

export const getMenuByAlias = (alias) => {
  return constants.MENULIST.find((m) => m.alias === alias);
};

export const getMenuById = (id) => {
  return constants.MENULIST.find((m) => m.id === id);
};

export const isAvailableMenu = (menuId, menus) => {
  return menus.some((m) => m.menu_id === menuId && m.active);
};

export const createClickEvent = () => {
  const e = document.createEvent("MouseEvents");
  e.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);

  return e;
};

export const formatScheduleValue = (target: string, v?: string | number) => {
  if (target === "field_t8") {
    return convertToValidSafetyMattersString(v);
  } else if (target === "field_t1") {
    if (!v && v !== 0) return "";
    const num = parseInt(v.toString());

    return isNaN(num) ? 0 : num;
  }

  return v;
};

export const downloadFile = (blob, filename) => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.className = "d-n";
  a.href = URL.createObjectURL(blob);
  a.download = filename;
  a.click();
  URL.revokeObjectURL(a.href);
  a.remove();
};

export const convertToValidSafetyMattersString = (str) => {
  const splitStr = str.split("");

  // 半角数値の1~8以外の値を削除する
  let filteredStr = splitStr.filter((s) => s.match(/^[1-8]$/));

  // 重複を削除する
  filteredStr = filteredStr.filter((s, i, self) => self.indexOf(s) === i);

  // 昇順にソートする
  filteredStr.sort((a, b) => a - b);

  // 半角カンマで連結する
  return filteredStr.join(",");
};

export const insertNewLine = (str: string, len: number): string => {
  if (!str) return "";
  const pattern = new RegExp(`.{1,${len}}`, "g");

  return str
    .split("\n")
    .map((line) => line.match(pattern).join("\n"))
    .join("\n");
};

export const getScheduleTableExpantionState = () => {
  const scheduleExpandedStateString = localStorage.getItem("scheduleExpandedState") || "{}";

  return JSON.parse(scheduleExpandedStateString);
};

export const omitString = (text: string | null | undefined, len: number): string => {
  if (!text) return "";
  if (len < text.length) {
    return text.slice(0, len) + "...";
  }

  return text;
};
