import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

const TooltipChiba = ({ data, t }) => {
  const { value } = data;

  return (
    <div role="tooltip" className="schedule-tip in tooltip">
      <div className="tooltip-inner">
        <div className="form-txt wrap">{value}</div>
      </div>
    </div>
  );
};

TooltipChiba.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(TooltipChiba);
