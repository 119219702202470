import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import validator from "validator";

import Modal from "../Modal";
import { SubmitButton } from "@/components/common/SubmitButton";

class CheckpointEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkpoint_id: 0,
      category_id: "",
      checkpoint_name: "",
      sort: "",
      timestamp: "",
      error: {},
      loading: false,
    };

    this.handleChangeCategoryName = this.handleChangeCategoryName.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeSort = this.handleChangeSort.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.props.setUnloadAlert();
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();
  }

  // eslint-disable-next-line react/no-deprecated -- 暫定処置でdisableしている
  componentWillReceiveProps(nextProps) {
    const data = nextProps.data;

    if (data) {
      const { checkpoint_id, category_id, checkpoint_name, sort, timestamp } = data;

      this.setState({
        checkpoint_id,
        category_id,
        checkpoint_name,
        sort,
        timestamp,
        loading: false,
        error: {},
      });
    } else {
      this.setState({
        checkpoint_id: 0,
        category_id: "",
        checkpoint_name: "",
        sort: "",
        timestamp: "",
        loading: false,
        error: {},
      });
    }
  }

  handleChangeCategoryName(e) {
    this.setState({
      category_id: e.target.value,
    });
  }

  handleChangeName(e) {
    this.setState({
      checkpoint_name: e.target.value,
    });
  }

  handleChangeSort(e) {
    this.setState({
      sort: e.target.value,
    });
  }

  handleSave() {
    if (this.state.loading) return;
    const error = {};
    const checkpoint_name = this.state.checkpoint_name ? this.state.checkpoint_name.trim() : "";
    const newState = { ...this.state, checkpoint_name };

    if (this.state.category_id == 0 || this.state.category_id == "") {
      error.category_name = "category_name";
      error.msgid = "is_not_selected";
    }

    if (validator.isEmpty(checkpoint_name)) {
      error.checkpoint_name = "checkpoint_name";
      error.msg = "is_required";
    } else if (checkpoint_name.length > 100) {
      error.checkpoint_name = "checkpoint_name";
      error.msg = "is_too_long";
    }

    if (!validator.isInt(this.state.sort.toString())) {
      error.sort = "display_order_is_invalid";
    } else if (this.state.sort > 999999999) {
      error.sort = "display_order_maximum";
    } else if (this.state.sort < 0) {
      error.sort = "display_order_negative";
    } else if (this.state.sort.toString().match(/^-0+$/)) {
      newState.sort = 0;
    } else {
      newState.sort = parseInt(this.state.sort, 10);
    }

    newState.error = error;

    if (Object.keys(error).length > 0) {
      this.setState(newState);
    } else {
      newState.loading = true;
      this.setState(newState);
      this.props.saveHandler(
        newState,
        () => this.setState({ loading: false }),
        () => this.setState({ loading: false })
      );
    }
  }

  render() {
    const { category_id, checkpoint_name, sort, error, loading } = this.state;
    const { fetching, closeHandler, t, categories } = this.props;

    return (
      <Modal title={t("checkpoint_dialog_title")} loading={fetching} closeHandler={closeHandler}>
        <div className="modal-body">
          <div className="form-row">
            <div className="form-group w-360">
              <span className="form-label txt-bold">{t("category_name")}</span>
              <select className="form-control w-250" value={category_id} onChange={this.handleChangeCategoryName}>
                <option key={0} value={0}>
                  -----
                </option>
                {categories.map((category) => (
                  <option key={category.category_id} value={category.category_id}>
                    {category.category_name}
                  </option>
                ))}
              </select>
            </div>
            {error.category_name && (
              <div className="form-error w-400">
                <p className="form-error-message w-290">
                  {t(error.category_name)}
                  {t(error.msgid)}
                </p>
              </div>
            )}
          </div>
          <div className="form-row">
            <div className="form-group w-400">
              <span className="form-label txt-bold">{t("checkpoint_name")}</span>
              <input
                data-test-id="text-checkpoint-edit-checkpoint-name"
                type="text"
                className="form-control w-290"
                value={checkpoint_name}
                onChange={this.handleChangeName}
              />
            </div>
            {error.checkpoint_name && (
              <div className="form-error w-400">
                <p className="form-error-message w-290">
                  {t(error.checkpoint_name)}
                  {t(error.msg)}
                </p>
              </div>
            )}
          </div>
          <div className="form-row">
            <div className="form-group w-230">
              <span className="form-label txt-bold">{t("display_order")}</span>
              <input
                data-test-id="text-checkpoint-edit-sort"
                type="number"
                min="0"
                className="form-control w-120"
                value={sort}
                onChange={this.handleChangeSort}
              />
            </div>
            {error.sort && (
              <div className="form-error w-400">
                <p className="form-error-message w-290">{t(error.sort)}</p>
              </div>
            )}
          </div>
        </div>
        <div className={"modal-footer"}>
          <button
            data-test-id="button-checkpoint-edit-cancel"
            type="button"
            className="btn btn-gray"
            disabled={loading}
            onClick={closeHandler}
          >
            {t("cancel")}
          </button>
          <SubmitButton data-test-id="button-checkpoint-edit-save" onClick={this.handleSave} loading={loading} />
        </div>
      </Modal>
    );
  }
}

CheckpointEditor.defaultProps = {
  data: null,
};

CheckpointEditor.propTypes = {
  data: PropTypes.object,
  fetching: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  saveHandler: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
};

export default withTranslation()(CheckpointEditor);
