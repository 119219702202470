import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import Modal from "../Modal";

const Help = ({ closeHandler, saveHandler, t }) => {
  return (
    <Modal title={t("help")} closeHandler={() => closeHandler()}>
      <div className="modal-body clearfix modal-matrix-help">
        <div className="w-510 clearfix">
          <div className="modal-body-left w-240">
            <h4 className="help-ttl">
              <span className="icon icon-help_outline"></span>
              {t("detail_mark")}
            </h4>
            <table className="help-tbl mark-tbl">
              <tbody>
                <tr>
                  <th>{t("blank")}</th>
                  <td>{t("process_exist")}</td>
                </tr>
                <tr>
                  <th>ー</th>
                  <td>{t("no_process")}</td>
                </tr>
                <tr>
                  <th className="status-mark">○</th>
                  <td>{t("schedule")}</td>
                </tr>
                <tr>
                  <th className="status-mark">②</th>
                  <td>{t("schedule_two_days")}</td>
                </tr>
                <tr>
                  <th className="status-mark">③</th>
                  <td>{t("schedule_three_days")}</td>
                </tr>
                <tr>
                  <th className="status-mark">◎</th>
                  <td>{t("keep")}</td>
                </tr>
                <tr>
                  <th className="status-mark">⓶</th>
                  <td>{t("continue_two_days")}</td>
                </tr>
                <tr>
                  <th className="status-mark">⓷</th>
                  <td>{t("continue_three_days")}</td>
                </tr>
                <tr>
                  <th className="status-mark">■</th>
                  <td>{t("completion_expected")}</td>
                </tr>
                <tr>
                  <th className="status-mark">△</th>
                  <td>{t("stop")}</td>
                </tr>
                <tr>
                  <th className="status-mark">●</th>
                  <td>{t("completed")}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="modal-body-right w-240">
            <h4 className="help-ttl">
              <span className="icon icon-help_outline"></span>
              {t("detail_date")}
            </h4>
            <table className="help-tbl date-tbl">
              <tbody>
                <tr>
                  <th>{t("upper_date")}</th>
                  <td>
                    <span className="plan">（12/31）</span>
                    {t("plan")}
                    {t("space")}
                    {t("start_day")}
                    <br />
                    　12/31　{t("results")}
                    {t("space")}
                    {t("start_day")}
                  </td>
                </tr>
                <tr>
                  <th>{t("under_date")}</th>
                  <td>
                    <span className="plan">（12/31）</span>
                    {t("plan")}
                    {t("space")}
                    {t("end_day")}
                    <br />
                    　12/31　{t("results")}
                    {t("space")}
                    {t("end_day")}
                  </td>
                </tr>
              </tbody>
            </table>
            <h4 className="help-ttl mt-20">
              <span className="icon icon-help_outline"></span>
              {t("detail_color")}
            </h4>
            <table className="help-tbl bgcolor-tbl">
              <tbody>
                <tr>
                  <th className="th-blue"></th>
                  <td>
                    {t("background_lightblue")}
                    <br />
                    {t("scheduled")}
                  </td>
                </tr>
                <tr>
                  <th className="th-green"></th>
                  <td>
                    {t("background_green")}
                    <br />
                    {t("bg_green_means")}
                  </td>
                </tr>
                <tr>
                  <th className="th-yellow"></th>
                  <td>
                    {t("background_yellow")}
                    <br />
                    {t("expired")}
                  </td>
                </tr>
                <tr>
                  <th className="th-orange"></th>
                  <td>
                    {t("background_orange")}
                    <br />
                    {t("expired_three_days")}
                  </td>
                </tr>
                <tr>
                  <th className="th-error">
                    <div className="cell-wrap"></div>
                  </th>
                  <td>
                    {t("border_red")}
                    <br />
                    {t("status_change_failed")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="w-510 mt-20">
          <h4 className="help-ttl">
            <span className="icon icon-help_outline"></span>
            {t("icon")}
          </h4>
          <table className="help-tbl icon-tbl">
            <tbody>
              <tr>
                <th>
                  <span className="icon icon-mode_edit"></span>
                </th>
                <td className="icon-help-font">
                  {t("means_editable")}
                  <br />
                  {t("open_edit_panel_with_click")}
                </td>
              </tr>
              <tr>
                <th>
                  <span className="icon icon-attach_file"></span>
                </th>
                <td className="icon-help-font">
                  {t("upper_clip")}
                  <br />
                  {t("under_clip")}
                </td>
              </tr>
              <tr>
                <th>
                  <span className="icon icon-visibility_off"></span>
                </th>
                <td className="icon-help-font">{t("print_off")}</td>
              </tr>
              <tr>
                <th>
                  <span className="icon icon-forward"></span>
                </th>
                <td className="icon-help-font">{t("linking_off")}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-footer">
        <button data-test-id="button-help-close" type="button" className="btn btn-gray" onClick={() => closeHandler()}>
          {t("close")}
        </button>
      </div>
    </Modal>
  );
};

Help.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  saveHandler: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Help);
