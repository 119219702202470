import * as constants from "../constants/index";
import environments from "../environments";
import storageManager from "../lib/storageManager";

export const changeArea = (items) => ({
  type: constants.INSPECTION_CHANGE_AREA,
  payload: items.map((item) => item.value),
});

export const changeDevice = (items) => ({
  type: constants.INSPECTION_CHANGE_DEVICE,
  payload: items.map((item) => item.value),
});

export const changeCategory = (item) => ({
  type: constants.INSPECTION_CHANGE_CATEGORY,
  payload: item !== null ? item.value : 0,
});

export const changeFacilityManagement = (items) => ({
  type: constants.INSPECTION_CHANGE_FACILITY_MANAGEMENT,
  payload: items.map((item) => item.value),
});

export const changeConstructionManagement = (items) => ({
  type: constants.INSPECTION_CHANGE_CONSTRUCTION_MANAGEMENT,
  payload: items.map((item) => item.value),
});

export const changePrimaryCharge = (items) => ({
  type: constants.INSPECTION_CHANGE_PRIMARY_CHARGE,
  payload: items.map((item) => item.value),
});

export const changeOther = (items) => ({
  type: constants.INSPECTION_CHANGE_OTHER,
  payload: items.map((item) => item.value),
});

export const changeProcessMajorClass = (items) => ({
  type: constants.INSPECTION_CHANGE_PROCESS_MAJOR_CLASS,
  payload: items.map((item) => item.value),
});

export const changeScheduleDate = (scheduleDate) => ({
  type: constants.INSPECTION_CHANGE_SCHEDULE_DATE,
  payload: scheduleDate,
});

export const changeCheckpoint = (items) => ({
  type: constants.INSPECTION_CHANGE_CHECKPOINT,
  payload: items.map((item) => item.value),
});

export const changeItemText = (itemText) => ({
  type: constants.INSPECTION_CHANGE_ITEM_TEXT,
  payload: itemText,
});

export const changeProcessText = (processText) => ({
  type: constants.INSPECTION_CHANGE_PROCESS_TEXT,
  payload: processText,
});

export const changeFilter = (flag) => ({
  type: constants.INSPECTION_CHANGE_FILTER,
  payload: flag,
});

export const clearSearch = () => ({
  type: constants.INSPECTION_CLEAR_SEARCH,
});

export const revertLocalCondition = () => ({
  type: constants.INSPECTION_REVERT_CONDITION,
});

export const setValidated = (validated) => ({
  type: constants.INSPECTION_SET_VALIDATED,
  payload: validated,
});

export const search = (params, callback) => {
  let endpoint = `${environments.API_BASE_URI}/list/inspections`;
  const query = new URLSearchParams();
  let infinite = false;

  if (params && params.areaIds && params.areaIds.length > 0) {
    params.areaIds.forEach((param) => query.append("area_id", param));
  }

  if (params && params.deviceIds && params.deviceIds.length > 0) {
    params.deviceIds.forEach((param) => query.append("device_id", param));
  }

  if (params && params.categoryId && params.categoryId > 0) {
    query.set("category_id", params.categoryId);
  }

  if (params && params.facilityManagementIds && params.facilityManagementIds.length > 0) {
    params.facilityManagementIds.forEach((param) => query.append("facility_management_id", param));
  }

  if (params && params.constructionManagementIds && params.constructionManagementIds.length > 0) {
    params.constructionManagementIds.forEach((param) => query.append("construction_management_id", param));
  }

  if (params && params.primaryChargeIds && params.primaryChargeIds.length > 0) {
    params.primaryChargeIds.forEach((param) => query.append("primary_charge_id", param));
  }

  if (params && params.otherIds && params.otherIds.length > 0) {
    params.otherIds.forEach((param) => query.append("other_id", param));
  }

  if (params && params.processMajorClassIds && params.processMajorClassIds.length > 0) {
    params.processMajorClassIds.forEach((param) => query.append("process_major_class_id", param));
  }

  if (params && params.scheduleDate) {
    query.set("schedule_date", params.scheduleDate.replace(/\//g, "-"));
  }

  if (params && params.checkpointIds && params.checkpointIds.length > 0) {
    params.checkpointIds.forEach((param) => query.append("checkpoint_id", param));
  }

  if (params && params.itemText) {
    query.set("item_text", params.itemText);
  }

  if (params && params.processText) {
    query.set("process_text", params.processText);
  }

  if (params && params.filter && params.filter.length > 0) {
    params.filter.forEach((param) => query.append("filter", param));
  }

  if (params && params.limit) {
    query.set("limit", params.limit);
  }

  if (params && params.start) {
    query.set("start", params.start);
  }

  if (params && params.start && params.start > 1) {
    infinite = true;
  }

  storageManager.setConstructionItem("inspectionSearchParams", JSON.stringify(params));
  endpoint = `${endpoint}?${query.toString()}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: infinite ? constants.INSPECTION_BEGIN_SEARCH_INFINITE : constants.INSPECTION_BEGIN_SEARCH,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: infinite ? constants.INSPECTION_END_SEARCH_INFINITE : constants.INSPECTION_END_SEARCH,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const changeSwitcherStatus = (status) => {
  return {
    type: constants.INSPECTION_CHANGE_SWITCHER_STATUS,
    payload: status,
  };
};
