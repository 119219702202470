import { useState } from "react";

import { ScheduleUrl } from "@/models/scheduleChiba";

export const useUrlList = () => {
  const [data, setData] = useState<ScheduleUrl[]>([]);

  const add = (url: ScheduleUrl) => {
    setData([url, ...data]);
  };

  const update = (url: ScheduleUrl) => {
    const list = data.concat();
    const index = list.findIndex((v) => v.item_url_id?.toString() === url.item_url_id?.toString());
    if (0 <= index) {
      list.splice(index, 1, url);
      setData(list);
    }
  };

  const remove = (urlId: number) => {
    const list = data.concat();
    setData(list.filter((v) => v.item_url_id?.toString() !== urlId?.toString()));
  };

  return {
    data,
    setData,
    add,
    update,
    remove,
  };
};
