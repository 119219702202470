import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";

import * as util from "../../lib/common";
import { compareInazumaCell } from "../../lib/summary";

class RightGridRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategories: 0,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.isInazumaSelecting ||
      this.props.isGraphSelecting ||
      this.props.isScheduleSelecting ||
      this.props.isDelaySelecting
    ) {
      return true;
    }

    if (!_.isEqual(this.props.row, nextProps.row)) {
      return true;
    }

    return false;
  }

  handleClick(row, data) {
    if (this.props.isInazumaSelecting) {
      if (data.item_num === undefined) {
        return;
      }

      this.props.onClickCellInazuma(row, data);
    } else if (this.props.isGraphSelecting) {
      if (data.item_num === undefined) {
        return;
      }

      this.props.onClickCellGraph(row, data);
    } else if (this.props.isScheduleSelecting) {
      if (data.item_num === undefined) {
        return;
      }

      this.props.onClickCellSchedule(row, data);
    } else if (this.props.isDelaySelecting) {
      if (data.item_num === undefined) {
        return;
      }

      this.props.onClickCellDelay(row, data);
    } else {
      if (data.item_num > 0) {
        this.props.onClickCell(row, data);
      }
    }
  }

  render() {
    return (
      <tr>
        {this.props.row.category_summaries.map((data, index) => {
          const cell = { ...this.props.row, ...data };
          const isKey = "item_num" in data ? true : false;

          return (
            <td
              key={index}
              className={`
                ${util.getColorClassName(data.background_color)}
                ${
                  this.props.isInazumaSelecting &&
                  this.props.inazumaSelectedCells.find((c) => compareInazumaCell(c, cell))
                    ? "inazuma-border"
                    : ""
                }
                ${
                  this.props.isGraphSelecting && this.props.graphSelectedCells.find((c) => compareInazumaCell(c, cell))
                    ? "inazuma-border"
                    : ""
                }
                ${
                  this.props.isScheduleSelecting &&
                  this.props.scheduleSelectedCells.find((c) => compareInazumaCell(c, cell))
                    ? "inazuma-border"
                    : ""
                }
                ${
                  this.props.isDelaySelecting && this.props.delaySelectedCells.find((c) => compareInazumaCell(c, cell))
                    ? "inazuma-border"
                    : ""
                }
              `}
              onClick={() => this.handleClick(this.props.row, data)}
            >
              {isKey ? (
                <div className="grid-cell-wrap grid-cell-clickable">
                  <div className="grid-cell-con">
                    <div className="grid-cell-inner">
                      <span className="grid-table-cell-item-num">{data.item_num}</span>
                      <span className="grid-table-cell-progress-rate">
                        {`${data.result_progress_rate}/${data.schedule_progress_rate}`}
                      </span>
                      {data.delivery_flg ? (
                        <span className="grid-table-cell-delivery-flg grid-icon icon icon-thumb_up"></span>
                      ) : (
                        <span className="grid-table-cell-delivery-flg grid-icon">&nbsp;</span>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </td>
          );
        })}
      </tr>
    );
  }
}

RightGridRow.propTypes = {
  index: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
  isInazumaSelecting: PropTypes.bool.isRequired,
  inazumaSelectedCells: PropTypes.array.isRequired,
  onClickCell: PropTypes.func.isRequired,
  onClickCellInazuma: PropTypes.func.isRequired,
  onClickCellGraph: PropTypes.func.isRequired,
  onClickCellSchedule: PropTypes.func.isRequired,
  onClickCellDelay: PropTypes.func.isRequired,
  isGraphSelecting: PropTypes.bool.isRequired,
  graphSelectedCells: PropTypes.array.isRequired,
  isScheduleSelecting: PropTypes.bool.isRequired,
  scheduleSelectedCells: PropTypes.array.isRequired,
  isDelaySelecting: PropTypes.bool.isRequired,
  delaySelectedCells: PropTypes.array.isRequired,
};

export default RightGridRow;
