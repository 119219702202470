export type TimeStamp = {
  insert_date: string;
  insert_user_id: string;
  insert_user_name: string;
  update_date: string;
  update_user_id: string;
  update_user_name: string;
};

export const DUMMY_TIMESTAMP: TimeStamp = {
  insert_date: "",
  insert_user_id: "0",
  insert_user_name: "",
  update_date: "",
  update_user_id: "0",
  update_user_name: "",
};
