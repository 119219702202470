import React, { useState } from "react";

import { cn } from "@/lib/classNames";

// Inspired by: https://codepen.io/dsenneff/pen/ZoLVZW

const ToggleWithWords = (props) => {
  const { labelOn, labelOff, isDefaultOn = true, onChange, id, disabled } = props;
  const onButtonId = `toggleWithWordsOn-${id}`;
  const offButtonId = `toggleWithWordsOff-${id}`;

  const [checked, setChecked] = useState({
    on: isDefaultOn,
    off: !isDefaultOn,
  });

  const handleChange = (e) => {
    const name = e.target.name;
    if (e.target.name === onButtonId) {
      setChecked({
        on: true,
        off: false,
      });

      onChange(name, true);
    } else {
      setChecked({
        on: false,
        off: true,
      });

      onChange(name, false);
    }
  };

  return (
    <div className="layout-toggle-button-with-words">
      <input
        type="checkbox"
        name={onButtonId}
        id={onButtonId}
        className={"peer/on"}
        onChange={handleChange}
        checked={checked.on}
        disabled={disabled}
      />
      <label
        htmlFor={onButtonId}
        className={cn(
          "cursor-pointer bg-white peer-checked/on:bg-[#dea321] peer-checked/on:text-white",
          disabled && "cursor-default bg-[#e9ecef] peer-checked/on:bg-[#aaa]"
        )}
      >
        {labelOn}
      </label>

      <input
        type="checkbox"
        name={offButtonId}
        id={offButtonId}
        className={"peer/off"}
        onChange={handleChange}
        checked={checked.off}
        disabled={disabled}
      />
      <label
        htmlFor={offButtonId}
        className={cn(
          "cursor-pointer bg-white peer-checked/off:bg-[#dea321] peer-checked/off:text-white",
          disabled && "cursor-default bg-[#e9ecef] peer-checked/off:bg-[#aaa]"
        )}
      >
        {labelOff}
      </label>
    </div>
  );
};

export { ToggleWithWords };
