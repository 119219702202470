import { connect } from "react-redux";

import actions from "../../actions/index";
import ScheduleChita from "../../components/list/ScheduleChita";

const mapStateToProps = (state) => {
  let areas = [];
  let devices = [];
  let categories = [];
  let facilityManagements = [];
  let constructionManagements = [];
  let primaryCharges = [];
  let others = [];
  let systems = [];
  let users = [];
  let groups = [];
  let userRole;

  if (state.construction.masters) {
    areas = state.construction.masters.areas;
    devices = state.construction.masters.devices;
    categories = state.construction.masters.categories;
    facilityManagements = state.construction.masters.facility_managements;
    constructionManagements = state.construction.masters.construction_managements;
    primaryCharges = state.construction.masters.primary_charges;
    others = state.construction.masters.others;
    systems = state.construction.masters.systems;
    users = state.construction.masters.users;
    groups = state.construction.masters.groups;
    userRole = state.construction.masters.user_role;
  }

  return {
    masters: {
      areas,
      devices,
      categories,
      facilityManagements,
      constructionManagements,
      primaryCharges,
      others,
      systems,
      users,
      groups,
      userRole,
    },
    areaIds: state.schedule_chita.areaIds,
    deviceIds: state.schedule_chita.deviceIds,
    categoryId: state.schedule_chita.categoryId,
    facilityManagementIds: state.schedule_chita.facilityManagementIds,
    constructionManagementIds: state.schedule_chita.constructionManagementIds,
    primaryChargeIds: state.schedule_chita.primaryChargeIds,
    field1Ids: state.schedule_chita.field1Ids,
    field2Ids: state.schedule_chita.field2Ids,
    field3Ids: state.schedule_chita.field3Ids,
    field4Ids: state.schedule_chita.field4Ids,
    scheduleDate: state.schedule_chita.scheduleDate,
    itemText: state.schedule_chita.itemText,
    processText: state.schedule_chita.processText,
    items: state.schedule_chita.items,
    bulkRoles: state.schedule_chita.bulkRoles,
    firstScheduleDate: state.schedule_chita.firstScheduleDate,
    scheduleUpdateDate: state.schedule_chita.scheduleUpdateDate,
    switcherStatus: state.schedule_chita.switcherStatus,
    fetching: state.schedule_chita.fetching,
    isError: state.schedule_chita.isError,
    validated: state.schedule_chita.validated,
    roles: state.app.roles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeArea: (items) => dispatch(actions.schedule_chita.changeArea(items)),
    changeDevice: (items) => dispatch(actions.schedule_chita.changeDevice(items)),
    changeCategory: (item) => dispatch(actions.schedule_chita.changeCategory(item)),
    changeFacilityManagement: (items) => dispatch(actions.schedule_chita.changeFacilityManagement(items)),
    changeConstructionManagement: (items) => dispatch(actions.schedule_chita.changeConstructionManagement(items)),
    changePrimaryCharge: (items) => dispatch(actions.schedule_chita.changePrimaryCharge(items)),
    changeField1: (items) => dispatch(actions.schedule_chita.changeField1(items)),
    changeField2: (items) => dispatch(actions.schedule_chita.changeField2(items)),
    changeField3: (items) => dispatch(actions.schedule_chita.changeField3(items)),
    changeField4: (items) => dispatch(actions.schedule_chita.changeField4(items)),
    changeScheduleDate: (scheduleDate) => dispatch(actions.schedule_chita.changeScheduleDate(scheduleDate)),
    changeItemText: (itemText) => dispatch(actions.schedule_chita.changeItemText(itemText)),
    changeProcessText: (processText) => dispatch(actions.schedule_chita.changeProcessText(processText)),
    search: (params, callback) => dispatch(actions.schedule_chita.search(params, callback)),
    getOptions: (callback) => dispatch(actions.schedule_chita.getOptions(callback)),
    clearSearch: (categoryId) => dispatch(actions.schedule_chita.clearSearch(categoryId)),
    revertLocalCondition: () => dispatch(actions.schedule_chita.revertLocalCondition()),
    download: (params, callback, fallback) => dispatch(actions.schedule_chita.download(params, callback, fallback)),
    updateItem: (data, handleSuccess, handleCancel) =>
      dispatch(actions.schedule_chita.updateItem(data, handleSuccess, handleCancel)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleChita);
