import { connect } from "react-redux";

import actions from "../actions";
import DataExportListDrawer from "../components/DataExportListDrawer/DataExportListDrawer";

const mapStateToProps = (state) => {
  const { fetching, jobs, end } = state.dataExport;

  return {
    fetching,
    jobs,
    end,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchJobs: (start) => dispatch(actions.dataExport.fetchJobs(start)),
    cancelJob: (fileId, timestamp, callback, fallback) =>
      dispatch(actions.dataExport.cancelJob(fileId, timestamp, callback, fallback)),
    downloadFile: (fileId, fileName) => dispatch(actions.dataExport.downloadFile(fileId, fileName)),
    clearJobs: () => dispatch(actions.dataExport.clearJobs()),
    readJob: (download_job_id) => dispatch(actions.dataExport.readJob(download_job_id)),
    readAllJob: () => dispatch(actions.dataExport.readAllJob()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataExportListDrawer);
