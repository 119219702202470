import clsx from "clsx";
import _ from "lodash";
import React, { useCallback, useMemo, useRef } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { isValidRole } from "../../lib/roleChecker";

import { MATRIX_TABLE_DATA_WIDTH, Process } from "@/models/matrix";
import { RootState } from "@/reducers/types";
import { omitString } from "@/lib/common";

type Props = {
  columns: any[];
  showWorkEditor: (processId) => void;
  showCreateWorkEditor: (processId) => void;
  showColumnEditor: (processId) => void;
  showDeleteDialog: (process) => void;
  showMiddleClassEditor: (processMiddleClassId) => void;
  handleSearch: (_?: { keepPage?: boolean }) => void;
  clearTitleSubmenu: () => void;
  clearProcessSubmenu: () => void;
  showAlert: (title, messages) => void;
  rightHeaderElement: HTMLDivElement;
};

type DispatchProps = {
  searchParams: any;
  setProcessSubmenu: (_: { x: number; y: number; process: Process }) => void;
  // 列移動
  columnDragStart: (column) => void;
  columnDragOver: (index) => void;
  columnDragScroll: (to) => void;
  columnDragEnd: () => void;
  sortProcess: (processId, data, callback?, failedCallback?) => void;
};

const RightGridHeader: React.FC<Props> = (props: Props & DispatchProps) => {
  const { t } = useTranslation();
  const { categoryId, primaryChargeId } = useSelector((state: RootState) => state.matrixSearch);
  const { dragColumn, dropIndex } = useSelector((state: RootState) => state.matrix);
  const isConsecutiveSort = useMemo(() => {
    // 作業列が1始まりで連続しているか＝フィルタされていないか
    // 抜け漏れがある場合に行移動を制限するのに使う
    const sorts = (props.columns ?? []).concat().map((v) => v.sort);
    return sorts[0] === 1 && sorts.every((v, i) => i === 0 || v - 1 === sorts[i - 1]);
  }, [props.columns]);
  const dragStartColumnIndex = useRef<number>(-1);
  const setDragging = useMemo(
    () =>
      _.debounce((column?, index?: number) => {
        const targetProcessHeader = document.getElementById(`right-grid-header-process-${index}`);
        if (0 <= index && targetProcessHeader) {
          if (!isConsecutiveSort) {
            props.showAlert(t("alert"), [t("cannot_sort_processes")]);
            return;
          }
          props.clearTitleSubmenu();
          props.clearProcessSubmenu();
          props.columnDragStart({
            id: column?.process_id,
            index,
            offsetLeft: targetProcessHeader.offsetLeft,
            offsetTop: targetProcessHeader.offsetTop,
            offsetWidth: targetProcessHeader.offsetWidth,
          });
          dragStartColumnIndex.current = index;
        }
      }, 1000),
    [isConsecutiveSort]
  );

  const getColspan = (start, propName, data) => {
    const value = data[start][propName];
    const prev = data[start - 1];

    if (!prev || prev[propName] !== value) {
      let colspan = 1;
      let i = start + 1;
      const len = data.length;

      for (; i < len; i += 1) {
        if (data[i][propName] === value) {
          colspan += 1;
        } else {
          return colspan;
        }
      }

      return colspan;
    }

    return 0;
  };

  const handleClickSubmenu = (e, column) => {
    props.clearTitleSubmenu();
    const menuWidth = 120; // コンテンツに依るが大体120px
    props.setProcessSubmenu({
      x: window.innerWidth < e.clientX + menuWidth ? window.innerWidth - menuWidth : e.clientX,
      y: e.clientY,
      process: column,
    });
    e.stopPropagation();
  };

  const handleColumnDrop = useCallback(() => {
    if (
      dragStartColumnIndex.current < 0 ||
      dragStartColumnIndex.current === dropIndex ||
      dragStartColumnIndex.current === dropIndex - 1
    ) {
      dragStartColumnIndex.current = -1;
      return;
    }
    const insertPrev = dropIndex < dragStartColumnIndex.current;
    props.sortProcess(
      dragColumn.id,
      {
        sort: dropIndex + (insertPrev ? 1 : 0),
        category_id: categoryId,
        primary_charge_id: primaryChargeId,
      },
      () => props.handleSearch({ keepPage: true })
    );
    dragStartColumnIndex.current = -1;
  }, [props.columns, dropIndex, dragColumn, categoryId, primaryChargeId]);

  const handleColumnDragOver = useMemo(
    () =>
      _.throttle((e: React.DragEvent<HTMLTableCellElement>, index: number) => {
        const gridRightHeader = props.rightHeaderElement;
        if (!gridRightHeader || !e.currentTarget) return;

        // 列の挿入位置を計算する
        // ポインターの位置 + ヘッダのスクロールで隠れている部分 - ヘッダの見えている部分の左位置 - ドロップしているセルの左位置 - 20(マージン)
        const xInCell =
          e.clientX + gridRightHeader.scrollLeft - gridRightHeader.offsetLeft - e.currentTarget.offsetLeft - 20;
        props.columnDragOver(index + (MATRIX_TABLE_DATA_WIDTH / 2 < xInCell ? 1 : 0));
      }, 70),
    [props.columnDragOver, props.rightHeaderElement]
  );

  const scrollCount = useRef(0);
  const handleColumnDragScroll = useMemo(
    () =>
      _.throttle((e: React.DragEvent<HTMLTableCellElement>) => {
        const gridRightHeader = props.rightHeaderElement;
        if (!gridRightHeader) return;

        // 左端または右端に寄っている場合スクロールする
        const gridHeaderRight = gridRightHeader.offsetLeft + gridRightHeader.offsetWidth + 20; // 20pxはマージン
        const gridHeaderLeft = gridRightHeader.offsetLeft + 20;
        scrollCount.current = scrollCount.current + 1;
        props.columnDragScroll(
          e.clientX < gridHeaderLeft + 30
            ? `left-${e.clientX}-${scrollCount.current}`
            : gridHeaderRight - 30 < e.clientX
            ? `right-${e.clientX}-${scrollCount.current}`
            : undefined
        );
      }, 150),
    [props.columnDragScroll, props.rightHeaderElement]
  );

  const columns = useMemo(
    () =>
      [...props.columns].map((column, index) => {
        return {
          ...column,
          processMajorClassColspan: getColspan(index, "process_major_class_id", props.columns),
          processMiddleClassColspan: getColspan(index, "process_middle_class_id", props.columns),
          companyColspan: getColspan(index, "company_id", props.columns),
        };
      }),
    [props.columns]
  );

  return (
    <>
      <table className="grid-table grid-table-header grid-table-right grid-table-right-header">
        <thead>
          <tr>
            {columns.map((column, index) => {
              if (column.processMajorClassColspan === 0) {
                return null;
              }

              const classname = `grid-process-major-classname ${
                column.schedule_mapping_flg ? "process-major-bgc" : ""
              }`;
              if (column.processMajorClassColspan === 1) {
                return (
                  <th key={index} className={classname}>
                    {column.process_major_class_name}
                  </th>
                );
              }

              return (
                <th key={index} colSpan={column.processMajorClassColspan} className={classname}>
                  {column.process_major_class_name}
                </th>
              );
            })}
          </tr>
          <tr>
            {columns.map((column, index) => {
              if (column.processMiddleClassColspan === 0) {
                return null;
              }

              const canUpdate = isValidRole(column.roles, 0);

              return (
                <th
                  key={index}
                  className={`grid-process-middle-classname ${canUpdate ? "grid-cell-clickable" : ""}`}
                  colSpan={column.processMiddleClassColspan}
                  onClick={() => {
                    canUpdate && props.showMiddleClassEditor(column.process_middle_class_id);
                  }}
                >
                  {column.process_middle_class_name}
                  {column.coordinate_flg && (
                    <button
                      className="grid-icon grid-icon-cooperation icon icon-forward"
                      onClick={(e) => e.stopPropagation()}
                    ></button>
                  )}
                  {canUpdate && <button className="grid-icon grid-icon-edit icon-mode_edit"></button>}
                </th>
              );
            })}
          </tr>
          <tr>
            {columns.map((column, index) => {
              const canUpdate = isValidRole(column.roles, 1);
              const canInsert = isValidRole(column.roles, 5);
              const canDelete = isValidRole(column.roles, 6);
              const canBulkProcessOn = isValidRole(column.roles, 3);
              const canBulkProcessOff = isValidRole(column.roles, 4);
              const canBulkProcess = isValidRole(column.roles, 2);
              const canMove = isValidRole(column.roles, 7) && 1 < columns.length;

              return (
                <th
                  id={`right-grid-header-process-${index}`}
                  key={index}
                  className={clsx("grid-process-name", canUpdate ? "grid-cell-clickable" : "")}
                  data-process-id={column.process_id}
                  onMouseDown={() => canMove && setDragging(column, index)}
                  onMouseUp={() => setDragging()}
                  draggable={canMove}
                  onDragStart={(e) => {
                    // ドラッグ中にデフォルトの画像を表示しないようにする
                    e.dataTransfer?.setDragImage(document.createElement("span"), 0, 0);
                    // 1秒後にもマウスが動いていない場合のみドラッグする
                    dragStartColumnIndex.current < 0 && setDragging();
                  }}
                  onDragOver={(e) => {
                    if (0 <= dragStartColumnIndex.current) {
                      handleColumnDragOver(e, index);
                      handleColumnDragScroll(e);
                    }

                    // onDropイベントのため
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onDrop={() => {
                    handleColumnDrop();
                    props.columnDragEnd();
                  }}
                  onDragEnd={() => {
                    setDragging();
                    scrollCount.current = 0;
                    props.columnDragEnd();
                  }}
                  onClick={(_) => {
                    if (0 <= dragStartColumnIndex.current) {
                      dragStartColumnIndex.current = -1;
                      props.columnDragEnd();

                      return;
                    }
                    canUpdate && props.showWorkEditor(column.process_id);
                  }}
                >
                  <div className="process">
                    {omitString(column.process_name, 17)}
                    {(canInsert || canDelete || canBulkProcessOn || canBulkProcessOff || canBulkProcess) && (
                      <div className="grid-icon-submenu-wrapper" onClick={(e) => handleClickSubmenu(e, column)}>
                        <button className="grid-icon grid-icon-submenu icon-submenu" />
                      </div>
                    )}
                    {canUpdate && <button className="grid-icon grid-icon-edit icon-mode_edit"></button>}
                  </div>
                </th>
              );
            })}
          </tr>
          <tr>
            {columns.map((column, index) => {
              if (column.companyColspan === 0) {
                return null;
              }

              return (
                <th key={index} colSpan={column.companyColspan} className="grid-company-name">
                  {column.company_name}
                </th>
              );
            })}
          </tr>
          <tr className="grid-separator-bg">
            {columns.map((column, i) => {
              const canBulkProcessOn = isValidRole(column.roles, 3);
              const canBulkProcessOff = isValidRole(column.roles, 4);

              return (
                <th
                  key={i}
                  className="grid-separator"
                  onContextMenu={(e) => {
                    if (!canBulkProcessOn && !canBulkProcessOff) {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                >
                  {!column.output_flg && <span className="grid-icon icon icon-visibility_off" />}
                </th>
              );
            })}
          </tr>
        </thead>
      </table>
      <div
        className="grid-dragging-header"
        style={{
          visibility: dragColumn ? "visible" : "hidden",
          left: dragColumn?.offsetLeft ?? 0,
          top: dragColumn?.offsetTop ?? 0,
          width: dragColumn?.offsetWidth ?? 0,
          height: "132px",
        }}
      />
      <div
        className="grid-drop-header"
        style={{
          visibility:
            dragColumn && ![dragColumn.index, dragColumn.index + 1].includes(dropIndex) ? "visible" : "hidden",
          left: dragColumn ? dragColumn.offsetWidth * dropIndex : 0,
          top: dragColumn?.offsetTop ?? 0,
          height: "132px",
        }}
      />
    </>
  );
};

RightGridHeader.displayName = "RightGridHeader";

export default withTranslation()(RightGridHeader);
