import React, { FC } from "react";

import { cn } from "@/lib/classNames";
export type ButtonType = "default" | "cancel" | "save";

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  readonly buttonType?: ButtonType;
  readonly children: React.ReactNode;
  readonly isLoading?: boolean;
}

export const Button: FC<ButtonProps> = (props) => {
  const { children, onClick, className, buttonType, disabled = false, isLoading = false, ...restProps } = props;

  return (
    <button
      className={cn(
        `
        bg-[#dea321]
        text-white
        text-[14px]
        font-bold
        rounded-[0.3rem]
        min-w-[100px]
        px-6
        py-3
        cursor-pointer`,
        buttonType === "cancel" && "bg-[#aaa]",
        disabled &&
          (buttonType === "save"
            ? "pointer-events-none text-[#8f8f8f] bg-[#d1d1d1]"
            : "pointer-events-none bg-opacity-50"),
        className
      )}
      type="button"
      onClick={onClick}
      {...restProps}
    >
      <div className="flex justify-center">
        {isLoading && (
          <div className="loading-layout inset-1/2 w-[20px] h-[20px] z-50 mr-[5px]">
            <img src="./img/loading-layout.svg" alt="loading" />
          </div>
        )}
        {children}
      </div>
    </button>
  );
};
