import { connect } from "react-redux";

import actions from "../../actions";
import SystemViewer from "../../components/system/SystemViewer";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchItems: () => dispatch(actions.system.fetchItems()),
    search: (params, callback) => dispatch(actions.system.searchItems(params, callback)),
    errors: (params, callback) => dispatch(actions.system.exportErrorFile(params, callback)),
    fetchItem: (id, callback) => dispatch(actions.system.fetchItem(id, callback)),
    resetSearch: () => dispatch(actions.system.resetSearch()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemViewer);
