// import format from "date-fns/format";
import format from "date-fns/format";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { TextArea } from "@/sx-layout/common/Form";
import { SubmitButton } from "@/components/common/SubmitButton";

type Props = {
  holidays: Date[];
  startDate: Date;
  endDate: Date;
  onClose: () => void;
  onChange: (date: Date[]) => void;
};

export const CalendarTextEditorDialog: FC<Props> = ({ holidays, onClose, onChange, startDate, endDate }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [text, setText] = useState<string>(holidays.map((v) => format(v, "yyyy/M/d")).join("\n"));
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    setText(value);
  };

  const handleSubmit = () => {
    setLoading(true);
    try {
      const dates: Date[] = [];
      const dateTexts = text.split("\n").filter((v) => !!v);
      for (const str of dateTexts) {
        const date = new Date(str);
        if (!date.getTime()) {
          setError(t("invalid_date_format"));
          return;
        }
        if (date.getTime() < startDate.getTime() || endDate.getTime() < date.getTime()) {
          setError(t("out_of_construction_period"));
          return;
        }
        dates.push(date);
      }
      setError("");
      onChange(dates);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal">
      <div className="modal-dialog">
        <div className={"modal-content"}>
          <div className="modal-header">
            <h3 className="modal-ttl">{t("edit_holidays_with_text")}</h3>
            <div className="btn-close" onClick={onClose}>
              <span className="icon icon-close" />
            </div>
          </div>
          <div className="modal-body schedule-update">
            <div className="pb-[5px] text-center">{t("edit_holidays_with_text_description")}</div>
            <div className="form-flex-center flex-col" style={{ marginBottom: "10px" }}>
              <TextArea className="h-[120px]" value={text} onChange={handleChange} autoFocus={true} />
            </div>
            {error && (
              <div className="form-error" style={{ textAlign: "center" }}>
                <p className="form-error-message">{error}</p>
              </div>
            )}
          </div>
          <div className={"modal-footer"}>
            <button data-test-id="button-column-editor-cancel" type="button" className="btn btn-gray" onClick={onClose}>
              {t("cancel")}
            </button>
            <SubmitButton data-test-id="button-column-editor-save" onClick={handleSubmit} loading={loading}>
              {t("save")}
            </SubmitButton>
          </div>
        </div>
      </div>
    </div>
  );
};
