import actions from "../actions";

const confirm = (store) => (next) => (action) => {
  if (action.meta && action.meta.confirm) {
    const { title, messages, cancelHandler } = action.meta.confirm;
    const okClickHandler = () => {
      delete action.meta.confirm;
      next(actions.app.hideConfirm());
      next(action);
    };
    const cancelClickHandler = () => {
      if (cancelHandler !== undefined) {
        cancelHandler();
      }

      next(actions.app.hideConfirm());
    };

    return next(actions.app.showConfirm(title, messages, okClickHandler, cancelClickHandler));
  }

  return next(action);
};

export default confirm;
