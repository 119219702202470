import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

const Tooltip = ({ data, t }) => {
  const { value } = data;

  return (
    <div role="tooltip" className="schedule-tip in tooltip">
      <div className="tooltip-inner">
        <div className="form-txt wrap">{value}</div>
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Tooltip);
