import { connect } from "react-redux";

import actions from "../../actions";
import LeftGridRowChiba from "../../components/matrix/LeftGridRowChiba";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateItem: (itemId, data, callback) => dispatch(actions.matrix.updateItem(itemId, data, callback)),
    updateNote: (itemId, data, callback) => dispatch(actions.matrix.updateNote(itemId, data, callback)),
    updateDelivery: (itemId, data, callback) => dispatch(actions.matrix.updateDelivery(itemId, data, callback)),
    updateAttention: (itemId, data, callback) => dispatch(actions.matrix.updateAttention(itemId, data, callback)),
    addItemPrev: (index) => dispatch(actions.matrix.addItemPrev(index)),
    addItemNext: (index) => dispatch(actions.matrix.addItemNext(index)),
    copyItemChiba: (index, data, callback) => dispatch(actions.matrix.copyItemChiba(index, data, callback)),
    deleteItem: (itemId, data, callback) => dispatch(actions.matrix.deleteItem(itemId, data, callback)),
    bulkUpdateItemStatus: (itemId, data, callback) =>
      dispatch(actions.matrix.bulkUpdateItemStatus(itemId, data, callback)),
    showAlert: (title, messages) =>
      dispatch(actions.app.showAlert(title, messages, () => dispatch(actions.app.hideAlert()))),
    setTitleSubmenu: (itemId: number) => dispatch(actions.matrix.setTitleSubmenu(itemId)),
    clearTitleSubmenu: () => dispatch(actions.matrix.clearTitleSubmenu()),
    clearProcessSubmenu: () => dispatch(actions.matrix.clearProcessSubmenu()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftGridRowChiba);
