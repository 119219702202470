import { connect } from "react-redux";

import actions from "../../actions";
import Tooltip from "../../components/list/Tooltip";

const mapStateToProps = (state) => {
  return {
    masters: state.construction.masters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Tooltip);
