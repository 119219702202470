import React from "react";

import FilterIcon from "../../../icons/FilterIcon";

type Props = {
  onClick: () => void;
};

const FilterButton: React.FC<Props> = ({ onClick }) => {
  return (
    <div id="filter-button" onClick={onClick}>
      <FilterIcon />
    </div>
  );
};

export default FilterButton;
