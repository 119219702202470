import moment from "moment";
import React, { ReactNode, useMemo, useState } from "react";
import ReactTooltip from "react-tooltip";

import TooltipContainer from "@/containers/matrix/TooltipContainer";
import { useConstructionExtensions } from "@/hooks";
import { isIE } from "@/lib/common";
import { isValidRole } from "@/lib/roleChecker";
import {
  Task,
  TaskScheduleType,
  TaskStatus,
  TaskSwitcherStatus,
  TaskSwitcherStatusType,
  TaskUtil,
} from "@/models/tasks";

type TooltipContent = {
  process: any;
  task: Task;
};

type Color = "blue" | "yellow" | "orange" | "green" | "gray" | "none";

type LinkageInfo = {
  type: string;
  num: number;
  content: any[];
};

type TaskExtra = { processing: boolean; error: any; warning_flg: any; file_status: any };

type Props = {
  index: number;
  itemId: number;
  task: Task & TaskExtra;
  switcherStatus: TaskSwitcherStatusType;
  row?: any;
  linkageInfo: LinkageInfo;
  isChiba: boolean;
  showTaskEditor: (taskId: any, isStatus: boolean, linkageInfo: any) => void;
  changeTaskStatus: (itemId: any, task: Task, switcherStatus: TaskSwitcherStatusType) => void;
};

const RightGridCell: React.FC<Props> = ({
  index,
  itemId,
  task,
  switcherStatus,
  row,
  linkageInfo,
  showTaskEditor,
  changeTaskStatus,
  isChiba,
}) => {
  // NOTE: 拡張番号14は存在するかのみを確認する。configカラムは使用しない。
  const { extension } = useConstructionExtensions(14);
  const [tooltipContent, setTooltipContent] = useState<TooltipContent | null>(null);
  const isPanel = useMemo(() => {
    return isValidRole(task.roles, 0);
  }, [task]);
  const isStatus = useMemo(() => {
    return isValidRole(task.roles, 1);
  }, [task]);

  const colorName: Color = useMemo(() => {
    if (!task.valid_flg) {
      return "gray";
    }

    switch (task.background_color) {
      case 1:
        return "blue";
      case 2:
        return "yellow";
      case 3:
        return "orange";
      case 4:
        return "green";
      default:
        return "none";
    }
  }, [task]);

  const className = useMemo(() => {
    let className = `grid-cell-clickable grid-cell-${colorName}`;

    if (task.processing) {
      className = `${className} grid-cell-processing`;
    }

    if (task.error) {
      className = `${className} grid-cell-error`;
    }

    if (isIE() && switcherStatus !== TaskSwitcherStatus.OFF) {
      className = `${className} cursor-${switcherStatus}`;
    }

    if (switcherStatus !== TaskSwitcherStatus.OFF && !isStatus) {
      className = `${className} grid-cell-not-allowed`;
    }
    if (switcherStatus === TaskSwitcherStatus.OFF && !isPanel) {
      className = `${className} grid-cell-not-allowed`;
    }

    return className;
  }, [task, isPanel, isStatus, switcherStatus]);

  const getStartTag = (task: Task) => {
    let className = "grid-cell-date grid-cell-start";
    let text = "";

    if (task.result_start_date) {
      text = moment(task.result_start_date, "YYYY-MM-DD").format("MM/DD");
      className = `${className} grid-cell-result`;
    } else if (task.schedule_start_date) {
      text = `(${moment(task.schedule_start_date, "YYYY-MM-DD").format("MM/DD")})`;
      className = `${className} grid-cell-schedule`;
    }

    if (text === "") {
      return <p className={className}>&nbsp;</p>;
    }

    return <p className={className}>{text}</p>;
  };

  const getEndTag = (task: Task) => {
    let className = "grid-cell-date grid-cell-end";
    let text = "";

    if (task.result_end_date) {
      text = moment(task.result_end_date, "YYYY-MM-DD").format("MM/DD");
      className = `${className} grid-cell-result`;
    } else if (task.schedule_end_date) {
      text = `(${moment(task.schedule_end_date, "YYYY-MM-DD").format("MM/DD")})`;
      className = `${className} grid-cell-schedule`;
    }

    if (text === "") {
      return <p className={className}>&nbsp;</p>;
    }

    return <p className={className}>{text}</p>;
  };

  const onClickHandler = (itemId: any, task: Task) => {
    if (task["processing"]) return;
    if (switcherStatus === TaskSwitcherStatus.OFF) {
      showTaskEditor(task.task_id, isStatus, linkageInfo);
    } else if (isStatus && !TaskUtil.equalsTaskAndSwitcherStatus(task, switcherStatus)) {
      // 同じステータスの場合は更新しない
      changeTaskStatus(itemId, task, switcherStatus);
    }
  };

  const formatDate = (value: string) => {
    if (!value) {
      return null;
    }

    //三井岩国：月・日は0フィル0後にその0をノーブレークスペースに置換
    value = moment(value, "YYYY-MM-DD").format("MM/DD");
    value = value.replace(/^0/, "\u00A0");
    value = value.replace("/0", "/\u00A0");

    return value;
  };

  const renderDate = (start: string, end: string) => {
    if (!start && !end) {
      return null;
    }
    if (!start) {
      // NOTE: startが空の時は全角スペース2つが仕様
      return <span>　　~{end}</span>;
    }

    return (
      <span>
        {start}~{end}
      </span>
    );
  };

  const renderNewScheduleDate = (): ReactNode => {
    const startDate = formatDate(task.schedule_start_date);
    const endDate = formatDate(task.schedule_end_date);

    return <p className="transform text-left scale-90 matrix-cell-date">予:{renderDate(startDate, endDate)}</p>;
  };

  const renderNewResultDate = () => {
    const startDate = formatDate(task.result_start_date);
    const endDate = formatDate(task.result_end_date);

    return <p className="transform text-left scale-90 matrix-cell-date">実:{renderDate(startDate, endDate)}</p>;
  };

  const renderScheduleDate = (): ReactNode => {
    if (extension) {
      return renderNewScheduleDate();
    }

    return getStartTag(task);
  };

  const renderResultDate = (): ReactNode => {
    if (extension) {
      return renderNewResultDate();
    }

    return getEndTag(task);
  };

  const showProgress = useMemo(() => {
    return (
      isChiba &&
      task.valid_flg &&
      task.status === TaskStatus.INCOMPLETE &&
      (task.schedule_type === TaskScheduleType.CONTINUE ||
        task.schedule_type === TaskScheduleType.COMPLETING ||
        task.schedule_type === TaskScheduleType.INTERRUPT)
    );
  }, [isChiba, task]);

  const progressDisp = useMemo(() => {
    if (task.progress !== 0 && !task.progress) return "";
    const percentage = Math.round((task.progress ?? 0) * 100);
    return `${percentage}%`;
  }, [task.progress]);

  return (
    <td
      key={index}
      className={className}
      onClick={() => {
        isPanel && onClickHandler(itemId, task);
      }}
      data-tip
      data-for="matrix-task-detail"
      data-place="left"
      onMouseEnter={() => {
        if (task.valid_flg) {
          setTooltipContent({
            process: row,
            task,
          });
          ReactTooltip.rebuild();
        } else {
          setTooltipContent(null);
        }
      }}
      onMouseLeave={() => {
        setTooltipContent(null);
      }}
    >
      <div className="grid-cell-wrap">
        <div className="grid-cell-con">
          <div className="grid-cell-inner">
            {task.valid_flg && task.file_status && (
              <span className="grid-icon grid-information icon-attach_file"></span>
            )}
            {linkageInfo && linkageInfo.num > 0 && (
              <div className="icon-linkage">
                <img
                  src={`img/icon-linkage-${linkageInfo.type}-gray.svg`}
                  width="14"
                  height="14"
                  className="icon"
                  style={{ marginRight: "2px", float: "left" }}
                />
                <span style={{ float: "left" }}>{linkageInfo.num > 1 ? linkageInfo.num : ""}</span>
              </div>
            )}

            {!task.valid_flg ? (
              <span className="grid-table-cell-status status-mark">-</span>
            ) : task.display_status === "" ? (
              <span className="grid-table-cell-status status-mark">&nbsp;</span>
            ) : (
              <span className="grid-table-cell-status status-mark">{task.display_status}</span>
            )}
            {task.valid_flg && renderScheduleDate()}
            {task.valid_flg && renderResultDate()}
            {task.valid_flg && task.display_user_name ? (
              <p>{task.display_user_name.substring(0, 5)}</p>
            ) : showProgress ? (
              <p>{progressDisp}</p>
            ) : (
              <p>&nbsp;</p>
            )}
            <button className={`grid-icon grid-icon-edit ${isPanel ? "icon-mode_edit" : ""}`}></button>
            {task.valid_flg && task.warning_flg && (
              <span className="grid-icon grid-icon-caution icon-caution">[!]</span>
            )}
          </div>
        </div>
      </div>
      {tooltipContent !== null && (
        <ReactTooltip
          id="matrix-task-detail"
          className="matrix-task-detail"
          delayShow={500}
          effect="solid"
          isCapture={true}
          scrollHide={true}
        >
          <TooltipContainer data={tooltipContent} />
        </ReactTooltip>
      )}
    </td>
  );
};

export default RightGridCell;
