import { connect } from "react-redux";

import actions from "../../actions";
import RightGridHeader from "../../components/matrix/RightGridHeader";

const mapStateToProps = (state) => {
  return {
    searchParams: state.matrixSearch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProcessSubmenu: (info: { x; y; process }) => dispatch(actions.matrix.setProcessSubmenu(info)),
    columnDragStart: (column) => dispatch(actions.matrix.columnDragStart(column)),
    columnDragOver: (index) => dispatch(actions.matrix.columnDragOver(index)),
    columnDragScroll: (to) => dispatch(actions.matrix.columnDragScroll(to)),
    columnDragEnd: () => dispatch(actions.matrix.columnDragEnd()),
    sortProcess: (processId, data, callback?, failedCallback?) =>
      dispatch(actions.matrix.sortProcess(processId, data, callback, failedCallback)),
    clearTitleSubmenu: () => dispatch(actions.matrix.clearTitleSubmenu()),
    clearProcessSubmenu: () => dispatch(actions.matrix.clearProcessSubmenu()),
    showAlert: (title, messages) =>
      dispatch(actions.app.showAlert(title, messages, () => dispatch(actions.app.hideAlert()))),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RightGridHeader);
