import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  disabled?: boolean;
  additionalClassName?: string;
  children?: React.ReactNode;
};

export const SubmitButton = ({ loading, disabled, additionalClassName, children, ...others }: Props) => {
  const { t } = useTranslation();

  return (
    <button
      {...others}
      data-test-id="button-task-editor-save"
      type="button"
      className={clsx("btn btn-blue", additionalClassName, loading ? "loading loading--button" : "")}
      disabled={disabled || loading}
    >
      {children ?? t("save")}
    </button>
  );
};
