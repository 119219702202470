import { connect } from "react-redux";

import actions from "../../actions";
import WorkCreateEditor from "@/components/matrix/WorkCreateEditor";

const mapStateToProps = (state) => {
  return {
    masters: state.construction.masters,
    scheduleRoles: state.matrix.scheduleRoles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProcess: (processId, callback) => dispatch(actions.matrix.fetchProcess(processId, callback)),
    createProcess: (data, callback, failedCallback) =>
      dispatch(actions.matrix.createProcess(data, callback, failedCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkCreateEditor);
