import { NONE_VALUE } from "../constants/index";

export const validateCondition = (options, selectedIds) => {
  if (!Array.isArray(selectedIds)) {
    selectedIds = [selectedIds];
  }

  if (!(options && options.length && selectedIds && selectedIds.length)) {
    return [];
  }

  if (selectedIds.includes(NONE_VALUE)) {
    return [NONE_VALUE];
  }

  const namePattern = "_name";
  const idPattern = "_id";

  const sampleOption = options[0];
  let idKey;
  const keys = Object.keys(sampleOption);
  keys.map((key) => {
    if (key.endsWith(namePattern)) {
      idKey = key.replace(namePattern, idPattern);
    }
  });

  const idOptions = options.map((option) => option[idKey]);

  return selectedIds.filter((selectedId) => {
    return idOptions.includes(selectedId);
  });
};

// 条件1: XXX.XX形式かつ100以上もOK
export const isValidOvertimeFormat = (time: string): boolean => {
  const regex = /^\d+(\.\d{1,2})?$/;

  return regex.test(time);
};

// 条件2: 0以上100未満
export const isValidOvertimeRange = (time: string): boolean => {
  const num = parseFloat(time);

  return num >= 0 && num < 100;
};

const isNotNull = (v?: string | number): boolean => {
  if (typeof v === "number") return true;
  if (typeof v === "string") return !!v;
  return false;
};

export const isPositiveInteger = (v: string | number | undefined) => {
  const num = Number(v);
  return isNotNull(v) && Number.isInteger(num) && 0 <= num;
};

export const isPositiveFiniteNumber = (v: string | number | undefined): boolean => {
  const num = Number(v);
  return isNotNull(v) && Number.isFinite(num) && 0 <= num;
};

export const isToSecondDecimalPlace = (v: string | number | undefined): boolean => {
  // 小数第二位未満まで
  return isNotNull(v) && !!v.toString().match(/^\d+(\.?\d{1,2})?$/);
};

export const isValidNumberRangeMax = (v: string | number | undefined, max: number) => {
  const num = Number(v);
  return isNotNull(v) && 0 <= num && num <= max;
};

export const isValidNumberRange = (v: string | number | undefined) => {
  const num = Number(v);
  return isNotNull(v) && 0 <= num && num <= 999999999;
};
