import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { createClickEvent, removeHeightResizeListener } from "../../lib/common";
import Modal from "../Modal";

class SchedulePanelCosmoChiba extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleExportSchedulePanel: false,
      scheduleFormatType: 1,
      workBasis: true,
      downloadUrl: "",
      downloadName: "",
      downloading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.CreateWorkBasis = this.CreateWorkBasis.bind(this);
    this.CreateTitleBasis = this.CreateTitleBasis.bind(this);
  }

  componentDidMount() {
    this.props.setUnloadAlert();
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();

    removeHeightResizeListener(this.panelLeft);
  }

  handleSubmit() {
    this.setState({ downloading: true });
    const params = {
      schedule_format_type: this.state.scheduleFormatType,
    };

    this.props.createScheduleCosmoChiba(
      params,
      (blob, filename) => {
        this.setState({ downloadUrl: URL.createObjectURL(blob), downloadName: filename }, () => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            const evt = createClickEvent();
            this.btnDownload.dispatchEvent(evt);
          }
          this.setState({ downloading: false });
          this.props.closeHandler();
          this.props.backHandler();
        });
      },
      () => this.setState({ downloading: false })
    );
  }

  CreateWorkBasis() {
    this.setState({ scheduleFormatType: 1, workBasis: true });
  }

  CreateTitleBasis() {
    this.setState({ scheduleFormatType: 2, workBasis: false });
  }

  render() {
    const { loadingPanel, closeHandler, selectedCategoryCount, targetItemCount, t } = this.props;

    return (
      <Modal title={t("putting_schedule_list")} loading={loadingPanel} closeHandler={closeHandler}>
        <div className="modal-body w-540 schedule-panel" ref={(node) => (this.body = node)}>
          <p className="disp-area">
            {t("selected_category")}：<span className="categoryNum txt-bold ">{selectedCategoryCount}</span>
            {t("selected_items_sum")}：<span className="targetItems txt-bold ">{targetItemCount}</span>
          </p>
          <div className="schedule-inner">
            <div className="toggle-basis">
              <div className="toggle-buttons w-past-line">
                <label className="toggle-label">
                  <input type="radio" name="radio-1" checked={this.state.workBasis} onChange={this.CreateWorkBasis} />
                  <span className="btn">{t("working_standard")}</span>
                </label>
                <label className="toggle-label">
                  <input type="radio" name="radio-1" checked={!this.state.workBasis} onChange={this.CreateTitleBasis} />
                  <span className="btn">{t("title_standard")}</span>
                </label>
              </div>
            </div>
          </div>
          <a
            className="d-n"
            download={this.state.downloadName}
            href={this.state.downloadUrl}
            ref={(node) => (this.btnDownload = node)}
          >
            download
          </a>
        </div>
        <div className={`modal-footer ${loadingPanel ? "loading--dialog" : ""}`}>
          <button
            disabled={loadingPanel}
            data-test-id="button-area-edit-cancel"
            type="button"
            className="btn btn-gray"
            onClick={closeHandler}
          >
            {t("cancel")}
          </button>
          <button
            disabled={loadingPanel}
            data-test-id="button-area-edit-save"
            type="button"
            className="btn btn-blue"
            onClick={this.handleSubmit}
          >
            {t("decision")}
          </button>
        </div>
      </Modal>
    );
  }
}

SchedulePanelCosmoChiba.defaultProps = {
  data: null,
};

SchedulePanelCosmoChiba.propTypes = {
  selectedCategoryCount: PropTypes.number.isRequired,
  targetItemCount: PropTypes.number.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
  scheduleSelectedCells: PropTypes.array.isRequired,
  loadingPanel: PropTypes.bool.isRequired,
};

export default withTranslation()(SchedulePanelCosmoChiba);
