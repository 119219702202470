import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import actions from "@/actions";
import AlertDialog from "@/components/AlertDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import LangSelectorContainer from "@/containers/LangSelectorContainer";
import PasswordEditorContainer from "@/containers/PasswordEditorContainer";
import { getConstructionId } from "@/lib/common";
import { RootState } from "@/reducers/types";
import { ConstructionSelector } from "@/sx-layout/components/ConstructionSelector";
import { PlotMap } from "@/sx-layout/components/plotmap";
import { MachineSetting } from "@/sx-layout/components/settings/machineSetting/components/MachineSetting";
import { PlotPlanSetting } from "@/sx-layout/components/settings/plotPlanSetting/components/PlotPlanSetting";
import { useInterval } from "@/sx-layout/hooks/useInterval";
import { LayoutMenu } from "@/sx-layout/models/layoutMenu";

const NOTIFICATION_SUMMARY_POLLING_INTERVAL_IN_MS = 30000;

export const SxLayoutApp: React.FC = () => {
  const dispatch = useDispatch();

  const constructionId = getConstructionId();
  const [isConstructionSelectorOpen, setIsConstructionSelectorOpen] = useState(false);

  const visiblePasswordEditor = useSelector((state) => state.app.visiblePasswordEditor);
  const visibleLangSelector = useSelector((state) => state.app.visibleLangSelector);

  const confirm = useSelector((state) => state.app.confirm);

  const alert = useSelector((state) => state.app.alert);

  const layoutMenu = useSelector<RootState, LayoutMenu>((state) => state.layoutApp.layoutMenu);

  const menu = useSelector((state) => state.app.menu);
  const construction = useSelector((state) => state.construction);

  useEffect(() => {
    if (menu === "machine" || menu === "machine_type" || menu === "plot_plan") {
      history.pushState(undefined, undefined, `/#/${construction.constructionId}/${menu}`);

      dispatch(actions.layoutApp.changeLayoutMenu(menu));
    }
  }, [menu]);

  useEffect(() => {
    dispatch(actions.construction.fetchItems());
    dispatch(actions.app.fetchRoles());
    if (constructionId) {
      dispatch(actions.app.fetchMenus());
      dispatch(actions.construction.fetchExtensions());
      dispatch(actions.construction.fetchMasters());
      dispatch(actions.layoutMasters.fetchLayoutMasters());
      dispatch(actions.notifications.fetchNotificationsSummary());
      dispatch(actions.machine.fetchCompaniesMachineTypes());
      dispatch(actions.machine.fetchMachineTypes());
    } else {
      setIsConstructionSelectorOpen(true);
    }
  }, []);

  useInterval(() => {
    if (constructionId) {
      dispatch(actions.notifications.fetchNotificationsSummary());
    }
  }, NOTIFICATION_SUMMARY_POLLING_INTERVAL_IN_MS);

  const onSaveLang = () => {
    dispatch(actions.layoutApp.changeLayoutMenu("machine"));
  };

  return (
    <div id="sx-layout">
      {layoutMenu === "machine" && <PlotMap />}
      {layoutMenu === "machine_type" && <MachineSetting />}
      {layoutMenu === "plot_plan" && <PlotPlanSetting />}
      {visiblePasswordEditor && <PasswordEditorContainer />}
      {visibleLangSelector && <LangSelectorContainer onSave={onSaveLang} />}
      {confirm !== null && <ConfirmDialog {...confirm} styleSxLayout={true} />}
      {alert !== null && <AlertDialog {...alert} from="layout" />}

      {isConstructionSelectorOpen && <ConstructionSelector onClose={() => setIsConstructionSelectorOpen(false)} />}
    </div>
  );
};
