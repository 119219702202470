import clsx from "clsx";
import React, { FC, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";

import { OptionItem } from "@/components/list/Schedule/types";
import { formatScheduleValue, insertNewLine } from "@/lib/common";
import { TextArea } from "@/sx-layout/common/Form";

import { isValidOvertimeFormat, isValidOvertimeRange } from "@/lib/validate";

type Props = {
  targetName: string;
  processName?: string;
  target: string;
  type: "text" | "textarea" | "select" | null;
  value: string | number;
  onClose: () => void;
  onChange: (v: string | number) => void;
  options?: OptionItem[];
  bulk?: boolean;
};

const NOT_SELECTED = "NOT_SELECTED";
const BULK_INIT_OPTION: OptionItem = { id: NOT_SELECTED, name: "-----" };

export const ScheduleEditorDialog: FC<Props> = ({
  targetName,
  processName,
  target,
  type,
  value,
  onClose,
  onChange,
  options,
  bulk,
}) => {
  const { t } = useTranslation();

  const [val, setVal] = useState<string | number>(
    ["text", "textarea"].includes(type) ? value || "" : bulk ? NOT_SELECTED : Number(value || "0")
  );

  const handleBlur = () => {
    if (target === "field_p2") {
      setVal(insertNewLine(val.toString(), 5));
    }
    if (target === "field_p3") {
      setVal(insertNewLine(val.toString(), 10));
    }
  };

  const SCHEDULE_TEXT_FIELD_MAX_VALIDATION: { [target: string]: { length: number; field: string } } = {
    field_p1: { length: 100, field: `${t("schedule_list")}：${t("company")}` },
    field_p2: { length: 100, field: t("hazard_source") },
    field_p3: { length: 100, field: t("hazard_reduction_activity") },
    field_t4: { length: 100, field: `${t("fire")}：${t("use_flame")}` },
    field_t7: { length: 100, field: t("heavy_machine") },
    field_t8: { length: 100, field: `${t("safety_matters")}（${t("safety")}${t("instruction")}）` },
    note1: { length: 500, field: `${t("construction")}${t("supervising_section")}：${t("comments")}` },
    note2: { length: 500, field: `${t("facility")}${t("supervising_section")}：${t("comments")}` },
  };

  const [error, setError] = useState<string>();

  const validate = (): boolean => {
    if (target === "field_t1") {
      if (!val) return true;
      const num = Number(val);
      if (!(Number.isSafeInteger(num) && 0 <= num)) {
        setError(t("invalid_number_format", { field: targetName }));

        return false;
      }
    }

    if (target === "field_t9") {
      const v = val as string;
      if (!v) return true;

      if (!isValidOvertimeFormat(v)) {
        setError(t("invalid_overtime_format"));

        return false;
      }

      if (!isValidOvertimeRange(v)) {
        setError(t("overtime_is_max_99"));

        return false;
      }
    }

    const validation = SCHEDULE_TEXT_FIELD_MAX_VALIDATION[target];
    if (validation?.length && validation.length < val.toString().length) {
      setError(t("is_too_long_max", validation));

      return false;
    }

    setError(null);

    return true;
  };

  const handleSubmit = () => {
    if (type === "select") {
      if (val === NOT_SELECTED) {
        setError(`${targetName}${t("is_not_selected")}`);
      } else {
        onChange(val ?? "0");
      }
    } else {
      if (!validate()) return;

      onChange(formatScheduleValue(target, val));
    }
  };

  return (
    <div className="modal">
      <Draggable handle=".modal-header">
        <div className="modal-drag-container">
          <div className="modal-dialog">
            <div className={"modal-content"}>
              <div className="modal-header">
                <h3 className="modal-ttl">{`${targetName} ${bulk ? t("update_bulk") : t("update")}`}</h3>
                <div className="btn-close" onClick={onClose}>
                  <span className="icon icon-close"></span>
                </div>
              </div>
              <div className="modal-body schedule-update">
                {!bulk && <div className="form-flex-center">{`[${processName}]`}</div>}
                <div className="form-flex-center flex-col">
                  {type === "text" && (
                    <div className={["field_t1", "field_t9"].includes(target) ? "w-[150px]" : "w-[100%]"}>
                      <input
                        type="text"
                        className={clsx("form-control")}
                        value={val}
                        onChange={(e) => setVal(e.target.value ?? "")}
                        autoFocus={true}
                      />
                    </div>
                  )}
                  {type === "textarea" && (
                    <TextArea
                      value={val}
                      onChange={(e) => setVal(e.target.value ?? "")}
                      onBlur={handleBlur}
                      autoFocus={true}
                    />
                  )}
                  {type === "select" && (
                    <select
                      className="form-control w-220"
                      value={val}
                      onChange={(e) => setVal(e.target.value === NOT_SELECTED ? NOT_SELECTED : Number(e.target.value))}
                    >
                      {(bulk ? [BULK_INIT_OPTION].concat(options) : options).map((o) => {
                        return (
                          <option key={`schedule-editor-${o.id}`} value={o.id}>
                            {o.name}
                          </option>
                        );
                      })}
                    </select>
                  )}
                  {error && (
                    <div className="form-error">
                      <p
                        className={clsx(
                          "form-error-message",
                          target === "field_t1" ? "w-200" : type === "select" ? "w-220" : "w-300"
                        )}
                      >
                        {error}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className={"modal-footer"}>
                <button
                  data-test-id="button-column-editor-cancel"
                  type="button"
                  className="btn btn-gray"
                  onClick={onClose}
                >
                  {t("cancel")}
                </button>
                <button
                  data-test-id="button-column-editor-save"
                  type="button"
                  className="btn btn-blue"
                  onClick={handleSubmit}
                >
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    </div>
  );
};
