import * as constants from "../constants/index";
import environments from "../environments";

export const fetchNotificationsSummary = () => {
  const endpoint = `${environments.API_BASE_URI}/notifications/summary`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.NOTIFICATIONS_BEGIN_FETCH_SUMMARY,
        };
      },
      success: (response) => {
        return {
          type: constants.NOTIFICATIONS_END_FETCH_SUMMARY,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};
