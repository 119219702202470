import { zodResolver } from "@hookform/resolvers/zod";
import moment, { Moment } from "moment";
import { ComponentProps, FC, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as z from "zod";

import { RootState } from "@/reducers/types";
import { Button } from "@/sx-layout/common/Button";
import { FormError, FormLabel, FormRow, Radio } from "@/sx-layout/common/Form";
import { DatePickerController } from "@/sx-layout/common/Form/DatePickerController";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "@/sx-layout/common/Modal";
import { AssociatedCopy, CopyOption, SingleCopy } from "@/sx-layout/components/plotmap/models";
import { ASSOCIATED_COPY, SINGLE_COPY } from "@/sx-layout/components/plotmap/util/constants";

type CopyForm = {
  date_from: Date;
  date_to: Date;
  copy_option: AssociatedCopy | SingleCopy;
};

export type CopyMachineKeepOutAreaModal = ComponentProps<typeof Modal> & {
  machineId?: string;
  keepOutAreaId?: string;
  isCopyMachine?: boolean;
  isCopyKeepOutArea?: boolean;
  onSubmit: ({
    layoutDateFrom,
    layoutDateTo,
    copyOption,
  }: {
    layoutDateFrom: string;
    layoutDateTo: string;
    copyOption: CopyOption;
  }) => void;
  onClose: () => void;
};

const today = moment();

export const CopyMachineKeepOutAreaModal: FC<CopyMachineKeepOutAreaModal> = ({
  onClose,
  onSubmit,
  machineId,
  keepOutAreaId,
  isCopyMachine,
  isCopyKeepOutArea,
}) => {
  const { t } = useTranslation();
  const layoutDateState = useSelector<RootState, Date>((state) => state.plotmap.layoutDate);
  const layoutDate = useMemo<Moment>(() => moment(layoutDateState, "day"), [layoutDateState]);

  const dateValidation = useCallback((date) => {
    if (!date) return false;
    const value = moment(date, "day");

    return value.isSameOrAfter(today, "day") && value.isAfter(layoutDate, "day");
  }, []);
  const formSchema = z
    .object({
      // From・Toともに、操作日の当日以降かつ表示中の配置日より後の日付を指定すること
      date_from: z
        .date()
        .nullish()
        .refine(dateValidation, { message: t("duplicate_error") }),
      date_to: z
        .date()
        .nullish()
        .refine(dateValidation, { message: t("duplicate_error") }),
      copy_option: z.enum([ASSOCIATED_COPY, SINGLE_COPY]),
    })
    // From <= Toであること
    .refine((data) => moment(data.date_from, "day").isSameOrBefore(moment(data.date_to, "day")), {
      message: t("duplicate_error"),
      path: ["date_from"],
    })
    // 最大60日間コピーが可能
    .refine((data) => moment(data.date_to, "day").diff(moment(data.date_from, "day"), "days") + 1 <= 60, {
      message: t("duplicate_calendar_range_validation"),
      path: ["date_from"],
    });

  const defaultDate = useMemo(
    () => (layoutDate.isBefore(today, "day") ? today.toDate() : layoutDate.clone().add(1, "day").toDate()),
    [layoutDate]
  );
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CopyForm>({
    resolver: zodResolver(formSchema),
    reValidateMode: "onSubmit",
    defaultValues: {
      date_from: defaultDate,
      date_to: defaultDate,
      copy_option: machineId && keepOutAreaId ? ASSOCIATED_COPY : SINGLE_COPY,
    },
  });

  const submit = (values: CopyForm) => {
    const formatDate = (date: Date) => moment(date).format("YYYY-MM-DD").toString();
    onSubmit({
      layoutDateFrom: formatDate(values.date_from),
      layoutDateTo: formatDate(values.date_to),
      copyOption: values.copy_option,
    });
    onClose();
  };

  return (
    <Modal id="sx-layout" onClose={onClose} shouldCloseOnEsc={false} shouldCloseOnOverlayClick={false}>
      <ModalHeader>{t("duplicate_heavy_machine")}</ModalHeader>

      <ModalBody className="flex-col w-500 min-h-[150px] h-full items-start p-0 mt-[30px] mb-[30px]" size="small">
        <FormRow
          className="mt-0"
          errorComponent={
            <>
              {(errors.date_from?.message || errors.date_to?.message) && (
                <FormError>{errors.date_from?.message ?? errors.date_to?.message}</FormError>
              )}
            </>
          }
        >
          <FormLabel className="w-[100px] mr-[10px]">{t("duplicate_direction")}</FormLabel>
          <DatePickerController name={"date_from"} control={control} popperPlacement="right" />
          &nbsp;〜&nbsp;
          <DatePickerController name={"date_to"} control={control} popperPlacement="left" />
        </FormRow>

        {keepOutAreaId && machineId && (
          <div className="w-380 flex flex-col ml-auto my-[10px]">
            <FormRow className="w-300 flex justify-start mr-0 mb-0 mt-0">
              <Radio id={ASSOCIATED_COPY} value={ASSOCIATED_COPY} {...register("copy_option")}>
                {t("duplicate_heavy_machine_and_keep_out_area")}
              </Radio>
            </FormRow>

            <FormRow className="w-300 flex justify-start mr-0 mb-0 mt-0">
              <Radio id={SINGLE_COPY} value={SINGLE_COPY} {...register("copy_option")}>
                {isCopyMachine && t("duplicate_only_heavy_machine")}
                {isCopyKeepOutArea && t("duplicate_only_keep_out_area")}
              </Radio>
            </FormRow>
          </div>
        )}
      </ModalBody>

      <ModalFooter>
        <Button buttonType="cancel" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button buttonType="save" onClick={handleSubmit(submit)}>
          {t("save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
