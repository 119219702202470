import * as constants from "../constants/index";
import environments from "../environments";

export const fetchItems = (callback) => {
  const endpoint = `${environments.API_BASE_URI}/construction_managements`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.CONSTRUCTION_MANAGEMENT_BEGIN_FETCH_ITEMS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.CONSTRUCTION_MANAGEMENT_END_FETCH_ITEMS,
          payload: response.construction_managements,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const changeSearchText = (text) => {
  return {
    type: constants.CONSTRUCTION_MANAGEMENT_CHANGE_SEARCH_TEXT,
    payload: text,
  };
};

export const searchItems = (text, callback) => {
  let endpoint = `${environments.API_BASE_URI}/construction_managements`;

  if (text && text.length > 0) {
    const params = new URLSearchParams();
    params.set("text", text);
    endpoint = `${endpoint}?${params.toString()}`;
  }

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.CONSTRUCTION_MANAGEMENT_BEGIN_SEARCH_ITEMS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.CONSTRUCTION_MANAGEMENT_END_SEARCH_ITEMS,
          payload: response.construction_managements,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchItem = (id, callback, failedCallback) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/construction_managements/${id}`,
    method: "GET",
    callbacks: {
      success: (response) => {
        callback();

        return {
          type: constants.CONSTRUCTION_MANAGEMENT_END_FETCH_ITEM,
          payload: response,
        };
      },
      error: (response) => {
        if (failedCallback !== undefined) {
          failedCallback();
        }

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const createItem = (data, handleSuccess, handleCancel) => {
  return (dispatch, getState) => {
    const { construction_management_name, sort } = data;
    const action = {
      type: constants.APP_CALL_API,
      endpoint: `${environments.API_BASE_URI}/construction_managements/create`,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        construction_management_name,
        sort,
      }),
      callbacks: {
        success: (response) => {
          handleSuccess();

          return {
            type: constants.CONSTRUCTION_MANAGEMENT_END_CREATE_ITEM,
            payload: response,
          };
        },
        error: (response) => {
          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    };

    // 工事管理名の重複チェック（重複している場合はアラート＆登録中止）
    const state = getState();
    const items = state.construction_management.masterItems;

    if (items.some((item) => item.construction_management_name === construction_management_name)) {
      action.meta = {
        alert: {
          messages: ["msg_already", "construction_management_name", "msg_exists"],
          okHandler: handleCancel,
        },
      };
    }

    dispatch(action);
  };
};

export const updateItem = (data, handleSuccess, handleCancel, failedCallback) => {
  return (dispatch, getState) => {
    const { construction_management_id, construction_management_name, sort, timestamp } = data;
    const action = {
      type: constants.APP_CALL_API,
      endpoint: `${environments.API_BASE_URI}/construction_managements/update/${construction_management_id}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        construction_management_name,
        sort,
        timestamp,
      }),
      callbacks: {
        success: (response) => {
          handleSuccess();

          return {
            type: constants.CONSTRUCTION_MANAGEMENT_END_UPDATE_ITEM,
            payload: response,
          };
        },
        error: (response) => {
          if (failedCallback !== undefined) {
            failedCallback(response);
          }

          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    };

    // 工事管理名の重複チェック（重複している場合はアラート＆登録中止）
    const state = getState();
    const items = state.construction_management.masterItems;

    items.some((item) => {
      if (
        item.construction_management_id !== construction_management_id &&
        item.construction_management_name === construction_management_name
      ) {
        const merge_target_id = item.construction_management_id;
        action.meta = {
          confirm: {
            messages: ["msg_already", "construction_management_name", "msg_exists", "msg_merge"],
            cancelHandler: handleCancel,
          },
        };
        action.endpoint = `${environments.API_BASE_URI}/construction_managements/merge/${construction_management_id}`;
        action.body = JSON.stringify({
          merge_target_id,
          timestamp,
        });
        action.callbacks.success = (response) => {
          handleSuccess();

          return {
            type: constants.CONSTRUCTION_MANAGEMENT_END_MERGE_ITEM,
            payload: response,
          };
        };

        return true;
      }
    });

    dispatch(action);
  };
};

export const deleteItem = (id, name) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/construction_managements/delete/${id}`,
    method: "POST",
    callbacks: {
      begin: () => {
        return {
          type: constants.CONSTRUCTION_MANAGEMENT_BEGIN_DELETE_ITEM,
        };
      },
      success: (response) => {
        return {
          type: constants.CONSTRUCTION_MANAGEMENT_END_DELETE_ITEM,
          payload: {
            id,
            ...response,
          },
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
    meta: {
      confirm: {
        messages: [name, "msg_deleted"],
      },
    },
  };
};
