import actions from "../actions";
import { validateCondition } from "../lib/validate";

const validateMatrix = (store) => (next) => (action) => {
  next(action);
  const state = store.getState();
  if (!(state.app.menu === "qrcode" && state.construction.masters)) {
    return;
  }

  const {
    categoryId,
    areaIds,
    deviceIds,
    facilityManagementIds,
    constructionManagementIds,
    primaryChargeIds,
    otherIds,
    userId,
    groupId,
  } = state.qrcode;

  const {
    categories,
    areas,
    devices,
    facility_managements,
    construction_managements,
    primary_charges,
    others,
    users,
    groups,
  } = state.construction.masters;

  const {
    changeCategory,
    changeArea,
    changeDevice,
    changeFacilityManagement,
    changeConstructionManagement,
    changePrimaryCharge,
    changeOther,
    changeUser,
    changeGroup,
    setValidRequired,
  } = actions.qrcode;

  const validatedAreaId = validateCondition(areas, areaIds);
  if (validatedAreaId.length !== areaIds.length) {
    next(changeArea(validatedAreaId));
  }
  const validatedDeviceIds = validateCondition(devices, deviceIds);
  if (validatedDeviceIds.length !== deviceIds.length) {
    next(changeDevice(validatedDeviceIds));
  }
  const validatedFacilityManagementIds = validateCondition(facility_managements, facilityManagementIds);
  if (validatedFacilityManagementIds.length !== facilityManagementIds.length) {
    next(changeFacilityManagement(validatedFacilityManagementIds));
  }
  const validatedConstructionManagements = validateCondition(construction_managements, constructionManagementIds);
  if (validatedConstructionManagements.length !== constructionManagementIds.length) {
    next(changeConstructionManagement(validatedConstructionManagements));
  }
  const validatedPrimaryChargeIds = validateCondition(primary_charges, primaryChargeIds);
  if (validatedPrimaryChargeIds.length !== primaryChargeIds.length) {
    next(changePrimaryCharge(validatedPrimaryChargeIds));
  }
  const validatedOtherIds = validateCondition(others, otherIds);
  if (validatedOtherIds.length !== otherIds.length) {
    next(changeOther(validatedOtherIds));
  }
  const validatedUserIds = validateCondition(users, userId);
  if (userId && !validatedUserIds.length) {
    next(changeUser(0));
  }
  const validatedGroupIds = validateCondition(groups, groupId);
  if (groupId && !validatedGroupIds.length) {
    next(changeGroup(0));
  }

  const valid = Boolean(validateCondition(categories, categoryId).length);
  next(setValidRequired(valid));
  if (!valid && categories.length > 0) {
    const validCategoryId = categories[0].category_id;
    const validCategory = { value: validCategoryId };
    next(changeCategory(validCategory));
  }

  return;
};

export default validateMatrix;
