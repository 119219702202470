import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class PulldownExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadUrl: "",
      downloadName: "",
      isActive: false,
    };
  }

  showDropdown(name) {
    this.setState({ activeButton: name });
  }

  hideDropdown() {
    this.setState({ activeButton: "" });
  }

  render() {
    const { beginSelectInazuma, beginSelectGraph, beginSelectDelay, download, extensions, handleClickSchedule, t } =
      this.props;

    const scheduleExtTypes = [
      3, //予定表（JXTG根岸）
      4, //予定表（出光徳山）
      7, //予定表（出光千葉）
      10, //予定表（OPC大阪）
      11, //予定表（三井岩国）
      16, //予定表（コスモ千葉）
    ];

    return (
      <React.Fragment>
        <div className="btn-dropdown-area" onMouseLeave={() => this.hideDropdown()}>
          <button
            data-test-id="button-summary-file-export"
            className="btn btn-light-blue btn-dropdown"
            onClick={() => this.showDropdown("list")}
          >
            <span className="icon icon-get_app" />
            {t("file_export")}
          </button>
          {this.state.activeButton === "list" && (
            <ul className="dropdown-menu" style={{ dispkay: "none" }} onClick={() => this.hideDropdown()}>
              <li onClick={download}>
                <span data-test-id="button-summary-list">
                  <img src="./img/icon_xls.svg" alt="XLS" className="icon-file" />
                  {t("table")}
                </span>
              </li>
              <li onClick={beginSelectInazuma}>
                <span data-test-id="button-summary-list">
                  <img src="./img/icon_xls.svg" alt="XLS" className="icon-file" />
                  {t("inazuma")}
                </span>
              </li>
              <li onClick={beginSelectGraph}>
                <span data-test-id="button-summary-list">
                  <img src="./img/icon_xls.svg" alt="XLS" className="icon-file" />
                  {t("progress_graph")}
                </span>
              </li>
              {extensions &&
                extensions
                  .filter((item) => {
                    return scheduleExtTypes.includes(item.extension_type);
                  })
                  .map((item, index) => {
                    return (
                      <li key={index} className="" onClick={() => handleClickSchedule(item.extension_type)}>
                        <span data-test-id="button-summary-list">
                          <img src="./img/icon_xls.svg" alt="XLS" className="icon-file" />
                          {t("schedule_list")}
                        </span>
                      </li>
                    );
                  })}
              <li onClick={beginSelectDelay}>
                <span data-test-id="button-summary-list">
                  <img src="./img/icon_xls.svg" alt="XLS" className="icon-file" />
                  {t("delay_list")}
                </span>
              </li>
            </ul>
          )}
        </div>
      </React.Fragment>
    );
  }
}

PulldownExport.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(PulldownExport);
