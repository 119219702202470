import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import actions from "../../actions";
import { useDebouncedCallback } from "../../hooks/useDebounce";
import { useEvent } from "../../hooks/useEvent";

import DrawerHeader from "./components/DrawerHeader";
import CancelReportPill from "./components/ListHeader/CancelReportPill";
import CompleteReportPill from "./components/ListHeader/CompleteReportPill";
import NotificationDetailModal from "./components/NotificationDetailModal";
import NotificationList from "./components/NotificationList";
import NotificationSearchModal from "./components/NotificationSearchModal";
import {
  ProgressNotification,
  ProgressNotificationFetchedNotificationLargestId,
  ProgressNotificationsPaginationParams,
  PROGRESS_NOTIFICATION_TYPE_CANCEL_REPORT,
  PROGRESS_NOTIFICATION_TYPE_COMPLETION_REPORT,
  ProgressNotificationSearchParam,
} from "./models";

import storageManager from "@/lib/storageManager";

const progressNotificationsDepsLocalStorageKey = "PROGRESS_NOTIFICATION_SEARCH_CONDITIONS";

const progressNotificationsFetchedLargestIdLocalStorageKey = "PROGRESS_NOTIFICATION_FETCHED_LARGEST_ID";

type Props = {
  visible?: boolean;
  onClose: () => void;
};

const ProgressNotificationDrawer: React.FC<Props> = ({ visible, onClose }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { notifications, fetching, end } = useSelector((state) => state.progressNotification);
  const calledOnce = useRef(false);
  const [searchParam, setSearchParam] = useState<ProgressNotificationSearchParam>(() => {
    const searchParam = JSON.parse(storageManager.getConstructionItem("progress_notification_search_param")) ?? {};
    if (searchParam?.notification_type === undefined) {
      searchParam.notification_type = PROGRESS_NOTIFICATION_TYPE_COMPLETION_REPORT;
    }

    return searchParam;
  });

  const drawerRef = useRef<any | null>(null);
  const notificationListRef = useRef<HTMLDivElement | null>(null);
  const [isSearchModalVisible, setIsSearchModalVisible] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<ProgressNotification | undefined>(undefined);
  const [deps, setDeps] = useReducer<
    (
      pref: ProgressNotificationsPaginationParams,
      next: Partial<ProgressNotificationsPaginationParams>
    ) => ProgressNotificationsPaginationParams
  >(
    (prev, next) => {
      return {
        ...prev,
        ...next,
      };
    },
    {
      notification_type: 1,
      category_id: undefined,
      process_text: undefined,
      limit: 20,
      start: 1,
    }
  );

  const setDepsToLocalStorage = useDebouncedCallback((values: ProgressNotificationsPaginationParams) => {
    const stringifyDeps = JSON.stringify(values);
    storageManager.setConstructionItem(progressNotificationsDepsLocalStorageKey, stringifyDeps);
  });

  const [fetchedNotificationLargestIdBeforeOpen, setFetchedNotificationLargestIdBeforeOpen] =
    useState<ProgressNotificationFetchedNotificationLargestId>({
      [PROGRESS_NOTIFICATION_TYPE_COMPLETION_REPORT]: 0,
      [PROGRESS_NOTIFICATION_TYPE_CANCEL_REPORT]: 0,
    });
  const [fetchedNotificationLargestId, setFetchedNotificationLargestId] =
    useState<ProgressNotificationFetchedNotificationLargestId>({
      [PROGRESS_NOTIFICATION_TYPE_COMPLETION_REPORT]: 0,
      [PROGRESS_NOTIFICATION_TYPE_CANCEL_REPORT]: 0,
    });

  const scrollToTop = useCallback(() => {
    const notificationListContainer = notificationListRef.current;
    if (notificationListContainer) {
      notificationListContainer.scrollTo(0, 0);
    }
  }, [notificationListRef]);

  const onDrawerClose = useCallback(() => {
    scrollToTop();
    onClose();
    // #337 一度閉じて通知を再度開くと全件取得再取得してしまう問題対応
  }, [scrollToTop, onClose]);

  const onSuccessfetchProgressNotifications = useCallback(
    (data, searchParam) => {
      dispatch(actions.notifications.fetchNotificationsSummary());

      if (data) {
        const ids = data?.list?.map((item) => item.progress_notification_id as number);

        if (ids.length > 0) {
          const maxId = Math.max(...ids);
          if (maxId > fetchedNotificationLargestId[searchParam.notification_type]) {
            const newLargestId = {
              ...fetchedNotificationLargestId,
              [searchParam.notification_type]: maxId,
            };
            setFetchedNotificationLargestId(newLargestId);
          }
        }
      }
    },
    [setFetchedNotificationLargestId, dispatch, fetchedNotificationLargestId]
  );

  useEffect(() => {
    if (!visible || calledOnce.current) {
      return;
    }

    dispatch(
      actions.progressNotification.fetchProgressNotifications({
        searchParam,
        onSuccess: onSuccessfetchProgressNotifications,
      })
    );
    calledOnce.current = true;
  }, [dispatch, onSuccessfetchProgressNotifications, searchParam, visible]);

  // NOTE: ドロワー外の範囲を押した時にドロワーを閉じる
  useEvent("mousedown", (event: MouseEvent) => {
    const eventTarget = event.target;
    if (drawerRef.current && drawerRef.current.contains(eventTarget)) {
      return;
    }
    const toggleButton = document.getElementById("progress-notification-bell");
    if (toggleButton && eventTarget instanceof Node && toggleButton.contains(eventTarget)) {
      return;
    }
    const imagePreview = document.querySelector(".react-images__blanket");
    if (imagePreview) {
      return;
    }

    // 通知検索の機器分類の条件クリアボタンを押されたときにドロワーを閉じないようにする
    if (eventTarget instanceof Node && (eventTarget.nodeName === "svg" || eventTarget.nodeName === "path")) {
      return;
    }

    onDrawerClose();
  });

  const effectOnce = useRef(false);

  useEffect(() => {
    if (effectOnce.current) return;
    effectOnce.current = true;

    const fetchedNotificationLargestId = loadFetchedNotificationLargestId();
    setFetchedNotificationLargestIdBeforeOpen(fetchedNotificationLargestId);
    setFetchedNotificationLargestId(fetchedNotificationLargestId);

    const localStorageDeps = storageManager.getConstructionItem(progressNotificationsDepsLocalStorageKey);
    if (localStorageDeps) {
      const parsedLocalStorageDeps = JSON.parse(localStorageDeps);
      setDeps(parsedLocalStorageDeps);
    }
  }, []);

  useEffect(() => {
    if (visible === false) {
      setFetchedNotificationLargestIdBeforeOpen(fetchedNotificationLargestId);
      scrollToTop();
    }
  }, [visible, fetchedNotificationLargestId]);

  useEffect(() => {
    scrollToTop();
    setDepsToLocalStorage(deps);
  }, [deps]);

  useEffect(() => {
    storageManager.setConstructionItem(
      progressNotificationsFetchedLargestIdLocalStorageKey,
      JSON.stringify(fetchedNotificationLargestId)
    );
  }, [fetchedNotificationLargestId]);

  const handleScroll = () => {
    if (end || fetching) return;

    const notificationList = notificationListRef.current;
    const containerHeight = notificationList.clientHeight;
    const contentHeight = notificationList.scrollHeight;
    const scrollTop = notificationList.scrollTop;
    const diff = contentHeight - (containerHeight + scrollTop);

    if (diff <= 10 && !fetching) {
      dispatch(
        actions.progressNotification.fetchProgressNotifications({
          searchParam,
          start: notifications.length + 1,
          onSuccess: onSuccessfetchProgressNotifications,
        })
      );
    }
  };

  const onFilterPress = () => {
    setIsSearchModalVisible(true);
  };

  const onRefresh = () => {
    scrollToTop();

    dispatch(
      actions.progressNotification.fetchProgressNotifications({
        searchParam,
        onSuccess: onSuccessfetchProgressNotifications,
      })
    );
  };

  const onDetailPress = (notification: ProgressNotification) => {
    setSelectedNotification(notification);
  };

  const handleSearch = useCallback(
    (searchParam: ProgressNotificationSearchParam) => {
      setDeps({
        category_id: searchParam.category_id,
        process_text: searchParam.process_text,
      });

      setSearchParam(searchParam);
      storageManager.setConstructionItem("progress_notification_search_param", JSON.stringify(searchParam));
      dispatch(
        actions.progressNotification.fetchProgressNotifications({
          searchParam,
          onSuccess: onSuccessfetchProgressNotifications,
        })
      );
      scrollToTop();
    },
    [dispatch, onSuccessfetchProgressNotifications, scrollToTop]
  );

  const renderListHeader = () => {
    const onPillClick = (notificationType) => {
      setDeps({
        notification_type: notificationType,
      });
      handleSearch({
        ...searchParam,
        notification_type: notificationType,
      });
    };

    const onReadAllClick = () => {
      dispatch(
        actions.progressNotification.readAllProgressNotification({
          fetchProgressNotifications: () => {
            dispatch(
              actions.progressNotification.fetchProgressNotifications({
                searchParam,
                onSuccess: onSuccessfetchProgressNotifications,
              })
            );
          },
          fetchNotificationsSummary: () => {
            dispatch(actions.notifications.fetchNotificationsSummary());
          },
        })
      );
    };

    return (
      <div id="list-header">
        <div id="pills">
          <CompleteReportPill
            isSelected={deps.notification_type === PROGRESS_NOTIFICATION_TYPE_COMPLETION_REPORT}
            onClick={() => onPillClick(PROGRESS_NOTIFICATION_TYPE_COMPLETION_REPORT)}
          />
          <CancelReportPill
            isSelected={deps.notification_type === PROGRESS_NOTIFICATION_TYPE_CANCEL_REPORT}
            onClick={() => onPillClick(PROGRESS_NOTIFICATION_TYPE_CANCEL_REPORT)}
          />
        </div>

        {/* ReadAllButton */}
        <a id="read-all-btn" onClick={onReadAllClick}>
          {t("all_read")}
        </a>
      </div>
    );
  };

  return (
    <div ref={drawerRef}>
      <div
        id="progressNotifications"
        className={visible ? "open" : undefined}
        ref={notificationListRef}
        onScroll={handleScroll}
      >
        <div>
          <div className="sticky-header">
            <DrawerHeader
              isLoading={fetching}
              onFilterPress={onFilterPress}
              onRefreshPress={onRefresh}
              onClosePress={onDrawerClose}
            />
            {renderListHeader()}
          </div>
          <NotificationList
            notificationType={deps.notification_type}
            isLoading={fetching}
            fetchedNotificationLargestIdBeforeOpen={fetchedNotificationLargestIdBeforeOpen}
            notifications={notifications}
            onDetailPress={onDetailPress}
          />
        </div>
      </div>
      {isSearchModalVisible && (
        <NotificationSearchModal
          initialCategoryId={deps.category_id}
          initialProcessTitle={deps.process_text}
          onClose={() => setIsSearchModalVisible(false)}
          onSearch={handleSearch}
          searchParam={searchParam}
        />
      )}
      {!!selectedNotification && (
        <NotificationDetailModal
          notification={selectedNotification}
          isVisible={!!selectedNotification}
          onClose={() => setSelectedNotification(undefined)}
        />
      )}
    </div>
  );
};

export default ProgressNotificationDrawer;

const loadFetchedNotificationLargestId = (): ProgressNotificationFetchedNotificationLargestId => {
  const fetchedNotificationLargestId =
    JSON.parse(storageManager.getConstructionItem(progressNotificationsFetchedLargestIdLocalStorageKey)) ?? {};
  if (fetchedNotificationLargestId[PROGRESS_NOTIFICATION_TYPE_COMPLETION_REPORT] === undefined) {
    fetchedNotificationLargestId[PROGRESS_NOTIFICATION_TYPE_COMPLETION_REPORT] = 0;
  }
  if (fetchedNotificationLargestId[PROGRESS_NOTIFICATION_TYPE_CANCEL_REPORT] === undefined) {
    fetchedNotificationLargestId[PROGRESS_NOTIFICATION_TYPE_CANCEL_REPORT] = 0;
  }

  return fetchedNotificationLargestId as ProgressNotificationFetchedNotificationLargestId;
};
