import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import DataExportListDrawerContainer from "../containers/DataExportListDrawerContainer";
import NavigationContainer from "../containers/NavigationContainer";
import { getConstructionId } from "../lib/common";

import { DataExportNotificationIcon } from "./DataExportListDrawer/components/DataExportNotificationIcon";
import ProgressNotificationDrawer from "./ProgressNotificationDrawer";
import ProgressNotificationBell from "./ProgressNotificationDrawer/components/ProgressNotificationBell";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConstructionDetail: false,
      showUserMenu: false,
      timer: 0,
      showDatalist: false,
      isNotificationDrawerVisible: false,
      fetchedJobsLargestIdBeforeOpenDatalist: 0,
    };

    this.closeDatalist = this.closeDatalist.bind(this);
    this.toggleDatalist = this.toggleDatalist.bind(this);
  }

  showUserMenu() {
    this.setState({ showUserMenu: true });
  }

  hideUserMenu() {
    this.setState({ showUserMenu: false });
  }

  enterConstructionArea() {
    if (this.state.timer > 0) {
      clearTimeout(this.state.timer);
    }

    this.setState({
      showConstructionDetail: true,
      timer: 0,
    });
  }

  leaveConstructionArea() {
    const timer = setTimeout(() => {
      this.setState({ showConstructionDetail: false });
    }, 500);

    this.setState({ timer });
  }

  enterConstructionDetail() {
    document.getElementById("construction-icon").style.visibility = "hidden";
  }

  leaveConstructionDetail() {
    document.getElementById("construction-icon").style.visibility = "";
  }

  showConstructionSelector() {
    this.props.showConstructionSelector();
    this.setState({ showConstructionDetail: false });
  }

  toggleDatalist() {
    const { showDatalist, isNotificationDrawerVisible } = this.state;
    if (!showDatalist) {
      const { fetchJobs } = this.props;
      fetchJobs();
    }

    this.setState({
      showDatalist: !showDatalist,
      fetchedJobsLargestIdBeforeOpenDatalist: this.props.fetchedJobsLargestId,
      isNotificationDrawerVisible: !showDatalist ? false : isNotificationDrawerVisible,
    });
  }

  closeDatalist() {
    if (this.state.showDatalist) {
      this.setState({ showDatalist: false });
    }
  }

  omitText(text, validLength) {
    if (text && text.length > validLength) {
      return `${text.substr(0, validLength)}...`;
    }

    return text;
  }

  onNotificationDrawerToggle = () => {
    this.setState((beforeState) => {
      return {
        ...this.state,
        showDatalist: false,
        isNotificationDrawerVisible: !beforeState.isNotificationDrawerVisible,
      };
    });
  };

  onNotificationDrawerClose = () => {
    this.setState({
      ...this.state,
      isNotificationDrawerVisible: false,
    });
  };

  render() {
    const { showDatalist, isNotificationDrawerVisible, fetchedJobsLargestIdBeforeOpenDatalist } = this.state;

    const {
      construction,
      fetchingProgressRate,
      userName,
      logout,
      reloadProgressRate,
      showPasswordEditor,
      showLangSelector,
      resultProgressRate,
      scheduleProgressRate,
      t,
      notifications,
    } = this.props;

    const constructionId = getConstructionId() || 0;

    const resRate =
      !fetchingProgressRate && (resultProgressRate || resultProgressRate === 0) ? resultProgressRate : "  --  ";
    const scheRate =
      !fetchingProgressRate && (scheduleProgressRate || scheduleProgressRate === 0) ? scheduleProgressRate : "  --  ";

    return (
      <header className="header">
        <div className="header-sub">
          <div className="inner">
            <div className="customer-information" onClick={() => this.showConstructionSelector()}>
              {`${construction.customerName} | ${construction.placeName} | ${construction.constructionName}`}
            </div>
            <div className="progress">
              {`${t("progress")} : ${resRate}% / ${scheRate}%`}
              <div
                className={`btn-reload ${fetchingProgressRate ? "animation-rotate-progress" : ""}`}
                onClick={reloadProgressRate}
              >
                <img src="./img/icon-reload-gray.svg" alt="reload" />
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix">
          <div className="header-left">
            <NavigationContainer closeHandler={this.closeDatalist} />
          </div>
          <div className="header-right">
            <nav className="user-nav" onMouseLeave={() => this.hideUserMenu()} onMouseEnter={() => this.showUserMenu()}>
              <ul>
                <li data-test-id="button-header-user_nav">
                  <span className="icon-menu">
                    <img src="./img/icon-menu-white.svg" />
                  </span>
                  {this.state.showUserMenu && (
                    <ul className="dropdown-menu" onClick={() => this.hideUserMenu()}>
                      <li className="user-name">{userName}</li>
                      <li>
                        <a data-test-id="button-header-manual" href="/api/manual" target="_blank">
                          {t("operation_manual")}
                        </a>
                      </li>
                      <li>
                        <a data-test-id="button-header-change_password" onClick={showPasswordEditor}>
                          {t("change_password")}
                        </a>
                      </li>
                      <li>
                        <a data-test-id="button-header-select_language" onClick={showLangSelector}>
                          {t("language")}
                        </a>
                      </li>
                      <li>
                        <a data-test-id="button-header-log_out" onClick={logout}>
                          {t("log_out")}
                        </a>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </nav>
            {constructionId > 0 && (
              <React.Fragment>
                <DataExportNotificationIcon
                  count={notifications.summary.download_job.not_read_num}
                  onClick={this.toggleDatalist}
                />
                <ProgressNotificationBell
                  count={notifications.summary.progress_notification.not_read_num}
                  onClick={this.onNotificationDrawerToggle}
                />
              </React.Fragment>
            )}
          </div>
          <DataExportListDrawerContainer
            open={showDatalist}
            onClose={this.closeDatalist}
            fetchedJobsLargestIdBeforeOpenDatalist={fetchedJobsLargestIdBeforeOpenDatalist}
          />
          <ProgressNotificationDrawer visible={isNotificationDrawerVisible} onClose={this.onNotificationDrawerClose} />
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  construction: PropTypes.object.isRequired,
  fetchingProgressRate: PropTypes.bool.isRequired,
  userName: PropTypes.string.isRequired,
  resultProgressRate: PropTypes.number,
  scheduleProgressRate: PropTypes.number,
  logout: PropTypes.func.isRequired,
  showConstructionSelector: PropTypes.func.isRequired,
  showPasswordEditor: PropTypes.func.isRequired,
  reloadProgressRate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  fetchJobs: PropTypes.func.isRequired,
  fetchedJobsLargestId: PropTypes.number.isRequired,
  notifications: PropTypes.object.isRequired,
  showLangSelector: PropTypes.func.isRequired,
};

export default withTranslation()(Header);
