import { connect } from "react-redux";

import actions from "../../actions";
import Device from "../../components/master/Device";

const mapStateToProps = (state) => {
  return {
    masterItems: state.device.masterItems,
    text: state.device.text,
    items: state.device.items,
    current: state.device.current,
    fetching: state.device.fetching,
    isError: state.device.isError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSearchText: (text) => dispatch(actions.device.changeSearchText(text)),
    fetchItems: (callback) => dispatch(actions.device.fetchItems(callback)),
    searchItems: (text, callback) => dispatch(actions.device.searchItems(text, callback)),
    fetchItem: (id, callback, failedCallback) => dispatch(actions.device.fetchItem(id, callback, failedCallback)),
    createItem: (data, handleSuccess, handleCancel) =>
      dispatch(actions.device.createItem(data, handleSuccess, handleCancel)),
    updateItem: (data, handleSuccess, handleCancel, failedCallback) =>
      dispatch(actions.device.updateItem(data, handleSuccess, handleCancel, failedCallback)),
    deleteItem: (id, name) => dispatch(actions.device.deleteItem(id, name)),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Device);
