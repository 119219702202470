import { Dispatch, MutableRefObject, SetStateAction, useCallback } from "react";
import { useDispatch } from "react-redux";

import actions from "@/actions";
import { KeepOutArea, Machine } from "@/sx-layout/components/plotmap/models";

type Props = {
  moveable: boolean;
  selectedArea?: KeepOutArea;
  updateSelectedArea: Dispatch<SetStateAction<KeepOutArea>>;
  withMachine?: Machine;
  areaRef: MutableRefObject<HTMLDivElement>;
  imageRef: MutableRefObject<HTMLImageElement>;
  scale: number;
  mapRatio: number;
  setIsDragging: (_: boolean) => void;
  resizeTarget: MutableRefObject<{ top: number; left: number; height: number; width: number }>;
};

export const useMoveableArea = ({
  moveable,
  selectedArea,
  updateSelectedArea,
  withMachine,
  areaRef,
  imageRef,
  scale,
  mapRatio,
  setIsDragging,
  resizeTarget,
}: Props) => {
  const dispatch = useDispatch();

  const onDrag = useCallback(
    ({ beforeTranslate }) => {
      if (!moveable) return;

      const target = areaRef.current;
      const imageRect = imageRef.current.getBoundingClientRect();

      // X軸の制限
      let x = beforeTranslate[0];
      const targetLeft = parseInt(target.style.left); // 移動中のオブジェクトのx座標
      const targetWidth = parseInt(target.style.width); // 移動中のオブジェクトの幅
      const imageWidth = imageRect.width * scale; // 画像の幅
      const moveX = targetLeft + beforeTranslate[0];
      if (moveX < 0) {
        // 画像の左にはみ出さないようにする
        x = -targetLeft;
      } else if (moveX + targetWidth > imageWidth) {
        // 画像の右側にはみ出さないようにする
        x = imageWidth - targetLeft - targetWidth;
      }

      // Y軸の制限
      let y = beforeTranslate[1];
      const targetTop = parseInt(target.style.top); // 移動中のオブジェクトのy座標
      const targetHeight = parseInt(target.style.height); // 移動中のオブジェクトの高さ
      const imageHeight = imageRect.height * scale; // 画像の高さ
      const moveY = parseInt(target.style.top) + beforeTranslate[1];
      if (moveY < 0) {
        // 画像の上にはみ出さないようにする
        y = -targetTop;
      } else if (moveY + targetHeight > imageHeight) {
        // 画面の下にはみ出さないようにする
        y = imageHeight - targetTop - targetHeight;
      }

      setIsDragging(true);
      target.style.transform = `translate(${x}px, ${y}px)`;
      if (selectedArea.machine_id) {
        const machine = document.querySelector<HTMLElement>(`#machine-${selectedArea.machine_id}`);
        if (machine) {
          machine.style.transform = `translate(${x}px, ${y}px)`;
        }
      }
    },
    [areaRef, imageRef, moveable, scale, selectedArea?.machine_id, setIsDragging]
  );

  const getTranslateXY = (element) => {
    const style = window.getComputedStyle(element);
    const matrix = new DOMMatrixReadOnly(style.transform);

    return {
      translateX: matrix.m41,
      translateY: matrix.m42,
    };
  };

  const onDragEnd = useCallback(
    ({ isDrag }) => {
      const target = areaRef.current;
      if (!moveable) return;

      // NOTE: チールチップメニューのクリック等でもonDragEndは発火するが、座標を移動してなければ更新不要
      if (isDrag) {
        setIsDragging(false);
        const { translateX, translateY } = getTranslateXY(target);
        const keepOutAreaX = Math.round((parseInt(target.style.left) + translateX) / mapRatio);
        const keepOutAreaY = Math.round((parseInt(target.style.top) + translateY) / mapRatio);
        const w = selectedArea.w;
        const h = selectedArea.h;

        // 紐付きかどうかでAPIを変える
        if (withMachine) {
          // 立入禁止エリアに紐づく重機の位置更新
          const { machine_id } = withMachine;
          const m = document.querySelector<HTMLElement>(`#machine-${machine_id}`);
          const machineX = Math.round((parseInt(m.style.left) + translateX + 20) / mapRatio);
          const machineY = Math.round((parseInt(m.style.top) + translateY + 20) / mapRatio);

          dispatch(
            actions.machineEdit.updateMachineKeepOutAreasCoordinates({
              input: {
                machine: {
                  machine_id: `${withMachine.machine_id}`,
                  x: machineX,
                  y: machineY,
                  timestamp: withMachine.timestamp,
                },
                keepout_area: {
                  keepout_area_id: selectedArea.keepout_area_id,
                  x: keepOutAreaX,
                  y: keepOutAreaY,
                  timestamp: selectedArea.timestamp,
                },
              },
              onSuccess: (response) => {
                target.style.transform = null;
                m.style.transform = null;

                updateSelectedArea((prev) => ({
                  ...prev,
                  x: keepOutAreaX,
                  y: keepOutAreaY,
                  w,
                  h,
                  timestamp: {
                    ...selectedArea.timestamp,
                    update_date: response.keepoutArea.timestamp.update_date,
                  },
                }));
              },
            })
          );
        } else {
          // 座標の更新
          dispatch(
            actions.keepOutAreas.updateKeepOutAreasCoordinates({
              input: {
                keepout_area_id: selectedArea.keepout_area_id,
                x: keepOutAreaX,
                y: keepOutAreaY,
                w,
                h,
                timestamp: {
                  update_date: selectedArea.timestamp.update_date,
                },
              },
              onSuccess: (response) => {
                target.style.transform = null;
                updateSelectedArea((prev) => ({
                  ...prev,
                  x: keepOutAreaX,
                  y: keepOutAreaY,
                  w,
                  h,
                  timestamp: {
                    ...selectedArea.timestamp,
                    update_date: response.timestamp.update_date,
                  },
                }));
              },
            })
          );
        }
      }
    },
    [
      areaRef,
      dispatch,
      mapRatio,
      moveable,
      selectedArea?.h,
      selectedArea?.keepout_area_id,
      selectedArea?.timestamp,
      selectedArea?.w,
      setIsDragging,
      updateSelectedArea,
      withMachine,
    ]
  );

  const onResizeStart = useCallback(() => {
    const target = areaRef.current;
    resizeTarget.current = {
      top: parseInt(target.style.top),
      left: parseInt(target.style.left),
      width: parseInt(target.style.width),
      height: parseInt(target.style.height),
    };
  }, [areaRef, resizeTarget]);

  const onResize = useCallback(
    ({ width, height, direction, drag }) => {
      const target = areaRef.current;
      const beforeTranslate = drag.beforeTranslate;
      const machine = document.querySelector<HTMLElement>(`#machine-${selectedArea.machine_id}`);

      let w = width;
      let h = height;
      let x = beforeTranslate[0];
      let y = beforeTranslate[1];
      const resizeTargetStyle = resizeTarget.current;

      // 左辺を動かしている時の制限
      if (direction[0] === -1) {
        // 左に動かした時の左端を考慮
        if (resizeTargetStyle.left + x <= 0) {
          x = -resizeTargetStyle.left;
          w = resizeTargetStyle.width + resizeTargetStyle.left;
        }

        // 右に動かした時に重機の位置を考慮
        if (machine && resizeTargetStyle.left + x >= parseInt(machine.style.left) + 20) {
          x = parseInt(machine.style.left) + 20 - resizeTargetStyle.left;
          w = resizeTargetStyle.width - x;
        }
      }

      // 右辺を動かしている時の制限
      else if (direction[0] === 1) {
        // 右に動かした時の右端を考慮
        if (resizeTargetStyle.left + width >= imageRef.current.offsetWidth) {
          w = imageRef.current.offsetWidth - resizeTargetStyle.left;
        }

        // 左に動かした時に重機の位置を考慮
        if (machine && resizeTargetStyle.left + width <= parseInt(machine.style.left) + 20) {
          w = parseInt(machine.style.left) + 20 - resizeTargetStyle.left;
        }
      }

      // 上辺を動かしている時の制限
      if (direction[1] === -1) {
        // 上に動かした時の上端を考慮
        if (resizeTargetStyle.top + y <= 0) {
          y = -resizeTargetStyle.top;
          h = resizeTargetStyle.height + resizeTargetStyle.top;
        }

        // 下に動かした時に重機の位置を考慮
        if (machine && resizeTargetStyle.top + y >= parseInt(machine.style.top) + 20) {
          y = parseInt(machine.style.top) + 20 - resizeTargetStyle.top;
          h = resizeTargetStyle.height - y;
        }
      }

      // 下辺を動かしている時の制限
      else if (direction[1] === 1) {
        // 下に動かした時の下端を考慮
        if (resizeTargetStyle.top + height >= imageRef.current.offsetHeight) {
          h = imageRef.current.offsetHeight - resizeTargetStyle.top;
        }

        // 上に動かした時に重機の位置を考慮
        if (machine && resizeTargetStyle.top + height <= parseInt(machine.style.top) + 20) {
          h = parseInt(machine.style.top) + 20 - resizeTargetStyle.top;
        }
      }

      // エリアの最小サイズが50px未満にならない様にする
      if (w < 50 * mapRatio) {
        w = Math.ceil(50 * mapRatio);
      }

      if (h < mapRatio * 50) {
        h = Math.ceil(50 * mapRatio);
      }

      setIsDragging(true);
      target.style.width = `${w}px`;
      target.style.height = `${h}px`;
      target.style.transform = `translate(${x}px, ${y}px)`;
    },
    [areaRef, imageRef, mapRatio, resizeTarget, selectedArea?.machine_id, setIsDragging]
  );

  const onResizeEnd = useCallback(
    ({ isDrag }) => {
      const target = areaRef.current;
      // NOTE: チールチップメニューのクリック等でもonDragEndは発火するが、座標を移動してなければ更新不要
      if (isDrag) {
        setIsDragging(false);
        resizeTarget.current = null;
        const { translateX, translateY } = getTranslateXY(target);
        const x = Math.round((parseInt(target.style.left) + translateX) / mapRatio);
        const y = Math.round((parseInt(target.style.top) + translateY) / mapRatio);
        // pxを除外
        const w = Math.round(parseInt(target.style.width) / mapRatio);
        const h = Math.round(parseInt(target.style.height) / mapRatio);

        // 矩形サイズの更新
        dispatch(
          actions.keepOutAreas.updateKeepOutAreasCoordinates({
            input: {
              keepout_area_id: selectedArea.keepout_area_id,
              x,
              y,
              w,
              h,
              timestamp: {
                update_date: selectedArea.timestamp.update_date,
              },
            },
            onSuccess: (response) => {
              target.style.transform = null;
              updateSelectedArea((prev) => ({
                ...prev,
                x,
                y,
                w,
                h,
                timestamp: {
                  ...selectedArea.timestamp,
                  update_date: response.timestamp.update_date,
                },
              }));
            },
          })
        );
      }
    },
    [
      areaRef,
      dispatch,
      mapRatio,
      resizeTarget,
      selectedArea?.keepout_area_id,
      selectedArea?.timestamp,
      setIsDragging,
      updateSelectedArea,
    ]
  );

  return {
    onDrag,
    onDragEnd,
    onResizeStart,
    onResize,
    onResizeEnd,
  };
};
