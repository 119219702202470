import { useState } from "react";
import { useDispatch } from "react-redux";

import actions from "@/actions";
import {
  CopyMachineProps,
  CopyKeepOutAreasProps,
  CopyMachineWithKeepOutAreaProps,
} from "@/sx-layout/components/plotmap/actions/types";
import { KeepOutArea, Machine } from "@/sx-layout/components/plotmap/models";

export const useCopyModal = (): {
  machineId: number | null;
  keepOutAreaId: number | null;
  target: "machine" | "area" | null;
  openMachine: (machine: Machine) => void;
  openKeepOutArea: (area: KeepOutArea) => void;
  close: () => void;
  copyMachineWithKeepOutArea: (from: string, to: string, option?: { onSuccess: () => void }) => void;
  copyMachine: (from: string, to: string, option?: { onSuccess: () => void }) => void;
  copyKeepOutArea: (from: string, to: string, option?: { onSuccess: () => void }) => void;
} => {
  const dispatch = useDispatch();
  const [machineId, setMachineId] = useState<number>();
  const [keepOutAreaId, setKeepOutAreaId] = useState<number>();
  const [target, setTarget] = useState<"machine" | "area">();

  const openMachine = (machine: Machine) => {
    setMachineId(machine.machine_id);
    setKeepOutAreaId(machine.keepout_area_id);
    setTarget("machine");
  };

  const openKeepOutArea = (area: KeepOutArea) => {
    setMachineId(area.machine_id);
    setKeepOutAreaId(area.keepout_area_id);
    setTarget("area");
  };

  const close = () => {
    setMachineId(null);
    setKeepOutAreaId(null);
    setTarget(null);
  };

  const copyMachineWithKeepOutArea = (
    layoutDateFrom: string,
    layoutDateTo: string,
    option?: { onSuccess: () => void }
  ) => {
    dispatch(
      actions.machineEdit.copyMachineWithKeepOutArea({
        input: {
          machine_id: machineId.toString(),
          layout_date_from: layoutDateFrom,
          layout_date_to: layoutDateTo,
        },
        onSuccess: () => {
          close();
          option?.onSuccess?.();
        },
      } as CopyMachineWithKeepOutAreaProps)
    );
  };

  const copyMachine = (layoutDateFrom: string, layoutDateTo: string, option?: { onSuccess: () => void }) => {
    dispatch(
      actions.machineEdit.copyMachine({
        input: {
          machine_id: machineId.toString(),
          layout_date_from: layoutDateFrom,
          layout_date_to: layoutDateTo,
        },
        onSuccess: () => {
          close();
          option?.onSuccess?.();
        },
      } as CopyMachineProps)
    );
  };

  const copyKeepOutArea = (layoutDateFrom: string, layoutDateTo: string, option?: { onSuccess: () => void }) => {
    dispatch(
      actions.keepOutAreas.copyKeepOutAreas({
        input: {
          keepout_area_id: keepOutAreaId.toString(),
          layout_date_from: layoutDateFrom,
          layout_date_to: layoutDateTo,
        },
        onSuccess: () => {
          close();
          option?.onSuccess?.();
        },
      } as CopyKeepOutAreasProps)
    );
  };

  return {
    machineId,
    keepOutAreaId,
    target,
    openMachine,
    openKeepOutArea,
    close,
    copyMachineWithKeepOutArea,
    copyMachine,
    copyKeepOutArea,
  };
};
