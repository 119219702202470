import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import actions from "@/actions";
import { useEvent } from "@/hooks/useEvent";
import { getConstructionId } from "@/lib/common";
import storageManager from "@/lib/storageManager";
import { AppDispatch } from "@/reducers/types";
import DrawerHeader from "@/sx-layout/components/notification/components/DrawerHeader";
import LayoutNotificationList from "@/sx-layout/components/notification/components/LayoutNotificationList";
import {
  LAYOUT_NOTIFICATION_TYPE_COMPLETION_REPORT,
  LayoutNotificationFetchedNotificationLargestId,
} from "@/sx-layout/components/notification/models";

const layoutNotificationsFetchedLargestIdLocalStorageKey = "LAYOUT_NOTIFICATION_FETCHED_LARGEST_ID";

type Props = {
  visible: boolean;
  onClose: () => void;
};

const NotificationDrawer: React.FC<Props> = ({ visible, onClose }) => {
  const dispatch: AppDispatch = useDispatch();
  const notificationList = useSelector((state) => state.layoutNotifications.notifications.list);
  const isLoading = useSelector((state) => state.layoutNotifications.fetching);

  const drawerRef = useRef<any | null>(null);
  const notificationListRef = useRef<HTMLDivElement | null>(null);

  const [fetchedNotificationLargestIdBeforeOpen, setFetchedNotificationLargestIdBeforeOpen] =
    useState<LayoutNotificationFetchedNotificationLargestId>({
      [LAYOUT_NOTIFICATION_TYPE_COMPLETION_REPORT]: 0,
    });

  const [fetchedNotificationLargestId, setFetchedNotificationLargestId] =
    useState<LayoutNotificationFetchedNotificationLargestId>({
      [LAYOUT_NOTIFICATION_TYPE_COMPLETION_REPORT]: 0,
    });

  const loadFetchedNotificationLargestId = (): LayoutNotificationFetchedNotificationLargestId => {
    const fetchedNotificationLargestId =
      JSON.parse(storageManager.getConstructionItem(layoutNotificationsFetchedLargestIdLocalStorageKey)) ?? {};
    if (fetchedNotificationLargestId[LAYOUT_NOTIFICATION_TYPE_COMPLETION_REPORT] === undefined) {
      fetchedNotificationLargestId[LAYOUT_NOTIFICATION_TYPE_COMPLETION_REPORT] = 0;
    }

    return fetchedNotificationLargestId as LayoutNotificationFetchedNotificationLargestId;
  };

  const effectOnce = useRef(false);

  // 新規の未読通知のスタイル設定
  useEffect(() => {
    if (effectOnce.current) return;
    effectOnce.current = true;

    const fetchedNotificationLargestId = loadFetchedNotificationLargestId();
    setFetchedNotificationLargestIdBeforeOpen(fetchedNotificationLargestId);
    setFetchedNotificationLargestId(fetchedNotificationLargestId);
  }, []);

  const fetchNotifications = () => {
    dispatch(
      actions.layoutNotifications.fetchLayoutNotifications({
        input: {
          notification_type: 1,
        },
        onSuccess: (data) => {
          const ids = data.list.map((n) => n.layout_notification_id);

          if (ids.length > 0) {
            const maxId = Math.max(...ids);
            if (maxId > fetchedNotificationLargestId[LAYOUT_NOTIFICATION_TYPE_COMPLETION_REPORT]) {
              const newLargestId = {
                ...fetchedNotificationLargestId,
                [LAYOUT_NOTIFICATION_TYPE_COMPLETION_REPORT]: maxId,
              };
              setFetchedNotificationLargestId(newLargestId);
            }
          }
        },
      })
    );
  };

  const constructionId = getConstructionId();

  useEffect(() => {
    if (constructionId && visible) {
      dispatch(actions.layoutNotifications.clearLayoutNotifications());
      fetchNotifications();
    }
  }, [visible]);

  useEffect(() => {
    storageManager.setConstructionItem(
      layoutNotificationsFetchedLargestIdLocalStorageKey,
      JSON.stringify(fetchedNotificationLargestId)
    );
  }, [fetchedNotificationLargestId]);

  const scrollToTop = useCallback(() => {
    const notificationListContainer = notificationListRef.current;
    if (notificationListContainer) {
      notificationListContainer.scrollTo(0, 0);
    }
  }, [notificationListRef]);

  // 通知リストのスクロール位置の初期化
  useEffect(() => {
    if (visible === false) {
      setFetchedNotificationLargestIdBeforeOpen(fetchedNotificationLargestId);
      scrollToTop();
    }
  }, [visible, fetchedNotificationLargestId]);

  // ローカルストレージに通知IDを保持する
  useEffect(() => {
    storageManager.setConstructionItem(
      layoutNotificationsFetchedLargestIdLocalStorageKey,
      JSON.stringify(fetchedNotificationLargestId)
    );
  }, [fetchedNotificationLargestId]);

  // NOTE: ドロワー外の範囲を押した時にドロワーを閉じる
  useEvent("mousedown", (event: MouseEvent) => {
    const eventTarget = event.target;
    if (drawerRef.current && drawerRef.current.contains(eventTarget)) {
      return;
    }
    const toggleButton = document.getElementById("layout-notification-bell");
    if (toggleButton && eventTarget instanceof Node && toggleButton.contains(eventTarget)) {
      return;
    }
    const imagePreview = document.querySelector(".react-images__blanket");
    if (imagePreview) {
      return;
    }

    onClose();
  });

  return (
    <div ref={drawerRef}>
      <div id="layoutNotifications" className={visible ? "open" : undefined} ref={notificationListRef}>
        <div>
          <div className="sticky-header">
            <DrawerHeader
              isLoading={isLoading}
              onRefreshPress={() => {
                fetchNotifications();
              }}
              onClosePress={onClose}
            />
          </div>
          <LayoutNotificationList
            isLoading={isLoading}
            notifications={notificationList}
            notificationType={LAYOUT_NOTIFICATION_TYPE_COMPLETION_REPORT}
            fetchedNotificationLargestIdBeforeOpen={fetchedNotificationLargestIdBeforeOpen}
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationDrawer;
