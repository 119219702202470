import * as constants from "@/constants";
import environments from "@/environments";
import {
  CopyMachineProps,
  CopyMachineWithKeepOutAreaProps,
  CreateMachineProps,
  DeleteMachineProps,
  FetchMachineInput,
  UpdateMachineCoordinatesProps,
  UpdateMachineKeepOutAreasCoordinatesProps,
  UpdateMachineProps,
  UpdateMachineStatusProps,
} from "@/sx-layout/components/plotmap/actions/types";

export const fetchMachine = (props: FetchMachineInput) => {
  const endpoint = `${environments.API_BASE_URI}/machines/${props.machine_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    callbacks: {
      begin: () => {
        return {
          type: constants.MACHINE_BEGIN_FETCH_MACHINE,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess(response);
        }

        return {
          type: constants.MACHINE_END_FETCH_MACHINE,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const copyMachine = (props: CopyMachineProps) => {
  const endpoint = `${environments.API_BASE_URI}/machines/copy`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(props.input),
    callbacks: {
      begin: () => {
        return {
          type: constants.MACHINE_BEGIN_COPY_MACHINE,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess(response);
        }

        return {
          type: constants.MACHINE_END_COPY_MACHINE,
          payload: response.machines,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const createMachine = (props: CreateMachineProps) => {
  const endpoint = `${environments.API_BASE_URI}/machines/create`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(props.input),
    callbacks: {
      begin: () => {
        return {
          type: constants.MACHINE_BEGIN_CREATE_MACHINE,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess(response);
        }

        return {
          type: constants.MACHINE_END_CREATE_MACHINE,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const deleteMachine = (props: DeleteMachineProps) => {
  const endpoint = `${environments.API_BASE_URI}/machines/delete/${props.input.machine_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    body: JSON.stringify({ timestamp: props.input.timestamp }),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    callbacks: {
      begin: () => {
        return {
          type: constants.MACHINE_BEGIN_DELETE_MACHINE,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess(response);
        }

        return {
          type: constants.MACHINE_END_DELETE_MACHINE,
          payload: {
            ...response,
            machine_id: props.input.machine_id,
            keepout_area_id: props.input.keepout_area_id,
          },
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateMachine = (props: UpdateMachineProps) => {
  const endpoint = `${environments.API_BASE_URI}/machines/update/${props.input.machine_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(props.input),
    callbacks: {
      begin: () => {
        return {
          type: constants.MACHINE_BEGIN_UPDATE_MACHINE,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess(response);
        }

        return {
          type: constants.MACHINE_END_UPDATE_MACHINE,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateMachineCoordinates = (props: UpdateMachineCoordinatesProps) => {
  const endpoint = `${environments.API_BASE_URI}/machines/coord/update/${props.input.machine_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(props.input),
    callbacks: {
      begin: () => {
        return {
          type: constants.MACHINE_BEGIN_UPDATE_MACHINE_COORDINATES,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess(response);
        }

        return {
          type: constants.MACHINE_END_UPDATE_MACHINE_COORDINATES,
          params: props.input,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateMachineStatus = (props: UpdateMachineStatusProps) => {
  const endpoint = `${environments.API_BASE_URI}/machines/status/update/${props.input.machine_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(props.input),
    callbacks: {
      begin: () => {
        return {
          type: constants.MACHINE_BEGIN_UPDATE_MACHINE_STATUS,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess(response);
        }

        return {
          type: constants.MACHINE_END_UPDATE_MACHINE_STATUS,
          payload: {
            ...response,
            status: props.input.status,
            use_end_hour: props.input.use_end_hour,
          },
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const copyMachineWithKeepOutArea = (props: CopyMachineWithKeepOutAreaProps) => {
  const endpoint = `${environments.API_BASE_URI}/machines_keepout_areas/copy`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(props.input),
    callbacks: {
      begin: () => {
        return {
          type: constants.MACHINE_BEGIN_COPY_MACHINE,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess(response);
        }

        return {
          type: constants.MACHINE_END_COPY_MACHINE,
          payload: response.machines,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateMachineKeepOutAreasCoordinates = (props: UpdateMachineKeepOutAreasCoordinatesProps) => {
  const endpoint = `${environments.API_BASE_URI}/machines_keepout_areas/coord/update`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(props.input),
    callbacks: {
      begin: () => {
        return {
          type: constants.MACHINE_KEEPOUT_AREA_BEGIN_UPDATE_MACHINE_COORDINATES,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess(response);
        }

        return {
          type: constants.MACHINE_KEEPOUT_AREA_END_UPDATE_MACHINE_COORDINATES,
          params: props.input,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};
