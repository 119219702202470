import React from "react";

type MachineIconProps = {
  className?: string;
  machineIconId: number;
  shapeColor: string;
  active: boolean;
  selected: boolean;
  outline?: boolean;
  fillColor?: string;
};

export const MachineIcon: React.FC<MachineIconProps> = ({
  className,
  machineIconId,
  active,
  selected,
  shapeColor = "#ff0000",
  outline = true,
  fillColor = "#fff",
}) => {
  const svgStyle = {
    borderRadius: "50%",
    backgroundColor: selected ? (active ? "#666" : "#e3844e") : "initial",
    boxShadow: selected ? `0px 0px 2px 2px ${active ? "#666" : "#e3844e"}` : "initial",
  };

  const machineShapeStyle = {
    fill: active ? shapeColor : "#fff",
  };

  const backgroundStyle = {
    fill: active ? "#eee" : shapeColor,
    opacity: active ? 0 : 0.5,
  };

  const outlineStyle = {
    fill: outline ? (active ? "#666" : "#e3844e") : "transparent",
  };

  switch (machineIconId) {
    // クレーン
    case 1: {
      return (
        <svg style={svgStyle} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
          <g>
            <circle style={outlineStyle} cx="50" cy="50" r="50" />
            <circle style={{ fill: fillColor }} cx="50" cy="50" r="44" />
            <circle style={backgroundStyle} cx="50" cy="50" r="44" />
            <g>
              <path
                style={machineShapeStyle}
                d="M33.1,80.9c-4.4,0-8-3.6-8-8.1c0-4.5,3.6-8.1,8-8.1s8,3.6,8,8.1C41,77.3,37.5,80.9,33.1,80.9z M33.1,69.3
                c-1.8,0-3.4,1.6-3.4,3.5s1.5,3.5,3.4,3.5c1.8,0,3.4-1.6,3.4-3.5C36.4,70.9,34.9,69.3,33.1,69.3z"
              />
              <path
                style={machineShapeStyle}
                d="M66.3,80.9c-4.4,0-8-3.6-8-8.1c0-4.5,3.6-8.1,8-8.1s8,3.6,8,8.1C74.2,77.3,70.7,80.9,66.3,80.9z M66.3,69.3
                c-1.8,0-3.4,1.6-3.4,3.5s1.5,3.5,3.4,3.5s3.4-1.6,3.4-3.5C69.6,70.9,68.1,69.3,66.3,69.3z"
              />
              <path
                style={machineShapeStyle}
                d="M21.4,76.2c0.8,0,1.4-0.7,1.2-1.5c-0.2-1.1-0.3-2.5,0.1-4.2c0.2-1.2,0.8-2.9,2.2-4.7c0.8-1,2.6-2.8,5.4-3.6
                c4-1.1,7.3,0.6,8,1c0.6,0.3,4.1,2.3,5.2,6.7c0.5,1.9,0.4,3.5,0.1,4.7c-0.1,0.8,0.4,1.5,1.2,1.5h9.7c0.8,0,1.4-0.7,1.2-1.4
                c-0.1-0.5-0.2-1.1-0.2-1.7c0-1.2,0.1-3.5,1.6-5.9c0.7-1.2,2.2-3,4.7-4.2c0.7-0.3,3.8-1.7,7.6-0.5c0.6,0.2,2.8,1,4.8,3.2
                c0.6,0.7,2.5,3.1,2.7,6.6c0,0.9,0,1.8-0.2,2.5c-0.1,0.8,0.4,1.5,1.2,1.5h2.3c0.7,0,1.2-0.6,1.2-1.2V60.1c0-0.7-0.6-1.2-1.2-1.2
                H17.7c-0.7,0-1.2,0.6-1.2,1.2V75c0,0.7,0.6,1.2,1.2,1.2C20.2,76.2,18.9,76.2,21.4,76.2L21.4,76.2z"
              />
              <path
                style={machineShapeStyle}
                d="M50.3,56c-1.4-2.6-7.6-14.9-9.3-18.3c-0.3-0.5-0.8-0.9-1.5-0.9H21.4c-0.9,0-1.6,0.7-1.6,1.5V55
                c0,0.8,0.7,1.5,1.6,1.5H50C50,56.4,50.5,56.4,50.3,56L50.3,56z M30.2,48.2v-8.2h6.2c1.5,0,1.9,0,2.6,1.4l3.2,6.8
                C42.1,48.2,30.2,48.2,30.2,48.2z"
              />
              <path
                style={machineShapeStyle}
                d="M75.6,41.7c0,0.1,0,0.4-0.1,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.3,0.3-0.6,0.5-0.8,0.7c-0.5,0.3-0.7,0.7-0.9,1
                c-0.4,0.6-0.5,1.1-0.6,1.4c-0.1,0.3-0.2,1.3,0.3,2.3c0.2,0.3,0.7,1.3,2,1.7c0.2,0.1,1.6,0.5,3.1-0.1c0.4-0.2,1.1-0.5,1.6-1.2
                c0.6-0.8,0.6-1.6,0.6-1.9c-0.8,0-1.5,0-2.3,0c0,0.1,0,0.2-0.1,0.4c0,0.1-0.1,0.3-0.3,0.5c-0.3,0.4-0.8,0.4-1,0.4
                c-0.1,0-0.7,0.1-1.2-0.3c-0.7-0.5-0.5-1.3-0.5-1.4c0-0.1,0.1-0.5,0.4-0.9c0.1-0.1,0.1-0.1,0.5-0.4c0.3-0.3,0.5-0.4,0.6-0.5
                c0.2-0.2,0.5-0.5,0.7-0.9c0.2-0.4,0.3-0.8,0.3-1.1c0-0.3,0-0.5,0-0.7v-0.8h-2.3L75.6,41.7L75.6,41.7z"
              />
              <path
                style={machineShapeStyle}
                d="M20.6,37l55.8-18.6c0.8-0.3,1.7,0.3,1.7,1.2v19.2h-2.4V23.3L41,41l-1.5-2.9L20.6,37L20.6,37z"
              />
            </g>
          </g>
        </svg>
      );
    }
    // トラック
    case 2: {
      return (
        <svg style={svgStyle} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
          <g>
            <circle style={outlineStyle} cx="50" cy="50" r="50" />
            <circle style={{ fill: fillColor }} cx="50" cy="50" r="44" />
            <circle style={backgroundStyle} cx="50" cy="50" r="44" />
            <g>
              <path
                style={machineShapeStyle}
                d="M34.1,71.9c-3.4,0-6.2-2.8-6.2-6.3c0-3.5,2.8-6.3,6.2-6.3c3.4,0,6.2,2.8,6.2,6.3
                C40.3,69.1,37.5,71.9,34.1,71.9z M34.1,62.9c-1.4,0-2.6,1.2-2.6,2.7s1.2,2.7,2.6,2.7c1.4,0,2.6-1.2,2.6-2.7
                C36.7,64.1,35.5,62.9,34.1,62.9z"
              />
              <path
                style={machineShapeStyle}
                d="M69.8,71.9c-3.4,0-6.2-2.8-6.2-6.3c0-3.5,2.8-6.3,6.2-6.3s6.2,2.8,6.2,6.3C76,69.1,73.2,71.9,69.8,71.9z
                 M69.8,62.9c-1.4,0-2.6,1.2-2.6,2.7s1.2,2.7,2.6,2.7s2.6-1.2,2.6-2.7C72.4,64.1,71.2,62.9,69.8,62.9z"
              />
              <path
                style={machineShapeStyle}
                d="M87.3,42.9L78,29.4c-0.6-0.8-1.5-1.3-2.5-1.3H63.1c-0.8,0-1.4,0.7-1.4,1.4l0,16.9c0,0.7-0.6,1.3-1.3,1.3H13.8
                c-0.7,0-1.3,0.6-1.3,1.3v17.6c0,0.6,0.4,1,1,1l10,0c1.1,0,2.1-0.9,2.1-2.1c0-1.4,0.3-3.3,1.2-4.7c0.2-0.4,2.2-3.4,6.1-3.9
                c4.3-0.6,6.8,2,7.4,2.6c0.7,0.7,1.8,2.2,2.2,4.7c0.1,0.4,0.1,0.7,0.1,1c0.1,1.2,1,2.1,2.2,2.1l14.4,0.1c1.1,0,2.1-0.9,2.1-2
                c0-1.4,0.3-3.3,1.2-4.7c0.2-0.4,2.2-3.4,6.1-3.9c4.3-0.6,6.8,2,7.4,2.6c0.7,0.7,1.8,2.2,2.2,4.7c0.1,0.4,0.1,0.8,0.1,1.2
                c0,1.1,0.9,2.1,2,2.1c1.4,0,3.1,0,4.5,0c1.6,0,2.9-1.3,2.9-2.8V43.6C87.5,43.4,87.4,43.1,87.3,42.9L87.3,42.9z M65.9,43V31.9h7.9
                c0.8,0,1.5,0.4,1.9,1.1L82,43L65.9,43L65.9,43z"
              />
            </g>
          </g>
        </svg>
      );
    }
    // ユニック
    case 3: {
      return (
        <svg style={svgStyle} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
          <g>
            <circle style={outlineStyle} cx="50" cy="50" r="50" />
            <circle style={{ fill: fillColor }} cx="50" cy="50" r="44" />
            <circle style={backgroundStyle} cx="50" cy="50" r="44" />
            <g>
              <path
                style={machineShapeStyle}
                d="M34.1,73.1c-3.4,0-6.2-2.8-6.2-6.3c0-3.5,2.8-6.3,6.2-6.3c3.4,0,6.2,2.8,6.2,6.3
                C40.3,70.3,37.5,73.1,34.1,73.1z M34.1,64c-1.4,0-2.6,1.2-2.6,2.7s1.2,2.7,2.6,2.7c1.4,0,2.6-1.2,2.6-2.7
                C36.7,65.3,35.5,64,34.1,64z"
              />
              <path
                style={machineShapeStyle}
                d="M69.8,73.1c-3.4,0-6.2-2.8-6.2-6.3c0-3.5,2.8-6.3,6.2-6.3s6.2,2.8,6.2,6.3C76,70.3,73.2,73.1,69.8,73.1z
                 M69.8,64c-1.4,0-2.6,1.2-2.6,2.7s1.2,2.7,2.6,2.7s2.6-1.2,2.6-2.7C72.4,65.3,71.2,64,69.8,64z"
              />
              <path
                style={machineShapeStyle}
                d="M68.5,58.1c4.5-0.3,6.8,2,7.4,2.6c0.7,0.7,1.8,2.2,2.2,4.7c0.1,0.4,0.1,0.8,0.1,1.2c0,1.1,0.9,2.1,2,2.1
                c1.4,0,3.1,0,4.5,0c1.6,0,2.9-1.3,2.9-2.8V44.8c0-0.3-0.1-0.5-0.2-0.7L78,30.6c-0.6-0.8-1.5-1.3-2.5-1.3H63.1
                c-0.8,0-1.4,0.7-1.4,1.4l0,24.9l0,2.5L68.5,58.1L68.5,58.1z M52.6,52.9H13.8c-0.7,0-1.3,0.6-1.3,1.3v13.6c0,0.6,0.4,1,1,1l10,0
                c1.1,0,2.1-0.9,2.1-2.1c0-1.4,0.3-3.3,1.2-4.7c0.2-0.4,2.2-3.4,6.1-3.9c4.3-0.6,6.8,2,7.4,2.6c0.7,0.7,1.8,2.2,2.2,4.7
                c0.1,0.4,0.1,0.7,0.1,1c0.1,1.2,1,2.1,2.2,2.1l7.9,0.1L52.6,52.9L52.6,52.9z M65.9,44.2V33.1h7.9c0.8,0,1.5,0.4,1.9,1.1l6.3,10
                H65.9z"
              />
              <rect x="54.4" y="33.7" style={machineShapeStyle} width="5.4" height="35" />
              <polygon style={machineShapeStyle} points="59.8,28.4 59.8,32.3 31.1,32.3 31.1,26.9 57.8,26.9     " />
              <path
                style={machineShapeStyle}
                d="M31.4,34.6c0,0.1,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.4-0.3,0.5c-0.3,0.4-0.7,0.6-0.9,0.7c-0.5,0.4-0.8,0.8-1,1.1
                c-0.4,0.6-0.6,1.2-0.6,1.5c-0.1,0.4-0.3,1.4,0.3,2.5c0.2,0.3,0.8,1.4,2.2,1.9c0.2,0.1,1.7,0.6,3.3-0.1c0.4-0.2,1.1-0.6,1.7-1.3
                c0.6-0.9,0.7-1.8,0.7-2.1c-0.8,0-1.7,0-2.5,0c0,0.1,0,0.2-0.1,0.4c0,0.1-0.1,0.4-0.3,0.6c-0.4,0.4-0.9,0.5-1.1,0.5
                c-0.1,0-0.8,0.1-1.3-0.3c-0.7-0.5-0.6-1.4-0.6-1.6c0-0.1,0.1-0.6,0.5-1c0.1-0.1,0.1-0.1,0.6-0.5c0.4-0.3,0.5-0.4,0.7-0.5
                c0.2-0.2,0.5-0.5,0.8-1c0.3-0.5,0.3-0.9,0.4-1.2c0-0.3,0-0.5,0-0.7v-0.9h-2.5L31.4,34.6L31.4,34.6z"
              />
            </g>
          </g>
        </svg>
      );
    }
    // JET車・P車
    case 4: {
      return (
        <svg style={svgStyle} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
          <g>
            <circle style={outlineStyle} cx="50" cy="50" r="50" />
            <circle style={{ fill: fillColor }} cx="50" cy="50" r="44" />
            <circle style={backgroundStyle} cx="50" cy="50" r="44" />
            <g>
              <path
                style={machineShapeStyle}
                d="M34.5,72.7c-3.4,0-6.2-2.8-6.2-6.3c0-3.5,2.8-6.3,6.2-6.3s6.2,2.8,6.2,6.3S37.9,72.7,34.5,72.7z M34.5,63.7
                c-1.4,0-2.6,1.2-2.6,2.7c0,1.5,1.2,2.7,2.6,2.7c1.4,0,2.6-1.2,2.6-2.7S35.9,63.7,34.5,63.7z"
              />
              <path
                style={machineShapeStyle}
                d="M69.9,72.7c-3.4,0-6.2-2.8-6.2-6.3c0-3.5,2.8-6.3,6.2-6.3s6.2,2.8,6.2,6.3S73.3,72.7,69.9,72.7z M69.9,63.7
                c-1.4,0-2.6,1.2-2.6,2.7c0,1.5,1.2,2.7,2.6,2.7s2.6-1.2,2.6-2.7S71.4,63.7,69.9,63.7z"
              />
              <path
                style={machineShapeStyle}
                d="M87.3,43.9l-9.2-13.4c-0.6-0.8-1.5-1.3-2.5-1.3H63.3c-0.8,0-1.4,0.6-1.4,1.4l0,24.7c0,0.7-0.6,1.3-1.3,1.3
                H14.4c-0.7,0-1.3,0.6-1.3,1.3v9.5c0,0.6,0.4,1,1,1l9.9,0c1.1,0,2.1-0.9,2.1-2c0-1.4,0.3-3.2,1.2-4.7c0.2-0.4,2.2-3.4,6-3.9
                c4.3-0.5,6.7,2,7.3,2.6c0.7,0.7,1.8,2.2,2.1,4.7c0.1,0.4,0.1,0.7,0.1,1c0.1,1.2,1,2.1,2.2,2.1l14.3,0.1c1.1,0,2.1-0.9,2.1-2
                c0-1.4,0.3-3.2,1.2-4.7c0.2-0.4,2.2-3.4,6-3.9c4.3-0.5,6.7,2,7.3,2.6c0.7,0.7,1.8,2.2,2.1,4.7c0.1,0.4,0.1,0.8,0.1,1.2
                c0,1.1,0.9,2.1,2,2.1c1.3,0,3.1,0,4.5,0c1.6,0,2.8-1.3,2.8-2.8v-21C87.5,44.4,87.4,44.1,87.3,43.9L87.3,43.9z M66,44V33h7.9
                c0.8,0,1.5,0.4,1.9,1L82,44H66z"
              />
              <path
                style={machineShapeStyle}
                d="M15,54.3c-1.2-1.9-2.5-5.8-2.5-11.1c0-4.9,1.5-9.2,2.5-11.1h41.5c1,1.7,2.7,5.7,2.7,11
                c0,5.7-1.5,9.5-2.7,11.1C43.5,54.4,28.2,54.2,15,54.3L15,54.3z"
              />
              <path
                style={machineShapeStyle}
                d="M45.1,35.5H27.4c-0.6,0-1.1-0.5-1.1-1.1v-4.4c0-0.4,0.3-0.8,0.7-1c2.3-0.8,5.3-1.8,9.2-1.8
                c3.7,0,6.3,0.9,9.2,1.8c0.4,0.1,0.7,0.5,0.7,1c0,1.8,0,2.8,0,4.3C46.2,35,45.7,35.5,45.1,35.5L45.1,35.5z"
              />
            </g>
          </g>
        </svg>
      );
    }
    // バキューム車・ローリー
    case 5: {
      return (
        <svg style={svgStyle} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
          <g>
            <circle style={outlineStyle} cx="50" cy="50" r="50" />
            <circle style={{ fill: fillColor }} cx="50" cy="50" r="44" />
            <circle style={backgroundStyle} cx="50" cy="50" r="44" />
            <g>
              <path
                style={machineShapeStyle}
                d="M69.8,71.9c-3.4,0-6.2-2.8-6.2-6.3c0-3.5,2.8-6.3,6.2-6.3c3.4,0,6.2,2.8,6.2,6.3C76,69.1,73.2,71.9,69.8,71.9
                z M69.8,62.9c-1.4,0-2.6,1.2-2.6,2.7c0,1.5,1.2,2.7,2.6,2.7c1.4,0,2.6-1.2,2.6-2.7C72.4,64.1,71.2,62.9,69.8,62.9z"
              />
              <path
                style={machineShapeStyle}
                d="M14,53.3c-0.7-1.9-1.5-5.8-1.5-11.2c0-4.9,0.9-9.2,1.5-11.2h24.7c0.6,1.8,1.6,5.7,1.6,11.1
                c0,5.8-0.9,9.6-1.6,11.2C30.9,53.4,21.8,53.2,14,53.3L14,53.3z"
              />
              <path
                style={machineShapeStyle}
                d="M34.1,71.9c-3.4,0-6.2-2.8-6.2-6.3c0-3.5,2.8-6.3,6.2-6.3s6.2,2.8,6.2,6.3C40.3,69.1,37.5,71.9,34.1,71.9z
                 M34.1,62.9c-1.4,0-2.6,1.2-2.6,2.7c0,1.5,1.2,2.7,2.6,2.7c1.4,0,2.6-1.2,2.6-2.7C36.7,64.1,35.5,62.9,34.1,62.9z"
              />
              <path
                style={machineShapeStyle}
                d="M87.3,42.9L78,29.4c-0.6-0.8-1.5-1.3-2.5-1.3H63.1c-0.8,0-1.4,0.7-1.4,1.4l0,24.9c0,0.7-0.6,1.3-1.3,1.3H13.8
                c-0.7,0-1.3,0.6-1.3,1.3v9.6c0,0.6,0.4,1,1,1l10,0c1.1,0,2.1-0.9,2.1-2.1c0-1.4,0.3-3.3,1.2-4.7c0.2-0.4,2.2-3.4,6.1-3.9
                c4.3-0.6,6.8,2,7.4,2.6c0.7,0.7,1.8,2.2,2.2,4.7c0.1,0.4,0.1,0.7,0.1,1c0.1,1.2,1,2.1,2.2,2.1l14.4,0.1c1.1,0,2.1-0.9,2.1-2
                c0-1.4,0.3-3.3,1.2-4.7c0.2-0.4,2.2-3.4,6.1-3.9c4.3-0.6,6.8,2,7.4,2.6c0.7,0.7,1.8,2.2,2.2,4.7c0.1,0.4,0.1,0.8,0.1,1.2
                c0,1.1,0.9,2.1,2,2.1c1.4,0,3.1,0,4.5,0c1.6,0,2.9-1.3,2.9-2.8V43.6C87.5,43.4,87.4,43.1,87.3,42.9L87.3,42.9z M65.9,43V31.9h7.9
                c0.8,0,1.5,0.4,1.9,1.1L82,43L65.9,43L65.9,43z"
              />
              <path
                style={machineShapeStyle}
                d="M44.1,30.9h13.4c1,0,1.7,0.8,1.7,1.7v18.9c0,1-0.8,1.7-1.7,1.7H44.1c-1,0-1.7-0.8-1.7-1.7V32.7
                C42.3,31.7,43.1,30.9,44.1,30.9z"
              />
            </g>
          </g>
        </svg>
      );
    }
    // その他
    case 6: {
      return (
        <svg style={svgStyle} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
          <g>
            <circle style={outlineStyle} cx="50" cy="50" r="50" />
            <circle style={{ fill: fillColor }} cx="50" cy="50" r="44" />
            <circle style={backgroundStyle} cx="50" cy="50" r="44" />
            <g>
              <path
                style={machineShapeStyle}
                d="M16.5,46.3l29.8-29.8c2-2,5.3-2,7.3,0l29.8,29.8c2,2,2,5.3,0,7.3L53.7,83.5c-2,2-5.3,2-7.3,0L16.5,53.7
                C14.5,51.6,14.5,48.4,16.5,46.3z"
              />
            </g>
          </g>
        </svg>
      );
    }
  }

  return <span />;
};
