import { connect } from "react-redux";

import { Schedule } from "@/components/list/Schedule";
import { BulkUpdateOption, ScheduleConfig, SearchConditionOption } from "@/components/list/Schedule/types";
import { RootState } from "@/reducers/types";

const mapStateToProps = (state: RootState) => {
  const bulkUpdateOptions: BulkUpdateOption[] = state.schedule.options?.bulk_update_options ?? [];
  const searchConditionOptions: SearchConditionOption[] = state.schedule.options?.search_condition_options ?? [];
  const scheduleConfig: ScheduleConfig = state.schedule.options?.schedule_config;

  return {
    masters: state.construction.masters,
    bulkUpdateOptions,
    searchConditionOptions,
    scheduleConfig,
    scheduleResults: state.schedule.results,
    isLoadingResults: state.schedule.isLoadingResults,
    scheduleSchedules: state.schedule.schedules,
    isLoadingSchedules: state.schedule.isLoadingSchedules,
  };
};

export default connect(mapStateToProps)(Schedule);
