import format from "date-fns/format";
import React, { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import actions from "@/actions";
import { HolidayCalendarData } from "@/components/list/Holiday/components/HolidayCalendarData";
import { HolidaySummaryData } from "@/components/list/Holiday/components/HolidaySummaryData";
import { EXPANDED_ROW_HEIGHT, ROW_HEIGHT } from "@/components/list/Holiday/constants";
import { RootState } from "@/reducers/types";

type Props = {
  onScroll: (scrollTop: number) => void;
  startDate: Date;
  endDate: Date;
};

export const HolidayTable = forwardRef<HTMLDivElement, Props>(({ onScroll, startDate, endDate }, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // カレンダーの表示期間
  const numberOfMonths = useMemo((): number => {
    return (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth() + 1;
  }, [startDate, endDate]);

  // カレンダーの表示初期値
  const openToDate = useMemo(() => {
    const today = new Date();
    const firstOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const twoMonthBeforeConstructionEnd = new Date(endDate.getFullYear(), endDate.getMonth() - 2, 1);
    if (numberOfMonths <= 3 || today.getTime() < startDate.getTime()) {
      // 工事期間が3ヶ月以内の場合
      // また、工事期間が未来の場合も開始月を表示する
      return startDate;
    }
    if (twoMonthBeforeConstructionEnd.getTime() < firstOfMonth.getTime()) {
      // 工事期間を超えている場合は一番右に工事期間最終月が表示されるようにする
      return twoMonthBeforeConstructionEnd;
    }

    return firstOfMonth;
  }, [startDate, endDate, numberOfMonths]);

  // 初期表示で最終月が表示されているかどうか (月表示制御用)
  const isEndMonthInitDisplayed = useMemo(() => {
    const numberOfCalendarMonths = numberOfMonths < 3 ? numberOfMonths : 3;
    const endMonth = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
    const lastMonth = new Date(openToDate.getFullYear(), openToDate.getMonth() + numberOfCalendarMonths - 1, 1);
    return endMonth.getTime() === lastMonth.getTime();
  }, [endDate, openToDate, numberOfMonths]);

  // カレンダー表示最終月 (月表示制御用)
  const reactDatepickerEndMonthLabel = useMemo(() => format(endDate, "M月 yyyy"), [endDate]);

  const { unworkDayGroups, expandedRowIndex } = useSelector((state: RootState) => state.holiday);
  const handleClickHoliday = (index: number) => {
    dispatch(actions.holiday.expandRow(index));
  };

  const handleScroll = (e) => {
    e.currentTarget && onScroll(e.currentTarget.scrollTop);
  };

  return (
    <div className="holiday-table w-[780px]">
      <div className="row header-row w-[100%]">
        <div className="w-[100%]">{t("unwork_days")}</div>
      </div>
      <div className="table-body" ref={ref} onScroll={handleScroll}>
        {unworkDayGroups.map((group, index) => {
          return (
            <div
              key={index}
              className="row body-row"
              style={{ height: `${expandedRowIndex === index ? EXPANDED_ROW_HEIGHT : ROW_HEIGHT}px` }}
            >
              <div className="w-[100%] flex-col overflow-hidden">
                {/* 全休日の集計データを表示 */}
                {expandedRowIndex !== index && (
                  <HolidaySummaryData
                    unworkDays={group.unwork_days}
                    onClick={() => {
                      handleClickHoliday(index);
                    }}
                  />
                )}
                {/* 全休日カレンダー表示 */}
                {expandedRowIndex === index && (
                  <HolidayCalendarData
                    unworkDayGroup={group}
                    startDate={startDate}
                    endDate={endDate}
                    openToDate={openToDate}
                    numberOfMonths={numberOfMonths}
                    isEndMonthInitDisplayed={isEndMonthInitDisplayed}
                    reactDatepickerEndMonthLabel={reactDatepickerEndMonthLabel}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});

HolidayTable.displayName = "HolidayTable";
