import * as constants from "@/constants";
import environments from "@/environments";
import {
  CopyTextProps,
  CreateTextProps,
  DeleteTextProps,
  UpdateTextCoordinateProps,
  UpdateTextProps,
} from "@/sx-layout/components/plotmap/actions/types";

export const createText = (props: CreateTextProps) => {
  const endpoint = `${environments.API_BASE_URI}/freetexts/create`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(toRequest(props.input)),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_CREATE_TEXTS,
        };
      },
      success: (response) => {
        const text = fromResponse(response, props.input.x, props.input.y);
        props.onSuccess?.(text);

        return {
          type: constants.PLOT_MAP_END_CREATE_TEXTS,
          payload: text,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const deleteText = (props: DeleteTextProps) => {
  const endpoint = `${environments.API_BASE_URI}/freetexts/delete/${props.input.freetext_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    body: JSON.stringify({ timestamp: props.input.timestamp }),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_DELETE_TEXTS,
        };
      },
      success: (response) => {
        props.onSuccess?.(response);

        return {
          type: constants.PLOT_MAP_END_DELETE_TEXTS,
          payload: {
            ...response,
            freetext_id: props.input.freetext_id,
          },
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateText = (props: UpdateTextProps) => {
  const endpoint = `${environments.API_BASE_URI}/freetexts/update/${props.input.freetext_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(toRequest(props.input)),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_UPDATE_TEXTS,
        };
      },
      success: (response) => {
        const text = fromResponse(response, props.input.x, props.input.y);
        props.onSuccess?.(text);

        return {
          type: constants.PLOT_MAP_END_UPDATE_TEXTS,
          payload: text,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateTextCoordinate = (props: UpdateTextCoordinateProps) => {
  const endpoint = `${environments.API_BASE_URI}/freetexts/coord/update/${props.input.freetext_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(toRequest(props.input)),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_UPDATE_TEXT_COORDINATE,
        };
      },
      success: (response) => {
        const text = fromResponse(response, props.input.x, props.input.y);
        props.onSuccess?.(text);

        return {
          type: constants.PLOT_MAP_END_UPDATE_TEXT_COORDINATE,
          payload: text,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const copyText = (props: CopyTextProps) => {
  const endpoint = `${environments.API_BASE_URI}/freetexts/copy`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(props.input),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_COPY_TEXTS,
        };
      },
      success: (response) => {
        props.onSuccess?.(response);

        return {
          type: constants.PLOT_MAP_END_COPY_TEXTS,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

const toRequest = (input) => {
  return {
    ...input,
    color: input.color?.replace("#", ""),
    x: Math.round(input.x),
    y: Math.round(input.y),
  };
};

const fromResponse = (response, x, y) => {
  return {
    ...response,
    color: `#${response.color}`,
    // NOTE: リフレッシュするまでは座標を丸めない
    x,
    y,
  };
};
