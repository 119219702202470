import { connect } from "react-redux";

import actions from "../../actions/index";
import Approval from "../../components/list/Approval";

const mapStateToProps = (state) => {
  let areas = [];
  let devices = [];
  let categories = [];
  let facilityManagements = [];
  let constructionManagements = [];
  let primaryCharges = [];
  let others = [];
  let processMajorClasses = [];
  let checkpoints = [];
  let systems = [];
  let companies = [];
  let users = [];
  let groups = [];
  let process_middle_classes = [];
  let userRole;

  if (state.construction.masters) {
    areas = state.construction.masters.areas;
    devices = state.construction.masters.devices;
    categories = state.construction.masters.categories;
    facilityManagements = state.construction.masters.facility_managements;
    constructionManagements = state.construction.masters.construction_managements;
    primaryCharges = state.construction.masters.primary_charges;
    others = state.construction.masters.others;
    processMajorClasses = state.construction.masters.process_major_classes;
    checkpoints = state.construction.masters.checkpoints;
    systems = state.construction.masters.systems;
    companies = state.construction.masters.companies;
    users = state.construction.masters.users;
    groups = state.construction.masters.groups;
    process_middle_classes = state.construction.masters.process_middle_classes;
    userRole = state.construction.masters.user_role;
  }

  return {
    masters: {
      areas,
      devices,
      categories,
      facilityManagements,
      constructionManagements,
      primaryCharges,
      others,
      processMajorClasses,
      process_middle_classes,
      checkpoints,
      systems,
      companies,
      users,
      groups,
      userRole,
    },
    areaIds: state.approval.areaIds,
    deviceIds: state.approval.deviceIds,
    categoryId: state.approval.categoryId,
    facilityManagementIds: state.approval.facilityManagementIds,
    constructionManagementIds: state.approval.constructionManagementIds,
    primaryChargeIds: state.approval.primaryChargeIds,
    otherIds: state.approval.otherIds,
    processMajorClassIds: state.approval.processMajorClassIds,
    scheduleEndDate: state.approval.scheduleEndDate,
    checkpointIds: state.approval.checkpointIds,
    companyIds: state.approval.companyIds,
    processMiddleClassIds: state.approval.processMiddleClassIds,
    itemText: state.approval.itemText,
    processText: state.approval.processText,
    filter: state.approval.filter,
    items: state.approval.items,
    switcherStatus: state.approval.switcherStatus,
    fetching: state.approval.fetching,
    isError: state.approval.isError,
    validated: state.approval.validated,
    linkageTasks: state.construction.linkageTasks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeArea: (items) => dispatch(actions.approval.changeArea(items)),
    changeDevice: (items) => dispatch(actions.approval.changeDevice(items)),
    changeCategory: (item) => dispatch(actions.approval.changeCategory(item)),
    changeFacilityManagement: (items) => dispatch(actions.approval.changeFacilityManagement(items)),
    changeConstructionManagement: (items) => dispatch(actions.approval.changeConstructionManagement(items)),
    changePrimaryCharge: (items) => dispatch(actions.approval.changePrimaryCharge(items)),
    changeOther: (items) => dispatch(actions.approval.changeOther(items)),
    changeProcessMajorClass: (items) => dispatch(actions.approval.changeProcessMajorClass(items)),
    changeProcessMiddleClass: (items) => dispatch(actions.approval.changeProcessMiddleClass(items)),
    changeCompany: (items) => dispatch(actions.approval.changeCompany(items)),
    changeScheduleEndDate: (scheduleEndDate) => dispatch(actions.approval.changeScheduleEndDate(scheduleEndDate)),
    changeCheckpoint: (items) => dispatch(actions.approval.changeCheckpoint(items)),
    changeItemText: (itemText) => dispatch(actions.approval.changeItemText(itemText)),
    changeProcessText: (processText) => dispatch(actions.approval.changeProcessText(processText)),
    changeFilter: (flag) => dispatch(actions.approval.changeFilter(flag)),
    search: (params, callback) => dispatch(actions.approval.search(params, callback)),
    clearSearch: () => dispatch(actions.approval.clearSearch()),
    revertLocalCondition: () => dispatch(actions.approval.revertLocalCondition()),
    changeSwitcherStatus: (status) => {
      dispatch(actions.approval.changeSwitcherStatus(status));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Approval);
