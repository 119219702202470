import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/reducers/types";
import { KeepOutAreaPolygon, Rectangle, UserRole, UserRoles } from "@/sx-layout/components/plotmap/models";

export const useSelectedArea = (loggedInUserCompanyId: number | undefined) => {
  const [freeDrawArea, setFreeDrawArea] = useState<KeepOutAreaPolygon>();
  const [rect, setRect] = useState<Rectangle>();
  const [dragging, setDragging] = useState(false);

  // 権限制御
  const userRole = useSelector<RootState, UserRoles>((state) => state.layoutApp.layoutMasters.user_role);
  const isEditable = useMemo(() => {
    const isAdmin = userRole === UserRole.MASTER_ROLE;
    const isUserAndCompany =
      userRole === UserRole.USER_ROLE && freeDrawArea && freeDrawArea.company_id === loggedInUserCompanyId;
    return isAdmin || isUserAndCompany;
  }, [freeDrawArea, loggedInUserCompanyId, userRole]);

  const start = useCallback((area: KeepOutAreaPolygon, rect: Rectangle) => {
    setFreeDrawArea(area);
    setRect(rect);
  }, []);

  const end = useCallback(() => {
    setFreeDrawArea(null);
    setRect(null);
  }, []);

  const changeCoords = useCallback(
    (area: KeepOutAreaPolygon) => {
      setFreeDrawArea({
        ...freeDrawArea,
        coords: area.coords,
        timestamp: { ...area.timestamp, update_date: area.timestamp.update_date },
      });
    },
    [freeDrawArea]
  );

  const startDrag = () => {
    setDragging(true);
  };

  const endDrag = (rect: Rectangle) => {
    setRect(rect);
    setDragging(false);
  };

  const centerX = useCallback((): number => {
    if (!rect) return 0;

    return rect.x + rect.width / 2;
  }, [rect]);

  return { freeDrawArea, start, end, isEditable, changeCoords, rect, centerX, startDrag, endDrag, dragging };
};
