import { connect } from "react-redux";

import actions from "../../actions";
import Other from "../../components/master/Other";

const mapStateToProps = (state) => {
  return {
    masterItems: state.other.masterItems,
    text: state.other.text,
    items: state.other.items,
    current: state.other.current,
    fetching: state.other.fetching,
    isError: state.other.isError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSearchText: (text) => dispatch(actions.other.changeSearchText(text)),
    fetchItems: (callback) => dispatch(actions.other.fetchItems(callback)),
    searchItems: (text, callback) => dispatch(actions.other.searchItems(text, callback)),
    fetchItem: (id, callback, failedCallback) => dispatch(actions.other.fetchItem(id, callback, failedCallback)),
    createItem: (data, handleSuccess, handleCancel) =>
      dispatch(actions.other.createItem(data, handleSuccess, handleCancel)),
    updateItem: (data, handleSuccess, handleCancel, failedCallback) =>
      dispatch(actions.other.updateItem(data, handleSuccess, handleCancel, failedCallback)),
    deleteItem: (id, name) => dispatch(actions.other.deleteItem(id, name)),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Other);
