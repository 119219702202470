const styles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    border: "1px solid rgba(0,0,0,.15)",
    minHeight: 27,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 3,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: "0 .45rem",
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
  }),
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 9999,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
};

export default styles;
