import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class ApprovalGridHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t, userRole } = this.props;

    return (
      <table className="tbl-basic tbl-data list-table-row">
        <tbody>
          <tr>
            <th>No</th>
            <th className="col2">{t("area")}</th>
            <th className="col3">{t("device")}</th>
            <th className="col4">{t("facility_management")}</th>
            <th className="col5">{t("construction_management")}</th>
            <th className="col6">{t("primary_charge")}</th>
            <th className="col7">{t("other")}</th>
            <th className="col8">{t("machines_category")}</th>
            <th className="col9">{t("title")}</th>
            <th className="col10">{t("process_major_class")}</th>
            <th className="col11">{t("process_title")}</th>
            <th>{t("scheduled_date")}</th>
            <th>{t("status")}</th>
            <th>{userRole !== 3 ? t("edit") : t("display")}</th>
          </tr>
        </tbody>
      </table>
    );
  }
}

ApprovalGridHeader.propTypes = {
  t: PropTypes.func.isRequired,
  userRole: PropTypes.number,
};

export default withTranslation()(ApprovalGridHeader);
