import React from "react";
import { useTranslation } from "react-i18next";

import {
  ProgressNotification,
  ProgressNotificationFetchedNotificationLargestId,
  ProgressNotificationType,
} from "../../models";
import NotificationRow from "../NotificationRow";

type Props = {
  notifications: ProgressNotification[];
  onDetailPress: (notification: ProgressNotification) => void;
  isLoading: boolean;
  fetchedNotificationLargestIdBeforeOpen: ProgressNotificationFetchedNotificationLargestId;
  notificationType: ProgressNotificationType;
};

const NotificationList: React.FC<Props> = ({
  notifications,
  onDetailPress,
  isLoading,
  fetchedNotificationLargestIdBeforeOpen,
  notificationType,
}) => {
  const { t } = useTranslation();

  // NOTE: notificationsが存在するとき（= 無限スクロールによる追加読み込み時）に
  // ローディング表示すると、全体の高さがなくなりスクロール位置が先頭に戻ってしまう
  if (notifications.length === 0 && isLoading) {
    return <div className="loading !absolute inset-1/2 w-[40px] h-[40px] z-50"></div>;
  }

  return (
    <div id="progress-notification-list">
      {notifications.length === 0 && !isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          {t("no_data")}
        </div>
      )}
      {notifications.map((notification) => (
        <NotificationRow
          key={notification.progress_notification_id}
          notification={notification}
          onDetailPress={() => onDetailPress(notification)}
          notificationType={notificationType}
          fetchedNotificationLargestIdBeforeOpen={fetchedNotificationLargestIdBeforeOpen}
        />
      ))}
    </div>
  );
};

export default NotificationList;
