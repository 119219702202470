import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Modal from "../Modal";

import { SubmitButton } from "@/components/common/SubmitButton";

class ProtectionEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      target: "protective_equipment_text",
      schedule_id: this.props.row.schedule_id,
      processText: this.props.row.process_text,
      timestamp: this.props.row.timestamp,
      value: "",
      loading: false,

      protective_equipments: [],
      otherName: "",
      equipData: [
        { id: 1, name: "rubber_glove" },
        { id: 2, name: "resistant_cloth" },
        { id: 3, name: "resistant_boots" },
        { id: 4, name: "face_mask" },
        { id: 5, name: "airline_mask" },
        { id: 6, name: "dust_mask" },
        { id: 7, name: "safety_harness" },
        { id: 8, name: "ear_protectors" },
        { id: 9, name: "others" },
      ],

      error: {},
    };

    this.handleChangeProtect = this.handleChangeProtect.bind(this);
    this.handleChangeOtherName = this.handleChangeOtherName.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.props.setUnloadAlert();
    const equips =
      this.props.row.protective_equipment_text == null ? [] : this.props.row.protective_equipment_text.split("\n");

    const equipsIds = [];
    let otherName = [];
    equips.map((equip) => {
      if (equip.charAt(0) === "①") {
        equipsIds.push(1);
      } else if (equip.charAt(0) === "②") {
        equipsIds.push(2);
      } else if (equip.charAt(0) === "③") {
        equipsIds.push(3);
      } else if (equip.charAt(0) === "④") {
        equipsIds.push(4);
      } else if (equip.charAt(0) === "⑤") {
        equipsIds.push(5);
      } else if (equip.charAt(0) === "⑥") {
        equipsIds.push(6);
      } else if (equip.charAt(0) === "⑦") {
        equipsIds.push(7);
      } else if (equip.charAt(0) === "⑧") {
        equipsIds.push(8);
      } else if (equip.charAt(0) === "⑨") {
        equipsIds.push(9);
        otherName = equip.match(/（(.+)）/u);
      }
    });

    this.setState({
      protective_equipments: equipsIds,
      otherName: otherName[1] || "",
    });
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();
  }

  handleChangeProtect(id, checked) {
    if (checked) {
      this.setState({ protective_equipments: [...this.state.protective_equipments, id] });
    } else {
      this.setState({ protective_equipments: this.state.protective_equipments.filter((item) => item != id) });
    }
  }

  handleChangeOtherName(e) {
    this.setState({ otherName: e.target.value });
  }

  handleSave() {
    if (this.state.loading) return;
    const error = {};
    const { t } = this.props;
    const { equipData, protective_equipments, otherName } = this.state;

    const protectStrings = [];

    equipData.map((equip, index) => {
      if (protective_equipments.some((id) => id === equip.id)) {
        if (equip.id === 9) {
          protectStrings.push(t(equip.name) + "（" + otherName + "）");
        } else {
          protectStrings.push(t(equip.name));
        }
      }
    });

    const data = { ...this.state, value: protectStrings.join("\n") };

    if (protective_equipments.some((id) => id === 9) && otherName.length < 1) {
      error.othername = `${t("enter_other_name")}`;
    }

    if (protective_equipments.some((id) => id === 9) && otherName.length > 100) {
      error.othername = `${t("other_name_too_long")}`;
    }

    if (Object.keys(error).length > 0) {
      this.setState({ error });
    } else {
      data.loading = true;
      this.setState(data);
      this.props.updateItem(
        data,
        () => {
          this.setState({ loading: false });
          this.props.closeHandler();
        },
        () => {
          this.setState({ loading: false });
        }
      );
    }
  }

  render() {
    const { closeHandler, t } = this.props;
    const { equipData, processText, error } = this.state;
    // 常時着用保護具は常に表示
    const equipDataMerged = [...equipData, { id: 10, name: "all_time_protective_gear" }];

    return (
      <Modal title={`${t("protection")} ${t("update")}`} closeHandler={() => closeHandler()}>
        <div className="modal-body">
          <div className="form-row mb-0">
            <div className="form-group w-360 txt-center">
              <p className="form-text">{processText ? "[" + processText + "]" : t("no_process_content")}</p>
              <div className="ckbox-vertically w-220 ml-40">
                {equipDataMerged.map((protect, index) => (
                  <React.Fragment key={index}>
                    <label className="ckbox mr-0">
                      <input
                        // 常時着用保護具は変更不可
                        disabled={protect.id === 10}
                        type="checkbox"
                        value={protect.id || undefined}
                        // 常時着用保護具は常にチェック済み
                        checked={
                          this.state.protective_equipments.some((value) => value === protect.id) || protect.id === 10
                        }
                        onChange={(e) => this.handleChangeProtect(protect.id, e.target.checked)}
                      />
                      <span>{t(protect.name)}</span>
                      {protect.id == 9 && (
                        <input
                          data-test-id="text-item-editor-other_name"
                          type="text"
                          className="form-control w-130 schedule-other-name"
                          value={this.state.otherName || ""}
                          onChange={this.handleChangeOtherName}
                        />
                      )}
                    </label>
                    {protect.id == 9 && error.othername && (
                      <div className="form-error">
                        <p className="form-error-message schedule-othername">{error.othername}</p>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={"modal-footer"}>
          <button
            data-test-id="button-column-editor-cancel"
            type="button"
            className="btn btn-gray"
            onClick={() => closeHandler()}
          >
            {t("cancel")}
          </button>
          <SubmitButton
            data-test-id="button-column-editor-save"
            onClick={this.handleSave}
            loading={this.state.loading}
          />
        </div>
      </Modal>
    );
  }
}

ProtectionEditor.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
};

export default withTranslation()(ProtectionEditor);
