import * as constants from "@/constants";
import { LayoutAppState } from "@/sx-layout/models/layoutAppState";

export const layoutApp = (
  state: LayoutAppState = {
    layoutMenu: "machine",
    layoutMasters: {
      companies: [],
      companies_machine_types: [],
      construction_id: 0,
      machine_types: [],
      plot_plans: [],
      user_role: 0,
      users: [],
    },
  },
  action
): LayoutAppState => {
  switch (action.type) {
    case constants.LAYOUT_APP_CHANGE_MENU:
      return { ...state, layoutMenu: action.payload };
    case constants.LAYOUT_MASTERS_END_FETCH_MASTERS:
      return { ...state, layoutMasters: action.payload };
    default:
      return state;
  }
};
