import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Modal from "../Modal";
import { SubmitButton } from "@/components/common/SubmitButton";

class MiddleClassEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coordinate: false,
      loading: false,
    };

    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    const columns = this.props.columns.filter(
      (column) => column.process_middle_class_id === this.props.processMiddleClassId
    );

    if (columns.length === 0) {
      return;
    }

    const column = columns[0];
    const coordinate = column.coordinate_flg;
    this.setState({ coordinate });
    this.props.setUnloadAlert();

    const elem = document.getElementsByClassName("modal-dialog");
    elem[0].classList.add("middleclass-panel");
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();
  }

  handleChangeRadio(value) {
    this.setState({ coordinate: value });
  }

  handleSave() {
    if (this.state.loading) return;
    const columns = this.props.columns.filter(
      (column) => column.process_middle_class_id === this.props.processMiddleClassId
    );

    if (columns.length === 0) {
      this.props.closeHandler();

      return;
    }

    this.setState({ loading: true });
    const coordinate = this.state.coordinate;
    const processIds = columns.map((column) => column.process_id);
    const callback = () => {
      this.setState({ loading: false });
      this.props.closeHandler();
    };
    const failedCallback = () => this.setState({ loading: false });
    this.props.onSave(processIds, coordinate, callback, failedCallback);
  }

  render() {
    const columns = this.props.columns.filter(
      (column) => column.process_middle_class_id === this.props.processMiddleClassId
    );

    if (columns.length === 0) {
      return null;
    }

    const column = columns[0];
    const middleClassName = column.process_middle_class_name;

    const { t } = this.props;

    return (
      <Modal
        title={`${t("edit_process_middle_class")}：[${middleClassName || ""}]`}
        closeHandler={this.props.closeHandler}
      >
        <div className="modal-body">
          <div className="form-row">
            <div className="form-group w-320 ta-c">
              <span className="form-label txt-bold">{t("linking")}</span>
              <div className="toggle-buttons" style={{ width: "113px" }}>
                <label data-test-id="radio-middle-class-editor-coordinate-on">
                  <input type="radio" checked={this.state.coordinate} onChange={() => this.handleChangeRadio(true)} />
                  <span className="btn">{t("on")}</span>
                </label>
                <label data-test-id="radio-middle-class-editor-coordinate-off">
                  <input type="radio" checked={!this.state.coordinate} onChange={() => this.handleChangeRadio(false)} />
                  <span className="btn">{t("off")}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className={"modal-footer"}>
          <button
            data-test-id="button-middle-class-editor-cancel"
            type="button"
            className="btn btn-gray"
            onClick={this.props.closeHandler}
          >
            {t("cancel")}
          </button>
          <SubmitButton
            data-test-id="button-middle-class-editor-save"
            onClick={this.handleSave}
            loading={this.state.loading}
          />
        </div>
      </Modal>
    );
  }
}

MiddleClassEditor.propTypes = {
  columns: PropTypes.array.isRequired,
  closeHandler: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
};

export default withTranslation()(MiddleClassEditor);
