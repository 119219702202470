import { ClassValue } from "clsx";
import { ComponentProps, FC } from "react";

import { cn } from "@/lib/classNames";

export type FormRowProps = Omit<ComponentProps<"div">, "className"> & {
  marginType?: "normal";
  className?: ClassValue;
  errorComponent?: JSX.Element;
};

export const FormRow: FC<FormRowProps> = ({ marginType = "normal", className, errorComponent, ...restProps }) => {
  return (
    <div>
      <div
        className={cn(
          // MEMO: Form の下の errorComponent を近づけて表示するため mb-[0px] とする
          "flex items-center justify-start mb-[0px]",
          marginType === "normal" && "mt-[20px] mr-[28px]",
          className
        )}
        {...restProps}
      />
      {errorComponent}
    </div>
  );
};
