import actions from "../actions";

const alert = (store) => (next) => (action) => {
  if (action.meta && action.meta.alert) {
    const { title, messages, okHandler } = action.meta.alert;
    const okClickHandler = () => {
      if (okHandler !== undefined) {
        okHandler();
      }

      next(actions.app.hideAlert());
    };

    return next(actions.app.showAlert(title, messages, okClickHandler));
  }

  return next(action);
};

export default alert;
