import { ComponentProps, FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../Button";

import { Modal } from "./Modal";
import { ModalBody } from "./ModalBody";
import { ModalFooter } from "./ModalFooter";
import { ModalHeader } from "./ModalHeader";

export type DeleteConfirmModalProps = ComponentProps<typeof Modal> & {
  message: string;
  onSubmit: () => void;
};

export const DeleteConfirmModal: FC<DeleteConfirmModalProps> = ({ message, onClose, onSubmit, id }) => {
  const { t } = useTranslation();

  return (
    <Modal id={id} onClose={onClose}>
      <ModalHeader>{t("confirmation")}</ModalHeader>
      <ModalBody size="small" className="flex-col">
        <p>{message}</p>
        <p>{t("is_it_ok")}</p>
      </ModalBody>
      <ModalFooter>
        <Button buttonType="cancel" onClick={onClose}>
          {t("confirm_dismiss")}
        </Button>
        <Button onClick={onSubmit}>{t("confirm_accept")}</Button>
      </ModalFooter>
    </Modal>
  );
};
