import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Modal from "../Modal";
import ChargeSelect from "../common/ChargeSelect";
import { SubmitButton } from "@/components/common/SubmitButton";

class ColumnEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: 0,
      userId: 0,
      groupId: 0,
      updating: false,
      error: {},
    };

    this.handleChangeCharge = this.handleChangeCharge.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.props.setUnloadAlert();

    const elem = document.getElementsByClassName("modal-dialog");
    elem[0].classList.add("column-panel");
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();
  }

  handleChangeCharge(e) {
    this.setState({
      companyId: e.company_id,
      userId: e.user_id,
      groupId: e.group_id,
    });
  }

  handleSave() {
    if (this.state.updating) return;
    const { t } = this.props;
    const error = {};
    const data = {
      receiver_user_id: this.state.userId,
      receiver_group_id: this.state.groupId,
    };

    if (this.state.userId === 0 && this.state.groupId === 0) {
      error.charge = t("select_receiver");
    }

    if (Object.keys(error).length > 0) {
      this.setState({ error });
    } else {
      this.setState({ updating: true });
      this.props.onSave(
        this.props.process,
        data,
        () => {
          this.setState({ updating: false });
          this.props.closeHandler();
          this.props.handleSearch();
        },
        () => this.setState({ updating: false }),
        () => this.setState({ updating: false })
      );
    }
  }

  render() {
    const { process, masters, closeHandler, onsave, t } = this.props;
    const { userId, groupId, error } = this.state;
    const mastersCopy = { ...masters };

    if (masters.user_role === 2) {
      mastersCopy.users = masters.users.filter((item) => item.company_id == process.company_id);
      mastersCopy.groups = masters.groups.filter((item) => item.company_id == process.company_id);
    }

    return (
      <Modal title={`${t("receiver_all_task")}：[${process.process_name}]`} closeHandler={() => closeHandler()}>
        <div className="modal-body">
          <div className="form-row mb-5">
            <div className="form-group w-360 ta-u">
              <span className="form-label txt-bold">{t("receiver")}</span>
              <div className="d-ib ta-l w-300">
                <ChargeSelect
                  masters={mastersCopy}
                  userId={userId}
                  groupId={groupId}
                  onChange={this.handleChangeCharge}
                />
              </div>
            </div>
            {error.charge && (
              <div className="form-error w-360">
                <p className="form-error-message w-310">{error.charge}</p>
              </div>
            )}
          </div>
        </div>
        <div className={"modal-footer"}>
          <button
            data-test-id="button-column-editor-cancel"
            type="button"
            className="btn btn-gray"
            onClick={() => closeHandler()}
          >
            {t("cancel")}
          </button>
          <SubmitButton
            data-test-id="button-column-editor-save"
            onClick={this.handleSave}
            loading={this.state.updating}
          />
        </div>
      </Modal>
    );
  }
}

ColumnEditor.propTypes = {
  process: PropTypes.object.isRequired,
  masters: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
};

export default withTranslation()(ColumnEditor);
