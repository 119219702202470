import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

import { ContinuousUpdateStatus, SafetyInstructionNumbers } from "../../constants";
import { useBulkEditorDialog } from "../../hooks/useBulkEditorDialog";
import { useContinuousUpdate } from "../../hooks/useContinuousUpdate";
import { useEditorDialog } from "../../hooks/useEditorDialog";
import { useNoteEditor } from "../../hooks/useNoteEditor";
import { UseSearchInputType } from "../../hooks/useSearchInput";
import { BulkUpdateOption, ScheduleConfig, ScheduleSchedule, ScheduleScheduleItem } from "../../types";
import { GridCell } from "../GridCell";
import { GridHeader } from "../GridHeader";
import { ScheduleEditorDialog } from "../ScheduleEditorDialog";

import actions from "@/actions";
import { getConstructionId, isWinOS, omitString } from "@/lib/common";
import { RootState } from "@/reducers/types";

type PlanListContentsProps = {
  bulkUpdateOptions: BulkUpdateOption[];
  condition: UseSearchInputType;
  continuousUpdateStatus: number;
  currentPageOfScheduleList: number;
  data: ScheduleSchedule;
  fetching: boolean;
  scheduleDate: Date;
  scheduleConfig: ScheduleConfig;
  total: number;
  setTooltipText: (text: string) => void;
  onSearch: (option?: { page?: number; isPartialUpdate?: boolean; preventRefresh?: boolean }) => void;
};

export const PlanListContents: FC<PlanListContentsProps> = ({
  bulkUpdateOptions,
  condition,
  continuousUpdateStatus,
  currentPageOfScheduleList,
  data,
  fetching,
  scheduleDate,
  scheduleConfig,
  setTooltipText,
  onSearch,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const constructionId = getConstructionId();

  // scroll-bar表示制御
  const [isRightListOverflow, setIsRightListOverflow] = useState(true);

  useEffect(() => {
    const updateOverflowStatus = (): void => {
      // table wrapperコンテンツ幅
      const wrapperWidth = planListRef.current?.offsetWidth;
      // overflow部分を含めたコンテンツ幅
      const contentsWidth = planListLeftRef.current?.offsetWidth + tableRightHeaderRef.current?.scrollWidth;

      setIsRightListOverflow(wrapperWidth < contentsWidth);
    };

    window.addEventListener("resize", updateOverflowStatus);
    updateOverflowStatus();

    return () => window.removeEventListener("resize", updateOverflowStatus);
  }, []);

  const continuousUpdate = useContinuousUpdate();
  const editorDialog = useEditorDialog({
    bulkUpdateOptions,
    currentPage: currentPageOfScheduleList,
    onSearch: (page, isPartialUpdate) => onSearch({ page, isPartialUpdate, preventRefresh: true }),
  });
  const noteEditor = useNoteEditor();
  const bulkEditorDialog = useBulkEditorDialog({
    scheduleDate,
    bulkUpdateOptions,
    onSearch,
    searchFilter: condition.values(),
  });

  const planListRef = useRef<HTMLDivElement>(); // リスト全体
  const tableRightHeaderRef = useRef<HTMLDivElement>();
  const tableRightBodyRef = useRef<HTMLDivElement>();
  const planListLeftRef = useRef<HTMLDivElement>(); // 固定幅の左側のリスト
  const tableLeftBodyRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (tableRightBodyRef.current?.scrollTop) {
      tableRightBodyRef.current.scrollTop = 0;
    }
  }, [currentPageOfScheduleList]);

  const { loadingItemId, loadingTarget, isLoadingSchedules } = useSelector((state: RootState) => state.schedule);

  const [editableSekou, editableSeiyu] = useMemo((): [boolean, boolean] => {
    const now = moment();

    const calcEndDate = (endDays: string, endTime: string) => {
      const date = moment(scheduleDate);
      const days = Number(endDays);
      const [hour, minute] = endTime.split(":").map((v) => Number(v));

      return (days < 0 ? date.subtract(Math.abs(days), "d") : date.add(days, "d")).set({
        hour: hour,
        minute: minute,
        second: 0,
      });
    };

    const sekou = scheduleConfig.field_options.find((v) => v.target === "sekou");
    const sekouEditEnd = sekou ? calcEndDate(sekou.edit_end_days, sekou.edit_end_time) : now;

    const seiyu = scheduleConfig.field_options.find((v) => v.target === "seiyu");
    const seiyuEditEnd = sekou ? calcEndDate(seiyu.edit_end_days, seiyu.edit_end_time) : now;

    return [now.isBefore(sekouEditEnd), now.isBefore(seiyuEditEnd)];
  }, [scheduleConfig, scheduleDate, continuousUpdateStatus]);

  // Scroll synchronization
  const syncScrollOn = useRef<"left" | "header" | "right" | null>(null);
  const clearSyncScrollControl = useMemo(
    () =>
      _.debounce(() => {
        // scrollTop/Leftの直接変更でonScrollイベントが呼ばれて相互作用しないように制御
        syncScrollOn.current = null;
      }, 100),
    []
  );

  const syncScrollByLeft = (e: React.UIEvent<HTMLDivElement>) => {
    if (syncScrollOn.current && syncScrollOn.current !== "left") return;
    syncScrollOn.current = "left";
    if (tableRightBodyRef.current) {
      tableRightBodyRef.current.scrollTop = e.currentTarget.scrollTop;
    }
    clearSyncScrollControl();
  };

  const syncScrollByHeader = (e: React.UIEvent<HTMLDivElement>) => {
    if (syncScrollOn.current && syncScrollOn.current !== "header") return;
    syncScrollOn.current = "header";
    if (tableRightBodyRef.current) {
      tableRightBodyRef.current.scrollLeft = e.currentTarget.scrollLeft;
    }
    clearSyncScrollControl();
  };

  const syncScrollByRight = (e: React.UIEvent<HTMLDivElement>) => {
    if (syncScrollOn.current && syncScrollOn.current !== "right") return;
    syncScrollOn.current = "right";
    if (tableLeftBodyRef.current) {
      tableLeftBodyRef.current.scrollTop = e.currentTarget.scrollTop;
    }
    if (tableRightHeaderRef.current) {
      tableRightHeaderRef.current.scrollLeft = e.currentTarget.scrollLeft;
    }
    clearSyncScrollControl();
  };

  const setTooltip = (text) => {
    setTooltipText(text);
    ReactTooltip.rebuild();
  };

  const bulkEditSelectItem = (targetName: string, target: string, optionKey?: string) => {
    bulkEditorDialog.openSelect({ targetName, target, optionKey });
  };

  const bulkEditTextItem = (targetName: string, target: string, option?: { multiline?: boolean }) => {
    bulkEditorDialog.openText({ targetName, target, multiline: option?.multiline });
  };

  const handleClickItemName = (path: string, itemId: number) => {
    dispatch(
      actions.matrix.fetchSubject(itemId, (response) => {
        window.open(
          `${path}&item_id=${itemId}&primary_charge_id=${response.primary_charge_id || ""}`,
          "_blank",
          "noreferrer"
        );
      })
    );
  };

  return (
    <>
      <div
        key={`plan-list-contents-${isLoadingSchedules}`} // スクロールが初期位置に戻るように再描画する
        className={clsx("plan-list-container", fetching ? "loading loading--list" : "")}
        ref={planListRef}
      >
        <div className="plan-list w-[350px]" ref={planListLeftRef}>
          <div className="header-row">
            <div className="col1">{t("device")}</div>
            <div className="col2">{t("title")}</div>
            <div className="col3">{t("content")}</div>
          </div>
          <div className="body-area fixed-area" onScroll={syncScrollByLeft} ref={tableLeftBodyRef}>
            {data?.list.map((item: ScheduleScheduleItem, index) => {
              const { device_name, item_name, process_name, category_id, item_id } = item;

              return (
                <div key={`plan-list-left-row-${index}`} className="body-row">
                  <GridCell className="col1" align="left">
                    {device_name}
                  </GridCell>
                  <GridCell className="col2 break-word" align="left">
                    <a
                      onClick={() =>
                        handleClickItemName(`/#/${constructionId}/matrix?category_id=${category_id}`, item_id)
                      }
                    >
                      {omitString(item_name, 22)}
                    </a>
                  </GridCell>
                  <GridCell className="col3 break-word" align="left">
                    {omitString(process_name, 37)}
                  </GridCell>
                </div>
              );
            })}
            <div className="scroll-margin" />
          </div>
        </div>
        <div className="plan-list plan-list-right">
          <div className="header-row fixed-row" ref={tableRightHeaderRef} onScroll={syncScrollByHeader}>
            <div className="col4">
              {t("sankyu")}
              <br />
              {t("manager_title")}
            </div>
            <GridHeader
              className="col5"
              editable={data?.roles[0] && editableSekou}
              onClick={() => bulkEditTextItem(t("construction_attend") + t("company_title"), "field_p1")}
            >
              {t("construction_attend")}
              <br />
              {t("company_title")}
            </GridHeader>
            <GridHeader
              className="vertical-text col6"
              editable={data?.roles[1] && editableSekou}
              onClick={() => bulkEditTextItem(t("number_of_workers"), "field_t1")}
            >
              {t("number_of_workers")}
            </GridHeader>
            <GridHeader
              className="vertical-text col6"
              editable={data?.roles[2] && editableSekou}
              onClick={() => bulkEditTextItem(t("overtime_schedule"), "field_t9")}
            >
              {t("overtime_schedule")}
            </GridHeader>
            <div className="spanning-header">
              <div className="top-row">{t("fire")}</div>
              <div className="bottom-row">
                <GridHeader
                  className="vertical-text col7"
                  editable={data?.roles[3] && editableSekou}
                  onClick={() => bulkEditSelectItem(`${t("fire")} : ${t("open_flame")}`, "field_t2")}
                >
                  {t("open_flame")}
                </GridHeader>
                <GridHeader
                  className="col8"
                  editable={data?.roles[4] && editableSekou}
                  onClick={() => bulkEditSelectItem(`${t("fire")} : ${t("usual")}${t("fire")}`, "field_t3")}
                >
                  {t("usual")}
                  <br />
                  {t("fire")}
                </GridHeader>
                <GridHeader
                  className="col9"
                  editable={data?.roles[5] && editableSekou}
                  onClick={() => bulkEditTextItem(`${t("fire")} : ${t("use_flame")}`, "field_t4")}
                >
                  {t("use_flame")}
                </GridHeader>
              </div>
            </div>
            <GridHeader
              className="col10"
              editable={data?.roles[6] && editableSekou}
              onClick={() => bulkEditSelectItem(t("pipe") + t("cutting"), "field_t5")}
            >
              {t("pipe")}
              <br />
              {t("cutting")}
            </GridHeader>
            <GridHeader
              className="col11"
              editable={data?.roles[7] && editableSekou}
              onClick={() => bulkEditTextItem(t("heavy_machine"), "field_t7")}
            >
              {t("heavy_machine")}
            </GridHeader>
            <GridHeader
              className="vertical-text col12"
              editable={data?.roles[8] && editableSekou}
              onClick={() => bulkEditSelectItem(t("lack_of_oxygen"), "field_t6")}
            >
              {t("lack_of_oxygen")}
            </GridHeader>
            <GridHeader
              className="col13"
              editable={data?.roles[9] && editableSekou}
              onClick={() => bulkEditSelectItem(t("hazard") + t("rank"), "field1")}
            >
              {t("hazard")}
              <br />
              {t("rank")}
            </GridHeader>
            <GridHeader
              className="col14"
              editable={data?.roles[10] && editableSeiyu}
              underColor="green"
              onClick={() => bulkEditSelectItem(t("attendance"), "field2")}
            >
              {t("attendance")}
            </GridHeader>
            <GridHeader
              className="col15"
              editable={data?.roles[11] && editableSekou}
              onClick={() => bulkEditTextItem(t("safety") + t("instruction"), "field_t8")}
            >
              {t("safety")}
              <br />
              {t("instruction")}
            </GridHeader>
            <div className="spanning-header">
              <div className="top-row">{t("safety_instruction_detail")}</div>
              <div className="bottom-row">
                <GridHeader
                  className="col16"
                  editable={data?.roles[12] && editableSekou}
                  onClick={() =>
                    bulkEditTextItem(`${t("safety_instruction_detail")} : ${t("hazard_source")}`, "field_p2", {
                      multiline: true,
                    })
                  }
                >
                  {t("hazard_source")}
                </GridHeader>
                <GridHeader
                  className="col17"
                  editable={data?.roles[13] && editableSekou}
                  style={{ borderRight: "1px solid #fff" }}
                  onClick={() =>
                    bulkEditTextItem(
                      `${t("safety_instruction_detail")} : ${t("hazard_reduction_activity")}`,
                      "field_p3",
                      { multiline: true }
                    )
                  }
                >
                  {t("hazard_reduction_activity")}
                </GridHeader>
              </div>
            </div>
            <GridHeader
              className="col18"
              editable={data?.roles[14] && editableSeiyu}
              underColor="red"
              onClick={() =>
                bulkEditSelectItem(t("construction") + t("supervising_section"), "approve1_status", "approve1")
              }
            >
              {t("construction")}
              <br />
              {t("supervising_section")}
            </GridHeader>
            <GridHeader
              className="col19"
              editable={data?.roles[15] && editableSeiyu}
              underColor="red"
              onClick={() => bulkEditSelectItem(t("PL"), "approve2_status", "approve2")}
            >
              {"PL"}
            </GridHeader>
            <GridHeader
              className="col20"
              editable={data?.roles[16] && editableSeiyu}
              underColor="red"
              onClick={() => bulkEditSelectItem(t("TL"), "approve3_status", "approve3")}
            >
              {"TL"}
            </GridHeader>
            <GridHeader
              className="col21"
              editable={!!data?.roles[17]}
              underColor="red"
              onClick={() =>
                bulkEditTextItem(`${t("construction")}${t("supervising_section")}${t("comments")}`, "note1", {
                  multiline: true,
                })
              }
            >
              {t("construction")}
              {t("supervising_section")}
              <br />
              {t("comments")}
            </GridHeader>
            <GridHeader
              className="col22"
              editable={data?.roles[18] && editableSeiyu}
              underColor="green"
              onClick={() =>
                bulkEditSelectItem(t("facility") + t("supervising_section"), "approve4_status", "approve4")
              }
            >
              {t("facility")}
              <br />
              {t("supervising_section")}
            </GridHeader>
            <GridHeader
              className="col23 last-column"
              editable={!!data?.roles[19]}
              underColor="green"
              onClick={() =>
                bulkEditTextItem(`${t("facility")}${t("supervising_section")}${t("comments")}`, "note2", {
                  multiline: true,
                })
              }
            >
              {t("facility")}
              {t("supervising_section")}
              <br />
              {t("comments")}
            </GridHeader>
            <div className="scroll-margin" />
          </div>
          <div className="scroll-header" />
          <div className="body-area" onScroll={syncScrollByRight} ref={tableRightBodyRef}>
            {data?.list.map((item: ScheduleScheduleItem) => {
              const roles = item.roles;
              const isContinuousUpdateMode = continuousUpdateStatus !== ContinuousUpdateStatus.OFF;
              const canContinuousUpdateSekou = [ContinuousUpdateStatus.CANCEL, ContinuousUpdateStatus.DO].includes(
                continuousUpdateStatus
              );
              const canContinuousUpdateSeiyu = isContinuousUpdateMode;

              const editSelectItem = (targetName: string, target: string, value?: string, optionKey?: string) => {
                if (isContinuousUpdateMode) {
                  continuousUpdate.update(item, target, continuousUpdateStatus);
                } else {
                  editorDialog.openSelect({
                    item,
                    targetName,
                    target,
                    value: value ?? item[target],
                    optionKey,
                  });
                }
              };
              const editTextItem = (
                targetName: string,
                target: string,
                value?: string,
                option?: {
                  multiline?: boolean;
                }
              ) => {
                editorDialog.openText({
                  item,
                  targetName,
                  target,
                  value: value ?? item[target],
                  multiline: option?.multiline,
                });
              };

              return (
                <div
                  key={`plan-list-right-row-${item.schedule_task_id}-${item.timestamp?.update_date}`}
                  className="body-row"
                >
                  <GridCell className="col4">
                    {item.manager_company_name}
                    {item.manager_company_name && item.manager_user_name && <br />}
                    {item.manager_user_name}
                  </GridCell>
                  <GridCell
                    className="col5"
                    align="left"
                    label={item.field_p1}
                    editable={roles[0] && editableSekou && !isContinuousUpdateMode}
                    setTooltip={setTooltip}
                    onClick={() =>
                      editTextItem(t("construction_attend") + t("company_title"), "field_p1", item.field_p1)
                    }
                  />
                  <GridCell
                    className="col6"
                    label={item.field_t1?.toString()}
                    editable={roles[1] && editableSekou && !isContinuousUpdateMode}
                    onClick={() => editTextItem(t("number_of_workers"), "field_t1", item.field_t1?.toString())}
                  />
                  <GridCell
                    className="col6"
                    label={Number.isFinite(item.field_t9) ? item.field_t9.toString() : ""}
                    editable={roles[2] && editableSekou && !isContinuousUpdateMode}
                    onClick={() => editTextItem(t("overtime_schedule"), "field_t9", item.field_t9?.toString())}
                  />
                  <GridCell
                    className="col7"
                    label={item.field_t2_name}
                    editable={roles[3] && editableSekou && item.field_t2 !== continuousUpdateStatus}
                    continuousUpdateStatus={continuousUpdateStatus}
                    isLoading={
                      isContinuousUpdateMode && item.schedule_task_id === loadingItemId && "field_t2" === loadingTarget
                    }
                    disableContinuousUpdate={!canContinuousUpdateSekou}
                    onClick={() => editSelectItem(`${t("fire")} : ${t("open_flame")}`, "field_t2")}
                  />
                  <GridCell
                    className="col8"
                    label={item.field_t3_name}
                    editable={roles[4] && editableSekou && item.field_t3 !== continuousUpdateStatus}
                    disableContinuousUpdate={!canContinuousUpdateSekou}
                    continuousUpdateStatus={continuousUpdateStatus}
                    isLoading={
                      isContinuousUpdateMode && item.schedule_task_id === loadingItemId && "field_t3" === loadingTarget
                    }
                    onClick={() => editSelectItem(`${t("fire")} : ${t("usual")}${t("fire")}`, "field_t3")}
                  />
                  <GridCell
                    className="col9"
                    label={item.field_t4}
                    editable={roles[5] && editableSekou && !isContinuousUpdateMode}
                    setTooltip={setTooltip}
                    onClick={() => editTextItem(`${t("fire")} : ${t("use_flame")}`, "field_t4", item.field_t4)}
                  />
                  <GridCell
                    className="col10"
                    label={item.field_t5_name}
                    editable={roles[6] && editableSekou && item.field_t5 !== continuousUpdateStatus}
                    disableContinuousUpdate={!canContinuousUpdateSekou}
                    continuousUpdateStatus={continuousUpdateStatus}
                    isLoading={
                      isContinuousUpdateMode && item.schedule_task_id === loadingItemId && "field_t5" === loadingTarget
                    }
                    onClick={() => editSelectItem(t("pipe") + t("cutting"), "field_t5")}
                  />
                  <GridCell
                    className="col11"
                    align="left"
                    label={item.field_t7}
                    editable={roles[7] && editableSekou && !isContinuousUpdateMode}
                    setTooltip={setTooltip}
                    onClick={() => editTextItem(t("heavy_machine"), "field_t7", item.field_t7)}
                  />
                  <GridCell
                    className="col12"
                    label={item.field_t6_name}
                    editable={roles[8] && editableSekou && item.field_t6 !== continuousUpdateStatus}
                    disableContinuousUpdate={!canContinuousUpdateSekou}
                    continuousUpdateStatus={continuousUpdateStatus}
                    isLoading={
                      isContinuousUpdateMode && item.schedule_task_id === loadingItemId && "field_t6" === loadingTarget
                    }
                    onClick={() => editSelectItem(t("lack_of_oxygen"), "field_t6")}
                  />
                  <GridCell
                    className="col13"
                    label={item.field1_name}
                    editable={roles[9] && editableSekou && !isContinuousUpdateMode}
                    onClick={() => editSelectItem(t("hazard") + t("rank"), "field1")}
                  />
                  <GridCell
                    className="col14"
                    label={item.field2_name}
                    editable={roles[10] && editableSeiyu && item.field2 !== continuousUpdateStatus}
                    disableContinuousUpdate={!canContinuousUpdateSekou}
                    continuousUpdateStatus={continuousUpdateStatus}
                    isLoading={
                      isContinuousUpdateMode && item.schedule_task_id === loadingItemId && "field2" === loadingTarget
                    }
                    onClick={() => editSelectItem(t("attendance"), "field2")}
                  />
                  <GridCell
                    className="col15 break-word"
                    editable={roles[11] && editableSekou && !isContinuousUpdateMode}
                    onClick={() => editTextItem(t("safety") + t("instruction"), "field_t8", item.field_t8)}
                  >
                    {item.field_t8?.split(",").map((v) => SafetyInstructionNumbers[v])}
                  </GridCell>
                  <GridCell
                    className="col16"
                    align="left"
                    label={item.field_p2}
                    editable={roles[12] && editableSekou && !isContinuousUpdateMode}
                    setTooltip={setTooltip}
                    onClick={() =>
                      editTextItem(
                        `${t("safety_instruction_detail")} : ${t("hazard_source")}`,
                        "field_p2",
                        item.field_p2,
                        { multiline: true }
                      )
                    }
                  />
                  <GridCell
                    className="col17"
                    align="left"
                    label={item.field_p3}
                    editable={roles[13] && editableSekou && !isContinuousUpdateMode}
                    setTooltip={setTooltip}
                    onClick={() =>
                      editTextItem(
                        `${t("safety_instruction_detail")} : ${t("hazard_reduction_activity")}`,
                        "field_p3",
                        item.field_p3,
                        { multiline: true }
                      )
                    }
                  />
                  <GridCell
                    className="col18"
                    editable={roles[14] && editableSeiyu && item.approve1_status !== continuousUpdateStatus}
                    disableContinuousUpdate={!canContinuousUpdateSeiyu}
                    continuousUpdateStatus={continuousUpdateStatus}
                    isLoading={
                      isContinuousUpdateMode && item.schedule_task_id === loadingItemId && "approve1" === loadingTarget
                    }
                    onClick={() =>
                      editSelectItem(
                        t("construction") + t("supervising_section"),
                        "approve1_status",
                        item.approve1_status?.toString(),
                        "approve1"
                      )
                    }
                  >
                    {item.approve1_status_name}
                    <br />
                    {item.approve1_status_name ? item.approve1_user_name : ""}
                  </GridCell>
                  <GridCell
                    className="col19"
                    editable={
                      roles[15] && editableSeiyu && item.approve2_status !== continuousUpdateStatus && item.field1 !== 0
                    } // 危険度ランクが設定されていれば更新可能
                    disableContinuousUpdate={!canContinuousUpdateSeiyu}
                    continuousUpdateStatus={continuousUpdateStatus}
                    isLoading={
                      isContinuousUpdateMode && item.schedule_task_id === loadingItemId && "approve2" === loadingTarget
                    }
                    onClick={() =>
                      editSelectItem(t("PL"), "approve2_status", item.approve2_status?.toString(), "approve2")
                    }
                  >
                    {item.approve2_status_name}
                    <br />
                    {item.approve2_status_name ? item.approve2_user_name : ""}
                  </GridCell>
                  <GridCell
                    className="col20"
                    editable={
                      roles[16] && editableSeiyu && item.approve3_status !== continuousUpdateStatus && item.field1 !== 0
                    } // 危険度ランクが設定されていれば更新可能
                    disableContinuousUpdate={!canContinuousUpdateSeiyu}
                    continuousUpdateStatus={continuousUpdateStatus}
                    isLoading={
                      isContinuousUpdateMode && item.schedule_task_id === loadingItemId && "approve3" === loadingTarget
                    }
                    onClick={() =>
                      editSelectItem(t("TL"), "approve3_status", item.approve3_status?.toString(), "approve3")
                    }
                  >
                    {item.approve3_status_name}
                    <br />
                    {item.approve3_status_name ? item.approve3_user_name : ""}
                  </GridCell>
                  <GridCell
                    className="col21"
                    align="left"
                    label={item.note1 || ""}
                    editable={roles[17] && !isContinuousUpdateMode}
                    setTooltip={setTooltip}
                    editing={noteEditor.id && noteEditor.id === item.schedule_task_id && noteEditor.target === "note1"}
                    onEnter={() => noteEditor.enter({ item, target: "note1" })}
                    onBlur={(value, e) => noteEditor.submit(value, e)}
                  />
                  <GridCell
                    className="col22"
                    editable={roles[18] && editableSeiyu && item.approve4_status !== continuousUpdateStatus}
                    disableContinuousUpdate={!canContinuousUpdateSeiyu}
                    continuousUpdateStatus={continuousUpdateStatus}
                    isLoading={
                      isContinuousUpdateMode && item.schedule_task_id === loadingItemId && "approve4" === loadingTarget
                    }
                    onClick={() =>
                      editSelectItem(
                        t("facility") + t("supervising_section"),
                        "approve4_status",
                        item.approve4_status?.toString(),
                        "approve4"
                      )
                    }
                  >
                    {item.approve4_status_name}
                    <br />
                    {item.approve4_status_name ? item.approve4_user_name : ""}
                  </GridCell>
                  <GridCell
                    className="col23"
                    align="left"
                    label={item.note2 || ""}
                    editable={roles[19] && !isContinuousUpdateMode}
                    setTooltip={setTooltip}
                    editing={noteEditor.id && noteEditor.id === item.schedule_task_id && noteEditor.target === "note2"}
                    onEnter={() => noteEditor.enter({ item, target: "note2" })}
                    onBlur={(value, e) => noteEditor.submit(value, e)}
                  />
                </div>
              );
            })}
            {/* Windowsの場合、overflowしない場合にscroll-barの高さ補完用のエレメントを表示 */}
            {(!isWinOS || !isRightListOverflow) && <div className="scroll-margin" />}
          </div>
        </div>
      </div>

      {editorDialog.inputType && (
        <ScheduleEditorDialog
          processName={editorDialog.item?.process_name}
          targetName={editorDialog.targetName}
          target={editorDialog.target}
          type={editorDialog.inputType}
          value={editorDialog.value}
          onClose={editorDialog.close}
          onChange={(v) => editorDialog.update(v)}
          options={editorDialog.options}
        />
      )}
      {bulkEditorDialog.inputType && (
        <ScheduleEditorDialog
          targetName={bulkEditorDialog.targetName}
          target={bulkEditorDialog.target}
          type={bulkEditorDialog.inputType}
          value={""}
          onClose={bulkEditorDialog.close}
          onChange={(v) => bulkEditorDialog.update(v)}
          options={bulkEditorDialog.options}
          bulk={true}
        />
      )}
    </>
  );
};
