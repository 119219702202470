import React from "react";
import { useTranslation, withTranslation } from "react-i18next";

import * as util from "../../lib/common";

import { TaskSwitcherStatus, TaskSwitcherStatusType } from "@/models/tasks";

type Props = {
  value: TaskSwitcherStatusType;
  onChange: (_: TaskSwitcherStatusType) => void;
};

const StatusSwitcher = (props: Props) => {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const showDropdown = () => {
    setOpen(true);
  };

  const hideDropdown = () => {
    setOpen(false);
  };

  const switcherStatusList = [
    { mark: "", label: `${t("status_change")} OFF`, value: TaskSwitcherStatus.OFF },
    { mark: "ー", label: `（${t("no_process")}）`, value: TaskSwitcherStatus.NO_PROCESS },
    { mark: "", label: `（${t("process_exist")}）`, value: TaskSwitcherStatus.YES_PROCESS },
    { mark: "○", label: `（${t("schedule")}）`, value: TaskSwitcherStatus.SCHEDULE },
    { mark: "◎", label: `（${t("keep")}）`, value: TaskSwitcherStatus.CONTINUE },
    { mark: "②", label: `（${t("schedule_two_days")}）`, value: TaskSwitcherStatus.SCHEDULE_2 },
    { mark: "⓶", label: `（${t("continue_two_days")}）`, value: TaskSwitcherStatus.CONTINUE_2 },
    { mark: "③", label: `（${t("schedule_three_days")}）`, value: TaskSwitcherStatus.SCHEDULE_3 },
    { mark: "⓷", label: `（${t("continue_three_days")}）`, value: TaskSwitcherStatus.CONTINUE_3 },
    { mark: "■", label: `（${t("completion_expected")}）`, value: TaskSwitcherStatus.COMPLETING },
    { mark: "△", label: `（${t("stop")}）`, value: TaskSwitcherStatus.INTERRUPT },
    { mark: "●", label: `（${t("completed")}）`, value: TaskSwitcherStatus.DONE },
  ];

  return (
    <div className="btn-dropdown-area" onMouseLeave={() => hideDropdown()}>
      <button
        data-test-id="button-status-switcher"
        className={`btn btn-dropdown ${props.value === TaskSwitcherStatus.OFF ? "btn-gray" : "btn-blue"}`}
        onClick={showDropdown}
      >
        {t("status_change")}：
        {props.value === TaskSwitcherStatus.OFF ? (
          <span>OFF</span>
        ) : (
          <span className={props.value === TaskSwitcherStatus.YES_PROCESS ? "symbol-empty is-selected" : "status-mark"}>
            {util.getStatusText(props.value)}
          </span>
        )}
      </button>
      {open && (
        <ul className="dropdown-menu" onClick={() => hideDropdown()}>
          {switcherStatusList.map((item, index) => {
            if (props.value === item.value) {
              return null;
            }

            return (
              <li key={index} onClick={() => props.onChange(item.value)}>
                <span
                  className={item.value === TaskSwitcherStatus.YES_PROCESS ? "symbol-empty" : ""}
                  style={{ display: "flex" }}
                >
                  {item.mark && <span className="status-mark">{item.mark}</span>}
                  {item.label}
                </span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default withTranslation()(StatusSwitcher);
