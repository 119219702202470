import { ComponentProps, forwardRef } from "react";

import { cn } from "@/lib/classNames";

export type RadioProps = Omit<ComponentProps<"input">, "className"> & {
  size?: "normal";
  id: ComponentProps<"input">["id"];
};

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ size = "normal", id, children, ...restProps }, ref) => {
    return (
      <label
        htmlFor={id}
        className={cn("inline-flex items-center relative cursor-pointer", size === "normal" && "min-w-[80px]")}
      >
        <input ref={ref} type="radio" className="sr-only peer" id={id} {...restProps} />
        `
        <div className="absolute top-[1px] left-0 w-[16px] h-[16px] border border-[#aaa] rounded-full bg-white" />
        <div className="hidden peer-checked:block absolute top-[3px] left-[2px] w-[12px] h-[12px] rounded-full bg-[#dea321]" />
        <span className="ml-[20px] text-[13px]">{children}</span>
      </label>
    );
  }
);

Radio.displayName = "Radio";
