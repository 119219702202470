import * as util from "./common";
import restoreState from "./restoreState";

const getUserObject = () => {
  const userId = util.getUserId();

  if (!userId) {
    return null;
  }

  const json = localStorage.getItem(userId.toString());

  if (json !== null) {
    return JSON.parse(json);
  }

  return null;
};

const setUserObject = (userObj) => {
  const userId = util.getUserId();

  if (!userId) {
    return;
  }

  localStorage.setItem(userId.toString(), JSON.stringify(userObj));
};

const getUserItem = (key) => {
  const userObj = getUserObject();

  if (!userObj) {
    return null;
  }

  return userObj[key];
};

const setUserItem = (key, text) => {
  const userObj = getUserObject() || {};

  userObj[key] = text;
  setUserObject(userObj);
};

const removeUserItem = (key) => {
  const userObj = getUserObject();

  if (userObj) {
    delete userObj[key];
  }

  setUserObject(userObj);
};

const getConstructionItem = (key) => {
  const userObj = getUserObject();
  const constructionId = util.getConstructionId();

  if (!constructionId || !userObj || !userObj.constructions) {
    return null;
  }

  const construction = userObj.constructions.find((c) => c.id === constructionId);

  if (!construction || !construction[key]) {
    return null;
  }

  return construction[key];
};

const setConstructionItem = (key, text) => {
  const constructionId = util.getConstructionId();

  if (!constructionId) {
    return;
  }

  const userObj = getUserObject() || {};

  if (!userObj.constructions) {
    userObj.constructions = [
      {
        id: constructionId,
        [key]: text,
      },
    ];
  } else {
    if (userObj.constructions.some((c) => c.id === constructionId)) {
      userObj.constructions = userObj.constructions.map((c) => {
        if (c.id === constructionId) {
          c[key] = text;
        }

        return c;
      });
    } else {
      userObj.constructions = [
        ...userObj.constructions,
        {
          id: constructionId,
          [key]: text,
        },
      ];
    }
  }

  setUserObject(userObj);
};

const removeConstructionItem = (key) => {
  const constructionId = util.getConstructionId();

  if (!constructionId) {
    return;
  }

  const userObj = getUserObject();

  if (!userObj || !userObj.constructions) {
    return;
  }

  userObj.constructions = userObj.constructions.map((c) => {
    if (c.id === constructionId) {
      delete c[key];
    }

    return c;
  });

  setUserObject(userObj);
};

const removeConstruction = (cid) => {
  const userObj = getUserObject();

  if (!userObj || !userObj.constructions) {
    return;
  }

  userObj.constructions = userObj.constructions.filter((c) => c.id !== cid);
  setUserObject(userObj);
};

const setLocale = (locale) => {
  localStorage.setItem("locale", locale);
  document.body.classList.remove("en");
  document.body.classList.remove("ja");
  document.body.classList.add(locale);
};

const getLocale = () => {
  const locale = localStorage.getItem("locale");

  return locale;
};

// Layout
const getPlotPlanId = () => {
  const constructionId = util.getConstructionId();

  const plotPlanIds = getUserItem("plotPlanIds");

  if (!plotPlanIds) {
    return false;
  }

  const data = plotPlanIds.find((data) => {
    return String(data.constructionId) === String(constructionId);
  });

  if (data === -1 || !data?.plotPlanId) {
    return false;
  }

  return data.plotPlanId;
};

const setPlotPlanId = (plotPlanId) => {
  const constructionId = util.getConstructionId();

  if (!constructionId) {
    return;
  }

  const userObj = getUserObject() || {};

  if (!userObj.plotPlanIds) {
    userObj.plotPlanIds = [
      {
        constructionId,
        plotPlanId,
      },
    ];
  } else {
    const idx = userObj.plotPlanIds.findIndex((p) => p.constructionId === constructionId);

    if (idx === -1) {
      userObj.plotPlanIds = [
        ...userObj.plotPlanIds,
        {
          constructionId,
          plotPlanId,
        },
      ];
    } else {
      userObj.plotPlanIds[idx] = {
        ...userObj.plotPlanIds[idx],
        plotPlanId,
      };
    }
  }

  setUserObject(userObj);
};

const getLayoutDate = () => {
  const layoutDate = localStorage.getItem("layoutDate");

  if (!layoutDate) {
    return new Date();
  }

  return new Date(layoutDate);
};

const setLayoutDate = (layoutDate) => {
  localStorage.setItem("layoutDate", layoutDate);
};

const getLayoutMapOpacity = () => {
  const layoutMapOpacity = localStorage.getItem("layoutMapOpacity");

  if (!layoutMapOpacity) {
    return 1;
  }

  return layoutMapOpacity;
};

const setLayoutMapOpacity = (layoutMapOpacity) => {
  localStorage.setItem("layoutMapOpacity", layoutMapOpacity);
};

const getLayoutMapNaviDisplay = () => {
  const layoutMapNaviDisplay = localStorage.getItem("layoutMapNaviDisplay");

  if (!layoutMapNaviDisplay) {
    return 1;
  }

  return layoutMapNaviDisplay;
};

const setLayoutMapNaviDisplay = (layoutMapNaviDisplay) => {
  localStorage.setItem("layoutMapNaviDisplay", layoutMapNaviDisplay);
};

const getLayoutMapMagnification = () => {
  const layoutMapMagnification = localStorage.getItem("layoutMapMagnification");

  if (!layoutMapMagnification) {
    return 100;
  }

  return layoutMapMagnification;
};

const setLayoutMapMagnification = (layoutMapMagnification) => {
  localStorage.setItem("layoutMapMagnification", layoutMapMagnification);
};

export default {
  getUserItem,
  setUserItem,
  removeUserItem,
  getConstructionItem,
  setConstructionItem,
  removeConstructionItem,
  removeConstruction,
  setLocale,
  getLocale,
  getPlotPlanId,
  setPlotPlanId,
  getLayoutDate,
  setLayoutDate,
  getLayoutMapOpacity,
  setLayoutMapOpacity,
  getLayoutMapNaviDisplay,
  setLayoutMapNaviDisplay,
  getLayoutMapMagnification,
  setLayoutMapMagnification,
};
