import React from "react";
import { useTranslation, withTranslation } from "react-i18next";

import { isValidStatusSwitch } from "../../lib/roleChecker";
import { TaskSwitcherStatus, TaskSwitcherStatusType, TaskUtil } from "@/models/tasks";
import { ConstructionMasters } from "@/models/masters";

type Props = {
  row: any;
  switcherStatus: TaskSwitcherStatusType;
  showTaskEditor: (item, linkageInfo) => void;
  no: number;
  linkageInfo: any;
  className: string;
};
type DispatchProps = {
  masters: ConstructionMasters;
  changeTaskStatus: (item_id, task, status) => void;
};

const ApprovalGridRow = (props: Props & DispatchProps) => {
  const { t } = useTranslation();
  const handleChangeStatus = () => {
    const { row, switcherStatus } = props;
    if (row["processing"]) return;
    const { item_id } = props.row;
    if (!TaskUtil.equalsTaskAndSwitcherStatus(row, switcherStatus) && switcherStatus !== TaskSwitcherStatus.OFF) {
      // 同じステータスの場合は更新しない
      props.changeTaskStatus(item_id, row, switcherStatus);
    }
  };

  const handleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (props.switcherStatus === TaskSwitcherStatus.OFF) {
      props.showTaskEditor(props.row, props.linkageInfo);
    }
  };

  const checkedStatus =
    props.switcherStatus === TaskSwitcherStatus.SCHEDULE_2 || props.switcherStatus === TaskSwitcherStatus.SCHEDULE_3
      ? TaskSwitcherStatus.SCHEDULE
      : props.switcherStatus === TaskSwitcherStatus.CONTINUE_2 || props.switcherStatus === TaskSwitcherStatus.CONTINUE_3
      ? TaskSwitcherStatus.CONTINUE
      : props.switcherStatus;
  const notAllowed =
    props.switcherStatus === TaskSwitcherStatus.OFF ? false : !isValidStatusSwitch(checkedStatus, props.row.roles);

  let className = props.className;

  if (notAllowed) {
    className = `${className} row-not-allowed`;
  }

  const { linkageInfo } = props;

  return (
    <tr className={className} key={props.row.item_id} onClick={() => (notAllowed ? false : handleChangeStatus())}>
      <td className="txt-center">{props.no}</td>
      <td className="col2">{props.row.area_name}</td>
      <td className="col3">{props.row.device_name}</td>
      <td className="col4">{props.row.facility_management_name}</td>
      <td className="col5">{props.row.construction_management_name}</td>
      <td className="col6">{props.row.primary_charge_name}</td>
      <td className="col7">{props.row.other_name}</td>
      <td className="col8">{props.row.category_name}</td>
      <td className="col9 break-all">{props.row.item_name}</td>
      <td className="col10">{props.row.process_major_class_name}</td>
      <td className="col11 break-all">{props.row.process_name}</td>
      <td className="txt-center">{props.row.schedule_end_date && props.row.schedule_end_date.replace(/-/g, "/")}</td>
      <td className="txt-center cell-inner" style={{ position: "relative" }}>
        {props.row.valid_flg && linkageInfo && linkageInfo.num > 0 && (
          <div className="approval-linkage">
            <img
              src={`img/icon-linkage-${linkageInfo.type}-gray.svg`}
              width="16"
              height="16"
              className="icon"
              style={{ marginRight: "2px", float: "left", cursor: "default" }}
            />
            <span style={{ float: "left" }}>{linkageInfo.num > 1 ? linkageInfo.num : ""}</span>
          </div>
        )}
        <div className="approval-status status-mark">{props.row.display_status}</div>
      </td>
      <td className="txt-center">
        <a onClick={(e) => handleEdit(e)}>{props.masters?.user_role !== 3 ? t("edit") : t("display")}</a>
      </td>
    </tr>
  );
};

export default withTranslation()(ApprovalGridRow);
