import { useState } from "react";

import storageManager from "@/lib/storageManager";

export const useNavigation = (): {
  opacity: number;
  setOpacity: (_: number) => void;
  displayNavi: number;
  setDisplayNavi: (_: number) => void;
  editable: number;
  setEditable: (_: number) => void;
  showMachineList: boolean;
  toggleMachineList: () => void;
  machineListFilter: "all" | "inOperation" | "waiting";
  setMachineListFilter: (_: "all" | "inOperation" | "waiting") => void;
} => {
  const [opacity, setMapOpacity] = useState<number>(Number(storageManager.getLayoutMapOpacity()));
  const [displayNavi, setMapDisplayNavi] = useState<number>(Number(storageManager.getLayoutMapNaviDisplay()));
  const [editable, setMapEditable] = useState<number>(1);
  const [showMachineList, setShowMachineList] = useState<boolean>(true);
  const [machineListFilter, setMapMachineListFilter] = useState<"all" | "inOperation" | "waiting">("all");

  const setOpacity = (value: number) => {
    setMapOpacity(value);
    storageManager.setLayoutMapOpacity(value);
  };
  const setDisplayNavi = (value: number) => {
    setMapDisplayNavi(value);
    storageManager.setLayoutMapNaviDisplay(value);
  };
  const setEditable = (value: number) => {
    setMapEditable(value);
  };
  const toggleMachineList = () => {
    setShowMachineList(!showMachineList);
  };
  const setMachineListFilter = (value: "all" | "inOperation" | "waiting") => {
    setMapMachineListFilter(value);
  };

  return {
    opacity,
    setOpacity,
    displayNavi,
    setDisplayNavi,
    editable,
    setEditable,
    showMachineList,
    toggleMachineList,
    machineListFilter,
    setMachineListFilter,
  };
};
