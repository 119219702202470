import actions from "../actions";
import { validateCondition } from "../lib/validate";

const validateAsset = (store) => (next) => (action) => {
  next(action);
  const state = store.getState();
  if (!(state.app.menu === "asset" && state.construction.masters)) {
    return;
  }

  const { categoryIds, validated } = state.asset;

  if (validated) {
    return;
  }

  const { categories } = state.construction.masters;

  const { changeCategory, setValidated } = actions.asset;

  const validatedCategoryIds = validateCondition(categories, categoryIds);
  if (categoryIds.length !== validatedCategoryIds.length) {
    next(changeCategory(validatedCategoryIds));
  }

  next(setValidated(true));

  return;
};

export default validateAsset;
