import { ClassValue } from "clsx";
import { ComponentProps, FC } from "react";

import { cn } from "@/lib/classNames";

export type ThProps = Omit<ComponentProps<"th">, "className"> & {
  className?: ClassValue;
};

export const Th: FC<ThProps> = ({ children, className, ...restProps }) => {
  return (
    <th className={cn("border border-collapse border-[#888] text-center px-[12px] py-[4px]", className)} {...restProps}>
      {children}
    </th>
  );
};
