import { zodResolver } from "@hookform/resolvers/zod";
import moment from "moment";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as z from "zod";

import actions from "@/actions";
import { RootState } from "@/reducers/types";
import { Button } from "@/sx-layout/common/Button";
import { FormError, FormLabel, FormRow, Select } from "@/sx-layout/common/Form";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "@/sx-layout/common/Modal";
import { ColorPickerFormController } from "@/sx-layout/components/plotmap/components/freeDraw/components/ColorPickerFormController";
import { FreeDrawText } from "@/sx-layout/components/plotmap/components/freeDraw/models";

type FreeDrawTextForm = { text: string; color: string; size: number };

type Props = {
  companyId: number;
  freeDrawText?: FreeDrawText;
  defaultColor: string;
  defaultSize: number;
  onClose: () => void;
  onChange: (text: FreeDrawText) => void;
  initialX: number;
  initialY: number;
};

const TEXT_SIZE_OPTIONS = [
  { label: "16pt", value: 16 },
  { label: "14pt", value: 14 },
  { label: "12pt", value: 12 },
  { label: "10pt", value: 10 },
];

export const EditFreeDrawTextModal: FC<Props> = ({
  companyId,
  freeDrawText,
  defaultColor,
  defaultSize,
  initialX,
  initialY,
  onClose,
  onChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const layoutMasterUsers = useSelector((state) => state.layoutApp.layoutMasters.users);
  const loginUserId = useSelector((state) => state.session.userId);
  const loginUser = layoutMasterUsers.find((user) => user.user_id === loginUserId);

  const plotPlanId = useSelector<RootState, string>((state) => state.plotmap.plotPlanId);
  const layoutDate = useSelector<RootState, Date>((state) => state.plotmap.layoutDate);
  const layoutDateStr = moment(layoutDate).format("YYYY-MM-DD").toString();

  const formSchema = z.object({
    text: z
      .string()
      .max(100, { message: t("text_is_too_long_100") })
      .refine((value) => value !== "", { message: `${t("text")}${t("is_required")}` }),
    color: z.string(),
    size: z.number(),
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FreeDrawTextForm>({
    resolver: zodResolver(formSchema),
    reValidateMode: "onSubmit",
    defaultValues: {
      text: freeDrawText?.content ?? "",
      color: freeDrawText?.color ?? defaultColor,
      size: freeDrawText?.font_size ?? defaultSize,
    },
  });

  const handleSave = useCallback(
    (values: FreeDrawTextForm) => {
      if (!freeDrawText) {
        dispatch(
          actions.texts.createText({
            input: {
              company_id: loginUser.company_id,
              layout_date: layoutDateStr,
              plot_plan_id: Number(plotPlanId),
              content: values.text,
              color: values.color,
              font_size: values.size,
              x: initialX - (Math.max(...values.text.split("\n").map((v) => v.length)) * values.size) / 2,
              y: initialY,
            },
            onSuccess: () => onClose(),
          })
        );
      } else {
        dispatch(
          actions.texts.updateText({
            input: {
              company_id: companyId,
              freetext_id: freeDrawText.freetext_id,
              content: values.text,
              color: values.color,
              font_size: values.size,
              x: freeDrawText.x,
              y: freeDrawText.y,
              timestamp: {
                update_date: freeDrawText.timestamp?.update_date,
              },
            },
            onSuccess: (text: FreeDrawText) => {
              onChange(text);
              onClose();
            },
          })
        );
      }
    },
    [plotPlanId, layoutDateStr, initialX, initialY]
  );

  return (
    <Modal
      id="text-edit-modal"
      onClose={onClose}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      style={{ content: { overflow: "visible" } }}
    >
      <ModalHeader>{t("text_edit")}</ModalHeader>
      <ModalBody size="small" className="modal-body items-start">
        <form>
          <FormRow className="items-start" errorComponent={<FormError>{errors.text?.message}</FormError>}>
            <FormLabel className="mt-2">{t("text")}</FormLabel>
            <textarea
              className="px-[8px] py-[4px] border border-[#000] rounded w-[300px] h-[60px]"
              {...register("text")}
            />
          </FormRow>
          <FormRow className="items-start">
            <FormLabel className="mt-2">{t("text_color")}</FormLabel>
            <ColorPickerFormController
              name="color"
              control={control}
              defaultColor={freeDrawText?.color ?? defaultColor}
            />
          </FormRow>
          <FormRow>
            <FormLabel>{t("text_size")}</FormLabel>
            <Select name="size" control={control} options={TEXT_SIZE_OPTIONS} className="w-100" />
          </FormRow>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button buttonType="cancel" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button onClick={handleSubmit(handleSave)}>{t("save")}</Button>
      </ModalFooter>
    </Modal>
  );
};
