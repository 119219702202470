import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import actions from "../../../../actions";
import {
  ProgressNotification,
  ProgressNotificationFetchedNotificationLargestId,
  ProgressNotificationType,
} from "../../models";

import DetailButton from "./DetailButton";
import DetailRow from "./DetailRow";
import Header from "./Header";

type Props = {
  notification: ProgressNotification;
  onDetailPress: () => void;
  fetchedNotificationLargestIdBeforeOpen: ProgressNotificationFetchedNotificationLargestId;
  notificationType: ProgressNotificationType;
};

const NotificationRow: React.FC<Props> = ({
  notification,
  fetchedNotificationLargestIdBeforeOpen,
  notificationType,
  ...props
}) => {
  const dispatch = useDispatch();

  const [isRead, setIsRead] = useState(false); // NOTE: APIによる既読とUI上の既読は非同期にするためのフラグ

  const notificationClassName = useMemo(() => {
    const baseClassName = "progress-notification";
    if (isRead) {
      return baseClassName + " " + "is-read";
    }
    if (notification.progress_notification_id > fetchedNotificationLargestIdBeforeOpen[notificationType]) {
      return baseClassName + " " + "is-new";
    }

    return baseClassName;
  }, [isRead, notification, fetchedNotificationLargestIdBeforeOpen]);

  useEffect(() => {
    setIsRead(!!notification.read_date);
  }, [notification]);

  const onReadNotification = () => {
    if (isRead) {
      return;
    }
    setIsRead(true);

    dispatch(
      actions.progressNotification.readProgressNotification(notification.progress_notification_id, () => {
        dispatch(actions.notifications.fetchNotificationsSummary());
      })
    );
  };

  const onDetailPress = () => {
    if (!notification.read_date) {
      onReadNotification();
    }
    props.onDetailPress();
  };

  return (
    <div
      className={isRead ? notificationClassName : notificationClassName + " pointer-cursor"}
      onClick={onReadNotification}
    >
      <Header title={notification.notification_type_name} timestamp={notification.notification_date} />
      <div className="progress-notification-content">
        {Object.entries(notification.information).map(([key, value], index) => (
          <DetailRow
            key={`notification-${notification.progress_notification_id}-detail-${index}`}
            label={key}
            content={value}
            comment_num={notification.comment_num}
          />
        ))}
      </div>
      <div className="progress-notification-footer">
        <DetailButton onClick={onDetailPress} />
      </div>
    </div>
  );
};

export default NotificationRow;
