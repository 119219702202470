import { ClassValue } from "clsx";
import { ComponentProps, forwardRef } from "react";

import { cn } from "@/lib/classNames";

export type TextAreaProps = Omit<ComponentProps<"textarea">, "className"> & {
  size?: "normal";
  className?: ClassValue;
};

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ size = "normal", className, ...restProps }, ref) => {
    return (
      <textarea
        ref={ref}
        className={cn(
          "border border-[#000] rounded px-[8px] py-[4px] resize-none",
          size === "normal" && "w-[300px]",
          restProps?.disabled && "bg-[#e9ecef]",
          className
        )}
        rows={size === "normal" ? 5 : undefined}
        {...restProps}
      />
    );
  }
);

TextArea.displayName = "TextArea";
