import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/sx-layout/common/Button";

type Props = {
  onCreateArea: () => void;
  onCreateCustomArea: () => void;
  disabled: boolean;
};

export const AddKeepOutAreaButton: React.FC<Props> = ({ onCreateArea, onCreateCustomArea, disabled }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation();

  const onOpenDropdown = () => {
    setIsDropdownOpen(true);
  };

  const onCloseDropdown = () => {
    setIsDropdownOpen(false);
  };

  return (
    <div className="btn-dropdown-area !z-auto" style={{ width: "190px" }} onMouseLeave={onCloseDropdown}>
      <Button className="flex items-center justify-center btn-dropdown" onClick={onOpenDropdown} disabled={disabled}>
        <img src="./img/icon-add.svg" className="mr-2 w-[16px]" />
        {t("add_keep_out_area")}
      </Button>
      {isDropdownOpen && (
        <ul className="dropdown-menu z-50">
          <li
            onClick={() => {
              onCloseDropdown();
              onCreateArea();
            }}
          >
            <span>
              <img src="./img/keepout_area_rectangle.png" className="w-[20px] mr-[5px]" />
            </span>
            <span>{t("rectangle")}</span>
          </li>
          <li
            id="add-custom-keepout-area-button"
            onClick={() => {
              onCloseDropdown();
              onCreateCustomArea();
            }}
          >
            <span>
              <img src="./img/keepout_area_polygon.png" className="w-[20px] mr-[5px]" />
            </span>
            <span>{t("custom")}</span>
          </li>
        </ul>
      )}
    </div>
  );
};
