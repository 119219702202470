import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import actions from "@/actions";
import { getConstructionId } from "@/lib/common";
import { RootState } from "@/reducers/types";
import { TextButton } from "@/sx-layout/common/Button/TextButton";
import { Header } from "@/sx-layout/common/Header";
import { DeleteConfirmModal } from "@/sx-layout/common/Modal";
import { Table, Tbody, Td, Th, Thead, Tr } from "@/sx-layout/common/Table";
import {
  DeletePlotPlanProps,
  FetchPlotPlansProps,
} from "@/sx-layout/components/settings/plotPlanSetting/actions/types";
import { PlotPlanEditor } from "@/sx-layout/components/settings/plotPlanSetting/components/PlotPlanEditor";
import { PlotPlan } from "@/sx-layout/components/settings/plotPlanSetting/models";
import { PlotPlanState } from "@/sx-layout/components/settings/plotPlanSetting/reducers/plotPlan";
import { qualityMasters } from "@/sx-layout/components/settings/plotPlanSetting/utils/quality";

export const PlotPlanSetting: FC = () => {
  const [isOpenEditor, setIsOpenEditor] = useState(false);
  const [editPlotPlan, setEditPlotPlan] = useState<PlotPlan>();

  const [deleteTargetPlotPlan, setDeleteTargetPlotPlan] = useState<PlotPlan>(null);

  const dispatch = useDispatch();
  const fetchPlotPlans = useCallback(
    (props?: FetchPlotPlansProps) => dispatch(actions.plotPlan.fetchPlotPlans(props)),
    []
  );
  const { plotPlans } = useSelector<RootState, PlotPlanState>((state) => state.plotPlan);

  const deletePlotPlan = useCallback(
    (props: DeletePlotPlanProps) => dispatch(actions.plotPlan.deletePlotPlan(props)),
    []
  );

  const constructionId = getConstructionId();

  useEffect(() => {
    if (constructionId) {
      fetchPlotPlans();
    }
  }, []);

  const { t } = useTranslation();

  return (
    <div className="bg-[#f3f2ef] min-h-screen">
      <Header />
      <div className="px-[32px]">
        <div className="flex items-center space-x-8">
          <h2 className="my-[16px] font-bold text-[16px]">{t("plot_plan")}</h2>
          <button
            type="button"
            onClick={() => setIsOpenEditor(true)}
            className="flex items-center justify-center pb-1 cursor-pointer"
          >
            <img className="w-[24px] h-[24px]" src="./img/icon-add-black.svg" alt="plus"></img>
          </button>
        </div>
        {plotPlans.length > 0 && (
          <Table>
            <Thead>
              <Tr>
                <Th className="w-[280px]">{t("plot_plan_name")}</Th>
                <Th className="w-[150px]">{t("floor_plan_image_quality")}</Th>
                <Th className="w-[100px]">{t("image_size")}</Th>
                <Th>{t("note")}</Th>
                <Th className="w-[80px]">{t("edit")}</Th>
                <Th className="w-[80px]">{t("delete")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {plotPlans.map((plotPlan) => (
                <Tr key={plotPlan.plot_plan_id}>
                  <Td>{plotPlan.plot_plan_name}</Td>
                  <Td>{t(qualityMasters.find((quality) => quality.value === plotPlan.quality)?.label)}</Td>
                  <Td className="text-right">
                    {/* TODO: APIからの返却値がKB単位になっているか確認したい */}
                    {plotPlan.file_size.toLocaleString("ja-JP")}
                  </Td>
                  <Td>{plotPlan.note}</Td>
                  <Td className="text-center">
                    <TextButton
                      onClick={() => {
                        setIsOpenEditor(true);
                        setEditPlotPlan(plotPlan);
                      }}
                    >
                      {t("edit")}
                    </TextButton>
                  </Td>
                  <Td className="text-center">
                    <TextButton
                      onClick={() => {
                        setDeleteTargetPlotPlan(plotPlan);
                      }}
                    >
                      {t("delete")}
                    </TextButton>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </div>
      {isOpenEditor && (
        <PlotPlanEditor
          editPlotPlan={editPlotPlan}
          onClose={() => {
            setIsOpenEditor(false);
            setEditPlotPlan(undefined);
            fetchPlotPlans();
          }}
        />
      )}
      {deleteTargetPlotPlan && (
        <DeleteConfirmModal
          message={`${deleteTargetPlotPlan.plot_plan_name}` + t("delete_confirmation")}
          onClose={() => {
            setDeleteTargetPlotPlan(null);
          }}
          onSubmit={() => {
            deletePlotPlan({
              input: { plot_plan_id: deleteTargetPlotPlan.plot_plan_id },
              onSuccess: () => {
                fetchPlotPlans();
                setDeleteTargetPlotPlan(null);
              },
            });
          }}
        />
      )}
    </div>
  );
};
