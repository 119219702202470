import { connect } from "react-redux";

import actions from "../../actions/index";
import Qrcode from "../../components/qrcode/Qrcode";

const mapStateToProps = (state) => {
  let areas = [];
  let devices = [];
  let categories = [];
  let facilityManagements = [];
  let constructionManagements = [];
  let primaryCharges = [];
  let others = [];
  let processMajorClasses = [];
  let checkpoints = [];
  let systems = [];
  let companies = [];
  let users = [];
  let groups = [];

  if (state.construction.masters) {
    areas = state.construction.masters.areas;
    devices = state.construction.masters.devices;
    categories = state.construction.masters.categories;
    facilityManagements = state.construction.masters.facility_managements;
    constructionManagements = state.construction.masters.construction_managements;
    primaryCharges = state.construction.masters.primary_charges;
    others = state.construction.masters.others;
    processMajorClasses = state.construction.masters.process_major_classes;
    checkpoints = state.construction.masters.checkpoints;
    systems = state.construction.masters.systems;
    companies = state.construction.masters.companies;
    users = state.construction.masters.users;
    groups = state.construction.masters.groups;
  }

  return {
    masters: {
      areas,
      devices,
      categories,
      facilityManagements,
      constructionManagements,
      primaryCharges,
      others,
      processMajorClasses,
      checkpoints,
      systems,
      companies,
      users,
      groups,
    },
    areaIds: state.qrcode.areaIds,
    deviceIds: state.qrcode.deviceIds,
    categoryId: state.qrcode.categoryId,
    facilityManagementIds: state.qrcode.facilityManagementIds,
    constructionManagementIds: state.qrcode.constructionManagementIds,
    primaryChargeIds: state.qrcode.primaryChargeIds,
    otherIds: state.qrcode.otherIds,
    companyId: state.qrcode.companyId,
    userId: state.qrcode.userId,
    groupId: state.qrcode.groupId,
    items: state.qrcode.items,
    fetching: state.qrcode.fetching,
    isError: state.qrcode.isError,
    validRequiredParameter: state.qrcode.validRequiredParameter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeArea: (items) => dispatch(actions.qrcode.changeArea(items)),
    changeDevice: (items) => dispatch(actions.qrcode.changeDevice(items)),
    changeCategory: (item) => dispatch(actions.qrcode.changeCategory(item)),
    changeFacilityManagement: (items) => dispatch(actions.qrcode.changeFacilityManagement(items)),
    changeConstructionManagement: (items) => dispatch(actions.qrcode.changeConstructionManagement(items)),
    changePrimaryCharge: (items) => dispatch(actions.qrcode.changePrimaryCharge(items)),
    changeOther: (items) => dispatch(actions.qrcode.changeOther(items)),
    changeCompany: (companyId) => dispatch(actions.qrcode.changeCompany(companyId)),
    changeUser: (userId) => dispatch(actions.qrcode.changeUser(userId)),
    changeGroup: (groupId) => dispatch(actions.qrcode.changeGroup(groupId)),
    search: (params, callback) => dispatch(actions.qrcode.search(params, callback)),
    clearSearch: () => dispatch(actions.qrcode.clearSearch()),
    revertLocalCondition: () => dispatch(actions.qrcode.revertLocalCondition()),
    downloadZip: (params, callback) => dispatch(actions.qrcode.downloadZip(params, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Qrcode);
