import actions from "../actions";
import { validateCondition } from "../lib/validate";

const validateMatrix = (store) => (next) => (action) => {
  next(action);
  const state = store.getState();
  if (!(state.app.menu === "dataimport" && state.construction.masters)) {
    return;
  }

  const { userId, categoryId } = state.dataimport;

  const { categories, users } = state.construction.masters;

  const { changeUser, changeCategory, setValidated } = actions.dataimport;

  if (userId && !validateCondition(users, userId).length) {
    next(changeUser(0));
  }

  if (categoryId && !validateCondition(categories, categoryId).length) {
    next(changeCategory(0));
  }

  next(setValidated(true));

  return;
};

export default validateMatrix;
