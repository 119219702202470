import actions from "../actions";
import * as constants from "../constants";

import { RootState } from "@/reducers/types";

const error = (store) => (next) => (action) => {
  if (action.type === constants.APP_SHOW_ERROR) {
    const { errors, statusCode } = action.payload;
    const callback = action.callback;
    const reload = action.reload;
    const onClick = (option?: { reload?: boolean }) => {
      if (callback !== undefined) {
        callback();
      }

      next(actions.app.hideAlert());

      const state: RootState = store.getState();
      const shouldLogout = state.session.shouldLogout;
      const system = state.session.system;
      const layoutMenu = state.layoutApp.layoutMenu;
      const menu = state.app.menu;

      if (shouldLogout) {
        next(actions.login.logout());
      } else if (option?.reload) {
        if (system === "layout" && layoutMenu === "machine") {
          const plotPlanId = state.plotmap.plotPlanId;
          const layoutDate = state.plotmap.layoutDate;
          store.dispatch(actions.plotmap.fetchMachines(Number(plotPlanId), layoutDate));
          store.dispatch(actions.plotmap.fetchKeepOutAreas(Number(plotPlanId), layoutDate));
          store.dispatch(actions.plotmap.fetchArrows(Number(plotPlanId), layoutDate));
          store.dispatch(actions.plotmap.fetchTexts(Number(plotPlanId), layoutDate));
        } else if (system !== "layout" && menu === "schedule") {
          const { requestForResultList, currentPageOfResultList, requestForScheduleList, currentPageOfScheduleList } =
            state.schedule;
          store.dispatch(actions.schedule.getScheduleResults(requestForResultList, currentPageOfResultList));
          store.dispatch(actions.schedule.getScheduleSchedules(requestForScheduleList, currentPageOfScheduleList));
        } else if (reload) {
          reload();
        }
      }
    };

    let messages;

    if (errors) {
      messages = errors.map((error, index) => `${index > 0 ? "\n" : ""}${error.err_code} | ${error.err_message}`);
    } else if (action.payload.message) {
      messages = [action.payload.message];
    } else {
      messages = ["An unexpected error occurred."];
    }

    // 実際のエラー表示はAPP_SHOW_ALERTアクションで行うが、
    // エラーハンドリング用にエラーアクションもディスパッチする
    const state = store.getState();
    next({
      ...action,
      errorFrom: state.app.menu,
    });

    return next(actions.app.showAlert("error", messages, onClick, statusCode));
  }

  return next(action);
};

export default error;
