type Props = {
  label: string;
  content: string | number;
  index: number;
};

const DetailRow: React.FC<Props> = ({ label, content, index }) => {
  return (
    <span className="layout-notification-detail-row">
      <span className="layout-notification-detail-row-label">{label}</span>
      <span>：</span>
      <span className="layout-notification-detail-row-content">
        <p key={"notification-detail-row-content-" + index}>{content}</p>
      </span>
    </span>
  );
};

export default DetailRow;
