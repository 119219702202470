import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import environments from "../../environments";
import * as util from "../../lib/common";
import Modal from "../Modal";

class SystemViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeButton: "",
      exportFileUrl1: "",
      exportFileUrl2: "",
      packSystemIds: [],
      toggleFlag: false,
      masterDetailItems: this.props.detailItems,
      items: this.props.detailItems,
      resultProgressRate: this.props.resultProgressRate,
      scheduleProgressRate: this.props.scheduleProgressRate,
    };

    this.handleResize = this.handleResize.bind(this);
    this.showDropdown = this.showDropdown.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);
    this.toggleCheckobx = this.toggleCheckobx.bind(this);
  }

  componentDidMount() {
    this.resizeTimer = 0;
    window.addEventListener("resize", this.handleResize);

    document.getElementById("table-body").style.maxHeight = window.innerHeight - 210 - 267 + "px";
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  showDropdown(name) {
    const endpoint = `${environments.API_BASE_URI}/system/files`;

    this.setState({
      ...this.state,
      activeButton: name,
      exportFileUrl1: `${endpoint}?filetype=3&format=pdf&system_ids=${this.props.systemId}`,
      exportFileUrl2: `${endpoint}?filetype=4&format=pdf&system_ids=${this.props.systemId}`,
    });
  }

  hideDropdown() {
    this.setState({ ...this.state, activeButton: "" });
  }

  toggleCheckobx() {
    let items = [];

    if (!this.state.toggleFlag) {
      const clientH = document.getElementById("viewer-list").clientHeight;
      document.getElementById("viewer-list").style.maxHeight = "initial";
      document.getElementById("viewer-list").style.height = clientH + "px";

      items = this.state.masterDetailItems.filter((item, index) => {
        return !item.totaltest_process;
      });
    } else {
      items = this.state.masterDetailItems;
    }

    this.setState({
      toggleFlag: !this.state.toggleFlag,
      items: items,
    });
  }

  handleResize() {
    if (this.resizeTimer > 0) {
      clearTimeout(this.resizeTimer);
    }

    const callback = () => {
      document.getElementById("viewer-list").style.height = "initial";
      document.getElementById("table-body").style.maxHeight = window.innerHeight - 210 - 267 + "px";
    };

    this.resizeTimer = setTimeout(callback, 200);
  }

  render() {
    const { activeButton, exportFileUrl1, exportFileUrl2, items, resultProgressRate, scheduleProgressRate } =
      this.state;
    const { t } = this.props;

    const panelTitle = t("system_detail_list") + "：[" + this.props.systemName + "]";
    const progressRate =
      t("progress") + ":" + resultProgressRate + "%　" + t("progress_schedule") + ":" + scheduleProgressRate + "%";
    const constructionId = util.getConstructionId();

    return (
      <Modal title={panelTitle} closeHandler={() => this.props.closeHandler()}>
        <div id="viewer-list" className="modal-body">
          <div className="inner">
            <div className="tbl-top-area clearfix">
              <div className="tbl-top-left">
                <div className="ckbox-area">
                  <label data-test-id="checkbox-system-view-airproof" className="ckbox">
                    <input type="checkbox" onClick={() => this.toggleCheckobx()} />
                    <span>{t("select_not_airproof")}</span>
                  </label>
                </div>
                {false && ( // PDFのファイル出力を一旦非表示にする
                  <div className="btn-dropdown-area" onMouseLeave={() => this.hideDropdown()}>
                    <button className="btn btn-light-blue btn-dropdown" onClick={() => this.showDropdown("export")}>
                      <span className="icon icon-get_app"></span>
                      {t("file_export")}
                    </button>
                    {activeButton === "export" && (
                      <ul className="dropdown-menu" onClick={() => this.hideDropdown()}>
                        <li>
                          <a href={exportFileUrl1}>
                            <img src="./img/icon_pdf.svg" alt="PDF" className="icon-file" />
                            {t("system_view")}
                          </a>
                        </li>
                        <li>
                          <a href={exportFileUrl2}>
                            <img src="./img/icon_pdf.svg" alt="PDF" className="icon-file" />
                            {t("progress_chart")}
                          </a>
                        </li>
                      </ul>
                    )}
                  </div>
                )}
              </div>
              <div className="tbl-top-right">{progressRate}</div>
            </div>
            <div className="tbl-area">
              <table className="tbl-basic tbl-data">
                <thead className="list-head tbl-head-adjusted">
                  <tr>
                    <th>
                      {t("airproof1")}
                      <br />
                      {t("airproof2")}
                    </th>
                    <th>{t("area")}</th>
                    <th>{t("device")}</th>
                    <th>{t("facility_management")}</th>
                    <th>{t("construction_management")}</th>
                    <th>{t("primary_charge")}</th>
                    <th>{t("other")}</th>
                    <th>{t("title")}</th>
                    <th>{t("law")}</th>
                    <th>{t("note")}</th>
                    <th>{t("assignee")}</th>
                    <th>{t("progress")}</th>
                    <th>{t("progress_schedule")}</th>
                    <th>{t("last_registry_date")}</th>
                    <th>{t("display")}</th>
                  </tr>
                </thead>
                <tbody id="table-body" className="list-body">
                  {items.map((item, index) => {
                    const tmpDate = new Date(item.timestamp.insert_date);
                    const insertDate =
                      tmpDate.getFullYear() +
                      "/" +
                      ("0" + (tmpDate.getMonth() + 1)).slice(-2) +
                      "/" +
                      ("0" + tmpDate.getDate()).slice(-2);

                    const path = `/#/${constructionId}/matrix?category_id=${item.category_id}&item_id=${
                      item.item_id
                    }&primary_charge_id=${item.primary_charge_id ?? ""}`;

                    return (
                      <tr key={index}>
                        <td className="txt-center">{item.totaltest_process ? "○" : ""}</td>
                        <td className="">{item.area_name}</td>
                        <td className="">{item.device_name}</td>
                        <td className="">{item.facility_management_name}</td>
                        <td className="">{item.construction_management_name}</td>
                        <td className="">{item.primary_charge_name}</td>
                        <td className="">{item.other_name}</td>
                        <td className="">{item.item_name}</td>
                        <td className="">{item.regulation}</td>
                        <td className="">{item.note}</td>
                        <td className="">{item.user_name}</td>
                        <td className={`txt-right${item.background_color == 3 ? " cell-orange" : ""}`}>
                          {item.result_progress_rate}%
                        </td>
                        <td className="txt-right">{item.schedule_progress_rate}%</td>
                        <td className="txt-center">{insertDate}</td>
                        <td className="txt-center">
                          <a href={path} target="_blank" rel="noreferrer">
                            {t("display")}
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            data-test-id="button-system-view-close"
            type="button"
            className="btn btn-gray"
            onClick={() => this.props.closeHandler()}
          >
            {t("close")}
          </button>
        </div>
      </Modal>
    );
  }
}

SystemViewer.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
};

export default withTranslation()(SystemViewer);
