import { connect } from "react-redux";

import actions from "../../actions";
import ProtectionBundleEditor from "../../components/matrix/ProtectionBundleEditor";

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSave: (data, callback, cancelHandler, failedCallback) =>
      dispatch(actions.matrix.bulkUpdateProtection(data, callback, cancelHandler, failedCallback)),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectionBundleEditor);
