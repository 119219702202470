import * as constants from "../constants/index";
import environments from "../environments";

export const changeName = (name) => ({
  type: constants.LOGIN_CHANGE_NAME,
  payload: name,
});

export const changePassword = (password) => ({
  type: constants.LOGIN_CHANGE_PASSWORD,
  payload: password,
});

export const submit = (login_id, password) => {
  const errorObj = {};

  if (login_id === "") {
    errorObj.name = "user_name_unentered";
  } else if (login_id.length > 100) {
    errorObj.name = "user_name_too_long";
  }

  if (password === "") {
    errorObj.password = "password_unentered";
  } else if (password.length > 100) {
    errorObj.password = "password_too_long";
  }

  if (Object.keys(errorObj).length > 0) {
    return {
      type: constants.LOGIN_VALIDATION_FAILED,
      payload: errorObj,
    };
  }

  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/auth`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({
      login_id,
      password,
    }),
    callbacks: {
      begin: () => ({
        type: constants.LOGIN_BEGIN_AUTH,
      }),
      success: (response) => ({
        type: constants.LOGIN_END_AUTH,
        payload: response,
      }),
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
        };
      },
    },
  };
};

export const verifyTwoFactorAuth = (security_code, ng_handler) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/security_code/check`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({ security_code }),
    callbacks: {
      begin: () => ({
        type: constants.LOGIN_BEGIN_TWO_FACTOR_AUTH,
      }),
      success: (response) => ({
        type: constants.LOGIN_END_TWO_FACTOR_AUTH,
        payload: response,
      }),
      error: (response) => {
        ng_handler();

        return {
          type: constants.LOGIN_FAILED_TWO_FACTOR_AUTH,
          payload: response,
        };
      },
    },
  };
};

export const nextAuth = (login_id, password) => {
  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/auth`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({
      login_id,
      password,
    }),
    callbacks: {
      begin: () => ({
        type: constants.LOGIN_BEGIN_NEXT_AUTH,
      }),
      success: (response) => ({
        type: constants.LOGIN_END_NEXT_AUTH,
        payload: response,
      }),
      error: (response) => ({
        type: constants.APP_SHOW_ERROR,
        payload: response,
      }),
    },
  };
};

export const hidePasswordWarning = () => ({
  type: constants.LOGIN_HIDE_PASSWORD_WARNING,
});

export const showPasswordEditor = () => ({
  type: constants.LOGIN_SHOW_PASSWORD_EDITOR,
});

export const hidePasswordEditor = () => ({
  type: constants.LOGIN_HIDE_PASSWORD_EDITOR,
});

export const showTwoFactorAuth = () => ({
  type: constants.LOGIN_SHOW_TWO_FACTOR_AUTH,
});

export const hideTwoFactorAuth = () => ({
  type: constants.LOGIN_HIDE_TWO_FACTOR_AUTH,
});

export const shouldLogout = () => ({
  type: constants.LOGIN_SHOULD_LOGOUT,
});

export const logout = () => {
  // ユーザー情報（トークン含む）をlocalStorageから削除
  localStorage.removeItem("authenticatedData");

  // 配置日をクリア
  localStorage.removeItem("layoutDate");

  return {
    type: constants.LOGOUT_SUCCESS,
  };
};
