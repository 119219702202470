import * as constants from "../constants/index";
import restoreState from "../lib/restoreState";
import storageManager from "../lib/storageManager";

const initialState = {
  categoryIds: [],
  fileName: "",
  insertStartDate: "",
  insertEndDate: "",
  items: [],
  uploadTarget: [],
  fetching: false,
  isError: false,
  validated: false,
  isCreate: false,
};

const getInitialSearchConditions = (onlyLocal = false) => {
  const urlState = restoreState();
  let categoryIds = [];
  let fileName = "";
  let insertStartDate = "";
  let insertEndDate = "";
  let uploadTarget = [];

  if (storageManager.getConstructionItem("assetSearchParams") !== null) {
    const params = JSON.parse(storageManager.getConstructionItem("assetSearchParams"));

    categoryIds = params.categoryIds;
    fileName = params.fileName;
    insertStartDate = params.insertStartDate;
    insertEndDate = params.insertEndDate;
    uploadTarget = params.uploadTarget;
  }

  if (!onlyLocal && urlState && urlState.menu === "asset") {
    categoryIds = urlState.category_id
      ? decodeURIComponent(urlState.category_id)
          .split(",")
          .map((f) => Number(f))
      : [];
    fileName = urlState.file_name ? decodeURIComponent(urlState.file_name) : "";
    insertStartDate = urlState.insert_start_date ? decodeURIComponent(urlState.insert_start_date) : "";
    insertEndDate = urlState.insert_end_date ? decodeURIComponent(urlState.insert_end_date) : "";
    uploadTarget = urlState.upload_target
      ? decodeURIComponent(urlState.upload_target)
          .split(",")
          .map((f) => Number(f))
      : [];
  }

  return {
    categoryIds,
    fileName,
    insertStartDate,
    insertEndDate,
    uploadTarget,
  };
};

const asset = (state = initialState, action) => {
  switch (action.type) {
    case constants.APP_CONTENTS_READY: {
      return {
        ...state,
        ...getInitialSearchConditions(),
      };
    }
    case constants.ASSET_SET_VALIDATED: {
      return {
        ...state,
        validated: Boolean(action.payload),
      };
    }
    case constants.CONSTRUCTION_END_FETCH_MASTERS: {
      const { categories } = action.payload;

      if (state.categoryId === 0 && categories.length > 0) {
        return {
          ...state,
          categoryId: categories[0].category_id,
        };
      }

      return state;
    }
    case constants.ASSET_CHANGE_CATEGORY: {
      return {
        ...state,
        categoryIds: action.payload,
      };
    }
    case constants.ASSET_CHANGE_FILENAME: {
      return {
        ...state,
        fileName: action.payload,
      };
    }
    case constants.ASSET_CHANGE_DATE_FROM: {
      return {
        ...state,
        insertStartDate: action.payload,
      };
    }
    case constants.ASSET_CHANGE_DATE_TO: {
      return {
        ...state,
        insertEndDate: action.payload,
      };
    }
    case constants.ASSET_CHANGE_UPLOAD_TARGET: {
      let uploadTarget = [...state.uploadTarget];

      if (_.includes(uploadTarget, action.payload)) {
        uploadTarget = uploadTarget.filter((f) => f !== action.payload);
      } else {
        uploadTarget = [...uploadTarget, action.payload];
      }

      return {
        ...state,
        uploadTarget,
      };
    }
    case constants.ASSET_BEGIN_SEARCH: {
      return {
        ...state,
        fetching: true,
        isError: false,
      };
    }
    case constants.ASSET_END_SEARCH: {
      return {
        ...state,
        items: action.payload.list,
        total: action.payload.total_num,
        fetching: false,
      };
    }
    case constants.ASSET_BEGIN_CREATE_ZIP_FILE: {
      return {
        ...state,
        isCreate: true,
      };
    }
    case constants.ASSET_END_CREATE_ZIP_FILE: {
      return {
        ...state,
        isCreate: false,
      };
    }
    case constants.ASSET_CLEAR_SEARCH: {
      return {
        ...initialState,
        categoryIds: action.payload.categoryIds,
        fileName: action.payload.fileName,
        insertStartDate: action.payload.insertStartDate,
        insertEndDate: action.payload.insertEndDate,
        uploadTarget: action.payload.uploadTarget,
      };
    }
    case constants.ASSET_END_DELETE_ITEM: {
      return {
        ...state,
        fetching: false,
      };
    }
    case constants.APP_SHOW_ERROR: {
      if (action.errorFrom === "asset") {
        return {
          ...state,
          fetching: false,
          isError: true,
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export default asset;
