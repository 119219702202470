import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

const AssetGridHeader = (props) => {
  const { t } = props;

  return (
    <table className="tbl-basic tbl-data">
      <thead>
        <tr>
          <th>{t("upload_destination")}</th>
          <th colSpan="3">{t("asset_header_title")}</th>
          <th>{t("registered_date")}</th>
          <th>{t("registered_person")}</th>
          <th>{t("file_name")}</th>
          <th>{t("delete")}</th>
        </tr>
      </thead>
    </table>
  );
};

AssetGridHeader.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AssetGridHeader);
