import moment, { Moment } from "moment";
import { useEffect, useMemo, useReducer } from "react";

import { Term } from "@/sx-layout/models";

const today = moment();

export const useTerm = (current?: string | Date | Moment) => {
  const initial = useMemo(() => moment(current), [current]);
  const [term, setTerm] = useReducer(
    (_: Term, next: Moment) => {
      const term: Term = {
        isPast: next.isBefore(today, "day"),
        isToday: next.isSame(today, "day"),
        isFuture: next.isAfter(today, "day"),
        today,
        current: next,
      };

      return term;
    },
    {
      isPast: initial.isBefore(today, "day"),
      isToday: initial.isSame(today, "day"),
      isFuture: initial.isAfter(today, "day"),
      today,
      current: initial,
    }
  );

  useEffect(() => {
    setTerm(moment(current));
  }, [current]);

  return {
    ...term,
  };
};
