import { Dispatch, MutableRefObject, SetStateAction, useCallback } from "react";
import { useDispatch } from "react-redux";

import actions from "@/actions";
import { KeepOutArea, Machine } from "@/sx-layout/components/plotmap/models";

type Props = {
  moveable: boolean;
  selectedMachine?: Machine;
  updateSelectedMachine: Dispatch<SetStateAction<Machine>>;
  withArea?: KeepOutArea;
  machineRef: MutableRefObject<HTMLDivElement>;
  imageRef: MutableRefObject<HTMLImageElement>;
  scale: number;
  mapRatio: number;
  setIsDragging: (_: boolean) => void;
};

export const useMoveableMachine = ({
  moveable,
  selectedMachine,
  updateSelectedMachine,
  withArea,
  machineRef,
  imageRef,
  scale,
  mapRatio,
  setIsDragging,
}: Props) => {
  const dispatch = useDispatch();

  const onDrag = useCallback(
    ({ beforeTranslate }: { beforeTranslate: number[] }) => {
      if (!moveable) return;

      const target = machineRef.current;
      const imageRect = imageRef.current.getBoundingClientRect();

      // 紐づくエリアがある場合、移動制限はエリアを基準に計算
      const targetElem = selectedMachine.keepout_area_id
        ? document.querySelector<HTMLElement>(`#area-${selectedMachine.keepout_area_id}`)
        : target;

      // マシーンは中心が基準位置になるため、アイコンの半分サイズ分引く
      const margin = selectedMachine.keepout_area_id ? 0 : 20;

      // X軸の制限
      let x = beforeTranslate[0];
      const targetLeft = parseInt(targetElem.style.left); // 移動中のオブジェクトのx座標
      const targetWidth = parseInt(targetElem.style.width); // 移動中のオブジェクトの幅
      const imageWidth = imageRect.width * scale; // 画像の幅
      const moveX = targetLeft + beforeTranslate[0] + margin;
      if (moveX < 0) {
        // 画像の左にはみ出さないようにする
        x = -targetLeft - margin;
      } else if (moveX + targetWidth - margin > imageWidth + margin) {
        // 画像の右側にはみ出さないようにする
        x = imageWidth - targetLeft - targetWidth + margin;
      }

      // Y軸の制限
      let y = beforeTranslate[1];
      const targetTop = parseInt(targetElem.style.top); // 移動中のオブジェクトのy座標
      const targetHeight = parseInt(targetElem.style.height); // 移動中のオブジェクトの高さ
      const imageHeight = imageRect.height * scale; // 画像の高さ
      const moveY = targetTop + beforeTranslate[1] + margin;
      if (moveY < 0) {
        // 画像の上にはみ出さないようにする
        y = -targetTop - margin;
      } else if (moveY + targetHeight - margin > imageHeight + margin) {
        // 画面の下にはみ出さないようにする
        y = imageHeight - targetTop - targetHeight + margin;
      }

      setIsDragging(true);
      target.style.transform = `translate(${x}px, ${y}px)`;

      if (selectedMachine.keepout_area_id) {
        const area = document.querySelector<HTMLElement>(`#area-${selectedMachine.keepout_area_id}`);
        if (area) {
          area.style.transform = `translate(${x}px, ${y}px)`;
        }
      }
    },
    [imageRef, machineRef, moveable, scale, selectedMachine?.keepout_area_id, setIsDragging]
  );

  const getTranslateXY = (element) => {
    const style = window.getComputedStyle(element);
    const matrix = new DOMMatrixReadOnly(style.transform);

    return {
      translateX: matrix.m41,
      translateY: matrix.m42,
    };
  };

  const onDragEnd = useCallback(
    ({ isDrag }) => {
      if (!moveable) return;

      const target = machineRef.current;
      // NOTE: チールチップメニューのクリック等でもonDragEndは発火するが、座標を移動してなければ更新不要
      if (isDrag) {
        setIsDragging(false);
        const { translateX, translateY } = getTranslateXY(target);
        const machineX = Math.round((parseInt(target.style.left) + translateX + 20) / mapRatio);
        const machineY = Math.round((parseInt(target.style.top) + translateY + 20) / mapRatio);

        // 紐付きかどうかでAPIを変える
        if (withArea) {
          const { keepout_area_id, timestamp } = withArea;
          const a = document.querySelector<HTMLElement>(`#area-${keepout_area_id}`);
          const keepOutAreaX = Math.round((parseInt(a.style.left) + translateX) / mapRatio);
          const keepOutAreaY = Math.round((parseInt(a.style.top) + translateY) / mapRatio);

          dispatch(
            actions.machineEdit.updateMachineKeepOutAreasCoordinates({
              input: {
                machine: {
                  machine_id: selectedMachine.machine_id?.toString(),
                  x: machineX,
                  y: machineY,
                  timestamp: {
                    update_date: selectedMachine.timestamp.update_date,
                  },
                },
                keepout_area: {
                  keepout_area_id: `${keepout_area_id}`,
                  x: keepOutAreaX,
                  y: keepOutAreaY,
                  timestamp,
                },
              },
              onSuccess: (response) => {
                target.style.transform = null;
                a.style.transform = null;

                updateSelectedMachine((prev) => ({
                  ...prev,
                  x: machineX,
                  y: machineY,
                  timestamp: {
                    ...selectedMachine.timestamp,
                    update_date: response.machine.timestamp.update_date,
                  },
                }));
              },
            })
          );
        } else {
          // 座標の更新
          dispatch(
            actions.machineEdit.updateMachineCoordinates({
              input: {
                machine_id: selectedMachine.machine_id?.toString(),
                x: machineX,
                y: machineY,
                timestamp: {
                  update_date: selectedMachine.timestamp.update_date,
                },
              },
              onSuccess: (response) => {
                target.style.transform = null;
                updateSelectedMachine((prev) => ({
                  ...prev,
                  x: machineX,
                  y: machineY,
                  timestamp: {
                    ...selectedMachine.timestamp,
                    update_date: response.timestamp.update_date,
                  },
                }));
              },
            })
          );
        }
      }
    },
    [
      dispatch,
      machineRef,
      mapRatio,
      moveable,
      selectedMachine?.machine_id,
      selectedMachine?.timestamp,
      setIsDragging,
      updateSelectedMachine,
      withArea,
    ]
  );

  return {
    onDrag,
    onDragEnd,
  };
};
