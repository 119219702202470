import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";

import styles from "../../constants/reactSelectStyle";
import Modal from "../Modal";
import { SubmitButton } from "@/components/common/SubmitButton";

class ScheduleEditorBundleChiba extends Component {
  constructor(props) {
    super(props);
    this.state = {
      target: this.props.target,
      value: -1,
      note: "",
      loading: false,
      error: {},
    };

    this.handleChangeTarget = this.handleChangeTarget.bind(this);
    this.handleChangeNote = this.handleChangeNote.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.props.setUnloadAlert();
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();
  }

  handleChangeTarget(e) {
    this.setState({
      target: this.props.target,
      value: parseInt(e.target.value),
    });
  }

  handleChangeNote(e) {
    this.setState({
      target: this.props.target,
      note: e.target.value,
    });
  }

  handleSave() {
    if (this.state.loading) return;
    const error = {};
    const { panelText, requestParam, target, t, scheduleIds } = this.props;
    const data = { ...this.state, scheduleIds };

    if (
      target != "note1" &&
      target != "note2" &&
      target != "note3" &&
      target != "special_process_text" &&
      target != "protective_equipment_text" &&
      target != "work_start_hour" &&
      target != "work_end_hour" &&
      this.state.value == -1
    ) {
      error.value = panelText;
      error.msg = "is_not_selected";
    }

    if (this.state.target == "note1" && this.state.note.length > 500) {
      error.value = "";
      error.msg = t("request") + "：" + t("mainte_is_too_long_500");
    }

    if (this.state.target == "note2" && this.state.note.length > 500) {
      error.value = "";
      error.msg = t("permission") + "：" + t("remarks_is_too_long_500");
    }

    if (this.state.target == "note3" && this.state.note.length > 500) {
      error.value = "";
      error.msg = "builder_is_too_long_500";
    }

    if (target == "special_process_text" && this.state.note.length > 200) {
      error.value = "";
      error.msg = "special_is_too_long_200";
    }

    if (target == "protective_equipment_text" && this.state.note.length > 200) {
      error.value = "";
      error.msg = "protection_is_too_long_200";
    }

    if (target == "work_start_hour" || target == "work_end_hour") {
      if (
        this.state.note !== null &&
        this.state.note.length > 0 &&
        this.state.note.match(/^([0-9]|[01][0-9]|2[0-3]):[0-5][0-9]$/u) == null
      ) {
        error.value = "";
        error.msg = "oirec_chiba_work_time_alert";
      }
    }

    data.error = error;

    if (Object.keys(error).length > 0) {
      this.setState(data);
    } else {
      data.loading = true;
      this.setState(data);
      this.props.updateItemBundle(
        data,
        () => {
          this.setState({ loading: false });
          this.props.searchHandler();
          this.props.closeHandler();
        },
        () => {
          this.setState({ loading: false });
        }
      );
    }
  }

  render() {
    const { target, panelText, selectValues, closeHandler, t } = this.props;
    const { error } = this.state;

    return (
      <Modal title={panelText + " " + t("update_bulk")} closeHandler={() => closeHandler()}>
        <div className="modal-body schedule-editor">
          <div className="form-row mb-5">
            <div className="form-group w-360 txt-center">
              {target == "note1" ||
              target == "note2" ||
              target == "note3" ||
              target == "special_process_text" ||
              target == "protective_equipment_text" ? (
                <textarea
                  data-test-id="text-note-editor"
                  rows="4"
                  className="form-control w-360"
                  value={this.state.note}
                  onChange={this.handleChangeNote}
                />
              ) : target == "work_start_hour" || target == "work_end_hour" ? (
                <input
                  data-test-id="text-schedule-item-name"
                  type="text"
                  className="form-control txt-center w-80"
                  value={this.state.note}
                  onChange={this.handleChangeNote}
                />
              ) : (
                <div className="form-center">
                  <select
                    data-test-id="text-import-file-type"
                    className="form-control w-170"
                    onChange={this.handleChangeTarget}
                  >
                    <option key={-1} value={-1}>
                      -----
                    </option>
                    {selectValues.map((value) => (
                      <option key={value.id} value={value.id}>
                        {value.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            {error.msg && (
              <div className="form-error w-360">
                <p className="form-error-message w-260">
                  {t(error.value)}
                  {t(error.msg)}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className={"modal-footer"}>
          <button
            data-test-id="button-column-editor-cancel"
            type="button"
            className="btn btn-gray"
            onClick={() => closeHandler()}
          >
            {t("cancel")}
          </button>
          <SubmitButton
            data-test-id="button-column-editor-save"
            onClick={this.handleSave}
            loading={this.state.loading}
          />
        </div>
      </Modal>
    );
  }
}

ScheduleEditorBundleChiba.propTypes = {
  target: PropTypes.string.isRequired,
  panelText: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
};

export default withTranslation()(ScheduleEditorBundleChiba);
