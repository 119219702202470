import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

type Props = {
  isSelected?: boolean;
  onClick: () => void;
};

const CompleteReportPill: React.FC<Props> = ({ isSelected, ...props }) => {
  const { t } = useTranslation();
  const notReadTaskComplete = useSelector(
    (state) => state.notifications.summary.progress_notification.not_read_num_by_type?.task_complete
  );

  return (
    <div
      id="complete-report-pill"
      className={`pill ${isSelected ? "is-pill-selected" : ""} ${notReadTaskComplete > 0 ? "badge" : ""}`}
      {...props}
    >
      {t("completion_report")}
    </div>
  );
};

export default CompleteReportPill;
