import { zodResolver } from "@hookform/resolvers/zod";
import React, { FC } from "react";
import Draggable from "react-draggable";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as z from "zod";

import { ScheduleExportSetting } from "../types";

type Props = {
  onClose: () => void;
  onExport: (setting?: ScheduleExportSetting) => void;
};

export const ScheduleExportSettingDialog: FC<Props> = ({ onClose, onExport }) => {
  const { t } = useTranslation();

  const isValidInt = (v?: string): boolean => {
    if (!v) return true;
    const num = Number(v);
    return Number.isSafeInteger(num) && 0 <= num;
  };

  const formSchema = z.object({
    unskilled_worker_num: z
      .string()
      .optional()
      .refine(isValidInt, t("invalid_number_format", { field: t("unskilled_worker_num") })),
    older_worker_num: z
      .string()
      .optional()
      .refine(isValidInt, t("invalid_number_format", { field: t("older_worker_num") })),
    younger_worker_num: z
      .string()
      .optional()
      .refine(isValidInt, t("invalid_number_format", { field: t("younger_worker_num") })),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ScheduleExportSetting>({
    resolver: zodResolver(formSchema),
    reValidateMode: "onSubmit",
    defaultValues: {
      unskilled_worker_num: "0",
      older_worker_num: "0",
      younger_worker_num: "0",
    },
  });

  return (
    <div className="modal">
      <Draggable handle=".modal-header">
        <div className="modal-drag-container">
          <div className="modal-dialog">
            <div className={"modal-content"}>
              <div className="modal-header">
                <h3 className="modal-ttl">{t("schedule_list") + t("export") + t("settings")}</h3>
                <div className="btn-close" onClick={onClose}>
                  <span className="icon icon-close"></span>
                </div>
              </div>
              <div className="modal-body w-420">
                <div className="form-row">
                  <div className="form-group w-300">
                    <span className="form-label">{t("unskilled_worker_num")}</span>
                    <input
                      type="text"
                      className="form-control w-120"
                      autoFocus={true}
                      {...register("unskilled_worker_num")}
                    />
                    <span className="ml-2 inline-block w-[18px]">{t("unit_number_of_people")}</span>
                  </div>
                  {errors.unskilled_worker_num && (
                    <div className="form-error">
                      <p className="form-error-message w-300">{t(errors.unskilled_worker_num.message)}</p>
                    </div>
                  )}
                </div>
                <div className="form-row">
                  <div className="form-group w-300">
                    <span className="form-label">{t("older_worker_num")}</span>
                    <input type="text" className="form-control w-120" {...register("older_worker_num")} />
                    <span className="ml-2 inline-block w-[18px]">{t("unit_number_of_people")}</span>
                  </div>
                  {errors.older_worker_num && (
                    <div className="form-error">
                      <p className="form-error-message w-300">{t(errors.older_worker_num.message)}</p>
                    </div>
                  )}
                </div>
                <div className="form-row">
                  <div className="form-group w-300">
                    <span className="form-label">{t("younger_worker_num")}</span>
                    <input type="text" className="form-control w-120" {...register("younger_worker_num")} />
                    <span className="ml-2 inline-block w-[18px]">{t("unit_number_of_people")}</span>
                  </div>
                  {errors.younger_worker_num && (
                    <div className="form-error">
                      <p className="form-error-message w-300">{t(errors.younger_worker_num.message)}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className={"modal-footer"}>
                <button data-test-id="button-download-cancel" type="button" className="btn btn-gray" onClick={onClose}>
                  {t("cancel")}
                </button>
                <button
                  data-test-id="button-download"
                  type="button"
                  className="btn btn-blue"
                  onClick={handleSubmit(onExport)}
                >
                  {t("export")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    </div>
  );
};
