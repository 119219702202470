import { ComponentProps, FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import actions from "@/actions";
import { RootState } from "@/reducers/types";
import { DeleteConfirmModal, Modal } from "@/sx-layout/common/Modal";
import { DeleteMachineProps } from "@/sx-layout/components/plotmap/actions/types";
import { Machine } from "@/sx-layout/components/plotmap/models";
import { MachineType } from "@/sx-layout/components/settings/machineSetting/models";

type Props = ComponentProps<typeof Modal> & {
  machine: Machine;
  onSuccess?: () => void;
};

export const DeleteMachineModal: FC<Props> = ({ machine, onClose, onSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const machineTypes = useSelector<RootState, MachineType[]>((state) => state.machine.machineTypes);
  const typeName = machineTypes.find((m) => m.machine_type_id === machine?.machine_type_id)?.machine_type_name ?? "";

  return (
    <DeleteConfirmModal
      message={`${typeName}${t("delete_confirmation")}`}
      onClose={onClose}
      onSubmit={() => {
        dispatch(
          actions.machineEdit.deleteMachine({
            input: {
              machine_id: machine.machine_id?.toString(),
              keepout_area_id: machine.keepout_area_id?.toString(),
              timestamp: {
                update_date: machine.timestamp?.update_date,
              },
            },
            onSuccess: () => {
              onClose();
              onSuccess?.();
            },
          } as DeleteMachineProps)
        );
      }}
    />
  );
};
