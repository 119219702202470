import PropTypes from "prop-types";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import ReactTooltip from "react-tooltip";

import * as actions from "../../actions/index";
import styles from "../../constants/reactSelectStyle";
import ScheduleEditorBundleChitaContainer from "../../containers/list/ScheduleEditorBundleChitaContainer";
import ScheduleEditorChitaContainer from "../../containers/list/ScheduleEditorChitaContainer";
import ScheduleRightRowChitaContainer from "../../containers/list/ScheduleRightRowChitaContainer";
import ScheduleUpdateChitaContainer from "../../containers/list/ScheduleUpdateChitaContainer";
import TooltipContainer from "../../containers/list/TooltipContainer";
import { addHeightResizeListener, removeHeightResizeListener, getTableBodyHeight } from "../../lib/common";
import MasterSelect from "../common/Select";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import * as util from "../../lib/common";
import storageManager from "../../lib/storageManager";
import restoreState from "../../lib/restoreState";
import { isValid, isValidTargets } from "../../lib/roleChecker";

const autoload = false; // IEの二重リクエスト対策フラグ
class ScheduleChita extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch: true,
      showTable: false,
      currentRowIndex: -1,
      limit: 999999,
      start: 1,
      end: false,
      isEmpty: false,
      tableBodyMaxHeight: window.innerHeight - 350,
      offset: 0,
      maxLength: 60,
      isWide: false,
      scheduleId: 0,
      downloadUrl: "",
      downloadName: "",
      downloading: false,

      panelText: "",
      requestParam: [],
      showUpdateBundle: false,
      showEditor: false,
      showUpdatePanel: false,

      timeStamp: "",
      target: "",
      processText: "",
      scheduleId: 0,
      scheduleUpdateDate: "",
      switchTime: "",
      selectValues: [],

      field1ListIds: [],
      field2ListIds: [],
      field3ListIds: [],
      field4ListIds: [],

      options: [],
      tooltip: null,
    };

    this.judgeScheduleDate = this.judgeScheduleDate.bind(this);
    this.judgeScheduleDateUpdate = this.judgeScheduleDateUpdate.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleSearchBundle = this.handleSearchBundle.bind(this);
    this.handleSearchBoxHeightChange = this.handleSearchBoxHeightChange.bind(this);
    this.isScrollable = this.isScrollable.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.fillList = this.fillList.bind(this);
    this.download = this.download.bind(this);
    this.checkScheduleDate = this.checkScheduleDate.bind(this);
    this.checkOpenEditor = this.checkOpenEditor.bind(this);
    this.checkOpenEditorNolimit = this.checkOpenEditorNolimit.bind(this);
    this.checkOpenEditorBulk = this.checkOpenEditorBulk.bind(this);
    this.checkOpenEditorBulkNoLimit = this.checkOpenEditorBulkNoLimit.bind(this);
    this.showUpdatePanel = this.showUpdatePanel.bind(this);
    this.setTooltip = this.setTooltip.bind(this);
    this.handleOnMouse = this.handleOnMouse.bind(this);
  }

  componentDidMount() {
    this.resizeTimer = 0;
    window.addEventListener("resize", this.handleResize);

    const urlState = restoreState();
    const { getOptions, validated } = this.props;
    let isSearch = false;
    if (
      validated &&
      (storageManager.getConstructionItem("scheduleChitaSearchParams") !== null || (urlState && urlState.hasQuery))
    ) {
      isSearch = true;
    }

    this.handleSearchBoxHeightChange();
    addHeightResizeListener(this.searchBox, this.handleSearchBoxHeightChange);

    getOptions((data) => {
      this.setState(
        {
          options: data,
          switchTime: data.extension_config.schedule.schedule_date_switch_time,
          editEndTime: data.extension_config.schedule.edit_end_time,
          field1ListIds: data.search_condition_options
            .filter((option) => {
              return option.target == "field1_id";
            })
            .shift()
            .options.map((user) => {
              return { id: parseInt(user.id), name: user.name };
            }),
          field2ListIds: data.search_condition_options
            .filter((option) => {
              return option.target == "field2_id";
            })
            .shift()
            .options.map((user) => {
              return { id: parseInt(user.id), name: user.name };
            }),
          field3ListIds: data.search_condition_options
            .filter((option) => {
              return option.target == "field3_id";
            })
            .shift()
            .options.map((user) => {
              return { id: parseInt(user.id), name: user.name };
            }),
          field4ListIds: data.search_condition_options
            .filter((option) => {
              return option.target == "field4_id";
            })
            .shift()
            .options.map((user) => {
              return { id: parseInt(user.id), name: user.name };
            }),
        },
        () => {
          if (isSearch) {
            this.props.changeScheduleDate(this.judgeScheduleDate());
            this.handleSearch(this.judgeScheduleDate());
          }
        }
      );
    });
  }

  componentDidUpdate(prevProps) {
    const { validated } = this.props;
    if (!validated) {
      return;
    }
    const urlState = restoreState();
    const notFirst =
      storageManager.getConstructionItem("scheduleChitaSearchParams") !== null || (urlState && urlState.hasQuery);
    if (!prevProps.validated && validated && notFirst) {
      this.handleSearch(false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    removeHeightResizeListener(this.searchBox);

    this.props.revertLocalCondition();
  }

  //予定日の補正
  judgeScheduleDate() {
    if (this.state.switchTime.trim().length < 1) {
      return moment().format("YYYY/MM/DD");
    }

    const switchTime = moment(moment().format("YYYY-MM-DD") + " " + ("0" + this.state.switchTime).slice(-5) + ":00");
    const scheduleDate =
      moment() > switchTime ? moment().add(1, "days").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");

    return scheduleDate;
  }

  judgeScheduleDateUpdate() {
    const scheduleDateList1 = [
      { id: 0, value: moment().add(1, "days").format("YYYY-MM-DD") },
      { id: 1, value: moment().add(2, "days").format("YYYY-MM-DD") },
      { id: 2, value: moment().add(3, "days").format("YYYY-MM-DD") },
    ];

    const scheduleDateList2 = [
      { id: 0, value: moment().add(2, "days").format("YYYY-MM-DD") },
      { id: 1, value: moment().add(3, "days").format("YYYY-MM-DD") },
    ];

    const editEndTime = moment(moment().format("YYYY-MM-DD") + " " + ("0" + this.state.editEndTime).slice(-5) + ":00");

    if (moment() > editEndTime) {
      return scheduleDateList2;
    } else {
      return scheduleDateList1;
    }
  }

  handleSearchBoxHeightChange() {
    const maxHeight = getTableBodyHeight("schedule", this.searchBox, this.theader);
    this.setState({ tableBodyMaxHeight: "calc(100vh - " + maxHeight + "px)" });
  }

  handleResize() {
    if (this.resizeTimer > 0) {
      clearTimeout(this.resizeTimer);
    }

    const callback = () => {
      this.handleSearchBoxHeightChange();
      this.fillList();
    };

    this.resizeTimer = setTimeout(callback, 200);
  }

  toggleSearch() {
    const callback = () => {
      this.handleSearchBoxHeightChange();
      addHeightResizeListener(this.searchBox, this.handleSearchBoxHeightChange);
      this.fillList();
    };
    this.setState({ showSearch: !this.state.showSearch }, () => setTimeout(callback, 100));
  }

  showTable(callback) {
    this.setState({ showTable: true }, () => {
      if (callback !== undefined) {
        // コールバックにしてもsetState直後はDOMに反映していない
        // ことがあるため、時間差で実行する
        setTimeout(callback, 100);
      }
    });
  }

  isScrollable() {
    const container = this.tbody;

    if (!container) {
      return false;
    }

    const diff = container.clientHeight - container.scrollHeight;

    return diff !== 0;
  }

  fillList() {
    const { showTable, end } = this.state;

    if (!showTable || end) {
      return;
    }
  }

  handleSearch(sche_date) {
    this.setState({ isEmpty: true });

    const {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      field1Ids,
      field2Ids,
      field3Ids,
      field4Ids,
      scheduleDate,
      itemText,
      processText,
      search,
    } = this.props;

    const params = {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      field1Ids,
      field2Ids,
      field3Ids,
      field4Ids,
      scheduleDate: sche_date || scheduleDate,
      itemText,
      processText,
      start: 1,
      limit: this.state.limit,
    };

    search(params, (data) => {
      this.setState(
        {
          start: data.list.length + 1,
          end: data.list.length < this.state.limit,
          isEmpty: data.list.length === 0,
          requestParam: {
            category_id: categoryId,
            area_id: areaIds,
            device_id: deviceIds,
            facility_management_id: facilityManagementIds,
            construction_management_id: constructionManagementIds,
            primary_charge_id: primaryChargeIds,
            field1_id: field1Ids,
            field2_id: field2Ids,
            field3_id: field3Ids,
            field4_id: field4Ids,
            item_text: itemText,
            process_text: processText,
            schedule_date: scheduleDate,
          },
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  handleClear() {
    const categoryId = this.props.masters.categories[0].category_id;
    const scheduleDate = this.judgeScheduleDate();

    this.props.clearSearch(categoryId);
    this.setState({ start: 1, showTable: false });

    const { changeScheduleDate, search } = this.props;

    const params = {
      areaIds: [],
      deviceIds: [],
      categoryId: categoryId,
      facilityManagementIds: [],
      constructionManagementIds: [],
      primaryChargeIds: [],
      field1Ids: [],
      field2Ids: [],
      field3Ids: [],
      field4Ids: [],
      scheduleDate: scheduleDate,
      itemText: "",
      processText: "",
      start: 1,
      limit: this.state.limit,
    };

    changeScheduleDate(this.judgeScheduleDate());

    search(params, (data) => {
      this.setState(
        {
          start: data.list.length + 1,
          end: data.list.length < this.state.limit,
          isEmpty: data.list.length === 0,
          requestParam: {
            category_id: categoryId,
            area_id: [],
            device_id: [],
            facility_management_id: [],
            construction_management_id: [],
            primary_charge_id: [],
            field1_id: [],
            field2_id: [],
            field3_id: [],
            field4_id: [],
            item_text: "",
            process_text: "",
            schedule_date: scheduleDate,
          },
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  handleSearchBundle() {
    this.setState({ isEmpty: true });
    const { clearSearch, changeScheduleDate, search } = this.props;
    const categoryId = this.props.masters.categories[0].category_id;

    const params = {
      areaIds: this.state.requestParam.area_id,
      deviceIds: this.state.requestParam.device_id,
      categoryId: this.state.requestParam.category_id,
      facilityManagementIds: this.state.requestParam.facility_management_id,
      constructionManagementIds: this.state.requestParam.construction_management_id,
      primaryChargeIds: this.state.requestParam.primary_charge_id,
      field1Ids: this.state.requestParam.field1_id,
      field2Ids: this.state.requestParam.field2_id,
      field3Ids: this.state.requestParam.field3_id,
      field4Ids: this.state.requestParam.field4_id,
      scheduleDate: this.state.requestParam.schedule_date,
      itemText: this.state.requestParam.item_text,
      processText: this.state.requestParam.process_text,
      start: 1,
      limit: this.state.limit,
    };

    clearSearch(categoryId);
    changeScheduleDate(this.state.requestParam.schedule_date);

    search(params, (data) => {
      this.setState(
        {
          isEmpty: data.list.length === 0,
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  syncScroll(e) {
    this.rightHeaderContainer.scrollLeft = e.target.scrollLeft;
    this.leftBodyContainer.scrollTop = e.target.scrollTop;

    const container = this.rightBodyContainer;
    const containerHeight = container.clientHeight;
    const contentHeight = container.scrollHeight;
    const scrollTop = container.scrollTop;

    const { offset, maxLength, schedules } = this.state;
    const { items } = this.props;
    const rowHeight = 80;
    const hiddenBottomHeight =
      items.length - (offset + maxLength) < 0 ? 0 : (schedules.length - (offset + maxLength)) * rowHeight;

    const diff = contentHeight - (containerHeight + scrollTop + hiddenBottomHeight);

    const top = scrollTop < containerHeight + offset * rowHeight;
    const bottom = diff < containerHeight * 0.6;

    if (top) {
      this.decreaseOffset();
    }

    if (bottom && items.length > offset + maxLength) {
      this.increaseOffset();
    } else if (diff <= 10 && !this.state.end) {
      this.handleSearchInfinite();
    }
  }

  increaseOffset() {
    const { offset, maxLength, limit } = this.state;
    if (this.props.rows.length >= maxLength * 0.5) {
      const nextOffset = offset + limit;
      this.setState({ offset: nextOffset });
    }
  }

  decreaseOffset() {
    const { offset, limit } = this.state;
    if (offset !== 0) {
      const nextOffset = offset < limit * 2 ? 0 : offset - limit;
      this.setState({ offset: nextOffset });
    }
  }

  syncWheel(e) {
    this.rightBodyContainer.scrollTop += e.deltaY;
    this.leftBodyContainer.scrollTop += e.deltaY;
  }

  toggleWide() {
    this.setState({ isWide: !this.state.isWide });
  }

  showDropdown(name) {
    this.setState({ activeButton: name });
  }

  hideDropdown() {
    this.setState({ activeButton: "" });
  }

  showScheduleEditor(target, column, processText, scheduleId, optionId, timestamp) {
    this.setState({
      showEditor: true,
      target: target,
      processText: processText,
      scheduleId: scheduleId,
      optionId: optionId || 0,
      panelText: column,
      timeStamp: timestamp,
      selectValues: this.state.options.bulk_update_options
        .filter((option) => {
          return option.target == target;
        })
        .shift().options,
    });
  }

  showScheduleEditorBundle(bulkTarget, column) {
    this.setState({
      showUpdateBundle: true,
      target: bulkTarget,
      panelText: column,
      selectValues: this.state.options.bulk_update_options
        .filter((option) => {
          return option.target == bulkTarget;
        })
        .shift().options,
    });
  }

  showUpdatePanel() {
    this.setState({
      showUpdatePanel: true,
    });
  }

  hideScheduleEditor() {
    this.setState({
      showUpdateBundle: false,
      showEditor: false,
      showUpdatePanel: false,
    });
  }

  download() {
    this.setState({ downloading: true });

    const {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      field1Ids,
      field2Ids,
      field3Ids,
      field4Ids,
      itemText,
      processText,
      scheduleDate,
    } = this.props;

    const params = {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      field1Ids,
      field2Ids,
      field3Ids,
      field4Ids,
      scheduleDate,
      itemText,
      processText,
    };

    const fallback = () => this.setState({ downloading: false });

    const constId = util.getConstructionId();
    const userId = util.getUserId();

    this.props.download(
      params,
      (blob, fileName) => {
        const fname = !fileName
          ? "schedule_" + userId + "_" + constId + "_" + moment().format("YYYY-MM-DD_HHmmssSSS") + ".xlsx"
          : fileName;

        this.setState({ downloadUrl: URL.createObjectURL(blob), downloadName: fname }, () => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fname);
          } else {
            const evt = util.createClickEvent();
            this.btnDownload.dispatchEvent(evt);
          }
          this.setState({ downloading: false });
        });
      },
      fallback
    );
  }

  checkScheduleDate(config, scheduleDate) {
    const { edit_end_days, edit_end_time } = config;

    if (edit_end_days == "") {
      return true;
    }

    const schedule =
      moment(scheduleDate, "YYYY-MM-DD").add(parseInt(edit_end_days), "days").format("YYYY-MM-DD") +
      " " +
      ("0" + edit_end_time).slice(-5) +
      ":00";

    return moment().isBefore(schedule, "minute");
  }

  checkOpenEditor(roles, scheduleDate, target) {
    const config = this.state.options.extension_config.field_options
      .filter((option) => {
        return option.target == target;
      })
      .shift();

    return isValid(roles, target, "update") && this.checkScheduleDate(config, scheduleDate);
  }

  checkOpenEditorNolimit(roles, target) {
    return isValid(roles, target, "update");
  }

  checkOpenEditorBulk(roles, target) {
    const config = this.state.options.extension_config.field_options
      .filter((option) => {
        return option.target == target;
      })
      .shift();

    return (
      this.props.firstScheduleDate &&
      isValid(roles, "bulk_" + target, "update") &&
      this.checkScheduleDate(config, this.props.firstScheduleDate)
    );
  }

  checkOpenEditorBulkNoLimit(roles, target) {
    return this.props.firstScheduleDate && isValid(roles, "bulk_" + target, "update");
  }

  omitText(text, validLength) {
    if (text && text.length > validLength) {
      return `${text.substr(0, validLength)}...`;
    }

    return text;
  }

  isOmit(text, validLength) {
    return text && text.length > validLength ? true : false;
  }

  setTooltip(data) {
    this.setState({ tooltip: data });
  }

  handleOnMouse(columnValue, validLength) {
    if (this.isOmit(columnValue, validLength)) {
      this.setTooltip({
        value: columnValue,
      });
      ReactTooltip.rebuild();
    } else {
      this.setTooltip(null);
    }
  }

  render() {
    const titleClassName = `toggle icon-keyboard_arrow_up ${!this.state.showSearch ? "closed" : ""}`;
    const { field1ListIds, field2ListIds, field3ListIds, field4ListIds } = this.state;
    const {
      masters,
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      field1Ids,
      field2Ids,
      field3Ids,
      field4Ids,
      scheduleDate,
      itemText,
      processText,
      changeArea,
      changeDevice,
      changeFacilityManagement,
      changeCategory,
      changeConstructionManagement,
      changePrimaryCharge,
      changeField1,
      changeField2,
      changeField3,
      changeField4,
      changeScheduleDate,
      changeItemText,
      changeProcessText,
      t,
      items,
      bulkRoles,
      scheduleUpdateDate,
      fetching,
      isError,
      roles,
    } = this.props;

    return (
      <div>
        <div className="contents">
          <div className="inner schedule">
            <div className="tbl-top-area mt-15 clearfix">
              <div className="tbl-top">
                <h1 className="page-ttl">
                  {t("schedule_list")}
                  <span
                    data-test-id="button-schedule-toggle-search"
                    className={titleClassName}
                    onClick={() => this.toggleSearch()}
                  ></span>
                </h1>
                {isValid(roles, "schedule_chita", "create") && (
                  <button
                    data-test-id="button-schedule-search"
                    className="btn btn-blue w-170 schedule-reload"
                    onClick={this.showUpdatePanel}
                  >
                    <i className="icon icon-reload"></i>
                    {t("update_schedule")}
                  </button>
                )}
              </div>
            </div>
            {this.state.showSearch && (
              <div className="search-box" ref={(node) => (this.searchBox = node)}>
                <div className="form-row">
                  <div className="form-group w-270">
                    <span className="form-label">{t("area")}</span>
                    <div className="w-170 d-ib ta-l">
                      <MasterSelect
                        prefix="area"
                        isMulti={true}
                        options={masters.areas}
                        value={areaIds}
                        onChange={changeArea}
                      />
                    </div>
                  </div>
                  <div className="form-group w-280">
                    <span className="form-label">{t("device")}</span>
                    <div className="w-170 d-ib ta-l">
                      <MasterSelect
                        prefix="device"
                        isMulti={true}
                        options={masters.devices}
                        value={deviceIds}
                        onChange={changeDevice}
                      />
                    </div>
                  </div>
                  <div className="form-group w-290">
                    <span className="form-label">{t("facility_management")}</span>
                    <div className="w-170 d-ib ta-l">
                      <MasterSelect
                        prefix="facility_management"
                        isMulti={true}
                        options={masters.facilityManagements}
                        value={facilityManagementIds}
                        onChange={changeFacilityManagement}
                      />
                    </div>
                  </div>
                  <div className="form-group w-290">
                    <span className="form-label">{t("machines_category")}</span>
                    <div className="w-170 d-ib ta-l">
                      <MasterSelect
                        prefix="category"
                        isMulti={false}
                        options={masters.categories}
                        value={categoryId}
                        onChange={changeCategory}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group w-270">
                    <span className="form-label">{t("construction_management")}</span>
                    <div className="w-170 d-ib ta-l">
                      <MasterSelect
                        prefix="construction_management"
                        isMulti={true}
                        options={masters.constructionManagements}
                        value={constructionManagementIds}
                        onChange={changeConstructionManagement}
                      />
                    </div>
                  </div>
                  <div className="form-group w-280">
                    <span className="form-label">{t("primary_charge")}</span>
                    <div className="w-170 d-ib ta-l">
                      <MasterSelect
                        prefix="primary_charge"
                        isMulti={true}
                        options={masters.primaryCharges}
                        value={primaryChargeIds}
                        onChange={changePrimaryCharge}
                      />
                    </div>
                  </div>
                  <div className="form-group w-290">
                    <span className="form-label">{t("construction_item_name")}</span>
                    <input
                      data-test-id="text-schedule-item-name"
                      type="text"
                      className="form-control w-170"
                      value={itemText}
                      onChange={(e) => changeItemText(e.target.value)}
                    />
                  </div>
                  <div className="form-group w-290">
                    <span className="form-label">{t("content")}</span>
                    <input
                      data-test-id="text-schedule-process-name"
                      type="text"
                      className="form-control w-170"
                      value={processText}
                      onChange={(e) => changeProcessText(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group w-270">
                    <span className="form-label">{t("gas_testing_hot_work")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        styles={styles}
                        isMulti={true}
                        options={field1ListIds.map((user) => ({ value: user.id, label: user.name }))}
                        onChange={(e) => changeField1(e)}
                        value={field1ListIds
                          .filter((user) => _.includes(field1Ids, user.id))
                          .map((user) => ({ value: user.id, label: user.name }))}
                      />
                    </div>
                  </div>
                  <div className="form-group w-280">
                    <span className="form-label">{t("gas_testing_confined_space")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        styles={styles}
                        isMulti={true}
                        options={field2ListIds.map((user) => ({ value: user.id, label: user.name }))}
                        onChange={(e) => changeField2(e)}
                        value={field2ListIds
                          .filter((user) => _.includes(field2Ids, user.id))
                          .map((user) => ({ value: user.id, label: user.name }))}
                      />
                    </div>
                  </div>
                  <div className="form-group w-290 scheduled-date">
                    <span className="form-label">{t("scheduled_date")}</span>
                    <DatePicker
                      wrapperClassName="w-170"
                      selected={scheduleDate === "" ? null : moment(scheduleDate, "YYYY/MM/DD").toDate()}
                      dateFormat="yyyy/MM/dd"
                      locale={t("calender_locale")}
                      onChange={(date) =>
                        changeScheduleDate(date === null ? "" : moment(date).format("YYYY-MM-DD").toString())
                      }
                    />
                  </div>
                </div>
                <div className="form-row mb-0">
                  <div className="form-group w-270">
                    <span className="form-label">{t("permit_work")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        styles={styles}
                        isMulti={true}
                        options={field3ListIds.map((user) => ({ value: user.id, label: user.name }))}
                        onChange={(e) => changeField3(e)}
                        value={field3ListIds
                          .filter((user) => _.includes(field3Ids, user.id))
                          .map((user) => ({ value: user.id, label: user.name }))}
                      />
                    </div>
                  </div>
                  <div className="form-group w-280">
                    <span className="form-label">{t("approval")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        styles={styles}
                        isMulti={true}
                        options={field4ListIds.map((user) => ({ value: user.id, label: user.name }))}
                        onChange={(e) => changeField4(e)}
                        value={field4ListIds
                          .filter((user) => _.includes(field4Ids, user.id))
                          .map((user) => ({ value: user.id, label: user.name }))}
                      />
                    </div>
                  </div>

                  <div className="form-group btn-area pull-right mt-0">
                    <button data-test-id="button-schedule-reset" className="btn btn-gray" onClick={this.handleClear}>
                      {t("reset")}
                    </button>
                    <button
                      data-test-id="button-schedule-search"
                      className="btn btn-blue"
                      onClick={() => this.handleSearch(false)}
                    >
                      {t("search")}
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className={`tbl-schedules chita ${fetching ? "loading loading--list" : ""}`}>
              {this.state.isEmpty
                ? this.state.showTable && !isError && !fetching && <p className="empty-message">{t("no_data")}</p>
                : this.state.showTable &&
                  !isError && (
                    <React.Fragment>
                      <div className="tbl-top-area chita clearfix relative">
                        <div className="tbl-top-left">
                          <div className="btn-dropdown-area" onMouseLeave={() => this.hideDropdown()}>
                            <button
                              data-test-id="button-summary-file-export"
                              className="btn btn-light-blue btn-dropdown"
                              onClick={() => this.showDropdown("list")}
                            >
                              <span className="icon icon-get_app" />
                              {t("file_export")}
                            </button>
                            {this.state.activeButton === "list" && (
                              <ul
                                className="dropdown-menu"
                                style={{ dispkay: "none" }}
                                onClick={() => this.hideDropdown()}
                              >
                                <li data-test-id="button-summary-list" onClick={this.download}>
                                  <img src="./img/icon_xls.svg" alt="XLS" className="icon-file" />
                                  {t("table")}
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                        <span className="update-date">
                          {t("updated_date")}
                          {moment(scheduleUpdateDate).format("YYYY/MM/DD HH:mm")}
                        </span>
                        <div className={`${this.state.downloading ? "loading-small loading-small-download" : ""}`} />
                      </div>
                      <div
                        className="tbl-area tbl-schedules-header tbl-head-adjusted"
                        ref={(node) => (this.theader = node)}
                      >
                        <div
                          className={`grid-container grid-container-schedule chita grid-container-progress ${
                            this.state.isWide ? "is-wide-table" : ""
                          }`}
                          style={{ height: this.state.tableBodyMaxHeight }}
                        >
                          <div className="grid-left-header">
                            <table className="tbl-basic tbl-data list-table-row">
                              <thead className="schedule">
                                <tr>
                                  <th className="col2 pos-rel">
                                    {t("device_name")}
                                    <br />
                                    {t("include_area")}
                                    <button
                                      data-test-id="button-matrix-collapse"
                                      className="grid-btn-collapse"
                                      onClick={() => this.toggleWide()}
                                    >
                                      {this.state.isWide ? "▶︎" : "◀︎"}
                                    </button>
                                  </th>
                                  <th className="col3">{t("schedule_category_name")}</th>
                                  <th className="col4">{t("construction_charge_tm")}</th>
                                  <th className="col5">{t("company")}</th>
                                  <th className="col7">{t("name_contact_person")}</th>
                                  <th className="col8">{t("construction_item_name")}</th>
                                  <th className="col9">{t("note")}</th>
                                  <th className="col10">{t("content")}</th>

                                  {this.checkOpenEditorBulk(bulkRoles, "checkpoint1") ? (
                                    <th
                                      className="col_check header-bottom grid-cell-clickable"
                                      onClick={() => this.showScheduleEditorBundle("checkpoint1_type", t("hot_work"))}
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="title">{t("hot_work")}</span>
                                            <button
                                              className={`grid-icon grid-icon-edit icon-edit icon-mode_edit`}
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className="col_check">
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="default">{t("hot_work")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  {this.checkOpenEditorBulk(bulkRoles, "checkpoint2") ? (
                                    <th
                                      className="col_check header-bottom grid-cell-clickable"
                                      onClick={() =>
                                        this.showScheduleEditorBundle("checkpoint2_type", t("confined_space_activity"))
                                      }
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="title">{t("confined_space_activity")}</span>
                                            <button
                                              className={`grid-icon grid-icon-edit icon-edit icon-mode_edit`}
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className="col_check">
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="default">{t("confined_space_activity")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                </tr>
                              </thead>
                            </table>
                          </div>
                          <div
                            className="grid-left-body"
                            ref={(el) => (this.leftBodyContainer = el)}
                            onWheel={(e) => this.syncWheel(e)}
                          >
                            <table className="tbl-basic tbl-data list-table-row data-schedule grid-table-left-body-schedule">
                              <tbody className="schedule">
                                {items.map((schedule, index) => (
                                  <tr key={index}>
                                    <td
                                      className="col2"
                                      data-tip
                                      data-for="schedule-full-text"
                                      onMouseEnter={() => this.handleOnMouse(schedule.device_name, 15)}
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner txt-left">
                                            {this.omitText(schedule.device_name, 15)}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      className="col3"
                                      data-tip
                                      data-for="schedule-full-text"
                                      onMouseEnter={() => this.handleOnMouse(schedule.category_name, 15)}
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner txt-left">
                                            {this.omitText(schedule.category_name, 15)}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      className="col4"
                                      data-tip
                                      data-for="schedule-full-text"
                                      onMouseEnter={() => this.handleOnMouse(schedule.construction_management_name, 15)}
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner txt-left">
                                            {this.omitText(schedule.construction_management_name, 15)}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      className="col5"
                                      data-tip
                                      data-for="schedule-full-text"
                                      onMouseEnter={() => this.handleOnMouse(schedule.primary_charge_name, 13)}
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner txt-left">
                                            {this.omitText(schedule.primary_charge_name, 13)}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      className="col7"
                                      data-tip
                                      data-for="schedule-full-text"
                                      onMouseEnter={() => this.handleOnMouse(schedule.item_user_name, 15)}
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner txt-left">
                                            {this.omitText(schedule.item_user_name, 15)}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      className="col8"
                                      data-tip
                                      data-for="schedule-full-text"
                                      onMouseEnter={() => this.handleOnMouse(schedule.item_name, 15)}
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner txt-left">
                                            {this.omitText(schedule.item_name, 15)}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      className="col9"
                                      data-tip
                                      data-for="schedule-full-text"
                                      onMouseEnter={() => this.handleOnMouse(schedule.item_note, 20)}
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner txt-left">
                                            {this.omitText(schedule.item_note, 20)}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      className="col10"
                                      data-tip
                                      data-for="schedule-full-text"
                                      onMouseEnter={() => this.handleOnMouse(schedule.process_text, 48)}
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner txt-left">
                                            {this.omitText(schedule.process_text, 48)}
                                          </div>
                                        </div>
                                      </div>
                                    </td>

                                    {this.checkOpenEditor(schedule.roles, schedule.schedule_date, "checkpoint1") ? (
                                      <td
                                        className="col_check grid-cell-clickable txt-center schedule-right-body"
                                        onClick={() =>
                                          this.showScheduleEditor(
                                            "checkpoint1_type",
                                            t("hot_work"),
                                            "[" + schedule.process_text + "]",
                                            schedule.schedule_id,
                                            schedule.checkpoint1_type,
                                            schedule.timestamp
                                          )
                                        }
                                      >
                                        <div className="grid-cell-wrap">
                                          <div className="grid-cell-con">
                                            <div className="grid-cell-inner">
                                              {schedule.checkpoint1_name}
                                              <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    ) : (
                                      <td className="col_check txt-center schedule-right-body">
                                        <div className="grid-cell-wrap">
                                          <div className="grid-cell-con">
                                            <div className="grid-cell-inner">{schedule.checkpoint1_name}</div>
                                          </div>
                                        </div>
                                      </td>
                                    )}
                                    {this.checkOpenEditor(schedule.roles, schedule.schedule_date, "checkpoint2") ? (
                                      <td
                                        className="col_check grid-cell-clickable txt-center schedule-right-body"
                                        onClick={() =>
                                          this.showScheduleEditor(
                                            "checkpoint2_type",
                                            t("confined_space_activity"),
                                            "[" + schedule.process_text + "]",
                                            schedule.schedule_id,
                                            schedule.checkpoint2_type,
                                            schedule.timestamp
                                          )
                                        }
                                      >
                                        <div className="grid-cell-wrap">
                                          <div className="grid-cell-con">
                                            <div className="grid-cell-inner">
                                              {schedule.checkpoint2_name}
                                              <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    ) : (
                                      <td className="col_check txt-center schedule-right-body">
                                        <div className="grid-cell-wrap">
                                          <div className="grid-cell-con">
                                            <div className="grid-cell-inner">{schedule.checkpoint2_name}</div>
                                          </div>
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="grid-right-header" ref={(el) => (this.rightHeaderContainer = el)}>
                            <table className="tbl-basic tbl-data list-table-row schedule-table">
                              <thead className="schedule">
                                <tr>
                                  <th colSpan="5" className="col-gb">
                                    {t("groundbreaking_end_check")}
                                  </th>
                                </tr>
                                <tr>
                                  <th className="col_chita">{t("gas_testing_hot_work")}</th>
                                  <th className="col_chita">{t("gas_testing_confined_space")}</th>
                                  <th className="col_chita">{t("permit_work")}</th>
                                  <th className="col4">{t("approval")}</th>
                                  <th className="col_chita">{t("complete_process_shoeten")}</th>
                                </tr>
                                <tr className="chita-list-header-right-3rd">
                                  {this.checkOpenEditorBulkNoLimit(bulkRoles, "field1") ? (
                                    <th
                                      className={`header-bottom col11`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field1_id",
                                          t("gas_testing_hot_work") + "：" + t("assignee")
                                        )
                                      }
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="title">{t("assignee")}</span>
                                            <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className="col11">
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="default">{t("assignee")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  {this.checkOpenEditorBulkNoLimit(bulkRoles, "field2") ? (
                                    <th
                                      className={`header-bottom col11`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field2_id",
                                          t("gas_testing_confined_space") + "：" + t("assignee")
                                        )
                                      }
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="title">{t("assignee")}</span>
                                            <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className="col11">
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="default">{t("assignee")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  {this.checkOpenEditorBulkNoLimit(bulkRoles, "field3") ? (
                                    <th
                                      className={`header-bottom col11`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field3_id",
                                          t("permit_work") + "：" + t("permit")
                                        )
                                      }
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="title">{t("permit")}</span>
                                            <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className="col11">
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="default">{t("permit")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  {this.checkOpenEditorBulkNoLimit(bulkRoles, "field4") ? (
                                    <th
                                      className={`header-bottom col11`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle("field4_id", t("approval") + "：" + t("tl_gm"))
                                      }
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="title">{t("tl_gm")}</span>
                                            <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className="col11">
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="default">{t("tl_gm")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                  {this.checkOpenEditorBulkNoLimit(bulkRoles, "field5") ? (
                                    <th
                                      className={`header-bottom col11`}
                                      onClick={() =>
                                        this.showScheduleEditorBundle(
                                          "field5_id",
                                          t("complete_process_shoeten") + "：" + t("comp_continue")
                                        )
                                      }
                                    >
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="title">{t("comp_continue")}</span>
                                            <button className="grid-icon grid-icon-edit icon-mode_edit icon-edit"></button>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <th className="col11">
                                      <div className="grid-cell-wrap">
                                        <div className="grid-cell-con">
                                          <div className="grid-cell-inner">
                                            <span className="default">{t("comp_continue")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  )}
                                </tr>
                              </thead>
                            </table>
                          </div>
                          <div
                            className="grid-right-body sch-chita"
                            onScroll={(e) => this.syncScroll(e)}
                            ref={(node) => (this.rightBodyContainer = node)}
                          >
                            <table className="tbl-basic tbl-data list-table-row data-schedule grid-table-right-body schedule-table">
                              <tbody className="schedule">
                                {items.map((schedule, index) => (
                                  <ScheduleRightRowChitaContainer
                                    key={index}
                                    index={index}
                                    schedule={schedule}
                                    checkOpenEditorNolimit={(roles, target) =>
                                      this.checkOpenEditorNolimit(roles, target)
                                    }
                                    showScheduleEditor={(
                                      updateTarget,
                                      column,
                                      processText,
                                      scheduleId,
                                      optionId,
                                      timestamp
                                    ) =>
                                      this.showScheduleEditor(
                                        updateTarget,
                                        column,
                                        processText,
                                        scheduleId,
                                        optionId,
                                        timestamp
                                      )
                                    }
                                    handleOnMouse={(columnValue, validLength) =>
                                      this.handleOnMouse(columnValue, validLength)
                                    }
                                    omitText={(text, validLength) => this.omitText(text, validLength)}
                                    setTooltip={this.setTooltip}
                                  />
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
            </div>
          </div>
          <a
            className="d-n"
            download={this.state.downloadName}
            href={this.state.downloadUrl}
            ref={(node) => (this.btnDownload = node)}
          >
            download
          </a>
        </div>
        {this.state.showUpdateBundle && (
          <ScheduleEditorBundleChitaContainer
            target={this.state.target}
            requestParam={this.state.requestParam}
            panelText={this.state.panelText}
            selectValues={this.state.selectValues}
            closeHandler={() => this.hideScheduleEditor()}
            searchHandler={() => this.handleSearchBundle()}
          />
        )}
        {this.state.showEditor && (
          <ScheduleEditorChitaContainer
            target={this.state.target}
            processText={this.state.processText}
            scheduleId={this.state.scheduleId}
            optionId={this.state.optionId}
            panelText={this.state.panelText}
            timeStamp={this.state.timeStamp}
            selectValues={this.state.selectValues}
            closeHandler={() => this.hideScheduleEditor()}
          />
        )}
        {this.state.showUpdatePanel && (
          <ScheduleUpdateChitaContainer
            scheduleDateList={this.judgeScheduleDateUpdate()}
            categories={masters.categories}
            closeHandler={() => this.hideScheduleEditor()}
          />
        )}
        {this.state.tooltip !== null && (
          <ReactTooltip
            id="schedule-full-text"
            className="matrix-task-detail"
            delayShow={500}
            effect="solid"
            isCapture={true}
            scrollHide={true}
          >
            <TooltipContainer data={this.state.tooltip} />
          </ReactTooltip>
        )}
      </div>
    );
  }
}

ScheduleChita.propTypes = {
  areaIds: PropTypes.array.isRequired,
  deviceIds: PropTypes.array.isRequired,
  categoryId: PropTypes.number.isRequired,
  facilityManagementIds: PropTypes.array.isRequired,
  constructionManagementIds: PropTypes.array.isRequired,
  primaryChargeIds: PropTypes.array.isRequired,
  scheduleDate: PropTypes.string.isRequired,
  field1Ids: PropTypes.array.isRequired,
  field2Ids: PropTypes.array.isRequired,
  field3Ids: PropTypes.array.isRequired,
  itemText: PropTypes.string.isRequired,
  processText: PropTypes.string.isRequired,
  masters: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  changeArea: PropTypes.func.isRequired,
  changeDevice: PropTypes.func.isRequired,
  changeFacilityManagement: PropTypes.func.isRequired,
  changeCategory: PropTypes.func.isRequired,
  changeConstructionManagement: PropTypes.func.isRequired,
  changePrimaryCharge: PropTypes.func.isRequired,
  changeScheduleDate: PropTypes.func.isRequired,
  changeItemText: PropTypes.func.isRequired,
  changeProcessText: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  revertLocalCondition: PropTypes.func.isRequired,
  validated: PropTypes.bool.isRequired,
};

export default withTranslation()(ScheduleChita);
