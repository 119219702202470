import { useMemo } from "react";
import { useSelector } from "react-redux";

import { ConstructionExtension, ConstructionExtensionType } from "@/models/constructionExtensions";

type ConstructionExtensions = {
  extensions: ConstructionExtension[];
};

export const useConstructionExtensions = (extensionType: ConstructionExtensionType) => {
  const { extensions } = useSelector<any, ConstructionExtensions>((state) => state.constructionExtensions);
  const extension: ConstructionExtension | undefined = useMemo(() => {
    return extensions?.find((extension) => extension.extension_type === extensionType);
  }, [extensionType, extensions]);

  return {
    extension,
  };
};
