import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState } from "@/reducers/types";
import { Button } from "@/sx-layout/common/Button";
import { Toggle } from "@/sx-layout/common/Toggle";
import FileExport from "@/sx-layout/components/plotmap/components/fileExport";
import { AddKeepOutAreaButton } from "@/sx-layout/components/plotmap/components/toolbar/components/AddKeepOutAreaButton";
import { PlotPlan } from "@/sx-layout/components/settings/plotPlanSetting/models";
import { Role } from "@/sx-layout/models";

type Props = {
  canEditMap: number;
  disabled: boolean;
  plotPlan: PlotPlan;
  loggedInUserCompanyId: number | undefined;
  onChangePlotPlan: () => void;
  onCreateMachine: () => void;
  onCreateArea: () => void;
  onCreateCustomArea: () => void;
  onChangeEditable: (_) => void;
  onReload: () => void;
  scale: number;
  mapRatio: number;
};

export const Toolbar = forwardRef<HTMLDivElement, Props>(
  (
    {
      canEditMap,
      disabled,
      plotPlan,
      loggedInUserCompanyId,
      onChangePlotPlan,
      onCreateMachine,
      onCreateArea,
      onCreateCustomArea,
      onChangeEditable,
      onReload,
      scale,
      mapRatio,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const layoutRoles = useSelector<RootState, Role>((state) => state.app.roles.layout?.layout);

    return (
      <div ref={ref} className="flex items-center justify-between">
        <div className="flex items-center space-x-6 tbl-top-area">
          <h2 className="my-8 font-bold text-[16px]">{t("deploy_heavy_machine")}</h2>
          <span className="underline text-[13px] cursor-pointer" onClick={onChangePlotPlan}>
            {plotPlan?.plot_plan_name ?? t("unselected")}
          </span>
          <Button
            disabled={disabled || !plotPlan}
            className={`${layoutRoles?.machine_create ? "" : "hidden"} flex items-center justify-center`}
            onClick={onCreateMachine}
          >
            <img src="./img/icon-add.svg" className="mr-2 w-[16px]" />
            {t("add_heavy_machine")}
          </Button>
          <AddKeepOutAreaButton
            onCreateArea={onCreateArea}
            onCreateCustomArea={onCreateCustomArea}
            disabled={disabled}
          />
          <FileExport loggedInUserCompanyId={loggedInUserCompanyId} scale={scale} mapRatio={mapRatio} />
        </div>
        <div className="flex items-center space-x-6">
          <Toggle
            label={t("edit")}
            onChange={onChangeEditable}
            defaultChecked={canEditMap}
            className={`${layoutRoles?.update ? "" : "hidden"}`}
            isBold
          />
          <Button className="flex items-center justify-center" onClick={onReload}>
            <img src="./img/icon-reload.svg" className="mr-2 w-[18px]" />
            {t("reload")}
          </Button>
        </div>
      </div>
    );
  }
);

Toolbar.displayName = "Toolbar";
