import actions from "../actions";
import * as constants from "../constants/index";
import environments from "../environments";

export const fetchJobs = (start = 1) => {
  const endpoint = `${environments.API_BASE_URI}/download_jobs?start=${start}&limit=20`;
  const init = start === 1;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.DATAEXPORT_BEGIN_FETCH_JOBS,
        };
      },
      success: (response) => {
        return {
          type: constants.DATAEXPORT_END_FETCH_JOBS,
          payload: {
            response,
            init,
          },
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const cancelJob = (download_job_id, timestamp, callback, fallback) => {
  const endpoint = `${environments.API_BASE_URI}/download_jobs/update/${download_job_id}`;

  const statusCancel = 3;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({
      status: statusCancel,
      timestamp,
    }),
    callbacks: {
      begin: () => {
        return {
          type: constants.DATAEXPORT_BEGIN_CANCEL_JOBS,
        };
      },
      success: (response) => {
        callback && callback();

        return {
          type: constants.DATAEXPORT_END_CANCEL_JOBS,
          payload: response,
        };
      },
      error: (response) => {
        fallback && fallback();

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const downloadFile = (download_job_id, fileName) => {
  const endpoint = `${environments.API_BASE_URI}/download_job/files/${download_job_id}`;
  const callback = (blob) => {
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else if (window.URL && window.URL.createObjectURL) {
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = window.URL.createObjectURL(blob);
      anchor.click();
    }
  };

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.DATAEXPORT_BEGIN_DONWLOAD_FILE,
        };
      },
      success: (response) => {
        callback && callback(response);

        return {
          type: constants.DATAEXPORT_END_DONWLOAD_FILE,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const clearJobs = () => {
  return {
    type: constants.DATAEXPORT_CLEAR_JOBS,
  };
};

export const readJob = (download_job_id: number) => {
  const endpoint = `${environments.API_BASE_URI}/download_jobs/read/${download_job_id}`;

  return (dispatch) => {
    const action = {
      type: constants.APP_CALL_API,
      endpoint,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({}),
      callbacks: {
        begin: () => {
          return {
            type: constants.DATAEXPORT_BEGIN_READ_JOB,
          };
        },
        success: (response) => {
          dispatch(actions.notifications.fetchNotificationsSummary());

          return {
            type: constants.DATAEXPORT_END_READ_JOB,
            payload: {
              response,
            },
          };
        },
        error: (response) => {
          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    };

    dispatch(action);
  };
};

export const readAllJob = () => {
  const endpoint = `${environments.API_BASE_URI}/download_jobs/read`;

  return (dispatch) => {
    const action = {
      type: constants.APP_CALL_API,
      endpoint,
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({}),
      callbacks: {
        begin: () => {
          return {
            type: constants.DATAEXPORT_BEGIN_READ_ALL_JOB,
          };
        },
        success: () => {
          dispatch(actions.dataExport.clearJobs());
          dispatch(actions.dataExport.fetchJobs());
          dispatch(actions.notifications.fetchNotificationsSummary());

          return {
            type: constants.DATAEXPORT_END_READ_ALL_JOB,
          };
        },
        error: (response) => {
          return {
            type: constants.APP_SHOW_ERROR,
            payload: response,
            error: true,
          };
        },
      },
    };

    dispatch(action);
  };
};
