import {
  PROGRESS_NOTIFICATION_TYPE_CANCEL_REPORT,
  PROGRESS_NOTIFICATION_TYPE_COMPLETION_REPORT,
  ProgressNotificationState,
} from "../components/ProgressNotificationDrawer/models";
import * as constants from "../constants/index";
import storageManager from "../lib/storageManager";

const initialState: ProgressNotificationState = {
  fetching: false,
  isError: false,
  notifications: [],
  isRefreshRequested: false,
  end: false,
  fetchedNotificationLargestId: {
    [PROGRESS_NOTIFICATION_TYPE_COMPLETION_REPORT]: 0,
    [PROGRESS_NOTIFICATION_TYPE_CANCEL_REPORT]: 0,
  },
};

const progressNotification = (state: ProgressNotificationState = initialState, action) => {
  switch (action.type) {
    case constants.PROGRESS_NOTIFICATIONS_BEGIN_FETCH_NOTIFICATIONS: {
      return {
        ...state,
        fetching: true,
        isRefreshRequested: false,
      };
    }

    case constants.PROGRESS_NOTIFICATIONS_END_FETCH_NOTIFICATIONS: {
      const { payload } = action;
      const { init, response, searchParam } = payload;
      const { list, total_num } = response;
      const newNotifications = init ? list : [...state.notifications, ...list];
      const end = newNotifications.length >= total_num;

      const { fetchedNotificationLargestId } = state;
      if (
        list.length > 0 &&
        list[0].progress_notification_id > fetchedNotificationLargestId[searchParam.notification_type]
      ) {
        fetchedNotificationLargestId[searchParam.notification_type] = list[0].progress_notification_id;

        storageManager.setConstructionItem(
          "progress_notification_fetched_largest_id",
          JSON.stringify(fetchedNotificationLargestId)
        );
      }

      return {
        ...state,
        fetching: false,
        notifications: newNotifications,
        end,
        fetchedNotificationLargestId,
      };
    }
    case constants.PROGRESS_NOTIFICATIONS_BEGIN_READ_NOTIFICATION: {
      return {
        ...state,
      };
    }
    case constants.PROGRESS_NOTIFICATIONS_END_READ_NOTIFICATION: {
      const readNotification = action.payload.response;
      const newNotifications = state.notifications.map((notification) => {
        if (notification.progress_notification_id === readNotification.progress_notification_id) {
          return readNotification;
        }

        return notification;
      });

      return {
        ...state,
        notifications: newNotifications,
      };
    }
    case constants.PROGRESS_NOTIFICATIONS_BEGIN_READ_ALL_NOTIFICATION: {
      return {
        ...state,
      };
    }
    case constants.PROGRESS_NOTIFICATIONS_END_READ_ALL_NOTIFICATION: {
      return {
        ...state,
      };
    }
    case constants.PROGRESS_NOTIFICATIONS_REFRESH: {
      return {
        ...state,
        isRefreshRequested: true,
      };
    }
    case constants.APP_SHOW_ERROR: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default progressNotification;
