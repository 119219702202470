import format from "date-fns/format";
import parse from "date-fns/parse";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ConfirmDialog from "../ConfirmDialog";

class DataExportListDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCancelDialog: false,
      selectedDownloadJobId: null,
      selectedTimestamp: null,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleReload = this.handleReload.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.infiniteSearch = this.infiniteSearch.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.handleRead = this.handleRead.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleMouseDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleMouseDown, false);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.scrollToTop();
    }
  }

  handleMouseDown(e) {
    if (this.listArea && this.listArea.contains(e.target)) {
      return;
    }

    const toggleButton = document.getElementById("button-header-export");
    if (toggleButton && toggleButton.contains(e.target)) {
      return;
    }

    const cancelDialog = document.getElementById("cancel-dialog-export");
    if (cancelDialog && cancelDialog.contains(e.target)) {
      return;
    }

    const { onClose } = this.props;
    onClose();
  }

  handleReload(e) {
    e.stopPropagation();
    e.preventDefault();

    const { fetchJobs, clearJobs } = this.props;
    clearJobs();
    fetchJobs();
  }

  handleScroll() {
    const { end } = this.props;
    if (end) {
      return;
    }

    const container = this.listArea;
    const containerHeight = container.clientHeight;
    const contentHeight = container.scrollHeight;
    const scrollTop = container.scrollTop;
    const diff = contentHeight - (containerHeight + scrollTop);

    if (diff <= 10) {
      this.infiniteSearch();
    }
  }

  infiniteSearch() {
    const { jobs, fetching, fetchJobs } = this.props;
    if (!fetching) {
      const nextStart = jobs.length + 1;
      fetchJobs(nextStart);
    }
  }

  handleClick(job) {
    const { download_job_id, status, file_name, timestamp } = job;

    if (status === 0) {
      this.setState({
        selectedDownloadJobId: download_job_id,
        selectedTimestamp: timestamp,
        showCancelDialog: true,
      });

      return;
    }

    if (status === 2) {
      const { downloadFile } = this.props;
      downloadFile(download_job_id, file_name);

      return;
    }
  }

  handleRead(job) {
    const { download_job_id, read_date } = job;
    if (read_date !== null) return;

    const { readJob } = this.props;
    readJob(download_job_id);
  }

  handleOk() {
    const { selectedDownloadJobId, selectedTimestamp } = this.state;
    const { cancelJob } = this.props;
    cancelJob(selectedDownloadJobId, selectedTimestamp, this.handleCancel, this.handleCancel);
  }

  handleCancel() {
    this.setState({
      selectedDownloadJobId: null,
      selectedTimestamp: null,
      showCancelDialog: false,
    });
  }

  scrollToTop() {
    this.listArea.scrollTop = 0;
  }

  render() {
    const { showCancelDialog } = this.state;

    const { t, open, onClose, fetching, jobs } = this.props;

    const statuses = {
      0: "status_scheduled",
      1: "status_processing",
      2: "status_done",
      3: "status_cancelled",
      4: "status_error",
    };
    const btnClasses = {
      0: "btn btn-gray btn-list",
      1: "btn-none",
      2: "btn btn-blue btn-list",
      3: "btn-none",
      4: "btn-none",
    };
    const btnTexts = {
      0: "cancel",
      1: "",
      2: "download",
      3: "",
    };

    return (
      <>
        <div
          id="data-export-area"
          className={`data-export-area ${open ? "open" : ""}`}
          ref={(node) => (this.listArea = node)}
          onScroll={this.handleScroll}
        >
          <div className="sticky-header">
            <div className="title">
              <span className="title-text">{t("data_export_list")}</span>
              <div className={`inline-block clickable  ${fetching ? "animation-rotate-progress" : ""}`}>
                <img
                  src="./img/icon-reload-black.svg"
                  alt="reload"
                  className="icon-file reload-icon"
                  onClick={this.handleReload}
                />
              </div>
              <div className="close-area clickable" onClick={onClose}>
                <img src="./img/icon-close-black.svg" alt="close" className="icon-file close-icon" />
              </div>
            </div>
            <div className="list-header">
              <a className="read-all-btn" onClick={this.props.readAllJob}>
                {t("all_read")}
              </a>
            </div>
          </div>
          <div className="data-list-area">
            <ul>
              {jobs.map((job, index) => {
                const { status, information, file_name, filetype_name, read_date } = job;
                const acceptedDate = information["受付日時"];
                const selectedCategory = information["選択カテゴリー"];
                const selectedItems = information["選択件名数"];
                const outputBase = information["出力基準"];

                const statusName = statuses[status];
                const btnClass = btnClasses[status];
                const btnText = btnTexts[status];

                let ext = "";
                if (file_name) {
                  const reg = /(.*)(?:\.([^.]+$))/;
                  ext = file_name.match(reg)[2];
                  if (ext === "xlsx" || ext === "xlm" || ext === "xlsm") {
                    ext = "xls";
                  }
                }
                const icon = ext ? <img src={`img/icon_${ext}.svg`} alt="Excel" className="icon" /> : null;

                const notificationClassName =
                  read_date !== null
                    ? "read"
                    : job.download_job_id > this.props.fetchedJobsLargestIdBeforeOpenDatalist
                    ? "new"
                    : "";
                const subjects = ["受付日時", "選択カテゴリー", "選択件名数", "出力基準"];
                const keys = Object.keys(information).filter((key) => {
                  return !subjects.includes(key);
                });

                return (
                  <li
                    key={index}
                    className={`list ${notificationClassName}`}
                    onClick={() => {
                      this.handleRead(job);
                    }}
                  >
                    <div className="clearfix">
                      <div className="data-header">
                        <div className="subject">
                          <span className="img-area">{icon}</span>
                          {filetype_name}
                        </div>
                        <div className="accepted-date">
                          {format(parse(acceptedDate, "yyyy/MM/dd HH:mm", new Date()), "M/d H:mm")}
                        </div>
                      </div>
                      <dl className="data-content">
                        {selectedCategory && (
                          <dl>
                            <dt>{t("selected_category")}：</dt>
                            <dd>{selectedCategory}</dd>
                          </dl>
                        )}
                        {selectedItems && (
                          <dl>
                            <dt>{t("selected_items")}：</dt>
                            <dd>{selectedItems}</dd>
                          </dl>
                        )}
                        {outputBase && (
                          <dl>
                            <dt>{t("output_base")}：</dt>
                            <dd>{outputBase}</dd>
                          </dl>
                        )}
                        {keys.map((key) => {
                          return (
                            <dl key={key}>
                              <dt>{key}：</dt>
                              <dd>{information[key]}</dd>
                            </dl>
                          );
                        })}
                        <dl>
                          <dt>{t("inazuma_status")}：</dt>
                          <dd>{t(statusName)}</dd>
                        </dl>
                      </dl>
                      <button
                        data-test-id="button-data-export-cancel"
                        className={btnClass}
                        onClick={() => this.handleClick(job)}
                      >
                        {t(btnText)}
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {showCancelDialog ? (
          <ConfirmDialog
            title="cancel_job"
            messages={["confirm_cancel_job"]}
            okClickHandler={this.handleOk}
            cancelClickHandler={this.handleCancel}
            id="cancel-dialog-export"
          />
        ) : null}
      </>
    );
  }
}

DataExportListDrawer.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fetchJobs: PropTypes.func.isRequired,
  cancelJob: PropTypes.func.isRequired,
  readJob: PropTypes.func.isRequired,
  readAllJob: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  jobs: PropTypes.array.isRequired,
  end: PropTypes.bool.isRequired,
  downloadFile: PropTypes.func.isRequired,
  fetchedJobsLargestIdBeforeOpenDatalist: PropTypes.number.isRequired,
};

export default withTranslation()(DataExportListDrawer);
