import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import storageManager from "../../lib/storageManager";

const QrGridRow = (props) => {
  const { downloadZip, item, t } = props;
  const {
    category_name,
    area_name,
    device_name,
    primary_charge_name,
    facility_management_name,
    construction_management_name,
    other_name,
    item_num,
  } = item;

  const handleDownload = () => {
    const {
      category_id,
      area_id,
      device_id,
      facility_management_id,
      construction_management_id,
      primary_charge_id,
      other_id,
    } = item;

    const params = {
      category_id,
      area_id,
      device_id,
      facility_management_id,
      construction_management_id,
      primary_charge_id,
      other_id,
    };

    const searchString = storageManager.getConstructionItem("qrSearchParams");

    if (searchString) {
      const searchParams = JSON.parse(searchString);

      if (searchParams.userId && searchParams.userId > 0) {
        params.user_id = searchParams.userId;
      } else if (searchParams.groupId && searchParams.groupId > 0) {
        params.group_id = searchParams.groupId;
      }
    }

    downloadZip(params, null);
  };

  return (
    <tr>
      <td>{area_name}</td>
      <td>{device_name}</td>
      <td>{facility_management_name}</td>
      <td>{construction_management_name}</td>
      <td>{primary_charge_name}</td>
      <td>{other_name}</td>
      <td>{category_name}</td>
      <td className="txt-right">{item_num}</td>
      <td className="txt-center">
        <a onClick={handleDownload}>{t("export")}</a>
      </td>
    </tr>
  );
};

QrGridRow.propTypes = {
  item: PropTypes.object.isRequired,
  downloadZip: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(QrGridRow);
