import { format } from "date-fns";
import React from "react";

type Props = {
  data?: string;
};

const Timestamp: React.FC<Props> = ({ data }) => {
  if (!data) {
    return null;
  }

  return <div className="progress-notification-timestamp">{format(new Date(data), "M/d H:mm")}</div>;
};

export default Timestamp;
