import { useCallback, useState } from "react";

import { FreeDrawArrow } from "@/sx-layout/components/plotmap/components/freeDraw/models";

export const useSelectedArrow = () => {
  const [freeDrawArrow, setFreeDrawArrow] = useState<FreeDrawArrow>();

  const start = useCallback((arrow: FreeDrawArrow) => {
    setFreeDrawArrow(arrow);
  }, []);

  const end = useCallback(() => {
    setFreeDrawArrow(null);
  }, []);

  const changeColorAndThickness = useCallback(
    (arrow: FreeDrawArrow) => {
      setFreeDrawArrow({
        ...freeDrawArrow,
        color: arrow.color,
        thickness: arrow.thickness,
        timestamp: { ...freeDrawArrow.timestamp, update_date: arrow.timestamp.update_date },
      });
    },
    [freeDrawArrow]
  );

  const changeCoords = useCallback(
    (arrow: FreeDrawArrow) => {
      setFreeDrawArrow({
        ...freeDrawArrow,
        coords: arrow.coords,
        timestamp: { ...freeDrawArrow.timestamp, update_date: arrow.timestamp.update_date },
      });
    },
    [freeDrawArrow]
  );

  return { freeDrawArrow, start, end, changeColorAndThickness, changeCoords };
};
