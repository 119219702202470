import { connect } from "react-redux";

import actions from "../../actions/index";
import ScheduleEneosKawasaki from "../../components/list/ScheduleEneosKawasaki";

const mapStateToProps = (state) => {
  let areas = [];
  let devices = [];
  let categories = [];
  let facilityManagements = [];
  let constructionManagements = [];
  let primaryCharges = [];
  let others = [];
  let systems = [];
  let users = [];
  let groups = [];
  let userRole;

  if (state.construction.masters) {
    areas = state.construction.masters.areas;
    devices = state.construction.masters.devices;
    categories = state.construction.masters.categories;
    facilityManagements = state.construction.masters.facility_managements;
    constructionManagements = state.construction.masters.construction_managements;
    primaryCharges = state.construction.masters.primary_charges;
    others = state.construction.masters.others;
    systems = state.construction.masters.systems;
    users = state.construction.masters.users;
    groups = state.construction.masters.groups;
    userRole = state.construction.masters.user_role;
  }

  return {
    masters: {
      areas,
      devices,
      categories,
      facilityManagements,
      constructionManagements,
      primaryCharges,
      others,
      systems,
      users,
      groups,
      userRole,
    },
    areaIds: state.schedule_eneos_kawasaki.areaIds,
    deviceIds: state.schedule_eneos_kawasaki.deviceIds,
    categoryId: state.schedule_eneos_kawasaki.categoryId,
    facilityManagementIds: state.schedule_eneos_kawasaki.facilityManagementIds,
    constructionManagementIds: state.schedule_eneos_kawasaki.constructionManagementIds,
    primaryChargeIds: state.schedule_eneos_kawasaki.primaryChargeIds,
    field1Ids: state.schedule_eneos_kawasaki.field1Ids,
    field7Ids: state.schedule_eneos_kawasaki.field7Ids,
    field8Ids: state.schedule_eneos_kawasaki.field8Ids,
    userIds: state.schedule_eneos_kawasaki.userIds,
    groupIds: state.schedule_eneos_kawasaki.groupIds,
    scheduleDate: state.schedule_eneos_kawasaki.scheduleDate,
    itemText: state.schedule_eneos_kawasaki.itemText,
    processText: state.schedule_eneos_kawasaki.processText,
    items: state.schedule_eneos_kawasaki.items,
    bulkRoles: state.schedule_eneos_kawasaki.bulkRoles,
    firstScheduleDate: state.schedule_eneos_kawasaki.firstScheduleDate,
    scheduleUpdateDate: state.schedule_eneos_kawasaki.scheduleUpdateDate,
    switcherStatus: state.schedule_eneos_kawasaki.switcherStatus,
    fetching: state.schedule_eneos_kawasaki.fetching,
    isError: state.schedule_eneos_kawasaki.isError,
    validated: state.schedule_eneos_kawasaki.validated,
    roles: state.app.roles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeArea: (items) => dispatch(actions.schedule_eneos_kawasaki.changeArea(items)),
    changeDevice: (items) => dispatch(actions.schedule_eneos_kawasaki.changeDevice(items)),
    changeCategory: (item) => dispatch(actions.schedule_eneos_kawasaki.changeCategory(item)),
    changeFacilityManagement: (items) => dispatch(actions.schedule_eneos_kawasaki.changeFacilityManagement(items)),
    changeConstructionManagement: (items) =>
      dispatch(actions.schedule_eneos_kawasaki.changeConstructionManagement(items)),
    changePrimaryCharge: (items) => dispatch(actions.schedule_eneos_kawasaki.changePrimaryCharge(items)),
    changeField1: (items) => dispatch(actions.schedule_eneos_kawasaki.changeField1(items)),
    changeField7: (items) => dispatch(actions.schedule_eneos_kawasaki.changeField7(items)),
    changeField8: (items) => dispatch(actions.schedule_eneos_kawasaki.changeField8(items)),
    changeUser: (items) => dispatch(actions.schedule_eneos_kawasaki.changeUser(items)),
    changeGroup: (items) => dispatch(actions.schedule_eneos_kawasaki.changeGroup(items)),
    changeScheduleDate: (scheduleDate) => dispatch(actions.schedule_eneos_kawasaki.changeScheduleDate(scheduleDate)),
    changeItemText: (itemText) => dispatch(actions.schedule_eneos_kawasaki.changeItemText(itemText)),
    changeProcessText: (processText) => dispatch(actions.schedule_eneos_kawasaki.changeProcessText(processText)),
    search: (params, callback) => dispatch(actions.schedule_eneos_kawasaki.search(params, callback)),
    getOptions: (callback) => dispatch(actions.schedule_eneos_kawasaki.getOptions(callback)),
    clearSearch: (categoryId) => dispatch(actions.schedule_eneos_kawasaki.clearSearch(categoryId)),
    revertLocalCondition: () => dispatch(actions.schedule_eneos_kawasaki.revertLocalCondition()),
    download: (params, callback, fallback) =>
      dispatch(actions.schedule_eneos_kawasaki.download(params, callback, fallback)),
    updateItem: (data, handleSuccess, handleCancel) =>
      dispatch(actions.schedule_eneos_kawasaki.updateItem(data, handleSuccess, handleCancel)),
    showAlert: (title, messages, okClickHandler) => dispatch(actions.app.showAlert(title, messages, okClickHandler)),
    hideAlert: () => dispatch(actions.app.hideAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleEneosKawasaki);
