import { connect } from "react-redux";

import actions from "../../actions";
import ColumnEditor from "../../components/matrix/ColumnEditor";

const mapStateToProps = (state, ownProps) => {
  const processes = state.matrix.columns.filter((process) => process.process_id === ownProps.processId);
  let process = null;

  if (processes.length > 0) {
    process = processes[0];
  }

  return {
    process,
    masters: state.construction.masters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSave: (process, data, callback, cancelHandler, failedCallback) =>
      dispatch(actions.matrix.updateProcessReceiver(process, data, callback, cancelHandler, failedCallback)),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ColumnEditor);
