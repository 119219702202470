import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import actions from "@/actions";
import { AppDispatch } from "@/reducers/types";
import DetailRow from "@/sx-layout/components/notification/components/NotificationRow/DetailRow";
import Header from "@/sx-layout/components/notification/components/NotificationRow/Header";
import {
  Notification,
  LayoutNotificationFetchedNotificationLargestId,
  LayoutNotificationType,
} from "@/sx-layout/components/notification/models";

type Props = {
  notification: Notification;
  fetchedNotificationLargestIdBeforeOpen: LayoutNotificationFetchedNotificationLargestId;
  notificationType: LayoutNotificationType;
};

const NotificationRow: React.FC<Props> = ({
  notification,
  fetchedNotificationLargestIdBeforeOpen,
  notificationType,
}) => {
  const [isRead, setIsRead] = useState(() => !!notification.read_date); // NOTE: APIによる既読とUI上の既読は非同期にするためのフラグ
  const dispatch: AppDispatch = useDispatch();

  const isNew = notification.layout_notification_id > fetchedNotificationLargestIdBeforeOpen[notificationType];

  const notificationClassName = useMemo(() => {
    const baseClassName = "layout-notification";
    if (isRead) {
      return baseClassName + " " + "is-read";
    }
    if (isNew) {
      return baseClassName + " " + "is-new";
    }

    return baseClassName;
  }, [isRead, notification]);

  const readNotification = (layout_notification_id: number) => {
    dispatch(
      actions.layoutNotifications.readLayoutNotification({
        input: {
          layout_notification_id,
        },
        onSuccess: () => {
          dispatch(actions.notifications.fetchNotificationsSummary());
        },
      })
    );
  };

  const onReadNotification = () => {
    if (isRead) {
      return;
    }
    setIsRead(true);

    readNotification(notification.layout_notification_id);
  };

  return (
    <div
      className={isRead ? notificationClassName : notificationClassName + " pointer-cursor"}
      onClick={onReadNotification}
    >
      <Header
        notification_type_name={notification.notification_type_name}
        machine_icon_id={notification.machine_icon_id}
        isRead={isRead}
        isNew={isNew}
      />
      <div className="layout-notification-content">
        {Object.entries(notification.information).map(([key, value], index) => (
          <DetailRow
            key={`notification-${notification.layout_notification_id}-detail-${index}`}
            label={key}
            content={value}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};

export default NotificationRow;
