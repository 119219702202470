import actions from "../actions";
import { validateCondition } from "../lib/validate";

const validateMatrix = (store) => (next) => (action) => {
  next(action);
  const state = store.getState();
  if (!(state.app.menu === "summary" && state.construction.masters)) {
    return;
  }

  const { areaIds, deviceIds, categoryIds, validated } = state.summary;

  if (validated) {
    return;
  }

  const { categories, areas, devices } = state.construction.masters;

  const { changeArea, changeDevice, changeCategory, setValidated } = actions.summary;

  const validatedAreaId = validateCondition(areas, areaIds);
  if (validatedAreaId.length !== areaIds.length) {
    next(changeArea(validatedAreaId));
  }
  const validatedDeviceIds = validateCondition(devices, deviceIds);
  if (validatedDeviceIds.length !== deviceIds.length) {
    next(changeDevice(validatedDeviceIds));
  }

  const validatedCategoryIds = validateCondition(categories, categoryIds);
  if (validatedCategoryIds.length !== categoryIds.length) {
    next(changeCategory(validatedCategoryIds));
  }

  next(setValidated(true));

  return;
};

export default validateMatrix;
