import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

const Tooltip = ({ masters, data, t }) => {
  const { process, task } = data;
  const { user_id, group_id } = task;
  const { users, groups } = masters;
  const checkpoints = task.checkpoints.map((cpid) => {
    return masters.checkpoints.find((item) => item.checkpoint_id === cpid);
  });

  const format = (str) => {
    if (!str) {
      return "";
    }

    return str.replace(/-/g, "/");
  };

  let userName = "";

  if (user_id > 0) {
    const user = users.find((d) => d.user_id === user_id);

    if (user) {
      userName = user.user_name;
    }
  } else if (group_id > 0) {
    const group = groups.find((d) => d.group_id === group_id);

    if (group) {
      userName = group.group_name;
    }
  }

  const { schedule_start_date, schedule_end_date, result_start_date, result_end_date, progress } = task;
  const scheduleDate =
    schedule_start_date || schedule_end_date ? `${format(schedule_start_date)} 〜 ${format(schedule_end_date)}` : "";
  const resultDate =
    result_start_date || result_end_date ? `${format(result_start_date)} 〜 ${format(result_end_date)}` : "";

  const comments = task.comments.slice(0, 3);

  return (
    <div role="tooltip" className="in tooltip">
      <div className="tooltip-inner">
        {task.warning_messages.length > 0 && (
          <ul className="tooltip-warnings">
            {task.warning_messages.map((msg, i) => (
              <li key={i}>{msg}</li>
            ))}
          </ul>
        )}
        <div className="form-row">
          <div className="form-group">
            <span className="form-label txt-bold w-130">
              {t("plan")}
              {t("space")}
              {t("date")}
            </span>
            <div className="form-txt">{scheduleDate}</div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <span className="form-label txt-bold w-130">
              {t("results")}
              {t("space")}
              {t("date")}
            </span>
            <div className="form-txt w-200">{resultDate}</div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <span className="form-label txt-bold w-130">{t("progress")}</span>
            <div className="form-txt w-200">{Math.trunc(progress * 100)}%</div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <span className="form-label txt-bold w-130">{t("receiver")}</span>
            <div className="form-txt w-200">{userName}</div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <span className="form-label txt-bold w-130">{t("checkpoint")}</span>
            <div className="form-txt w-200">
              {checkpoints.map((cp, index) => (
                <React.Fragment key={index}>
                  {cp.checkpoint_name}
                  <br />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <span className="form-label txt-bold w-130">
              {t("additional_construction")}
              {t("specification")}
            </span>
            <div className="form-txt w-200">{task.document_no}</div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <span className="form-label txt-bold w-130">{t("comments")}</span>
            <div className="form-txt w-200 tooltip-comments">
              {comments.map((comment, index) => (
                <div key={index} className="tooltip-comment">
                  <p className="tooltip-comment-text">{comment.comment}</p>
                  <div className="tooltip-comment-footer">
                    <time className="tooltip-comment-time">{moment(comment.insert_date).format("YYYY/MM/DD")}</time>
                    <span className="tooltip-comment-user">{comment.user_name}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  masters: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Tooltip);
