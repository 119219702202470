import PropTypes from "prop-types";
import React, { Component } from "react";

import ConstructionSelectorContainer from "../containers/ConstructionSelectorContainer";
import LangSelectorContainer from "../containers/LangSelectorContainer";
import PasswordEditorContainer from "../containers/PasswordEditorContainer";
import AssetContainer from "../containers/asset/AssetContainer";
import HomeContainer from "../containers/home/HomeContainer";
import ImportContainer from "../containers/import/ImportContainer";
import ApprovalContainer from "../containers/list/ApprovalContainer";
import InspectionContainer from "../containers/list/InspectionContainer";
import ScheduleChibaContainer from "../containers/list/ScheduleChibaContainer";
import ScheduleChitaContainer from "../containers/list/ScheduleChitaContainer";
import ScheduleEneosKawasakiContainer from "../containers/list/ScheduleEneosKawasakiContainer";
import AreaContainer from "../containers/master/AreaContainer";
import CategoryContainer from "../containers/master/CategoryContainer";
import CheckpointContainer from "../containers/master/CheckpointContainer";
import ConstructionManagementContainer from "../containers/master/Construction_managementContainer";
import DeviceContainer from "../containers/master/DeviceContainer";
import FacilityManagementContainer from "../containers/master/Facility_managementContainer";
import OtherContainer from "../containers/master/OtherContainer";
import PrimaryChargeContainer from "../containers/master/Primary_chargeContainer";
import ProcessMajorClassContainer from "../containers/master/Process_major_classContainer";
import ProcessMiddleClassContainer from "../containers/master/Process_middle_classContainer";
import MatrixContainer from "../containers/matrix/MatrixContainer";
import QrcodeContainer from "../containers/qrcode/QrcodeContainer";
import SummaryContainer from "../containers/summary/SummaryContainer";
import SystemContainer from "../containers/system/SystemContainer";
import * as util from "../lib/common";

import Toast from "./Toast";

import storageManager from "@/lib/storageManager";
import ScheduleContainer from "@/containers/list/ScheduleContainer";
import { Holiday } from "@/components/list/Holiday";

class Contents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      isNotificationSearchModalOpen: false,
      intervalId: undefined,
    };
  }

  componentDidMount() {
    this.props.contentsReady();
    this.props.fetchConstructions((data) => {
      const constructions = data.constructions;
      const constructionId = util.getConstructionId();

      if (!constructionId) {
        this.props.showConstructionSelector();

        return;
      }

      if (constructions.some((c) => c.construction_id === constructionId)) {
        util.setConstructionId(constructionId);
        this.props.fetchGlobalData();
        this.props.fetchPollingGlobalData();
        const intervalId = window.setInterval(() => {
          this.props.fetchPollingGlobalData();
        }, 30 * 1000);

        this.setState({ ready: true, intervalId });

        return;
      }

      this.props.logout();
    });
    storageManager.setUserItem("system", "progress");
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    if (intervalId !== undefined) {
      clearInterval(intervalId);
    }
  }

  onOpenNotificationSearchModal = () => {
    this.setState({
      ...this.state,
      isNotificationSearchModalOpen: true,
    });
  };

  onCloseNotificationSearchModal = () => {
    this.setState({
      ...this.state,
      isNotificationSearchModalOpen: false,
    });
  };

  render() {
    const { menu, activeMenu, visibleConstructionSelector, visiblePasswordEditor, toastContents, visibleLangSelector } =
      this.props;

    return (
      <React.Fragment>
        {this.state.ready && (
          <React.Fragment>
            {menu === "home" && <HomeContainer />}
            {menu === "dataimport" && <ImportContainer />}
            {menu === "summary" && <SummaryContainer />}
            {menu === "matrix" && <MatrixContainer />}
            {menu === "schedule" && <ScheduleContainer />}
            {menu === "unworkdays" && <Holiday />}
            {menu === "system" && <SystemContainer />}
            {menu === "approval" && <ApprovalContainer />}
            {menu === "inspection" && <InspectionContainer />}
            {menu === "schedule_eneos_kawasaki" && activeMenu && <ScheduleEneosKawasakiContainer />}
            {menu === "schedule_chita" && activeMenu && <ScheduleChitaContainer />}
            {menu === "schedule_chiba" && activeMenu && <ScheduleChibaContainer />}
            {menu === "category" && <CategoryContainer />}
            {menu === "checkpoint" && <CheckpointContainer />}
            {menu === "area" && <AreaContainer />}
            {menu === "device" && <DeviceContainer />}
            {menu === "facility_management" && <FacilityManagementContainer />}
            {menu === "construction_management" && <ConstructionManagementContainer />}
            {menu === "primary_charge" && <PrimaryChargeContainer />}
            {menu === "other" && <OtherContainer />}
            {menu === "process_major_class" && <ProcessMajorClassContainer />}
            {menu === "process_middle_class" && <ProcessMiddleClassContainer />}
            {menu === "qrcode" && <QrcodeContainer />}
            {menu === "asset" && <AssetContainer />}
          </React.Fragment>
        )}
        {visibleConstructionSelector && <ConstructionSelectorContainer onSave={this.props.onSaveConstruction} />}
        {visiblePasswordEditor && <PasswordEditorContainer />}
        {visibleLangSelector && <LangSelectorContainer onSave={this.props.onSaveConstruction} />}
        <Toast contents={toastContents} />
      </React.Fragment>
    );
  }
}

Contents.propTypes = {
  menu: PropTypes.string,
  visibleConstructionSelector: PropTypes.bool.isRequired,
  visiblePasswordEditor: PropTypes.bool.isRequired,
  toastContents: PropTypes.array.isRequired,
  showConstructionSelector: PropTypes.func.isRequired,
  fetchConstructions: PropTypes.func.isRequired,
  fetchGlobalData: PropTypes.func.isRequired,
  onSaveConstruction: PropTypes.func.isRequired,
  contentsReady: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  visibleLangSelector: PropTypes.bool.isRequired,
};

export default Contents;
