import * as constants from "../constants/index";

const initialState = {
  summary: {
    download_job: {
      not_read_num: 0,
    },
    progress_notification: {
      not_read_num: 0,
      not_read_num_by_type: {
        task_complete: 0,
        task_cancel: 0,
      },
    },
    layout_notification: {
      not_read_num: 0,
      not_read_num_by_type: {
        machine_complete: 0,
      },
    },
  },
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case constants.NOTIFICATIONS_END_FETCH_SUMMARY: {
      const { payload } = action;

      return {
        ...state,
        summary: payload,
      };
    }
    default:
      return state;
  }
};

export default notifications;
