import React, { ComponentProps, FC, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import actions from "@/actions";
import { Button } from "@/sx-layout/common/Button";
import { FormError, FormLabel, FormRow, Select } from "@/sx-layout/common/Form";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "@/sx-layout/common/Modal";

export type ConstructionSelector = ComponentProps<typeof Modal>;

export const ConstructionSelector: FC<ConstructionSelector> = ({ onClose }) => {
  const dispatch = useDispatch();

  const construction = useSelector((state) => state.construction);
  const { constructions, customers, places } = construction;

  const [customerId, setCustomerId] = useState(construction.customerId);
  const [placeId, setPlaceId] = useState(construction.placeId);
  const [constructionId, setConstructionId] = useState(construction.constructionId);

  const customerPlaces = useMemo(() => {
    return places.filter((p) => p.customer_id === Number(customerId));
  }, [customerId]);
  const customerConstructions = useMemo(() => {
    return constructions.filter((c) => c.customer_id === Number(customerId) && c.place_id === Number(placeId));
  }, [customerId, placeId]);

  const [isError, setIsError] = useState(false);

  const { control } = useForm({
    defaultValues: { customer_id: customerId, place_id: placeId, construction_id: constructionId },
  });

  const submit = () => {
    if (!customerId || !placeId || !constructionId) {
      setIsError(true);

      return;
    }

    setIsError(false);

    history.replaceState(undefined, undefined, `/#/${constructionId}/home`);
    dispatch(actions.app.changeMenu("home"));
    dispatch(actions.construction.save(constructionId));
    dispatch(actions.plotmap.selectPlotPlan(""));

    onClose();

    location.reload();
  };

  const { t } = useTranslation();

  const handleClose = () => {
    if (!customerId || !placeId || !constructionId) {
      setIsError(true);

      return;
    }

    onClose();
  };

  return (
    <Modal onClose={onClose} shouldCloseOnEsc={false} shouldCloseOnOverlayClick={false}>
      <ModalHeader>{t("switch_construction")}</ModalHeader>

      <ModalBody
        className="flex-col w-500 min-h-[150px] h-full items-start p-0 mt-[10px] mb-[25px] w-[390px]"
        size="small"
      >
        <FormRow className="mt-0">
          <FormLabel className="w-[70px] mr-[10px]">{t("customer_name")}</FormLabel>
          <Select
            className="w-290"
            name="customer_id"
            // @ts-ignore 暫定処置でts-ignoreしている
            control={control}
            handleChange={(value) => setCustomerId(value)}
            options={[...customers.map((c) => ({ value: c.customer_id, label: c.customer_name }))]}
          />
        </FormRow>
        <FormRow>
          <FormLabel className="w-[70px] mr-[10px]">{t("construction_location")}</FormLabel>
          <Select
            className="w-290"
            name="place_id"
            // @ts-ignore 暫定処置でts-ignoreしている
            control={control}
            handleChange={(value) => setPlaceId(value)}
            options={[...customerPlaces.map((c) => ({ label: c.place_name, value: c.place_id }))]}
          />
        </FormRow>
        <FormRow>
          <FormLabel className="w-[70px] mr-[10px]">{t("construction_name")}</FormLabel>
          <Select
            className="w-290"
            name="construction_id"
            // @ts-ignore 暫定処置でts-ignoreしている
            control={control}
            handleChange={(value) => setConstructionId(value)}
            options={customerConstructions.map((cc) => ({
              label: cc.construction_name,
              value: cc.construction_id,
            }))}
          />
        </FormRow>
      </ModalBody>

      {isError && (
        <div className="flex justify-center mt-0 mb-4">
          <FormError marginLeftSize="0">{t("select_all")}</FormError>
        </div>
      )}

      <ModalFooter>
        <Button buttonType="cancel" onClick={handleClose}>
          {t("cancel")}
        </Button>
        <Button onClick={submit}>{t("decision")}</Button>
      </ModalFooter>
    </Modal>
  );
};
