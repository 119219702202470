import { ClassValue } from "clsx";
import React, { ComponentProps, FC } from "react";

import { cn } from "@/lib/classNames";

export type TextButtonProps = Omit<ComponentProps<"button">, "className"> & {
  className?: ClassValue;
};

export const TextButton: FC<TextButtonProps> = ({ children, className, ...restProps }) => {
  return (
    <button className={cn("underline text-[#1c77ff] cursor-pointer", className)} type="button" {...restProps}>
      {children}
    </button>
  );
};
