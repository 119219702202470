import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState } from "@/reducers/types";
import { Machine, MachineStatus, MachineStatusLabel } from "@/sx-layout/components/plotmap/models";
import { MachineType } from "@/sx-layout/components/settings/machineSetting/models";

type Props = {
  filter: "all" | "inOperation" | "waiting";
  onZoomMachine: (_: number) => void;
};

export const MachineList: FC<Props> = ({ filter, onZoomMachine }) => {
  const { t } = useTranslation();
  const machines = useSelector<RootState, Machine[]>((state) => state.plotmap.machines);
  const machineTypes = useSelector<RootState, MachineType[]>((state) => state.machine.machineTypes);

  return (
    <div className="h-[180px] overflow-y-auto mb-10">
      <table className="text-[13px] w-full border-separate" style={{ borderSpacing: 0 }}>
        <thead className="text-center">
          <tr className="sticky top-0 bg-[#ccc] [&>th]:border-solid [&>th]:border-[#888]">
            <th className="text-center border w-[40px]">No</th>
            <th className="text-center border-r border-y">{t("heavy_machine")}</th>
            <th className="text-center border-r border-y w-[40px]">{t("radio")}</th>
            <th className="text-center border-r border-y w-[60px]">{t("jib_extension")}</th>
            <th className="text-center border-r border-y">{t("substitute")}</th>
            <th className="text-center border-r border-y">{t("content")}</th>
            <th className="text-center border-r border-y w-[60px]">{t("machine_operation_start")}</th>
            <th className="text-center border-r border-y w-[60px]">{t("machine_operation_end")}</th>
            <th className="text-center border-r border-y">{t("belonging")}</th>
            <th className="text-center border-r border-y">{t("machine_user")}</th>
            <th className="text-center border-r border-y w-[120px]">{t("contact")}</th>
            <th className="text-center border-r border-y w-[60px]">{t("machine_status")}</th>
            <th className="text-center border-r border-y">{t("note")}</th>
          </tr>
        </thead>
        <tbody className="bg-[#fff]">
          {machines
            .filter(
              (machine) =>
                filter === "all" ||
                (filter === "inOperation" && machine.status === MachineStatus.RUNNING) ||
                (filter === "waiting" && machine.status === MachineStatus.STAND_BY)
            )
            .map((machine) => (
              <tr
                key={machine.machine_id}
                className="[&>td]:border-[#888] [&>td]:px-4 [&>td]:py-2 [&>td]:border-b [&>td]:border-solid"
              >
                <td className="border-x text-center">
                  <a
                    className="text-inherit hover:text-inherit hover:underline cursor-pointer"
                    onClick={() => onZoomMachine(machine.machine_id)}
                  >
                    {machine.no}
                  </a>
                </td>
                <td className="border-r">{machine.machine_type_name}</td>
                <td className="border-r text-center">{machine.wireless_flg === true ? t("use") : t("negation")}</td>
                <td className="border-r text-center">
                  {machine.jib_flg === true ? t("extension") : t("not_extension")}
                </td>
                <td className="border-r">
                  {machineTypes.find((machineType: MachineType) => machineType.machine_type_id === machine.substitute)
                    ?.machine_type_name ?? t("disuse")}
                </td>
                <td className="border-r">
                  {machine.work_content && machine.work_content.length >= 30
                    ? machine.work_content.substring(0, 30) + "..."
                    : machine.work_content}
                </td>
                <td className="border-r text-center">{machine.use_start_hour}</td>
                <td className="border-r text-center">{machine.use_end_hour}</td>
                <td className="border-r">{machine.company_name}</td>
                <td className="border-r">{machine.charge_user_name}</td>
                <td className="border-r">{machine.phone_number}</td>
                <td
                  className={`${
                    machine.status === MachineStatus.STAND_BY && "bg-[#E3844E] text-[#ffffff]"
                  } border-r text-center`}
                >
                  {t(MachineStatusLabel[machine.status])}
                </td>
                <td className="border-r">
                  {machine.note && machine.note.length >= 30 ? machine.note.substring(0, 30) + "..." : machine.note}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
