import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import React, { useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import actions from "@/actions";
import MasterSelect from "@/components/common/Select";
import { UseSearchInputType } from "@/components/list/Schedule/hooks/useSearchInput";
import { SearchConditionOption } from "@/components/list/Schedule/types";
import { NONE_VALUE } from "@/constants";
import { ConstructionMasters } from "@/models/masters";

type Props = {
  scheduleDate: Date;
  input: UseSearchInputType;
  masters: ConstructionMasters;
  searchOptions: SearchConditionOption[];
  onReset: () => void;
  onSearch: () => void;
};

export const SearchBox = ({ scheduleDate, input, masters, searchOptions, onReset, onSearch }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const hazardRankOptions = useMemo(
    () => searchOptions.find((v) => v.target === "field1")?.options ?? [],
    [searchOptions]
  );
  const attendanceOptions = useMemo(
    () => searchOptions.find((v) => v.target === "field2")?.options ?? [],
    [searchOptions]
  );

  const [scheduleDateLabel, setScheduleDateLabel] = useState<string>(moment(scheduleDate).format("YYYY/MM/DD"));

  const handleReset = () => {
    const date = moment().startOf("day").add(1, "day").toDate();
    setScheduleDateLabel(moment(date).format("YYYY/MM/DD"));
    onReset();
  };

  const handleSearch = () => {
    if (!scheduleDateLabel) {
      dispatch(
        actions.app.showAlert(t("error"), [`${t("scheduled_date")}${t("input_required_field")}`], () =>
          dispatch(actions.app.hideAlert())
        )
      );

      return;
    }
    onSearch();
  };

  return (
    <div className="search-box w-900 mb-0">
      <div className="form-row">
        <div className="form-group w-270 scheduled-date">
          <span className="form-label">{t("scheduled_date")}</span>
          <DatePicker
            wrapperClassName="w-170"
            selected={!scheduleDateLabel ? null : moment(scheduleDateLabel, "YYYY/MM/DD").toDate()}
            dateFormat="yyyy/MM/dd"
            locale={t("calender_locale")}
            onChange={(date) => {
              input.setScheduleDate(date);
              setScheduleDateLabel(!date ? null : moment(date).format("YYYY-MM-DD"));
            }}
          />
        </div>
        <div className="form-group w-270">
          <span className="form-label">{t("area")}</span>
          <div className="w-170 d-ib ta-l">
            <MasterSelect
              prefix="area"
              isMulti={true}
              options={masters?.areas ?? []}
              value={input.areaId}
              onChange={(ids) => input.setAreaId(ids)}
            />
          </div>
        </div>
        <div className="form-group w-270">
          <span className="form-label">{t("device")}</span>
          <div className="w-170 d-ib ta-l">
            <MasterSelect
              prefix="device"
              isMulti={true}
              options={masters?.devices ?? []}
              value={input.deviceId}
              onChange={(ids) => input.setDeviceId(ids)}
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group w-270">
          <span className="form-label">{t("machines_category")}</span>
          <div className="w-170 d-ib ta-l">
            <MasterSelect
              prefix="category"
              isMulti={true}
              options={masters?.categories ?? []}
              value={input.categoryId ?? NONE_VALUE}
              onChange={(ids) => input.setCategoryId(ids)}
            />
          </div>
        </div>
        <div className="form-group w-270">
          <span className="form-label">{t("sankyu_manager")}</span>
          <div className="w-170 d-ib ta-l">
            <MasterSelect
              prefix="user"
              isMulti={true}
              options={masters?.users ?? []}
              value={input.managerUserId}
              onChange={(ids) => input.setManagerUserId(ids)}
            />
          </div>
        </div>
        <div className="form-group w-270">
          <span className="form-label">{t("company")}</span>
          <div className="w-170 d-ib ta-l">
            <input
              type="text"
              className="form-control"
              value={input.fieldP1}
              onChange={(e) => input.setFieldP1(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group w-270">
          <span className="form-label">{t("hazard_rank")}</span>
          <div className="w-170 d-ib ta-l">
            <MasterSelect
              isMulti={true}
              options={hazardRankOptions}
              value={input.field1}
              onChange={(ids) => input.setField1(ids)}
              placeholder={<span className="text-black">{"-----"}</span>}
            />
          </div>
        </div>
        <div className="form-group w-270">
          <span className="form-label">{t("attendance")}</span>
          <div className="w-170 d-ib ta-l">
            <MasterSelect
              options={attendanceOptions}
              value={input.field2}
              onChange={(ids) => input.setField2(ids[0])}
              withNone={true}
              noneLabel={"-----"}
              placeholder={<span className="text-black">{"-----"}</span>}
            />
          </div>
        </div>
        <div className="form-group w-320">
          <span className="form-label">{t("unprocessed")}</span>
          <div className="w-220 d-ib ta-l">
            <label className="ckbox">
              <input
                type="checkbox"
                checked={input.approve1Unprocessed}
                onChange={() => input.setApprove1Unprocessed(!input.approve1Unprocessed)}
              />
              <span>
                {t("construction")}
                {t("supervising_section")}
              </span>
            </label>
            <label className="ckbox">
              <input
                type="checkbox"
                checked={input.approve4Unprocessed}
                onChange={() => input.setApprove4Unprocessed(!input.approve4Unprocessed)}
              />
              <span>
                {t("facility")}
                {t("supervising_section")}
              </span>
            </label>
          </div>
        </div>
        <div className="form-group w-320">
          <span className="form-label">{t("overtime_hours")}</span>
          <div className="w-220 d-ib ta-l">
            <label className="ckbox">
              <input
                type="checkbox"
                checked={input.fieldT9 || false}
                onChange={() => input.setFieldT9(!input.fieldT9)}
              />
              <span>{t("registered")}</span>
            </label>
          </div>
        </div>
      </div>
      <div className="form-row mb-0">
        <div className="form-group w-270">
          <span className="form-label">
            {t("construction")}
            {t("supervising_section")}
          </span>
          <div className="w-170 d-ib ta-l">
            <MasterSelect
              prefix="user"
              isMulti={true}
              options={masters?.users ?? []}
              value={input.approve1UserId}
              onChange={(ids) => input.setApprove1UserId(ids)}
            />
          </div>
        </div>
        <div className="form-group w-270">
          <span className="form-label">
            {t("facility")}
            {t("supervising_section")}
          </span>
          <div className="w-170 d-ib ta-l">
            <MasterSelect
              prefix="user"
              isMulti={true}
              options={masters?.users ?? []}
              value={input.approve4UserId}
              onChange={(ids) => input.setApprove4UserId(ids)}
            />
          </div>
        </div>
        <div className="form-group btn-area pull-right mt-0">
          <button data-test-id="button-schedule-reset" className="btn btn-gray" onClick={handleReset}>
            {t("reset")}
          </button>
          <button data-test-id="button-schedule-search" className="btn btn-blue" onClick={() => handleSearch()}>
            {t("search")}
          </button>
        </div>
      </div>
    </div>
  );
};
