import _ from "lodash";

import {
  ScheduleOption,
  ScheduleResult,
  ScheduleSchedule,
  ScheduleScheduleItem,
} from "@/components/list/Schedule/types";
import { ScheduleResultsRequest, ScheduleSchedulesRequest } from "@/components/list/Schedule/types/api";
import * as constants from "@/constants";

export type ScheduleState = {
  // マスタ
  options?: ScheduleOption;
  // 検索条件
  requestForResultList?: ScheduleResultsRequest;
  currentPageOfResultList: number;
  requestForScheduleList?: ScheduleSchedulesRequest;
  currentPageOfScheduleList: number;
  // データ
  results?: ScheduleResult;
  schedules?: ScheduleSchedule;
  // 画面制御
  isLoadingResults: boolean;
  isLoadingSchedules: boolean;
  loadingItemId?: number;
  loadingTarget?: string;
};

export const schedule = (
  state: ScheduleState = {
    options: null,
    currentPageOfResultList: 1,
    currentPageOfScheduleList: 1,
    results: null,
    schedules: null,
    isLoadingResults: false,
    isLoadingSchedules: false,
  },
  action
) => {
  switch (action.type) {
    case constants.SCHEDULES_BEGIN_GET_OPTIONS: {
      return state;
    }
    case constants.SCHEDULES_END_GET_OPTIONS: {
      return {
        ...state,
        options: action.payload,
      };
    }
    case constants.SCHEDULES_BEGIN_GET_RESULTS: {
      return {
        ...state,
        isLoadingResults: true,
        requestForResultList: action.payload.request,
        currentPageOfResultList: action.payload.page,
      };
    }
    case constants.SCHEDULES_END_GET_RESULTS: {
      return {
        ...state,
        results: action.payload,
        isLoadingResults: false,
      };
    }
    case constants.SCHEDULES_BEGIN_GET_SCHEDULES: {
      return {
        ...state,
        isLoadingSchedules: !action.payload.preventRefresh,
        requestForScheduleList: action.payload.request,
        currentPageOfScheduleList: action.payload.page,
      };
    }
    case constants.SCHEDULES_END_GET_SCHEDULES: {
      return {
        ...state,
        schedules: action.payload,
        isLoadingSchedules: false,
      };
    }
    case constants.SCHEDULES_BEGIN_UPDATE_SCHEDULE:
    case constants.SCHEDULES_BEGIN_UPDATE_SCHEDULE_WITH_TASK:
    case constants.SCHEDULES_BEGIN_UPDATE_SCHEDULE_WITH_PROCESS:
    case constants.SCHEDULES_BEGIN_APPROVE_SCHEDULE: {
      return {
        ...state,
        loadingItemId: action.payload?.schedule_task_id,
        loadingTarget: action.payload?.target,
      };
    }
    case constants.SCHEDULES_END_UPDATE_SCHEDULE: {
      const list = updateSchedule(state.schedules.list, action.payload);

      return {
        ...state,
        schedules: {
          ...state.schedules,
          list,
        },
        loadingItemId: undefined,
        loadingTarget: undefined,
      };
    }
    case constants.SCHEDULES_END_UPDATE_SCHEDULE_WITH_TASK: {
      const list = updateSchedule(state.schedules.list, action.payload);

      return {
        ...state,
        schedules: {
          ...state.schedules,
          total_worker_num: action.payload.diff_number_of_workers
            ? state.schedules.total_worker_num + action.payload.diff_number_of_workers
            : state.schedules.total_worker_num,
          list,
        },
        loadingItemId: undefined,
        loadingTarget: undefined,
      };
    }
    case constants.SCHEDULES_END_UPDATE_OVERTIME: {
      return {
        ...state,
        schedules: { ...action.payload, max_overtime: action.payload.max_overtime },
        isLoadingSchedules: false,
      };
    }
    case constants.SCHEDULES_END_UPDATE_SCHEDULE_WITH_PROCESS: {
      return {
        ...state,
        loadingItemId: undefined,
        loadingTarget: undefined,
      };
    }
    case constants.SCHEDULES_END_APPROVE_SCHEDULE: {
      const list = updateSchedule(state.schedules.list, action.payload);

      return {
        ...state,
        schedules: {
          ...state.schedules,
          list,
        },
        loadingItemId: undefined,
        loadingTarget: undefined,
      };
    }
    case constants.SCHEDULES_END_BULK_UPDATE_SCHEDULE: {
      return {
        ...state,
        schedules: {
          ...state.schedules,
          list: bulkUpdateSchedule(state.schedules.list, action.payload),
        },
      };
    }
    case constants.SCHEDULES_END_BULK_UPDATE_SCHEDULE_WITH_TASK: {
      return {
        ...state,
        schedules: {
          ...state.schedules,
          list: bulkUpdateSchedule(state.schedules.list, action.payload),
        },
      };
    }
    case constants.SCHEDULES_END_BULK_UPDATE_SCHEDULE_WITH_PROCESS: {
      return {
        ...state,
        schedules: {
          ...state.schedules,
          list: bulkUpdateSchedule(state.schedules.list, action.payload),
        },
      };
    }
    case constants.SCHEDULES_END_BULK_APPROVE_SCHEDULE: {
      return {
        ...state,
        schedules: {
          ...state.schedules,
          list: bulkUpdateSchedule(state.schedules.list, action.payload),
        },
      };
    }
    case constants.APP_SHOW_ERROR: {
      return {
        ...state,
        isLoadingResults: false,
        isLoadingSchedules: false,
        loadingItemId: undefined,
        loadingTarget: undefined,
      };
    }
    default:
      return state;
  }
};

const updateSchedule = (list: ScheduleScheduleItem[], payload: ScheduleScheduleItem): ScheduleScheduleItem[] => {
  const result = payload;
  const index = list.findIndex((v) => v.schedule_task_id === result.schedule_task_id);
  const l = list.concat();
  if (0 <= index) {
    const target = _.cloneDeep(result);
    l.splice(index, 1, target);
  }

  return l;
};

const bulkUpdateSchedule = (
  list: ScheduleScheduleItem[],
  payload: {
    target: string;
    value: string | number;
    name?: string;
  }
): ScheduleScheduleItem[] => {
  return list.concat().map((target) => {
    target[payload.target] = payload.value;
    if (payload.name !== undefined) {
      target[`${payload.target}_name`] = payload.name;
    }
    target.timestamp.update_date = new Date().toISOString();

    return target;
  });
};
