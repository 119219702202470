import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";

import { LANG_OPTIONS } from "../constants/index";
import styles from "../constants/reactSelectStyle";

import Modal from "./Modal";

import storageManager from "@/lib/storageManager";

class LangSelector extends Component {
  constructor(props) {
    super(props);

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleCancel() {
    this.props.hideLangSelector();
  }

  handleSave() {
    const { constructionId, onSave, saveLang, hideLangSelector } = this.props;

    saveLang();

    const system = storageManager.getUserItem("system");

    if (system !== "layout") {
      history.replaceState(undefined, undefined, `/#/${constructionId}/home`);
    }

    onSave();
    hideLangSelector();
  }

  render() {
    const { lang, changeLang, t } = this.props;

    return (
      <Modal title={t("select_language")} closeHandler={this.handleCancel}>
        <div className="modal-body">
          <div className="w-350 txt-center">
            <div className="w-250 d-ib ta-l">
              <Select
                styles={styles}
                options={LANG_OPTIONS}
                onChange={changeLang}
                value={LANG_OPTIONS.find((option) => option.value === lang)}
                menuPortalTarget={document.querySelector("body")}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            data-test-id="button-construction-cancel"
            type="button"
            className="btn btn-gray"
            onClick={this.handleCancel}
          >
            {t("cancel")}
          </button>
          <button
            data-test-id="button-construction-save"
            type="button"
            className="btn btn-blue"
            onClick={this.handleSave}
          >
            {t("decision")}
          </button>
        </div>
      </Modal>
    );
  }
}

LangSelector.propTypes = {
  lang: PropTypes.string.isRequired,
  changeLang: PropTypes.func.isRequired,
  hideLangSelector: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  constructionId: PropTypes.number.isRequired,
  saveLang: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(LangSelector);
