import React from "react";

import CloseButton from "./CloseButton";
import FilterButton from "./FilterButton";
import Title from "./Title";

type Props = {
  isLoading?: boolean;
  onFilterPress: () => void;
  onRefreshPress: () => void;
  onClosePress: () => void;
};

const DrawerHeader: React.FC<Props> = ({ isLoading, onFilterPress, onRefreshPress, onClosePress }) => {
  return (
    <div id="drawer-header">
      <FilterButton onClick={onFilterPress} />
      <Title isLoading={isLoading} onRefreshPress={onRefreshPress} />
      <CloseButton onClick={onClosePress} />
    </div>
  );
};

export default DrawerHeader;
