import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import * as commonUtil from "../../lib/common";
import Modal from "../Modal";

class PermitTableDownloadChiba extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleDate: this.props.scheduleDateList[0].value || "",
      isDownloading: false,
      downloadName: "",
      downloadUrl: "",
    };

    this.handleChangeScheduleDate = this.handleChangeScheduleDate.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  handleChangeScheduleDate(e) {
    this.setState({
      scheduleDate: e.target.value,
    });
  }

  handleCancel() {
    this.props.closeHandler();
  }

  handleDownload() {
    this.setState({
      isDownloading: true,
    });

    const constId = commonUtil.getConstructionId();
    const userId = commonUtil.getUserId();

    // callbak関数の定義
    const callback = (blob, filename) => {
      const fname = !filename
        ? "schedule_permit_table_" + userId + "_" + constId + "_" + this.state.scheduleDate + ".xlsx"
        : filename;

      this.setState(
        {
          downloadUrl: URL.createObjectURL(blob),
          downloadName: fname,
        },
        () => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fname);
          } else {
            const evt = commonUtil.createClickEvent();
            this.btnDownload.dispatchEvent(evt);
          }

          // 完了
          this.setState({
            isDownloading: false,
          });
          this.props.closeHandler();
        }
      );
    };

    const fallback = () => {
      this.setState({
        isDownloading: false,
      });
    };

    this.props.permitTableDownload(this.state.scheduleDate, callback, fallback);
  }

  render() {
    const { scheduleDateList, closeHandler, t } = this.props;

    return (
      <Modal title={t("permit_table_download")} closeHandler={() => closeHandler()}>
        <div className="modal-body permit_table_download">
          <React.Fragment>
            <div className="form-row">
              <div className="form-group w-280">
                <div>
                  <span className="form-label txt-bold">{t("scheduled_date_shorten")}</span>
                  <select
                    data-test-id="select-schedule-date"
                    className="form-control w-220"
                    onChange={this.handleChangeScheduleDate}
                  >
                    {scheduleDateList.map((item) => (
                      <option key={item.id} value={item.value}>
                        {moment(item.value).format("YYYY/MM/DD")}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
        <div className="modal-footer">
          <button
            data-test-id="button-update-cancel"
            type="button"
            className="btn btn-gray"
            onClick={this.handleCancel}
          >
            {t("cancel")}
          </button>
          <button
            disabled={`${this.state.isDownloading ? "disabled" : ""}`}
            data-test-id="button-download"
            type="button"
            className="btn btn-blue relative"
            onClick={this.handleDownload}
          >
            {t("export")}
            <div className={`${this.state.isDownloading ? "loading-small loading-small-schedule-permit-table" : ""}`} />
          </button>
        </div>
        <a
          className="d-n"
          download={this.state.downloadName}
          href={this.state.downloadUrl}
          ref={(node) => (this.btnDownload = node)}
        >
          download
        </a>
      </Modal>
    );
  }
}

PermitTableDownloadChiba.propTypes = {
  scheduleDateList: PropTypes.array.isRequired,
  closeHandler: PropTypes.func.isRequired,
  permitTableDownload: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(PermitTableDownloadChiba);
