import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";

import actions from "@/actions";
import { SCHEDULE_DATA_PER_PAGE } from "@/components/list/Schedule/constants";
import { SearchInput } from "@/components/list/Schedule/types";
import { ScheduleSchedulesRequest } from "@/components/list/Schedule/types/api";
import { RootState } from "@/reducers/types";

type SearchOption = {
  page?: number;
  isPartialUpdate?: boolean;
  preventRefresh?: boolean;
};

export const usePlanList = (): {
  search: (input: SearchInput, option?: SearchOption) => void;
  prev: () => void;
  next: () => void;
} => {
  const dispatch = useDispatch();

  const { requestForScheduleList, currentPageOfScheduleList } = useSelector((state: RootState) => state.schedule);

  const toRequest = (input: SearchInput): Omit<ScheduleSchedulesRequest, "start" | "limit"> => {
    return {
      schedule_date: moment(input.schedule_date).format("YYYY-MM-DD"),
      category_id: input.category_id,
      area_id: input.area_id,
      device_id: input.device_id,
      manager_user_id: input.manager_user_id,
      field_p1: input.field_p1,
      field1: input.field1,
      field2: input.field2,
      approve1_user_id: input.approve1_user_id,
      approve4_user_id: input.approve4_user_id,
      field_t9: input.field_t9,
      filter: [input.approve1_unprocessed ? 1 : null, input.approve4_unprocessed ? 2 : null].filter((v) => v),
    };
  };

  const search = (input: SearchInput, option?: SearchOption) => {
    const p = option?.page ?? 1;

    if (option?.isPartialUpdate) {
      dispatch(
        actions.schedule.getAndUpdateScheduleOvertime({
          ...toRequest(input),
          start: (p - 1) * SCHEDULE_DATA_PER_PAGE + 1,
          limit: SCHEDULE_DATA_PER_PAGE,
        })
      );
    } else {
      dispatch(
        actions.schedule.getScheduleSchedules(
          {
            ...toRequest(input),
            start: (p - 1) * SCHEDULE_DATA_PER_PAGE + 1,
            limit: SCHEDULE_DATA_PER_PAGE,
          },
          p,
          option?.preventRefresh
        )
      );
    }
  };

  const prev = () => {
    const p = currentPageOfScheduleList - 1;
    dispatch(
      actions.schedule.getScheduleSchedules(
        {
          ...requestForScheduleList,
          start: (p - 1) * SCHEDULE_DATA_PER_PAGE + 1,
        },
        p
      )
    );
  };

  const next = () => {
    const p = currentPageOfScheduleList + 1;
    dispatch(
      actions.schedule.getScheduleSchedules(
        {
          ...requestForScheduleList,
          start: (p - 1) * SCHEDULE_DATA_PER_PAGE + 1,
        },
        p
      )
    );
  };

  return { search, prev, next };
};
