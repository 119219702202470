import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

const PasswordWarning = (props) => {
  const { t } = props;

  return (
    <div className="modal">
      <div className="modal-dialog">
        <div className="modal-content" id={"test"}>
          <div className="modal-header">
            <h3 className="modal-ttl">{t("alert")}</h3>
            <div className="btn-close" onClick={props.onClose}>
              <span className="icon icon-close"></span>
            </div>
          </div>
          <div className="modal-body">
            <p className="form-row w-300 ta-c" style={{ lineHeight: 1.75 }}>
              {t("password_expiry_date")}
              <br />
              {t("expiry_date")}：{props.expireDate}
            </p>
          </div>
          <div className="modal-footer">
            <button data-test-id="button-confirm-cancel" type="button" className="btn btn-gray" onClick={props.onClose}>
              {t("close")}
            </button>
            <button
              data-test-id="button-confirm-ok"
              type="button"
              className="btn btn-blue"
              onClick={props.onChangePasswordClick}
            >
              {t("changing_password")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

PasswordWarning.propTypes = {
  expireDate: PropTypes.string.isRequired,
  onChangePasswordClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withTranslation()(PasswordWarning);
