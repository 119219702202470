import { connect } from "react-redux";

import actions from "../../actions";
import System from "../../components/system/System";

const mapStateToProps = (state) => {
  let areas = [];
  let devices = [];
  let categories = [];
  let facilityManagements = [];
  let constructionManagements = [];
  let primaryCharges = [];
  let others = [];

  if (state.construction.masters) {
    areas = state.construction.masters.areas;
    devices = state.construction.masters.devices;
    categories = state.construction.masters.categories;
    facilityManagements = state.construction.masters.facility_managements;
    constructionManagements = state.construction.masters.construction_managements;
    primaryCharges = state.construction.masters.primary_charges;
    others = state.construction.masters.others;
  }

  return {
    masters: {
      areas,
      devices,
      categories,
      facilityManagements,
      constructionManagements,
      primaryCharges,
      others,
    },
    areaIds: state.system.areaIds,
    deviceIds: state.system.deviceIds,
    categoryId: state.system.categoryId,
    facilityManagementIds: state.system.facilityManagementIds,
    constructionManagementIds: state.system.constructionManagementIds,
    primaryChargeIds: state.system.primaryChargeIds,
    otherIds: state.system.otherIds,
    systemName: state.system.systemName,
    note: state.system.note,
    scheStartDate: state.system.scheStartDate,
    scheEndDate: state.system.scheEndDate,
    closeStartDate: state.system.closeStartDate,
    closeEndDate: state.system.closeEndDate,
    totalTest: state.system.totalTest,
    preparation: state.system.preparation,
    masterItems: state.system.masterItems,
    items: state.system.items,
    detailItems: state.system.detailItems,
    editItem: state.system.editItem,
    current: state.system.current,
    fetching: state.system.fetching,
    validated: state.system.validated,
    downloading: state.system.downloading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchItems: () => dispatch(actions.system.fetchItems()),
    search: (params, callback) => dispatch(actions.system.search(params, callback)),
    searchItems: (params, callback) => dispatch(actions.system.searchItems(params, callback)),
    errors: (params, callback) => dispatch(actions.system.exportErrorFile(params, callback)),
    fetchItem: (id, callback) => dispatch(actions.system.fetchItem(id, callback)),
    resetSearch: () => dispatch(actions.system.resetSearch()),
    updateItem: (data, handleSuccess, handleCancel) =>
      dispatch(actions.system.updateItem(data, handleSuccess, handleCancel)),
    downloadFiles: (kind, fileIds, filetype, callback, fallback) =>
      dispatch(actions.common.downloadSystemFiles(kind, fileIds, filetype, callback, fallback)),
    changeArea: (items) => dispatch(actions.system.changeArea(items)),
    changeDevice: (items) => dispatch(actions.system.changeDevice(items)),
    changeCategory: (item) => dispatch(actions.system.changeCategory(item)),
    changeFacilityManagement: (items) => dispatch(actions.system.changeFacilityManagement(items)),
    changeConstructionManagement: (items) => dispatch(actions.system.changeConstructionManagement(items)),
    changePrimaryCharge: (items) => dispatch(actions.system.changePrimaryCharge(items)),
    changeOther: (items) => dispatch(actions.system.changeOther(items)),
    changeSystemName: (systemName) => dispatch(actions.system.changeSystemName(systemName)),
    changeNote: (note) => dispatch(actions.system.changeNote(note)),
    changeScheduledDateFrom: (dateFrom) => dispatch(actions.system.changeScheduledDateFrom(dateFrom)),
    changeScheduledDateTo: (dateTo) => dispatch(actions.system.changeScheduledDateTo(dateTo)),
    changeCloseDateFrom: (dateFrom) => dispatch(actions.system.changeCloseDateFrom(dateFrom)),
    changeCloseDateTo: (dateTo) => dispatch(actions.system.changeCloseDateTo(dateTo)),
    changeTotalTest: (item) => dispatch(actions.system.changeTotalTest(item)),
    changePreparation: (item) => dispatch(actions.system.changePreparation(item)),
    revertLocalCondition: () => dispatch(actions.system.revertLocalCondition()),
    toggleDownloading: (downloading) => dispatch(actions.system.toggleDownloading(downloading)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(System);
