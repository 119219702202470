export const compareInazumaCell = (c1, c2) => {
  return (
    c1.category_id === c2.category_id &&
    c1.area_id === c2.area_id &&
    c1.device_id === c2.device_id &&
    c1.facility_management_id === c2.facility_management_id &&
    c1.construction_management_id === c2.construction_management_id &&
    c1.primary_charge_id === c2.primary_charge_id
  );
};
