import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";

import styles from "../../constants/reactSelectStyle";
import Modal from "../Modal";

class ScheduleUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryId: this.props.categories[0].category_id || 0,
      scheduleDate: this.props.scheduleDateList[0].value || "",
      loading: false,
    };

    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.handleChangeScheduleDate = this.handleChangeScheduleDate.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.props.setUnloadAlert();
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();
  }

  handleChangeCategory(e) {
    this.setState({
      categoryId: e.target.value,
    });
  }

  handleChangeScheduleDate(e) {
    this.setState({
      scheduleDate: e.target.value,
    });
  }

  handleSave() {
    const data = { ...this.state };
    const { t } = this.props;

    data.loading = true;
    this.setState(data);
    this.props.createSchedule(
      data,
      (res) => {
        this.setState({ loading: false });
        this.props.closeHandler();
        this.props.showAlert(
          t("confirmation"),
          [t("updated_schedule") + "（" + res.count + t("unit") + "）"],
          this.props.hideAlert
        );
      },
      () => {
        this.setState({ loading: false });
      }
    );
  }

  render() {
    const { categories, scheduleDateList, closeHandler, t } = this.props;
    const { loading } = this.state;

    return (
      <Modal title={t("schedule_update")} closeHandler={() => closeHandler()}>
        <div className="modal-body schedule-update">
          <div className="form-row">
            <div className="form-group w-280">
              <div>
                <span className="form-label txt-bold">{t("machines_category")}</span>
                <select
                  data-test-id="text-import-file-type"
                  className="form-control w-220"
                  onChange={this.handleChangeCategory}
                >
                  {categories.map((category) => (
                    <option key={category.category_id} value={category.category_id}>
                      {category.category_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form-row mb-5">
            <div className="form-group w-280">
              <div>
                <span className="form-label txt-bold">{t("scheduled_date_shorten")}</span>
                <select
                  data-test-id="select-schedule-date"
                  className="form-control w-220"
                  onChange={this.handleChangeScheduleDate}
                >
                  {scheduleDateList.map((item) => (
                    <option key={item.id} value={item.value}>
                      {moment(item.value).format("YYYY/MM/DD")}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className={`modal-footer ${loading ? "loading loading--dialog" : ""}`}>
          <button
            data-test-id="button-column-editor-cancel"
            type="button"
            className="btn btn-gray"
            onClick={() => closeHandler()}
          >
            {t("cancel")}
          </button>
          <button
            data-test-id="button-column-editor-save"
            type="button"
            className="btn btn-blue"
            onClick={this.handleSave}
          >
            {t("update")}
          </button>
        </div>
      </Modal>
    );
  }
}

ScheduleUpdate.propTypes = {
  scheduleDateList: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  closeHandler: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ScheduleUpdate);
