import React from "react";

import CancelIcon from "../../../icons/CancelIcon";

type Props = {
  onClick: () => void;
};

const CloseButton: React.FC<Props> = ({ onClick }) => {
  return (
    <div id="close-button" className="clickable" onClick={onClick}>
      <CancelIcon />
    </div>
  );
};

export default CloseButton;
