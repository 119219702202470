import { ClassValue } from "clsx";
import { ComponentProps, FC } from "react";

import { cn } from "@/lib/classNames";

export type TrProps = Omit<ComponentProps<"tr">, "className"> & {
  className?: ClassValue;
};

export const Tr: FC<TrProps> = ({ children, className, ...restProps }) => {
  return (
    <tr className={cn("", className)} {...restProps}>
      {children}
    </tr>
  );
};
