import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { isValid } from "../../lib/roleChecker";
import FileItem from "../FileItem";
import Modal from "../Modal";

class InformationViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      deleting: false,
      data: null,
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated -- 暫定処置でdisableしている
  componentWillReceiveProps(nextProps) {
    const data = nextProps.data;

    if (data) {
      this.setState({ data });
    }
  }

  handleDelete() {
    if (this.state.data === null) {
      return;
    }

    const id = this.state.data.information_id;
    this.setState({ deleting: true });
    this.props.deleteItem(id, this.props.closeHandler, () => this.setState({ deleting: false }));
  }

  render() {
    const { closeHandler, download, t } = this.props;
    let subject = "";
    let text = "";
    let date = "";
    let files = [];
    let insertUserName = "";
    let canDelete = false;

    if (this.state.data) {
      subject = this.state.data.subject;
      text = this.state.data.text;
      date = moment(this.state.data.timestamp.insert_date).format("YYYY/MM/DD HH:mm");
      files = this.state.data.files;
      insertUserName = this.state.data.timestamp.insert_user_name;
      canDelete = isValid(this.state.data.roles, "__self__", "delete");
    }

    return (
      <Modal title={t("board_detail")} closeHandler={() => closeHandler()} loading={this.props.fetching}>
        <div className="modal-body w-710 clearfix">
          <div className="modal-body-left w-360">
            <div className="form-row">
              <div className="form-group w-350">
                <span className="form-label txt-bold">{t("board_subject_shorten")}</span>
                <div className="form-txt w-280">{subject}</div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group w-350">
                <span className="form-label txt-bold">{t("body")}</span>
                <div className="form-txt w-280">{text}</div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group w-350">
                <span className="form-label txt-bold">{t("created_by")}</span>
                <div className="form-txt w-280">{insertUserName}</div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group w-350">
                <span className="form-label txt-bold">{t("created_at")}</span>
                <div className="form-txt w-280">{date}</div>
              </div>
            </div>
          </div>
          <div className="modal-body-right w-290">
            <div className="form-row">
              <div className="form-group">
                <span className="form-label txt-bold">{t("upload_file")}</span>
              </div>
            </div>
            <div className="file-box">
              <ul className="form-file-list">
                {files.map((file, index) => (
                  <FileItem
                    key={index}
                    file={file}
                    kind="information"
                    qrFlag={false}
                    onDownload={download}
                    additionalFilenameClass="mw-210"
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={`modal-footer ${this.state.deleting ? "loading loading--dialog" : ""}`}>
          <button
            data-test-id="button-information-close"
            type="button"
            className="btn btn-gray"
            onClick={() => closeHandler()}
          >
            {t("close")}
          </button>
          {canDelete && (
            <button
              data-test-id="button-information-delete"
              type="button"
              className="btn btn-blue"
              onClick={this.handleDelete}
            >
              {t("delete")}
            </button>
          )}
        </div>
      </Modal>
    );
  }
}

InformationViewer.defaultProps = {
  data: null,
};

InformationViewer.propTypes = {
  data: PropTypes.object,
  fetching: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  download: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(InformationViewer);
