import { FC, useCallback, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import actions from "@/actions";
import { getConstructionId } from "@/lib/common";
import { RootState } from "@/reducers/types";
import { Button } from "@/sx-layout/common/Button";
import { Checkbox } from "@/sx-layout/common/Form";
import { Header } from "@/sx-layout/common/Header";
import { MachineIcon } from "@/sx-layout/common/MachineIcon";
import { ConfirmModal } from "@/sx-layout/common/Modal";
import { MachineType } from "@/sx-layout/components/settings/machineSetting/models";

type MachineRHFValues = {
  machine_type_ids: number[];
};

export const MachineSetting: FC = () => {
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);

  const { reset, control, handleSubmit, setValue, watch } = useForm<MachineRHFValues>({
    defaultValues: {
      machine_type_ids: [],
    },
  });

  const { machine_type_ids } = watch();
  const dispatch = useDispatch();

  const constructionId = getConstructionId();

  useEffect(() => {
    if (constructionId) {
      dispatch(actions.machine.fetchMachineTypes());
    }
  }, []);

  const fetching = useSelector<RootState, boolean>((state) => state.machine.fetching);
  const machineTypes = useSelector<RootState, MachineType[]>((state) => state.machine.machineTypes);
  const companyMachineTypeIds = useSelector<RootState, number[]>((state) => state.machine.companyMachineTypeIds);

  useEffect(() => {
    if (!fetching) {
      reset({ machine_type_ids: companyMachineTypeIds });
    }
  }, [fetching, companyMachineTypeIds]);

  const handleChange = (value) => {
    if ((machine_type_ids || []).includes(value)) {
      const newIDs = machine_type_ids.filter((id) => id != value);
      setValue("machine_type_ids", newIDs);
    } else {
      setValue("machine_type_ids", [...machine_type_ids, value]);
    }
  };

  const onSubmit = useCallback(({ ...restValues }: MachineRHFValues) => {
    dispatch(
      actions.machine.updateCompaniesMachineTypes({
        input: { ...restValues },
        onSuccess: () => {
          setIsOpenConfirmModal(true);
          dispatch(actions.layoutMasters.fetchLayoutMasters());
          dispatch(actions.machine.fetchCompaniesMachineTypes());
        },
      })
    );
  }, []);

  const { t } = useTranslation();

  return (
    <div className="bg-[#f3f2ef] min-h-screen">
      <Header />
      <div className="px-[32px]">
        <div className="flex items-center space-x-8">
          <h2 className="my-[16px] font-bold text-[16px]">{t("heavy_machine_settings")}</h2>
        </div>
        <h3 className="my-[14px] font-bold text-[14px]">{t("heavy_machine_for_use")}</h3>
        <div className="bg-[#fff] px-10 py-5 w-[1000px]">
          {machineTypes.map((machineType, index) => {
            return (
              <label key={index} className={`inline-flex items-center space-x-5 cursor-pointer my-3 w-[250px]`}>
                <Controller
                  control={control}
                  name="machine_type_ids"
                  render={() => (
                    <Checkbox
                      id={`checkbox-${machineType.machine_type_id}`}
                      value={machineType.machine_type_id}
                      onChange={() => handleChange(machineType.machine_type_id)}
                      checked={(machine_type_ids || []).includes(machineType.machine_type_id)}
                    />
                  )}
                />
                <MachineIcon
                  machineIconId={machineType.machine_icon_id}
                  active={true}
                  selected={false}
                  outline={false}
                  shapeColor="#000"
                  className="w-[50px] h-[50px]"
                />
                <div className="text-[14px]">{machineType.machine_type_name}</div>
              </label>
            );
          })}
        </div>
        <Button className="mt-20" onClick={handleSubmit(onSubmit)}>
          {t("save")}
        </Button>
      </div>
      {isOpenConfirmModal && (
        <ConfirmModal
          title={t("confirmation")}
          message={t("save_done")}
          onClose={() => {
            setIsOpenConfirmModal(false);
          }}
        />
      )}
    </div>
  );
};
