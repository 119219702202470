import { ClassValue } from "clsx";
import { ComponentProps, FC } from "react";

import { cn } from "@/lib/classNames";

export type TheadProps = Omit<ComponentProps<"thead">, "className"> & {
  className?: ClassValue;
};

export const Thead: FC<TheadProps> = ({ children, className, ...restProps }) => {
  return (
    <thead className={cn("bg-[#ccc] text-center border border-collapse border-[#888]", className)} {...restProps}>
      {children}
    </thead>
  );
};
