import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Modal from "../Modal";

import actions from "@/actions";
import { SubmitButton } from "@/components/common/SubmitButton";
import { ToggleButtonGroup } from "@/components/common/ToggleButtonGroup";
import { EditSegmentLabelModal } from "@/components/matrix/EditSegmentLabelModal";
import { Process, ProcessSegmentLabel } from "@/models/matrix";
import clsx from "clsx";

type Props = {
  processId: number;
  categoryId: number;
  primaryChargeId: number;
  onClose: () => void;
};

export const SegmentSettingModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const [process, setProcess] = useState<Process>();
  const [segment, setSegment] = useState<[string, string, string]>(null);
  const [segmentLabels, setSegmentLabels] = useState<ProcessSegmentLabel[]>([]);
  const [openAddGroupModal, setOpenAddGroupModal] = useState<boolean>(false);
  const [segmentLabelToEdit, setSegmentLabelToEdit] = useState<ProcessSegmentLabel>(null);
  const [hoverLabel, setHoverLabel] = useState<number>();

  useEffect(() => {
    dispatch(
      actions.matrix.fetchProcess(props.processId, (res: Process) => {
        setProcess(res);
      })
    );
    dispatch(
      actions.matrix.fetchProcessSegment(props.processId, (res) => {
        const seg = res.segment ?? "";
        setSegment([seg.charAt(0), seg.charAt(1), seg.charAt(2)]);
      })
    );
    dispatch(
      actions.matrix.fetchSegmentLabels(
        {
          category_id: props.categoryId,
          primary_charge_id: props.primaryChargeId,
        },
        (res) => setSegmentLabels(res)
      )
    );
  }, [props.processId]);

  const handleChangeSegment = (i: number, v: string) => {
    const seg = segment.concat() as [string, string, string];
    seg[i] = v;
    setSegment(seg);
  };

  const handleEditSegmentLabel = (value: ProcessSegmentLabel) => {
    const labels = segmentLabels.concat();
    const index = labels.findIndex((l) => l.sort === value.sort);
    if (0 <= index) {
      labels.splice(index, 1, value);
      setSegmentLabels(labels);
    } else {
      setSegmentLabels([...labels, value]);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    dispatch(
      actions.matrix.updateProcessSegment(
        props.processId,
        { segment: segment.join("") },
        () => {
          setLoading(false);
          props.onClose();
        },
        () => setLoading(false)
      )
    );
  };

  const handleLabelEnter = (i: number) => {
    setHoverLabel(i);
  };
  const handleLabelLeave = () => {
    setHoverLabel(null);
  };

  if (!process || !segment) return <></>;

  return (
    <>
      <Modal title={`${t("split_schedule")}${t("settings")} : [${process.process_name}]`} closeHandler={props.onClose}>
        <div className="modal-body min-w-[400px]">
          <p className="form-text">
            {t("segment_group")}
            {segmentLabels.length < 3 && (
              <button type="button" onClick={() => setOpenAddGroupModal(true)} className="mx-2 cursor-pointer">
                <img className="w-[18px] h-[18px]" src="./img/icon-add-black.svg" alt="plus" />
              </button>
            )}
          </p>
          {segmentLabels.map((label, i) => (
            <div
              key={i}
              className="form-row flex items-center justify-center min-h-[56px] bg-white p-[3px]"
              style={{ marginBottom: i === segmentLabels.length - 1 ? "0px" : "12px", border: "1px solid #ccc" }}
            >
              <div className="m-[10px]">
                <ToggleButtonGroup
                  value={segment[i]}
                  options={[
                    { label: "ON", value: "1" },
                    { label: "OFF", value: "0" },
                  ]}
                  onChange={(v) => handleChangeSegment(i, v)}
                  optionWidth="50px"
                />
              </div>
              <div
                className="flex grow items-center h-[100%] px-[10px] rounded-[5px] cursor-pointer"
                onClick={() => setSegmentLabelToEdit(label)}
                onMouseEnter={() => handleLabelEnter(i)}
                onMouseLeave={handleLabelLeave}
              >
                <div className="max-w-[200px]">{label.segment_label_name}</div>
                <button
                  type="button"
                  className={clsx("min-w-[16px] ml-[5px] p-[5px]", i === hoverLabel ? "" : "invisible")}
                >
                  <img className="w-[16px] h-[16px]" src="./img/icon-edit-black.svg" />
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="modal-footer">
          <button
            data-test-id="button-column-editor-cancel"
            type="button"
            className="btn btn-gray"
            onClick={props.onClose}
          >
            {t("cancel")}
          </button>
          <SubmitButton data-test-id="button-column-editor-save" onClick={handleSubmit} loading={loading} />
        </div>
      </Modal>
      {(openAddGroupModal || segmentLabelToEdit) && (
        <EditSegmentLabelModal
          categoryId={props.categoryId}
          primaryChargeId={props.primaryChargeId}
          numberOfSegmentLabels={segmentLabels.length}
          segmentLabel={segmentLabelToEdit}
          onUpdate={handleEditSegmentLabel}
          onClose={() => {
            setOpenAddGroupModal(false);
            setSegmentLabelToEdit(null);
          }}
        />
      )}
    </>
  );
};
