import * as constants from "@/constants";
import { PlotPlan } from "@/sx-layout/components/settings/plotPlanSetting/models";

export type PlotPlanState = {
  plotPlans: PlotPlan[];
  fetching: boolean;
};

export const plotPlan = (
  state = {
    plotPlans: [],
    fetching: false,
  },
  action
) => {
  switch (action.type) {
    case constants.PLOT_PLAN_BEGIN_FETCH_PLOT_PLANS: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.PLOT_PLAN_END_FETCH_PLOT_PLANS: {
      return {
        ...state,
        plotPlans: action.payload,
        fetching: false,
      };
    }
    case constants.APP_SHOW_ERROR: {
      return {
        ...state,
        fetching: false,
      };
    }
    default:
      return state;
  }
};
