import { connect } from "react-redux";

import actions from "../../actions/index";
import Home from "../../components/home/Home";

const mapStateToProps = (state) => {
  return {
    menu: state.app.menu,
    menus: state.app.menus,
    roles: state.app.roles,
    informations: state.information.items,
    currentInformation: state.information.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeMenu: (menu) => dispatch(actions.app.changeMenu(menu)),
    fetchInformations: (params, callback) => dispatch(actions.information.fetchItems(params, callback)),
    fetchInformation: (informationId, callback) => dispatch(actions.information.fetchItem(informationId, callback)),
    resetInformation: () => dispatch(actions.information.resetItems()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
