import PropTypes from "prop-types";
import React, { Component } from "react";
import Carousel, { Modal as Lightbox, ModalGateway } from "react-images";

import * as util from "../../lib/common";

class FileItemAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLightbox: false,
      objectUrl: "",
    };

    this.handleCloseLightbox = this.handleCloseLightbox.bind(this);
    this.handleOpenLightbox = this.handleOpenLightbox.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  handleOpenLightbox() {
    this.setState({
      showLightbox: true,
    });
  }

  handleCloseLightbox() {
    this.setState({ showLightbox: false });
  }

  handleDownload(showModal = false, qr = false) {
    const { kind, fileId, fileName, onDownload } = this.props;

    onDownload(kind, fileId, false, (blob) => {
      const objectUrl = URL.createObjectURL(blob);
      this.setState({ objectUrl }, () => {
        if (showModal) {
          this.handleOpenLightbox();
        } else {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else {
            const evt = util.createClickEvent();
            this.btnDownload.dispatchEvent(evt);
          }
        }
      });
    });
  }

  render() {
    const { fileName, format } = this.props;

    return (
      <React.Fragment>
        <td className="w-400">
          <a className="d-n" download={fileName} href={this.state.objectUrl} ref={(node) => (this.btnDownload = node)}>
            download
          </a>
          <React.Fragment>
            {format === "jpg" || format === "png" || format === "jpeg" ? (
              <span className="">
                <a onClick={() => this.handleDownload(true)}>{fileName}</a>
              </span>
            ) : (
              <span className="">
                <a onClick={() => this.handleDownload(false)}>{fileName}</a>
              </span>
            )}
          </React.Fragment>
        </td>
        <ModalGateway>
          {this.state.showLightbox ? (
            <Lightbox
              onClose={this.handleCloseLightbox}
              styles={{
                blanket: (base, state) => ({ ...base, zIndex: 99999 }),
                positioner: (base, state) => ({ ...base, zIndex: 99999 }),
                dialog: (base, state) => ({ ...base, zIndex: 100000 }),
              }}
            >
              <Carousel
                views={[{ src: this.state.objectUrl }]}
                styles={{
                  footerCount: (base, state) => ({ ...base, display: "none" }),
                }}
              />
            </Lightbox>
          ) : null}
        </ModalGateway>
      </React.Fragment>
    );
  }
}

FileItemAsset.proptypes = {
  fileId: PropTypes.number.isRequired,
  fileName: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  onDownload: PropTypes.func.isRequired,
};

export default FileItemAsset;
