import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../Modal";

import actions from "@/actions";
import SelectBase from "@/components/common/Select";
import { SubmitButton } from "@/components/common/SubmitButton";
import { MatrixItem } from "@/models/matrix";
import { RootState } from "@/reducers/types";

type Props = {
  itemId: number;
  onClose: () => void;
  onCompleted: () => void;
};

const SortEditor: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const searchParams = useSelector((state: RootState) => state.matrixSearch);

  const [itemName, setItemName] = useState("");
  const [isSortByName, setSortByName] = useState(true);
  const [items, setItems] = useState<MatrixItem[]>([]);
  const [targetItemId, setTargetItemId] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    dispatch(
      actions.matrix.fetchItems(searchParams, (response: MatrixItem[]) => {
        const index = response.findIndex((v) => v.item_id === props.itemId);
        setItemName(response[index]?.item_name);
        setItems(response.filter((_, i) => i !== index && i !== index - 1));
      })
    );
  }, []);

  useEffect(() => {
    if (!isSortByName) {
      setError(false);
    }
  }, [isSortByName]);

  const handleSubmit = () => {
    if (isSortByName && !targetItemId) {
      setError(true);
      return;
    }
    setError(false);
    setLoading(true);
    dispatch(
      actions.matrix.sortItem(
        props.itemId,
        isSortByName ? targetItemId : 0,
        searchParams,
        () => {
          setLoading(false);
          props.onCompleted();
        },
        () => setLoading(false)
      )
    );
  };

  return (
    <Modal
      title={`${t("title")}${t("sort_row")}${itemName ? "：[" + itemName + "]" : ""}`}
      closeHandler={() => props.onClose()}
    >
      <div className="modal-body" style={{ padding: "20px 60px" }}>
        <div className="form-row mb-5">
          <div className="form-group w-360 ta-u">
            <span className="form-label txt-bold">{t("sort_order")}</span>
            <label data-test-id="checkbox-sort-by-name" className="align-middle ml-5 mr-15">
              <input type="radio" className="mr-3" checked={isSortByName} onChange={() => setSortByName(true)} />
              <span>{t("sort_by_name")}</span>
            </label>
            <label data-test-id="checkbox-sort-top" className="align-middle">
              <input type="radio" className="mr-3" checked={!isSortByName} onChange={() => setSortByName(false)} />
              <span>{t("sort_top")}</span>
            </label>
          </div>
        </div>
        <div className={clsx("form-row mb-5", isSortByName ? "" : "invisible")}>
          <div className="form-group w-360 ta-u">
            <div className="d-ib ta-l w-270 ml-50 mr-3">
              <SelectBase
                prefix="item"
                options={items}
                value={targetItemId}
                onChange={(id) => setTargetItemId(id)}
                disabled={!isSortByName}
              />
            </div>
            {t("sort_under")}
          </div>
          {error && (
            <div className="form-error ml-50" style={{ textAlign: "left" }}>
              <p className="form-error-message">{t("sort_form_error")}</p>
            </div>
          )}
        </div>
      </div>
      <div className="modal-footer">
        <button
          data-test-id="button-column-editor-cancel"
          type="button"
          className="btn btn-gray"
          onClick={props.onClose}
        >
          {t("cancel")}
        </button>
        <SubmitButton data-test-id="button-column-editor-save" onClick={handleSubmit} loading={loading}>
          {t("save")}
        </SubmitButton>
      </div>
    </Modal>
  );
};

export default withTranslation()(SortEditor);
