import { ClassValue } from "clsx";
import { ComponentProps, forwardRef, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { cn } from "@/lib/classNames";

export type FileUploaderProps = Omit<ComponentProps<"input">, "className"> & {
  className?: ClassValue;
};

export const FileUploader = forwardRef<HTMLInputElement, FileUploaderProps>(
  ({ className, disabled, ...restProps }, ref) => {
    const { t } = useTranslation();
    const children: ReactNode = restProps.children ?? t("browze_file");

    return (
      <label
        className={cn(
          "bg-[#dea321] text-white text-[13px] font-bold rounded min-w-[150px] px-[12px] py-[8px] cursor-pointer text-center",
          disabled && "bg-[#d1d1d1] text-[#8f8f8f] cursor-default",
          className
        )}
      >
        <span>{children}</span>
        <input ref={ref} type="file" className="hidden" disabled={disabled} {...restProps} />
      </label>
    );
  }
);

FileUploader.displayName = "FileUploader";
