import PropTypes from "prop-types";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { withTranslation } from "react-i18next";

import SystemEditorContainer from "../../containers/system/SystemEditorContainer";
import SystemViewerContainer from "../../containers/system/SystemViewerContainer";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import styles from "../../constants/reactSelectStyle";
import { addHeightResizeListener, removeHeightResizeListener, getTableBodyHeight } from "../../lib/common";
import * as util from "../../lib/common";
import restoreState from "../../lib/restoreState";
import { isValid } from "../../lib/roleChecker";
import storageManager from "../../lib/storageManager";
import Select from "../common/Select";
import clsx from "clsx";

class System extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch: true,
      showTable: false,
      showDetail: false,
      showEditor: false,
      currentRowIndex: -1,
      limit: 20,
      start: 1,
      end: false,
      isEmpty: true,
      editingItem: null,
      packSystemIds: [],
      systemId: 0,
      systemName: "",
      error: {},
      tableBodyMaxHeight: window.innerHeight - 420,
      objectUrl: "",
      downloadName: "",
      downloading: false,
      currentPage: 1,
      maxPage: 0,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleDetailSearch = this.handleDetailSearch.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSearchBoxHeightChange = this.handleSearchBoxHeightChange.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.fillList = this.fillList.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  componentDidMount() {
    this.handleSearchBoxHeightChange();
    addHeightResizeListener(this.searchBox, this.handleSearchBoxHeightChange);

    this.resizeTimer = 0;
    window.addEventListener("resize", this.handleResize);

    const urlState = restoreState();
    const { validated } = this.props;
    if (
      validated &&
      (storageManager.getConstructionItem("systemSearchParams") !== null || (urlState && urlState.hasQuery))
    ) {
      this.handleSearch();
    }
  }

  componentDidUpdate(prevProps) {
    const { validated } = this.props;
    if (!validated) {
      return;
    }

    const urlState = restoreState();
    const notFirst =
      storageManager.getConstructionItem("systemSearchParams") !== null || (urlState && urlState.hasQuery);
    if (!prevProps.validated && validated && notFirst) {
      this.handleSearch();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    removeHeightResizeListener(this.searchBox);

    this.props.revertLocalCondition();
  }

  handleSearchBoxHeightChange() {
    const maxHeight = getTableBodyHeight("system", this.searchBox, this.theader);
    this.setState({ tableBodyMaxHeight: maxHeight });
  }

  handleResize() {
    if (this.resizeTimer > 0) {
      clearTimeout(this.resizeTimer);
    }

    const callback = () => {
      this.handleSearchBoxHeightChange();
      this.fillList();
    };

    this.resizeTimer = setTimeout(callback, 200);
  }

  showDropdown(name) {
    this.setState({
      ...this.state,
      activeButton: name,
    });
  }

  toggleSearch() {
    const callback = () => {
      this.handleSearchBoxHeightChange();
      addHeightResizeListener(this.searchBox, this.handleSearchBoxHeightChange);
      this.fillList();
    };
    this.setState({ showSearch: !this.state.showSearch }, () => setTimeout(callback, 100));
  }

  hideDropdown() {
    this.setState({ ...this.state, activeButton: "" });
  }

  showTable(callback) {
    this.setState({ showTable: true }, () => {
      if (callback !== undefined) {
        setTimeout(callback, 100);
      }
    });
  }

  showDetail() {
    this.setState({ ...this.state, showDetail: true });
  }

  hideDetail() {
    this.setState({ ...this.state, showDetail: false });
  }

  handleEditor(systemId, data) {
    const { fetchItem } = this.props;

    fetchItem(systemId, (data) => {
      this.setState({
        ...this.state,
        showEditor: true,
        editItem: data,
      });
    });
  }

  hideEditor() {
    this.setState({ showEditor: false });
  }

  fillList() {
    const { showTable, end } = this.state;

    if (!showTable || end) {
      return;
    }
  }

  handleSearch() {
    this.setState({
      isEmpty: true,
      packSystemIds: [],
      error: {},
    });

    const error = {};
    const {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      systemName,
      note,
      scheStartDate,
      scheEndDate,
      closeStartDate,
      closeEndDate,
      totalTest,
      preparation,
      search,
      t,
    } = this.props;

    const params = {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      systemName,
      note,
      scheStartDate,
      scheEndDate,
      closeStartDate,
      closeEndDate,
      totalTest,
      preparation,
      start: 1,
      limit: this.state.limit,
    };

    // 日付のチェック
    if (scheStartDate !== null && scheEndDate !== null) {
      const sd = moment(scheStartDate);
      const ed = moment(scheEndDate);
      error.scheDate = ed.diff(sd, "days") < 0 ? t("schedule_alert") : "";
      this.setState({ error });
    }

    if (closeStartDate !== null && closeEndDate !== null) {
      const sd = moment(closeStartDate);
      const ed = moment(closeEndDate);
      error.closeDate = ed.diff(sd, "days") < 0 ? t("schedule_alert") : "";
      this.setState({ error });
    }

    search(params, (data) => {
      this.setState(
        {
          start: data.length + 1,
          end: data.length < this.state.limit,
          isEmpty: data.length === 0,
          maxPage: Math.ceil(data.total_num / this.state.limit),
          currentPage: Math.ceil(params.start / this.state.limit),
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  handleReset() {
    this.setState({
      isEmpty: true,
      packSystemIds: [],
      error: {},
    });

    const { search } = this.props;

    const params = {
      areaIds: [],
      deviceIds: [],
      categoryId: 0,
      facilityManagementIds: [],
      constructionManagementIds: [],
      primaryChargeIds: [],
      otherIds: [],
      systemName: "",
      note: "",
      scheStartDate: "",
      scheEndDate: "",
      closeStartDate: "",
      closeEndDate: "",
      totalTest: -1,
      preparation: "",
      items: [],
      start: 1,
      limit: this.state.limit,
    };

    search(params, (data) => {
      this.setState({
        start: data.length + 1,
        end: data.length < this.state.limit,
        isEmpty: data.length === 0,
        maxPage: Math.ceil(data.total_num / this.state.limit),
        currentPage: Math.ceil(params.start / this.state.limit),
      }),
        this.showTable(this.fillList);
    });
  }

  handleClear() {
    this.setState({ start: 1 }, () => {
      this.props.resetSearch();
      this.handleReset();
    });
  }

  handleSearchPager(startPos = 1) {
    this.setState({
      isEmpty: true,
      packSystemIds: [],
      error: {},
    });

    const error = {};
    const {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      systemName,
      note,
      scheStartDate,
      scheEndDate,
      closeStartDate,
      closeEndDate,
      totalTest,
      preparation,
      search,
      t,
    } = this.props;

    const params = {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      systemName,
      note,
      scheStartDate,
      scheEndDate,
      closeStartDate,
      closeEndDate,
      totalTest,
      preparation,
      start: startPos,
      limit: this.state.limit,
    };

    // 日付のチェック
    if (scheStartDate !== null && scheEndDate !== null) {
      const sd = moment(scheStartDate);
      const ed = moment(scheEndDate);
      error.scheDate = ed.diff(sd, "days") < 0 ? t("schedule_alert") : "";
      this.setState({ error });
    }

    if (closeStartDate !== null && closeEndDate !== null) {
      const sd = moment(closeStartDate);
      const ed = moment(closeEndDate);
      error.closeDate = ed.diff(sd, "days") < 0 ? t("schedule_alert") : "";
      this.setState({ error });
    }

    search(params, (data) => {
      this.setState(
        {
          start: data.length + 1,
          end: data.length < this.state.limit,
          isEmpty: data.length === 0,
          maxPage: Math.ceil(data.total_num / this.state.limit),
          currentPage: Math.ceil(params.start / this.state.limit),
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  handleNextPage() {
    const { currentPage, limit, maxPage } = this.state;
    if (currentPage < maxPage) {
      const startPos = 1 + currentPage * limit;
      this.handleSearchPager(startPos);
    }
  }

  handlePrevPage() {
    const { currentPage, limit } = this.state;
    if (currentPage > 1) {
      const startPos = 1 + (currentPage - 2) * limit;
      this.handleSearchPager(startPos);
    }
  }

  handleDetailSearch(system_id, system_name) {
    const { searchItems } = this.props;

    const params = {
      systemId: system_id,
      start: 1,
      limit: 999999,
    };

    searchItems(params, (data) => {
      this.setState({
        showDetail: true,
        systemId: system_id,
        systemName: system_name,
        detailItems: data,
      });
    });
  }

  handleSave(data, cancelHandler) {
    const { updateItem } = this.props;

    updateItem(data, this.hideEditor, cancelHandler);
    this.hideEditor();
  }

  toggleCheckobx(system_id) {
    if (this.state.packSystemIds.indexOf(system_id) >= 0) {
      this.setState({
        packSystemIds: this.state.packSystemIds.filter((item) => item != system_id),
      });
    } else {
      this.setState({
        packSystemIds: [...this.state.packSystemIds, system_id],
      });
    }
  }

  handleDownload(ids, filetype) {
    const { toggleDownloading } = this.props;
    toggleDownloading(true);

    const fallback = () => toggleDownloading(false);

    this.props.downloadFiles(
      "system",
      ids,
      filetype,
      (blob, fileName) => {
        const objectUrl = URL.createObjectURL(blob);
        const fname = !fileName ? "system.xls" : fileName;

        this.setState({ objectUrl, downloadName: fname }, () => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fname);
          } else {
            const evt = util.createClickEvent();
            this.btnDownload.dispatchEvent(evt);
          }

          toggleDownloading(false);
        });
      },
      fallback
    );
  }

  render() {
    const titleClassName = `toggle icon-keyboard_arrow_up ${!this.state.showSearch ? "closed" : ""}`;
    const {
      masters,
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      systemName,
      note,
      scheStartDate,
      scheEndDate,
      closeStartDate,
      closeEndDate,
      totalTest,
      preparation,
      fetching,
      changeArea,
      changeDevice,
      changeFacilityManagement,
      changeCategory,
      changeConstructionManagement,
      changePrimaryCharge,
      changeOther,
      changeSystemName,
      changeNote,
      changeScheduledDateFrom,
      changeScheduledDateTo,
      changeCloseDateFrom,
      changeCloseDateTo,
      changeTotalTest,
      changePreparation,
      t,
      downloading,
      masterItems,
    } = this.props;

    const error = this.state.error;

    return (
      <div>
        <div className="contents system">
          <div className="inner">
            <h1 className="page-ttl">
              {t("system_list")}
              <span
                data-test-id="button-system-toggle-search"
                className={titleClassName}
                onClick={() => this.toggleSearch()}
              ></span>
            </h1>
            {this.state.showSearch && (
              <div className="search-box" ref={(node) => (this.searchBox = node)}>
                <div className="form-row">
                  <div className="form-group w-280">
                    <span className="form-label">{t("area")}</span>
                    <div className="w-200 d-ib ta-l">
                      <Select
                        prefix="area"
                        isMulti={true}
                        options={masters.areas}
                        value={areaIds}
                        onChange={changeArea}
                      />
                    </div>
                  </div>
                  <div className="form-group w-280">
                    <span className="form-label">{t("device")}</span>
                    <div className="w-200 d-ib ta-l">
                      <Select
                        prefix="device"
                        isMulti={true}
                        options={masters.devices}
                        value={deviceIds}
                        onChange={changeDevice}
                      />
                    </div>
                  </div>
                  <div className="form-group w-290">
                    <span className="form-label">{t("facility_management")}</span>
                    <div className="w-200 d-ib ta-l">
                      <Select
                        prefix="facility_management"
                        isMulti={true}
                        options={masters.facilityManagements}
                        value={facilityManagementIds}
                        onChange={changeFacilityManagement}
                      />
                    </div>
                  </div>
                  <div className="form-group w-310">
                    <span className="form-label">{t("system_name")}</span>
                    <input
                      data-test-id="text-system-system-name"
                      type="text"
                      className="form-control w-230"
                      value={systemName}
                      onChange={(e) => changeSystemName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group w-280">
                    <span className="form-label">{t("construction_management")}</span>
                    <div className="w-200 d-ib ta-l">
                      <Select
                        prefix="construction_management"
                        isMulti={true}
                        options={masters.constructionManagements}
                        value={constructionManagementIds}
                        onChange={changeConstructionManagement}
                      />
                    </div>
                  </div>
                  <div className="form-group w-280">
                    <span className="form-label">{t("primary_charge")}</span>
                    <div className="w-200 d-ib ta-l">
                      <Select
                        prefix="primary_charge"
                        isMulti={true}
                        options={masters.primaryCharges}
                        value={primaryChargeIds}
                        onChange={changePrimaryCharge}
                      />
                    </div>
                  </div>
                  <div className="form-group w-290">
                    <span className="form-label">{t("other")}</span>
                    <div className="w-200 d-ib ta-l">
                      <Select
                        prefix="other"
                        isMulti={true}
                        options={masters.others}
                        value={otherIds}
                        onChange={changeOther}
                      />
                    </div>
                  </div>
                  <div className="form-group w-310">
                    <span className="form-label">{t("note")}</span>
                    <input
                      data-test-id="text-system-note"
                      type="text"
                      className="form-control w-230"
                      value={note}
                      onChange={(e) => changeNote(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group w-350">
                    <span className="form-label">{t("scheduled_date")}</span>
                    <div className="d-ib">
                      <DatePicker
                        wrapperClassName="w-120"
                        disabled={false}
                        selected={scheStartDate ? moment(scheStartDate).toDate() : null}
                        dateFormat="yyyy/MM/dd"
                        locale={t("calender_locale")}
                        onChange={(date) => changeScheduledDateFrom(date)}
                      />
                    </div>
                    <div className="d-ib w-30 ta-c">&nbsp;〜&nbsp;</div>
                    <div className="d-ib">
                      <DatePicker
                        wrapperClassName="w-120"
                        disabled={false}
                        selected={scheEndDate ? moment(scheEndDate).toDate() : null}
                        dateFormat="yyyy/MM/dd"
                        locale={t("calender_locale")}
                        onChange={(date) => changeScheduledDateTo(date)}
                      />
                    </div>
                    {error.scheDate && (
                      <div className="form-error w-380">
                        <p className="form-error-message w-300">{error.scheDate}</p>
                      </div>
                    )}
                  </div>
                  <div className="form-group w-390">
                    <span className="form-label">{t("result_date")}</span>
                    <div className="d-ib">
                      <DatePicker
                        wrapperClassName="w-120"
                        disabled={false}
                        selected={closeStartDate ? moment(closeStartDate).toDate() : null}
                        dateFormat="yyyy/MM/dd"
                        locale={t("calender_locale")}
                        onChange={(date) => changeCloseDateFrom(date)}
                      />
                    </div>
                    <div className="d-ib w-30 ta-c">&nbsp;〜&nbsp;</div>
                    <div className="d-ib">
                      <DatePicker
                        wrapperClassName="w-120"
                        disabled={false}
                        selected={closeEndDate ? moment(closeEndDate).toDate() : null}
                        dateFormat="yyyy/MM/dd"
                        locale={t("calender_locale")}
                        onChange={(date) => changeCloseDateTo(date)}
                      />
                    </div>
                    {error.closeDate && (
                      <div className="form-error w-380">
                        <p className="form-error-message w-260">{error.closeDate}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-row mb-0">
                  <div className="form-group w-350">
                    <span className="form-label">{t("machines_category")}</span>
                    <div className="w-270 d-ib ta-l">
                      <Select
                        prefix="category"
                        isMulti={false}
                        options={masters.categories}
                        value={categoryId}
                        onChange={changeCategory}
                      />
                    </div>
                  </div>
                  <div className="form-group w-240">
                    <span className="form-label">
                      {t("unadministered")}/{t("completed")}
                    </span>
                    <select
                      data-test-id="select-system-completed"
                      className="form-control w-120"
                      value={totalTest}
                      onChange={(e) => changeTotalTest(e.target.value)}
                    >
                      <option key={-1} value={-1}>
                        -----
                      </option>
                      <option key={0} value={0}>
                        {t("unadministered")}
                      </option>
                      <option key={1} value={1}>
                        {t("completed")}
                      </option>
                    </select>
                  </div>
                  <div className="form-group w-250">
                    <span className="form-label">{t("airtight_preparation")}</span>
                    <select
                      data-test-id="select-system-airtight-preparation"
                      className="form-control w-160"
                      value={preparation}
                      onChange={(e) => changePreparation(e.target.value)}
                    >
                      <option key={-1} value={""}>
                        -----
                      </option>
                      <option key={0} value={false}>
                        {t("not_completed")}
                      </option>
                      <option key={1} value={true}>
                        {t("completed")}
                      </option>
                    </select>
                  </div>
                  <div className="form-group btn-area pull-right mt-0">
                    <button
                      data-test-id="button-system-reset"
                      className="btn btn-gray"
                      onClick={() => this.handleClear()}
                    >
                      {t("reset")}
                    </button>
                    <button
                      data-test-id="button-system-search"
                      className="btn btn-blue"
                      onClick={() => this.handleSearch()}
                    >
                      {t("search")}
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className={`${fetching ? "loading loading--list" : ""}`}>
              {this.state.isEmpty || !masterItems || !masterItems.length
                ? this.state.showTable && !fetching && <p className="empty-message">{t("no_data")}</p>
                : this.state.showTable && (
                    <React.Fragment>
                      <div className="tbl-top-area clearfix relative">
                        <div className="tbl-top-left">
                          <div className="btn-dropdown-area" onMouseLeave={() => this.hideDropdown()}>
                            <a
                              className="d-n"
                              download={this.state.downloadName}
                              href={this.state.objectUrl}
                              ref={(node) => (this.btnDownload = node)}
                            >
                              download
                            </a>
                            <button
                              data-test-id="button-system-batch"
                              className="btn btn-blue btn-dropdown"
                              onClick={() => this.showDropdown("batch")}
                              disabled={this.state.packSystemIds.length > 0 ? false : true}
                            >
                              <span
                                className={clsx(
                                  "icon",
                                  this.state.packSystemIds.length > 0 ? "icon-check_box" : "icon-check_box_disabled"
                                )}
                              />
                              {t("packed_operation")}
                            </button>
                            {this.state.activeButton === "batch" && (
                              <ul className="dropdown-menu" onClick={() => this.hideDropdown()}>
                                <li
                                  data-test-id="button-system-update-system"
                                  disabled={false}
                                  onClick={() => this.handleDownload(this.state.packSystemIds, 2)}
                                >
                                  <img src="./img/icon_xls.svg" alt="XLS" className="icon-file" />
                                  {t("for_update_system")}
                                </li>
                                <li
                                  data-test-id="button-system-update-item-system"
                                  disabled={false}
                                  onClick={() => this.handleDownload(this.state.packSystemIds, 1)}
                                >
                                  <img src="./img/icon_xls.svg" alt="XLS" className="icon-file" />
                                  {t("for_update_item_system")}
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                        <div className={`${downloading ? "loading-small loading-small-download" : ""}`} />
                      </div>
                      <div className="tbl-area">
                        <table className="tbl-basic tbl-data">
                          <thead className="list-head tbl-head-adjusted" ref={(node) => (this.theader = node)}>
                            <tr>
                              <th width="30"></th>
                              <th>{t("system")}</th>
                              <th width="120">{t("airproof_sche_date")}</th>
                              <th width="120">{t("airproof_result_date")}</th>
                              <th>{t("note")}</th>
                              <th width="100">{t("progress")}</th>
                              <th width="100">{t("progress_schedule")}</th>
                              <th width="140">{t("airproof_comp_all")}</th>
                              <th width="120">{t("last_registry_date")}</th>
                              <th width="60">{t("edit")}</th>
                            </tr>
                          </thead>
                          <tbody
                            className="list-body"
                            style={{ maxHeight: this.state.tableBodyMaxHeight }}
                            ref={(node) => (this.tbody = node)}
                          >
                            {masterItems.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td className="txt-center">
                                    <label className={"ckbox"}>
                                      <input type="checkbox" onClick={() => this.toggleCheckobx(item.system_id)} />
                                      <span></span>
                                    </label>
                                  </td>
                                  <td>
                                    <a onClick={() => this.handleDetailSearch(item.system_id, item.system_name)}>
                                      {item.system_name}
                                    </a>
                                  </td>
                                  <td className="txt-center">
                                    {item.totaltest_schedule_date
                                      ? item.totaltest_schedule_date.replace(/-/g, "/")
                                      : ""}
                                  </td>
                                  <td className="txt-center">
                                    {item.totaltest_result_date ? item.totaltest_result_date.replace(/-/g, "/") : ""}
                                  </td>
                                  <td className="txt-left">{item.system_comment}</td>
                                  <td className={`txt-right${item.background_color == 3 ? " cell-orange" : ""}`}>
                                    {item.result_progress_rate}%
                                  </td>
                                  <td className="txt-right">{item.schedule_progress_rate}%</td>
                                  <td className="txt-center">
                                    {item.preparation_complete_num}/{item.preparation_num}
                                  </td>
                                  <td className="txt-center">
                                    {item.item_add_date ? item.item_add_date.replace(/-/g, "/") : ""}
                                  </td>
                                  <td className="txt-center">
                                    {isValid(item.roles, "__self__", "update") && (
                                      <a onClick={() => this.handleEditor(item.system_id)}>{t("edit")}</a>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </React.Fragment>
                  )}
            </div>
          </div>
        </div>
        {this.state.maxPage > 0 && (
          <div className="pagination">
            <div className="null-box grid1">
              {this.state.currentPage > 1 && (
                <div className="left-arrow-box" onClick={() => this.handlePrevPage()}>
                  <span>{t("prev")}</span>
                </div>
              )}
            </div>
            <span className="page-status">
              {this.state.currentPage} / {this.state.maxPage}
            </span>
            <div className="null-box grid3">
              {this.state.currentPage < this.state.maxPage && (
                <div className="right-arrow-box" onClick={() => this.handleNextPage()}>
                  <span>{t("next")}</span>
                </div>
              )}
            </div>
          </div>
        )}
        {this.state.showDetail && (
          <SystemViewerContainer
            closeHandler={() => this.hideDetail()}
            deleteHandler={() => this.hideDetail()}
            systemId={this.state.systemId}
            systemName={this.state.systemName}
            resultProgressRate={this.state.detailItems.result_progress_rate}
            scheduleProgressRate={this.state.detailItems.schedule_progress_rate}
            masterDetailItems={this.state.detailItems.system_item_list_data}
            detailItems={this.state.detailItems.system_item_list_data}
          />
        )}
        {this.state.showEditor && (
          <SystemEditorContainer
            titleName={t("system_edit") + "：[" + this.props.editItem.system_name + "]"}
            data={this.state.editItem}
            closeHandler={() => this.hideEditor()}
          />
        )}
      </div>
    );
  }
}

System.propTypes = {
  areaIds: PropTypes.array.isRequired,
  deviceIds: PropTypes.array.isRequired,
  categoryId: PropTypes.number.isRequired,
  facilityManagementIds: PropTypes.array.isRequired,
  constructionManagementIds: PropTypes.array.isRequired,
  primaryChargeIds: PropTypes.array.isRequired,
  otherIds: PropTypes.array.isRequired,
  masters: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  changeArea: PropTypes.func.isRequired,
  changeDevice: PropTypes.func.isRequired,
  changeFacilityManagement: PropTypes.func.isRequired,
  changeCategory: PropTypes.func.isRequired,
  changeConstructionManagement: PropTypes.func.isRequired,
  changePrimaryCharge: PropTypes.func.isRequired,
  changeOther: PropTypes.func.isRequired,
  changeSystemName: PropTypes.func.isRequired,
  changeNote: PropTypes.func.isRequired,
  revertLocalCondition: PropTypes.func.isRequired,
  validated: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  toggleDownloading: PropTypes.func.isRequired,
};

export default withTranslation()(System);
