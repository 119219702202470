import * as constants from "@/constants";
import { MachineType } from "@/sx-layout/components/settings/machineSetting/models";

export type MachineState = {
  machineTypes: MachineType[];
  companyMachineTypeIds: number[];
  fetching: boolean;
};

export const machine = (
  state = {
    machineTypes: [],
    companyMachineTypeIds: [],
    fetching: false,
  },
  action
) => {
  switch (action.type) {
    case constants.MACHINE_BEGIN_FETCH_MACHINE_TYPES: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.MACHINE_END_FETCH_MACHINE_TYPES: {
      return {
        ...state,
        machineTypes: action.payload,
        fetching: false,
      };
    }
    case constants.MACHINE_BEGIN_FETCH_COMPANIES_MACHINE_TYPES: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.MACHINE_END_FETCH_COMPANIES_MACHINE_TYPES: {
      return {
        ...state,
        companyMachineTypeIds: action.payload,
        fetching: false,
      };
    }
    case constants.APP_SHOW_ERROR: {
      return {
        ...state,
        fetching: false,
      };
    }
    default:
      return state;
  }
};
