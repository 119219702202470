import { connect } from "react-redux";

import actions from "../../actions";
import MiddleClassEditor from "../../components/matrix/MiddleClassEditor";

const mapStateToProps = (state) => {
  return {
    columns: state.matrix.columns,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSave: (processIds, coordinate, callback, failedCallback) =>
      dispatch(actions.matrix.updateProcesses(processIds, coordinate, callback, failedCallback)),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MiddleClassEditor);
