import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import validator from "validator";

import Modal from "../Modal";
import { SubmitButton } from "@/components/common/SubmitButton";

class Construction_managementEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      construction_management_id: 0,
      construction_management_name: "",
      sort: "",
      timestamp: "",
      error: {},
      loading: false,
    };

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeSort = this.handleChangeSort.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.props.setUnloadAlert();
  }

  componentWillUnmount() {
    this.props.clearUnloadAlert();
  }

  // eslint-disable-next-line react/no-deprecated -- 暫定処置でdisableしている
  componentWillReceiveProps(nextProps) {
    const data = nextProps.data;

    if (data) {
      const { construction_management_id, construction_management_name, sort, timestamp } = data;

      this.setState({
        construction_management_id,
        construction_management_name,
        sort,
        timestamp,
        loading: false,
        error: {},
      });
    } else {
      this.setState({
        construction_management_id: 0,
        construction_management_name: "",
        sort: "",
        timestamp: "",
        loading: false,
        error: {},
      });
    }
  }

  handleChangeName(e) {
    this.setState({
      construction_management_name: e.target.value,
    });
  }

  handleChangeSort(e) {
    this.setState({
      sort: e.target.value,
    });
  }

  handleSave() {
    if (this.state.loading) return;
    const error = {};
    const construction_management_name = this.state.construction_management_name
      ? this.state.construction_management_name.trim()
      : "";
    const newState = { ...this.state, construction_management_name };

    if (validator.isEmpty(construction_management_name)) {
      error.construction_management_name = "construction_management_name";
      error.msg = "is_required";
    } else if (construction_management_name.length > 100) {
      error.construction_management_name = "construction_management_name";
      error.msg = "is_too_long";
    }

    if (!validator.isInt(this.state.sort.toString())) {
      error.sort = "display_order_is_invalid";
    } else if (this.state.sort > 999999999) {
      error.sort = "display_order_maximum";
    } else if (this.state.sort < 0) {
      error.sort = "display_order_negative";
    } else if (this.state.sort.toString().match(/^-0+$/)) {
      newState.sort = 0;
    } else {
      newState.sort = parseInt(this.state.sort, 10);
    }

    newState.error = error;

    if (Object.keys(error).length > 0) {
      this.setState(newState);
    } else {
      newState.loading = true;
      this.setState(newState);
      this.props.saveHandler(
        newState,
        () => this.setState({ loading: false }),
        () => this.setState({ loading: false })
      );
    }
  }

  render() {
    const { construction_management_name, sort, error, loading } = this.state;
    const { fetching, closeHandler, t } = this.props;

    return (
      <Modal title={t("construction_management_dialog_title")} loading={fetching} closeHandler={closeHandler}>
        <div className="modal-body">
          <div className="form-row">
            <div className="form-group w-400">
              <span className="form-label txt-bold">{t("construction_management_name")}</span>
              <input
                data-test-id="text-construction-management-edit-construction-management-name"
                type="text"
                className="form-control w-290"
                value={construction_management_name}
                onChange={this.handleChangeName}
              />
            </div>
            {error.construction_management_name && (
              <div className="form-error w-400">
                <p className="form-error-message w-290">
                  {t(error.construction_management_name)}
                  {t(error.msg)}
                </p>
              </div>
            )}
          </div>
          <div className="form-row">
            <div className="form-group w-230">
              <span className="form-label txt-bold">{t("display_order")}</span>
              <input
                data-test-id="text-construction-management-edit-sort"
                type="number"
                min="0"
                className="form-control w-120"
                value={sort}
                onChange={this.handleChangeSort}
              />
            </div>
            {error.sort && (
              <div className="form-error w-400">
                <p className="form-error-message w-290">{t(error.sort)}</p>
              </div>
            )}
          </div>
        </div>
        <div className={"modal-footer"}>
          <button
            data-test-id="button-construction-management-edit-cancel"
            type="button"
            className="btn btn-gray"
            disabled={loading}
            onClick={closeHandler}
          >
            {t("cancel")}
          </button>
          <SubmitButton
            data-test-id="button-construction-management-edit-save"
            onClick={this.handleSave}
            loading={loading}
          />
        </div>
      </Modal>
    );
  }
}

Construction_managementEditor.defaultProps = {
  data: null,
};

Construction_managementEditor.propTypes = {
  data: PropTypes.object,
  fetching: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  saveHandler: PropTypes.func.isRequired,
  setUnloadAlert: PropTypes.func.isRequired,
  clearUnloadAlert: PropTypes.func.isRequired,
};

export default withTranslation()(Construction_managementEditor);
