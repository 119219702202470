import { connect } from "react-redux";

import actions from "../../actions";
import InazumaPanel from "../../components/summary/InazumaPanel";

const mapStateToProps = (state) => {
  const { inazumaSelectedCells, loadingPanel } = state.summary;

  return {
    inazumaSelectedCells,
    loadingPanel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInazumaBasePoints: (callback) => dispatch(actions.summary.fetchInazumaBasePoints(callback)),
    createInazumaBasePoint: (title, callback) => dispatch(actions.summary.createInazumaBasePoint(title, callback)),
    deleteInazumaBasePoint: (id, callback) => dispatch(actions.summary.deleteInazumaBasePoint(id, callback)),
    fetchInazumaTerm: (inazumaBasePointId, callback) =>
      dispatch(actions.summary.fetchInazumaTerm(inazumaBasePointId, callback)),
    requestInazumaLineCreation: (params, callback) =>
      dispatch(actions.summary.requestInazumaLineCreation(params, callback)),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InazumaPanel);
