import { connect } from "react-redux";

import actions from "../../actions";
import Process_middle_class from "../../components/master/Process_middle_class";

const mapStateToProps = (state) => {
  return {
    masterItems: state.process_middle_class.masterItems,
    text: state.process_middle_class.text,
    items: state.process_middle_class.items,
    current: state.process_middle_class.current,
    fetching: state.process_middle_class.fetching,
    isError: state.process_middle_class.isError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSearchText: (text) => dispatch(actions.process_middle_class.changeSearchText(text)),
    fetchItems: (callback) => dispatch(actions.process_middle_class.fetchItems(callback)),
    searchItems: (text, callback) => dispatch(actions.process_middle_class.searchItems(text, callback)),
    fetchItem: (id, callback, failedCallback) =>
      dispatch(actions.process_middle_class.fetchItem(id, callback, failedCallback)),
    createItem: (data, handleSuccess, handleCancel) =>
      dispatch(actions.process_middle_class.createItem(data, handleSuccess, handleCancel)),
    updateItem: (data, handleSuccess, handleCancel, failedCallback) =>
      dispatch(actions.process_middle_class.updateItem(data, handleSuccess, handleCancel, failedCallback)),
    deleteItem: (id, name) => dispatch(actions.process_middle_class.deleteItem(id, name)),
    setUnloadAlert: () => dispatch(actions.app.setUnloadAlert()),
    clearUnloadAlert: () => dispatch(actions.app.clearUnloadAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Process_middle_class);
