import moment from "moment/moment";
import { useState } from "react";
import { useDispatch } from "react-redux";

import actions from "@/actions";
import {
  APPROVE_SCHEDULE_TARGET,
  UPDATE_SCHEDULE_TARGET,
  UPDATE_SCHEDULE_WITH_PROCESS_TARGET,
  UPDATE_SCHEDULE_WITH_TASK_TARGET,
} from "@/components/list/Schedule/constants";
import { BulkUpdateOption, OptionItem, SearchInput } from "@/components/list/Schedule/types";
import { BulkUpdateScheduleRequest } from "@/components/list/Schedule/types/api";
import { formatScheduleValue } from "@/lib/common";

export const useBulkEditorDialog = (props: {
  scheduleDate: Date;
  bulkUpdateOptions?: BulkUpdateOption[];
  onSearch?: () => void;
  searchFilter?: SearchInput;
}) => {
  const { scheduleDate, bulkUpdateOptions, onSearch, searchFilter } = props;
  const dispatch = useDispatch();

  const [inputType, setInputType] = useState<"text" | "textarea" | "select" | null>(null);

  const [targetName, setTargetName] = useState("");
  const [target, setTarget] = useState("");
  const [options, setOptions] = useState<OptionItem[]>([]);

  const openText = (props: { targetName: string; target: string; multiline?: boolean }) => {
    setInputType(props.multiline ? "textarea" : "text");
    setTargetName(props.targetName);
    setTarget(props.target);
  };

  const openSelect = (props: { targetName: string; target: string; optionKey?: string }) => {
    setInputType("select");
    setTargetName(props.targetName);
    setTarget(props.target);
    setOptions(bulkUpdateOptions?.find((v) => v.target === (props.optionKey ?? props.target))?.options ?? []);
  };

  const close = () => {
    setInputType(null);
    setTargetName("");
    setTarget(null);
    setOptions([]);
  };

  const removeEmptyValue = (v?: string[]) => (!v?.length ? undefined : v);

  const update = (value?: string | number) => {
    const filter = [searchFilter.approve1_unprocessed ? 1 : null, searchFilter.approve4_unprocessed ? 2 : null].filter(
      (v) => v
    );
    const request: BulkUpdateScheduleRequest = {
      input: {
        schedule_date: moment(scheduleDate).format("YYYY-MM-DD"),
        category_id: removeEmptyValue(searchFilter.category_id),
        area_id: removeEmptyValue(searchFilter.area_id),
        device_id: removeEmptyValue(searchFilter.device_id),
        manager_user_id: removeEmptyValue(searchFilter.manager_user_id),
        field_p1: searchFilter.field_p1 ? searchFilter.field_p1 : undefined,
        field1: removeEmptyValue(searchFilter.field1),
        field2: searchFilter.field2,
        approve1_user_id: removeEmptyValue(searchFilter.approve1_user_id),
        approve4_user_id: removeEmptyValue(searchFilter.approve4_user_id),
        field_t9: searchFilter.field_t9 ? searchFilter.field_t9 : undefined,
        filter: !filter.length ? undefined : filter,
        target: target.replaceAll("_status", ""),
        value: formatScheduleValue(target, value),
      },
      onSuccess: () => onSearch(),
    };
    if (UPDATE_SCHEDULE_TARGET.includes(target)) {
      dispatch(actions.schedule.bulkUpdateSchedule(request));
    } else if (UPDATE_SCHEDULE_WITH_TASK_TARGET.includes(target)) {
      dispatch(actions.schedule.bulkUpdateScheduleWithTask(request));
    } else if (UPDATE_SCHEDULE_WITH_PROCESS_TARGET.includes(target)) {
      dispatch(actions.schedule.bulkUpdateScheduleWithProcess(request));
    } else if (APPROVE_SCHEDULE_TARGET.includes(target)) {
      dispatch(actions.schedule.bulkApproveSchedule(request));
    }
    close();
  };

  return { inputType, target, targetName, options, openText, openSelect, close, update };
};
